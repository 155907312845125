import { Divider, Drawer, Grid, IconButton, SxProps, Theme, styled } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

export const SIDEBAR_DEFAULT_WIDTH = '330px'

export enum SidebarType {
    SampleDetails,
    SearchSamples,
}

export interface SidebarDrawerProps {
    open: boolean
    onClose: () => void
    customHeader?: JSX.Element
    width?: string
    paperSx?: SxProps<Theme>
    children?: React.ReactNode
}

/**
 * Resusable Drawer component for the sidebar
 */
export function SidebarDrawer(props: SidebarDrawerProps) {
    const { open, customHeader, children, width = SIDEBAR_DEFAULT_WIDTH, paperSx, onClose } = props

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            sx={{
                width: width,
                position: 'relative',
                height: '200vh',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: width,
                    boxSizing: 'border-box',
                },
                zIndex: 0,
            }}
            PaperProps={{
                sx: {
                    ...paperSx,
                    width: width,
                    position: 'absolute',
                    transition: 'none !important',
                },
            }}
        >
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px 24px',
                }}
            >
                {customHeader ? customHeader : <SidebarHeader onClose={onClose} />}

                {children}
            </Grid>
        </Drawer>
    )
}

export interface SidebarHeaderProps {
    onClose: () => void
}
export function SidebarHeader(props: SidebarHeaderProps) {
    const { onClose } = props
    return (
        <>
            <IconButton onClick={onClose} sx={{ position: 'absolute', alignSelf: 'flex-end', padding: '8px 10px 16px' }}>
                <ChevronLeftIcon />
            </IconButton>
            <Divider sx={{ borderColor: 'white' }} />
        </>
    )
}

// Styles main component based on sidebar state, including open/close transition
// Please make sure width has same value as the SidebarDrawer component
export const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})<{
    width?: string
    open?: boolean
}>(({ theme, width = SIDEBAR_DEFAULT_WIDTH, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${width}`,
    }),
}))
