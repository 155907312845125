import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Box, IconButton, Typography, Avatar, Button } from '@mui/material'
import { useStorageContext } from '../../context/Storage'
import { GridSamples } from '../../components/grid-samples'
import { useNotificationContext } from '../../context/NotificationContext'
import { getSamplesWithFilters } from '../../libs/api/samples/SampleAPI'
import { SampleSearchSidebar, SearchSampleFilters } from './SampleSearchSidebar'
import { Main } from '../../components/SidebarDrawer'
import { Sample, SequencingType } from '../../libs/api/samples/SampleModel'
import { filterSamples, searchSamples } from './SampleSearch.services'
import SearchIcon from '@mui/icons-material/Search'
import _ from 'lodash'
import { GeochemicalItemRange } from './GeochemicalFilter'
import { useParams } from 'react-router-dom'
import GenerateReportsDialog from '../../components/generate-reports/GenerateReportsDialog'

export const DEFAULT_FILTERS: SearchSampleFilters = {
    projects: [],
}

/**
 * The Samples Search main component
 */
export default function SamplesSearch() {
    const { projectId } = useParams()
    const openNotification = useNotificationContext().openNotification
    const { samplesSearchContext, updateSamplesSearchContext } = useStorageContext()

    const initialFilters = samplesSearchContext ? samplesSearchContext.filters : DEFAULT_FILTERS
    const triggerInitialFetch = projectId !== undefined || samplesSearchContext === undefined

    const [showSidebar, setShowSidebar] = useState<boolean>(true)
    const [samples, setSamples] = useState<Sample[]>(samplesSearchContext ? samplesSearchContext.samples : [])
    const [filteredSamples, setFilteredSamples] = useState<Sample[]>([])
    const [searchedSamples, setSearchedSamples] = useState<Sample[]>([])

    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const [reportsDialogOpen, setReportsDialogOpen] = useState<boolean>(false)

    // All filters
    const [filters, setFilters] = useState<SearchSampleFilters>(initialFilters)

    // Backend Filters
    const [start, setStart] = useState<Date>(initialFilters.startDate)
    const [end, setEnd] = useState<Date>(initialFilters.endDate)
    const [projects, setProjects] = useState(initialFilters.projects)
    const [geochemicalElements, setGeochemicalElements] = useState<GeochemicalItemRange[]>(initialFilters.geochemElements)
    const [geochemicalCompounds, setGeochemicalCompounds] = useState<GeochemicalItemRange[]>(initialFilters.geochemCompounds)
    const [sequencingTypes, setSequencingTypes] = useState<SequencingType[]>(initialFilters.sequencingType)

    const [searchTerm, setSearchTerm] = useState<string>(samplesSearchContext ? samplesSearchContext.searchTerm : '')

    const [shouldFetch, setShouldFetch] = useState(triggerInitialFetch)
    const [loading, setLoading] = useState(false)

    const samplesFetch = useCallback(async () => {
        setLoading(true)
        const { succeeded, data, errors } = await getSamplesWithFilters(projects, start, end, sequencingTypes, geochemicalElements, geochemicalCompounds)
        if (succeeded) {
            setSamples(data)

            const updatedFilteredSamples = filterSamples(data, filters)
            setFilteredSamples(updatedFilteredSamples)
            const searchedSamples = searchSamples(updatedFilteredSamples, searchTerm)
            setSearchedSamples(searchedSamples)

            updateSamplesSearchContext({
                samples: data,
                filters: filters,
                searchTerm: searchTerm,
            })
        } else {
            openNotification(errors.message, errors.severity)
        }
        setLoading(false)
        setShouldFetch(false)
    }, [start, end, projects, geochemicalElements, geochemicalCompounds, sequencingTypes, samples, filters, searchTerm, updateSamplesSearchContext])

    useEffect(() => {
        if (samplesSearchContext && samplesSearchContext.samples) {
            const updatedFilteredSamples = filterSamples(samplesSearchContext.samples, samplesSearchContext.filters)
            setFilteredSamples(updatedFilteredSamples)
            const searchedSamples = searchSamples(updatedFilteredSamples, samplesSearchContext.searchTerm)
            setSearchedSamples(searchedSamples)
        }
    }, [])

    useEffect(() => {
        if (shouldFetch) {
            samplesFetch()
            setShouldFetch(false)
        }
    }, [shouldFetch])

    const loadSamples = async () => {
        setShouldFetch(true)
    }

    const handleFilterUpdate = (updatedFilters: SearchSampleFilters) => {
        setFilters(updatedFilters)
    }

    const handleResetFilters = () => {
        handleApplyFilter(DEFAULT_FILTERS)
    }

    const handleApplyFilter = (updatedFilters: SearchSampleFilters) => {
        let shouldRefreshSamples = false
        if (!_.isEqual(updatedFilters.projects, projects)) {
            setProjects(updatedFilters.projects)
            shouldRefreshSamples = true
        }

        if (!_.isEqual(updatedFilters.startDate, start)) {
            setStart(updatedFilters.startDate)
            shouldRefreshSamples = true
        }

        if (!_.isEqual(updatedFilters.endDate, end)) {
            setEnd(updatedFilters.endDate)
            shouldRefreshSamples = true
        }

        if (!_.isEqual(updatedFilters.sequencingType, sequencingTypes)) {
            setSequencingTypes(updatedFilters.sequencingType)
            shouldRefreshSamples = true
        }

        if (!_.isEqual(updatedFilters.geochemElements, geochemicalElements)) {
            setGeochemicalElements(updatedFilters.geochemElements ? _.cloneDeep(updatedFilters.geochemElements) : undefined)
            shouldRefreshSamples = true
        }

        if (!_.isEqual(updatedFilters.geochemCompounds, geochemicalCompounds)) {
            setGeochemicalCompounds(updatedFilters.geochemCompounds ? _.cloneDeep(updatedFilters.geochemCompounds) : undefined)
            shouldRefreshSamples = true
        }

        if (shouldRefreshSamples) {
            loadSamples()
        } else {
            const updatedFilteredSamples = filterSamples(samples, updatedFilters)
            setFilteredSamples(updatedFilteredSamples)
            const searchedSamples = searchSamples(updatedFilteredSamples, searchTerm)
            setSearchedSamples(searchedSamples)
        }

        setFilters(updatedFilters)
        updateSamplesSearchContext({
            samples: samples,
            filters: updatedFilters,
            searchTerm: searchTerm,
        })
    }

    useEffect(() => {
        if (filteredSamples?.length > 0) {
            const searchedSamples = searchSamples(filteredSamples, searchTerm)
            setSearchedSamples(searchedSamples)
        }

        updateSamplesSearchContext({
            samples: samples,
            filters: filters,
            searchTerm: searchTerm,
        })
    }, [searchTerm])

    const selectedSamples = useMemo(() => samples.filter(s => selectedRows.includes(s.id)), [samples, selectedRows])

    const gridTitle = loading ? 'Loading samples ...' : `${searchedSamples.length.toLocaleString('en-US')} Samples`

    return (
        <Box id="samples-search" sx={{ display: 'flex', width: '100%' }}>
            <GenerateReportsDialog
                openDialog={reportsDialogOpen}
                onClose={() => setReportsDialogOpen(false)}
                selectedSamples={selectedSamples}/>
            <SampleSearchSidebar
                open={showSidebar}
                loading={loading}
                filters={filters}
                searchTerm={searchTerm}
                onClose={() => setShowSidebar(false)}
                onFilterReset={handleResetFilters}
                onFilterUpdate={handleFilterUpdate}
                onFilterApply={handleApplyFilter}
                onUpdateSearchTerm={setSearchTerm}
            />
            <Main open={showSidebar} width="450px" sx={{ width: 'auto', flex: 1, overflow: 'hidden' }}>
                <Grid justifyContent={"space-between"} display="flex" flexDirection="row">
                    <Grid display="flex" flexDirection="row">
                        <IconButton
                            onClick={() => setShowSidebar(true)}
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{
                                mar: 2,
                                paddingTop: '2px',
                                ...(showSidebar && { display: 'none' }),
                            }}
                        >
                            <Avatar sx={{ backgroundColor: '#1B49D4' }}>
                                <SearchIcon />
                            </Avatar>
                        </IconButton>
                        <Grid display="flex" flexDirection="column">
                            <Typography variant="h4">{gridTitle}</Typography>
                            <Typography variant="body2" color="rgba(19, 19, 32, 0.70)">
                                {'matches your search'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid display="flex" flexDirection="column">
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                height: '36px',
                                marginLeft: 'auto',
                                alignItems: 'flex-end',
                                textTransform: 'none'
                            }}
                            disabled={selectedRows.length === 0}
                            onClick={() => {
                                setReportsDialogOpen(true)
                            }}
                        >
                            Generate Static Reports
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={10} sx={{ paddingTop: '10px' }}>
                    <GridSamples samples={searchedSamples} loading={loading} enableCheckbox={true} getSelectedRows={(rows) => setSelectedRows(rows)} />
                </Grid>
            </Main>
        </Box>
    )
}
