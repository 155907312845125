import BaseAPI, { ApiResponse } from '../BaseAPI'
import { SsuRunDTO } from './SsurunDTO'
import { mapSsuRunDtoToSsuRun } from './SsurunMapper'
import { SsuRun } from './SsurunModel'

/**
 * Gets a list of SsuRun Ids for a given sample
 * @param sampleId The sample Id
 * @returns A list of SsuRun Ids
 */
export async function getSsuRunIds(sampleId: string): Promise<ApiResponse<string[]>> {
    const response = await BaseAPI.getMany<string>('/ssurun/id', sampleId)

    if (response.errors && response.errors.code === '404') {
        return {
            succeeded: true,
            data: [],
        }
    }

    return response
}

/**
 * Gets the SsuRun data from a given ssuRunId
 * @param ssuRunId The ssuRunId
 * @returns The ssuRun model
 */
export async function getSsuRun(ssuRunId: string): Promise<ApiResponse<SsuRun>> {
    const response = await BaseAPI.get<SsuRunDTO>('/ssurun/ssu-id', ssuRunId)

    const ssuRunModel = response.data && mapSsuRunDtoToSsuRun(response.data)
    return {
        ...response,
        data: ssuRunModel,
    }
}
