import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Input, InputLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { Sample } from '../../libs/api/samples/SampleModel'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import ProgressIndicator from '../ProgressIndicator'
import { createReport } from '../../libs/api/static-reports/ReportAPI'
import { useNotificationContext } from '../../context/NotificationContext'

export interface GenerateReportsDialogProps {
    openDialog: boolean
    selectedSamples: Sample[]
    onClose: () => void
}

export default function GenerateReportsDialog(props: GenerateReportsDialogProps) {
    const { openDialog, selectedSamples, onClose } = props
    const [isGenerating, setIsGenerating] = useState(false)
    const [loading, setLoading] = useState(false)

    const [qcChecked, setQcChecked] = useState(false)
    const [dataChecked, setDataChecked] = useState(false)
    const [sequencingType, setSequencingType] = useState('')
    const [reportName, setReportName] = useState('')

    const openNotification = useNotificationContext().openNotification

    useEffect(() => {
        if (!openDialog) {
            setLoading(false)
            setIsGenerating(false)
            setQcChecked(false)
            setDataChecked(false)
            setReportName('')
            setSequencingType('')
        }
    }, [openDialog])

    const formValid = useMemo(() => {
        if (!qcChecked && !dataChecked) return false
        if (!sequencingType) return false
        if ((reportName?.trim()?.length ?? 0) === 0) return false

        return true
    }, [qcChecked, dataChecked, sequencingType, reportName])

    const confirm = async () => {
        setLoading(true)

        const { succeeded, errors } = await createReport({
            generateQcReport: qcChecked,
            generateDataReport: dataChecked,
            sampleIds: selectedSamples.map((s) => s.id),
            name: reportName,
            sequencingType: sequencingType,
        })

        if (succeeded) {
            setIsGenerating(true)
        }
        else {
            openNotification(errors.message, errors.severity)
        }

        setLoading(false)
    }

    return (
        <Dialog
            open={openDialog}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { borderRadius: 12, padding: 15 } }}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">Generate Static Reports</DialogTitle>
            <DialogContent>
                <>
                    <ProgressIndicator open={loading}></ProgressIndicator>
                    {!isGenerating ? (
                        <FormGroup>
                            <Typography variant="body1" fontWeight="700">
                                Report Type
                            </Typography>
                            <FormControlLabel control={<Checkbox checked={qcChecked} onChange={(e) => setQcChecked(e.target.checked)} />} label="QC Report" />
                            <small>Contains details on the sequencing of samples and the quality of the generated DNA sequences</small>
                            <FormControlLabel control={<Checkbox checked={dataChecked} onChange={(e) => setDataChecked(e.target.checked)} />} label="Data Report" />
                            <small>Contains the data and figures for understanding microbial community taxonomy and function</small>
                            <Typography variant="body1" fontWeight="700" sx={{ marginTop: '20px' }}>
                                Sequencing Type
                            </Typography>
                            <RadioGroup onChange={(_, value) => setSequencingType(value)}>
                                <FormControlLabel value="SSU" control={<Radio />} label="16S rRNA" />
                                <FormControlLabel value="WGS" control={<Radio />} label="WGS" />
                            </RadioGroup>
                            <FormControl sx={{ marginTop: '20px' }}>
                                <InputLabel>Report Name</InputLabel>
                                <Input value={reportName} onChange={(e) => setReportName(e.target.value)} />
                            </FormControl>
                        </FormGroup>
                    ) : (
                        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
                            <img alt="processing" src="/images/construction.svg" width={'800px'} />
                            <Typography sx={{ width: '800px', margin: '10px 0 20px' }} variant="h5" align="center" fontWeight={'bold'}>
                                Static Report in progress
                            </Typography>
                            <Typography sx={{ width: '800px' }} variant="body1" align="center">
                                The report will be ready in a few minutes and you can find it <Link to={`/reports`}>here</Link>.<br />
                                You can close this window now.
                            </Typography>
                        </Grid>
                    )}
                </>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        height: '36px',
                        padding: '9px 16px',
                        marginLeft: 'auto',
                        borderRadius: '12px',
                        alignItems: 'flex-start',
                    }}
                    onClick={onClose}
                >
                    <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                        {isGenerating ? 'Close' : 'Cancel'}
                    </Typography>
                </Button>
                {!isGenerating && (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            height: '36px',
                            padding: '9px 16px',
                            marginLeft: '15px',
                            borderRadius: '12px',
                            alignItems: 'flex-start',
                        }}
                        disabled={!formValid}
                        onClick={confirm}
                        autoFocus
                    >
                        <Typography variant="body2" textTransform="capitalize">
                            Start
                        </Typography>
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
