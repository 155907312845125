import BaseAPI, { ApiResponse } from '../BaseAPI'
import { ImageDTO } from './ImageDTO'
import { mapImageDtoToImage } from './ImageMapper'
import { Image, ImageUrl } from './ImageModel'
import { ImageApiError } from '../../../libs/AppErrors'
import { appInsights } from '../../AppInsights'

/**
 * Gets the image array with blob link images for the sample
 * @param sampleId The sample Id
 * @returns An array of image models containg url for teh image blob storage
 */
export async function getSampleImages(sampleId: string): Promise<ApiResponse<Image[]>> {
    const response = await BaseAPI.getMany<ImageDTO>('/images', sampleId)

    const imageModel = response.data && response.data.map((imageDto) => mapImageDtoToImage(imageDto))

    return {
        ...response,
        data: imageModel,
    }
}

/**
 * Gets the image url array from Blob storage, including related attributes
 * @param images An array of image models with blob storage urls
 * @returns The array of image urls and its related attributes
 */
export async function getBlobImages(images: Image[]): Promise<ApiResponse<ImageUrl[]>> {
    const config = { responseType: 'blob' }

    try {
        const resultImages: ImageUrl[] = await Promise.all(
            images.map(async (item, index) => {
                const { succeeded, data, errors } = await BaseAPI.getBlob(`/image/${item.sampleId}`, item.id, config)
                if (succeeded) {
                    const binaryData = []
                    binaryData.push(data)
                    const imageObjectURL = URL.createObjectURL(new Blob(binaryData, { type: 'application/octet-stream' }))
                    const imageUrl: ImageUrl = {
                        url: imageObjectURL,
                        id: item.id,
                        title: `${item.sampleId}-image-${index + 1}`,
                        description: `Image #${index + 1} from sample ${item.sampleId}`,
                    }
                    return imageUrl
                } else {
                    appInsights.trackException({
                        ...ImageApiError.FailToFetchImage,
                        properties: ['sampleId', item.sampleId, 'photoId', item.id, 'Errors', JSON.stringify(errors)],
                    })
                }
            })
        )

        return {
            succeeded: true,
            data: resultImages,
        }
    } catch (error) {
        appInsights.trackException({
            ...ImageApiError.FailToFetchImages,
            properties: ['images', JSON.stringify(images)],
        })
        return {
            succeeded: false,
            errors: {
                message: 'Failed fetching images from blob storage',
                severity: 'Error',
                code: '500',
            },
        }
    }
}
