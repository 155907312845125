import { AccordionDetails, AccordionSummary, Button, Grid, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SidebarDrawer } from '../../components/SidebarDrawer'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { ProjectSelector } from './ProjectSelector'
import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { getUpdatedRangeValue, validateRangeValue } from './SampleSearch.services'
import SearchText from '../../components/SearchText'
import { GEOCHEM_RANGE_PROPERTIES, RANGE_PROPERTIES, RangeValues, SampleSearchFilter } from './SampleSearch.constants'
import { RangeFilter } from './RangeFilter'
import { CustomAccordion } from './SampleSearch.styles'
import { SampleType, SamplingMethod, SequencingType, ShippingCondition, Weather } from '../../libs/api/samples/SampleModel'
import { SelectionFilter } from './SelectionFilter'
import { GeochemItemRangeError, GeochemicalFilter, GeochemicalItemRange } from './GeochemicalFilter'
import { FilterAlt } from '@mui/icons-material'
import dayjs from 'dayjs'

export interface SearchSampleFilters {
    startDate?: Date
    endDate?: Date
    projects: string[]
    sampleType?: SampleType[]
    samplingMethod?: SamplingMethod[]
    weather?: Weather[]
    shippingCondition?: ShippingCondition[]
    depthRange?: RangeValues
    phRange?: RangeValues
    temperatureRange?: RangeValues
    doRange?: RangeValues
    conductivityRange?: RangeValues
    geochemElements?: GeochemicalItemRange[]
    geochemCompounds?: GeochemicalItemRange[]
    sequencingType?: SequencingType[]
}

export interface SampleSearchSidebarProps {
    open: boolean
    loading: boolean
    filters: SearchSampleFilters
    searchTerm: string
    onClose: () => void
    onFilterReset: () => void
    onFilterUpdate: (filter: SearchSampleFilters) => void
    onFilterApply: (filter: SearchSampleFilters) => void
    onUpdateSearchTerm: (searchTerm: string) => void
}

/**
 * The Samples Search Sidebar component holding the filters
 */
export function SampleSearchSidebar(props: SampleSearchSidebarProps) {
    const { open, loading, filters, searchTerm, onClose, onFilterReset, onFilterUpdate, onFilterApply, onUpdateSearchTerm } = props

    const [depthRangeError, setDepthRangeError] = useState<string>('')
    const [phRangeError, setPhRangeError] = useState<string>('')
    const [temperatureRangeError, setTemperatureRangeError] = useState<string>('')
    const [doRangeError, setDoRangeError] = useState<string>('')
    const [conductivityRangeError, setConductivityRangeError] = useState<string>('')
    const [geochemElementsRangeError, setGeochemElementsRangeError] = useState<GeochemItemRangeError[]>([])
    const [geochemCompoundsRangeError, setGeochemCompoundsRangeError] = useState<GeochemItemRangeError[]>([])
    const [isApplyEnabled, setIsApplyEnabled] = useState<boolean>(false)

    const handleSelectionFilterChange = (property: string, key: string, checked: boolean) => {
        let currProperty = (filters[property] as string[]) || []
        if (checked) {
            currProperty.push(key)
        } else {
            currProperty = currProperty.filter((x) => x !== key)
        }
        onFilterUpdate({ ...filters, [property]: currProperty.length > 0 ? currProperty : undefined })
    }

    const handleRangeChange = (propertyName: SampleSearchFilter, key: string, value: string, setErrorCallback: (message: string) => void) => {
        const { name, minAllowed, maxAllowed } = RANGE_PROPERTIES.find((property) => property.name === propertyName)

        const updatedRange = getUpdatedRangeValue(filters[name], key, value)
        onFilterUpdate({ ...filters, [name]: updatedRange })

        const errorMsg = validateRangeValue(updatedRange, minAllowed, maxAllowed)
        setErrorCallback(errorMsg)
    }

    const handleGeochemicalItemSelectionChange = (
        geochemPropertyName: string,
        key: string,
        checked: boolean,
        itemsRangeErrors: GeochemItemRangeError[],
        updateItemsRangeErrors: (rangeErrors: GeochemItemRangeError[]) => void
    ) => {
        let currProperty = (filters[geochemPropertyName] as GeochemicalItemRange[]) || []
        if (checked) {
            currProperty.push({ id: key })
        } else {
            currProperty = currProperty.filter((item) => item.id !== key)
        }
        onFilterUpdate({ ...filters, [geochemPropertyName]: currProperty.length > 0 ? currProperty : undefined })

        // Clears error message for element
        if (!checked) {
            const currentErrorMsg = itemsRangeErrors.find((item) => item.id === key)
            if (currentErrorMsg) {
                updateItemsRangeErrors([...itemsRangeErrors.filter((item) => item.id !== key)])
            }
        }
    }

    const handleGeochemicalItemRangeChange = (
        geochemPropertyName: string,
        itemId: string,
        key: string,
        value: string,
        itemsRangeErrors: GeochemItemRangeError[],
        updateItemsRangeErrors: (rangeErrors: GeochemItemRangeError[]) => void
    ) => {
        const itemFilter = filters[geochemPropertyName].find((x) => x.id === itemId)
        const updatedRange = getUpdatedRangeValue(itemFilter.range, key, value)

        onFilterUpdate({ ...filters, [geochemPropertyName]: [...filters[geochemPropertyName].filter((x) => x.id !== itemId), { id: itemId, range: updatedRange }] })

        const { minAllowed, maxAllowed } = GEOCHEM_RANGE_PROPERTIES.find((property) => property.name === geochemPropertyName)
        const errorMsg = validateRangeValue(updatedRange, minAllowed, maxAllowed)
        const currentErrorMsg = itemsRangeErrors.find((item) => item.id === itemId)
        if (currentErrorMsg) {
            const newErrorItem =
                errorMsg.length > 0
                    ? {
                          id: itemId,
                          errorMsg,
                      }
                    : null
            updateItemsRangeErrors(
                newErrorItem ? [...itemsRangeErrors.filter((item) => item.id !== itemId), newErrorItem] : [...itemsRangeErrors.filter((item) => item.id !== itemId)]
            )
        } else if (errorMsg.length > 0) {
            updateItemsRangeErrors([
                ...itemsRangeErrors,
                {
                    id: itemId,
                    errorMsg,
                },
            ])
        }
    }

    const handleResetFilters = () => {
        
        setDepthRangeError('')
        setPhRangeError('')
        setTemperatureRangeError('')
        setDoRangeError('')
        setConductivityRangeError('')
        setGeochemElementsRangeError([])
        setGeochemCompoundsRangeError([])
        onFilterReset()
    }

    useEffect(() => {
        const invalid =
            depthRangeError.length > 0 ||
            phRangeError.length > 0 ||
            temperatureRangeError.length > 0 ||
            doRangeError.length > 0 ||
            conductivityRangeError.length > 0 ||
            geochemElementsRangeError.length > 0 ||
            geochemCompoundsRangeError.length > 0
        setIsApplyEnabled(!invalid)
    }, [depthRangeError, phRangeError, temperatureRangeError, doRangeError, conductivityRangeError, geochemElementsRangeError, geochemCompoundsRangeError])

    return (
        <SidebarDrawer open={open} onClose={onClose} width="450px">
            <Grid
                item
                xs={12}
                lg={2}
                sx={{
                    minWidth: '230px',
                    paddingRight: '5px',
                    heigth: '100%',
                }}
            >
                <Stack spacing={0} minHeight="600px">
                    <Typography variant="h6" component="h5" sx={{ padding: '8px 10px 16px', fontWeight: 'bold' }}>
                        Search
                    </Typography>
                    <SearchText label="Search by sample ID or project name..." value={searchTerm} onSearchChange={onUpdateSearchTerm} sx={{ marginBottom: '10px' }} />
                    <Grid sx={{ display: 'flex', flexDirection: 'row', margin: '10px 0', justifyContent: 'space-between' }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FilterAlt />
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginLeft: '5px' }}>
                                Refine your search
                            </Typography>
                        </Grid>
                        <Button onClick={handleResetFilters} sx={{ alignSelf: 'flex-end' }}>
                            <Typography variant="body2" textTransform="capitalize" fontWeight={500}>
                                Clear all
                            </Typography>
                        </Button>
                    </Grid>
                    <CustomAccordion elevation={0} disableGutters>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="collection-date" id="collection-date" className={filters.startDate || filters.endDate ? 'active' : ''}>
                            Collection Date
                        </AccordionSummary>
                        <AccordionDetails>
                            <DesktopDatePicker
                                label="Start"                                
                                value={filters.startDate ? dayjs(filters.startDate) : null}
                                onChange={(newValue) => onFilterUpdate({ ...filters, startDate: newValue?.toDate() })}
                                sx={{ paddingBottom: '10px' }}
                                slotProps={{
                                    field: { clearable: true},
                                    textField: {
                                        helperText: 'MM/DD/YYYY',
                                    },
                                }}
                            />
                            <DesktopDatePicker
                                label="End"                                
                                value={filters.endDate ? dayjs(filters.endDate) : null}
                                onChange={(newValue) => onFilterUpdate({ ...filters, endDate: newValue?.toDate() })}
                                slotProps={{
                                    field: { clearable: true},
                                    textField: {
                                        helperText: 'MM/DD/YYYY',
                                    },
                                }}
                            />
                        </AccordionDetails>
                    </CustomAccordion>
                    <CustomAccordion elevation={0} disableGutters>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="projects" id="projects" className={filters.projects.length > 0 ? 'active' : ''}>
                            Projects
                        </AccordionSummary>
                        <AccordionDetails>
                            <ProjectSelector initialProjects={filters.projects} onChange={(newValue) => onFilterUpdate({ ...filters, projects: newValue })} />
                        </AccordionDetails>
                    </CustomAccordion>
                    <GeochemicalFilter
                        geochemPropertyName={SampleSearchFilter.GeochemElements}
                        currentGeochemFilters={filters.geochemElements}
                        rangeErrors={geochemElementsRangeError}
                        onSelectionUpdate={handleGeochemicalItemSelectionChange}
                        onRangeUpdate={handleGeochemicalItemRangeChange}
                        onRangeErrorsUpdate={setGeochemElementsRangeError}
                    />
                    <GeochemicalFilter
                        geochemPropertyName={SampleSearchFilter.geochemCompounds}
                        currentGeochemFilters={filters.geochemCompounds}
                        rangeErrors={geochemCompoundsRangeError}
                        onSelectionUpdate={handleGeochemicalItemSelectionChange}
                        onRangeUpdate={handleGeochemicalItemRangeChange}
                        onRangeErrorsUpdate={setGeochemCompoundsRangeError}
                    />
                    <SelectionFilter
                        selectionTypeName={SampleSearchFilter.SequencingType}
                        currentSelection={filters.sequencingType}
                        onSelectionUpdate={handleSelectionFilterChange}
                    />
                    <SelectionFilter selectionTypeName={SampleSearchFilter.SampleType} currentSelection={filters.sampleType} onSelectionUpdate={handleSelectionFilterChange} />
                    <SelectionFilter
                        selectionTypeName={SampleSearchFilter.SamplingMethod}
                        currentSelection={filters.samplingMethod}
                        onSelectionUpdate={handleSelectionFilterChange}
                    />
                    <SelectionFilter
                        selectionTypeName={SampleSearchFilter.ShippingCondition}
                        currentSelection={filters.shippingCondition}
                        onSelectionUpdate={handleSelectionFilterChange}
                    />
                    <SelectionFilter selectionTypeName={SampleSearchFilter.Weather} currentSelection={filters.weather} onSelectionUpdate={handleSelectionFilterChange} />
                    <RangeFilter
                        rangeTypeName={SampleSearchFilter.PHRange}
                        currentRangeValue={filters.phRange}
                        rangeError={phRangeError}
                        setRangeError={setPhRangeError}
                        onRangeUpdate={handleRangeChange}
                    />
                    <RangeFilter
                        rangeTypeName={SampleSearchFilter.TemperatureRange}
                        currentRangeValue={filters.temperatureRange}
                        rangeError={temperatureRangeError}
                        setRangeError={setTemperatureRangeError}
                        onRangeUpdate={handleRangeChange}
                    />
                    <RangeFilter
                        rangeTypeName={SampleSearchFilter.DepthRange}
                        currentRangeValue={filters.depthRange}
                        rangeError={depthRangeError}
                        setRangeError={setDepthRangeError}
                        onRangeUpdate={handleRangeChange}
                    />
                    <RangeFilter
                        rangeTypeName={SampleSearchFilter.DORange}
                        currentRangeValue={filters.doRange}
                        rangeError={doRangeError}
                        setRangeError={setDoRangeError}
                        onRangeUpdate={handleRangeChange}
                    />
                    <RangeFilter
                        rangeTypeName={SampleSearchFilter.ConductivityRange}
                        currentRangeValue={filters.conductivityRange}
                        rangeError={conductivityRangeError}
                        setRangeError={setConductivityRangeError}
                        onRangeUpdate={handleRangeChange}
                    />
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={!isApplyEnabled}
                        onClick={() => onFilterApply(filters)}
                        sx={{ marginTop: 'auto' }}
                    >
                        Apply
                    </LoadingButton>
                </Stack>
            </Grid>
        </SidebarDrawer>
    )
}
