import { SolidGeochemicalFile } from "../../../pages/manage-data/ManageData.model"
import { GeochemicalFileDTO } from "./GeochemicalFilesDTO"


export const mapGeochemicalFileDtoToSolidGeochemicalFile = (geochemicalFileDTO: GeochemicalFileDTO): SolidGeochemicalFile => {
    const geochemicalFile: SolidGeochemicalFile = {
        id:geochemicalFileDTO.id,
        fileName:geochemicalFileDTO.name,
        associatedSampleIDs: geochemicalFileDTO.geochemicalDatas,
        vendor:geochemicalFileDTO.vendor,
        uploadDate: geochemicalFileDTO.uploadDate
    }

    return geochemicalFile
}