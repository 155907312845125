import BaseAPI, { ApiResponse } from '../BaseAPI'

/**
 * Gets the krona html url for a given ssuRun id
 * @param ssuRunId The ssuRun id
 * @returns The html url string
 */
export async function getKrona(ssuRunId: string): Promise<ApiResponse<string>> {
    const response = await BaseAPI.get<string>('/krona', ssuRunId)
    return response
}
