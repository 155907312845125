import { Grid, Card, CardHeader, CardContent, Typography, Tooltip } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'

export interface GridItem {
    label: string
    field: string
    type?: string
}

export interface InfoCardProps {
    title: string
    gridItems: GridItem[]
    data: any
}

const PUBLIC_PROPERTIES = ['projectName', 'userDefinedSampleId']

export default function InfoCard(props: InfoCardProps) {
    const { title, gridItems, data } = props

    const isAnonymized = (field: string) => {
        const isPublicProperty = PUBLIC_PROPERTIES.findIndex((x) => x === field) >= 0
        const isAnonymizedProperty = data.publicSampleProps && data.publicSampleProps.findIndex((x) => x === field) < 0
        return !isPublicProperty && isAnonymizedProperty
    }

    return (
        <Card variant="outlined">
            <CardHeader title={title} />
            <CardContent>
                <Grid container spacing={1}>
                    {gridItems.map((item: GridItem) => (
                        <Grid key={item.label} item xs={12} md={6}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {item.label}
                            </Typography>
                            {isAnonymized(item.field) ? (
                                <Tooltip title="Anonymized">
                                    <LockIcon sx={{ color: 'rgba(19, 19, 32, 0.38)', transform: 'scale(0.8)', marginBottom: '4px' }} />
                                </Tooltip>
                            ) : (
                                <Typography variant="body1">{data[item.field] ?? 'NA'}</Typography>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    )
}
