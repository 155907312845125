import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export interface ProgressIndicatorProps {
    open: boolean
}

/**
 * The Progress Indicator is a common component that can be placed in a page to visually indicate that
 * the application is currently in a processing or waiting state.
 */
export default function ProgressIndicator(props: ProgressIndicatorProps) {
    const { open } = props

    return (
        <div>
            <Backdrop
                sx={{
                    position: 'absolute',
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
