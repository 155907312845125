import { PublicClientApplication, BrowserCacheLocation, InteractionRequiredAuthError } from '@azure/msal-browser'
import axios from 'axios'
import { getAuthSsoOrg, setAuthSsoOrg } from '../../context/Storage'

export const B2C_USER_FLOW_PREFIX = 'B2C_1_'
export const DEFAULT_B2C_USER_FLOW = `${B2C_USER_FLOW_PREFIX}susi`

let msalInstance: PublicClientApplication = null

export async function getMsalInstance() {
    // If valid org supplied on the URL, automatically log in with that SSO
    const url = new URL(window.location.href)
    const org = url.searchParams.get('org')
    if (org) {
        // Remove org from URL
        url.searchParams.delete('org')
        window.history.pushState({}, document.title, url)

        // Log in if the org is valid
        if (await checkSsoOrg(org)) {
            loginRedirect(org)
            return
        }
    }

    msalInstance = msalInstance ?? createMsalInstance(getAuthSsoOrg())
    return msalInstance
}

function createMsalInstance(ssoOrg?: string) {
    // Create an MSAL instance configured with the correct B2C user flow.
    const userFlow = ssoOrg ? B2C_USER_FLOW_PREFIX + ssoOrg : DEFAULT_B2C_USER_FLOW
    return new PublicClientApplication({
        auth: {
            clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
            authority: process.env.REACT_APP_AUTH_AUTHORITY + userFlow,
            knownAuthorities: [process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES],
            redirectUri: '/',
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage, // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
        },
    })
}

export function loginRedirect(ssoOrg?: string) {
    // Create new MSAL instance as it may be for a different user flow than what was already loaded
    const loginMsal = createMsalInstance(ssoOrg)

    // Store SSO org in local storage so it will be tracked across the redirect
    setAuthSsoOrg(ssoOrg)

    loginMsal.loginRedirect()
}

export async function checkSsoOrg(org: string) {
    // Check if an SSO org is registred with B2C
    const result = await axios.get(`${process.env.REACT_APP_AUTH_AUTHORITY}${B2C_USER_FLOW_PREFIX}${org}/v2.0/.well-known/openid-configuration`, { validateStatus: () => true })
    return result.status === 200
}

async function acquireAccessToken(msalInstance: PublicClientApplication) {
    const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts()

    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        return false
    }
    const accessTokenRequest = {
        scopes: [process.env.REACT_APP_ACCESS_TOKEN_SCOPE],
        account: activeAccount || accounts[0],
    }

    const authResult: any = await msalInstance.acquireTokenSilent(accessTokenRequest).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(accessTokenRequest)
        }
    })

    return authResult.accessToken
}

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 80000,
    headers: { 'Content-Type': 'application/json' },
})

axiosInstance.interceptors.request.use(
    async function (config) {
        const accessToken = await acquireAccessToken(msalInstance)
        if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken
        }
        return config
    },
    function (error: any) {
        return Promise.reject(error)
    }
)
