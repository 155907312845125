export interface Sample {
    id: string
    checkNumber?: string
    legacyBarcode?: string
    userDefinedSampleId?: string
    projectId?: string
    projectName?: string
    company?: string
    site?: string
    weather?: Weather
    shippingCondition?: ShippingCondition
    sampleType?: SampleType
    samplingMethod?: SamplingMethod
    status?: string
    collectionDate?: string
    source?: string
    depth?: number
    temperature?: number
    elevation?: number
    location?: Location
    ph?: number
    do?: number
    orp?: number
    conductivity?: number
    waterPreservation?: WaterPreservationMethod
    waterQualitySampleId?: string
    sampleLocationId?: string
    samplingNotes?: string
    processingNotes?: string
    createdById?: string
    createdByEmail?: string
    publicSampleProps?: string[]
}

export interface Location {
    type: string
    coordinates: number[]
}

export enum Weather {
    clear = 'Clear',
    light_rain = 'Light Rain',
    partly_cloudy = 'Partly Cloudy',
    pouring_rain = 'Pouring Rain',
    snowing = 'Snowing',
    other = 'Other',
}

export enum ShippingCondition {
    ice = 'Ice',
    dry_ice = 'Dry Ice',
    blue_ice = 'Blue Ice',
}

export enum SampleType {
    bedrock = 'Bedrock',
    biofilm = 'Biofilm',
    biosolids = 'Biosolids',
    coal = 'Coal',
    groundwater = 'Groundwater',
    landfill = 'Landfill',
    mineral_oil = 'Mineral Soil',
    oil_associated_bedrock = 'Oil Associated Bedrock',
    oil_associated_enrichments = 'Oil Associated Enrichments',
    oil_associated_groundwater = 'Oil Associated Groundwater',
    oil_associated_sediments = 'Oil Associated Sediments',
    oil_pipeline_associated = 'Oil Pipeline Associated',
    oil_production_associated = 'Oil Production Associated',
    oil_storage_associated = 'Oil Storage Associated',
    ore = 'Ore',
    organic_soil = 'Organic Soil',
    process_water = 'Process Water',
    sediments_mine_impacted = 'Sediments (mine impacted)',
    sediments_natural = 'Sediments (natural)',
    seawater = 'Seawater',
    shale = 'Shale',
    surface_water_mine_impacted = 'Surface Water (mine impacted)',
    surface_water_natural = 'Surface Water (natural)',
    tailings = 'Tailings',
    waste_rock = 'Waste Rock',
    in_silico = 'In Silico',
    wasterock = 'Wasterock',
    water_treatment_plant = 'Water treatment plant',
    other = 'Other',
}

export enum SamplingMethod {
    auger = 'Auger',
    bailer = 'Bailer',
    barge_sample = 'Barge Sample',
    drilling = 'Drilling',
    excavator = 'Excavator',
    hand_sample = 'Hand Sample',
    hand_shovel = 'Hand Shovel',
    peristaltic_pump = 'Peristaltic Pump',
    other = 'Other',
}

export enum WaterPreservationMethod {
    filter_in_field = 'Filter in Field',
    ethanol = 'Ethanol',
    filter_in_lab = 'Filter in Lab',
}

export enum SequencingType {
    SSU = 'SSU',
    WGS = 'WGS',
}
