import { Box } from '@mui/material'
import { PageUnderConstruction } from '../../PageUnderConstruction'

export function PredictedGenes() {
    return (
        <Box id="predicted-genes" sx={{ display: 'flex', justifyContent: 'center' }}>
            <PageUnderConstruction pageName="Predicted Genes" />
        </Box>
    )
}
