import { Close } from '@mui/icons-material'
import { Button, Drawer, Grid, Typography } from '@mui/material'

const NOTES_CARD_WIDTH = '460px'

export interface NotesCardDrawerProps {
    open: boolean
    title: string
    children?: React.ReactNode
    onClose: () => void
}

/**
 * Resusable Drawer component for Notes card
 */
export function NotesCardDrawer(props: NotesCardDrawerProps) {
    const { open, title, children, onClose } = props
    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            onClose={() => onClose()}
            sx={{
                position: 'relative',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    marginRight: '17px',
                    height: '670px',
                    width: NOTES_CARD_WIDTH,
                    boxSizing: 'border-box',
                    elevation: 3,
                    top: '260px',
                },
                zIndex: 0,
            }}
        >
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid
                    height="56px"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'rgba(34, 34, 58, 1)',
                    }}
                >
                    <Typography variant="subtitle1" color="white" sx={{ marginLeft: '30px' }}>
                        {title}
                    </Typography>
                    <Button onClick={() => onClose()} sx={{ marginRight: '10px' }}>
                        <Close sx={{ color: 'white' }} />
                    </Button>
                </Grid>
            </Grid>
            {children}
        </Drawer>
    )
}
