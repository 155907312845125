export enum ChipConfidenceColor {
    Red = 'error',
    Yellow = 'warning',
    Green = 'success',
}

export enum ChipConfidenceBackgroundColor {
    Red = '#FDF7F7',
    Yellow = '#FFF9F5',
    Green = '#F7FAF7',
}
