import { AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomAccordion, GeochemAccordion } from './SampleSearch.styles'
import { ERROR_COLOR_RED, GEOCHEM_RANGE_PROPERTIES, RangeValues, SampleSearchFilter } from './SampleSearch.constants'
import { Warning } from '@mui/icons-material'
import { ChangeEvent, useState } from 'react'

export interface GeochemicalItem {
    id: string
    name: string
    isCommon?: boolean
}

export interface GeochemicalItemRange {
    id: string
    range?: RangeValues
}

export interface GeochemItemRangeError {
    id: string
    errorMsg: string
}

export interface GeochemRangeType {
    name: string
    title: string
    minAllowed: number
    maxAllowed: number
    collection: GeochemicalItem[]
}

export interface GeochemicalFilterProps {
    geochemPropertyName: SampleSearchFilter
    currentGeochemFilters: GeochemicalItemRange[]
    rangeErrors: GeochemItemRangeError[]
    onSelectionUpdate: (
        geochemPropertyName: string,
        key: string,
        checked: boolean,
        itemsRangeErrors: GeochemItemRangeError[],
        updateItemsRangeErrors: (rangeErrors: GeochemItemRangeError[]) => void
    ) => void
    onRangeUpdate: (
        geochemPropertyName: string,
        itemId: string,
        targetId: string,
        value: string,
        itemsRangeErrors: GeochemItemRangeError[],
        updateItemsRangeErrors: (rangeErrors: GeochemItemRangeError[]) => void
    ) => void
    onRangeErrorsUpdate: (rangeErrors: GeochemItemRangeError[]) => void
}

/**
 * The gneric geochemical selection and range filter component
 */
export function GeochemicalFilter(props: GeochemicalFilterProps) {
    const { geochemPropertyName, currentGeochemFilters, rangeErrors, onSelectionUpdate, onRangeUpdate, onRangeErrorsUpdate } = props

    const { name, title, collection } = GEOCHEM_RANGE_PROPERTIES.find((item) => item.name === geochemPropertyName)

    return (
        <CustomAccordion elevation={0} disableGutters>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${name}-type`}
                id={`${name}-type`}
                className={rangeErrors.length > 0 ? 'error' : currentGeochemFilters && 'active'}
            >
                <Typography>{title}</Typography>
                {rangeErrors.length > 0 ? <Warning sx={{ marginLeft: '10px' }} /> : null}
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    <Typography sx={{ margin: '10px 0 10px 5px' }}>{`Common ${name === SampleSearchFilter.GeochemElements ? 'Elements' : 'Compounds'}`}</Typography>
                    {collection
                        .filter((geochemItem) => geochemItem.isCommon)
                        .map((geochemItem) => (
                            <GeochemItem
                                key={geochemItem.id}
                                geochemFilterName={name}
                                geochemItem={geochemItem}
                                geochemFilters={currentGeochemFilters}
                                rangeErrors={rangeErrors}
                                onSelectionUpdate={onSelectionUpdate}
                                onRangeUpdate={onRangeUpdate}
                                onRangeErrorsUpdate={onRangeErrorsUpdate}
                            />
                        ))}
                    <Typography sx={{ margin: '20px 0 10px 5px' }}>{'Others'}</Typography>
                    {collection
                        .filter((geochemItem) => !geochemItem.isCommon)
                        .map((geochemItem) => (
                            <GeochemItem
                                key={geochemItem.id}
                                geochemFilterName={name}
                                geochemItem={geochemItem}
                                geochemFilters={currentGeochemFilters}
                                rangeErrors={rangeErrors}
                                onSelectionUpdate={onSelectionUpdate}
                                onRangeUpdate={onRangeUpdate}
                                onRangeErrorsUpdate={onRangeErrorsUpdate}
                            />
                        ))}
                </FormGroup>
            </AccordionDetails>
        </CustomAccordion>
    )
}

interface GeochemItemProps {
    geochemFilterName: string
    geochemItem: GeochemicalItem
    geochemFilters: GeochemicalItemRange[]
    rangeErrors: GeochemItemRangeError[]
    onSelectionUpdate: (
        geochemPropertyName: string,
        key: string,
        checked: boolean,
        itemsRangeErrors: GeochemItemRangeError[],
        updateItemsRangeErrors: (rangeErrors: GeochemItemRangeError[]) => void
    ) => void
    onRangeUpdate: (
        geochemPropertyName: string,
        itemId: string,
        targetId: string,
        value: string,
        itemsRangeErrors: GeochemItemRangeError[],
        updateItemsRangeErrors: (rangeErrors: GeochemItemRangeError[]) => void
    ) => void
    onRangeErrorsUpdate: (rangeErrors: GeochemItemRangeError[]) => void
}

/**
 * Renders a unitary geochemical element filter option, with checkbox selection and range
 */
function GeochemItem(props: GeochemItemProps) {
    const { geochemFilterName, geochemItem, geochemFilters, rangeErrors, onSelectionUpdate, onRangeUpdate, onRangeErrorsUpdate } = props

    const geochemFilter = geochemFilters && geochemFilters.find((x) => x.id === geochemItem.id)
    const minValue = geochemFilter?.range?.minValue ? geochemFilter.range.minValue : ''
    const maxValue = geochemFilter?.range?.maxValue ? geochemFilter.range.maxValue : ''
    const errorItem = rangeErrors.length > 0 && rangeErrors.find((item) => item.id === geochemItem.id)
    const errorMessage = errorItem ? errorItem.errorMsg : ''

    const [isSelected, setIsSelected] = useState<boolean>(geochemFilter !== undefined)

    const toggleSelection = () => {
        setIsSelected(!isSelected)
        onSelectionUpdate(geochemFilterName, geochemItem.id, !isSelected, rangeErrors, onRangeErrorsUpdate)
    }

    return (
        <Grid key={geochemItem.id}>
            <GeochemAccordion expanded={isSelected} elevation={0} onChange={() => {}}>
                <AccordionSummary>
                    <FormControlLabel control={<Checkbox id={geochemItem.id} checked={isSelected} onChange={toggleSelection} />} label={geochemItem.name} />
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            onRangeUpdate(geochemFilterName, geochemItem.id, event.target.getAttribute('id'), event.target.value, rangeErrors, onRangeErrorsUpdate)
                        }
                    >
                        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                id="minValue"
                                error={errorMessage.length > 0}
                                aria-describedby="minimum-concentration"
                                inputProps={{ 'aria-label': 'ppm' }}
                                label="Min"
                                sx={{ margin: '0 15px' }}
                                InputProps={{
                                    inputMode: 'numeric',
                                    endAdornment: <InputAdornment position="end">ppm</InputAdornment>,
                                }}
                                value={minValue}
                            />
                            <TextField
                                id="maxValue"
                                error={errorMessage.length > 0}
                                aria-describedby="maximum-concentration"
                                inputProps={{ 'aria-label': 'ppm' }}
                                label="Max"
                                sx={{ margin: '0 15px' }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">ppm</InputAdornment>,
                                }}
                                value={maxValue}
                            />
                        </Grid>
                        <Typography variant="body2" sx={{ padding: '10px', color: ERROR_COLOR_RED }}>
                            {errorMessage}
                        </Typography>
                    </FormGroup>
                </AccordionDetails>
            </GeochemAccordion>
        </Grid>
    )
}
