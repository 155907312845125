import { useEffect, useState } from 'react'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import { getProjectsList } from '../../libs/api/projects/ProjectAPI'

const DROPDOWN_ITEM_HEIGHT = 48
const DROPDOWN_ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: DROPDOWN_ITEM_HEIGHT * 4.5 + DROPDOWN_ITEM_PADDING_TOP,
            width: 500,
        },
    },
}

export interface ProjectSelectorProps {
    onChange: (projects: any) => void
    initialProjects?: string[]
}

/**
 * The project selector filter component
 */
export function ProjectSelector(props: ProjectSelectorProps) {
    const { onChange, initialProjects } = props
    const [projects, setProjects] = useState([])
    const [projectMap, setProjectMap] = useState(new Map())

    useEffect(() => {
        const loadProjects = async () => {
            const projectsResponse = await getProjectsList()
            const newProjectMap = new Map()
            projectsResponse.data.map((item) => newProjectMap.set(item.id, item.name))
            setProjectMap(newProjectMap)
            setProjects(initialProjects || [])
        }

        loadProjects()
    }, [initialProjects])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event

        setProjects(typeof value === 'string' ? value.split(',') : value)
        if (onChange) onChange(value)
    }

    return (
        <div>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id="projects-selector-input-label">Projects</InputLabel>
                <Select
                    labelId="projects-selector-select-label"
                    id="project-selector-select-id"
                    multiple
                    value={projects}
                    onChange={handleChange}
                    input={<OutlinedInput label="Projects" />}
                    renderValue={(data) => data.map((item) => projectMap?.get(item)).join(',') || []}
                    MenuProps={MenuProps}
                >
                    {[...projectMap.keys()].map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={projects.indexOf(name) > -1} />
                            <ListItemText primary={projectMap.get(name)} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
