import qs from 'qs'
import BaseAPI, { ApiResponse } from '../BaseAPI'
import { SampleDTO } from './SampleDTO'
import { Sample, SequencingType } from './SampleModel'
import { mapSampleDtoToSample, mapSampleToSampleDto, stringifyGeochemRangeElements } from './SampleMappers'
import { GeochemicalItemRange } from '../../../pages/search/GeochemicalFilter'

/**
 * Gets a samples array from a sample id
 * @param sampleId The id of the sample
 * @returns An array including the sample data
 */
export function getSamplesBySampleId(sampleId: string): Promise<ApiResponse<Sample[]>> {
    const config = { params: { sampleId, includePublic: true } }
    return getSamples(config)
}

/**
 * Gets an array of all the samples within a given time period.
 * Can also be filtered by project ids
 * @param fromDate The start date of the search period
 * @param toDate The end date of the search period
 * @param projectIds All the project ids to be included in the search
 * @param geochemElements Geochemical elements and optional concentration to be filtered
 * @param geochemCompounds Geochemical compounds and optional concentration to be filtered
 * @returns All the samples withing a given date and project id list (if given)
 */
export function getSamplesWithFilters(
    projectIds: string[],
    fromDate?: Date,
    toDate?: Date,    
    sequencingTypes?: SequencingType[],
    geochemElements?: GeochemicalItemRange[],
    geochemCompounds?: GeochemicalItemRange[]
): Promise<ApiResponse<Sample[]>> {
    const geochemElementsPayload = stringifyGeochemRangeElements(geochemElements)
    const geochemCompoundsPayload = stringifyGeochemRangeElements(geochemCompounds)

    const config = {
        params: {
            projectIds,
            fromDate,
            toDate,            
            sequencingTypes,
            includePublic: true,
            geochemElements: geochemElementsPayload,
            geochemCompounds: geochemCompoundsPayload,
        },
        paramsSerializer: (params: any) => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        },
    }
    return getSamples(config)
}

/**
 * Gets complete sample data
 * @param sampleId The sample id
 * @returns The sample model containg complete sample data
 */
export async function getSample(sampleId: string): Promise<ApiResponse<Sample>> {
    const response = await BaseAPI.get<SampleDTO>('/sample', sampleId)
    if (!response.succeeded && response.errors.code === '404') {
        response.errors.message = 'Sample not found'
        response.errors.severity = 'warning'
    }

    const sampleModel = response.data && mapSampleDtoToSample(response.data)

    return {
        ...response,
        data: sampleModel,
    }
}

/**
 * Updates sample data in the database
 * @param sample The sample model to be updated
 * @returns Success or failure. No data included in the response
 */
export async function updateSample(sample: Sample): Promise<ApiResponse<Sample>> {
    const sampleDTO = mapSampleToSampleDto(sample)
    const response = await BaseAPI.update<SampleDTO>('/sample/update-sample', sampleDTO)
    return {
        ...response,
        data: null,
    }
}

/**
 * Updates the status property of a sample
 * @param sampleId The id of the sample
 * @param status The new status property value
 * @returns Success or failure. No data included in the response
 */
export async function updateSampleStatus(sampleId: string, status: string): Promise<ApiResponse<Sample>> {
    const config = { params: { status: status } }
    const response = await BaseAPI.patch<SampleDTO>(`/sample_status/${sampleId}`, null, config)
    return {
        ...response,
        data: null,
    }
}

/** Private methods */

/**
 * Generic method to obtain an array of samples based on filtering in config
 * @param config The config containg the filter logic for the search
 * @returns An array of samples
 */
async function getSamples(config: any): Promise<ApiResponse<Sample[]>> {
    const response = await BaseAPI.getMany<SampleDTO>('/samples', null, config)
    if (!response.succeeded && response.errors.code === '404') {
        response.errors.message = 'No samples found that matches search criteria'
        response.errors.severity = 'warning'
    }
    const sampleModel = response.data && response.data.map((sampleDTO) => mapSampleDtoToSample(sampleDTO))

    return {
        ...response,
        data: sampleModel,
    }
}
