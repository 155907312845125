import { Info } from '@mui/icons-material'
import { Divider, Grid, Typography } from '@mui/material'
import { SidebarDrawer } from '../../../../components/SidebarDrawer'
import { SampleDetailsSidebarHeader } from '../../SidebarHeader'
import { SidebarSearchFilters } from '../../SidebarSearchFilters'
import { SidebarAccessions } from '../../SidebarAccessions'
import { SIDEBAR_DRAWER_BACKGROUND_COLOR } from '../../SampleDetails.constants'

export interface MetabolicPathwaysSidebarProps {
    open: boolean
    onClose: () => void
}

export function MetabolicPathwaySidebar(props: MetabolicPathwaysSidebarProps) {
    const { open, onClose } = props

    return (
        <SidebarDrawer open={open} customHeader={SampleDetailsSidebarHeader({ onClose })} onClose={onClose} paperSx={{ backgroundColor: SIDEBAR_DRAWER_BACKGROUND_COLOR }}>
            <SidebarAccessions />
            <SidebarSearchFilters />
            <Divider sx={{ borderColor: 'white' }} />
            <Grid
                marginTop="30px"
                sx={{
                    display: 'flex',
                    padding: '20px 16px 15px',
                    border: '2px solid #1B49D4',
                    borderRadius: '12px',
                }}
            >
                <Info sx={{ marginRight: '15px', color: '#1B49D4' }} />
                <Grid>
                    <Typography variant="subtitle1" fontWeight="bold" color="rgba(19, 19, 32, 0.87)" marginBottom="4px">
                        Functional Profiles
                    </Typography>
                    <Typography variant="body2">
                        Functional profiles are generated for both WGS and 16S rRNA gene sequencing data. Genes are directly annotated in WGS data. In contrast, for 16S rRNA data
                        functional profiles are transitively inferred from the set of reactions present in organisms identified from the 16S rRNA data. Therefore, functional
                        profiles from WGS data are considered more accurate than those from 16S rRNA gene data.
                    </Typography>
                </Grid>
            </Grid>
        </SidebarDrawer>
    )
}
