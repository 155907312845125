import { Accordion, Divider, styled } from '@mui/material'
import { SidebarDrawer } from '../../../../components/SidebarDrawer'
import { SampleDetailsSidebarHeader } from '../../SidebarHeader'
import { SIDEBAR_DRAWER_BACKGROUND_COLOR } from '../../SampleDetails.constants'
import { SidebarSearchFilters } from '../../SidebarSearchFilters'

export interface SummarySidebarProps {
    open: boolean
    onClose: () => void
}

export function SummarySidebar(props: SummarySidebarProps) {
    const { open, onClose } = props

    return (
        <SidebarDrawer open={open} onClose={onClose} customHeader={SampleDetailsSidebarHeader({ onClose })} paperSx={{ backgroundColor: SIDEBAR_DRAWER_BACKGROUND_COLOR }}>
            <SidebarSearchFilters />
            <Divider />
        </SidebarDrawer>
    )
}

export const CustomAccordionSidebar = styled(Accordion)({
    borderBottom: '2px solid #EBEBEC',
    '&:before': {
        content: 'none',
    },
    '& .MuiAccordionSummary-root': {
        backgroundColor: SIDEBAR_DRAWER_BACKGROUND_COLOR,
        minHeight: '42px',
        padding: 0,
    },
    '& .MuiAccordionDetails-root': {
        backgroundColor: SIDEBAR_DRAWER_BACKGROUND_COLOR,
        padding: '0 0 10px 0',
    },
    '& .MuiTypography-root': {
        padding: 0,
    },
})
