import React, { useState } from 'react'
import { Box, Grid, Typography, Container } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { DataGrid } from '@mui/x-data-grid'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { searchBarcodes } from '../../libs/api/barcodes/BarcodesAPI'
import { useNotificationContext } from '../../context/NotificationContext'

const columns = [
    { field: 'code', headerName: 'Code', width: 250 },
    { field: 'check_digits', headerName: 'Check digits', width: 100 },
    { field: 'customer', headerName: 'Customer', width: 200 },
    { field: 'create_time', headerName: 'Create Time', width: 200 },
    { field: 'create_user', headerName: 'User', width: 400 },
]

function SearchBarcodes() {
    const [end, setEnd] = useState(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000))
    const [start, setStart] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
    const [rows, setRows] = useState([])
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    })
    const [loading, setLoading] = React.useState(false)
    const openNotification = useNotificationContext().openNotification

    const fetchData = async () => {
        setLoading(true)
        const { succeeded, data, errors } = await searchBarcodes(start,end)
        if (succeeded) {
            setRows(data)
        } else {
            openNotification(errors.message, errors.severity)
        }
        setLoading(false)
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">
                        Search Barcodes
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <DesktopDatePicker
                                    label="Start"                                
                                    value={start ? dayjs(start) : null}
                                    onChange={(newValue) => setStart(newValue.toDate())}
                                    sx={{ paddingBottom: '10px' }}
                                    slotProps={{
                                        field: { clearable: true},
                                        textField: {
                                            helperText: 'MM/DD/YYYY',
                                        },
                                    }}
                                />                            
                        </Grid>
                        <Grid item xs={3}>
                        <DesktopDatePicker
                                label="End"                                
                                value={end ? dayjs(end) : null}
                                    onChange={(newValue) => setEnd(newValue.toDate())}
                                slotProps={{
                                    field: { clearable: true},
                                    textField: {
                                        helperText: 'MM/DD/YYYY',
                                    },
                                }}
                            />                            
                        </Grid>
                        <Grid item xs={2}>
                            <LoadingButton variant="contained" color="primary" loading={loading} onClick={fetchData}>
                                Search
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: 800, width: '100%' }}>
                        <DataGrid
                            getRowId={(r) => r.code}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[10, 50, 100]}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default SearchBarcodes
