import { AccordionSummary, Typography, AccordionDetails, Chip } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomAccordionSidebar } from './subcomponents/summary/SummarySidebar'
import { useContext } from 'react'
import { SampleDataContext } from '../../context/CustomContext'
import { SIDEBAR_FONT_COLOR } from './SampleDetails.constants'

export interface SearchFilters {
    isActiveFilterExpanded: boolean
    isMatchingCriteriaExpanded: boolean
    activeFilters: string[]
    matchingCriteria: string[]
}

const INITIAL_SEARCH_FILTER: SearchFilters = {
    isActiveFilterExpanded: false,
    isMatchingCriteriaExpanded: false,
    activeFilters: [],
    matchingCriteria: [],
}

export function SidebarSearchFilters() {
    const { searchFilters = INITIAL_SEARCH_FILTER, setSearchFilters } = useContext(SampleDataContext)

    const handleActiveFiltersExpandToggle = () => {
        searchFilters &&
            setSearchFilters({
                ...searchFilters,
                isActiveFilterExpanded: !searchFilters.isActiveFilterExpanded,
            })
    }

    const handleMatchingCriteriaExpandToggle = () => {
        searchFilters &&
            setSearchFilters({
                ...searchFilters,
                isMatchingCriteriaExpanded: !searchFilters.isMatchingCriteriaExpanded,
            })
    }

    return (
        <>
            <CustomAccordionSidebar
                elevation={0}
                disableGutters
                defaultExpanded={searchFilters?.isActiveFilterExpanded}
                expanded={searchFilters?.isActiveFilterExpanded}
                onChange={handleActiveFiltersExpandToggle}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1" padding="20px 20px 5px" fontWeight={'bold'} color={SIDEBAR_FONT_COLOR}>
                        Active Filters
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {searchFilters?.activeFilters?.map((filter) => <Chip key={filter} label={filter} sx={{ height: '24px', margin: '0 2px 3px', padding: '2px' }} />)}
                    {!searchFilters?.activeFilters ||
                        (searchFilters.activeFilters.length === 0 && (
                            <Typography variant="caption" fontStyle="italic" letterSpacing={0.5} padding="0 20px 20px">
                                No filter applied
                            </Typography>
                        ))}
                </AccordionDetails>
            </CustomAccordionSidebar>
            <CustomAccordionSidebar
                elevation={0}
                disableGutters
                defaultExpanded={searchFilters?.isMatchingCriteriaExpanded}
                expanded={searchFilters?.isMatchingCriteriaExpanded}
                onChange={handleMatchingCriteriaExpandToggle}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1" padding="20px 20px 5px" fontWeight={'bold'} color={SIDEBAR_FONT_COLOR}>
                        Matching Criteria
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {searchFilters?.matchingCriteria?.map((criteria) => (
                        <Typography key={criteria} variant="body1" padding="20px 20px 5px" color={SIDEBAR_FONT_COLOR}>
                            {criteria}
                        </Typography>
                    ))}
                    {!searchFilters?.matchingCriteria ||
                        (searchFilters.matchingCriteria.length === 0 && (
                            <Typography variant="caption" fontStyle="italic" letterSpacing={0.5} padding="0 20px 20px">
                                No sample criteria specified
                            </Typography>
                        ))}
                </AccordionDetails>
            </CustomAccordionSidebar>
        </>
    )
}
