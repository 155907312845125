import Box from '@mui/system/Box'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'

const SQUARE_SIZE = 70

export interface PeriodicTableElementProps {
    elementName: string
    valueWithUnit?: string // Concentration value like '100 ppm'
    method?: string // Name of the lab where the concentration was determined
}

export function PeriodicTableElement(props: PeriodicTableElementProps) {
    const { elementName, valueWithUnit } = props
    const hasConcentration = Boolean(valueWithUnit)

    const elementStyle = {
        borderRadius: 2,
        backgroundColor: hasConcentration ? '#F7B2F7' : '#F5F5F5', // Change color if concentration is present
        width: SQUARE_SIZE,
        height: SQUARE_SIZE,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative', // Needed to position the lab name absolutely
        padding: 1,
        border: hasConcentration ? '1.5px solid #F281F2' : 'none',
    }
    return (
        <Box sx={elementStyle}>
            <Typography variant="body2" color="black" fontWeight="bold" textAlign="center">
                {elementName}
            </Typography>
            {hasConcentration && (
                <Stack direction="column" spacing={0.1} alignItems="center">
                    {/* Stack component for automatic spacing */}
                    <Typography variant="caption" color="black" textAlign="center" sx={{ fontSize: '0.6rem' }}>
                        {valueWithUnit}
                    </Typography>
                </Stack>
            )}
        </Box>
    )
}
