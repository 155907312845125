import { createRoot } from 'react-dom/client'
import { MsalProvider } from '@azure/msal-react'
import { getMsalInstance } from './libs/api/ServicesAuth'
import App from './App'
import './index.css'
import { ThemeProvider } from '@mui/material/styles'
import { mmapTheme } from './context/Theme'
import { NotificationProvider } from './context/NotificationContext'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

getMsalInstance().then((msalInstance) =>
    root.render(
        <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={mmapTheme}>
                <NotificationProvider>
                    <App />
                </NotificationProvider>
            </ThemeProvider>
        </MsalProvider>
    )
)
