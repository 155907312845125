import { Button, Typography } from "@mui/material";
import { StaticReportRow } from "../../../../libs/api/static-reports/StaticReportModel";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { deleteReport, deleteReportFile, downloadReport } from "../../../../libs/api/static-reports/ReportAPI";

export interface ReportsActionsProps {
    row: StaticReportRow
    firstChild?: string
    reportDeleted: () => void
}


export default function ReportActions(props: ReportsActionsProps) {
    const id = props.firstChild 
        ? props.firstChild.split('_')[0]
        : props.row.id
    const reportType = props.firstChild 
        ? null
        : props.row.reportType

    const downloadOrViewReport = async (view: boolean) => {
        const report = await downloadReport(id, reportType)
        const objectUrl = URL.createObjectURL(report.data);
        const a = document.createElement("a")
        document.body.appendChild(a)
        a.href = objectUrl
        if(view)
            a.target = "_blank"
        else
            a.download = `${props.row.groupName}_${reportType}.pdf`
        a.click()
    }

    if(!props.row.groupName) {
        return (
            <>
                {props.row.status !== "CREATED" &&
                    <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                        {props.row.status}
                    </Typography>
                }
                {props.row.status !== "PROCESSING" && 
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            height: '36px',
                            padding: '9px 16px',
                            marginLeft: 'auto',
                            borderRadius: '12px',
                            alignItems: 'flex-start',
                        }}
                        onClick={async () => {
                            await deleteReport(id)
                            props?.reportDeleted()
                        }}
                    >
                        <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                            Delete all
                        </Typography>
                    </Button>
                }
            </>
        )
    }

    return (
        <>
            <Button
                variant="text"
                color="primary"
                sx={{
                    height: '36px',
                    padding: '9px 16px',
                    marginLeft: 'auto',
                    borderRadius: '12px',
                    alignItems: 'flex-start',
                }}
                onClick={() => downloadOrViewReport(true)}
            >
                <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                    View
                </Typography>
            </Button>
            <Button
                variant="text"
                color="primary"
                sx={{
                    height: '36px',
                    padding: '9px 16px',
                    marginLeft: 'auto',
                    borderRadius: '12px',
                    alignItems: 'flex-start',
                }}
                onClick={() => downloadOrViewReport(false)}
            >
                <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                    Download
                </Typography>
            </Button>
            <Button
                variant="text"
                color="primary"
                sx={{
                    height: '36px',
                    padding: '9px 16px',
                    marginLeft: 'auto',
                    borderRadius: '12px',
                    alignItems: 'flex-start',
                }}
                onClick={async () => {
                    await deleteReportFile(id, reportType)
                    props?.reportDeleted()
                }}
            >
                <GridDeleteIcon />
            </Button>
        </>
    )
}