import BaseAPI, { ApiResponse } from '../BaseAPI'
import { OrganizationDTO } from './OrganizationDTO'
import { mapOrganizationsDtoToOrganizations } from './OrganizationMapper'
import { Organization } from './OrganizationModel'

/**
 * Obtains a list with all the organizations within M-MAP
 * @returns List of organizations in M-MAP
 */
export async function getAllOrganizations(): Promise<ApiResponse<Organization[]>> {
    const response = await BaseAPI.getMany<OrganizationDTO>('/orgs/all-orgs', null)

    const orgModel = response.data && mapOrganizationsDtoToOrganizations(response.data)

    return {
        ...response,
        data: orgModel,
    }
}
