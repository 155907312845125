import { Warning } from '@mui/icons-material'
import { AccordionDetails, AccordionSummary, FormGroup, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ChangeEvent } from 'react'
import { CustomAccordion } from './SampleSearch.styles'
import { ERROR_COLOR_RED, RANGE_PROPERTIES, RangeValues, SampleSearchFilter } from './SampleSearch.constants'

export interface RangeFilterProps {
    rangeTypeName: SampleSearchFilter
    currentRangeValue: RangeValues
    rangeError: string
    setRangeError: (message: string) => void
    onRangeUpdate: (propertyName: string, targetId: string, value: string, setErrorCallback: (message: string) => void) => void
}

/**
 * Generic range type filter component
 */
export function RangeFilter(props: RangeFilterProps) {
    const { rangeTypeName, currentRangeValue, rangeError, setRangeError, onRangeUpdate } = props
    const { name, title, unitSymbol, unitName, ariaLabel } = RANGE_PROPERTIES.find((property) => property.name === rangeTypeName)

    return (
        <CustomAccordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={name} id={name} className={rangeError.length > 0 ? 'error' : currentRangeValue && 'active'}>
                <Typography>{title}</Typography>
                {rangeError.length > 0 ? <Warning sx={{ marginLeft: '10px' }} /> : null}
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup onChange={(event: ChangeEvent<HTMLInputElement>) => onRangeUpdate(name, event.target.getAttribute('id'), event.target.value, setRangeError)}>
                    <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            id="minValue"
                            error={rangeError.length > 0}
                            data-key="minValue"
                            aria-describedby={`minimum-${ariaLabel}`}
                            inputProps={{ 'aria-label': unitName }}
                            label="Min"
                            sx={{ margin: '0 15px' }}
                            InputProps={{
                                inputMode: 'numeric',
                                endAdornment: <InputAdornment position="end">{unitSymbol}</InputAdornment>,
                            }}
                            value={currentRangeValue?.minValue || ''}
                        />
                        <TextField
                            id="maxValue"
                            error={rangeError.length > 0}
                            data-key="maxValue"
                            aria-describedby={`maximum-${ariaLabel}`}
                            inputProps={{ 'aria-label': unitName }}
                            label="Max"
                            sx={{ margin: '0 15px' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{unitSymbol}</InputAdornment>,
                            }}
                            value={currentRangeValue?.maxValue || ''}
                        />
                    </Grid>
                    <Typography variant="body2" sx={{ padding: '10px', color: ERROR_COLOR_RED }}>
                        {rangeError}
                    </Typography>
                </FormGroup>
            </AccordionDetails>
        </CustomAccordion>
    )
}
