import { Divider, Grid, Typography } from '@mui/material'

export interface CardItemsProps {
    notesCardState: NotesCardState
    noteCardItems?: NoteCardAttributes[]
}

/**
 * The reusable NoteCard rendering component for the metabolic pathways
 */
export function NoteCard(props: CardItemsProps) {
    const { notesCardState, noteCardItems } = props
    if (notesCardState === NotesCardState.Disabled) {
        return null
    }

    const cardItems = noteCardItems ? noteCardItems : HelperItems

    return (
        <Grid sx={{ padding: '25px 20px' }}>
            {cardItems.map((item) => {
                return (
                    <span key={item.key}>
                        <Typography variant="subtitle2" fontWeight="bold" marginBottom="5px">
                            {item.title}
                        </Typography>
                        <Typography variant="body2" whiteSpace="pre-wrap">
                            {item.description}
                        </Typography>
                        <Divider sx={{ margin: '20px 0' }} />
                    </span>
                )
            })}
        </Grid>
    )
}

export enum NotesCardState {
    Disabled = 'disabled',
    Attributes = 'attributes',
    Helper = 'helper',
}

export interface NoteCardAttributes {
    key: string
    title: string
    description: string
}

/**
 * Items for the Helper card
 */
const HelperItems: NoteCardAttributes[] = [
    {
        key: 'asv',
        title: 'ASVs count',
        description: 'Number of Amplicon Sequence Variants (ASVs) that contribute to the inference of a metabolic pathway.',
    },
    {
        key: 'abundance',
        title: 'Relative abundance',
        description:
            "The abundance of a pathway, relative to all other pathways inferred for the community. These values are normalised such that the sum of the relative abundances for all pathways sums to 100. \n\n Pathway abundances themselves are calculated as the harmonic mean of reactions in a pathway. The abundance of a reaction is calculated by dividing each contributing ASV's read depth by the predicted number of 16S rRNA gene copies and multiplying by the predicted copy number of each gene in the reaction.",
    },
    {
        key: 'confidence',
        title: 'Confidence value',
        description:
            'Value between 0 and 100 indicating the quality of evidence indicating a metabolic pathway is present. Confidence values are qualified as high (90-100), medium (70 - 89), or low (1 - 69).',
    },
]

/**
 * Maps a new NotesCard item list based on functional profile attributes
 *
 * @param functionalInference
 * @returns A list of notes card items
 */
export function mapNotesCardAttributes(functionalInference: any): NoteCardAttributes[] {
    const attributes: NotesCardSampleAttributes = {
        asvUsedInferencePerc: functionalInference.asvUsedInferencePerc,
        communityUsedInferencePerc: functionalInference.communityUsedInferencePerc,
        pathwayCount: functionalInference.pathwayCount,
        filteredAsvLowAbundanceCount: functionalInference.filteredAsvLowAbundanceCount,
        nstiSampleWeightedAvg: functionalInference.nstiSampleWeightedAvg,
    }

    const noteCardItems = AttributesItems.map((item) => {
        return {
            key: item.key,
            title: item.title,
            description: attributes[`${item.property}`] !== undefined ? attributes[`${item.property}`].toString() : 'N/A',
        }
    })

    return noteCardItems
}

/**
 * Attributes used in the Sample Attributes Notes Card
 */
type NotesCardSampleAttributes = {
    asvUsedInferencePerc: number
    communityUsedInferencePerc: number
    pathwayCount: number
    filteredAsvLowAbundanceCount: number
    nstiSampleWeightedAvg: number
}

const AttributesItems = [
    {
        key: 'asvUsedInferencePerc',
        title: 'Percentage of ASVs used in inference',
        property: 'asvUsedInferencePerc',
    },
    {
        key: 'communityUsedInferencePerc',
        title: 'Percentage of community used in inference',
        property: 'communityUsedInferencePerc',
    },
    {
        key: 'pathwayCount',
        title: 'Total number of pathways inferred',
        property: 'pathwayCount',
    },
    {
        key: 'filteredAsvLowAbundanceCount',
        title: 'Number of low-abundance ASVs removed',
        property: 'filteredAsvLowAbundanceCount',
    },
    {
        key: 'nstiSampleWeightedAvg',
        title: 'Number of distantly-related ASVs removed',
        property: 'nstiSampleWeightedAvg',
    },
]
