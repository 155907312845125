import { Card, CardHeader, CardContent, ImageList, ImageListItem, ImageListItemBar, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getBlobImages, getSampleImages } from '../../../libs/api/images/ImageAPI'
import { useNotificationContext } from '../../../context/NotificationContext'
import ProgressIndicator from '../../../components/ProgressIndicator'

export interface PhotoImage {
    title: string
    url: string
    description: string
}

export interface PhotosProps {
    sampleId: string
    images: PhotoImage[]
    setImages: (images: PhotoImage[]) => void
}

export default function Photos(props: PhotosProps) {
    const { sampleId, images, setImages } = props
    const openNotification = useNotificationContext().openNotification

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        async function getImages() {
            setLoading(true)
            const { succeeded, data, errors } = await getSampleImages(sampleId)
            if (succeeded) {
                const { succeeded: fetchSucceeded, data: fetchData, errors: fetchErrors } = await getBlobImages(data)
                if (fetchSucceeded) {
                    setImages(fetchData as any)
                } else {
                    openNotification(fetchErrors.message, fetchErrors.severity)
                }
            } else {
                openNotification(errors.message, errors.severity)
            }
            setLoading(false)
        }

        getImages()
    }, [sampleId])

    return (
        <Card variant="outlined">
            <CardHeader title="Photos" />
            <CardContent>
                {images && images.length > 0 ? (
                    <ImageList cols={3}>
                        {images.map((item) => (
                            <ImageListItem key={item.url}>
                                <img src={item.url} alt={item.title} />
                                <ImageListItemBar title={item.title} subtitle={item.description} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                ) : (
                    <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20px' }}>
                        <Typography variant="h5" sx={{ marginBottom: '30px' }}>
                            No images available for the sample
                        </Typography>
                    </Grid>
                )}
            </CardContent>
            <ProgressIndicator open={loading}></ProgressIndicator>
        </Card>
    )
}
