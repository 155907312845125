import { InputAdornment, SxProps, TextField, Theme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useRef, useState } from 'react'

export interface SearchTextProps {
    label: string
    onSearchChange: (searchTerm: string) => void
    value?: string
    disabled?: boolean
    sx?: SxProps<Theme>
}

/**
 * The SearchText component is a text field common component to be used whenever a keyword search
 * is necessary, providing consistent styling and functionality across the platform
 */
export default function SearchText(props: SearchTextProps) {
    const { disabled = false, label, onSearchChange, value = '', sx } = props

    const [textValue, setTextValue] = useState<string>(value)
    const timer = useRef(null)

    useEffect(() => {
        if (timer) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            onSearchChange(textValue)

            return () => {
                clearTimeout(timer.current)
            }
        }, 750)
    }, [textValue])

    return (
        <TextField
            disabled={disabled}
            type="search"
            id="search"
            label={label}
            value={textValue}
            onChange={(event) => setTextValue(event.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
                sx: { borderRadius: '12px' },
                size: 'small',
            }}
            sx={sx ? sx : {}}
        />
    )
}

/**
 * Verify if keyword is found in the string
 * TODO: Excludes special characters from search
 *
 * @param text The string where the keyword search will be performed
 * @param keyword The string to search for in text
 * @returns True if the keyword is found in text. False otherwise.
 */
export function isKeywordInText(text: string, keyword: string): boolean {
    if (!text || !keyword) {
        return false
    }

    return text.toLowerCase().indexOf(keyword.toLowerCase()) > -1
}

/**
 * Wraps the keywords found in span element with specific classname for highlighting
 * on render
 *
 * @param text The text where the containing keyword will be highlighted
 * @param keyword The keyword string to search and highlight
 * @returns The text with appropriate HTML element wrapper to be highlighted on render
 */
export function highlightKeyword(text: string, keyword: string): string {
    if (!keyword || !text) {
        return text
    }

    const regex = new RegExp(`(${keyword})`, 'gi')
    const parts = text.split(regex)

    const highlightedPart = parts.map((part) => {
        const isMatch = regex.test(part)
        return isMatch ? `<span class="keyword">${part}</span>` : `${part}`
    })

    return highlightedPart.join('')
}
