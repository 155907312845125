import BaseAPI, { ApiResponse } from '../BaseAPI'
import { GeochemicalRunDTO } from './GeochemicalRunDTO'
import { GeochemicalRun } from './GeochemicalRunModel'
import { mapGeochemicalRunModel } from './GeochemicalRunMapper'

export async function getGeochemicalDataForSample(sampleId: string): Promise<ApiResponse<GeochemicalRun>> {
    //The first response returns all the geochemical runs performed on a sample
    const response = await BaseAPI.get<string[]>(`/geochem/sample-id`, sampleId)

    if (response.succeeded && response.data.length > 0) {
        //For now, only the first geochem run for a sample will be used.
        //Supporting multiple geochem samples for one sample will be a feature for future development
        const firstGeochemId = response.data[0]
        //The second response returns the geochemical data of one specific geochemical run
        const second_response = await BaseAPI.get<GeochemicalRunDTO>(`/geochem/id/${sampleId}`, firstGeochemId)
        const geochemicalRunModel = second_response.data && mapGeochemicalRunModel(second_response.data)

        return {
            ...second_response,
            data: geochemicalRunModel,
        }
    } else {
        return {
            ...response,
            data: null,
        }
    }
}


