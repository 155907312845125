export const FUNCTIONAL_INFERENCE_DB_DATA = [
    {
        BioCyc_ID: 'TCA-GLYOX-BYPASS',
        Common_Name: 'superpathway of glyoxylate bypass and TCA',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|TCA-GLYOX-BYPASS',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glyoxylate bypass and TCA',
    },
    {
        BioCyc_ID: 'PWY-7736',
        Common_Name: 'stellatic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-7736',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|stellatic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8395',
        Common_Name: 'linoleate metabolites biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8395',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|linoleate metabolites biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7748',
        Common_Name: 'yatein biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|Yatein-Biosynthesis|PWY-7748',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|Yatein Biosynthesis|yatein biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6530',
        Common_Name: 'perchlorate reduction',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY-6530',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|perchlorate reduction',
    },
    {
        BioCyc_ID: 'PWY-1981',
        Common_Name: 'indole-3-acetate inactivation III',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-1981',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation III',
    },
    {
        BioCyc_ID: 'PWY-6353',
        Common_Name: 'purine nucleotides degradation II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|PWY-6353',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|purine nucleotides degradation II (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-6353',
        Common_Name: 'purine nucleotides degradation II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6353',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|purine nucleotides degradation II (aerobic)',
    },
    {
        BioCyc_ID: 'SUCROSEUTIL2-PWY',
        Common_Name: 'sucrose degradation VII (sucrose 3-dehydrogenase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUCROSE-DEG|SUCROSEUTIL2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sucrose Degradation|sucrose degradation VII (sucrose 3-dehydrogenase)',
    },
    {
        BioCyc_ID: 'PWY-5410',
        Common_Name: 'traumatin and (<i>Z</i>)-3-hexen-1-yl acetate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|FATTY-ACID-DERIVATIVE-SYN|PWY-5410',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Fatty Acid Derivative Biosynthesis|traumatin and (<i>Z</i>)-3-hexen-1-yl acetate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6426',
        Common_Name: 'uracil degradation II (oxidative)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Base-Degradation|Uracil-Degradation|PWY-6426',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Nucleobase Degradation|Uracil Degradation|uracil degradation II (oxidative)',
    },
    {
        BioCyc_ID: 'GLUCARGALACTSUPER-PWY',
        Common_Name: 'superpathway of D-glucarate and D-galactarate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GLUCARGALACTSUPER-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of D-glucarate and D-galactarate degradation',
    },
    {
        BioCyc_ID: 'PWY-8391',
        Common_Name: 'D-galacturonate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Galacturonate-Degradation|PWY-8391',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Galacturonate Degradation|D-galacturonate degradation III',
    },
    {
        BioCyc_ID: 'PWY-8391',
        Common_Name: 'D-galacturonate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Galacturonate-Degradation|PWY-8391',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Galacturonate Degradation|D-galacturonate degradation III',
    },
    {
        BioCyc_ID: 'METHFORM-PWY',
        Common_Name: 'methyl-coenzyme M reduction to methane',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|METHFORM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methyl-coenzyme M reduction to methane',
    },
    {
        BioCyc_ID: 'PWY-7450',
        Common_Name: 'anthocyanidin modification (<i>Arabidopsis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7450',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin modification (<i>Arabidopsis</i>)',
    },
    {
        BioCyc_ID: 'PWY-7450',
        Common_Name: 'anthocyanidin modification (<i>Arabidopsis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7450',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin modification (<i>Arabidopsis</i>)',
    },
    {
        BioCyc_ID: 'PWY-5285',
        Common_Name: 'sulfide oxidation III (to sulfite)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|PWY-5285',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|sulfide oxidation III (to sulfite)',
    },
    {
        BioCyc_ID: 'PWY-5285',
        Common_Name: 'sulfide oxidation III (to sulfite)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfide-Oxidation|PWY-5285',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfide Oxidation|sulfide oxidation III (to sulfite)',
    },
    {
        BioCyc_ID: 'PWY-7677',
        Common_Name: 'rosamicin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7677',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|rosamicin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6258',
        Common_Name: 'patchoulol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6258',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|patchoulol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8398',
        Common_Name: '&alpha;-linolenate metabolites biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8398',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|&alpha;-linolenate metabolites biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5105',
        Common_Name: 'hesperitin glycoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVANONES-SYN|PWY-5105',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavanone Biosynthesis|hesperitin glycoside biosynthesis',
    },
    {
        BioCyc_ID: 'DENITRIFICATION-PWY',
        Common_Name: 'nitrate reduction I (denitrification)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|DENITRIFICATION-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|nitrate reduction I (denitrification)',
    },
    {
        BioCyc_ID: 'DENITRIFICATION-PWY',
        Common_Name: 'nitrate reduction I (denitrification)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Denitrification|DENITRIFICATION-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Denitrification|nitrate reduction I (denitrification)',
    },
    {
        BioCyc_ID: 'DENITRIFICATION-PWY',
        Common_Name: 'nitrate reduction I (denitrification)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|DENITRIFICATION-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction I (denitrification)',
    },
    {
        BioCyc_ID: 'PWY-5788',
        Common_Name: 'indole-3-acetate inactivation V',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-5788',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation V',
    },
    {
        BioCyc_ID: 'PWY-6628',
        Common_Name: 'superpathway of L-phenylalanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PHENYLALANINE-SYN|PWY-6628',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-phenylalanine Biosynthesis|superpathway of L-phenylalanine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6628',
        Common_Name: 'superpathway of L-phenylalanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6628',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-phenylalanine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5384',
        Common_Name: 'sucrose degradation IV (sucrose phosphorylase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUCROSE-DEG|PWY-5384',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sucrose Degradation|sucrose degradation IV (sucrose phosphorylase)',
    },
    {
        BioCyc_ID: 'PWY-7561',
        Common_Name: 'tenellin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7561',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|tenellin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1471',
        Common_Name: 'uracil degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Base-Degradation|Uracil-Degradation|PWY0-1471',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Nucleobase Degradation|Uracil Degradation|uracil degradation III',
    },
    {
        BioCyc_ID: 'NADSYN-PWY',
        Common_Name: 'NAD <i>de novo</i> biosynthesis II (from tryptophan)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|NADSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis II (from tryptophan)',
    },
    {
        BioCyc_ID: 'NADSYN-PWY',
        Common_Name: 'NAD <i>de novo</i> biosynthesis II (from tryptophan)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|NADSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis II (from tryptophan)',
    },
    {
        BioCyc_ID: 'NADSYN-PWY',
        Common_Name: 'NAD <i>de novo</i> biosynthesis II (from tryptophan)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|NADSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis II (from tryptophan)',
    },
    {
        BioCyc_ID: 'NADSYN-PWY',
        Common_Name: 'NAD <i>de novo</i> biosynthesis II (from tryptophan)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|NADSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|NAD <i>de novo</i> biosynthesis II (from tryptophan)',
    },
    {
        BioCyc_ID: 'PWY-7464',
        Common_Name: 'cyanidin 3,7-diglucoside polyacylation biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7464',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|cyanidin 3,7-diglucoside polyacylation biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7464',
        Common_Name: 'cyanidin 3,7-diglucoside polyacylation biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7464',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|cyanidin 3,7-diglucoside polyacylation biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5248',
        Common_Name: 'methanogenesis from dimethylamine',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-5248',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from dimethylamine',
    },
    {
        BioCyc_ID: 'PWY-6491',
        Common_Name: 'D-galacturonate degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Galacturonate-Degradation|PWY-6491',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Galacturonate Degradation|D-galacturonate degradation IV',
    },
    {
        BioCyc_ID: 'PWY-6491',
        Common_Name: 'D-galacturonate degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Galacturonate-Degradation|PWY-6491',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Galacturonate Degradation|D-galacturonate degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7429',
        Common_Name: 'arsenite to oxygen electron transfer (via azurin)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY-7429',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|arsenite to oxygen electron transfer (via azurin)',
    },
    {
        BioCyc_ID: 'PWY-7429',
        Common_Name: 'arsenite to oxygen electron transfer (via azurin)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|PWY-7429',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|arsenite to oxygen electron transfer (via azurin)',
    },
    {
        BioCyc_ID: 'PWY-7429',
        Common_Name: 'arsenite to oxygen electron transfer (via azurin)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|PWY-7429',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|arsenite to oxygen electron transfer (via azurin)',
    },
    {
        BioCyc_ID: 'PWY-7429',
        Common_Name: 'arsenite to oxygen electron transfer (via azurin)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-7429',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|arsenite to oxygen electron transfer (via azurin)',
    },
    {
        BioCyc_ID: 'PWY-7109',
        Common_Name: 'megalomicin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7109',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|megalomicin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6275',
        Common_Name: '&beta;-caryophyllene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6275',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|&beta;-caryophyllene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8410',
        Common_Name: 'representative whole plasma arachidonate-derived icosanoids I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8410',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|representative whole plasma arachidonate-derived icosanoids I',
    },
    {
        BioCyc_ID: 'PWY-6602',
        Common_Name: 'naringenin <i>C</i>-glucosylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVANONES-SYN|PWY-6602',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavanone Biosynthesis|naringenin <i>C</i>-glucosylation',
    },
    {
        BioCyc_ID: 'PWY-4601',
        Common_Name: 'arsenate reduction (respiratory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY-4601',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|arsenate reduction (respiratory)',
    },
    {
        BioCyc_ID: 'PWY-6219',
        Common_Name: 'indole-3-acetate inactivation VIII',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-6219',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation VIII',
    },
    {
        BioCyc_ID: 'PWY-6947',
        Common_Name: 'superpathway of cholesterol degradation II (cholesterol dehydrogenase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Cholesterol-Degradation|PWY-6947',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Cholesterol Degradation|superpathway of cholesterol degradation II (cholesterol dehydrogenase)',
    },
    {
        BioCyc_ID: 'PWY-6947',
        Common_Name: 'superpathway of cholesterol degradation II (cholesterol dehydrogenase)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6947',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of cholesterol degradation II (cholesterol dehydrogenase)',
    },
    {
        BioCyc_ID: 'PWY66-373',
        Common_Name: 'sucrose degradation V (sucrose &alpha;-glucosidase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUCROSE-DEG|PWY66-373',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sucrose Degradation|sucrose degradation V (sucrose &alpha;-glucosidase)',
    },
    {
        BioCyc_ID: 'PWY-7880',
        Common_Name: 'coelimycin P1 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7880',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|coelimycin P1 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7185',
        Common_Name: 'UTP and CTP dephosphorylation I',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Ribonucleosides-Degradation|UTP-CTP-Dephosphorylation|PWY-7185',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Ribonucleoside Degradation|UTP and CTP Dephosphorylation|UTP and CTP dephosphorylation I',
    },
    {
        BioCyc_ID: 'PENTOSE-P-PWY',
        Common_Name: 'pentose phosphate pathway',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Pentose-Phosphate-Cycle|PENTOSE-P-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Pentose Phosphate Pathways|pentose phosphate pathway',
    },
    {
        BioCyc_ID: 'PENTOSE-P-PWY',
        Common_Name: 'pentose phosphate pathway',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PENTOSE-P-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pentose phosphate pathway',
    },
    {
        BioCyc_ID: 'PWY-7925',
        Common_Name: 'rubber degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|Rubber-Degradation|PWY-7925',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Rubber Degradation|rubber degradation II',
    },
    {
        BioCyc_ID: 'PWY-5259',
        Common_Name: 'methanogenesis from methanethiol',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-5259',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from methanethiol',
    },
    {
        BioCyc_ID: 'PWY-7252',
        Common_Name: 'luteolinidin 5-<i>O</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7252',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|luteolinidin 5-<i>O</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7252',
        Common_Name: 'luteolinidin 5-<i>O</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7252',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|luteolinidin 5-<i>O</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'P222-PWY',
        Common_Name: 'sulfide oxidation I (to sulfur globules)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|P222-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|sulfide oxidation I (to sulfur globules)',
    },
    {
        BioCyc_ID: 'P222-PWY',
        Common_Name: 'sulfide oxidation I (to sulfur globules)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfide-Oxidation|P222-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfide Oxidation|sulfide oxidation I (to sulfur globules)',
    },
    {
        BioCyc_ID: 'PWY-7415',
        Common_Name: 'tylosin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7415',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|tylosin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5425',
        Common_Name: 'oleoresin sesquiterpene volatiles biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-5425',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|oleoresin sesquiterpene volatiles biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8413',
        Common_Name: 'representative whole plasma EPA-derived icosanoids',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8413',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|representative whole plasma EPA-derived icosanoids',
    },
    {
        BioCyc_ID: 'PWY-5094',
        Common_Name: 'naringenin glycoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVANONES-SYN|PWY-5094',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavanone Biosynthesis|naringenin glycoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6529',
        Common_Name: 'chlorate reduction',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY-6529',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|chlorate reduction',
    },
    {
        BioCyc_ID: 'PWY-1741',
        Common_Name: 'indole-3-acetate inactivation IX',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-1741',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation IX',
    },
    {
        BioCyc_ID: 'PWY-7110',
        Common_Name: 'superpathway of megalomicin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7110',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|superpathway of megalomicin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7110',
        Common_Name: 'superpathway of megalomicin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7110',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of megalomicin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3801',
        Common_Name: 'sucrose degradation II (sucrose synthase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUCROSE-DEG|PWY-3801',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sucrose Degradation|sucrose degradation II (sucrose synthase)',
    },
    {
        BioCyc_ID: 'PWY-4421',
        Common_Name: 'curcumin glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-4421',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|curcumin glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4421',
        Common_Name: 'curcumin glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-4421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|curcumin glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4421',
        Common_Name: 'curcumin glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-4421',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|curcumin glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7177',
        Common_Name: 'UTP and CTP dephosphorylation II',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Ribonucleosides-Degradation|UTP-CTP-Dephosphorylation|PWY-7177',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Ribonucleoside Degradation|UTP and CTP Dephosphorylation|UTP and CTP dephosphorylation II',
    },
    {
        BioCyc_ID: 'PWY-2229',
        Common_Name: 'superpathway of pterocarpan biosynthesis (via formononetin)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2229',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|superpathway of pterocarpan biosynthesis (via formononetin)',
    },
    {
        BioCyc_ID: 'PWY-2229',
        Common_Name: 'superpathway of pterocarpan biosynthesis (via formononetin)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-2229',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pterocarpan biosynthesis (via formononetin)',
    },
    {
        BioCyc_ID: 'PWY-7924',
        Common_Name: 'rubber degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|Rubber-Degradation|PWY-7924',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Rubber Degradation|rubber degradation I',
    },
    {
        BioCyc_ID: 'METHANOGENESIS-PWY',
        Common_Name: 'methanogenesis from H<SUB>2</SUB> and CO<SUB>2</SUB>',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|METHANOGENESIS-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from H<SUB>2</SUB> and CO<SUB>2</SUB>',
    },
    {
        BioCyc_ID: 'PWY-5153',
        Common_Name: 'anthocyanin biosynthesis (delphinidin 3-O-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5153',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|anthocyanin biosynthesis (delphinidin 3-O-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5153',
        Common_Name: 'anthocyanin biosynthesis (delphinidin 3-O-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5153',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|anthocyanin biosynthesis (delphinidin 3-O-glucoside)',
    },
    {
        BioCyc_ID: 'P303-PWY',
        Common_Name: 'ammonia oxidation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|P303-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|ammonia oxidation II (anaerobic)',
    },
    {
        BioCyc_ID: 'P303-PWY',
        Common_Name: 'ammonia oxidation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-oxidation|P303-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Oxidation|ammonia oxidation II (anaerobic)',
    },
    {
        BioCyc_ID: 'P303-PWY',
        Common_Name: 'ammonia oxidation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|P303-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|ammonia oxidation II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7624',
        Common_Name: 'nystatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7624',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|nystatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6836',
        Common_Name: 'santalene biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6836',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|santalene biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY2G6Z-2',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY2G6Z-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
    },
    {
        BioCyc_ID: 'PWY2G6Z-2',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY2G6Z-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
    },
    {
        BioCyc_ID: 'PWY2G6Z-2',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY2G6Z-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
    },
    {
        BioCyc_ID: 'PWY-5118',
        Common_Name: 'ponciretin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVANONES-SYN|PWY-5118',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavanone Biosynthesis|ponciretin biosynthesis',
    },
    {
        BioCyc_ID: 'THIOSULFOX-PWY',
        Common_Name: 'thiosulfate oxidation I (to tetrathionate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|THIOSULFOX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|thiosulfate oxidation I (to tetrathionate)',
    },
    {
        BioCyc_ID: 'THIOSULFOX-PWY',
        Common_Name: 'thiosulfate oxidation I (to tetrathionate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Oxidation|THIOSULFOX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Oxidation|thiosulfate oxidation I (to tetrathionate)',
    },
    {
        BioCyc_ID: 'PWY-1962',
        Common_Name: 'indole-3-acetate inactivation II',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-1962',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation II',
    },
    {
        BioCyc_ID: 'PWY-7384',
        Common_Name: 'anaerobic energy metabolism (invertebrates, mitochondrial)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|PWY-7384',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|anaerobic energy metabolism (invertebrates, mitochondrial)',
    },
    {
        BioCyc_ID: 'PWY-7384',
        Common_Name: 'anaerobic energy metabolism (invertebrates, mitochondrial)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7384',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|anaerobic energy metabolism (invertebrates, mitochondrial)',
    },
    {
        BioCyc_ID: 'PWY-7345',
        Common_Name: 'superpathway of anaerobic sucrose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUCROSE-DEG|PWY-7345',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sucrose Degradation|superpathway of anaerobic sucrose degradation',
    },
    {
        BioCyc_ID: 'PWY-7345',
        Common_Name: 'superpathway of anaerobic sucrose degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7345',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of anaerobic sucrose degradation',
    },
    {
        BioCyc_ID: 'PWY-7653',
        Common_Name: 'griseofulvin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7653',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|griseofulvin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7653',
        Common_Name: 'griseofulvin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7653',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|griseofulvin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5053',
        Common_Name: 'superpathway of gibberellin GA<sub>12</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|PWY-5053',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|superpathway of gibberellin GA<sub>12</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5053',
        Common_Name: 'superpathway of gibberellin GA<sub>12</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|PWY-5053',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|superpathway of gibberellin GA<sub>12</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5053',
        Common_Name: 'superpathway of gibberellin GA<sub>12</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5053',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of gibberellin GA<sub>12</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6791',
        Common_Name: 'xyloglucan degradation I (endoglucanase)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6791',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|xyloglucan degradation I (endoglucanase)',
    },
    {
        BioCyc_ID: 'PWY-6791',
        Common_Name: 'xyloglucan degradation I (endoglucanase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Xyloglucan-Degradation|PWY-6791',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Xyloglucan Degradation|xyloglucan degradation I (endoglucanase)',
    },
    {
        BioCyc_ID: 'PWY-6791',
        Common_Name: 'xyloglucan degradation I (endoglucanase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Xyloglucan-Degradation|PWY-6791',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Xyloglucan Degradation|xyloglucan degradation I (endoglucanase)',
    },
    {
        BioCyc_ID: 'PWY-5247',
        Common_Name: 'methanogenesis from methylamine',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-5247',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from methylamine',
    },
    {
        BioCyc_ID: 'PWY-5284',
        Common_Name: 'shisonin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5284',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|shisonin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5284',
        Common_Name: 'shisonin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5284',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|shisonin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7108',
        Common_Name: 'erythromycin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7108',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|erythromycin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5773',
        Common_Name: 'gossypol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-5773',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|gossypol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8073',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>P. putida</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8073',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>P. putida</i>)',
    },
    {
        BioCyc_ID: 'PWY-8073',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>P. putida</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8073',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>P. putida</i>)',
    },
    {
        BioCyc_ID: 'PWY-8073',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>P. putida</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8073',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>P. putida</i>)',
    },
    {
        BioCyc_ID: 'CARNMET-PWY',
        Common_Name: 'L-carnitine degradation I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|CARNMET-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|L-carnitine degradation I',
    },
    {
        BioCyc_ID: 'CARNMET-PWY',
        Common_Name: 'L-carnitine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|CARN-DEG|CARNMET-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|L-Carnitine Degradation|L-carnitine degradation I',
    },
    {
        BioCyc_ID: 'PWY-6566',
        Common_Name: 'chondroitin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6566',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|chondroitin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6566',
        Common_Name: 'chondroitin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6566',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|chondroitin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7596',
        Common_Name: 'superpathway of stearidonate biosynthesis (cyanobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-7596',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|superpathway of stearidonate biosynthesis (cyanobacteria)',
    },
    {
        BioCyc_ID: 'PWY-7596',
        Common_Name: 'superpathway of stearidonate biosynthesis (cyanobacteria)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7596',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of stearidonate biosynthesis (cyanobacteria)',
    },
    {
        BioCyc_ID: 'SUCUTIL-PWY',
        Common_Name: 'sucrose degradation I (sucrose phosphotransferase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUCROSE-DEG|SUCUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sucrose Degradation|sucrose degradation I (sucrose phosphotransferase)',
    },
    {
        BioCyc_ID: 'PWY-8037',
        Common_Name: 'saliniketal A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8037',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|saliniketal A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5347',
        Common_Name: 'superpathway of L-methionine biosynthesis (transsulfuration)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-De-novo-Biosynthesis|PWY-5347',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine <i>De Novo</i> Biosynthesis|superpathway of L-methionine biosynthesis (transsulfuration)',
    },
    {
        BioCyc_ID: 'PWY-5347',
        Common_Name: 'superpathway of L-methionine biosynthesis (transsulfuration)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5347',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-methionine biosynthesis (transsulfuration)',
    },
    {
        BioCyc_ID: 'PWY-6812',
        Common_Name: 'xyloglucan degradation III (cellobiohydrolase)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6812',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|xyloglucan degradation III (cellobiohydrolase)',
    },
    {
        BioCyc_ID: 'PWY-6812',
        Common_Name: 'xyloglucan degradation III (cellobiohydrolase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Xyloglucan-Degradation|PWY-6812',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Xyloglucan Degradation|xyloglucan degradation III (cellobiohydrolase)',
    },
    {
        BioCyc_ID: 'PWY-6812',
        Common_Name: 'xyloglucan degradation III (cellobiohydrolase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Xyloglucan-Degradation|PWY-6812',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Xyloglucan Degradation|xyloglucan degradation III (cellobiohydrolase)',
    },
    {
        BioCyc_ID: 'PWY-5261',
        Common_Name: 'methanogenesis from tetramethylammonium',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-5261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from tetramethylammonium',
    },
    {
        BioCyc_ID: 'PWY-7449',
        Common_Name: 'acylated cyanidin galactoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7449',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|acylated cyanidin galactoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7449',
        Common_Name: 'acylated cyanidin galactoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7449',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|acylated cyanidin galactoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7412',
        Common_Name: 'mycinamicin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7412',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|mycinamicin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5950',
        Common_Name: 'geosmin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-5950',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|geosmin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2381',
        Common_Name: '4-nitrobenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrobenzoate-Degradation|PWY-2381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrobenzoate Degradation|4-nitrobenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY2B4Q-4',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (2,3-diamino-2,3-dideoxy-D-glucopyranose-containing)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY2B4Q-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (2,3-diamino-2,3-dideoxy-D-glucopyranose-containing)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-4',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (2,3-diamino-2,3-dideoxy-D-glucopyranose-containing)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY2B4Q-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (2,3-diamino-2,3-dideoxy-D-glucopyranose-containing)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-4',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (2,3-diamino-2,3-dideoxy-D-glucopyranose-containing)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY2B4Q-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (2,3-diamino-2,3-dideoxy-D-glucopyranose-containing)',
    },
    {
        BioCyc_ID: 'PWY-5821',
        Common_Name: 'dalcochinin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-5821',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|dalcochinin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5674',
        Common_Name: 'nitrate reduction IV (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY-5674',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|nitrate reduction IV (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY-5674',
        Common_Name: 'nitrate reduction IV (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY-5674',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction IV (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY-6568',
        Common_Name: 'dermatan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6568',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|dermatan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6568',
        Common_Name: 'dermatan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6568',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|dermatan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8227',
        Common_Name: 'superpathway of enterobacterial common antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-8227',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|superpathway of enterobacterial common antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8227',
        Common_Name: 'superpathway of enterobacterial common antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-8227',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|superpathway of enterobacterial common antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8227',
        Common_Name: 'superpathway of enterobacterial common antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8227',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of enterobacterial common antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-621',
        Common_Name: 'sucrose degradation III (sucrose invertase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUCROSE-DEG|PWY-621',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sucrose Degradation|sucrose degradation III (sucrose invertase)',
    },
    {
        BioCyc_ID: 'PWY-5393',
        Common_Name: 'raspberry ketone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-5393',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|raspberry ketone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5505',
        Common_Name: 'L-glutamate and L-glutamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMATE-SYN|PWY-5505',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamate Biosynthesis|L-glutamate and L-glutamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5505',
        Common_Name: 'L-glutamate and L-glutamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMINE-SYN|PWY-5505',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamine Biosynthesis|L-glutamate and L-glutamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5505',
        Common_Name: 'L-glutamate and L-glutamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-5505',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|L-glutamate and L-glutamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5505',
        Common_Name: 'L-glutamate and L-glutamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5505',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-glutamate and L-glutamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6807',
        Common_Name: 'xyloglucan degradation II (exoglucanase)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6807',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|xyloglucan degradation II (exoglucanase)',
    },
    {
        BioCyc_ID: 'PWY-6807',
        Common_Name: 'xyloglucan degradation II (exoglucanase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Xyloglucan-Degradation|PWY-6807',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Xyloglucan Degradation|xyloglucan degradation II (exoglucanase)',
    },
    {
        BioCyc_ID: 'PWY-6807',
        Common_Name: 'xyloglucan degradation II (exoglucanase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Xyloglucan-Degradation|PWY-6807',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Xyloglucan Degradation|xyloglucan degradation II (exoglucanase)',
    },
    {
        BioCyc_ID: 'PWY-8043',
        Common_Name: 'ophthalmate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|PWY-8043',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|ophthalmate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8304',
        Common_Name: 'methanogenesis from methoxylated aromatic compounds',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|PWY-8304',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|methanogenesis from methoxylated aromatic compounds',
    },
    {
        BioCyc_ID: 'PWY-8304',
        Common_Name: 'methanogenesis from methoxylated aromatic compounds',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-8304',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from methoxylated aromatic compounds',
    },
    {
        BioCyc_ID: 'PWY-5307',
        Common_Name: 'gentiodelphin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5307',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|gentiodelphin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5307',
        Common_Name: 'gentiodelphin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5307',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|gentiodelphin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7422',
        Common_Name: 'methymycin, neomethymycin and novamethymycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|methymycin, neomethymycin and novamethymycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7720',
        Common_Name: 'ophiobolin F biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-7720',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|ophiobolin F biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5648',
        Common_Name: '2-nitrobenzoate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrobenzoate-Degradation|2-Nirobenzoate-Degradation|PWY-5648',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrobenzoate Degradation|2-Nitrobenzoate Degradation|2-nitrobenzoate degradation II',
    },
    {
        BioCyc_ID: 'NAGLIPASYN-PWY',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-IV-A-Biosynthesis|NAGLIPASYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'NAGLIPASYN-PWY',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|NAGLIPASYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'NAGLIPASYN-PWY',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|NAGLIPASYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-6332',
        Common_Name: 'coumestrol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-6332',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|coumestrol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6748',
        Common_Name: 'nitrate reduction VII (denitrification)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY-6748',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|nitrate reduction VII (denitrification)',
    },
    {
        BioCyc_ID: 'PWY-6748',
        Common_Name: 'nitrate reduction VII (denitrification)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Denitrification|PWY-6748',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Denitrification|nitrate reduction VII (denitrification)',
    },
    {
        BioCyc_ID: 'PWY-6748',
        Common_Name: 'nitrate reduction VII (denitrification)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY-6748',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction VII (denitrification)',
    },
    {
        BioCyc_ID: 'PWY-6558',
        Common_Name: 'heparan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6558',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|heparan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6558',
        Common_Name: 'heparan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6558',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|heparan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-41',
        Common_Name: 'allantoin degradation IV (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Allantoin-degradation|PWY0-41',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Allantoin Degradation|allantoin degradation IV (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY0-41',
        Common_Name: 'allantoin degradation IV (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-41',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|allantoin degradation IV (anaerobic)',
    },
    {
        BioCyc_ID: 'TREDEGLOW-PWY',
        Common_Name: 'trehalose degradation I (low osmolarity)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Trehalose-Degradation|TREDEGLOW-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Trehalose Degradation|trehalose degradation I (low osmolarity)',
    },
    {
        BioCyc_ID: 'PWY-7669',
        Common_Name: 'equisetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7669',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|equisetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7669',
        Common_Name: 'equisetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7669',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|equisetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7458',
        Common_Name: 'violdelphin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7458',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|violdelphin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7458',
        Common_Name: 'violdelphin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7458',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|violdelphin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5823',
        Common_Name: 'superpathway of CDP-glucose-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CDP-Sugar-Biosynthesis|PWY-5823',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CDP-sugar Biosynthesis|superpathway of CDP-glucose-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5823',
        Common_Name: 'superpathway of CDP-glucose-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5823',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of CDP-glucose-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6902',
        Common_Name: 'chitin degradation II (Vibrio)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Chitin-Degradation|PWY-6902',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Chitin Degradation|chitin degradation II (Vibrio)',
    },
    {
        BioCyc_ID: 'PWY-6902',
        Common_Name: 'chitin degradation II (Vibrio)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Chitin-Degradation|PWY-6902',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Chitin Degradation|chitin degradation II (Vibrio)',
    },
    {
        BioCyc_ID: 'PWY-6902',
        Common_Name: 'chitin degradation II (Vibrio)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6902',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|chitin degradation II (Vibrio)',
    },
    {
        BioCyc_ID: 'GLYCGREAT-PWY',
        Common_Name: 'creatine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLYCINE-DEG|GLYCGREAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|Glycine Degradation|creatine biosynthesis',
    },
    {
        BioCyc_ID: 'GLYCGREAT-PWY',
        Common_Name: 'creatine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|GLYCGREAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|creatine biosynthesis',
    },
    {
        BioCyc_ID: 'METH-ACETATE-PWY',
        Common_Name: 'methanogenesis from acetate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|METH-ACETATE-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from acetate',
    },
    {
        BioCyc_ID: 'PWY-6971',
        Common_Name: 'oleandomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-6971',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|oleandomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6257',
        Common_Name: 'curcumene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|curcumene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8283',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (generic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8283',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (generic)',
    },
    {
        BioCyc_ID: 'PWY-8283',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (generic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8283',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (generic)',
    },
    {
        BioCyc_ID: 'PWY-8283',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (generic)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8283',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (generic)',
    },
    {
        BioCyc_ID: 'PWY-5729',
        Common_Name: 'vestitol and sativan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-5729',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|vestitol and sativan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6327',
        Common_Name: 'tetrathionate oxidation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|Tetrathionate-Oxidation|PWY-6327',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Tetrathionate Oxidation|tetrathionate oxidation',
    },
    {
        BioCyc_ID: 'PWY-6327',
        Common_Name: 'tetrathionate oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Tetrathionate-Oxidation|PWY-6327',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Tetrathionate Oxidation|tetrathionate oxidation',
    },
    {
        BioCyc_ID: 'PWY-6567',
        Common_Name: 'chondroitin sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6567',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|chondroitin sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6567',
        Common_Name: 'chondroitin sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6567',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|chondroitin sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-4',
        Common_Name: 'cholesterol biosynthesis III (via desmosterol)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY66-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|cholesterol biosynthesis III (via desmosterol)',
    },
    {
        BioCyc_ID: 'PWY66-4',
        Common_Name: 'cholesterol biosynthesis III (via desmosterol)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY66-4',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|cholesterol biosynthesis III (via desmosterol)',
    },
    {
        BioCyc_ID: 'PWY-2722',
        Common_Name: 'trehalose degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Trehalose-Degradation|PWY-2722',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Trehalose Degradation|trehalose degradation IV',
    },
    {
        BioCyc_ID: 'PWY-8047',
        Common_Name: 'bryostatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8047',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|bryostatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7256',
        Common_Name: 'cyanidin diglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7256',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|cyanidin diglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-7256',
        Common_Name: 'cyanidin diglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7256',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|cyanidin diglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-5918',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from glutamate',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY-5918',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|superpathway of heme <i>b</i> biosynthesis from glutamate',
    },
    {
        BioCyc_ID: 'PWY-5918',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from glutamate',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY-5918',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|superpathway of heme <i>b</i> biosynthesis from glutamate',
    },
    {
        BioCyc_ID: 'PWY-5918',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from glutamate',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5918',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of heme <i>b</i> biosynthesis from glutamate',
    },
    {
        BioCyc_ID: 'PWY-6855',
        Common_Name: 'chitin degradation I (archaea)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Chitin-Degradation|PWY-6855',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Chitin Degradation|chitin degradation I (archaea)',
    },
    {
        BioCyc_ID: 'PWY-6855',
        Common_Name: 'chitin degradation I (archaea)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Chitin-Degradation|PWY-6855',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Chitin Degradation|chitin degradation I (archaea)',
    },
    {
        BioCyc_ID: 'PWY-6855',
        Common_Name: 'chitin degradation I (archaea)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6855',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|chitin degradation I (archaea)',
    },
    {
        BioCyc_ID: 'PWY-6871',
        Common_Name: '3-methylbutanol biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|PWY-6871',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|3-methylbutanol biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-5209',
        Common_Name: 'methyl-coenzyme M oxidation to CO<sub>2</sub>',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-5209',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methyl-coenzyme M oxidation to CO<sub>2</sub>',
    },
    {
        BioCyc_ID: 'P164-PWY',
        Common_Name: 'purine nucleobases degradation I (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|P164-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|purine nucleobases degradation I (anaerobic)',
    },
    {
        BioCyc_ID: 'P164-PWY',
        Common_Name: 'purine nucleobases degradation I (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|P164-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|purine nucleobases degradation I (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7106',
        Common_Name: 'erythromycin D biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7106',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|erythromycin D biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6271',
        Common_Name: 'eudesmol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6271',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|eudesmol biosynthesis',
    },
    {
        BioCyc_ID: 'PWYI-14',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-IV-A-Biosynthesis|PWYI-14',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWYI-14',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWYI-14',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWYI-14',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWYI-14',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY-5061',
        Common_Name: "6,7,4'-trihydroxyisoflavone biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-5061',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|6,7,4'-trihydroxyisoflavone biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6047',
        Common_Name: 'dimethyl sulfide degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Dimethylsulfide-Degradation|PWY-6047',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Dimethylsulfide Degradation|dimethyl sulfide degradation I',
    },
    {
        BioCyc_ID: 'GLYCOGENSYNTH-PWY',
        Common_Name: 'glycogen biosynthesis I (from ADP-D-Glucose)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|GLYCOGEN-BIOSYN|GLYCOGENSYNTH-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycogen and Starch Biosynthesis|glycogen biosynthesis I (from ADP-D-Glucose)',
    },
    {
        BioCyc_ID: 'GLYCOGENSYNTH-PWY',
        Common_Name: 'glycogen biosynthesis I (from ADP-D-Glucose)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|GLYCOGEN-BIOSYN|GLYCOGENSYNTH-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycogen and Starch Biosynthesis|glycogen biosynthesis I (from ADP-D-Glucose)',
    },
    {
        BioCyc_ID: 'ANAEROFRUCAT-PWY',
        Common_Name: 'homolactic fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-Lactate|ANAEROFRUCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Lactate|homolactic fermentation',
    },
    {
        BioCyc_ID: 'ANAEROFRUCAT-PWY',
        Common_Name: 'homolactic fermentation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ANAEROFRUCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|homolactic fermentation',
    },
    {
        BioCyc_ID: 'PWY0-1466',
        Common_Name: 'trehalose degradation VI (periplasmic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Trehalose-Degradation|PWY0-1466',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Trehalose Degradation|trehalose degradation VI (periplasmic)',
    },
    {
        BioCyc_ID: 'PWY-6312',
        Common_Name: 'aloin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-6312',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|aloin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5139',
        Common_Name: 'pelargonidin conjugates biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5139',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|pelargonidin conjugates biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5139',
        Common_Name: 'pelargonidin conjugates biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5139',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|pelargonidin conjugates biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6165',
        Common_Name: 'chorismate biosynthesis II (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Chorismate-Biosynthesis|PWY-6165',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Chorismate Biosynthesis|chorismate biosynthesis II (archaea)',
    },
    {
        BioCyc_ID: 'PWY-6165',
        Common_Name: 'chorismate biosynthesis II (archaea)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6165',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|chorismate biosynthesis II (archaea)',
    },
    {
        BioCyc_ID: 'PWY-7822',
        Common_Name: 'chitin degradation III (Serratia)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Chitin-Degradation|PWY-7822',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Chitin Degradation|chitin degradation III (Serratia)',
    },
    {
        BioCyc_ID: 'PWY-7822',
        Common_Name: 'chitin degradation III (Serratia)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Chitin-Degradation|PWY-7822',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Chitin Degradation|chitin degradation III (Serratia)',
    },
    {
        BioCyc_ID: 'PWY-7822',
        Common_Name: 'chitin degradation III (Serratia)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-7822',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|chitin degradation III (Serratia)',
    },
    {
        BioCyc_ID: 'PWY-6730',
        Common_Name: 'methylhalides biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|PWY-6730',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|methylhalides biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-5250',
        Common_Name: 'methanogenesis from trimethylamine',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-5250',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from trimethylamine',
    },
    {
        BioCyc_ID: 'PWY-7383',
        Common_Name: 'anaerobic energy metabolism (invertebrates, cytosol)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|PWY-7383',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|anaerobic energy metabolism (invertebrates, cytosol)',
    },
    {
        BioCyc_ID: 'PWY-7421',
        Common_Name: 'narbomycin, pikromycin and novapikromycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-7421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|narbomycin, pikromycin and novapikromycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6650',
        Common_Name: 'juvenile hormone III biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|JH-III-Biosynthesis|PWY-6650',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|juvenile hormone III biosynthesis|juvenile hormone III biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6650',
        Common_Name: 'juvenile hormone III biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|JH-III-Biosynthesis|PWY-6650',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|juvenile hormone III biosynthesis|juvenile hormone III biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6059',
        Common_Name: 'dimethyl sulfide degradation II (oxidation)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Dimethylsulfide-Degradation|PWY-6059',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Dimethylsulfide Degradation|dimethyl sulfide degradation II (oxidation)',
    },
    {
        BioCyc_ID: 'PWY-8245',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8245',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8245',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8245',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8245',
        Common_Name: 'lipid IV<sub>A</sub> biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-IV-A-Biosynthesis|PWY-8245',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid IV<sub>A</sub> Biosynthesis|lipid IV<sub>A</sub> biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-5825',
        Common_Name: 'dalpatein and dalnigrein biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-5825',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|dalpatein and dalnigrein biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7900',
        Common_Name: 'glycogen biosynthesis III (from &alpha;-maltose 1-phosphate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|GLYCOGEN-BIOSYN|PWY-7900',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycogen and Starch Biosynthesis|glycogen biosynthesis III (from &alpha;-maltose 1-phosphate)',
    },
    {
        BioCyc_ID: 'PWY-7900',
        Common_Name: 'glycogen biosynthesis III (from &alpha;-maltose 1-phosphate)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|GLYCOGEN-BIOSYN|PWY-7900',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycogen and Starch Biosynthesis|glycogen biosynthesis III (from &alpha;-maltose 1-phosphate)',
    },
    {
        BioCyc_ID: 'ERGOSTEROL-SYN-PWY',
        Common_Name: 'superpathway of ergosterol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|Ergosterol-Biosynthesis|ERGOSTEROL-SYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|Ergosterol Biosynthesis|superpathway of ergosterol biosynthesis I',
    },
    {
        BioCyc_ID: 'ERGOSTEROL-SYN-PWY',
        Common_Name: 'superpathway of ergosterol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ERGOSTEROL-SYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of ergosterol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-2721',
        Common_Name: 'trehalose degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Trehalose-Degradation|PWY-2721',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Trehalose Degradation|trehalose degradation III',
    },
    {
        BioCyc_ID: 'PWY-6418',
        Common_Name: '4-hydroxycoumarin and dicoumarol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|PWY-6418',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|4-hydroxycoumarin and dicoumarol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6418',
        Common_Name: '4-hydroxycoumarin and dicoumarol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-6418',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|4-hydroxycoumarin and dicoumarol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5268',
        Common_Name: 'salvianin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5268',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|salvianin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5268',
        Common_Name: 'salvianin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5268',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|salvianin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6446',
        Common_Name: 'benzoate biosynthesis III (CoA-dependent, non-&beta;-oxidative)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Benzoate-Biosynthesis|PWY-6446',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Benzoate Biosynthesis|benzoate biosynthesis III (CoA-dependent, non-&beta;-oxidative)',
    },
    {
        BioCyc_ID: 'PWY-6446',
        Common_Name: 'benzoate biosynthesis III (CoA-dependent, non-&beta;-oxidative)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6446',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|benzoate biosynthesis III (CoA-dependent, non-&beta;-oxidative)',
    },
    {
        BioCyc_ID: 'PWY-7118',
        Common_Name: 'chitin deacetylation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Chitin-Degradation|PWY-7118',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Chitin Degradation|chitin deacetylation',
    },
    {
        BioCyc_ID: 'PWY-7118',
        Common_Name: 'chitin deacetylation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Chitin-Degradation|PWY-7118',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Chitin Degradation|chitin deacetylation',
    },
    {
        BioCyc_ID: 'PWY-8249',
        Common_Name: 'xanthommatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|PWY-8249',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|xanthommatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8107',
        Common_Name: 'methanogenesis from glycine betaine',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-8107',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from glycine betaine',
    },
    {
        BioCyc_ID: 'PWY-5497',
        Common_Name: 'purine nucleobases degradation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|PWY-5497',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|purine nucleobases degradation II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5497',
        Common_Name: 'purine nucleobases degradation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|PWY-5497',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|purine nucleobases degradation II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7650',
        Common_Name: 'echinocandin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|LIPOPEPTIDE-BIOSYNTHESIS|PWY-7650',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Lipopeptide Biosynthesis|echinocandin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6575',
        Common_Name: 'juvenile hormone III biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|JH-III-Biosynthesis|PWY-6575',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|juvenile hormone III biosynthesis|juvenile hormone III biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6575',
        Common_Name: 'juvenile hormone III biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|JH-III-Biosynthesis|PWY-6575',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|juvenile hormone III biosynthesis|juvenile hormone III biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6677',
        Common_Name: 'thiosulfate oxidation IV (multienzyme complex)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Oxidation|PWY-6677',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Oxidation|thiosulfate oxidation IV (multienzyme complex)',
    },
    {
        BioCyc_ID: 'PWY-7145',
        Common_Name: 'genistin gentiobioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-7145',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|genistin gentiobioside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-622',
        Common_Name: 'starch biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|GLYCOGEN-BIOSYN|PWY-622',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycogen and Starch Biosynthesis|starch biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-622',
        Common_Name: 'starch biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|GLYCOGEN-BIOSYN|PWY-622',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycogen and Starch Biosynthesis|starch biosynthesis',
    },
    {
        BioCyc_ID: 'HSERMETANA-PWY',
        Common_Name: 'L-methionine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-De-novo-Biosynthesis|HSERMETANA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine <i>De Novo</i> Biosynthesis|L-methionine biosynthesis III',
    },
    {
        BioCyc_ID: 'HSERMETANA-PWY',
        Common_Name: 'L-methionine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|HSERMETANA-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-methionine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY0-1182',
        Common_Name: 'trehalose degradation II (cytosolic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Trehalose-Degradation|PWY0-1182',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Trehalose Degradation|trehalose degradation II (cytosolic)',
    },
    {
        BioCyc_ID: 'PWY-7692',
        Common_Name: 'bikaverin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7692',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|bikaverin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7280',
        Common_Name: 'ternatin C3 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7280',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|ternatin C3 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7280',
        Common_Name: 'ternatin C3 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7280',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|ternatin C3 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6590',
        Common_Name: 'superpathway of <i>Clostridium acetobutylicum</i> acidogenic fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|PWY-6590',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|superpathway of <i>Clostridium acetobutylicum</i> acidogenic fermentation',
    },
    {
        BioCyc_ID: 'PWY-6590',
        Common_Name: 'superpathway of <i>Clostridium acetobutylicum</i> acidogenic fermentation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6590',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of <i>Clostridium acetobutylicum</i> acidogenic fermentation',
    },
    {
        BioCyc_ID: 'PWY-7248',
        Common_Name: 'pectin degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Pectin-Degradation|PWY-7248',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Pectin Degradation|pectin degradation II',
    },
    {
        BioCyc_ID: 'PWY-7248',
        Common_Name: 'pectin degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Pectin-Degradation|PWY-7248',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Pectin Degradation|pectin degradation II',
    },
    {
        BioCyc_ID: 'PWY-6481',
        Common_Name: 'L-dopa and L-dopachrome biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|PWY-6481',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|L-dopa and L-dopachrome biosynthesis',
    },
    {
        BioCyc_ID: 'HEME-BIOSYNTHESIS-II-1',
        Common_Name: 'heme <i>b</i> biosynthesis V (aerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|HEME-BIOSYNTHESIS-II-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis V (aerobic)',
    },
    {
        BioCyc_ID: 'HEME-BIOSYNTHESIS-II-1',
        Common_Name: 'heme <i>b</i> biosynthesis V (aerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|HEME-BIOSYNTHESIS-II-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis V (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-7796',
        Common_Name: 'pentose phosphate pathway (oxidative branch) II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Pentose-Phosphate-Cycle|PWY-7796',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Pentose Phosphate Pathways|pentose phosphate pathway (oxidative branch) II',
    },
    {
        BioCyc_ID: 'PWY-7407',
        Common_Name: 'aurachin A, B, C and D biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Aurachin-Biosynthesis|PWY-7407',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Aurachin Biosynthesis|aurachin A, B, C and D biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5296',
        Common_Name: 'thiosulfate oxidation III (multienzyme complex)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Oxidation|PWY-5296',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Oxidation|thiosulfate oxidation III (multienzyme complex)',
    },
    {
        BioCyc_ID: 'PWY-6914',
        Common_Name: 'sophoraflavanone G biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PRENYLFLAVONOID-SYN|PWY-6914',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Prenylflavonoid Biosynthesis|sophoraflavanone G biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5067',
        Common_Name: 'glycogen biosynthesis II (from UDP-D-Glucose)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|GLYCOGEN-BIOSYN|PWY-5067',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycogen and Starch Biosynthesis|glycogen biosynthesis II (from UDP-D-Glucose)',
    },
    {
        BioCyc_ID: 'PWY-5067',
        Common_Name: 'glycogen biosynthesis II (from UDP-D-Glucose)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|GLYCOGEN-BIOSYN|PWY-5067',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycogen and Starch Biosynthesis|glycogen biosynthesis II (from UDP-D-Glucose)',
    },
    {
        BioCyc_ID: 'P381-PWY',
        Common_Name: 'adenosylcobalamin biosynthesis II (aerobic)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|De-Novo-Adenosylcobalamin-Biosynthesis|P381-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|Adenosylcobalamin <i>de novo</i> Biosynthesis|adenosylcobalamin biosynthesis II (aerobic)',
    },
    {
        BioCyc_ID: 'P381-PWY',
        Common_Name: 'adenosylcobalamin biosynthesis II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P381-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|adenosylcobalamin biosynthesis II (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-2723',
        Common_Name: 'trehalose degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Trehalose-Degradation|PWY-2723',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Trehalose Degradation|trehalose degradation V',
    },
    {
        BioCyc_ID: 'PWY-8327',
        Common_Name: 'atranorin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8327',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|atranorin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5295',
        Common_Name: 'ternatin C5 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5295',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|ternatin C5 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5295',
        Common_Name: 'ternatin C5 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5295',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|ternatin C5 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6887',
        Common_Name: 'kauralexin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-6887',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|kauralexin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6887',
        Common_Name: 'kauralexin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6887',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|kauralexin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6887',
        Common_Name: 'kauralexin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-6887',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|kauralexin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7246',
        Common_Name: 'pectin degradation I',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-7246',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|pectin degradation I',
    },
    {
        BioCyc_ID: 'PWY-7246',
        Common_Name: 'pectin degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Pectin-Degradation|PWY-7246',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Pectin Degradation|pectin degradation I',
    },
    {
        BioCyc_ID: 'PWY-7246',
        Common_Name: 'pectin degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Pectin-Degradation|PWY-7246',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Pectin Degradation|pectin degradation I',
    },
    {
        BioCyc_ID: 'HEME-BIOSYNTHESIS-II',
        Common_Name: 'heme <i>b</i> biosynthesis I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|HEME-BIOSYNTHESIS-II',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis I (aerobic)',
    },
    {
        BioCyc_ID: 'HEME-BIOSYNTHESIS-II',
        Common_Name: 'heme <i>b</i> biosynthesis I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|HEME-BIOSYNTHESIS-II',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-8157',
        Common_Name: 'avenanthramide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-8157',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|avenanthramide biosynthesis',
    },
    {
        BioCyc_ID: 'NONOXIPENT-PWY',
        Common_Name: 'pentose phosphate pathway (non-oxidative branch) I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Pentose-Phosphate-Cycle|NONOXIPENT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Pentose Phosphate Pathways|pentose phosphate pathway (non-oxidative branch) I',
    },
    {
        BioCyc_ID: 'PWY-7405',
        Common_Name: 'aurachin RE biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Aurachin-Biosynthesis|PWY-7405',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Aurachin Biosynthesis|aurachin RE biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5329',
        Common_Name: 'L-cysteine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|CYSTEINE-DEG|PWY-5329',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-cysteine Degradation|L-cysteine degradation III',
    },
    {
        BioCyc_ID: 'PWY-5329',
        Common_Name: 'L-cysteine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Hydrogen-Sulfide-Biosynthesis|PWY-5329',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Hydrogen Sulfide Biosynthesis|L-cysteine degradation III',
    },
    {
        BioCyc_ID: 'PWY-5135',
        Common_Name: 'xanthohumol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PRENYLFLAVONOID-SYN|PWY-5135',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Prenylflavonoid Biosynthesis|xanthohumol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6082',
        Common_Name: 'alginate biosynthesis II (bacterial)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Alginate-Biosynthesis|PWY-6082',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Alginate Biosynthesis|alginate biosynthesis II (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-6082',
        Common_Name: 'alginate biosynthesis II (bacterial)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Alginate-Biosynthesis|PWY-6082',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Alginate Biosynthesis|alginate biosynthesis II (bacterial)',
    },
    {
        BioCyc_ID: 'PRPP-PWY',
        Common_Name: 'superpathway of histidine, purine, and pyrimidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PRPP-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of histidine, purine, and pyrimidine biosynthesis',
    },
    {
        BioCyc_ID: 'DARABCAT-PWY',
        Common_Name: 'D-arabinose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|D-Arabinose-Degradation|DARABCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-arabinose Degradation|D-arabinose degradation I',
    },
    {
        BioCyc_ID: 'PWY-7452',
        Common_Name: 'cyanidin dimalonylglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7452',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|cyanidin dimalonylglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7452',
        Common_Name: 'cyanidin dimalonylglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7452',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|cyanidin dimalonylglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7513',
        Common_Name: 'flaviolin dimer and mompain biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7513',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|flaviolin dimer and mompain biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7003',
        Common_Name: 'glycerol degradation to butanol',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-7003',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|glycerol degradation to butanol',
    },
    {
        BioCyc_ID: 'PWY-7003',
        Common_Name: 'glycerol degradation to butanol',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7003',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|glycerol degradation to butanol',
    },
    {
        BioCyc_ID: 'PWY-1081',
        Common_Name: 'pectin degradation III',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-1081',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|pectin degradation III',
    },
    {
        BioCyc_ID: 'PWY-1081',
        Common_Name: 'pectin degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Pectin-Degradation|PWY-1081',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Pectin Degradation|pectin degradation III',
    },
    {
        BioCyc_ID: 'PWY-1081',
        Common_Name: 'pectin degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Pectin-Degradation|PWY-1081',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Pectin Degradation|pectin degradation III',
    },
    {
        BioCyc_ID: 'PWY-7766',
        Common_Name: 'heme <i>b</i> biosynthesis IV (Gram-positive bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY-7766',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis IV (Gram-positive bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7766',
        Common_Name: 'heme <i>b</i> biosynthesis IV (Gram-positive bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY-7766',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis IV (Gram-positive bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6673',
        Common_Name: 'caffeoylglucarate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-6673',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|caffeoylglucarate biosynthesis',
    },
    {
        BioCyc_ID: 'P21-PWY',
        Common_Name: 'pentose phosphate pathway (partial)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Pentose-Phosphate-Cycle|P21-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Pentose Phosphate Pathways|pentose phosphate pathway (partial)',
    },
    {
        BioCyc_ID: 'PWY-7014',
        Common_Name: 'paromamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Paromamine-Biosynthesis|PWY-7014',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Paromamine Biosynthesis|paromamine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5279',
        Common_Name: 'sulfite oxidation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfite-Oxidation|PWY-5279',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfite Oxidation|sulfite oxidation II',
    },
    {
        BioCyc_ID: 'PWY-6036',
        Common_Name: 'cardenolide glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-6036',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|cardenolide glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6036',
        Common_Name: 'cardenolide glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-6036',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|cardenolide glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5161',
        Common_Name: "6'-deoxychalcone metabolism",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|CHALCONE-SYN|PWY-5161',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Chalcone Biosynthesis|6'-deoxychalcone metabolism",
    },
    {
        BioCyc_ID: 'PWY-6073',
        Common_Name: 'alginate biosynthesis I (algal)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Alginate-Biosynthesis|PWY-6073',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Alginate Biosynthesis|alginate biosynthesis I (algal)',
    },
    {
        BioCyc_ID: 'PWY-6073',
        Common_Name: 'alginate biosynthesis I (algal)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Alginate-Biosynthesis|PWY-6073',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Alginate Biosynthesis|alginate biosynthesis I (algal)',
    },
    {
        BioCyc_ID: 'PWY-5134',
        Common_Name: 'superpathway of bitter acids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-5134',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|superpathway of bitter acids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5134',
        Common_Name: 'superpathway of bitter acids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5134',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of bitter acids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5134',
        Common_Name: 'superpathway of bitter acids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|TERPENOPHENOLICS-SYN|PWY-5134',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenophenolic Biosynthesis|superpathway of bitter acids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4961',
        Common_Name: '&beta;-pyrazole-1-ylalanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|NON-PROTEIN-AMINO-ACID-SYN|PWY-4961',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Non-Protein Amino Acid Biosynthesis|&beta;-pyrazole-1-ylalanine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8331',
        Common_Name: 'D-arabinose degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|D-Arabinose-Degradation|PWY-8331',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-arabinose Degradation|D-arabinose degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7702',
        Common_Name: 'sch210971 and sch210972 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7702',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|sch210971 and sch210972 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7702',
        Common_Name: 'sch210971 and sch210972 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7702',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|sch210971 and sch210972 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7211',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-7211',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-7211',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-7211',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7211',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7211',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6789',
        Common_Name: '(1,3)-&beta;-D-xylan degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6789',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|(1,3)-&beta;-D-xylan degradation',
    },
    {
        BioCyc_ID: 'PWY-6789',
        Common_Name: '(1,3)-&beta;-D-xylan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Xylan-Degradation|PWY-6789',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Xylan Degradation|(1,3)-&beta;-D-xylan degradation',
    },
    {
        BioCyc_ID: 'PWY-6789',
        Common_Name: '(1,3)-&beta;-D-xylan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Xylan-Degradation|PWY-6789',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Xylan Degradation|(1,3)-&beta;-D-xylan degradation',
    },
    {
        BioCyc_ID: 'HEMESYN2-PWY',
        Common_Name: 'heme <i>b</i> biosynthesis II (oxygen-independent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|HEMESYN2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis II (oxygen-independent)',
    },
    {
        BioCyc_ID: 'HEMESYN2-PWY',
        Common_Name: 'heme <i>b</i> biosynthesis II (oxygen-independent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|HEMESYN2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis II (oxygen-independent)',
    },
    {
        BioCyc_ID: 'PWY-7468',
        Common_Name: 'benzoyl-&beta;-<i>D</i>-glucopyranose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-7468',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|benzoyl-&beta;-<i>D</i>-glucopyranose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8178',
        Common_Name: 'pentose phosphate pathway (non-oxidative branch) II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Pentose-Phosphate-Cycle|PWY-8178',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Pentose Phosphate Pathways|pentose phosphate pathway (non-oxidative branch) II',
    },
    {
        BioCyc_ID: 'PWY-7022',
        Common_Name: 'paromamine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Paromamine-Biosynthesis|PWY-7022',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Paromamine Biosynthesis|paromamine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5326',
        Common_Name: 'sulfite oxidation IV (sulfite oxidase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfite-Oxidation|PWY-5326',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfite Oxidation|sulfite oxidation IV (sulfite oxidase)',
    },
    {
        BioCyc_ID: 'PWY-6132',
        Common_Name: 'lanosterol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-6132',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|lanosterol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7160',
        Common_Name: 'polymethylated myricetin biosynthesis (tomato)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7160',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|polymethylated myricetin biosynthesis (tomato)',
    },
    {
        BioCyc_ID: 'PWY-8244',
        Common_Name: '<i>Escherichia coli</i> serotype O:177 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8244',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:177 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8244',
        Common_Name: '<i>Escherichia coli</i> serotype O:177 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8244',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:177 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8244',
        Common_Name: '<i>Escherichia coli</i> serotype O:177 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8244',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:177 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8244',
        Common_Name: '<i>Escherichia coli</i> serotype O:177 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8244',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:177 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5310',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from delphinidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5310',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|superpathway of anthocyanin biosynthesis (from delphinidin 3-<i>O</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5310',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from delphinidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5310',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|superpathway of anthocyanin biosynthesis (from delphinidin 3-<i>O</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5310',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from delphinidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5310',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of anthocyanin biosynthesis (from delphinidin 3-<i>O</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5021',
        Common_Name: 'willardiine and isowillardiine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|NON-PROTEIN-AMINO-ACID-SYN|PWY-5021',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Non-Protein Amino Acid Biosynthesis|willardiine and isowillardiine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5519',
        Common_Name: 'D-arabinose degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|D-Arabinose-Degradation|PWY-5519',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-arabinose Degradation|D-arabinose degradation III',
    },
    {
        BioCyc_ID: 'PWY-12',
        Common_Name: 'pentaketide chromone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-12',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|pentaketide chromone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7341',
        Common_Name: 'superpathway of hyoscyamine (atropine) and scopolamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7341',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of hyoscyamine (atropine) and scopolamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7341',
        Common_Name: 'superpathway of hyoscyamine (atropine) and scopolamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TROPANE-ALKALOIDS|PWY-7341',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Tropane Alkaloid Biosynthesis|superpathway of hyoscyamine (atropine) and scopolamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6717',
        Common_Name: '(1,4)-&beta;-D-xylan degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6717',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|(1,4)-&beta;-D-xylan degradation',
    },
    {
        BioCyc_ID: 'PWY-6717',
        Common_Name: '(1,4)-&beta;-D-xylan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Xylan-Degradation|PWY-6717',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Xylan Degradation|(1,4)-&beta;-D-xylan degradation',
    },
    {
        BioCyc_ID: 'PWY-6717',
        Common_Name: '(1,4)-&beta;-D-xylan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Xylan-Degradation|PWY-6717',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Xylan Degradation|(1,4)-&beta;-D-xylan degradation',
    },
    {
        BioCyc_ID: 'P4-PWY',
        Common_Name: 'superpathway of L-lysine, L-threonine and L-methionine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|P4-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|superpathway of L-lysine, L-threonine and L-methionine biosynthesis I',
    },
    {
        BioCyc_ID: 'P4-PWY',
        Common_Name: 'superpathway of L-lysine, L-threonine and L-methionine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P4-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-lysine, L-threonine and L-methionine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7552',
        Common_Name: 'heme <i>b</i> biosynthesis III (from siroheme)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY-7552',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis III (from siroheme)',
    },
    {
        BioCyc_ID: 'PWY-7552',
        Common_Name: 'heme <i>b</i> biosynthesis III (from siroheme)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY-7552',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|heme <i>b</i> biosynthesis III (from siroheme)',
    },
    {
        BioCyc_ID: 'PWY-7682',
        Common_Name: 'arabidopyrone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-7682',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|arabidopyrone biosynthesis',
    },
    {
        BioCyc_ID: 'OXIDATIVEPENT-PWY',
        Common_Name: 'pentose phosphate pathway (oxidative branch) I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Pentose-Phosphate-Cycle|OXIDATIVEPENT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Pentose Phosphate Pathways|pentose phosphate pathway (oxidative branch) I',
    },
    {
        BioCyc_ID: 'PWY-3022',
        Common_Name: 'linamarin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|CYANOGENIC-GLUCOSIDE-SYN|PWY-3022',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Cyanogenic Glucoside Biosynthesis|linamarin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5278',
        Common_Name: 'sulfite oxidation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfite-Oxidation|PWY-5278',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfite Oxidation|sulfite oxidation III',
    },
    {
        BioCyc_ID: 'PWY-7455',
        Common_Name: 'allopregnanolone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-7455',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|allopregnanolone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5391',
        Common_Name: 'syringetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-5391',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|syringetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8195',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8195',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8195',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8195',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8195',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8195',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8195',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8195',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5655',
        Common_Name: 'L-tryptophan degradation IX',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5655',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-tryptophan degradation IX',
    },
    {
        BioCyc_ID: 'PWY-5655',
        Common_Name: 'L-tryptophan degradation IX',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-5655',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation IX',
    },
    {
        BioCyc_ID: 'PWY-1',
        Common_Name: 'lathyrine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|NON-PROTEIN-AMINO-ACID-SYN|PWY-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Non-Protein Amino Acid Biosynthesis|lathyrine biosynthesis',
    },
    {
        BioCyc_ID: 'DARABCATK12-PWY',
        Common_Name: 'D-arabinose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|D-Arabinose-Degradation|DARABCATK12-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-arabinose Degradation|D-arabinose degradation II',
    },
    {
        BioCyc_ID: 'PWY-7565',
        Common_Name: 'aspyridone A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7565',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|aspyridone A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7509',
        Common_Name: 'cardiolipin and phosphatidylethanolamine biosynthesis (<i>Xanthomonas</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Cardiolipin-Biosynthesis|PWY-7509',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Cardiolipin Biosynthesis|cardiolipin and phosphatidylethanolamine biosynthesis (<i>Xanthomonas</i>)',
    },
    {
        BioCyc_ID: 'PWY-7509',
        Common_Name: 'cardiolipin and phosphatidylethanolamine biosynthesis (<i>Xanthomonas</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylethanolamineBiosynthesis|PWY-7509',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylethanolamine Biosynthesis|cardiolipin and phosphatidylethanolamine biosynthesis (<i>Xanthomonas</i>)',
    },
    {
        BioCyc_ID: 'PWY-7509',
        Common_Name: 'cardiolipin and phosphatidylethanolamine biosynthesis (<i>Xanthomonas</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7509',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|cardiolipin and phosphatidylethanolamine biosynthesis (<i>Xanthomonas</i>)',
    },
    {
        BioCyc_ID: 'PWY-6788',
        Common_Name: 'cellulose degradation II (fungi)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Cellulose-Degradation|PWY-6788',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Cellulose Degradation|cellulose degradation II (fungi)',
    },
    {
        BioCyc_ID: 'PWY-6788',
        Common_Name: 'cellulose degradation II (fungi)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Cellulose-Degradation|PWY-6788',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Cellulose Degradation|cellulose degradation II (fungi)',
    },
    {
        BioCyc_ID: 'PWY-821',
        Common_Name: 'superpathway of sulfur amino acid biosynthesis (<i>Saccharomyces cerevisiae</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|PWY-821',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|superpathway of sulfur amino acid biosynthesis (<i>Saccharomyces cerevisiae</i>)',
    },
    {
        BioCyc_ID: 'PWY-821',
        Common_Name: 'superpathway of sulfur amino acid biosynthesis (<i>Saccharomyces cerevisiae</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-821',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sulfur amino acid biosynthesis (<i>Saccharomyces cerevisiae</i>)',
    },
    {
        BioCyc_ID: 'PWY-8104',
        Common_Name: 'yangonin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-8104',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|yangonin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7095',
        Common_Name: '3,4-dihydroxymandelonitrile &beta;-<i>D</i>-glucose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|CYANOGENIC-GLUCOSIDE-SYN|PWY-7095',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Cyanogenic Glucoside Biosynthesis|3,4-dihydroxymandelonitrile &beta;-<i>D</i>-glucose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7101',
        Common_Name: '5-deoxystrigol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|PWY-7101',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|5-deoxystrigol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1264',
        Common_Name: 'taurine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Taurine-degradation|PWY-1264',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Taurine Degradation|taurine degradation II',
    },
    {
        BioCyc_ID: 'PWY-1264',
        Common_Name: 'taurine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Taurine-degradation|PWY-1264',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Taurine Degradation|taurine degradation II',
    },
    {
        BioCyc_ID: 'PWY-8027',
        Common_Name: 'parkeol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-8027',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|parkeol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7168',
        Common_Name: 'flavonol acylglucoside biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7168',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|flavonol acylglucoside biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8220',
        Common_Name: '<i>Escherichia coli</i> serotype O:117 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8220',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:117 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8220',
        Common_Name: '<i>Escherichia coli</i> serotype O:117 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8220',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:117 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8220',
        Common_Name: '<i>Escherichia coli</i> serotype O:117 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8220',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:117 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8220',
        Common_Name: '<i>Escherichia coli</i> serotype O:117 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8220',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:117 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6113',
        Common_Name: 'superpathway of mycolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY-6113',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|superpathway of mycolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6113',
        Common_Name: 'superpathway of mycolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6113',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of mycolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5',
        Common_Name: 'canavanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|NON-PROTEIN-AMINO-ACID-SYN|PWY-5',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Non-Protein Amino Acid Biosynthesis|canavanine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5515',
        Common_Name: 'L-arabinose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-Arabinose-Degradation|PWY-5515',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-arabinose Degradation|L-arabinose degradation II',
    },
    {
        BioCyc_ID: 'PWY-8036',
        Common_Name: 'chaxamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8036',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|chaxamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8036',
        Common_Name: 'chaxamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8036',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|chaxamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1298',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|PWY0-1298',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|superpathway of pyrimidine deoxyribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY0-1298',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-1298',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pyrimidine deoxyribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY-6784',
        Common_Name: 'cellulose and hemicellulose degradation (cellulolosome)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Cellulose-Degradation|PWY-6784',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Cellulose Degradation|cellulose and hemicellulose degradation (cellulolosome)',
    },
    {
        BioCyc_ID: 'PWY-6784',
        Common_Name: 'cellulose and hemicellulose degradation (cellulolosome)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Cellulose-Degradation|PWY-6784',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Cellulose Degradation|cellulose and hemicellulose degradation (cellulolosome)',
    },
    {
        BioCyc_ID: 'PWY-6784',
        Common_Name: 'cellulose and hemicellulose degradation (cellulolosome)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6784',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|cellulose and hemicellulose degradation (cellulolosome)',
    },
    {
        BioCyc_ID: 'COMPLETE-ARO-PWY',
        Common_Name: 'superpathway of aromatic amino acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|COMPLETE-ARO-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|superpathway of aromatic amino acid biosynthesis',
    },
    {
        BioCyc_ID: 'COMPLETE-ARO-PWY',
        Common_Name: 'superpathway of aromatic amino acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|COMPLETE-ARO-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of aromatic amino acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6268',
        Common_Name: 'adenosylcobalamin salvage from cobalamin',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-Salvage|PWY-6268',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobinamide Salvage|adenosylcobalamin salvage from cobalamin',
    },
    {
        BioCyc_ID: 'PWY-5882',
        Common_Name: 'epoxypseudoisoeugenol-2-methylbutanoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-5882',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|epoxypseudoisoeugenol-2-methylbutanoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-861',
        Common_Name: 'dhurrin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|CYANOGENIC-GLUCOSIDE-SYN|PWY-861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Cyanogenic Glucoside Biosynthesis|dhurrin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5983',
        Common_Name: 'trehalose biosynthesis VI',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Trehalose-biosynthesis|PWY-5983',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis VI',
    },
    {
        BioCyc_ID: 'PWY-5983',
        Common_Name: 'trehalose biosynthesis VI',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Trehalose-biosynthesis|PWY-5983',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis VI',
    },
    {
        BioCyc_ID: 'PWY-8252',
        Common_Name: '<i>Escherichia coli</i> serotype O:50 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8252',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:50 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8252',
        Common_Name: '<i>Escherichia coli</i> serotype O:50 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8252',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:50 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8252',
        Common_Name: '<i>Escherichia coli</i> serotype O:50 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8252',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:50 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8252',
        Common_Name: '<i>Escherichia coli</i> serotype O:50 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8252',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:50 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8238',
        Common_Name: '24-<i>epi</i>-campesterol, fucosterol, and clionasterol biosynthesis (diatoms)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-8238',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|24-<i>epi</i>-campesterol, fucosterol, and clionasterol biosynthesis (diatoms)',
    },
    {
        BioCyc_ID: 'PWY-8238',
        Common_Name: '24-<i>epi</i>-campesterol, fucosterol, and clionasterol biosynthesis (diatoms)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-8238',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|24-<i>epi</i>-campesterol, fucosterol, and clionasterol biosynthesis (diatoms)',
    },
    {
        BioCyc_ID: 'PWY-6199',
        Common_Name: 'quercetin sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-6199',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|quercetin sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'TAURINEDEG-PWY',
        Common_Name: 'taurine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Taurine-degradation|TAURINEDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Taurine Degradation|taurine degradation III',
    },
    {
        BioCyc_ID: 'TAURINEDEG-PWY',
        Common_Name: 'taurine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Taurine-degradation|TAURINEDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Taurine Degradation|taurine degradation III',
    },
    {
        BioCyc_ID: 'PWY-6277',
        Common_Name: 'superpathway of 5-aminoimidazole ribonucleotide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|AIR-Biosynthesis|PWY-6277',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|5-Aminoimidazole Ribonucleotide Biosynthesis|superpathway of 5-aminoimidazole ribonucleotide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6277',
        Common_Name: 'superpathway of 5-aminoimidazole ribonucleotide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6277',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of 5-aminoimidazole ribonucleotide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4985',
        Common_Name: 'mimosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|NON-PROTEIN-AMINO-ACID-SYN|PWY-4985',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Non-Protein Amino Acid Biosynthesis|mimosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8329',
        Common_Name: 'L-arabinose degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-Arabinose-Degradation|PWY-8329',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-arabinose Degradation|L-arabinose degradation V',
    },
    {
        BioCyc_ID: 'PWY-4922',
        Common_Name: '6-methoxymellein biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|COUMARIN-PHYTOALEXINS|PWY-4922',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Coumarin Phytoalexin Biosynthesis|6-methoxymellein biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4922',
        Common_Name: '6-methoxymellein biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-4922',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|6-methoxymellein biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4922',
        Common_Name: '6-methoxymellein biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-4922',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|6-methoxymellein biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4FS-5',
        Common_Name: 'superpathway of phosphatidylcholine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY4FS-5',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of phosphatidylcholine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6805',
        Common_Name: 'cellulose degradation I (cellulosome)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Cellulose-Degradation|PWY-6805',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Cellulose Degradation|cellulose degradation I (cellulosome)',
    },
    {
        BioCyc_ID: 'PWY-6805',
        Common_Name: 'cellulose degradation I (cellulosome)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Cellulose-Degradation|PWY-6805',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Cellulose Degradation|cellulose degradation I (cellulosome)',
    },
    {
        BioCyc_ID: 'PWY-6805',
        Common_Name: 'cellulose degradation I (cellulosome)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6805',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|cellulose degradation I (cellulosome)',
    },
    {
        BioCyc_ID: 'PWY-724',
        Common_Name: 'superpathway of L-lysine, L-threonine and L-methionine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|PWY-724',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|superpathway of L-lysine, L-threonine and L-methionine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-724',
        Common_Name: 'superpathway of L-lysine, L-threonine and L-methionine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-724',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-lysine, L-threonine and L-methionine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7974',
        Common_Name: 'cobalamin salvage (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-Salvage|PWY-7974',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobinamide Salvage|cobalamin salvage (eukaryotic)',
    },
    {
        BioCyc_ID: 'PWY-6835',
        Common_Name: '6-gingerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-6835',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|6-gingerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5084',
        Common_Name: '2-oxoglutarate decarboxylation to succinyl-CoA',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|PWY-5084',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|2-oxoglutarate decarboxylation to succinyl-CoA',
    },
    {
        BioCyc_ID: 'PWY-7088',
        Common_Name: 'taxiphyllin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|CYANOGENIC-GLUCOSIDE-SYN|PWY-7088',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Cyanogenic Glucoside Biosynthesis|taxiphyllin biosynthesis',
    },
    {
        BioCyc_ID: 'TREHALOSESYN-PWY',
        Common_Name: 'trehalose biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Trehalose-biosynthesis|TREHALOSESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis III',
    },
    {
        BioCyc_ID: 'TREHALOSESYN-PWY',
        Common_Name: 'trehalose biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Trehalose-biosynthesis|TREHALOSESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8198',
        Common_Name: '<i>Salmonella enterica</i> serotype O:2 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8198',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:2 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8198',
        Common_Name: '<i>Salmonella enterica</i> serotype O:2 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8198',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:2 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8198',
        Common_Name: '<i>Salmonella enterica</i> serotype O:2 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8198',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:2 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8198',
        Common_Name: '<i>Salmonella enterica</i> serotype O:2 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8198',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:2 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1263',
        Common_Name: 'taurine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Taurine-degradation|PWY-1263',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Taurine Degradation|taurine degradation I',
    },
    {
        BioCyc_ID: 'PWY-1263',
        Common_Name: 'taurine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Taurine-degradation|PWY-1263',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Taurine Degradation|taurine degradation I',
    },
    {
        BioCyc_ID: 'PWY-6032',
        Common_Name: 'digitoxigenin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-6032',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|digitoxigenin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7137',
        Common_Name: 'quercetin gentiotetraside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7137',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|quercetin gentiotetraside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6374',
        Common_Name: 'vibriobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6374',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|vibriobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6374',
        Common_Name: 'vibriobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6374',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|vibriobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8295',
        Common_Name: 'nitrite biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|NON-PROTEIN-AMINO-ACID-SYN|PWY-8295',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Non-Protein Amino Acid Biosynthesis|nitrite biosynthesis',
    },
    {
        BioCyc_ID: 'ARABCAT-PWY',
        Common_Name: 'L-arabinose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-Arabinose-Degradation|ARABCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-arabinose Degradation|L-arabinose degradation I',
    },
    {
        BioCyc_ID: 'PWY-8040',
        Common_Name: 'ansatrienin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8040',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|ansatrienin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8040',
        Common_Name: 'ansatrienin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8040',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|ansatrienin biosynthesis',
    },
    {
        BioCyc_ID: 'THREOCAT-PWY',
        Common_Name: 'superpathway of L-threonine metabolism',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|THREOCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-threonine metabolism',
    },
    {
        BioCyc_ID: 'THREOCAT-PWY',
        Common_Name: 'superpathway of L-threonine metabolism',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|THREONINE-DEG|THREOCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-threonine Degradation|superpathway of L-threonine metabolism',
    },
    {
        BioCyc_ID: 'PWY-6573',
        Common_Name: 'chondroitin sulfate degradation (metazoa)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6573',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|chondroitin sulfate degradation (metazoa)',
    },
    {
        BioCyc_ID: 'PWY-6573',
        Common_Name: 'chondroitin sulfate degradation (metazoa)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-6573',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|chondroitin sulfate degradation (metazoa)',
    },
    {
        BioCyc_ID: 'PWY-6573',
        Common_Name: 'chondroitin sulfate degradation (metazoa)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-6573',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|chondroitin sulfate degradation (metazoa)',
    },
    {
        BioCyc_ID: 'BRANCHED-CHAIN-AA-SYN-PWY',
        Common_Name: 'superpathway of branched chain amino acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|BRANCHED-CHAIN-AA-SYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|superpathway of branched chain amino acid biosynthesis',
    },
    {
        BioCyc_ID: 'BRANCHED-CHAIN-AA-SYN-PWY',
        Common_Name: 'superpathway of branched chain amino acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|BRANCHED-CHAIN-AA-SYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of branched chain amino acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7643',
        Common_Name: 'coniferyl alcohol 9-methyl ester biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-7643',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|coniferyl alcohol 9-methyl ester biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5046',
        Common_Name: '2-oxoisovalerate decarboxylation to isobutanoyl-CoA',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|PWY-5046',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|2-oxoisovalerate decarboxylation to isobutanoyl-CoA',
    },
    {
        BioCyc_ID: 'PWY-8081',
        Common_Name: 'leucine-derived hydroxynitrile glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|CYANOGENIC-GLUCOSIDE-SYN|PWY-8081',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Cyanogenic Glucoside Biosynthesis|leucine-derived hydroxynitrile glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2661',
        Common_Name: 'trehalose biosynthesis V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Trehalose-biosynthesis|PWY-2661',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis V',
    },
    {
        BioCyc_ID: 'PWY-2661',
        Common_Name: 'trehalose biosynthesis V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Trehalose-biosynthesis|PWY-2661',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis V',
    },
    {
        BioCyc_ID: 'PWY-8224',
        Common_Name: '<i>Escherichia coli</i> serotype O:157/<i>Salmonella enterica</i> serotype O:30 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8224',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:157/<i>Salmonella enterica</i> serotype O:30 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8224',
        Common_Name: '<i>Escherichia coli</i> serotype O:157/<i>Salmonella enterica</i> serotype O:30 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8224',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:157/<i>Salmonella enterica</i> serotype O:30 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8224',
        Common_Name: '<i>Escherichia coli</i> serotype O:157/<i>Salmonella enterica</i> serotype O:30 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8224',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:157/<i>Salmonella enterica</i> serotype O:30 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8224',
        Common_Name: '<i>Escherichia coli</i> serotype O:157/<i>Salmonella enterica</i> serotype O:30 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8224',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:157/<i>Salmonella enterica</i> serotype O:30 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-981',
        Common_Name: 'taurine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Taurine-degradation|PWY0-981',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Taurine Degradation|taurine degradation IV',
    },
    {
        BioCyc_ID: 'PWY0-981',
        Common_Name: 'taurine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Taurine-degradation|PWY0-981',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Taurine Degradation|taurine degradation IV',
    },
    {
        BioCyc_ID: 'PWY18C3-1',
        Common_Name: 'cholesterol biosynthesis (plants, early side-chain reductase)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY18C3-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|cholesterol biosynthesis (plants, early side-chain reductase)',
    },
    {
        BioCyc_ID: 'PWY-7448',
        Common_Name: 'galloylated catechin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7448',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|galloylated catechin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6544',
        Common_Name: 'superpathway of C28 brassinosteroid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6544',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of C28 brassinosteroid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7295',
        Common_Name: 'L-arabinose degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-Arabinose-Degradation|PWY-7295',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-arabinose Degradation|L-arabinose degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7620',
        Common_Name: 'naphthalene degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Naphthalene-Degradation|PWY-7620',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Naphthalene Degradation|naphthalene degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-6310',
        Common_Name: 'aloesone biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-6310',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|aloesone biosynthesis II',
    },
    {
        BioCyc_ID: 'COBALSYN-PWY',
        Common_Name: 'superpathway of adenosylcobalamin salvage from cobinamide I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-Salvage|B12-Salvage-From-Cobinamide|COBALSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobinamide Salvage|Adenosylcobalamin Salvage from Cobinamide|superpathway of adenosylcobalamin salvage from cobinamide I',
    },
    {
        BioCyc_ID: 'COBALSYN-PWY',
        Common_Name: 'superpathway of adenosylcobalamin salvage from cobinamide I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|COBALSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of adenosylcobalamin salvage from cobinamide I',
    },
    {
        BioCyc_ID: 'PWY-7645',
        Common_Name: 'hyaluronan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-7645',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|hyaluronan degradation',
    },
    {
        BioCyc_ID: 'PWY-7645',
        Common_Name: 'hyaluronan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-7645',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|hyaluronan degradation',
    },
    {
        BioCyc_ID: 'PWY-3481',
        Common_Name: 'superpathway of L-phenylalanine and L-tyrosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|PWY-3481',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|superpathway of L-phenylalanine and L-tyrosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3481',
        Common_Name: 'superpathway of L-phenylalanine and L-tyrosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-3481',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-phenylalanine and L-tyrosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8103',
        Common_Name: 'kavain biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-8103',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|kavain biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6970',
        Common_Name: 'pyruvate decarboxylation to acetyl CoA II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Acetyl-CoA-Biosynthesis|PWY-6970',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Acetyl-CoA Biosynthesis|pyruvate decarboxylation to acetyl CoA II',
    },
    {
        BioCyc_ID: 'PWY-5990',
        Common_Name: 'lotaustralin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|CYANOGENIC-GLUCOSIDE-SYN|PWY-5990',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Cyanogenic Glucoside Biosynthesis|lotaustralin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-881',
        Common_Name: 'trehalose biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Trehalose-biosynthesis|PWY-881',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-881',
        Common_Name: 'trehalose biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Trehalose-biosynthesis|PWY-881',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8272',
        Common_Name: '<i>Escherichia coli</i> serotype O:49 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8272',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:49 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8272',
        Common_Name: '<i>Escherichia coli</i> serotype O:49 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8272',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:49 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8272',
        Common_Name: '<i>Escherichia coli</i> serotype O:49 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8272',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:49 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8272',
        Common_Name: '<i>Escherichia coli</i> serotype O:49 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8272',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:49 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8215',
        Common_Name: '3-sulfopropanediol degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|DHPS-Degradation|PWY-8215',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|3-Sulfopropanediol Degradation|3-sulfopropanediol degradation III',
    },
    {
        BioCyc_ID: 'PWY-6074',
        Common_Name: 'zymosterol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-6074',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|zymosterol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3101',
        Common_Name: 'flavonol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-3101',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|flavonol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6632',
        Common_Name: 'caffeine degradation IV (bacteria, via demethylation and oxidation)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|ALKALOIDS|CAFFEINE|PWY-6632',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Alkaloid Degradation|Caffeine Degradation|caffeine degradation IV (bacteria, via demethylation and oxidation)',
    },
    {
        BioCyc_ID: 'PWY-6632',
        Common_Name: 'caffeine degradation IV (bacteria, via demethylation and oxidation)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6632',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|caffeine degradation IV (bacteria, via demethylation and oxidation)',
    },
    {
        BioCyc_ID: 'PWY-5517',
        Common_Name: 'L-arabinose degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-Arabinose-Degradation|PWY-5517',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-arabinose Degradation|L-arabinose degradation III',
    },
    {
        BioCyc_ID: 'PWY-5427',
        Common_Name: 'naphthalene degradation (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Naphthalene-Degradation|PWY-5427',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Naphthalene Degradation|naphthalene degradation (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-7672',
        Common_Name: 'fusaric acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7672',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|fusaric acid biosynthesis',
    },
    {
        BioCyc_ID: 'GLUDEG-II-PWY',
        Common_Name: 'L-glutamate degradation VII (to butanoate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetyl-CoA-Butyrate|GLUDEG-II-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Butanoate|L-glutamate degradation VII (to butanoate)',
    },
    {
        BioCyc_ID: 'GLUDEG-II-PWY',
        Common_Name: 'L-glutamate degradation VII (to butanoate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|GLUDEG-II-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation VII (to butanoate)',
    },
    {
        BioCyc_ID: 'GLUDEG-II-PWY',
        Common_Name: 'L-glutamate degradation VII (to butanoate)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GLUDEG-II-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-glutamate degradation VII (to butanoate)',
    },
    {
        BioCyc_ID: 'PWY-6572',
        Common_Name: 'chondroitin sulfate degradation I (bacterial)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-6572',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|chondroitin sulfate degradation I (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-6572',
        Common_Name: 'chondroitin sulfate degradation I (bacterial)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-6572',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|chondroitin sulfate degradation I (bacterial)',
    },
    {
        BioCyc_ID: 'SER-GLYSYN-PWY',
        Common_Name: 'superpathway of L-serine and glycine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|SER-GLYSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|superpathway of L-serine and glycine biosynthesis I',
    },
    {
        BioCyc_ID: 'SER-GLYSYN-PWY',
        Common_Name: 'superpathway of L-serine and glycine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|SER-GLYSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-serine and glycine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5867',
        Common_Name: '<i>t</i>-anethole biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-5867',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|<i>t</i>-anethole biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-432',
        Common_Name: 'blood coagulation',
        MetaCyc_hierarchy_IDs: 'PWY66-432',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|blood coagulation',
    },
    {
        BioCyc_ID: 'PWY-7824',
        Common_Name: 'prunasin and amygdalin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|CYANOGENIC-GLUCOSIDE-SYN|PWY-7824',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Cyanogenic Glucoside Biosynthesis|prunasin and amygdalin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5172',
        Common_Name: 'acetyl-CoA biosynthesis from citrate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Acetyl-CoA-Biosynthesis|PWY-5172',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Acetyl-CoA Biosynthesis|acetyl-CoA biosynthesis from citrate',
    },
    {
        BioCyc_ID: 'PWY-2622',
        Common_Name: 'trehalose biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Trehalose-biosynthesis|PWY-2622',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-2622',
        Common_Name: 'trehalose biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Trehalose-biosynthesis|PWY-2622',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-8205',
        Common_Name: '<i>Escherichia coli</i> serotype O:77/<i>Salmonella enterica</i> serotype O:6,14 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8205',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:77/<i>Salmonella enterica</i> serotype O:6,14 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8205',
        Common_Name: '<i>Escherichia coli</i> serotype O:77/<i>Salmonella enterica</i> serotype O:6,14 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8205',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:77/<i>Salmonella enterica</i> serotype O:6,14 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8205',
        Common_Name: '<i>Escherichia coli</i> serotype O:77/<i>Salmonella enterica</i> serotype O:6,14 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8205',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:77/<i>Salmonella enterica</i> serotype O:6,14 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8205',
        Common_Name: '<i>Escherichia coli</i> serotype O:77/<i>Salmonella enterica</i> serotype O:6,14 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8205',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:77/<i>Salmonella enterica</i> serotype O:6,14 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8214',
        Common_Name: '3-sulfopropanediol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|DHPS-Degradation|PWY-8214',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|3-Sulfopropanediol Degradation|3-sulfopropanediol degradation II',
    },
    {
        BioCyc_ID: 'PWY-8026',
        Common_Name: 'sterol biosynthesis (methylotrophs)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-8026',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|sterol biosynthesis (methylotrophs)',
    },
    {
        BioCyc_ID: 'PWY-5321',
        Common_Name: 'quercetin glycoside biosynthesis (Arabidopsis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-5321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|quercetin glycoside biosynthesis (Arabidopsis)',
    },
    {
        BioCyc_ID: 'PWY-7156',
        Common_Name: 'superpathway of ergosterol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|Ergosterol-Biosynthesis|PWY-7156',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|Ergosterol Biosynthesis|superpathway of ergosterol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7156',
        Common_Name: 'superpathway of ergosterol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7156',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of ergosterol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5388',
        Common_Name: 'N-glucosylnicotinate metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|PWY-5388',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|N-glucosylnicotinate metabolism',
    },
    {
        BioCyc_ID: 'LACTOSEUTIL-PWY',
        Common_Name: 'lactose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|LACTOSE-DEG|LACTOSEUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Lactose Degradation|lactose degradation II',
    },
    {
        BioCyc_ID: 'PWY-6316',
        Common_Name: 'aromatic polyketides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-6316',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|aromatic polyketides biosynthesis',
    },
    {
        BioCyc_ID: 'PHOSLIPSYN2-PWY',
        Common_Name: 'superpathway of phospholipid biosynthesis II (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PHOSLIPSYN2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|superpathway of phospholipid biosynthesis II (plants)',
    },
    {
        BioCyc_ID: 'PHOSLIPSYN2-PWY',
        Common_Name: 'superpathway of phospholipid biosynthesis II (plants)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PHOSLIPSYN2-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of phospholipid biosynthesis II (plants)',
    },
    {
        BioCyc_ID: 'PWY-7644',
        Common_Name: 'heparin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-7644',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|heparin degradation',
    },
    {
        BioCyc_ID: 'PWY-7644',
        Common_Name: 'heparin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-7644',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|heparin degradation',
    },
    {
        BioCyc_ID: 'ASPASN-PWY',
        Common_Name: 'superpathway of L-aspartate and L-asparagine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|ASPASN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|superpathway of L-aspartate and L-asparagine biosynthesis',
    },
    {
        BioCyc_ID: 'ASPASN-PWY',
        Common_Name: 'superpathway of L-aspartate and L-asparagine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ASPASN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-aspartate and L-asparagine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7962',
        Common_Name: 'adenosylcobinamide-GDP biosynthesis from cobyrinate <i>a,c</i>-diamide',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|PWY-7962',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|adenosylcobinamide-GDP biosynthesis from cobyrinate <i>a,c</i>-diamide',
    },
    {
        BioCyc_ID: 'PWY-6543',
        Common_Name: '4-aminobenzoate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|4-Aminobenzoate-Biosynthesis|PWY-6543',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|4-Aminobenzoate Biosynthesis|4-aminobenzoate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY66-368',
        Common_Name: 'ketolysis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Acetyl-CoA-Biosynthesis|PWY66-368',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Acetyl-CoA Biosynthesis|ketolysis',
    },
    {
        BioCyc_ID: 'PWY-2821',
        Common_Name: 'glucosinolate biosynthesis from phenylalanine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWY-2821',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from phenylalanine',
    },
    {
        BioCyc_ID: 'PWY-0',
        Common_Name: 'putrescine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Putrescine-Degradation|PWY-0',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Putrescine Degradation|putrescine degradation III',
    },
    {
        BioCyc_ID: 'PWY-5985',
        Common_Name: 'trehalose biosynthesis VII',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Trehalose-biosynthesis|PWY-5985',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis VII',
    },
    {
        BioCyc_ID: 'PWY-5985',
        Common_Name: 'trehalose biosynthesis VII',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Trehalose-biosynthesis|PWY-5985',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis VII',
    },
    {
        BioCyc_ID: 'PWY-8230',
        Common_Name: '<i>Salmonella enterica</i> serotype O:13 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8230',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:13 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8230',
        Common_Name: '<i>Salmonella enterica</i> serotype O:13 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8230',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:13 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8230',
        Common_Name: '<i>Salmonella enterica</i> serotype O:13 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8230',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:13 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8230',
        Common_Name: '<i>Salmonella enterica</i> serotype O:13 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8230',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:13 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6634',
        Common_Name: '3-sulfopropanediol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|DHPS-Degradation|PWY-6634',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|3-Sulfopropanediol Degradation|3-sulfopropanediol degradation I',
    },
    {
        BioCyc_ID: 'PWY0-1221',
        Common_Name: 'putrescine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Putrescine-Degradation|PWY0-1221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Putrescine Degradation|putrescine degradation II',
    },
    {
        BioCyc_ID: 'PWY-8191',
        Common_Name: 'cholesterol biosynthesis (algae, late side-chain reductase)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-8191',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|cholesterol biosynthesis (algae, late side-chain reductase)',
    },
    {
        BioCyc_ID: 'PWY-5390',
        Common_Name: 'rutin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-5390',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|rutin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7277',
        Common_Name: 'sphingolipid biosynthesis (mammals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-7277',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|sphingolipid biosynthesis (mammals)',
    },
    {
        BioCyc_ID: 'PWY-7277',
        Common_Name: 'sphingolipid biosynthesis (mammals)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7277',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|sphingolipid biosynthesis (mammals)',
    },
    {
        BioCyc_ID: 'PWY-6333',
        Common_Name: 'acetaldehyde biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Acetaldehyde-Biosynthesis|PWY-6333',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Acetaldehyde Biosynthesis|acetaldehyde biosynthesis I',
    },
    {
        BioCyc_ID: 'PUTDEG-PWY',
        Common_Name: 'putrescine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Putrescine-Degradation|PUTDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Putrescine Degradation|putrescine degradation I',
    },
    {
        BioCyc_ID: 'LACTOSECAT-PWY',
        Common_Name: 'lactose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|LACTOSE-DEG|LACTOSECAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Lactose Degradation|lactose degradation I',
    },
    {
        BioCyc_ID: 'PWY-7689',
        Common_Name: '8-<i>O</i>-methylfusarubin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7689',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|8-<i>O</i>-methylfusarubin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3',
        Common_Name: 'putrescine degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Putrescine-Degradation|PWY-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Putrescine Degradation|putrescine degradation V',
    },
    {
        BioCyc_ID: 'PWY-3097',
        Common_Name: 'superpathway of isoflavonoids (via naringenin)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-3097',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|superpathway of isoflavonoids (via naringenin)',
    },
    {
        BioCyc_ID: 'PWY-3097',
        Common_Name: 'superpathway of isoflavonoids (via naringenin)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-3097',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of isoflavonoids (via naringenin)',
    },
    {
        BioCyc_ID: 'PWY-7651',
        Common_Name: 'heparan sulfate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-7651',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|heparan sulfate degradation',
    },
    {
        BioCyc_ID: 'PWY-7651',
        Common_Name: 'heparan sulfate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-7651',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|heparan sulfate degradation',
    },
    {
        BioCyc_ID: 'PWY-8281',
        Common_Name: '4-aminobenzoate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|4-Aminobenzoate-Biosynthesis|PWY-8281',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|4-Aminobenzoate Biosynthesis|4-aminobenzoate biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8275',
        Common_Name: 'pyruvate decarboxylation to acetyl CoA III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Acetyl-CoA-Biosynthesis|PWY-8275',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Acetyl-CoA Biosynthesis|pyruvate decarboxylation to acetyl CoA III',
    },
    {
        BioCyc_ID: 'PWYQT-4450',
        Common_Name: 'aliphatic glucosinolate biosynthesis, side chain elongation cycle',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWYQT-4450',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|aliphatic glucosinolate biosynthesis, side chain elongation cycle',
    },
    {
        BioCyc_ID: 'PWY-2',
        Common_Name: 'putrescine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Putrescine-Degradation|PWY-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Putrescine Degradation|putrescine degradation IV',
    },
    {
        BioCyc_ID: 'TRESYN-PWY',
        Common_Name: 'trehalose biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Trehalose-biosynthesis|TRESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis I',
    },
    {
        BioCyc_ID: 'TRESYN-PWY',
        Common_Name: 'trehalose biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Trehalose-biosynthesis|TRESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Trehalose Biosynthesis|trehalose biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8233',
        Common_Name: '<i>Escherichia coli</i> serotype O:169 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8233',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:169 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8233',
        Common_Name: '<i>Escherichia coli</i> serotype O:169 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8233',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:169 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8233',
        Common_Name: '<i>Escherichia coli</i> serotype O:169 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8233',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:169 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8233',
        Common_Name: '<i>Escherichia coli</i> serotype O:169 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8233',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:169 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5350',
        Common_Name: 'thiosulfate disproportionation IV (rhodanese)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Disproportionation|PWY-5350',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Disproportionation|thiosulfate disproportionation IV (rhodanese)',
    },
    {
        BioCyc_ID: 'PWY-5747',
        Common_Name: '2-methylcitrate cycle II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Propionate-Degradation|Methyl-Citrate-Cycle|PWY-5747',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Propanoate Degradation|2-Methylcitrate Cycle|2-methylcitrate cycle II',
    },
    {
        BioCyc_ID: 'PWY-2541',
        Common_Name: 'phytosterol biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-2541',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|phytosterol biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-2541',
        Common_Name: 'phytosterol biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-2541',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|phytosterol biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-7166',
        Common_Name: 'kaempferide triglycoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7166',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|kaempferide triglycoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7393',
        Common_Name: '&beta;-carotene biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7393',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&beta;-carotene biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7393',
        Common_Name: '&beta;-carotene biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7393',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&beta;-carotene biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7393',
        Common_Name: '&beta;-carotene biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7393',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&beta;-carotene biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7393',
        Common_Name: '&beta;-carotene biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7393',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|&beta;-carotene biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6330',
        Common_Name: 'acetaldehyde biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Acetaldehyde-Biosynthesis|PWY-6330',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Acetaldehyde Biosynthesis|acetaldehyde biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6330',
        Common_Name: 'acetaldehyde biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|PWY-6330',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|acetaldehyde biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY0-42',
        Common_Name: '2-methylcitrate cycle I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Propionate-Degradation|Methyl-Citrate-Cycle|PWY0-42',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Propanoate Degradation|2-Methylcitrate Cycle|2-methylcitrate cycle I',
    },
    {
        BioCyc_ID: 'BGALACT-PWY',
        Common_Name: 'lactose degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|LACTOSE-DEG|BGALACT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Lactose Degradation|lactose degradation III',
    },
    {
        BioCyc_ID: 'PWY-8326',
        Common_Name: 'grayanate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8326',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|grayanate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5088',
        Common_Name: 'L-glutamate degradation VIII (to propanoate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-propanoate|PWY-5088',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Propanoate|L-glutamate degradation VIII (to propanoate)',
    },
    {
        BioCyc_ID: 'PWY-5088',
        Common_Name: 'L-glutamate degradation VIII (to propanoate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|PWY-5088',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation VIII (to propanoate)',
    },
    {
        BioCyc_ID: 'PWY-5088',
        Common_Name: 'L-glutamate degradation VIII (to propanoate)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5088',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-glutamate degradation VIII (to propanoate)',
    },
    {
        BioCyc_ID: 'PWY-6576',
        Common_Name: 'dermatan sulfate degradation (metazoa)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6576',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|dermatan sulfate degradation (metazoa)',
    },
    {
        BioCyc_ID: 'PWY-6576',
        Common_Name: 'dermatan sulfate degradation (metazoa)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-6576',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|dermatan sulfate degradation (metazoa)',
    },
    {
        BioCyc_ID: 'PWY-6576',
        Common_Name: 'dermatan sulfate degradation (metazoa)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-6576',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|dermatan sulfate degradation (metazoa)',
    },
    {
        BioCyc_ID: 'PWY-8276',
        Common_Name: '4-aminobenzoate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|4-Aminobenzoate-Biosynthesis|PWY-8276',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|4-Aminobenzoate Biosynthesis|4-aminobenzoate biosynthesis II',
    },
    {
        BioCyc_ID: 'ENTNER-DOUDOROFF-PWY',
        Common_Name: 'Entner-Doudoroff shunt',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Entner-Duodoroff-Pathways|ENTNER-DOUDOROFF-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Entner-Doudoroff Pathways|Entner-Doudoroff shunt',
    },
    {
        BioCyc_ID: 'PWYQT-4473',
        Common_Name: 'glucosinolate biosynthesis from tetrahomomethionine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWYQT-4473',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from tetrahomomethionine',
    },
    {
        BioCyc_ID: 'PWY-6054',
        Common_Name: 'dimethylsulfoniopropanoate biosynthesis I (Wollastonia)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Dimethylsulfoniopropionate-Biosynthesis|PWY-6054',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Dimethylsulfoniopropanoate Biosynthesis|dimethylsulfoniopropanoate biosynthesis I (Wollastonia)',
    },
    {
        BioCyc_ID: 'PWY-8208',
        Common_Name: '<i>Salmonella enterica</i> serotype O:6,7 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8208',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:6,7 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8208',
        Common_Name: '<i>Salmonella enterica</i> serotype O:6,7 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8208',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:6,7 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8208',
        Common_Name: '<i>Salmonella enterica</i> serotype O:6,7 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8208',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:6,7 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8208',
        Common_Name: '<i>Salmonella enterica</i> serotype O:6,7 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8208',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:6,7 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5274',
        Common_Name: 'sulfide oxidation II (flavocytochrome <em>c</em>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfide-Oxidation|PWY-5274',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfide Oxidation|sulfide oxidation II (flavocytochrome <em>c</em>)',
    },
    {
        BioCyc_ID: 'PWY-6591',
        Common_Name: 'manganese oxidation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Manganese-Oxidation|PWY-6591',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Manganese Oxidation|manganese oxidation I',
    },
    {
        BioCyc_ID: 'PWY-6061',
        Common_Name: 'bile acid biosynthesis, neutral pathway',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-6061',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|bile acid biosynthesis, neutral pathway',
    },
    {
        BioCyc_ID: 'PWY-6064',
        Common_Name: 'methylquercetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-6064',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|methylquercetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7622',
        Common_Name: 'UDP-&alpha;-D-galactofuranose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7622',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|UDP-&alpha;-D-galactofuranose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7622',
        Common_Name: 'UDP-&alpha;-D-galactofuranose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7622',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&alpha;-D-galactofuranose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7100',
        Common_Name: 'spinosyn biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Insecticides-Biosynthesis|PWY-7100',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Insecticide Biosynthesis|spinosyn biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6313',
        Common_Name: 'serotonin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PWY-6313',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|serotonin degradation',
    },
    {
        BioCyc_ID: 'PWY-6627',
        Common_Name: 'salinosporamide A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-6627',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|salinosporamide A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5537',
        Common_Name: 'pyruvate fermentation to acetate V',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5537',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate V',
    },
    {
        BioCyc_ID: 'PWY-5537',
        Common_Name: 'pyruvate fermentation to acetate V',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5537',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate V',
    },
    {
        BioCyc_ID: 'PWY-5537',
        Common_Name: 'pyruvate fermentation to acetate V',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5537',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate V',
    },
    {
        BioCyc_ID: 'PWY-7646',
        Common_Name: 'dermatan sulfate degradation I (bacterial)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-7646',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|dermatan sulfate degradation I (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-7646',
        Common_Name: 'dermatan sulfate degradation I (bacterial)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycosaminoglycan-Degradation|PWY-7646',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycosaminoglycan Degradation|dermatan sulfate degradation I (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-7930',
        Common_Name: 'pyoluteorin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Halogenated-Aromatics-Biosynthesis|PWY-7930',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Halogenated Aromatic Compound Biosynthesis|pyoluteorin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6744',
        Common_Name: 'hydrogen production I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-6744',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|hydrogen production I',
    },
    {
        BioCyc_ID: 'PWY-1187',
        Common_Name: 'glucosinolate biosynthesis from homomethionine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWY-1187',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from homomethionine',
    },
    {
        BioCyc_ID: 'PWY-6053',
        Common_Name: 'dimethylsulfoniopropanoate biosynthesis III (algae and phytoplankton)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Dimethylsulfoniopropionate-Biosynthesis|PWY-6053',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Dimethylsulfoniopropanoate Biosynthesis|dimethylsulfoniopropanoate biosynthesis III (algae and phytoplankton)',
    },
    {
        BioCyc_ID: 'PWY-8236',
        Common_Name: '<i>Salmonella enterica</i> serotype O:39 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8236',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:39 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8236',
        Common_Name: '<i>Salmonella enterica</i> serotype O:39 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8236',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:39 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8236',
        Common_Name: '<i>Salmonella enterica</i> serotype O:39 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8236',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:39 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8236',
        Common_Name: '<i>Salmonella enterica</i> serotype O:39 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8236',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:39 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7927',
        Common_Name: 'sulfide oxidation IV (mitochondria)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfide-Oxidation|PWY-7927',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfide Oxidation|sulfide oxidation IV (mitochondria)',
    },
    {
        BioCyc_ID: 'PWY-6592',
        Common_Name: 'manganese oxidation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Manganese-Oxidation|PWY-6592',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Manganese Oxidation|manganese oxidation II',
    },
    {
        BioCyc_ID: 'PWY-7155',
        Common_Name: '7-dehydroporiferasterol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-7155',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|7-dehydroporiferasterol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7172',
        Common_Name: 'flavonol acylglucoside biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7172',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|flavonol acylglucoside biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8369',
        Common_Name: 'superpathway of novobiocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8369',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|superpathway of novobiocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8369',
        Common_Name: 'superpathway of novobiocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8369',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of novobiocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6926',
        Common_Name: 'pyrethrin I biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Insecticides-Biosynthesis|PWY-6926',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Insecticide Biosynthesis|pyrethrin I biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6688',
        Common_Name: 'thyronamine and iodothyronamine metabolism',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PWY-6688',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|thyronamine and iodothyronamine metabolism',
    },
    {
        BioCyc_ID: 'PWY-7563',
        Common_Name: 'bassianin and desmethylbassianin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7563',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|bassianin and desmethylbassianin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6196',
        Common_Name: 'D-serine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|D-Amino-Acid-Degradation|PWY-6196',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|D-Amino Acid Degradation|D-serine degradation',
    },
    {
        BioCyc_ID: 'PWY-6196',
        Common_Name: 'D-serine degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6196',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|D-serine degradation',
    },
    {
        BioCyc_ID: 'PWY-6735',
        Common_Name: 'starch degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-6735',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation IV',
    },
    {
        BioCyc_ID: 'PWY-6735',
        Common_Name: 'starch degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-6735',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation IV',
    },
    {
        BioCyc_ID: 'PWY-8210',
        Common_Name: 'pyrrolomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Halogenated-Aromatics-Biosynthesis|PWY-8210',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Halogenated Aromatic Compound Biosynthesis|pyrrolomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6759',
        Common_Name: 'hydrogen production III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-6759',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|hydrogen production III',
    },
    {
        BioCyc_ID: 'PWY-7901',
        Common_Name: 'glucosinolate biosynthesis from tyrosine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWY-7901',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from tyrosine',
    },
    {
        BioCyc_ID: 'PWY-6055',
        Common_Name: 'dimethylsulfoniopropanoate biosynthesis II (Spartina)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Dimethylsulfoniopropionate-Biosynthesis|PWY-6055',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Dimethylsulfoniopropanoate Biosynthesis|dimethylsulfoniopropanoate biosynthesis II (Spartina)',
    },
    {
        BioCyc_ID: 'PWY-7530',
        Common_Name: '<i>Escherichia coli</i> serotype O:104 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-7530',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:104 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7530',
        Common_Name: '<i>Escherichia coli</i> serotype O:104 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-7530',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:104 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7530',
        Common_Name: '<i>Escherichia coli</i> serotype O:104 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-7530',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:104 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7530',
        Common_Name: '<i>Escherichia coli</i> serotype O:104 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-7530',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:104 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6638',
        Common_Name: 'sulfolactate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfolactate-Degradation|PWY-6638',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfolactate Degradation|sulfolactate degradation III',
    },
    {
        BioCyc_ID: 'PWY-7588',
        Common_Name: 'ursodeoxycholate biosynthesis (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-7588',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|ursodeoxycholate biosynthesis (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7192',
        Common_Name: 'quercetin diglycoside biosynthesis (pollen-specific)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7192',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|quercetin diglycoside biosynthesis (pollen-specific)',
    },
    {
        BioCyc_ID: 'PWY0-881',
        Common_Name: 'superpathway of fatty acid biosynthesis I (E. coli)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY0-881',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|superpathway of fatty acid biosynthesis I (E. coli)',
    },
    {
        BioCyc_ID: 'PWY0-881',
        Common_Name: 'superpathway of fatty acid biosynthesis I (E. coli)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-881',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of fatty acid biosynthesis I (E. coli)',
    },
    {
        BioCyc_ID: 'PWY-8069',
        Common_Name: 'nepetalactone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Insecticides-Biosynthesis|PWY-8069',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Insecticide Biosynthesis|nepetalactone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6342',
        Common_Name: 'noradrenaline and adrenaline degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PWY-6342',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|noradrenaline and adrenaline degradation',
    },
    {
        BioCyc_ID: 'PWY-8035',
        Common_Name: 'streptovaricin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8035',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|streptovaricin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8035',
        Common_Name: 'streptovaricin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8035',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|streptovaricin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6471',
        Common_Name: 'peptidoglycan biosynthesis IV (<i>Enterococcus faecium</i>)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Antibiotic-Resistance|PWY-6471',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Antibiotic Resistance|peptidoglycan biosynthesis IV (<i>Enterococcus faecium</i>)',
    },
    {
        BioCyc_ID: 'PWY-6471',
        Common_Name: 'peptidoglycan biosynthesis IV (<i>Enterococcus faecium</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Biosynthesis|PWY-6471',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Biosynthesis|peptidoglycan biosynthesis IV (<i>Enterococcus faecium</i>)',
    },
    {
        BioCyc_ID: 'PWY-6471',
        Common_Name: 'peptidoglycan biosynthesis IV (<i>Enterococcus faecium</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6471',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|peptidoglycan biosynthesis IV (<i>Enterococcus faecium</i>)',
    },
    {
        BioCyc_ID: 'PWY-6731',
        Common_Name: 'starch degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-6731',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation III',
    },
    {
        BioCyc_ID: 'PWY-6731',
        Common_Name: 'starch degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-6731',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation III',
    },
    {
        BioCyc_ID: 'PWY-7929',
        Common_Name: 'polybrominated phenols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Halogenated-Aromatics-Biosynthesis|PWY-7929',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Halogenated Aromatic Compound Biosynthesis|polybrominated phenols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6780',
        Common_Name: 'hydrogen production VI',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-6780',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|hydrogen production VI',
    },
    {
        BioCyc_ID: 'PWYQT-4472',
        Common_Name: 'glucosinolate biosynthesis from trihomomethionine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWYQT-4472',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from trihomomethionine',
    },
    {
        BioCyc_ID: 'PWY-5658',
        Common_Name: 'mannosylglycerate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Mannosylglycerate-Biosynthesis|PWY-5658',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Mannosylglycerate Biosynthesis|mannosylglycerate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8212',
        Common_Name: '<i>Escherichia coli</i> serotype O:8 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8212',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:8 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8212',
        Common_Name: '<i>Escherichia coli</i> serotype O:8 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8212',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:8 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8212',
        Common_Name: '<i>Escherichia coli</i> serotype O:8 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8212',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:8 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8212',
        Common_Name: '<i>Escherichia coli</i> serotype O:8 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8212',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:8 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6637',
        Common_Name: 'sulfolactate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfolactate-Degradation|PWY-6637',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfolactate Degradation|sulfolactate degradation II',
    },
    {
        BioCyc_ID: 'PWY-6932',
        Common_Name: 'selenate reduction',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Selenium-Metabolism|PWY-6932',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Selenium Metabolism|selenate reduction',
    },
    {
        BioCyc_ID: 'PWY-8028',
        Common_Name: 'cycloartenol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-8028',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|cycloartenol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8028',
        Common_Name: 'cycloartenol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-8028',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|cycloartenol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7143',
        Common_Name: 'kaempferol gentiobioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7143',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|kaempferol gentiobioside biosynthesis',
    },
    {
        BioCyc_ID: 'ARGDEGRAD-PWY',
        Common_Name: 'L-arginine degradation V (arginine deiminase pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ARGDEGRAD-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation V (arginine deiminase pathway)',
    },
    {
        BioCyc_ID: 'ARGDEGRAD-PWY',
        Common_Name: 'L-arginine degradation V (arginine deiminase pathway)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ARGDEGRAD-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-arginine degradation V (arginine deiminase pathway)',
    },
    {
        BioCyc_ID: 'PWY-7960',
        Common_Name: 'rhabduscin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Insecticides-Biosynthesis|PWY-7960',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Insecticide Biosynthesis|rhabduscin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8402',
        Common_Name: '<i>N</i>-(1-deoxy-D-fructos-1-yl)-L-asparagine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY-8402',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|<i>N</i>-(1-deoxy-D-fructos-1-yl)-L-asparagine degradation',
    },
    {
        BioCyc_ID: 'PWY-4801',
        Common_Name: 'aloesone biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-4801',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|aloesone biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6604',
        Common_Name: 'superpathway of <i>Clostridium acetobutylicum</i> acidogenic and solventogenic fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|PWY-6604',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|superpathway of <i>Clostridium acetobutylicum</i> acidogenic and solventogenic fermentation',
    },
    {
        BioCyc_ID: 'PWY-6604',
        Common_Name: 'superpathway of <i>Clostridium acetobutylicum</i> acidogenic and solventogenic fermentation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6604',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of <i>Clostridium acetobutylicum</i> acidogenic and solventogenic fermentation',
    },
    {
        BioCyc_ID: 'PWY-842',
        Common_Name: 'starch degradation I',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-842',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|starch degradation I',
    },
    {
        BioCyc_ID: 'PWY-842',
        Common_Name: 'starch degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-842',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation I',
    },
    {
        BioCyc_ID: 'PWY-842',
        Common_Name: 'starch degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-842',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation I',
    },
    {
        BioCyc_ID: 'PWY-7932',
        Common_Name: 'polybrominated biphenyls and diphenyl ethers biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Halogenated-Aromatics-Biosynthesis|PWY-7932',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Halogenated Aromatic Compound Biosynthesis|polybrominated biphenyls and diphenyl ethers biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6758',
        Common_Name: 'hydrogen production II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-6758',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|hydrogen production II',
    },
    {
        BioCyc_ID: 'PWYQT-4475',
        Common_Name: 'glucosinolate biosynthesis from hexahomomethionine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWYQT-4475',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from hexahomomethionine',
    },
    {
        BioCyc_ID: 'PWY-5656',
        Common_Name: 'mannosylglycerate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Mannosylglycerate-Biosynthesis|PWY-5656',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Mannosylglycerate Biosynthesis|mannosylglycerate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8242',
        Common_Name: '<i>Escherichia coli</i> serotype O:51/<i>Salmonella enterica</i> serotype O:57 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8242',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:51/<i>Salmonella enterica</i> serotype O:57 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8242',
        Common_Name: '<i>Escherichia coli</i> serotype O:51/<i>Salmonella enterica</i> serotype O:57 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8242',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:51/<i>Salmonella enterica</i> serotype O:57 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8242',
        Common_Name: '<i>Escherichia coli</i> serotype O:51/<i>Salmonella enterica</i> serotype O:57 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8242',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:51/<i>Salmonella enterica</i> serotype O:57 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8242',
        Common_Name: '<i>Escherichia coli</i> serotype O:51/<i>Salmonella enterica</i> serotype O:57 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8242',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:51/<i>Salmonella enterica</i> serotype O:57 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6616',
        Common_Name: 'sulfolactate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfolactate-Degradation|PWY-6616',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfolactate Degradation|sulfolactate degradation I',
    },
    {
        BioCyc_ID: 'PWY-6692',
        Common_Name: 'Fe(II) oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Iron-Metabolism|PWY-6692',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Iron Metabolism|Fe(II) oxidation',
    },
    {
        BioCyc_ID: 'PWY-8239',
        Common_Name: 'cholesterol biosynthesis (diatoms)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-8239',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|cholesterol biosynthesis (diatoms)',
    },
    {
        BioCyc_ID: 'PWY-8239',
        Common_Name: 'cholesterol biosynthesis (diatoms)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-8239',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|cholesterol biosynthesis (diatoms)',
    },
    {
        BioCyc_ID: 'PWY-7995',
        Common_Name: 'tricin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7995',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|tricin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1701',
        Common_Name: 'methanol and methylamine oxidation to formaldehyde',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Methanol-Oxidation|PWY-1701',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Methanol Oxidation|methanol and methylamine oxidation to formaldehyde',
    },
    {
        BioCyc_ID: 'PWY-1701',
        Common_Name: 'methanol and methylamine oxidation to formaldehyde',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-1701',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|methanol and methylamine oxidation to formaldehyde',
    },
    {
        BioCyc_ID: 'PWY-7711',
        Common_Name: 'calonectrin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Tricothecene-Biosynthesis|PWY-7711',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Tricothecene Biosynthesis|calonectrin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7711',
        Common_Name: 'calonectrin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Tricothecene-Biosynthesis|PWY-7711',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Tricothecene Biosynthesis|calonectrin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4FS-13',
        Common_Name: 'extended VTC2 cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY4FS-13',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|extended VTC2 cycle',
    },
    {
        BioCyc_ID: 'PWY-7655',
        Common_Name: 'dechlorogriseofulvin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7655',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|dechlorogriseofulvin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7655',
        Common_Name: 'dechlorogriseofulvin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7655',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|dechlorogriseofulvin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7024',
        Common_Name: 'superpathway of the 3-hydroxypropanoate cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|PWY-7024',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|superpathway of the 3-hydroxypropanoate cycle',
    },
    {
        BioCyc_ID: 'PWY-7024',
        Common_Name: 'superpathway of the 3-hydroxypropanoate cycle',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7024',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of the 3-hydroxypropanoate cycle',
    },
    {
        BioCyc_ID: 'PWY-6724',
        Common_Name: 'starch degradation II',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6724',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|starch degradation II',
    },
    {
        BioCyc_ID: 'PWY-6724',
        Common_Name: 'starch degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-6724',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation II',
    },
    {
        BioCyc_ID: 'PWY-6724',
        Common_Name: 'starch degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-6724',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation II',
    },
    {
        BioCyc_ID: 'PWY-7935',
        Common_Name: 'spongiadioxin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Halogenated-Aromatics-Biosynthesis|PWY-7935',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Halogenated Aromatic Compound Biosynthesis|spongiadioxin C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6772',
        Common_Name: 'hydrogen production V',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-6772',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|hydrogen production V',
    },
    {
        BioCyc_ID: 'PWY-601',
        Common_Name: 'glucosinolate biosynthesis from tryptophan',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWY-601',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from tryptophan',
    },
    {
        BioCyc_ID: 'PWY-6687',
        Common_Name: 'mannosylglucosylglycerate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Mannosylglucosylglycerate-Biosynthesis|PWY-6687',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Mannosylglucosylglycerate Biosynthesis|mannosylglucosylglycerate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8193',
        Common_Name: '<i>Salmonella enterica</i> serotype O:8 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8193',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:8 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8193',
        Common_Name: '<i>Salmonella enterica</i> serotype O:8 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8193',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:8 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8193',
        Common_Name: '<i>Salmonella enterica</i> serotype O:8 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8193',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:8 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8193',
        Common_Name: '<i>Salmonella enterica</i> serotype O:8 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8193',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:8 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5332',
        Common_Name: 'sulfur reduction I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-reduction|PWY-5332',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Reduction|sulfur reduction I',
    },
    {
        BioCyc_ID: 'PWY-5934',
        Common_Name: 'iron reduction and absorption',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Iron-Metabolism|PWY-5934',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Iron Metabolism|iron reduction and absorption',
    },
    {
        BioCyc_ID: 'PWY-8053',
        Common_Name: 'anandamide biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Anandamide-Biosynthesis|PWY-8053',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Anandamide Biosynthesis|anandamide biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5320',
        Common_Name: 'kaempferol glycoside biosynthesis (Arabidopsis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-5320',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|kaempferol glycoside biosynthesis (Arabidopsis)',
    },
    {
        BioCyc_ID: 'PWY-5004',
        Common_Name: 'superpathway of L-citrulline metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|Citrulline-Biosynthesis|PWY-5004',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-citrulline Biosynthesis|superpathway of L-citrulline metabolism',
    },
    {
        BioCyc_ID: 'PWY-5004',
        Common_Name: 'superpathway of L-citrulline metabolism',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5004',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-citrulline metabolism',
    },
    {
        BioCyc_ID: 'PWY-7714',
        Common_Name: 'harzianum A and trichodermin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Tricothecene-Biosynthesis|PWY-7714',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Tricothecene Biosynthesis|harzianum A and trichodermin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7714',
        Common_Name: 'harzianum A and trichodermin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Tricothecene-Biosynthesis|PWY-7714',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Tricothecene Biosynthesis|harzianum A and trichodermin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6507',
        Common_Name: '4-deoxy-L-<i>threo</i>-hex-4-enopyranuronate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY-6507',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|4-deoxy-L-<i>threo</i>-hex-4-enopyranuronate degradation',
    },
    {
        BioCyc_ID: 'PWY-8038',
        Common_Name: 'naphthomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8038',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|naphthomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8038',
        Common_Name: 'naphthomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8038',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|naphthomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7229',
        Common_Name: 'superpathway of adenosine nucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|PWY-7229',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|superpathway of adenosine nucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7229',
        Common_Name: 'superpathway of adenosine nucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7229',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of adenosine nucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6737',
        Common_Name: 'starch degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-6737',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation V',
    },
    {
        BioCyc_ID: 'PWY-6737',
        Common_Name: 'starch degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Starch-Degradation|PWY-6737',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Starch Degradation|starch degradation V',
    },
    {
        BioCyc_ID: 'PWY-7931',
        Common_Name: 'brominated pyrroles biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Halogenated-Aromatics-Biosynthesis|PWY-7931',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Halogenated Aromatic Compound Biosynthesis|brominated pyrroles biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6765',
        Common_Name: 'hydrogen production IV',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-6765',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|hydrogen production IV',
    },
    {
        BioCyc_ID: 'PWYQT-4471',
        Common_Name: 'glucosinolate biosynthesis from dihomomethionine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWYQT-4471',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from dihomomethionine',
    },
    {
        BioCyc_ID: 'PWY-6686',
        Common_Name: 'mannosylglucosylglycerate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Mannosylglucosylglycerate-Biosynthesis|PWY-6686',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Mannosylglucosylglycerate Biosynthesis|mannosylglucosylglycerate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8219',
        Common_Name: '<i>Escherichia coli</i> serotype O:107 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8219',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:107 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8219',
        Common_Name: '<i>Escherichia coli</i> serotype O:107 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8219',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:107 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8219',
        Common_Name: '<i>Escherichia coli</i> serotype O:107 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8219',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:107 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8219',
        Common_Name: '<i>Escherichia coli</i> serotype O:107 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8219',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:107 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7976',
        Common_Name: 'sulfur reduction III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-reduction|PWY-7976',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Reduction|sulfur reduction III',
    },
    {
        BioCyc_ID: 'PWY-8051',
        Common_Name: 'anandamide biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Anandamide-Biosynthesis|PWY-8051',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Anandamide Biosynthesis|anandamide biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7151',
        Common_Name: 'polymethylated quercetin glucoside biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7151',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|polymethylated quercetin glucoside biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5327',
        Common_Name: 'superpathway of L-lysine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY-5327',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|superpathway of L-lysine degradation',
    },
    {
        BioCyc_ID: 'PWY-5327',
        Common_Name: 'superpathway of L-lysine degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5327',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-lysine degradation',
    },
    {
        BioCyc_ID: 'PWY-7713',
        Common_Name: 'nivalenol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Tricothecene-Biosynthesis|PWY-7713',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Tricothecene Biosynthesis|nivalenol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7713',
        Common_Name: 'nivalenol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Tricothecene-Biosynthesis|PWY-7713',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Tricothecene Biosynthesis|nivalenol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6546',
        Common_Name: 'brassinosteroids inactivation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|PWY-6546',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|brassinosteroids inactivation',
    },
    {
        BioCyc_ID: 'PWY-6546',
        Common_Name: 'brassinosteroids inactivation',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6546',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|brassinosteroids inactivation',
    },
    {
        BioCyc_ID: 'PWY-6546',
        Common_Name: 'brassinosteroids inactivation',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|PWY-6546',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|brassinosteroids inactivation',
    },
    {
        BioCyc_ID: 'PWY-7180',
        Common_Name: '2-deoxy-&alpha;-D-ribose 1-phosphate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY-7180',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|2-deoxy-&alpha;-D-ribose 1-phosphate degradation',
    },
    {
        BioCyc_ID: 'PWY-5984',
        Common_Name: 'rifamycin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5984',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|rifamycin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5984',
        Common_Name: 'rifamycin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-5984',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|rifamycin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7357',
        Common_Name: 'thiamine diphosphate formation from pyrithiamine and oxythiamine (yeast)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7357',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|thiamine diphosphate formation from pyrithiamine and oxythiamine (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7357',
        Common_Name: 'thiamine diphosphate formation from pyrithiamine and oxythiamine (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-7357',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate formation from pyrithiamine and oxythiamine (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7357',
        Common_Name: 'thiamine diphosphate formation from pyrithiamine and oxythiamine (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-7357',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate formation from pyrithiamine and oxythiamine (yeast)',
    },
    {
        BioCyc_ID: 'PWY-6817',
        Common_Name: '&lambda;-carrageenan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Carrageenan-Degradation|PWY-6817',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Carrageenan Degradation|&lambda;-carrageenan degradation',
    },
    {
        BioCyc_ID: 'PWY-6817',
        Common_Name: '&lambda;-carrageenan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Carrageenan-Degradation|PWY-6817',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Carrageenan Degradation|&lambda;-carrageenan degradation',
    },
    {
        BioCyc_ID: 'PWY-7934',
        Common_Name: 'polybrominated dihydroxylated diphenyl ethers biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Halogenated-Aromatics-Biosynthesis|PWY-7934',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Halogenated Aromatic Compound Biosynthesis|polybrominated dihydroxylated diphenyl ethers biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6785',
        Common_Name: 'hydrogen production VIII',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-6785',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|hydrogen production VIII',
    },
    {
        BioCyc_ID: 'PWYQT-4474',
        Common_Name: 'glucosinolate biosynthesis from pentahomomethionine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|N-CONTAINING-GLUCOSIDE-SYN|GLUCOSINOLATE-SYN|PWYQT-4474',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Nitrogen-Containing Glucoside Biosynthesis|Glucosinolate Biosynthesis|glucosinolate biosynthesis from pentahomomethionine',
    },
    {
        BioCyc_ID: 'PWY-6685',
        Common_Name: 'glucosylglycerate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Glucosylglycerate-Biosynthesis|PWY-6685',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Glucosylglycerate Biosynthesis|glucosylglycerate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8251',
        Common_Name: '<i>Escherichia coli</i> serotype O:2 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8251',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:2 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8251',
        Common_Name: '<i>Escherichia coli</i> serotype O:2 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8251',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:2 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8251',
        Common_Name: '<i>Escherichia coli</i> serotype O:2 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8251',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:2 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8251',
        Common_Name: '<i>Escherichia coli</i> serotype O:2 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8251',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:2 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5364',
        Common_Name: 'sulfur reduction II (via polysulfide)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-reduction|PWY-5364',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Reduction|sulfur reduction II (via polysulfide)',
    },
    {
        BioCyc_ID: 'PWY-7684',
        Common_Name: '&alpha;-diglucosyldiacylglycerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Glycolipids-Biosynthesis|PWY-7684',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Glycolipid Biosynthesis|&alpha;-diglucosyldiacylglycerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5363',
        Common_Name: 'chrysin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-5363',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|chrysin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5464',
        Common_Name: 'superpathway of cytosolic glycolysis (plants), pyruvate dehydrogenase and TCA cycle',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-5464',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|superpathway of cytosolic glycolysis (plants), pyruvate dehydrogenase and TCA cycle',
    },
    {
        BioCyc_ID: 'PWY-5464',
        Common_Name: 'superpathway of cytosolic glycolysis (plants), pyruvate dehydrogenase and TCA cycle',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5464',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of cytosolic glycolysis (plants), pyruvate dehydrogenase and TCA cycle',
    },
    {
        BioCyc_ID: 'PWY-7712',
        Common_Name: 'deoxynivalenol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Tricothecene-Biosynthesis|PWY-7712',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Tricothecene Biosynthesis|deoxynivalenol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7712',
        Common_Name: 'deoxynivalenol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Tricothecene-Biosynthesis|PWY-7712',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Tricothecene Biosynthesis|deoxynivalenol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8087',
        Common_Name: 'indole-3-acetate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|AUXINS-DEGRADATION|PWY-8087',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Indole-3-acetate Degradation|indole-3-acetate degradation II',
    },
    {
        BioCyc_ID: 'PWY-8087',
        Common_Name: 'indole-3-acetate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|AUXINS-DEGRADATION|PWY-8087',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Indole-3-acetate Degradation|indole-3-acetate degradation II',
    },
    {
        BioCyc_ID: 'PWY-8121',
        Common_Name: '2-deoxy-D-glucose 6-phosphate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY-8121',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|2-deoxy-D-glucose 6-phosphate degradation',
    },
    {
        BioCyc_ID: 'PWY-7670',
        Common_Name: 'fusaridione A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7670',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|fusaridione A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7670',
        Common_Name: 'fusaridione A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7670',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|fusaridione A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7537',
        Common_Name: 'superpathway of fumitremorgin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7537',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of fumitremorgin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6822',
        Common_Name: '&iota;-carrageenan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Carrageenan-Degradation|PWY-6822',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Carrageenan Degradation|&iota;-carrageenan degradation',
    },
    {
        BioCyc_ID: 'PWY-6822',
        Common_Name: '&iota;-carrageenan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Carrageenan-Degradation|PWY-6822',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Carrageenan Degradation|&iota;-carrageenan degradation',
    },
    {
        BioCyc_ID: 'PWY18C3-23',
        Common_Name: 'guaiacol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Volatile-Benzenoids-Biosynthesis|PWY18C3-23',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Volatile Benzenoid Biosynthesis|guaiacol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-241',
        Common_Name: 'C4 photosynthetic carbon assimilation cycle, NADP-ME type',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photosynthesis|PWY-241',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photosynthesis|C4 photosynthetic carbon assimilation cycle, NADP-ME type',
    },
    {
        BioCyc_ID: 'PWY-7946',
        Common_Name: 'sarcinaxanthin diglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C50-Carotenoids-Biosynthesis|PWY-7946',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>50</sub> Carotenoid Biosynthesis|sarcinaxanthin diglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7946',
        Common_Name: 'sarcinaxanthin diglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C50-Carotenoids-Biosynthesis|PWY-7946',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>50</sub> Carotenoid Biosynthesis|sarcinaxanthin diglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5662',
        Common_Name: 'glucosylglycerate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Glucosylglycerate-Biosynthesis|PWY-5662',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Glucosylglycerate Biosynthesis|glucosylglycerate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8197',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9,46 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8197',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9,46 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8197',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9,46 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8197',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9,46 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8197',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9,46 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8197',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9,46 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8197',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9,46 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8197',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9,46 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2242',
        Common_Name: 'ammonia oxidation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-oxidation|PWY-2242',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Oxidation|ammonia oxidation III',
    },
    {
        BioCyc_ID: 'PWY-8000',
        Common_Name: 'vitamin K degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|COFACTOR-DEGRADATION|Vitamin-Degradation|PWY-8000',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cofactor, Carrier, and Vitamin Degradation|Vitamin degradation|vitamin K degradation',
    },
    {
        BioCyc_ID: 'PWY-7885',
        Common_Name: 'phosphatidylinositol mannoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Glycolipids-Biosynthesis|PWY-7885',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Glycolipid Biosynthesis|phosphatidylinositol mannoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7163',
        Common_Name: 'polymethylated kaempferol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7163',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|polymethylated kaempferol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5694',
        Common_Name: 'allantoin degradation to glyoxylate I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Allantoin-degradation|PWY-5694',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Allantoin Degradation|allantoin degradation to glyoxylate I',
    },
    {
        BioCyc_ID: 'PWY-5694',
        Common_Name: 'allantoin degradation to glyoxylate I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5694',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|allantoin degradation to glyoxylate I',
    },
    {
        BioCyc_ID: 'PWY-7730',
        Common_Name: 'T-2 toxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Tricothecene-Biosynthesis|PWY-7730',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Tricothecene Biosynthesis|T-2 toxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7730',
        Common_Name: 'T-2 toxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Tricothecene-Biosynthesis|PWY-7730',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Tricothecene Biosynthesis|T-2 toxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2421',
        Common_Name: 'indole-3-acetate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|AUXINS-DEGRADATION|PWY-2421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Indole-3-acetate Degradation|indole-3-acetate degradation I',
    },
    {
        BioCyc_ID: 'PWY-2421',
        Common_Name: 'indole-3-acetate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|AUXINS-DEGRADATION|PWY-2421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Indole-3-acetate Degradation|indole-3-acetate degradation I',
    },
    {
        BioCyc_ID: 'PWY-6377',
        Common_Name: '&alpha;-tocopherol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|COFACTOR-DEGRADATION|Vitamin-Degradation|PWY-6377',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cofactor, Carrier, and Vitamin Degradation|Vitamin degradation|&alpha;-tocopherol degradation',
    },
    {
        BioCyc_ID: 'PWY-6314',
        Common_Name: 'plumbagin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-6314',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|plumbagin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4FS-12',
        Common_Name: 'VTC2 cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY4FS-12',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|VTC2 cycle',
    },
    {
        BioCyc_ID: 'PWY-8384',
        Common_Name: 'vitamin B<sub>6</sub> degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|COFACTOR-DEGRADATION|Vitamin-Degradation|Vitamin-B6-Degradation|PWY-8384',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cofactor, Carrier, and Vitamin Degradation|Vitamin degradation|Vitamin B6 Degradation|vitamin B<sub>6</sub> degradation II',
    },
    {
        BioCyc_ID: 'PWY-8154',
        Common_Name: 'superpathway of cholesterol degradation III (oxidase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Cholesterol-Degradation|PWY-8154',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Cholesterol Degradation|superpathway of cholesterol degradation III (oxidase)',
    },
    {
        BioCyc_ID: 'PWY-8154',
        Common_Name: 'superpathway of cholesterol degradation III (oxidase)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8154',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of cholesterol degradation III (oxidase)',
    },
    {
        BioCyc_ID: 'PWY-6821',
        Common_Name: '&kappa;-carrageenan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Carrageenan-Degradation|PWY-6821',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Carrageenan Degradation|&kappa;-carrageenan degradation',
    },
    {
        BioCyc_ID: 'PWY-6821',
        Common_Name: '&kappa;-carrageenan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Carrageenan-Degradation|PWY-6821',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Carrageenan Degradation|&kappa;-carrageenan degradation',
    },
    {
        BioCyc_ID: 'PWY-6444',
        Common_Name: 'benzoate biosynthesis II (CoA-independent, non-&beta;-oxidative)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Benzoate-Biosynthesis|PWY-6444',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Benzoate Biosynthesis|benzoate biosynthesis II (CoA-independent, non-&beta;-oxidative)',
    },
    {
        BioCyc_ID: 'PWY-8270',
        Common_Name: 'cyclic electron flow',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-8270',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|cyclic electron flow',
    },
    {
        BioCyc_ID: 'PWY-8270',
        Common_Name: 'cyclic electron flow',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photosynthesis|PWY-8270',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photosynthesis|cyclic electron flow',
    },
    {
        BioCyc_ID: 'PWY-7945',
        Common_Name: "2,2'-bis-(4-hydroxy-3-methybut-2-enyl)-&beta;,&beta;-carotene monoglucoside biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C50-Carotenoids-Biosynthesis|PWY-7945',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>50</sub> Carotenoid Biosynthesis|2,2'-bis-(4-hydroxy-3-methybut-2-enyl)-&beta;,&beta;-carotene monoglucoside biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-7945',
        Common_Name: "2,2'-bis-(4-hydroxy-3-methybut-2-enyl)-&beta;,&beta;-carotene monoglucoside biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C50-Carotenoids-Biosynthesis|PWY-7945',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>50</sub> Carotenoid Biosynthesis|2,2'-bis-(4-hydroxy-3-methybut-2-enyl)-&beta;,&beta;-carotene monoglucoside biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-5499',
        Common_Name: 'vitamin B<sub>6</sub> degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|COFACTOR-DEGRADATION|Vitamin-Degradation|Vitamin-B6-Degradation|PWY-5499',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cofactor, Carrier, and Vitamin Degradation|Vitamin degradation|Vitamin B6 Degradation|vitamin B<sub>6</sub> degradation I',
    },
    {
        BioCyc_ID: 'GAMMAHEXCHLORDEG-PWY',
        Common_Name: '&gamma;-hexachlorocyclohexane degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|GAMMAHEXCHLORDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|&gamma;-hexachlorocyclohexane degradation',
    },
    {
        BioCyc_ID: 'PWY-6762',
        Common_Name: 'salicylate glucosides biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|Salicylate-Glycosylation|PWY-6762',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|Salicylate Glycosylation|salicylate glucosides biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-8222',
        Common_Name: '<i>Escherichia coli</i> serotype O:128 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8222',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:128 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8222',
        Common_Name: '<i>Escherichia coli</i> serotype O:128 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8222',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:128 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8222',
        Common_Name: '<i>Escherichia coli</i> serotype O:128 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8222',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:128 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8222',
        Common_Name: '<i>Escherichia coli</i> serotype O:128 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8222',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:128 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7576',
        Common_Name: 'nitrogen fixation II (flavodoxin)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrogen-Fixation|PWY-7576',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrogen fixation|nitrogen fixation II (flavodoxin)',
    },
    {
        BioCyc_ID: 'PWY-7666',
        Common_Name: 'galactolipid biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Glycolipids-Biosynthesis|PWY-7666',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Glycolipid Biosynthesis|galactolipid biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7171',
        Common_Name: 'flavonol acylglucoside biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7171',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|flavonol acylglucoside biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6141',
        Common_Name: 'archaetidylserine and archaetidylethanolamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Archaeal-Lipid-Biosynthesis|PWY-6141',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Archaeal Lipid Biosynthesis|archaetidylserine and archaetidylethanolamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6141',
        Common_Name: 'archaetidylserine and archaetidylethanolamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-6141',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|archaetidylserine and archaetidylethanolamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6141',
        Common_Name: 'archaetidylserine and archaetidylethanolamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6141',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|archaetidylserine and archaetidylethanolamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7077',
        Common_Name: '<i>N</i>-acetyl-D-galactosamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY-7077',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|<i>N</i>-acetyl-D-galactosamine degradation',
    },
    {
        BioCyc_ID: 'PWY-5822',
        Common_Name: 'trichloroethene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|PWY-5822',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|trichloroethene degradation',
    },
    {
        BioCyc_ID: 'PWY-7687',
        Common_Name: 'stipitatate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7687',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|stipitatate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-162',
        Common_Name: 'superpathway of pyrimidine ribonucleotides <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Ribonucleot-De-Novo-Biosyn|PWY0-162',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Ribonucleotide <i>De Novo</i> Biosynthesis|superpathway of pyrimidine ribonucleotides <i>de novo</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-162',
        Common_Name: 'superpathway of pyrimidine ribonucleotides <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-162',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pyrimidine ribonucleotides <i>de novo</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5941',
        Common_Name: 'glycogen degradation II',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-5941',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|glycogen degradation II',
    },
    {
        BioCyc_ID: 'PWY-5941',
        Common_Name: 'glycogen degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycogen-Degradation|PWY-5941',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycogen Degradation|glycogen degradation II',
    },
    {
        BioCyc_ID: 'PWY-5941',
        Common_Name: 'glycogen degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycogen-Degradation|PWY-5941',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycogen Degradation|glycogen degradation II',
    },
    {
        BioCyc_ID: 'PWY-981',
        Common_Name: 'salicylate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Salicylate-Biosynthesis|PWY-981',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Salicylate Biosynthesis|salicylate biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-101',
        Common_Name: 'photosynthesis light reactions',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-101',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|photosynthesis light reactions',
    },
    {
        BioCyc_ID: 'PWY-101',
        Common_Name: 'photosynthesis light reactions',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photosynthesis|PWY-101',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photosynthesis|photosynthesis light reactions',
    },
    {
        BioCyc_ID: 'PWY-7944',
        Common_Name: 'bacterioruberin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C50-Carotenoids-Biosynthesis|PWY-7944',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>50</sub> Carotenoid Biosynthesis|bacterioruberin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7944',
        Common_Name: 'bacterioruberin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C50-Carotenoids-Biosynthesis|PWY-7944',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>50</sub> Carotenoid Biosynthesis|bacterioruberin biosynthesis',
    },
    {
        BioCyc_ID: '12DICHLORETHDEG-PWY',
        Common_Name: '1,2-dichloroethane degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|12DICHLORETHDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|1,2-dichloroethane degradation',
    },
    {
        BioCyc_ID: 'PWY-6624',
        Common_Name: 'salicylate glucosides biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|Salicylate-Glycosylation|PWY-6624',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|Salicylate Glycosylation|salicylate glucosides biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8257',
        Common_Name: '<i>Escherichia coli</i> serotype O:52 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:52 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8257',
        Common_Name: '<i>Escherichia coli</i> serotype O:52 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:52 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8257',
        Common_Name: '<i>Escherichia coli</i> serotype O:52 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:52 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8257',
        Common_Name: '<i>Escherichia coli</i> serotype O:52 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:52 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'N2FIX-PWY',
        Common_Name: 'nitrogen fixation I (ferredoxin)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrogen-Fixation|N2FIX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrogen fixation|nitrogen fixation I (ferredoxin)',
    },
    {
        BioCyc_ID: 'PWY-782',
        Common_Name: 'glycolipid desaturation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Glycolipids-Biosynthesis|PWY-782',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Glycolipid Biosynthesis|glycolipid desaturation',
    },
    {
        BioCyc_ID: 'PWY-6631',
        Common_Name: 'tricetin methylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-6631',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|tricetin methylation',
    },
    {
        BioCyc_ID: 'PWY-6292',
        Common_Name: 'superpathway of L-cysteine biosynthesis (mammalian)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|PWY-6292',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|superpathway of L-cysteine biosynthesis (mammalian)',
    },
    {
        BioCyc_ID: 'PWY-6292',
        Common_Name: 'superpathway of L-cysteine biosynthesis (mammalian)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6292',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-cysteine biosynthesis (mammalian)',
    },
    {
        BioCyc_ID: 'PWY-7562',
        Common_Name: '3,6-anhydro-&alpha;-L-galactopyranose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY-7562',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|3,6-anhydro-&alpha;-L-galactopyranose degradation',
    },
    {
        BioCyc_ID: 'PCPDEG-PWY',
        Common_Name: 'pentachlorophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|PCPDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|pentachlorophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-8166',
        Common_Name: 'emodin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8166',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|emodin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4LZ-257',
        Common_Name: 'superpathway of fermentation (<i>Chlamydomonas reinhardtii</i>)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|PWY4LZ-257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|superpathway of fermentation (<i>Chlamydomonas reinhardtii</i>)',
    },
    {
        BioCyc_ID: 'PWY4LZ-257',
        Common_Name: 'superpathway of fermentation (<i>Chlamydomonas reinhardtii</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY4LZ-257',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of fermentation (<i>Chlamydomonas reinhardtii</i>)',
    },
    {
        BioCyc_ID: 'GLYCOCAT-PWY',
        Common_Name: 'glycogen degradation I',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|GLYCOCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|glycogen degradation I',
    },
    {
        BioCyc_ID: 'GLYCOCAT-PWY',
        Common_Name: 'glycogen degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycogen-Degradation|GLYCOCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycogen Degradation|glycogen degradation I',
    },
    {
        BioCyc_ID: 'GLYCOCAT-PWY',
        Common_Name: 'glycogen degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycogen-Degradation|GLYCOCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycogen Degradation|glycogen degradation I',
    },
    {
        BioCyc_ID: 'PWY-8321',
        Common_Name: 'salicylate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Salicylate-Biosynthesis|PWY-8321',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Salicylate Biosynthesis|salicylate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7117',
        Common_Name: 'C4 photosynthetic carbon assimilation cycle, PEPCK type',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photosynthesis|PWY-7117',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photosynthesis|C4 photosynthetic carbon assimilation cycle, PEPCK type',
    },
    {
        BioCyc_ID: 'PWY18HP-2',
        Common_Name: 'decaprenoxanthin diglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C50-Carotenoids-Biosynthesis|PWY18HP-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>50</sub> Carotenoid Biosynthesis|decaprenoxanthin diglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY18HP-2',
        Common_Name: 'decaprenoxanthin diglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C50-Carotenoids-Biosynthesis|PWY18HP-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>50</sub> Carotenoid Biosynthesis|decaprenoxanthin diglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'GLNSYN-PWY',
        Common_Name: 'L-glutamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-Assimilation|GLNSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Assimilation|L-glutamine biosynthesis I',
    },
    {
        BioCyc_ID: 'GLNSYN-PWY',
        Common_Name: 'L-glutamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMINE-SYN|GLNSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamine Biosynthesis|L-glutamine biosynthesis I',
    },
    {
        BioCyc_ID: 'PCEDEG-PWY',
        Common_Name: 'tetrachloroethene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|PCEDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|tetrachloroethene degradation',
    },
    {
        BioCyc_ID: 'PWY-6623',
        Common_Name: 'salicylate glucosides biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|Salicylate-Glycosylation|PWY-6623',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|Salicylate Glycosylation|salicylate glucosides biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8204',
        Common_Name: '<i>Salmonella enterica</i> serotype O:54 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8204',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:54 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8204',
        Common_Name: '<i>Salmonella enterica</i> serotype O:54 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8204',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:54 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8204',
        Common_Name: '<i>Salmonella enterica</i> serotype O:54 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8204',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:54 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8204',
        Common_Name: '<i>Salmonella enterica</i> serotype O:54 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8204',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:54 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6648',
        Common_Name: 'rhamnolipid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Glycolipids-Biosynthesis|PWY-6648',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Glycolipid Biosynthesis|rhamnolipid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7191',
        Common_Name: 'kaempferol diglycoside biosynthesis (pollen-specific)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7191',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|kaempferol diglycoside biosynthesis (pollen-specific)',
    },
    {
        BioCyc_ID: 'PWY-6396',
        Common_Name: 'superpathway of 2,3-butanediol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Butanediol-Biosynthesis|PWY-6396',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Butanediol Biosynthesis|superpathway of 2,3-butanediol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6396',
        Common_Name: 'superpathway of 2,3-butanediol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6396',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of 2,3-butanediol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1300',
        Common_Name: '2-<I>O</I>-&alpha;-mannosyl-D-glycerate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY0-1300',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|2-<I>O</I>-&alpha;-mannosyl-D-glycerate degradation',
    },
    {
        BioCyc_ID: 'PWY-6438',
        Common_Name: 'phenylphenalenone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-6438',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|phenylphenalenone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7662',
        Common_Name: 'glycogen degradation III (via anhydrofructose)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Glycogen-Degradation|PWY-7662',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Glycogen Degradation|glycogen degradation III (via anhydrofructose)',
    },
    {
        BioCyc_ID: 'PWY-7662',
        Common_Name: 'glycogen degradation III (via anhydrofructose)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Glycogen-Degradation|PWY-7662',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Glycogen Degradation|glycogen degradation III (via anhydrofructose)',
    },
    {
        BioCyc_ID: 'PWY-6406',
        Common_Name: 'salicylate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Salicylate-Biosynthesis|PWY-6406',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Salicylate Biosynthesis|salicylate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7115',
        Common_Name: 'C4 photosynthetic carbon assimilation cycle, NAD-ME type',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photosynthesis|PWY-7115',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photosynthesis|C4 photosynthetic carbon assimilation cycle, NAD-ME type',
    },
    {
        BioCyc_ID: 'PWY-6964',
        Common_Name: 'ammonia assimilation cycle II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-Assimilation|PWY-6964',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Assimilation|ammonia assimilation cycle II',
    },
    {
        BioCyc_ID: 'PWY-6482',
        Common_Name: 'diphthamide biosynthesis I (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Amino-Acids-Modification|DiphthamideBiosynthesis|PWY-6482',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Modification of Amino Acid Within Proteins|Diphthamide Biosynthesis|diphthamide biosynthesis I (archaea)',
    },
    {
        BioCyc_ID: 'PWY-8228',
        Common_Name: '<i>Porphyromonas gingivalis</i> O-LPS antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8228',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Porphyromonas gingivalis</i> O-LPS antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8228',
        Common_Name: '<i>Porphyromonas gingivalis</i> O-LPS antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8228',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Porphyromonas gingivalis</i> O-LPS antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8228',
        Common_Name: '<i>Porphyromonas gingivalis</i> O-LPS antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8228',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Porphyromonas gingivalis</i> O-LPS antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8228',
        Common_Name: '<i>Porphyromonas gingivalis</i> O-LPS antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8228',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Porphyromonas gingivalis</i> O-LPS antigen biosynthesis',
    },
    {
        BioCyc_ID: 'CYANCAT-PWY',
        Common_Name: 'cyanate degradation',
        MetaCyc_hierarchy_IDs: 'Detoxification|CYANCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|cyanate degradation',
    },
    {
        BioCyc_ID: 'CYANCAT-PWY',
        Common_Name: 'cyanate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|CYANCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|cyanate degradation',
    },
    {
        BioCyc_ID: 'PWY-7738',
        Common_Name: 'polyacyltrehalose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Glycolipids-Biosynthesis|PWY-7738',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Glycolipid Biosynthesis|polyacyltrehalose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7140',
        Common_Name: 'myricetin gentiobioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7140',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|myricetin gentiobioside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6676',
        Common_Name: 'superpathway of sulfide oxidation (phototrophic sulfur bacteria)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfide-Oxidation|PWY-6676',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfide Oxidation|superpathway of sulfide oxidation (phototrophic sulfur bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6676',
        Common_Name: 'superpathway of sulfide oxidation (phototrophic sulfur bacteria)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6676',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sulfide oxidation (phototrophic sulfur bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6906',
        Common_Name: 'chitin derivatives degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY-6906',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|chitin derivatives degradation',
    },
    {
        BioCyc_ID: 'PWY-7695',
        Common_Name: 'aurofusarin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7695',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|aurofusarin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6771',
        Common_Name: 'rhamnogalacturonan type I degradation II (bacteria)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Rhamnogalacturonan-Degradation|PWY-6771',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Rhamnogalacturonan Type I Degradation|rhamnogalacturonan type I degradation II (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6771',
        Common_Name: 'rhamnogalacturonan type I degradation II (bacteria)',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Rhamnogalacturonan-Degradation|PWY-6771',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Rhamnogalacturonan Type I Degradation|rhamnogalacturonan type I degradation II (bacteria)',
    },
    {
        BioCyc_ID: 'PWY18C3-22',
        Common_Name: 'methylsalicylate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Salicylate-Biosynthesis|PWY18C3-22',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Salicylate Biosynthesis|methylsalicylate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3781',
        Common_Name: 'aerobic respiration I (cytochrome c)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY-3781',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|aerobic respiration I (cytochrome c)',
    },
    {
        BioCyc_ID: 'PWY-3781',
        Common_Name: 'aerobic respiration I (cytochrome c)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-3781',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|aerobic respiration I (cytochrome c)',
    },
    {
        BioCyc_ID: 'AMMASSIM-PWY',
        Common_Name: 'ammonia assimilation cycle III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-Assimilation|AMMASSIM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Assimilation|ammonia assimilation cycle III',
    },
    {
        BioCyc_ID: 'PWY-7546',
        Common_Name: 'diphthamide biosynthesis II (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Amino-Acids-Modification|DiphthamideBiosynthesis|PWY-7546',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Modification of Amino Acid Within Proteins|Diphthamide Biosynthesis|diphthamide biosynthesis II (eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-8232',
        Common_Name: '<i>Shigella boydii</i> serotype 6 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8232',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Shigella boydii</i> serotype 6 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8232',
        Common_Name: '<i>Shigella boydii</i> serotype 6 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8232',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Shigella boydii</i> serotype 6 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8232',
        Common_Name: '<i>Shigella boydii</i> serotype 6 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8232',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Shigella boydii</i> serotype 6 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8232',
        Common_Name: '<i>Shigella boydii</i> serotype 6 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8232',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Shigella boydii</i> serotype 6 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7683',
        Common_Name: 'nitrite reduction (hemoglobin)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|PWY-7683',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|nitrite reduction (hemoglobin)',
    },
    {
        BioCyc_ID: 'PWY-7902',
        Common_Name: 'glucosylglycerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Glycolipids-Biosynthesis|PWY-7902',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Glycolipid Biosynthesis|glucosylglycerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7495',
        Common_Name: 'gossypetin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7495',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|gossypetin metabolism',
    },
    {
        BioCyc_ID: 'PWY-7706',
        Common_Name: 'dapdiamides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7706',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|dapdiamides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6975',
        Common_Name: 'superpathway of erythromycin biosynthesis (without sugar biosynthesis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-6975',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|superpathway of erythromycin biosynthesis (without sugar biosynthesis)',
    },
    {
        BioCyc_ID: 'PWY-6975',
        Common_Name: 'superpathway of erythromycin biosynthesis (without sugar biosynthesis)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6975',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of erythromycin biosynthesis (without sugar biosynthesis)',
    },
    {
        BioCyc_ID: 'PWY-7395',
        Common_Name: 'D-galactosamine and <i>N</i>-acetyl-D-galactosamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|PWY-7395',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|D-galactosamine and <i>N</i>-acetyl-D-galactosamine degradation',
    },
    {
        BioCyc_ID: 'PWY-7757',
        Common_Name: 'bisphenol A degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PWY-7757',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|bisphenol A degradation',
    },
    {
        BioCyc_ID: 'PWY-8343',
        Common_Name: 'leinamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8343',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|leinamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8343',
        Common_Name: 'leinamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8343',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|leinamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6769',
        Common_Name: 'rhamnogalacturonan type I degradation I',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6769',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|rhamnogalacturonan type I degradation I',
    },
    {
        BioCyc_ID: 'PWY-6769',
        Common_Name: 'rhamnogalacturonan type I degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|Rhamnogalacturonan-Degradation|PWY-6769',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|Rhamnogalacturonan Type I Degradation|rhamnogalacturonan type I degradation I',
    },
    {
        BioCyc_ID: 'PWY-6769',
        Common_Name: 'rhamnogalacturonan type I degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|Rhamnogalacturonan-Degradation|PWY-6769',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|Rhamnogalacturonan Type I Degradation|rhamnogalacturonan type I degradation I',
    },
    {
        BioCyc_ID: 'PWY0-1347',
        Common_Name: 'NADH to trimethylamine <i>N</i>-oxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1347',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|NADH to trimethylamine <i>N</i>-oxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1347',
        Common_Name: 'NADH to trimethylamine <i>N</i>-oxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1347',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADH to trimethylamine <i>N</i>-oxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY-381',
        Common_Name: 'nitrate reduction II (assimilatory)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY-381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction II (assimilatory)',
    },
    {
        BioCyc_ID: 'PWY-5154',
        Common_Name: 'L-arginine biosynthesis III (via <i>N</i>-acetyl-L-citrulline)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ARGININE-SYN|PWY-5154',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-arginine Biosynthesis|L-arginine biosynthesis III (via <i>N</i>-acetyl-L-citrulline)',
    },
    {
        BioCyc_ID: 'PWY2DNV-5',
        Common_Name: '<i>H. pylori</i> 26695 O-antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY2DNV-5',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>H. pylori</i> 26695 O-antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2DNV-5',
        Common_Name: '<i>H. pylori</i> 26695 O-antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY2DNV-5',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>H. pylori</i> 26695 O-antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2DNV-5',
        Common_Name: '<i>H. pylori</i> 26695 O-antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY2DNV-5',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>H. pylori</i> 26695 O-antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2DNV-5',
        Common_Name: '<i>H. pylori</i> 26695 O-antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY2DNV-5',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>H. pylori</i> 26695 O-antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7387',
        Common_Name: 'hypotaurine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|PWY-7387',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|hypotaurine degradation',
    },
    {
        BioCyc_ID: 'PWY-7387',
        Common_Name: 'hypotaurine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-7387',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|hypotaurine degradation',
    },
    {
        BioCyc_ID: 'PWY-401',
        Common_Name: 'galactolipid biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Glycolipids-Biosynthesis|PWY-401',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Glycolipid Biosynthesis|galactolipid biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5059',
        Common_Name: 'pinobanksin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-5059',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|pinobanksin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7911',
        Common_Name: "6'-dechloromelleolide F biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7911',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|6'-dechloromelleolide F biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-7196',
        Common_Name: 'superpathway of pyrimidine ribonucleosides salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7196',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|superpathway of pyrimidine ribonucleosides salvage',
    },
    {
        BioCyc_ID: 'PWY-7196',
        Common_Name: 'superpathway of pyrimidine ribonucleosides salvage',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7196',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pyrimidine ribonucleosides salvage',
    },
    {
        BioCyc_ID: 'PWY-8093',
        Common_Name: 'D-apiose degradation II (to D-apionate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|D-Apiose-Degradation|PWY-8093',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-Apiose Degradation|D-apiose degradation II (to D-apionate)',
    },
    {
        BioCyc_ID: 'P281-PWY',
        Common_Name: '3-phenylpropanoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|P281-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|3-phenylpropanoate degradation',
    },
    {
        BioCyc_ID: 'P281-PWY',
        Common_Name: '3-phenylpropanoate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P281-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|3-phenylpropanoate degradation',
    },
    {
        BioCyc_ID: 'PWY-8435',
        Common_Name: 'yeast mannan degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-8435',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|yeast mannan degradation',
    },
    {
        BioCyc_ID: 'PWY-8392',
        Common_Name: 'D-arabinitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|D-Arabinitol-Degradation|PWY-8392',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|D-arabinitol Degradation|D-arabinitol degradation II',
    },
    {
        BioCyc_ID: 'PWY-8392',
        Common_Name: 'D-arabinitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|D-Arabinitol-Degradation|PWY-8392',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|D-arabinitol Degradation|D-arabinitol degradation II',
    },
    {
        BioCyc_ID: 'PWY0-1582',
        Common_Name: 'glycerol-3-phosphate to fumarate electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1582',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|glycerol-3-phosphate to fumarate electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1582',
        Common_Name: 'glycerol-3-phosphate to fumarate electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1582',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|glycerol-3-phosphate to fumarate electron transfer',
    },
    {
        BioCyc_ID: 'PWY-5175',
        Common_Name: 'lactucaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5175',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lactucaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5175',
        Common_Name: 'lactucaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5175',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lactucaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5175',
        Common_Name: 'lactucaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5175',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lactucaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY490-3',
        Common_Name: 'nitrate reduction VI (assimilatory)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY490-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction VI (assimilatory)',
    },
    {
        BioCyc_ID: 'ARGSYNBSUB-PWY',
        Common_Name: 'L-arginine biosynthesis II (acetyl cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ARGININE-SYN|ARGSYNBSUB-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-arginine Biosynthesis|L-arginine biosynthesis II (acetyl cycle)',
    },
    {
        BioCyc_ID: 'PWY-8207',
        Common_Name: '<i>Escherichia coli</i> serotype O:85/<i>Salmonella enterica</i> serotype O:17 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8207',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:85/<i>Salmonella enterica</i> serotype O:17 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8207',
        Common_Name: '<i>Escherichia coli</i> serotype O:85/<i>Salmonella enterica</i> serotype O:17 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8207',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:85/<i>Salmonella enterica</i> serotype O:17 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8207',
        Common_Name: '<i>Escherichia coli</i> serotype O:85/<i>Salmonella enterica</i> serotype O:17 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8207',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:85/<i>Salmonella enterica</i> serotype O:17 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8207',
        Common_Name: '<i>Escherichia coli</i> serotype O:85/<i>Salmonella enterica</i> serotype O:17 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8207',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:85/<i>Salmonella enterica</i> serotype O:17 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4984',
        Common_Name: 'urea cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|PWY-4984',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|urea cycle',
    },
    {
        BioCyc_ID: 'PWY-7404',
        Common_Name: 'ceramide phosphoethanolamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-7404',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|ceramide phosphoethanolamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7150',
        Common_Name: 'polymethylated quercetin glucoside biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7150',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|polymethylated quercetin glucoside biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8298',
        Common_Name: 'streptozotocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8298',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|streptozotocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7317',
        Common_Name: 'superpathway of dTDP-glucose-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7317',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of dTDP-glucose-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7317',
        Common_Name: 'superpathway of dTDP-glucose-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7317',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|superpathway of dTDP-glucose-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8089',
        Common_Name: 'D-apiose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|D-Apiose-Degradation|PWY-8089',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-Apiose Degradation|D-apiose degradation I',
    },
    {
        BioCyc_ID: 'PWY-6690',
        Common_Name: 'cinnamate and 3-hydroxycinnamate degradation to 2-hydroxypentadienoate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PWY-6690',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|cinnamate and 3-hydroxycinnamate degradation to 2-hydroxypentadienoate',
    },
    {
        BioCyc_ID: 'PWY-1722',
        Common_Name: 'formate assimilation into 5,10-methylenetetrahydrofolate',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|PWY-1722',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|formate assimilation into 5,10-methylenetetrahydrofolate',
    },
    {
        BioCyc_ID: 'PWY-6790',
        Common_Name: 'L-arabinan degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6790',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|L-arabinan degradation',
    },
    {
        BioCyc_ID: 'DARABITOLUTIL-PWY',
        Common_Name: 'D-arabinitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|D-Arabinitol-Degradation|DARABITOLUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|D-arabinitol Degradation|D-arabinitol degradation I',
    },
    {
        BioCyc_ID: 'DARABITOLUTIL-PWY',
        Common_Name: 'D-arabinitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|D-Arabinitol-Degradation|DARABITOLUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|D-arabinitol Degradation|D-arabinitol degradation I',
    },
    {
        BioCyc_ID: 'PWY-7534',
        Common_Name: 'gliotoxin inactivation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7534',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|gliotoxin inactivation',
    },
    {
        BioCyc_ID: 'PWY-5358',
        Common_Name: 'tetrathionate reduction I (to thiosulfate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-5358',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|tetrathionate reduction I (to thiosulfate)',
    },
    {
        BioCyc_ID: 'PWY-5358',
        Common_Name: 'tetrathionate reduction I (to thiosulfate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Tetrathionate-Reduction|PWY-5358',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Tetrathionate Reduction|tetrathionate reduction I (to thiosulfate)',
    },
    {
        BioCyc_ID: 'PWY-6581',
        Common_Name: "spirilloxanthin and 2,2'-diketo-spirilloxanthin biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6581',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|spirilloxanthin and 2,2'-diketo-spirilloxanthin biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6581',
        Common_Name: "spirilloxanthin and 2,2'-diketo-spirilloxanthin biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6581',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|spirilloxanthin and 2,2'-diketo-spirilloxanthin biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6581',
        Common_Name: "spirilloxanthin and 2,2'-diketo-spirilloxanthin biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6581',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|spirilloxanthin and 2,2'-diketo-spirilloxanthin biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-7750',
        Common_Name: 'carbon monoxide oxidation to CO<sub>2</sub>',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|PWY-7750',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|carbon monoxide oxidation to CO<sub>2</sub>',
    },
    {
        BioCyc_ID: 'PWY-5675',
        Common_Name: 'nitrate reduction V (assimilatory)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY-5675',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction V (assimilatory)',
    },
    {
        BioCyc_ID: 'PWY-7400',
        Common_Name: 'L-arginine biosynthesis IV (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ARGININE-SYN|PWY-7400',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-arginine Biosynthesis|L-arginine biosynthesis IV (archaea)',
    },
    {
        BioCyc_ID: 'PWY-8235',
        Common_Name: '<i>Escherichia coli</i> serotype O:21/<i>Salmonella enterica</i> serotype O:38 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8235',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:21/<i>Salmonella enterica</i> serotype O:38 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8235',
        Common_Name: '<i>Escherichia coli</i> serotype O:21/<i>Salmonella enterica</i> serotype O:38 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8235',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:21/<i>Salmonella enterica</i> serotype O:38 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8235',
        Common_Name: '<i>Escherichia coli</i> serotype O:21/<i>Salmonella enterica</i> serotype O:38 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8235',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:21/<i>Salmonella enterica</i> serotype O:38 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8235',
        Common_Name: '<i>Escherichia coli</i> serotype O:21/<i>Salmonella enterica</i> serotype O:38 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8235',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:21/<i>Salmonella enterica</i> serotype O:38 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6510',
        Common_Name: 'methanol oxidation to formaldehyde II',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Methanol-Oxidation|PWY-6510',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Methanol Oxidation|methanol oxidation to formaldehyde II',
    },
    {
        BioCyc_ID: 'PWY-7838',
        Common_Name: 'globo-series glycosphingolipids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-7838',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|globo-series glycosphingolipids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6401',
        Common_Name: "hispidol and hispidol 4'-<i>O</i>-&beta;-<i>D</i>-glucoside biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|AURONE-SYN|PWY-6401',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Aurone Biosynthesis|hispidol and hispidol 4'-<i>O</i>-&beta;-<i>D</i>-glucoside biosynthesis",
    },
    {
        BioCyc_ID: 'PWY1A0-6325',
        Common_Name: 'actinorhodin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY1A0-6325',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|actinorhodin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7434',
        Common_Name: 'terminal <i>O</i>-glycans residues modification (via type 2 precursor disaccharide)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7434',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|terminal <i>O</i>-glycans residues modification (via type 2 precursor disaccharide)',
    },
    {
        BioCyc_ID: 'PWY-7434',
        Common_Name: 'terminal <i>O</i>-glycans residues modification (via type 2 precursor disaccharide)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7434',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|terminal <i>O</i>-glycans residues modification (via type 2 precursor disaccharide)',
    },
    {
        BioCyc_ID: 'PWY-7434',
        Common_Name: 'terminal <i>O</i>-glycans residues modification (via type 2 precursor disaccharide)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7434',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|terminal <i>O</i>-glycans residues modification (via type 2 precursor disaccharide)',
    },
    {
        BioCyc_ID: 'PWY-7434',
        Common_Name: 'terminal <i>O</i>-glycans residues modification (via type 2 precursor disaccharide)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7434',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|terminal <i>O</i>-glycans residues modification (via type 2 precursor disaccharide)',
    },
    {
        BioCyc_ID: 'PWY-6693',
        Common_Name: 'D-galactose degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|GALACTOSE-DEGRADATION|PWY-6693',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Galactose Degradation|D-galactose degradation IV',
    },
    {
        BioCyc_ID: 'HCAMHPDEG-PWY',
        Common_Name: '3-phenylpropanoate and 3-(3-hydroxyphenyl)propanoate degradation to 2-hydroxypentadienoate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|HCAMHPDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|3-phenylpropanoate and 3-(3-hydroxyphenyl)propanoate degradation to 2-hydroxypentadienoate',
    },
    {
        BioCyc_ID: 'PWY-6509',
        Common_Name: 'methanol oxidation to formaldehyde III',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Methanol-Oxidation|PWY-6509',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Methanol Oxidation|methanol oxidation to formaldehyde III',
    },
    {
        BioCyc_ID: 'PWY-7857',
        Common_Name: 'adlupulone and adhumulone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|TERPENOPHENOLICS-SYN|PWY-7857',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenophenolic Biosynthesis|adlupulone and adhumulone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5506',
        Common_Name: 'methanol oxidation to formaldehyde IV',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Methanol-Oxidation|PWY-5506',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Methanol Oxidation|methanol oxidation to formaldehyde IV',
    },
    {
        BioCyc_ID: 'PWY-5342',
        Common_Name: 'ajugose biosynthesis I (galactinol-dependent)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-5342',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|ajugose biosynthesis I (galactinol-dependent)',
    },
    {
        BioCyc_ID: 'PWY-5342',
        Common_Name: 'ajugose biosynthesis I (galactinol-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|PWY-5342',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|ajugose biosynthesis I (galactinol-dependent)',
    },
    {
        BioCyc_ID: 'PWY-3861',
        Common_Name: 'mannitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|Mannitol-Degradation|PWY-3861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|mannitol degradation|mannitol degradation II',
    },
    {
        BioCyc_ID: 'PWY-3861',
        Common_Name: 'mannitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|Mannitol-Degradation|PWY-3861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|mannitol degradation|mannitol degradation II',
    },
    {
        BioCyc_ID: 'PWY-5957',
        Common_Name: 'L-nicotianamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-5957',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-nicotianamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5957',
        Common_Name: 'L-nicotianamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-5957',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|L-nicotianamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1353',
        Common_Name: 'succinate to cytochrome <i>bd</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1353',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|succinate to cytochrome <i>bd</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1353',
        Common_Name: 'succinate to cytochrome <i>bd</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1353',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|succinate to cytochrome <i>bd</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-7636',
        Common_Name: 'astaxanthin biosynthesis (flowering plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7636',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin biosynthesis (flowering plants)',
    },
    {
        BioCyc_ID: 'PWY-7636',
        Common_Name: 'astaxanthin biosynthesis (flowering plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7636',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin biosynthesis (flowering plants)',
    },
    {
        BioCyc_ID: 'PWY-7636',
        Common_Name: 'astaxanthin biosynthesis (flowering plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7636',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin biosynthesis (flowering plants)',
    },
    {
        BioCyc_ID: 'PWY-6966',
        Common_Name: 'methanol oxidation to formaldehyde I',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Methanol-Oxidation|PWY-6966',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Methanol Oxidation|methanol oxidation to formaldehyde I',
    },
    {
        BioCyc_ID: 'PWY-6523',
        Common_Name: 'nitrite-dependent anaerobic methane oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Denitrification|PWY-6523',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Denitrification|nitrite-dependent anaerobic methane oxidation',
    },
    {
        BioCyc_ID: 'PWY-6523',
        Common_Name: 'nitrite-dependent anaerobic methane oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Methane-Oxidation|PWY-6523',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|methane oxidation to methanol|nitrite-dependent anaerobic methane oxidation',
    },
    {
        BioCyc_ID: 'PWY0-901',
        Common_Name: 'L-selenocysteine biosynthesis I (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|Selenocysteine-Biosynthesis|PWY0-901',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-selenocysteine Biosynthesis|L-selenocysteine biosynthesis I (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7290',
        Common_Name: '<i>Escherichia coli</i> serotype O:86 <i>O</i> antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-7290',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:86 <i>O</i> antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7290',
        Common_Name: '<i>Escherichia coli</i> serotype O:86 <i>O</i> antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-7290',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:86 <i>O</i> antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7290',
        Common_Name: '<i>Escherichia coli</i> serotype O:86 <i>O</i> antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-7290',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:86 <i>O</i> antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7290',
        Common_Name: '<i>Escherichia coli</i> serotype O:86 <i>O</i> antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-7290',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:86 <i>O</i> antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4741',
        Common_Name: 'creatinine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Creatinine-Degradation|PWY-4741',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Creatinine Degradation|creatinine degradation III',
    },
    {
        BioCyc_ID: 'PWY-7841',
        Common_Name: 'neolacto-series glycosphingolipids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-7841',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|neolacto-series glycosphingolipids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1901',
        Common_Name: 'aurone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|AURONE-SYN|PWY-1901',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Aurone Biosynthesis|aurone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5737',
        Common_Name: '(5<i>R</i>)-carbapenem carboxylate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5737',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|(5<i>R</i>)-carbapenem carboxylate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7809',
        Common_Name: 'superpathway of tetracycline and oxytetracycline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7809',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|superpathway of tetracycline and oxytetracycline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7809',
        Common_Name: 'superpathway of tetracycline and oxytetracycline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7809',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of tetracycline and oxytetracycline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6317',
        Common_Name: 'D-galactose degradation I (Leloir pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|GALACTOSE-DEGRADATION|PWY-6317',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Galactose Degradation|D-galactose degradation I (Leloir pathway)',
    },
    {
        BioCyc_ID: 'PWY-6080',
        Common_Name: '4-ethylphenol degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PWY-6080',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|4-ethylphenol degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-4722',
        Common_Name: 'creatinine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Creatinine-Degradation|PWY-4722',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Creatinine Degradation|creatinine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7105',
        Common_Name: 'olivetol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|TERPENOPHENOLICS-SYN|PWY-7105',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenophenolic Biosynthesis|olivetol biosynthesis',
    },
    {
        BioCyc_ID: 'CRNFORCAT-PWY',
        Common_Name: 'creatinine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Creatinine-Degradation|CRNFORCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Creatinine Degradation|creatinine degradation I',
    },
    {
        BioCyc_ID: 'PWY-6397',
        Common_Name: 'mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|PWY-6397',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6397',
        Common_Name: 'mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6397',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
    },
    {
        BioCyc_ID: 'MANNIDEG-PWY',
        Common_Name: 'mannitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|Mannitol-Degradation|MANNIDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|mannitol degradation|mannitol degradation I',
    },
    {
        BioCyc_ID: 'MANNIDEG-PWY',
        Common_Name: 'mannitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|Mannitol-Degradation|MANNIDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|mannitol degradation|mannitol degradation I',
    },
    {
        BioCyc_ID: 'PWY-7599',
        Common_Name: 'anditomin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7599',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|anditomin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1590',
        Common_Name: 'NADH to hydrogen peroxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1590',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADH to hydrogen peroxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY-7939',
        Common_Name: 'chlorobactene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7939',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|chlorobactene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7939',
        Common_Name: 'chlorobactene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7939',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|chlorobactene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7939',
        Common_Name: 'chlorobactene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7939',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|chlorobactene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6281',
        Common_Name: 'L-selenocysteine biosynthesis II (archaea and eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|Selenocysteine-Biosynthesis|PWY-6281',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-selenocysteine Biosynthesis|L-selenocysteine biosynthesis II (archaea and eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-8211',
        Common_Name: '<i>Escherichia coli</i> serotype O:7 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8211',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:7 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8211',
        Common_Name: '<i>Escherichia coli</i> serotype O:7 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8211',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:7 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8211',
        Common_Name: '<i>Escherichia coli</i> serotype O:7 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8211',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:7 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8211',
        Common_Name: '<i>Escherichia coli</i> serotype O:7 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8211',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:7 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6346',
        Common_Name: 'staurosporine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6346',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|staurosporine biosynthesis',
    },
    {
        BioCyc_ID: 'SPHINGOLIPID-SYN-PWY',
        Common_Name: 'sphingolipid biosynthesis (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|SPHINGOLIPID-SYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|sphingolipid biosynthesis (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7161',
        Common_Name: 'polymethylated quercetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-7161',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|polymethylated quercetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-I9',
        Common_Name: 'L-cysteine biosynthesis VI (reverse transsulfuration)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|PWY-I9',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|L-cysteine biosynthesis VI (reverse transsulfuration)',
    },
    {
        BioCyc_ID: 'PWY-I9',
        Common_Name: 'L-cysteine biosynthesis VI (reverse transsulfuration)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-I9',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-cysteine biosynthesis VI (reverse transsulfuration)',
    },
    {
        BioCyc_ID: 'GALDEG-PWY',
        Common_Name: 'D-galactose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|GALACTOSE-DEGRADATION|GALDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Galactose Degradation|D-galactose degradation II',
    },
    {
        BioCyc_ID: 'PWY-8162',
        Common_Name: 'phenylethanol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PWY-8162',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|phenylethanol degradation',
    },
    {
        BioCyc_ID: 'PWY-5133',
        Common_Name: 'colupulone and cohumulone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|TERPENOPHENOLICS-SYN|PWY-5133',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenophenolic Biosynthesis|colupulone and cohumulone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6525',
        Common_Name: 'stellariose and mediose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6525',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|stellariose and mediose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6525',
        Common_Name: 'stellariose and mediose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|PWY-6525',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|stellariose and mediose biosynthesis',
    },
    {
        BioCyc_ID: 'GLYCEROLMETAB-PWY',
        Common_Name: 'glycerol degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|GLYCEROLMETAB-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Glycerol Degradation|glycerol degradation V',
    },
    {
        BioCyc_ID: 'GLYCEROLMETAB-PWY',
        Common_Name: 'glycerol degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|GLYCEROLMETAB-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Glycerol Degradation|glycerol degradation V',
    },
    {
        BioCyc_ID: 'PWY-6069',
        Common_Name: 'indigo biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-6069',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|indigo biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1544',
        Common_Name: 'proline to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1544',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|proline to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1544',
        Common_Name: 'proline to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1544',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|proline to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-6279',
        Common_Name: "myxol-2' fucoside biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6279',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|myxol-2' fucoside biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6279',
        Common_Name: "myxol-2' fucoside biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6279',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|myxol-2' fucoside biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6279',
        Common_Name: "myxol-2' fucoside biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6279',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|myxol-2' fucoside biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-8010',
        Common_Name: 'L-cysteine biosynthesis IX (Trichomonas vaginalis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|PWY-8010',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|L-cysteine biosynthesis IX (Trichomonas vaginalis)',
    },
    {
        BioCyc_ID: 'PWY-8241',
        Common_Name: '<i>Escherichia coli</i> serotype O:71/<i>Salmonella enterica</i> serotype O:28ac O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8241',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:71/<i>Salmonella enterica</i> serotype O:28ac O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8241',
        Common_Name: '<i>Escherichia coli</i> serotype O:71/<i>Salmonella enterica</i> serotype O:28ac O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8241',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:71/<i>Salmonella enterica</i> serotype O:28ac O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8241',
        Common_Name: '<i>Escherichia coli</i> serotype O:71/<i>Salmonella enterica</i> serotype O:28ac O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8241',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:71/<i>Salmonella enterica</i> serotype O:28ac O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8241',
        Common_Name: '<i>Escherichia coli</i> serotype O:71/<i>Salmonella enterica</i> serotype O:28ac O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8241',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:71/<i>Salmonella enterica</i> serotype O:28ac O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7569',
        Common_Name: 'arginomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7569',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|arginomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7836',
        Common_Name: 'ganglio-series glycosphingolipids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-7836',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|ganglio-series glycosphingolipids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7212',
        Common_Name: 'baicalein metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-7212',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|baicalein metabolism',
    },
    {
        BioCyc_ID: 'PWY18C3-17',
        Common_Name: 'superpathway of acylsucrose biosynthesis (wild tomato)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|ACYLSUGAR-BIOSYNTHESIS|PWY18C3-17',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Acylsugar biosynthesis|superpathway of acylsucrose biosynthesis (wild tomato)',
    },
    {
        BioCyc_ID: 'PWY18C3-17',
        Common_Name: 'superpathway of acylsucrose biosynthesis (wild tomato)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY18C3-17',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of acylsucrose biosynthesis (wild tomato)',
    },
    {
        BioCyc_ID: 'PWY-7568',
        Common_Name: 'L-galactose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|GALACTOSE-DEGRADATION|PWY-7568',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Galactose Degradation|L-galactose degradation',
    },
    {
        BioCyc_ID: 'PWY-1361',
        Common_Name: 'benzoyl-CoA degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzoyl-CoA-Degradation|PWY-1361',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzoyl-CoA Degradation|benzoyl-CoA degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-5808',
        Common_Name: 'hyperforin and adhyperforin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|TERPENOPHENOLICS-SYN|PWY-5808',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenophenolic Biosynthesis|hyperforin and adhyperforin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5337',
        Common_Name: 'stachyose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-5337',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|stachyose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5337',
        Common_Name: 'stachyose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|PWY-5337',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|stachyose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6130',
        Common_Name: 'glycerol degradation III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-6130',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|glycerol degradation III',
    },
    {
        BioCyc_ID: 'PWY-6130',
        Common_Name: 'glycerol degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY-6130',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Glycerol Degradation|glycerol degradation III',
    },
    {
        BioCyc_ID: 'PWY-6130',
        Common_Name: 'glycerol degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY-6130',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Glycerol Degradation|glycerol degradation III',
    },
    {
        BioCyc_ID: 'PWY-7861',
        Common_Name: '<i>N</i>-hydroxy-L-pipecolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7861',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|<i>N</i>-hydroxy-L-pipecolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY1YI0-3',
        Common_Name: 'succinate to cytochrome <i>c</i> oxidase via plastocyanin',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY1YI0-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|succinate to cytochrome <i>c</i> oxidase via plastocyanin',
    },
    {
        BioCyc_ID: 'PWY1YI0-3',
        Common_Name: 'succinate to cytochrome <i>c</i> oxidase via plastocyanin',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY1YI0-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|succinate to cytochrome <i>c</i> oxidase via plastocyanin',
    },
    {
        BioCyc_ID: 'PWY-5174',
        Common_Name: 'capsanthin and capsorubin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5174',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|capsanthin and capsorubin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5174',
        Common_Name: 'capsanthin and capsorubin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5174',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|capsanthin and capsorubin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5174',
        Common_Name: 'capsanthin and capsorubin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5174',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|capsanthin and capsorubin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8009',
        Common_Name: 'L-cysteine biosynthesis VIII (Thermococcus kodakarensis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|PWY-8009',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|L-cysteine biosynthesis VIII (Thermococcus kodakarensis)',
    },
    {
        BioCyc_ID: 'PWY-8192',
        Common_Name: '<i>Salmonella enterica</i> serotype O:4 O antigen biosynthesis (group B1)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8192',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:4 O antigen biosynthesis (group B1)',
    },
    {
        BioCyc_ID: 'PWY-8192',
        Common_Name: '<i>Salmonella enterica</i> serotype O:4 O antigen biosynthesis (group B1)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8192',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:4 O antigen biosynthesis (group B1)',
    },
    {
        BioCyc_ID: 'PWY-8192',
        Common_Name: '<i>Salmonella enterica</i> serotype O:4 O antigen biosynthesis (group B1)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8192',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:4 O antigen biosynthesis (group B1)',
    },
    {
        BioCyc_ID: 'PWY-8192',
        Common_Name: '<i>Salmonella enterica</i> serotype O:4 O antigen biosynthesis (group B1)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8192',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:4 O antigen biosynthesis (group B1)',
    },
    {
        BioCyc_ID: 'PWY-7733',
        Common_Name: '3-hydroxyquinaldate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7733',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|3-hydroxyquinaldate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7840',
        Common_Name: 'gala-series glycosphingolipids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-7840',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|gala-series glycosphingolipids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7325',
        Common_Name: 'salvigenin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-7325',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|salvigenin biosynthesis',
    },
    {
        BioCyc_ID: 'ASPARAGINE-DEG1-PWY-1',
        Common_Name: 'L-asparagine degradation III (mammalian)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Glyco-Amino-Acids-Degradation|ASPARAGINE-DEG1-PWY-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|Glyco-Amino Acid Degradation|L-asparagine degradation III (mammalian)',
    },
    {
        BioCyc_ID: 'ASPARAGINE-DEG1-PWY-1',
        Common_Name: 'L-asparagine degradation III (mammalian)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ASPARAGINE-DEG1-PWY-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-asparagine degradation III (mammalian)',
    },
    {
        BioCyc_ID: 'RHAMCAT-PWY',
        Common_Name: 'L-rhamnose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-rhamnose-Degradation|RHAMCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-rhamnose Degradation|L-rhamnose degradation I',
    },
    {
        BioCyc_ID: 'P321-PWY',
        Common_Name: 'benzoyl-CoA degradation III (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzoyl-CoA-Degradation|P321-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzoyl-CoA Degradation|benzoyl-CoA degradation III (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5132',
        Common_Name: 'lupulone and humulone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|TERPENOPHENOLICS-SYN|PWY-5132',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenophenolic Biosynthesis|lupulone and humulone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6524',
        Common_Name: 'lychnose and isolychnose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6524',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|lychnose and isolychnose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6524',
        Common_Name: 'lychnose and isolychnose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|PWY-6524',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|lychnose and isolychnose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-381',
        Common_Name: 'glycerol and glycerophosphodiester degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY0-381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Glycerol Degradation|glycerol and glycerophosphodiester degradation',
    },
    {
        BioCyc_ID: 'PWY0-381',
        Common_Name: 'glycerol and glycerophosphodiester degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY0-381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Glycerol Degradation|glycerol and glycerophosphodiester degradation',
    },
    {
        BioCyc_ID: 'PWY0-381',
        Common_Name: 'glycerol and glycerophosphodiester degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-381',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|glycerol and glycerophosphodiester degradation',
    },
    {
        BioCyc_ID: 'PWY-6662',
        Common_Name: 'superpathway of quinolone and alkylquinolone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-6662',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|superpathway of quinolone and alkylquinolone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6662',
        Common_Name: 'superpathway of quinolone and alkylquinolone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6662',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of quinolone and alkylquinolone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6794',
        Common_Name: "adenosine 5'-phosphoramidate biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|PWY-6794',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|adenosine 5'-phosphoramidate biosynthesis",
    },
    {
        BioCyc_ID: 'PWY0-1567',
        Common_Name: 'NADH to cytochrome <i>bo</i> oxidase electron transfer II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1567',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|NADH to cytochrome <i>bo</i> oxidase electron transfer II',
    },
    {
        BioCyc_ID: 'PWY0-1567',
        Common_Name: 'NADH to cytochrome <i>bo</i> oxidase electron transfer II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1567',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADH to cytochrome <i>bo</i> oxidase electron transfer II',
    },
    {
        BioCyc_ID: 'PWY-6287',
        Common_Name: 'neurosporene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6287',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|neurosporene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6287',
        Common_Name: 'neurosporene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6287',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|neurosporene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6287',
        Common_Name: 'neurosporene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6287',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|neurosporene biosynthesis',
    },
    {
        BioCyc_ID: 'CYSTSYN-PWY',
        Common_Name: 'L-cysteine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|CYSTSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|L-cysteine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8218',
        Common_Name: '<i>Escherichia coli</i> serotype O:56 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8218',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:56 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8218',
        Common_Name: '<i>Escherichia coli</i> serotype O:56 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8218',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:56 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8218',
        Common_Name: '<i>Escherichia coli</i> serotype O:56 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8218',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:56 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8218',
        Common_Name: '<i>Escherichia coli</i> serotype O:56 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8218',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:56 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8032',
        Common_Name: 'chloramphenicol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8032',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|chloramphenicol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY3DJ-12',
        Common_Name: 'ceramide <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY3DJ-12',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|ceramide <i>de novo</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5060',
        Common_Name: 'luteolin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-5060',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|luteolin biosynthesis',
    },
    {
        BioCyc_ID: 'METSYN-PWY',
        Common_Name: 'superpathway of L-homoserine and L-methionine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-De-novo-Biosynthesis|METSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine <i>De Novo</i> Biosynthesis|superpathway of L-homoserine and L-methionine biosynthesis',
    },
    {
        BioCyc_ID: 'METSYN-PWY',
        Common_Name: 'superpathway of L-homoserine and L-methionine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|METSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-homoserine and L-methionine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6714',
        Common_Name: 'L-rhamnose degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-rhamnose-Degradation|PWY-6714',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-rhamnose Degradation|L-rhamnose degradation III',
    },
    {
        BioCyc_ID: 'CENTBENZCOA-PWY',
        Common_Name: 'benzoyl-CoA degradation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzoyl-CoA-Degradation|CENTBENZCOA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzoyl-CoA Degradation|benzoyl-CoA degradation II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5140',
        Common_Name: 'cannabinoid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|TERPENOPHENOLICS-SYN|PWY-5140',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenophenolic Biosynthesis|cannabinoid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8436',
        Common_Name: 'mammalian high-mannose <i>N</i>-glycan degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-8436',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|mammalian high-mannose <i>N</i>-glycan degradation',
    },
    {
        BioCyc_ID: 'PWY-4261',
        Common_Name: 'glycerol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY-4261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Glycerol Degradation|glycerol degradation I',
    },
    {
        BioCyc_ID: 'PWY-4261',
        Common_Name: 'glycerol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY-4261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Glycerol Degradation|glycerol degradation I',
    },
    {
        BioCyc_ID: 'PWY-8019',
        Common_Name: '2-hydroxyparaconate and itatartarate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-8019',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|2-hydroxyparaconate and itatartarate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6214',
        Common_Name: '3-chlorocatechol degradation III (<i>meta</i> pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|3-Chlorocatechol-Degradation|PWY-6214',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3-Chlorocatechol Degradation|3-chlorocatechol degradation III (<i>meta</i> pathway)',
    },
    {
        BioCyc_ID: 'PWY-6214',
        Common_Name: '3-chlorocatechol degradation III (<i>meta</i> pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|3-Chlorocatechol-Degradation|PWY-6214',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3-Chlorocatechol Degradation|3-chlorocatechol degradation III (<i>meta</i> pathway)',
    },
    {
        BioCyc_ID: 'PWY0-1329',
        Common_Name: 'succinate to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1329',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|succinate to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1329',
        Common_Name: 'succinate to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1329',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|succinate to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-5291',
        Common_Name: 'canthaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5291',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|canthaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5291',
        Common_Name: 'canthaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5291',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|canthaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5291',
        Common_Name: 'canthaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5291',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|canthaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7870',
        Common_Name: 'L-cysteine biosynthesis VII (from <i>S</i>-sulfo-L-cysteine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|PWY-7870',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|L-cysteine biosynthesis VII (from <i>S</i>-sulfo-L-cysteine)',
    },
    {
        BioCyc_ID: 'PWY-8344',
        Common_Name: 'ansamitocin P biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8344',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|ansamitocin P biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8250',
        Common_Name: '<i>Escherichia coli</i> serotype O:9 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8250',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:9 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8250',
        Common_Name: '<i>Escherichia coli</i> serotype O:9 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8250',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:9 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8250',
        Common_Name: '<i>Escherichia coli</i> serotype O:9 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8250',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:9 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8250',
        Common_Name: '<i>Escherichia coli</i> serotype O:9 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8250',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:9 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5704',
        Common_Name: 'urea degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Urea-Degradation|PWY-5704',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Urea Degradation|urea degradation II',
    },
    {
        BioCyc_ID: 'PWY-5129',
        Common_Name: 'sphingolipid biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-5129',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|sphingolipid biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-6010',
        Common_Name: 'apigenin glycosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-6010',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|apigenin glycosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2055',
        Common_Name: 'superpathway of pterocarpan biosynthesis (via daidzein)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2055',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|superpathway of pterocarpan biosynthesis (via daidzein)',
    },
    {
        BioCyc_ID: 'PWY-2055',
        Common_Name: 'superpathway of pterocarpan biosynthesis (via daidzein)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-2055',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pterocarpan biosynthesis (via daidzein)',
    },
    {
        BioCyc_ID: 'PWY-6713',
        Common_Name: 'L-rhamnose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-rhamnose-Degradation|PWY-6713',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-rhamnose Degradation|L-rhamnose degradation II',
    },
    {
        BioCyc_ID: 'PWY-5703',
        Common_Name: 'urea degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Urea-Degradation|PWY-5703',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Urea Degradation|urea degradation I',
    },
    {
        BioCyc_ID: 'PWY-7765',
        Common_Name: '3-hydroxy-4-methyl-anthranilate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|3-OH-4-Methyl-Anthranilate-Biosynthesis|PWY-7765',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|3-hydroxy-4-methyl-anthranilate biosynthesis|3-hydroxy-4-methyl-anthranilate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5343',
        Common_Name: 'ajugose biosynthesis II (galactinol-independent)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-5343',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|ajugose biosynthesis II (galactinol-independent)',
    },
    {
        BioCyc_ID: 'PWY-5343',
        Common_Name: 'ajugose biosynthesis II (galactinol-independent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|PWY-5343',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|ajugose biosynthesis II (galactinol-independent)',
    },
    {
        BioCyc_ID: 'PWY-6952',
        Common_Name: 'glycerophosphodiester degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY-6952',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Glycerol Degradation|glycerophosphodiester degradation',
    },
    {
        BioCyc_ID: 'PWY-6952',
        Common_Name: 'glycerophosphodiester degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY-6952',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Glycerol Degradation|glycerophosphodiester degradation',
    },
    {
        BioCyc_ID: 'PWY-6920',
        Common_Name: '6-gingerol analog biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-6920',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|6-gingerol analog biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6193',
        Common_Name: '3-chlorocatechol degradation II (<i>ortho</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|3-Chlorocatechol-Degradation|PWY-6193',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3-Chlorocatechol Degradation|3-chlorocatechol degradation II (<i>ortho</i>)',
    },
    {
        BioCyc_ID: 'PWY-6193',
        Common_Name: '3-chlorocatechol degradation II (<i>ortho</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|3-Chlorocatechol-Degradation|PWY-6193',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3-Chlorocatechol Degradation|3-chlorocatechol degradation II (<i>ortho</i>)',
    },
    {
        BioCyc_ID: 'PWY0-1576',
        Common_Name: 'hydrogen to fumarate electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1576',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|hydrogen to fumarate electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1576',
        Common_Name: 'hydrogen to fumarate electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1576',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|hydrogen to fumarate electron transfer',
    },
    {
        BioCyc_ID: 'PWY-7175',
        Common_Name: 'nostoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7175',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|nostoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7175',
        Common_Name: 'nostoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7175',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|nostoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7175',
        Common_Name: 'nostoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7175',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|nostoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7854',
        Common_Name: 'crotonyl-CoA/ethylmalonyl-CoA/hydroxybutyryl-CoA cycle (engineered)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|PWY-7854',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|crotonyl-CoA/ethylmalonyl-CoA/hydroxybutyryl-CoA cycle (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7289',
        Common_Name: 'L-cysteine biosynthesis V (mycobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|PWY-7289',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|L-cysteine biosynthesis V (mycobacteria)',
    },
    {
        BioCyc_ID: 'PWY-5776',
        Common_Name: '2-hydroxyphenazine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5776',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|2-hydroxyphenazine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8196',
        Common_Name: '<i>Salmonella enterica</i> serotype O:3,10 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8196',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:3,10 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8196',
        Common_Name: '<i>Salmonella enterica</i> serotype O:3,10 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8196',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:3,10 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8196',
        Common_Name: '<i>Salmonella enterica</i> serotype O:3,10 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8196',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:3,10 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8196',
        Common_Name: '<i>Salmonella enterica</i> serotype O:3,10 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8196',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:3,10 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5493',
        Common_Name: 'reductive monocarboxylic acid cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|PWY-5493',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|reductive monocarboxylic acid cycle',
    },
    {
        BioCyc_ID: 'PWY-7839',
        Common_Name: 'lacto-series glycosphingolipids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-7839',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|lacto-series glycosphingolipids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6239',
        Common_Name: 'luteolin glycosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-6239',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|luteolin glycosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5052',
        Common_Name: 'superpathway of gibberellin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|PWY-5052',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|superpathway of gibberellin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5052',
        Common_Name: 'superpathway of gibberellin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|PWY-5052',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|superpathway of gibberellin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5052',
        Common_Name: 'superpathway of gibberellin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5052',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of gibberellin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6041',
        Common_Name: '4-sulfocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Sulfoaromatics-Degradation|PWY-6041',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Sulfoaromatic Compound Degradation|4-sulfocatechol degradation',
    },
    {
        BioCyc_ID: 'PWYQT-4429',
        Common_Name: 'CO<sub>2</sub> fixation into oxaloacetate (anaplerotic)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|PWYQT-4429',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|CO<sub>2</sub> fixation into oxaloacetate (anaplerotic)',
    },
    {
        BioCyc_ID: 'PWY-7717',
        Common_Name: '3-hydroxy-4-methyl-anthranilate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|3-OH-4-Methyl-Anthranilate-Biosynthesis|PWY-7717',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|3-hydroxy-4-methyl-anthranilate biosynthesis|3-hydroxy-4-methyl-anthranilate biosynthesis I',
    },
    {
        BioCyc_ID: 'GOLPDLCAT-PWY',
        Common_Name: 'superpathway of glycerol degradation to 1,3-propanediol',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|GOLPDLCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Glycerol Degradation|superpathway of glycerol degradation to 1,3-propanediol',
    },
    {
        BioCyc_ID: 'GOLPDLCAT-PWY',
        Common_Name: 'superpathway of glycerol degradation to 1,3-propanediol',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|GOLPDLCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Glycerol Degradation|superpathway of glycerol degradation to 1,3-propanediol',
    },
    {
        BioCyc_ID: 'GOLPDLCAT-PWY',
        Common_Name: 'superpathway of glycerol degradation to 1,3-propanediol',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GOLPDLCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glycerol degradation to 1,3-propanediol',
    },
    {
        BioCyc_ID: 'PWY-8320',
        Common_Name: '(3<i>R</i>)-N-[(2<i>S</i>)-1-hydroxy-6-[(3<i>R</i>)-3-isocyanobutanamido]hexan-2-yl]-3-isocyanobutanamide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-8320',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|(3<i>R</i>)-N-[(2<i>S</i>)-1-hydroxy-6-[(3<i>R</i>)-3-isocyanobutanamido]hexan-2-yl]-3-isocyanobutanamide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6089',
        Common_Name: '3-chlorocatechol degradation I (<i>ortho</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|3-Chlorocatechol-Degradation|PWY-6089',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3-Chlorocatechol Degradation|3-chlorocatechol degradation I (<i>ortho</i>)',
    },
    {
        BioCyc_ID: 'PWY-6089',
        Common_Name: '3-chlorocatechol degradation I (<i>ortho</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|3-Chlorocatechol-Degradation|PWY-6089',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3-Chlorocatechol Degradation|3-chlorocatechol degradation I (<i>ortho</i>)',
    },
    {
        BioCyc_ID: 'PWY0-1336',
        Common_Name: 'NADH to fumarate electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1336',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|NADH to fumarate electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1336',
        Common_Name: 'NADH to fumarate electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1336',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADH to fumarate electron transfer',
    },
    {
        BioCyc_ID: 'PWY-7623',
        Common_Name: 'astaxanthin dirhamnoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7623',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin dirhamnoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7623',
        Common_Name: 'astaxanthin dirhamnoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7623',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin dirhamnoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7623',
        Common_Name: 'astaxanthin dirhamnoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7623',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin dirhamnoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5743',
        Common_Name: '3-hydroxypropanoate cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|PWY-5743',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|3-hydroxypropanoate cycle',
    },
    {
        BioCyc_ID: 'PWY-6679',
        Common_Name: 'jadomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6679',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|jadomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6120',
        Common_Name: 'L-tyrosine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|TYROSINE-SYN|PWY-6120',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-tyrosine Biosynthesis|L-tyrosine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8221',
        Common_Name: '<i>Escherichia coli</i> serotype O:111/<i>Salmonella enterica</i> serotype O:35 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:111/<i>Salmonella enterica</i> serotype O:35 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8221',
        Common_Name: '<i>Escherichia coli</i> serotype O:111/<i>Salmonella enterica</i> serotype O:35 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:111/<i>Salmonella enterica</i> serotype O:35 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8221',
        Common_Name: '<i>Escherichia coli</i> serotype O:111/<i>Salmonella enterica</i> serotype O:35 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:111/<i>Salmonella enterica</i> serotype O:35 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8221',
        Common_Name: '<i>Escherichia coli</i> serotype O:111/<i>Salmonella enterica</i> serotype O:35 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:111/<i>Salmonella enterica</i> serotype O:35 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'CODH-PWY',
        Common_Name: 'reductive acetyl coenzyme A pathway I (homoacetogenic bacteria)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|CODH-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|reductive acetyl coenzyme A pathway I (homoacetogenic bacteria)',
    },
    {
        BioCyc_ID: 'PWY-8042',
        Common_Name: 'acylceramide biosynthesis and processing',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-8042',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|acylceramide biosynthesis and processing',
    },
    {
        BioCyc_ID: 'PWY-7189',
        Common_Name: 'pinocembrin <i>C</i>-glucosylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-7189',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|pinocembrin <i>C</i>-glucosylation',
    },
    {
        BioCyc_ID: 'PWY-5345',
        Common_Name: 'superpathway of L-methionine biosynthesis (by sulfhydrylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-De-novo-Biosynthesis|PWY-5345',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine <i>De Novo</i> Biosynthesis|superpathway of L-methionine biosynthesis (by sulfhydrylation)',
    },
    {
        BioCyc_ID: 'PWY-5345',
        Common_Name: 'superpathway of L-methionine biosynthesis (by sulfhydrylation)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5345',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-methionine biosynthesis (by sulfhydrylation)',
    },
    {
        BioCyc_ID: 'DESULFONATION-PWY',
        Common_Name: 'benzenesulfonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Sulfoaromatics-Degradation|DESULFONATION-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Sulfoaromatic Compound Degradation|benzenesulfonate degradation',
    },
    {
        BioCyc_ID: 'PWY-5789',
        Common_Name: '3-hydroxypropanoate/4-hydroxybutanate cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|PWY-5789',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|3-hydroxypropanoate/4-hydroxybutanate cycle',
    },
    {
        BioCyc_ID: 'PWY-5001',
        Common_Name: '1,3,6,7-tetrahydroxyxanthone biosynthesis (from benzoyl-CoA)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|XANTHONE-SYN|PWY-5001',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Xanthone Biosynthesis|1,3,6,7-tetrahydroxyxanthone biosynthesis (from benzoyl-CoA)',
    },
    {
        BioCyc_ID: 'PWY-8303',
        Common_Name: 'reductive glycine pathway of autotrophic CO<sub>2</sub> fixation',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|PWY-8303',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|reductive glycine pathway of autotrophic CO<sub>2</sub> fixation',
    },
    {
        BioCyc_ID: 'PWY-6131',
        Common_Name: 'glycerol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY-6131',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Glycerol Degradation|glycerol degradation II',
    },
    {
        BioCyc_ID: 'PWY-6131',
        Common_Name: 'glycerol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|GLYCEROL-DEG|PWY-6131',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Glycerol Degradation|glycerol degradation II',
    },
    {
        BioCyc_ID: 'PWY-7236',
        Common_Name: 'mycocyclosin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7236',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|mycocyclosin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6104',
        Common_Name: '3-chlorotoluene degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|3-Chlorotoluene-Degradation|PWY-6104',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|3 Chlorotoluene Degradation|3-chlorotoluene degradation II',
    },
    {
        BioCyc_ID: 'PWY-6104',
        Common_Name: '3-chlorotoluene degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|3-Chlorotoluene-Degradation|PWY-6104',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|3 Chlorotoluene Degradation|3-chlorotoluene degradation II',
    },
    {
        BioCyc_ID: 'PWY0-1581',
        Common_Name: 'nitrate reduction IX (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1581',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|nitrate reduction IX (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY0-1581',
        Common_Name: 'nitrate reduction IX (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1581',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|nitrate reduction IX (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY0-1581',
        Common_Name: 'nitrate reduction IX (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY0-1581',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction IX (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY-7638',
        Common_Name: 'echinenone and zeaxanthin biosynthesis (<i>Synechocystis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7638',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|echinenone and zeaxanthin biosynthesis (<i>Synechocystis</i>)',
    },
    {
        BioCyc_ID: 'PWY-7638',
        Common_Name: 'echinenone and zeaxanthin biosynthesis (<i>Synechocystis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7638',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|echinenone and zeaxanthin biosynthesis (<i>Synechocystis</i>)',
    },
    {
        BioCyc_ID: 'PWY-7638',
        Common_Name: 'echinenone and zeaxanthin biosynthesis (<i>Synechocystis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7638',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|echinenone and zeaxanthin biosynthesis (<i>Synechocystis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8253',
        Common_Name: '<i>Escherichia coli</i> serotype O:149/<i>Shigella boydii</i> serotype O1 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8253',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:149/<i>Shigella boydii</i> serotype O1 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8253',
        Common_Name: '<i>Escherichia coli</i> serotype O:149/<i>Shigella boydii</i> serotype O1 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8253',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:149/<i>Shigella boydii</i> serotype O1 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8253',
        Common_Name: '<i>Escherichia coli</i> serotype O:149/<i>Shigella boydii</i> serotype O1 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8253',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:149/<i>Shigella boydii</i> serotype O1 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8253',
        Common_Name: '<i>Escherichia coli</i> serotype O:149/<i>Shigella boydii</i> serotype O1 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8253',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:149/<i>Shigella boydii</i> serotype O1 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7045',
        Common_Name: 'mithramycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7045',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|mithramycin biosynthesis',
    },
    {
        BioCyc_ID: 'TYRSYN',
        Common_Name: 'L-tyrosine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|TYROSINE-SYN|TYRSYN',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-tyrosine Biosynthesis|L-tyrosine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7784',
        Common_Name: 'reductive acetyl coenzyme A pathway II (autotrophic methanogens)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|PWY-7784',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|reductive acetyl coenzyme A pathway II (autotrophic methanogens)',
    },
    {
        BioCyc_ID: 'PWY-5392',
        Common_Name: 'reductive TCA cycle II',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|Reductive-TCA-Cycles|PWY-5392',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|Reductive TCA Cycles|reductive TCA cycle II',
    },
    {
        BioCyc_ID: 'PWY-7298',
        Common_Name: 'nevadensin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-7298',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|nevadensin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5485',
        Common_Name: 'pyruvate fermentation to acetate IV',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5485',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate IV',
    },
    {
        BioCyc_ID: 'PWY-5485',
        Common_Name: 'pyruvate fermentation to acetate IV',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5485',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate IV',
    },
    {
        BioCyc_ID: 'PWY-5485',
        Common_Name: 'pyruvate fermentation to acetate IV',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5485',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate IV',
    },
    {
        BioCyc_ID: 'PWY-7521',
        Common_Name: '3-(4-sulfophenyl)butanoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Sulfoaromatics-Degradation|PWY-7521',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Sulfoaromatic Compound Degradation|3-(4-sulfophenyl)butanoate degradation',
    },
    {
        BioCyc_ID: 'P42-PWY',
        Common_Name: 'incomplete reductive TCA cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|Reductive-TCA-Cycles|P42-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|Reductive TCA Cycles|incomplete reductive TCA cycle',
    },
    {
        BioCyc_ID: 'PWY-8442',
        Common_Name: 'hyperxanthone E biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|XANTHONE-SYN|PWY-8442',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Xanthone Biosynthesis|hyperxanthone E biosynthesis',
    },
    {
        BioCyc_ID: 'P23-PWY',
        Common_Name: 'reductive TCA cycle I',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|Reductive-TCA-Cycles|P23-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|Reductive TCA Cycles|reductive TCA cycle I',
    },
    {
        BioCyc_ID: 'LARABITOLUTIL-PWY',
        Common_Name: 'xylitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|Xylitol-Degradation|LARABITOLUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Xylitol Degradation|xylitol degradation I',
    },
    {
        BioCyc_ID: 'LARABITOLUTIL-PWY',
        Common_Name: 'xylitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|Xylitol-Degradation|LARABITOLUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Xylitol Degradation|xylitol degradation I',
    },
    {
        BioCyc_ID: 'PWY-5935',
        Common_Name: '1-tuberculosinyladenosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-5935',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|1-tuberculosinyladenosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6103',
        Common_Name: '3-chlorotoluene degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|3-Chlorotoluene-Degradation|PWY-6103',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|3 Chlorotoluene Degradation|3-chlorotoluene degradation I',
    },
    {
        BioCyc_ID: 'PWY-6103',
        Common_Name: '3-chlorotoluene degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|3-Chlorotoluene-Degradation|PWY-6103',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|3 Chlorotoluene Degradation|3-chlorotoluene degradation I',
    },
    {
        BioCyc_ID: 'PWY0-1352',
        Common_Name: 'nitrate reduction VIII (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1352',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|nitrate reduction VIII (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY0-1352',
        Common_Name: 'nitrate reduction VIII (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1352',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|nitrate reduction VIII (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY0-1352',
        Common_Name: 'nitrate reduction VIII (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY0-1352',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction VIII (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY-5944',
        Common_Name: 'zeaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5944',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|zeaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5944',
        Common_Name: 'zeaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5944',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|zeaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5944',
        Common_Name: 'zeaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5944',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|zeaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8199',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9,46,27 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8199',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9,46,27 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8199',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9,46,27 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8199',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9,46,27 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8199',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9,46,27 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8199',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9,46,27 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8199',
        Common_Name: '<i>Salmonella enterica</i> serotype O:9,46,27 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8199',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:9,46,27 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7626',
        Common_Name: 'bacilysin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7626',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|bacilysin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3461',
        Common_Name: 'L-tyrosine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|TYROSINE-SYN|PWY-3461',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-tyrosine Biosynthesis|L-tyrosine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8340',
        Common_Name: 'isoorientin biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-8340',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|isoorientin biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5724',
        Common_Name: 'superpathway of atrazine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|s-Triazine-Degradation|Atrazine-Degradation|PWY-5724',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>s</i>-Triazine Degredation|Atrazine Degradation|superpathway of atrazine degradation',
    },
    {
        BioCyc_ID: 'PWY-5724',
        Common_Name: 'superpathway of atrazine degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5724',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of atrazine degradation',
    },
    {
        BioCyc_ID: 'PWY-7098',
        Common_Name: 'vanillin and vanillate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Vanillin-Degradation|PWY-7098',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Vanillin Degradation|vanillin and vanillate degradation II',
    },
    {
        BioCyc_ID: 'PWY-5002',
        Common_Name: 'tetrahydroxyxanthone biosynthesis (from 3-hydroxybenzoate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|XANTHONE-SYN|PWY-5002',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Xanthone Biosynthesis|tetrahydroxyxanthone biosynthesis (from 3-hydroxybenzoate)',
    },
    {
        BioCyc_ID: 'PWY-8393',
        Common_Name: 'xylitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|Xylitol-Degradation|PWY-8393',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Xylitol Degradation|xylitol degradation II',
    },
    {
        BioCyc_ID: 'PWY-8393',
        Common_Name: 'xylitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|Xylitol-Degradation|PWY-8393',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Xylitol Degradation|xylitol degradation II',
    },
    {
        BioCyc_ID: 'PWY-7556',
        Common_Name: '(-)-microperfuranone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7556',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|(-)-microperfuranone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6191',
        Common_Name: '2,5-dichlorotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|Dichlorotoluene-Degradation|PWY-6191',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|Dichlorotoluene Degradation|2,5-dichlorotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY-6191',
        Common_Name: '2,5-dichlorotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|Dichlorotoluene-Degradation|PWY-6191',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|Dichlorotoluene Degradation|2,5-dichlorotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY0-1585',
        Common_Name: 'formate to nitrite electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1585',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|formate to nitrite electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1585',
        Common_Name: 'formate to nitrite electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1585',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|formate to nitrite electron transfer',
    },
    {
        BioCyc_ID: 'PWY-7947',
        Common_Name: 'flexixanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7947',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|flexixanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7947',
        Common_Name: 'flexixanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7947',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|flexixanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7947',
        Common_Name: 'flexixanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7947',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|flexixanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8226',
        Common_Name: '<i>Escherichia coli</i> serotype O:152 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8226',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:152 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8226',
        Common_Name: '<i>Escherichia coli</i> serotype O:152 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8226',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:152 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8226',
        Common_Name: '<i>Escherichia coli</i> serotype O:152 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8226',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:152 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8226',
        Common_Name: '<i>Escherichia coli</i> serotype O:152 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8226',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:152 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7737',
        Common_Name: 'thiocoraline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7737',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|thiocoraline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6134',
        Common_Name: 'L-tyrosine biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|TYROSINE-SYN|PWY-6134',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-tyrosine Biosynthesis|L-tyrosine biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-5372',
        Common_Name: 'carbon tetrachloride degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Carbon-tetrachloride-degradation|PWY-5372',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Carbon Tetrachloride Degradation|carbon tetrachloride degradation II',
    },
    {
        BioCyc_ID: 'PWY-5370',
        Common_Name: 'carbon tetrachloride degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Carbon-tetrachloride-degradation|PWY-5370',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Carbon Tetrachloride Degradation|carbon tetrachloride degradation I',
    },
    {
        BioCyc_ID: 'PWY-5793',
        Common_Name: 'maysin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-5793',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|maysin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5910',
        Common_Name: 'superpathway of geranylgeranyldiphosphate biosynthesis I (via mevalonate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-5910',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|superpathway of geranylgeranyldiphosphate biosynthesis I (via mevalonate)',
    },
    {
        BioCyc_ID: 'PWY-5910',
        Common_Name: 'superpathway of geranylgeranyldiphosphate biosynthesis I (via mevalonate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|GGPP-Biosynthesis|PWY-5910',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|Geranylgeranyl Diphosphate Biosynthesis|superpathway of geranylgeranyldiphosphate biosynthesis I (via mevalonate)',
    },
    {
        BioCyc_ID: 'PWY-5910',
        Common_Name: 'superpathway of geranylgeranyldiphosphate biosynthesis I (via mevalonate)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5910',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of geranylgeranyldiphosphate biosynthesis I (via mevalonate)',
    },
    {
        BioCyc_ID: 'PWY-7097',
        Common_Name: 'vanillin and vanillate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Vanillin-Degradation|PWY-7097',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Vanillin Degradation|vanillin and vanillate degradation I',
    },
    {
        BioCyc_ID: 'PWY-7255',
        Common_Name: 'ergothioneine biosynthesis I (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Ergothioneine-Biosynthesis|PWY-7255',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Ergothioneine Biosynthesis|ergothioneine biosynthesis I (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7789',
        Common_Name: 'erythritol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|Erythritol-Degradation|PWY-7789',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Erythritol Degradation|erythritol degradation I',
    },
    {
        BioCyc_ID: 'PWY-7789',
        Common_Name: 'erythritol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|Erythritol-Degradation|PWY-7789',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Erythritol Degradation|erythritol degradation I',
    },
    {
        BioCyc_ID: 'PWY-6068',
        Common_Name: 'indican biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-6068',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|indican biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6190',
        Common_Name: '2,4-dichlorotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|Dichlorotoluene-Degradation|PWY-6190',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|Dichlorotoluene Degradation|2,4-dichlorotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY-6190',
        Common_Name: '2,4-dichlorotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|Dichlorotoluene-Degradation|PWY-6190',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|Dichlorotoluene Degradation|2,4-dichlorotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY-7279',
        Common_Name: 'aerobic respiration II (cytochrome c) (yeast)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY-7279',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|aerobic respiration II (cytochrome c) (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7279',
        Common_Name: 'aerobic respiration II (cytochrome c) (yeast)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-7279',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|aerobic respiration II (cytochrome c) (yeast)',
    },
    {
        BioCyc_ID: 'PWY-5947',
        Common_Name: 'lutein biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5947',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lutein biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5947',
        Common_Name: 'lutein biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5947',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lutein biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5947',
        Common_Name: 'lutein biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5947',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lutein biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2B4Q-3',
        Common_Name: '<i>Brucella abortus</i> O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY2B4Q-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Brucella abortus</i> O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2B4Q-3',
        Common_Name: '<i>Brucella abortus</i> O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY2B4Q-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Brucella abortus</i> O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2B4Q-3',
        Common_Name: '<i>Brucella abortus</i> O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY2B4Q-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Brucella abortus</i> O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2B4Q-3',
        Common_Name: '<i>Brucella abortus</i> O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY2B4Q-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Brucella abortus</i> O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8368',
        Common_Name: 'clorobiocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8368',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|clorobiocin biosynthesis',
    },
    {
        BioCyc_ID: 'GLYCINE-SYN2-PWY',
        Common_Name: 'glycine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLYCINE-SYN|GLYCINE-SYN2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|Glycine Biosynthesis|glycine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6024',
        Common_Name: 'isovitexin glycosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-6024',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|isovitexin glycosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6309',
        Common_Name: 'L-tryptophan degradation XI (mammalian, via kynurenine)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6309',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-tryptophan degradation XI (mammalian, via kynurenine)',
    },
    {
        BioCyc_ID: 'PWY-6309',
        Common_Name: 'L-tryptophan degradation XI (mammalian, via kynurenine)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-6309',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation XI (mammalian, via kynurenine)',
    },
    {
        BioCyc_ID: 'PWY-6338',
        Common_Name: 'superpathway of vanillin and vanillate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6338',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of vanillin and vanillate degradation',
    },
    {
        BioCyc_ID: 'PWY-6338',
        Common_Name: 'superpathway of vanillin and vanillate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Vanillin-Degradation|PWY-6338',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Vanillin Degradation|superpathway of vanillin and vanillate degradation',
    },
    {
        BioCyc_ID: 'PWY-7550',
        Common_Name: 'ergothioneine biosynthesis II (fungi)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Ergothioneine-Biosynthesis|PWY-7550',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Ergothioneine Biosynthesis|ergothioneine biosynthesis II (fungi)',
    },
    {
        BioCyc_ID: 'PWY-7788',
        Common_Name: 'erythritol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|Erythritol-Degradation|PWY-7788',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Erythritol Degradation|erythritol degradation II',
    },
    {
        BioCyc_ID: 'PWY-7788',
        Common_Name: 'erythritol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|Erythritol-Degradation|PWY-7788',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Erythritol Degradation|erythritol degradation II',
    },
    {
        BioCyc_ID: 'PWY-7752',
        Common_Name: 'gadusol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7752',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|gadusol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6192',
        Common_Name: '3,4-dichlorotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|Dichlorotoluene-Degradation|PWY-6192',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|Dichlorotoluene Degradation|3,4-dichlorotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY-6192',
        Common_Name: '3,4-dichlorotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorotoluene-Degradation|Dichlorotoluene-Degradation|PWY-6192',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorotoluene Degradation|Dichlorotoluene Degradation|3,4-dichlorotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY0-1356',
        Common_Name: 'formate to dimethyl sulfoxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1356',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|formate to dimethyl sulfoxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1356',
        Common_Name: 'formate to dimethyl sulfoxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1356',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|formate to dimethyl sulfoxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY-6286',
        Common_Name: 'spheroidene and spheroidenone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6286',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|spheroidene and spheroidenone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6286',
        Common_Name: 'spheroidene and spheroidenone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6286',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|spheroidene and spheroidenone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6286',
        Common_Name: 'spheroidene and spheroidenone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6286',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|spheroidene and spheroidenone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8206',
        Common_Name: '<i>Salmonella enterica</i> serotype O:18 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8206',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:18 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8206',
        Common_Name: '<i>Salmonella enterica</i> serotype O:18 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8206',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:18 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8206',
        Common_Name: '<i>Salmonella enterica</i> serotype O:18 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8206',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:18 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8206',
        Common_Name: '<i>Salmonella enterica</i> serotype O:18 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8206',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Salmonella enterica</i> serotype O:18 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5929',
        Common_Name: 'puromycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5929',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|puromycin biosynthesis',
    },
    {
        BioCyc_ID: 'GLYSYN-THR-PWY',
        Common_Name: 'glycine biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLYCINE-SYN|GLYSYN-THR-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|Glycine Biosynthesis|glycine biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-7188',
        Common_Name: 'orientin and isoorientin biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-7188',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|orientin and isoorientin biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6443',
        Common_Name: 'benzoate biosynthesis I (CoA-dependent, &beta;-oxidative)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Benzoate-Biosynthesis|PWY-6443',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Benzoate Biosynthesis|benzoate biosynthesis I (CoA-dependent, &beta;-oxidative)',
    },
    {
        BioCyc_ID: 'PWY-6443',
        Common_Name: 'benzoate biosynthesis I (CoA-dependent, &beta;-oxidative)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6443',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|benzoate biosynthesis I (CoA-dependent, &beta;-oxidative)',
    },
    {
        BioCyc_ID: 'P3-PWY',
        Common_Name: 'gallate degradation III (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|GALLATE-DEG|P3-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Gallate Degradation|gallate degradation III (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7351',
        Common_Name: 'pyruvate-derived opine biosynthesis II (marine invertebrates)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Opine-Biosynthesis|PWY-7351',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Opine Biosynthesis|pyruvate-derived opine biosynthesis II (marine invertebrates)',
    },
    {
        BioCyc_ID: 'PWY-7351',
        Common_Name: 'pyruvate-derived opine biosynthesis II (marine invertebrates)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|PWY-7351',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|pyruvate-derived opine biosynthesis II (marine invertebrates)',
    },
    {
        BioCyc_ID: 'PWY-8394',
        Common_Name: 'ribitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|Ribitol-Degradation|PWY-8394',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Ribitol Degradation|ribitol degradation II',
    },
    {
        BioCyc_ID: 'PWY-8394',
        Common_Name: 'ribitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|Ribitol-Degradation|PWY-8394',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Ribitol Degradation|ribitol degradation II',
    },
    {
        BioCyc_ID: 'PWY-6661',
        Common_Name: '4-hydroxy-2(1<i>H</i>)-quinolone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-6661',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|4-hydroxy-2(1<i>H</i>)-quinolone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6228',
        Common_Name: '3-chlorobenzoate degradation III (via gentisate)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|3-Chlorobenzoate-Degradation|PWY-6228',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|3-Chlorobenzoate Degradation|3-chlorobenzoate degradation III (via gentisate)',
    },
    {
        BioCyc_ID: 'PWY-6228',
        Common_Name: '3-chlorobenzoate degradation III (via gentisate)',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|3-Chlorobenzoate-Degradation|PWY-6228',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|3-Chlorobenzoate Degradation|3-chlorobenzoate degradation III (via gentisate)',
    },
    {
        BioCyc_ID: 'PWY1YI0-2',
        Common_Name: 'succinate to chytochrome <i>c</i> oxidase via cytochrome <i>c</i><sub>6</sub>',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY1YI0-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|succinate to chytochrome <i>c</i> oxidase via cytochrome <i>c</i><sub>6</sub>',
    },
    {
        BioCyc_ID: 'PWY1YI0-2',
        Common_Name: 'succinate to chytochrome <i>c</i> oxidase via cytochrome <i>c</i><sub>6</sub>',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY1YI0-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|succinate to chytochrome <i>c</i> oxidase via cytochrome <i>c</i><sub>6</sub>',
    },
    {
        BioCyc_ID: 'PWY-5288',
        Common_Name: 'astaxanthin biosynthesis (bacteria, fungi, algae)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5288',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin biosynthesis (bacteria, fungi, algae)',
    },
    {
        BioCyc_ID: 'PWY-5288',
        Common_Name: 'astaxanthin biosynthesis (bacteria, fungi, algae)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5288',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin biosynthesis (bacteria, fungi, algae)',
    },
    {
        BioCyc_ID: 'PWY-5288',
        Common_Name: 'astaxanthin biosynthesis (bacteria, fungi, algae)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5288',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|astaxanthin biosynthesis (bacteria, fungi, algae)',
    },
    {
        BioCyc_ID: 'PWY-8231',
        Common_Name: '<i>Escherichia coli</i> serotype O:127 <i>O</i> antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8231',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:127 <i>O</i> antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8231',
        Common_Name: '<i>Escherichia coli</i> serotype O:127 <i>O</i> antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8231',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:127 <i>O</i> antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8231',
        Common_Name: '<i>Escherichia coli</i> serotype O:127 <i>O</i> antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8231',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:127 <i>O</i> antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8231',
        Common_Name: '<i>Escherichia coli</i> serotype O:127 <i>O</i> antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8231',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:127 <i>O</i> antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6721',
        Common_Name: 'sangivamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6721',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|sangivamycin biosynthesis',
    },
    {
        BioCyc_ID: 'GLYSYN-PWY',
        Common_Name: 'glycine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLYCINE-SYN|GLYSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|Glycine Biosynthesis|glycine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7213',
        Common_Name: 'wogonin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-7213',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|wogonin metabolism',
    },
    {
        BioCyc_ID: 'PWY-6579',
        Common_Name: 'superpathway of guanine and guanosine salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Guanine-Guanosine-Salvage|PWY-6579',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Guanine and Guanosine Salvage|superpathway of guanine and guanosine salvage',
    },
    {
        BioCyc_ID: 'PWY-6579',
        Common_Name: 'superpathway of guanine and guanosine salvage',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6579',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of guanine and guanosine salvage',
    },
    {
        BioCyc_ID: 'GALLATE-DEGRADATION-II-PWY',
        Common_Name: 'gallate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|GALLATE-DEG|GALLATE-DEGRADATION-II-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Gallate Degradation|gallate degradation I',
    },
    {
        BioCyc_ID: 'PWY-8451',
        Common_Name: '2-oxoglutarate-derived opine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Opine-Biosynthesis|PWY-8451',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Opine Biosynthesis|2-oxoglutarate-derived opine biosynthesis',
    },
    {
        BioCyc_ID: 'RIBITOLUTIL-PWY',
        Common_Name: 'ribitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|Ribitol-Degradation|RIBITOLUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|Ribitol Degradation|ribitol degradation I',
    },
    {
        BioCyc_ID: 'RIBITOLUTIL-PWY',
        Common_Name: 'ribitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|Ribitol-Degradation|RIBITOLUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|Ribitol Degradation|ribitol degradation I',
    },
    {
        BioCyc_ID: 'PWY-7955',
        Common_Name: 'paerucumarin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7955',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|paerucumarin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6216',
        Common_Name: '3-chlorobenzoate degradation II (via protocatechuate)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|3-Chlorobenzoate-Degradation|PWY-6216',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|3-Chlorobenzoate Degradation|3-chlorobenzoate degradation II (via protocatechuate)',
    },
    {
        BioCyc_ID: 'PWY-6216',
        Common_Name: '3-chlorobenzoate degradation II (via protocatechuate)',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|3-Chlorobenzoate-Degradation|PWY-6216',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|3-Chlorobenzoate Degradation|3-chlorobenzoate degradation II (via protocatechuate)',
    },
    {
        BioCyc_ID: 'PWY-7545',
        Common_Name: 'pyruvate to cytochrome <i>bd</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY-7545',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|pyruvate to cytochrome <i>bd</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-7545',
        Common_Name: 'pyruvate to cytochrome <i>bd</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-7545',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|pyruvate to cytochrome <i>bd</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-6809',
        Common_Name: 'neoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6809',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|neoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6809',
        Common_Name: 'neoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6809',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|neoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6809',
        Common_Name: 'neoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6809',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|neoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8234',
        Common_Name: '<i>Escherichia coli</i> serotype O:183/<i>Shigella boydii</i> serotype O:10 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8234',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:183/<i>Shigella boydii</i> serotype O:10 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8234',
        Common_Name: '<i>Escherichia coli</i> serotype O:183/<i>Shigella boydii</i> serotype O:10 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8234',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:183/<i>Shigella boydii</i> serotype O:10 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8234',
        Common_Name: '<i>Escherichia coli</i> serotype O:183/<i>Shigella boydii</i> serotype O:10 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8234',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:183/<i>Shigella boydii</i> serotype O:10 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8234',
        Common_Name: '<i>Escherichia coli</i> serotype O:183/<i>Shigella boydii</i> serotype O:10 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8234',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:183/<i>Shigella boydii</i> serotype O:10 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7352',
        Common_Name: 'daunorubicin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7352',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|daunorubicin biosynthesis',
    },
    {
        BioCyc_ID: 'GLYSYN-ALA-PWY',
        Common_Name: 'glycine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLYCINE-SYN|GLYSYN-ALA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|Glycine Biosynthesis|glycine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7444',
        Common_Name: 'luteolin triglucuronide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-7444',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|luteolin triglucuronide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6886',
        Common_Name: '1-butanol autotrophic biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-6886',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|1-butanol autotrophic biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6886',
        Common_Name: '1-butanol autotrophic biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6886',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|1-butanol autotrophic biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'GALLATE-DEGRADATION-I-PWY',
        Common_Name: 'gallate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|GALLATE-DEG|GALLATE-DEGRADATION-I-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Gallate Degradation|gallate degradation II',
    },
    {
        BioCyc_ID: 'PWY-8450',
        Common_Name: 'pyruvate-derived opine biosynthesis I (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Opine-Biosynthesis|PWY-8450',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Opine Biosynthesis|pyruvate-derived opine biosynthesis I (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6802',
        Common_Name: 'salidroside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-6802',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|salidroside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6088',
        Common_Name: '3-chlorobenzoate degradation I (via chlorocatechol)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|3-Chlorobenzoate-Degradation|PWY-6088',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|3-Chlorobenzoate Degradation|3-chlorobenzoate degradation I (via chlorocatechol)',
    },
    {
        BioCyc_ID: 'PWY-6088',
        Common_Name: '3-chlorobenzoate degradation I (via chlorocatechol)',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|3-Chlorobenzoate-Degradation|PWY-6088',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|3-Chlorobenzoate Degradation|3-chlorobenzoate degradation I (via chlorocatechol)',
    },
    {
        BioCyc_ID: 'PWY0-1565',
        Common_Name: 'D-lactate to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1565',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|D-lactate to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1565',
        Common_Name: 'D-lactate to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1565',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|D-lactate to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-7591',
        Common_Name: 'okenone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7591',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|okenone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7591',
        Common_Name: 'okenone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7591',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|okenone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7591',
        Common_Name: 'okenone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7591',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|okenone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8209',
        Common_Name: '<i>Escherichia coli</i> serotype O:15 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8209',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:15 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8209',
        Common_Name: '<i>Escherichia coli</i> serotype O:15 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8209',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:15 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8209',
        Common_Name: '<i>Escherichia coli</i> serotype O:15 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8209',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:15 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8209',
        Common_Name: '<i>Escherichia coli</i> serotype O:15 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8209',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:15 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7665',
        Common_Name: 'aureobasidin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7665',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|aureobasidin A biosynthesis',
    },
    {
        BioCyc_ID: 'LEUSYN-PWY',
        Common_Name: 'L-leucine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|LEUCINE-SYN|LEUSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-leucine Biosynthesis|L-leucine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5119',
        Common_Name: 'acacetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-5119',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|acacetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7000',
        Common_Name: 'kanamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7000',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|kanamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7000',
        Common_Name: 'kanamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7000',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|kanamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1501',
        Common_Name: 'mandelate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|MANDELATE-DEG|PWY-1501',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Mandelate Degradation|mandelate degradation I',
    },
    {
        BioCyc_ID: 'PWY-8449',
        Common_Name: 'mannopine and agropine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Opine-Biosynthesis|PWY-8449',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Opine Biosynthesis|mannopine and agropine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8296',
        Common_Name: 'cremeomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-8296',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|cremeomycin biosynthesis',
    },
    {
        BioCyc_ID: 'P141-PWY',
        Common_Name: 'atrazine degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|s-Triazine-Degradation|Atrazine-Degradation|P141-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>s</i>-Triazine Degredation|Atrazine Degradation|atrazine degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY1YI0-8',
        Common_Name: 'succinate to plastoquinol oxidase',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY1YI0-8',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|succinate to plastoquinol oxidase',
    },
    {
        BioCyc_ID: 'PWY1YI0-8',
        Common_Name: 'succinate to plastoquinol oxidase',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY1YI0-8',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|succinate to plastoquinol oxidase',
    },
    {
        BioCyc_ID: 'PWY-5943',
        Common_Name: '&beta;-carotene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5943',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&beta;-carotene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5943',
        Common_Name: '&beta;-carotene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5943',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&beta;-carotene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5943',
        Common_Name: '&beta;-carotene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5943',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&beta;-carotene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8237',
        Common_Name: '<i>Escherichia coli</i> serotype O:1B/<i>Salmonella enterica</i> serotype O:42 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8237',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:1B/<i>Salmonella enterica</i> serotype O:42 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8237',
        Common_Name: '<i>Escherichia coli</i> serotype O:1B/<i>Salmonella enterica</i> serotype O:42 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8237',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:1B/<i>Salmonella enterica</i> serotype O:42 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8237',
        Common_Name: '<i>Escherichia coli</i> serotype O:1B/<i>Salmonella enterica</i> serotype O:42 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8237',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:1B/<i>Salmonella enterica</i> serotype O:42 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8237',
        Common_Name: '<i>Escherichia coli</i> serotype O:1B/<i>Salmonella enterica</i> serotype O:42 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8237',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:1B/<i>Salmonella enterica</i> serotype O:42 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7797',
        Common_Name: 'nocardicin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7797',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|nocardicin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3462',
        Common_Name: 'L-phenylalanine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PHENYLALANINE-SYN|PWY-3462',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-phenylalanine Biosynthesis|L-phenylalanine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6527',
        Common_Name: 'stachyose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|PWY-6527',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|stachyose degradation',
    },
    {
        BioCyc_ID: 'PWYG-321',
        Common_Name: 'mycolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWYG-321',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|mycolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6015',
        Common_Name: 'vitexin and derivatives biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONE-SYN|PWY-6015',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavone Biosynthesis|vitexin and derivatives biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7209',
        Common_Name: 'superpathway of pyrimidine ribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Base-Degradation|PWY-7209',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Nucleobase Degradation|superpathway of pyrimidine ribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY-7209',
        Common_Name: 'superpathway of pyrimidine ribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Ribonucleosides-Degradation|PWY-7209',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Ribonucleoside Degradation|superpathway of pyrimidine ribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY-7209',
        Common_Name: 'superpathway of pyrimidine ribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7209',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pyrimidine ribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY-6957',
        Common_Name: 'mandelate degradation to acetyl-CoA',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|MANDELATE-DEG|PWY-6957',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Mandelate Degradation|mandelate degradation to acetyl-CoA',
    },
    {
        BioCyc_ID: 'PWY-6957',
        Common_Name: 'mandelate degradation to acetyl-CoA',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6957',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|mandelate degradation to acetyl-CoA',
    },
    {
        BioCyc_ID: 'PWY-7153',
        Common_Name: 'grixazone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7153',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|grixazone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8039',
        Common_Name: 'mitomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8039',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|mitomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1321',
        Common_Name: 'nitrate reduction III (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|nitrate reduction III (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY0-1321',
        Common_Name: 'nitrate reduction III (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|nitrate reduction III (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY0-1321',
        Common_Name: 'nitrate reduction III (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY0-1321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction III (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY-7940',
        Common_Name: 'lauryl-hydroxychlorobactene glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7940',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lauryl-hydroxychlorobactene glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7940',
        Common_Name: 'lauryl-hydroxychlorobactene glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7940',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lauryl-hydroxychlorobactene glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7940',
        Common_Name: 'lauryl-hydroxychlorobactene glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7940',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|lauryl-hydroxychlorobactene glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7905',
        Common_Name: '<i>Escherichia coli</i> serotype O:9a O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-7905',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:9a O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7905',
        Common_Name: '<i>Escherichia coli</i> serotype O:9a O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-7905',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:9a O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7905',
        Common_Name: '<i>Escherichia coli</i> serotype O:9a O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-7905',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:9a O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7905',
        Common_Name: '<i>Escherichia coli</i> serotype O:9a O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-7905',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:9a O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5731',
        Common_Name: 'atrazine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|s-Triazine-Degradation|Atrazine-Degradation|PWY-5731',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>s</i>-Triazine Degredation|Atrazine Degradation|atrazine degradation III',
    },
    {
        BioCyc_ID: 'PHESYN',
        Common_Name: 'L-phenylalanine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PHENYLALANINE-SYN|PHESYN',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-phenylalanine Biosynthesis|L-phenylalanine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8152',
        Common_Name: 'androstenedione degradation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Androstenedione-Degradation|PWY-8152',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Androstenedione Degradation|androstenedione degradation II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY0-1309',
        Common_Name: 'chitobiose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|PWY0-1309',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|chitobiose degradation',
    },
    {
        BioCyc_ID: 'PWY-6050',
        Common_Name: 'dimethyl sulfoxide degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6050',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|dimethyl sulfoxide degradation',
    },
    {
        BioCyc_ID: 'PWY-6944',
        Common_Name: 'androstenedione degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Androstenedione-Degradation|PWY-6944',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Androstenedione Degradation|androstenedione degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-5080',
        Common_Name: 'very long chain fatty acid biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY-5080',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|very long chain fatty acid biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY1F-823',
        Common_Name: 'leucopelargonidin and leucocyanidin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|LEUCOANTHOCYANIDIN-SYN|PWY1F-823',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Leucoanthocyanidin (flavan-3,4-diols) Biosynthesis|leucopelargonidin and leucocyanidin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7332',
        Common_Name: 'superpathway of UDP-<I>N</i>-acetylglucosamine-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7332',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of UDP-<I>N</i>-acetylglucosamine-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7332',
        Common_Name: 'superpathway of UDP-<I>N</i>-acetylglucosamine-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7332',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|superpathway of UDP-<I>N</i>-acetylglucosamine-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5137',
        Common_Name: 'fatty acid &beta;-oxidation III (unsaturated, odd number)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-5137',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|fatty acid &beta;-oxidation III (unsaturated, odd number)',
    },
    {
        BioCyc_ID: 'PWY66-391',
        Common_Name: 'fatty acid &beta;-oxidation VI (mammalian peroxisome)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY66-391',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|fatty acid &beta;-oxidation VI (mammalian peroxisome)',
    },
    {
        BioCyc_ID: 'PWY8J2-20',
        Common_Name: 'pulcherrimin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY8J2-20',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|pulcherrimin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8379',
        Common_Name: 'sibiromycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8379',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|sibiromycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1573',
        Common_Name: 'nitrate reduction VIIIb (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1573',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|nitrate reduction VIIIb (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY0-1573',
        Common_Name: 'nitrate reduction VIIIb (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1573',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|nitrate reduction VIIIb (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY0-1573',
        Common_Name: 'nitrate reduction VIIIb (dissimilatory)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY0-1573',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|nitrate reduction VIIIb (dissimilatory)',
    },
    {
        BioCyc_ID: 'PWY-5946',
        Common_Name: '&alpha;-carotene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5946',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&alpha;-carotene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5946',
        Common_Name: '&alpha;-carotene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5946',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&alpha;-carotene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5946',
        Common_Name: '&alpha;-carotene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5946',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|&alpha;-carotene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8217',
        Common_Name: '<i>Escherichia coli</i> serotype O:55/<i>Salmonella enterica</i> serotype O:50 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8217',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:55/<i>Salmonella enterica</i> serotype O:50 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8217',
        Common_Name: '<i>Escherichia coli</i> serotype O:55/<i>Salmonella enterica</i> serotype O:50 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|O-Antigen-Biosynthesis|PWY-8217',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:55/<i>Salmonella enterica</i> serotype O:50 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8217',
        Common_Name: '<i>Escherichia coli</i> serotype O:55/<i>Salmonella enterica</i> serotype O:50 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8217',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:55/<i>Salmonella enterica</i> serotype O:50 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8217',
        Common_Name: '<i>Escherichia coli</i> serotype O:55/<i>Salmonella enterica</i> serotype O:50 O antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|O-Antigen-Biosynthesis|PWY-8217',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|<i>O</i>-Antigen Biosynthesis|<i>Escherichia coli</i> serotype O:55/<i>Salmonella enterica</i> serotype O:50 O antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5727',
        Common_Name: 'atrazine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|s-Triazine-Degradation|Atrazine-Degradation|PWY-5727',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>s</i>-Triazine Degredation|Atrazine Degradation|atrazine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7432',
        Common_Name: 'L-phenylalanine biosynthesis III (cytosolic, plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PHENYLALANINE-SYN|PWY-7432',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-phenylalanine Biosynthesis|L-phenylalanine biosynthesis III (cytosolic, plants)',
    },
    {
        BioCyc_ID: 'PWY-7288',
        Common_Name: 'fatty acid &beta;-oxidation VII (yeast peroxisome)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-7288',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|fatty acid &beta;-oxidation VII (yeast peroxisome)',
    },
    {
        BioCyc_ID: 'PWY-7828',
        Common_Name: 'cyclobis-(1&rarr;6)-&alpha;-nigerosyl degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|PWY-7828',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|cyclobis-(1&rarr;6)-&alpha;-nigerosyl degradation',
    },
    {
        BioCyc_ID: 'PWY-6321',
        Common_Name: 'homotaurine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|homotaurine degradation',
    },
    {
        BioCyc_ID: 'PWY-7307',
        Common_Name: 'oleate &beta;-oxidation (reductase-dependent, yeast)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-7307',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|oleate &beta;-oxidation (reductase-dependent, yeast)',
    },
    {
        BioCyc_ID: 'PWY-7388',
        Common_Name: 'octanoyl-[acyl-carrier protein] biosynthesis (mitochondria, yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY-7388',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|octanoyl-[acyl-carrier protein] biosynthesis (mitochondria, yeast)',
    },
    {
        BioCyc_ID: 'PWY-5152',
        Common_Name: 'leucodelphinidin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|LEUCOANTHOCYANIDIN-SYN|PWY-5152',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Leucoanthocyanidin (flavan-3,4-diols) Biosynthesis|leucodelphinidin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7494',
        Common_Name: 'choline degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Choline-Degradation|PWY-7494',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Choline Degradation|choline degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7494',
        Common_Name: 'choline degradation IV',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7494',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|choline degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7339',
        Common_Name: '10-<i>trans</i>-heptadecenoyl-CoA degradation (MFE-dependent, yeast)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-7339',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|10-<i>trans</i>-heptadecenoyl-CoA degradation (MFE-dependent, yeast)',
    },
    {
        BioCyc_ID: 'PWY-8182',
        Common_Name: 'valproate &beta;-oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-8182',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|valproate &beta;-oxidation',
    },
    {
        BioCyc_ID: 'PWY-5723',
        Common_Name: 'Rubisco shunt',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-5723',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Rubisco shunt',
    },
    {
        BioCyc_ID: 'PWY-4161',
        Common_Name: 'superpathway of benzoxazinoid glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-4161',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|superpathway of benzoxazinoid glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4161',
        Common_Name: 'superpathway of benzoxazinoid glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-4161',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of benzoxazinoid glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5629',
        Common_Name: 'isopenicillin N biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5629',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|isopenicillin N biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5640',
        Common_Name: 'nitrobenzene degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrobenzene-Degradation|PWY-5640',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrobenzene Degradation|nitrobenzene degradation II',
    },
    {
        BioCyc_ID: 'PWY0-1335',
        Common_Name: 'NADH to cytochrome <i>bo</i> oxidase electron transfer I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1335',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|NADH to cytochrome <i>bo</i> oxidase electron transfer I',
    },
    {
        BioCyc_ID: 'PWY0-1335',
        Common_Name: 'NADH to cytochrome <i>bo</i> oxidase electron transfer I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1335',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADH to cytochrome <i>bo</i> oxidase electron transfer I',
    },
    {
        BioCyc_ID: 'PWY-7950',
        Common_Name: 'diadinoxanthin and fucoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7950',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|diadinoxanthin and fucoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7950',
        Common_Name: 'diadinoxanthin and fucoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7950',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|diadinoxanthin and fucoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7950',
        Common_Name: 'diadinoxanthin and fucoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7950',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|diadinoxanthin and fucoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8284',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (generic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-8284',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (generic)',
    },
    {
        BioCyc_ID: 'PWY-8284',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (generic)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-8284',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (generic)',
    },
    {
        BioCyc_ID: 'PWY-5136',
        Common_Name: 'fatty acid &beta;-oxidation II (plant peroxisome)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-5136',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|fatty acid &beta;-oxidation II (plant peroxisome)',
    },
    {
        BioCyc_ID: 'ALANINE-VALINESYN-PWY',
        Common_Name: 'L-alanine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ALANINE-SYN|ALANINE-VALINESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-alanine Biosynthesis|L-alanine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7586',
        Common_Name: '&beta;-1,4-D-mannosyl-<i>N</i>-acetyl-D-glucosamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|PWY-7586',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|&beta;-1,4-D-mannosyl-<i>N</i>-acetyl-D-glucosamine degradation',
    },
    {
        BioCyc_ID: 'PWY-6736',
        Common_Name: 'sulfur volatiles biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6736',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|sulfur volatiles biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6736',
        Common_Name: 'sulfur volatiles biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6736',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|sulfur volatiles biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-387',
        Common_Name: '3-methyl-branched fatty acid &alpha;-oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY66-387',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|3-methyl-branched fatty acid &alpha;-oxidation',
    },
    {
        BioCyc_ID: 'PWY66-430',
        Common_Name: 'tetradecanoate biosynthesis (mitochondria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY66-430',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|tetradecanoate biosynthesis (mitochondria)',
    },
    {
        BioCyc_ID: 'PWY-641',
        Common_Name: 'proanthocyanidins biosynthesis from flavanols',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PROANTHOCYANIDIN-SYN|PWY-641',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Proanthocyanidin Biosynthesis|proanthocyanidins biosynthesis from flavanols',
    },
    {
        BioCyc_ID: 'PWY0-1297',
        Common_Name: 'superpathway of purine deoxyribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|PWY0-1297',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|superpathway of purine deoxyribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY0-1297',
        Common_Name: 'superpathway of purine deoxyribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-1297',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of purine deoxyribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY-6837',
        Common_Name: 'fatty acid &beta;-oxidation V (unsaturated, odd number, di-isomerase-dependent)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-6837',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|fatty acid &beta;-oxidation V (unsaturated, odd number, di-isomerase-dependent)',
    },
    {
        BioCyc_ID: 'PWY-5344',
        Common_Name: 'L-homocysteine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-5344',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-homocysteine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7292',
        Common_Name: 'oleate &beta;-oxidation (thioesterase-dependent, yeast)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-7292',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|oleate &beta;-oxidation (thioesterase-dependent, yeast)',
    },
    {
        BioCyc_ID: 'PWY0-1517',
        Common_Name: 'sedoheptulose bisphosphate bypass',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY0-1517',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|sedoheptulose bisphosphate bypass',
    },
    {
        BioCyc_ID: 'PWY-6919',
        Common_Name: 'neopentalenoketolactone and pentalenate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6919',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|neopentalenoketolactone and pentalenate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7536',
        Common_Name: '2-amino-3-hydroxycyclopent-2-enone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7536',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|2-amino-3-hydroxycyclopent-2-enone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5637',
        Common_Name: 'nitrobenzene degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrobenzene-Degradation|PWY-5637',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrobenzene Degradation|nitrobenzene degradation I',
    },
    {
        BioCyc_ID: 'PWY0-1578',
        Common_Name: 'hydrogen to trimethylamine N-oxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1578',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|hydrogen to trimethylamine N-oxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1578',
        Common_Name: 'hydrogen to trimethylamine N-oxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1578',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|hydrogen to trimethylamine N-oxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY-6280',
        Common_Name: 'synechoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6280',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|synechoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6280',
        Common_Name: 'synechoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6280',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|synechoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6280',
        Common_Name: 'synechoxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-6280',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|synechoxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2DNV-1',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY2DNV-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-1',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY2DNV-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY-7338',
        Common_Name: '10-<i>trans</i>-heptadecenoyl-CoA degradation (reductase-dependent, yeast)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-7338',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|10-<i>trans</i>-heptadecenoyl-CoA degradation (reductase-dependent, yeast)',
    },
    {
        BioCyc_ID: 'ALANINE-SYN2-PWY',
        Common_Name: 'L-alanine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ALANINE-SYN|ALANINE-SYN2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-alanine Biosynthesis|L-alanine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY0-1602',
        Common_Name: 'D-gulosides conversion to D-glucosides',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|PWY0-1602',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|D-gulosides conversion to D-glucosides',
    },
    {
        BioCyc_ID: 'ALKANEMONOX-PWY',
        Common_Name: 'two-component alkanesulfonate monooxygenase',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|ALKANEMONOX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|two-component alkanesulfonate monooxygenase',
    },
    {
        BioCyc_ID: 'PWY-2501',
        Common_Name: 'fatty acid &alpha;-oxidation I (plants)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-2501',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|fatty acid &alpha;-oxidation I (plants)',
    },
    {
        BioCyc_ID: 'FASYN-ELONG-PWY',
        Common_Name: 'fatty acid elongation -- saturated',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|FASYN-ELONG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|fatty acid elongation -- saturated',
    },
    {
        BioCyc_ID: 'PWY-7227',
        Common_Name: 'adenosine deoxyribonucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|Adenosine-Deoxy-Denovo-Bbiosynthesis|PWY-7227',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|Adenosine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|adenosine deoxyribonucleotides <i>de novo</i> biosynthesis I",
    },
    {
        BioCyc_ID: 'PWY-7227',
        Common_Name: 'adenosine deoxyribonucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|Adenosine-Deoxy-Denovo-Bbiosynthesis|PWY-7227',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|Adenosine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|adenosine deoxyribonucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY2OL-4',
        Common_Name: 'superpathway of linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|Linalool-Biosynthesis|PWY2OL-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|Linalool Biosynthesis|superpathway of linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2OL-4',
        Common_Name: 'superpathway of linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|Linalool-Biosynthesis|PWY2OL-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Linalool Biosynthesis|superpathway of linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2OL-4',
        Common_Name: 'superpathway of linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY2OL-4',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1337',
        Common_Name: 'oleate &beta;-oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY0-1337',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|oleate &beta;-oxidation',
    },
    {
        BioCyc_ID: 'PWY-6936',
        Common_Name: 'seleno-amino acid biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-6936',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|seleno-amino acid biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-5138',
        Common_Name: 'fatty acid &beta;-oxidation IV (unsaturated, even number)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-5138',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|fatty acid &beta;-oxidation IV (unsaturated, even number)',
    },
    {
        BioCyc_ID: 'PWY-6728',
        Common_Name: 'methylaspartate cycle',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-6728',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|methylaspartate cycle',
    },
    {
        BioCyc_ID: 'PWY-7811',
        Common_Name: '6-methylpretetramide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7811',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|6-methylpretetramide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5961',
        Common_Name: 'superpathway of aflatoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-5961',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|superpathway of aflatoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5961',
        Common_Name: 'superpathway of aflatoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5961',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of aflatoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5645',
        Common_Name: '4-chloronitrobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrobenzene-Degradation|PWY-5645',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrobenzene Degradation|4-chloronitrobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-4302',
        Common_Name: 'aerobic respiration III (alternative oxidase pathway)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY-4302',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|aerobic respiration III (alternative oxidase pathway)',
    },
    {
        BioCyc_ID: 'PWY-4302',
        Common_Name: 'aerobic respiration III (alternative oxidase pathway)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-4302',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|aerobic respiration III (alternative oxidase pathway)',
    },
    {
        BioCyc_ID: 'PWY-5399',
        Common_Name: 'betacyanin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|BETALAIN-ALKALOIDS|PWY-5399',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Betalaine Alkaloid Biosynthesis|betacyanin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7675',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Haemophilus</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-7675',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>Haemophilus</i>)',
    },
    {
        BioCyc_ID: 'PWY-7675',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Haemophilus</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-7675',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>Haemophilus</i>)',
    },
    {
        BioCyc_ID: 'PWY-7291',
        Common_Name: 'oleate &beta;-oxidation (isomerase-dependent, yeast)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-7291',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|oleate &beta;-oxidation (isomerase-dependent, yeast)',
    },
    {
        BioCyc_ID: 'PWY0-1021',
        Common_Name: 'L-alanine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ALANINE-SYN|PWY0-1021',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-alanine Biosynthesis|L-alanine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7465',
        Common_Name: "3,3'-thiodipropanoate degradation",
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-7465',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|3,3'-thiodipropanoate degradation",
    },
    {
        BioCyc_ID: 'PWY-7337',
        Common_Name: '10-<i>cis</i>-heptadecenoyl-CoA degradation (yeast)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-7337',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|10-<i>cis</i>-heptadecenoyl-CoA degradation (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7094',
        Common_Name: 'fatty acid salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY-7094',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|fatty acid salvage',
    },
    {
        BioCyc_ID: 'PWY-7220',
        Common_Name: 'adenosine deoxyribonucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|Adenosine-Deoxy-Denovo-Bbiosynthesis|PWY-7220',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|Adenosine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|adenosine deoxyribonucleotides <i>de novo</i> biosynthesis II",
    },
    {
        BioCyc_ID: 'PWY-7220',
        Common_Name: 'adenosine deoxyribonucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|Adenosine-Deoxy-Denovo-Bbiosynthesis|PWY-7220',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|Adenosine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|adenosine deoxyribonucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'BIOTIN-BIOSYNTHESIS-PWY',
        Common_Name: 'biotin biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|BIOTIN-SYN|BIOTIN-BIOSYNTHESIS-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Biotin Biosynthesis|biotin biosynthesis I',
    },
    {
        BioCyc_ID: 'BIOTIN-BIOSYNTHESIS-PWY',
        Common_Name: 'biotin biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|BIOTIN-BIOSYNTHESIS-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|biotin biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7044',
        Common_Name: '5-nitroanthranilate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|PWY-7044',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|5-nitroanthranilate degradation',
    },
    {
        BioCyc_ID: 'PWY-7340',
        Common_Name: '9-<i>cis</i>, 11-<i>trans</i>-octadecadienoyl-CoA degradation (isomerase-dependent, yeast)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-7340',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|9-<i>cis</i>, 11-<i>trans</i>-octadecadienoyl-CoA degradation (isomerase-dependent, yeast)',
    },
    {
        BioCyc_ID: 'PWY-7649',
        Common_Name: '3-hydroxy-<i>L</i>-homotyrosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-7649',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|3-hydroxy-<i>L</i>-homotyrosine biosynthesis',
    },
    {
        BioCyc_ID: 'FAO-PWY',
        Common_Name: 'fatty acid &beta;-oxidation I (generic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|FAO-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|fatty acid &beta;-oxidation I (generic)',
    },
    {
        BioCyc_ID: 'MALATE-ASPARTATE-SHUTTLE-PWY',
        Common_Name: 'malate/L-aspartate shuttle pathway',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|MALATE-ASPARTATE-SHUTTLE-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|malate/L-aspartate shuttle pathway',
    },
    {
        BioCyc_ID: 'PWY-8409',
        Common_Name: 'cyathin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8409',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|cyathin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7751',
        Common_Name: 'shinorine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7751',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|shinorine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1348',
        Common_Name: 'NADH to dimethyl sulfoxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1348',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|NADH to dimethyl sulfoxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1348',
        Common_Name: 'NADH to dimethyl sulfoxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1348',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADH to dimethyl sulfoxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY-5404',
        Common_Name: 'betaxanthin biosynthesis (via dopaxanthin)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|BETALAIN-ALKALOIDS|PWY-5404',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Betalaine Alkaloid Biosynthesis|betaxanthin biosynthesis (via dopaxanthin)',
    },
    {
        BioCyc_ID: 'PWY-8246',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-8246',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8246',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-8246',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8354',
        Common_Name: 'adipate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-8354',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|adipate degradation',
    },
    {
        BioCyc_ID: 'PWY-8011',
        Common_Name: 'L-serine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|SERINE-BIOSYNTHESIS|PWY-8011',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-serine Biosynthesis|L-serine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5260',
        Common_Name: 'methanogenesis from methylthiopropanoate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-5260',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from methylthiopropanoate',
    },
    {
        BioCyc_ID: 'PWY-5260',
        Common_Name: 'methanogenesis from methylthiopropanoate',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-5260',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|methanogenesis from methylthiopropanoate',
    },
    {
        BioCyc_ID: 'PWY-6967',
        Common_Name: 'methylamine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Methylamine-Degradation|PWY-6967',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Methylamine Degradation|methylamine degradation I',
    },
    {
        BioCyc_ID: 'PWY-7036',
        Common_Name: 'very long chain fatty acid biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY-7036',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|very long chain fatty acid biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7226',
        Common_Name: 'guanosine deoxyribonucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|Guanosine-Deoxy-Denovo-Biosynthesis|PWY-7226',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|Guanosine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|guanosine deoxyribonucleotides <i>de novo</i> biosynthesis I",
    },
    {
        BioCyc_ID: 'PWY-7226',
        Common_Name: 'guanosine deoxyribonucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|Guanosine-Deoxy-Denovo-Biosynthesis|PWY-7226',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|Guanosine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|guanosine deoxyribonucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'TRYPTOPHAN-DEGRADATION-1',
        Common_Name: 'L-tryptophan degradation III (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|TRYPTOPHAN-DEGRADATION-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-tryptophan degradation III (eukaryotic)',
    },
    {
        BioCyc_ID: 'TRYPTOPHAN-DEGRADATION-1',
        Common_Name: 'L-tryptophan degradation III (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|TRYPTOPHAN-DEGRADATION-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation III (eukaryotic)',
    },
    {
        BioCyc_ID: 'PWY-7628',
        Common_Name: '2,4-dinitroanisole degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|PWY-7628',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|2,4-dinitroanisole degradation',
    },
    {
        BioCyc_ID: 'PWY-6965',
        Common_Name: 'methylamine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Methylamine-Degradation|PWY-6965',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Methylamine Degradation|methylamine degradation II',
    },
    {
        BioCyc_ID: 'PWY-8140',
        Common_Name: 'serine racemization',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-8140',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|serine racemization',
    },
    {
        BioCyc_ID: 'PWY-6938',
        Common_Name: 'NADH repair (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-6938',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|NADH repair (eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-5632',
        Common_Name: 'cephalosporin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5632',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|cephalosporin C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6660',
        Common_Name: '2-heptyl-3-hydroxy-4(1<i>H</i>)-quinolone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-6660',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|2-heptyl-3-hydroxy-4(1<i>H</i>)-quinolone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5641',
        Common_Name: '2-nitrotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitro-Toluene-Degradation|PWY-5641',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrotoluene Degradation|2-nitrotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY0-1584',
        Common_Name: '<i>sn</i>-glycerol 3-phosphate anaerobic respiration',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1584',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|<i>sn</i>-glycerol 3-phosphate anaerobic respiration',
    },
    {
        BioCyc_ID: 'PWY0-1584',
        Common_Name: '<i>sn</i>-glycerol 3-phosphate anaerobic respiration',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1584',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|<i>sn</i>-glycerol 3-phosphate anaerobic respiration',
    },
    {
        BioCyc_ID: 'PWY0-1584',
        Common_Name: '<i>sn</i>-glycerol 3-phosphate anaerobic respiration',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Nitrate-Reduction|PWY0-1584',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Nitrate Reduction|<i>sn</i>-glycerol 3-phosphate anaerobic respiration',
    },
    {
        BioCyc_ID: 'PWY-5439',
        Common_Name: 'betacyanin biosynthesis (via dopamine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|BETALAIN-ALKALOIDS|PWY-5439',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Betalaine Alkaloid Biosynthesis|betacyanin biosynthesis (via dopamine)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-6',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Brucella</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY2B4Q-6',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>Brucella</i>)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-6',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Brucella</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY2B4Q-6',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>Brucella</i>)',
    },
    {
        BioCyc_ID: 'SERSYN-PWY',
        Common_Name: 'L-serine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|SERINE-BIOSYNTHESIS|SERSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-serine Biosynthesis|L-serine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8418',
        Common_Name: '<i>S</i>-benzyl-L-cysteine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-8418',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|<i>S</i>-benzyl-L-cysteine degradation',
    },
    {
        BioCyc_ID: 'PWY-8041',
        Common_Name: 'ultra-long-chain fatty acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY-8041',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|ultra-long-chain fatty acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7222',
        Common_Name: 'guanosine deoxyribonucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|Guanosine-Deoxy-Denovo-Biosynthesis|PWY-7222',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|Guanosine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|guanosine deoxyribonucleotides <i>de novo</i> biosynthesis II",
    },
    {
        BioCyc_ID: 'PWY-7222',
        Common_Name: 'guanosine deoxyribonucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|Guanosine-Deoxy-Denovo-Biosynthesis|PWY-7222',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|Guanosine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|guanosine deoxyribonucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'GLUDEG-I-PWY',
        Common_Name: 'GABA shunt I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|4-Aminobutyraye-Degradation|GLUDEG-I-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|4-Aminobutanoate Degradation|GABA shunt I',
    },
    {
        BioCyc_ID: 'GLUDEG-I-PWY',
        Common_Name: 'GABA shunt I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|GLUDEG-I-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|GABA shunt I',
    },
    {
        BioCyc_ID: 'GLUDEG-I-PWY',
        Common_Name: 'GABA shunt I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GLUDEG-I-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|GABA shunt I',
    },
    {
        BioCyc_ID: 'PWY-7403',
        Common_Name: 'tetramethylpyrazine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|PWY-7403',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|tetramethylpyrazine degradation',
    },
    {
        BioCyc_ID: 'PWY-1186',
        Common_Name: 'L-homomethionine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-1186',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-homomethionine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5197',
        Common_Name: 'lactate biosynthesis (archaea)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-5197',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|lactate biosynthesis (archaea)',
    },
    {
        BioCyc_ID: 'PWY-6003',
        Common_Name: 'gramicidin S biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6003',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|gramicidin S biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7936',
        Common_Name: 'psilocybin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7936',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|psilocybin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6051',
        Common_Name: '2,4,6-trinitrotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitro-Toluene-Degradation|PWY-6051',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrotoluene Degradation|2,4,6-trinitrotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY-6057',
        Common_Name: 'dimethylsulfide to cytochrome <i>c</i><sub>2</sub> electron transfer',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Dimethylsulfide-Degradation|PWY-6057',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Dimethylsulfide Degradation|dimethylsulfide to cytochrome <i>c</i><sub>2</sub> electron transfer',
    },
    {
        BioCyc_ID: 'PWY-6057',
        Common_Name: 'dimethylsulfide to cytochrome <i>c</i><sub>2</sub> electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-6057',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|dimethylsulfide to cytochrome <i>c</i><sub>2</sub> electron transfer',
    },
    {
        BioCyc_ID: 'PWY-5394',
        Common_Name: 'betalamic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|BETALAIN-ALKALOIDS|PWY-5394',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Betalaine Alkaloid Biosynthesis|betalamic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8074',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>P. putida</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-8074',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>P. putida</i>)',
    },
    {
        BioCyc_ID: 'PWY-8074',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>P. putida</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-8074',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>P. putida</i>)',
    },
    {
        BioCyc_ID: 'ASPARTATESYN-PWY',
        Common_Name: 'L-aspartate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ASPARTATE-SYN|ASPARTATESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-aspartate Biosynthesis|L-aspartate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6044',
        Common_Name: 'methanesulfonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6044',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|methanesulfonate degradation',
    },
    {
        BioCyc_ID: 'PWY-8378',
        Common_Name: 'Kdo-lipid A biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8378',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Kdo-lipid A biosynthesis|Kdo-lipid A biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
    },
    {
        BioCyc_ID: 'PWY-8378',
        Common_Name: 'Kdo-lipid A biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8378',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|Kdo-lipid A biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
    },
    {
        BioCyc_ID: 'PWY-8378',
        Common_Name: 'Kdo-lipid A biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8378',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|Kdo-lipid A biosynthesis (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
    },
    {
        BioCyc_ID: 'ORNARGDEG-PWY',
        Common_Name: 'superpathway of L-arginine and L-ornithine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ORNARGDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|superpathway of L-arginine and L-ornithine degradation',
    },
    {
        BioCyc_ID: 'ORNARGDEG-PWY',
        Common_Name: 'superpathway of L-arginine and L-ornithine degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ORNARGDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-arginine and L-ornithine degradation',
    },
    {
        BioCyc_ID: 'PWY-283',
        Common_Name: 'benzoate degradation II (aerobic and anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzoate-Degradation|PWY-283',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzoate Degradation|benzoate degradation II (aerobic and anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7648',
        Common_Name: '3-hydroxy-4-methyl-proline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-7648',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|3-hydroxy-4-methyl-proline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7015',
        Common_Name: 'ribostamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7015',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|ribostamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7813',
        Common_Name: 'thiosulfate disproportionation III (quinone)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-7813',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|thiosulfate disproportionation III (quinone)',
    },
    {
        BioCyc_ID: 'PWY-7813',
        Common_Name: 'thiosulfate disproportionation III (quinone)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Disproportionation|PWY-7813',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Disproportionation|thiosulfate disproportionation III (quinone)',
    },
    {
        BioCyc_ID: 'PWY-6703',
        Common_Name: 'preQ<sub>0</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-6703',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|preQ<sub>0</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1355',
        Common_Name: 'formate to trimethylamine <i>N</i>-oxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1355',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|formate to trimethylamine <i>N</i>-oxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1355',
        Common_Name: 'formate to trimethylamine <i>N</i>-oxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1355',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|formate to trimethylamine <i>N</i>-oxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY-5403',
        Common_Name: 'betaxanthin biosynthesis (via dopamine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|BETALAIN-ALKALOIDS|PWY-5403',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Betalaine Alkaloid Biosynthesis|betaxanthin biosynthesis (via dopamine)',
    },
    {
        BioCyc_ID: 'PWY-8284-1',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-8284-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
    },
    {
        BioCyc_ID: 'PWY-8284-1',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-8284-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>Vibrio cholerae</i> serogroup O1 El Tor)',
    },
    {
        BioCyc_ID: 'TRPSYN-PWY',
        Common_Name: 'L-tryptophan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|TRYPTOPHAN-BIOSYNTHESIS|TRPSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-tryptophan Biosynthesis|L-tryptophan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6072',
        Common_Name: 'superpathway of dimethylsulfone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6072',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|superpathway of dimethylsulfone degradation',
    },
    {
        BioCyc_ID: 'PWY-6072',
        Common_Name: 'superpathway of dimethylsulfone degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6072',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of dimethylsulfone degradation',
    },
    {
        BioCyc_ID: 'PWY2G6Z-4',
        Common_Name: 'lipid A modification (glycine addition, <i>Vibrio cholerae</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2G6Z-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Kdo-lipid A biosynthesis|lipid A modification (glycine addition, <i>Vibrio cholerae</i>)',
    },
    {
        BioCyc_ID: 'PWY2G6Z-4',
        Common_Name: 'lipid A modification (glycine addition, <i>Vibrio cholerae</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2G6Z-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|lipid A modification (glycine addition, <i>Vibrio cholerae</i>)',
    },
    {
        BioCyc_ID: 'PWY2G6Z-4',
        Common_Name: 'lipid A modification (glycine addition, <i>Vibrio cholerae</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2G6Z-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|lipid A modification (glycine addition, <i>Vibrio cholerae</i>)',
    },
    {
        BioCyc_ID: 'PWY-7176',
        Common_Name: 'UTP and CTP <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Ribonucleot-De-Novo-Biosyn|PWY-7176',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Ribonucleotide <i>De Novo</i> Biosynthesis|UTP and CTP <i>de novo</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PHOSLIPSYN-PWY',
        Common_Name: 'superpathway of phospholipid biosynthesis III (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PHOSLIPSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|superpathway of phospholipid biosynthesis III (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PHOSLIPSYN-PWY',
        Common_Name: 'superpathway of phospholipid biosynthesis III (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PHOSLIPSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of phospholipid biosynthesis III (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-2503',
        Common_Name: 'benzoate degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzoate-Degradation|PWY-2503',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzoate Degradation|benzoate degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-8072',
        Common_Name: 'alanine racemization',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-8072',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|alanine racemization',
    },
    {
        BioCyc_ID: 'PWY-7485',
        Common_Name: 'tetracenomycin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7485',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|tetracenomycin C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8137',
        Common_Name: 'NADPH repair (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-8137',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|NADPH repair (eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-8158',
        Common_Name: 'ibotenate and muscimol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-8158',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|ibotenate and muscimol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1591',
        Common_Name: 'glycerol-3-phosphate to hydrogen peroxide electron transport',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1591',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|glycerol-3-phosphate to hydrogen peroxide electron transport',
    },
    {
        BioCyc_ID: 'PWY-5426',
        Common_Name: 'betaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|BETALAIN-ALKALOIDS|PWY-5426',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Betalaine Alkaloid Biosynthesis|betaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'KDOSYN-PWY',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|KDOSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'KDOSYN-PWY',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|KDOSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-5103',
        Common_Name: 'L-isoleucine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ISOLEUCINE-SYN|PWY-5103',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-isoleucine Biosynthesis|L-isoleucine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6642',
        Common_Name: '(<i>R</i>)-cysteate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6642',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|(<i>R</i>)-cysteate degradation',
    },
    {
        BioCyc_ID: 'PWY-6742',
        Common_Name: 'methane oxidation to methanol II',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Methane-Oxidation|PWY-6742',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|methane oxidation to methanol|methane oxidation to methanol II',
    },
    {
        BioCyc_ID: 'KDO-LIPASYN-PWY',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Kdo-lipid-A-Biosynthesis|KDO-LIPASYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'KDO-LIPASYN-PWY',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|KDO-LIPASYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'KDO-LIPASYN-PWY',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|KDO-LIPASYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-6620',
        Common_Name: 'guanine and guanosine salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Guanine-Guanosine-Salvage|PWY-6620',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Guanine and Guanosine Salvage|guanine and guanosine salvage I',
    },
    {
        BioCyc_ID: 'PWY-3001',
        Common_Name: 'superpathway of L-isoleucine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ISOLEUCINE-SYN|PWY-3001',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-isoleucine Biosynthesis|superpathway of L-isoleucine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-3001',
        Common_Name: 'superpathway of L-isoleucine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-3001',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-isoleucine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7690',
        Common_Name: 'holomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7690',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|holomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5430',
        Common_Name: '<i>meta</i> cleavage pathway of aromatic compounds',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzoate-Degradation|PWY-5430',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzoate Degradation|<i>meta</i> cleavage pathway of aromatic compounds',
    },
    {
        BioCyc_ID: 'PWY-5430',
        Common_Name: '<i>meta</i> cleavage pathway of aromatic compounds',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5430',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|<i>meta</i> cleavage pathway of aromatic compounds',
    },
    {
        BioCyc_ID: 'PWY-1641',
        Common_Name: 'methane oxidation to methanol I',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Methane-Oxidation|PWY-1641',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|methane oxidation to methanol|methane oxidation to methanol I',
    },
    {
        BioCyc_ID: 'PWY-8441',
        Common_Name: '(4<i>R</i>)-4-[(<i>E</i>)-2-butenyl]-4-methyl-L-threonine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-8441',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|(4<i>R</i>)-4-[(<i>E</i>)-2-butenyl]-4-methyl-L-threonine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6440',
        Common_Name: 'spermine and spermidine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|SPERMINE-SPERMIDINE-DEG|PWY-6440',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Spermine and Spermidine Degradation|spermine and spermidine degradation II',
    },
    {
        BioCyc_ID: 'PWY-8427',
        Common_Name: '2-allylmalonyl-CoA biosynthesis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-8427',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|2-allylmalonyl-CoA biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7040',
        Common_Name: 'violacein biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-7040',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|violacein biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7627',
        Common_Name: '2,4,6-trinitrophenol and 2,4-dinitrophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrophenol-Degradation|PWY-7627',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrophenol Degradation|2,4,6-trinitrophenol and 2,4-dinitrophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-7627',
        Common_Name: '2,4,6-trinitrophenol and 2,4-dinitrophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Nitrophenol-Degradation|PWY-7627',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Nitrophenol Degradation|2,4,6-trinitrophenol and 2,4-dinitrophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-7544',
        Common_Name: 'pyruvate to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY-7544',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|pyruvate to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-7544',
        Common_Name: 'pyruvate to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-7544',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|pyruvate to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-5400',
        Common_Name: 'amaranthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|BETALAIN-ALKALOIDS|PWY-5400',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Betalaine Alkaloid Biosynthesis|amaranthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7676',
        Common_Name: 'Kdo8N transfer to lipid IV<sub>A</sub>',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-7676',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo8N transfer to lipid IV<sub>A</sub>',
    },
    {
        BioCyc_ID: 'PWY-7676',
        Common_Name: 'Kdo8N transfer to lipid IV<sub>A</sub>',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-7676',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo8N transfer to lipid IV<sub>A</sub>',
    },
    {
        BioCyc_ID: 'PWY-6117',
        Common_Name: 'spermine and spermidine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|SPERMINE-SPERMIDINE-DEG|PWY-6117',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Spermine and Spermidine Degradation|spermine and spermidine degradation I',
    },
    {
        BioCyc_ID: 'PWY-5101',
        Common_Name: 'L-isoleucine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ISOLEUCINE-SYN|PWY-5101',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-isoleucine Biosynthesis|L-isoleucine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7462',
        Common_Name: "3,3'-disulfanediyldipropannoate degradation",
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-7462',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|3,3'-disulfanediyldipropannoate degradation",
    },
    {
        BioCyc_ID: 'PWY-6441',
        Common_Name: 'spermine and spermidine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|SPERMINE-SPERMIDINE-DEG|PWY-6441',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Spermine and Spermidine Degradation|spermine and spermidine degradation III',
    },
    {
        BioCyc_ID: 'PWY-8285',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (generic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8285',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (generic)',
    },
    {
        BioCyc_ID: 'PWY-8285',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (generic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8285',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (generic)',
    },
    {
        BioCyc_ID: 'PWY-8285',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (generic)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8285',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (generic)',
    },
    {
        BioCyc_ID: 'PWY-6618',
        Common_Name: 'guanine and guanosine salvage III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Guanine-Guanosine-Salvage|PWY-6618',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Guanine and Guanosine Salvage|guanine and guanosine salvage III',
    },
    {
        BioCyc_ID: 'PWY-5071',
        Common_Name: 'superpathway of rosmarinic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-5071',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|superpathway of rosmarinic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5071',
        Common_Name: 'superpathway of rosmarinic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5071',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of rosmarinic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7863',
        Common_Name: 'roseoflavin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7863',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|roseoflavin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6636',
        Common_Name: 'salicylate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Salicylate-Degradation|PWY-6636',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Salicylate Degradation|salicylate degradation III',
    },
    {
        BioCyc_ID: 'HOMOSERSYN-PWY',
        Common_Name: 'L-homoserine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|HOMOSERSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-homoserine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6118',
        Common_Name: 'glycerol-3-phosphate shuttle',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-6118',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|glycerol-3-phosphate shuttle',
    },
    {
        BioCyc_ID: 'PWY-8447',
        Common_Name: 'carminate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|PWY-8447',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|carminate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5642',
        Common_Name: '2,4-dinitrotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Dinitrotoluene-Degradation|PWY-5642',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Dinitrotoluene Degradation|2,4-dinitrotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY0-1561',
        Common_Name: 'glycerol-3-phosphate to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1561',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|glycerol-3-phosphate to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1561',
        Common_Name: 'glycerol-3-phosphate to cytochrome <i>bo</i> oxidase electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1561',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|glycerol-3-phosphate to cytochrome <i>bo</i> oxidase electron transfer',
    },
    {
        BioCyc_ID: 'PWY-5752',
        Common_Name: 'piperine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PYRROLIDINE-ALKALOIDS|PWY-5752',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Pyrrolidine, Piperidine and Pyridine Alkaloid Biosynthesis|piperine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5108',
        Common_Name: 'L-isoleucine biosynthesis V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ISOLEUCINE-SYN|PWY-5108',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-isoleucine Biosynthesis|L-isoleucine biosynthesis V',
    },
    {
        BioCyc_ID: 'PWY-5258',
        Common_Name: 'methanogenesis from dimethylsulfide',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-5258',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from dimethylsulfide',
    },
    {
        BioCyc_ID: 'PWY-5258',
        Common_Name: 'methanogenesis from dimethylsulfide',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-5258',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|methanogenesis from dimethylsulfide',
    },
    {
        BioCyc_ID: 'PWY2DNV-3',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A modification (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2DNV-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A modification (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-3',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A modification (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2DNV-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A modification (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-3',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A modification (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2DNV-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A modification (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY-6599',
        Common_Name: 'guanine and guanosine salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Guanine-Guanosine-Salvage|PWY-6599',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Guanine and Guanosine Salvage|guanine and guanosine salvage II',
    },
    {
        BioCyc_ID: 'PWY-8297',
        Common_Name: '6-diazo-5-oxo-L-norleucine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8297',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|6-diazo-5-oxo-L-norleucine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5265',
        Common_Name: 'peptidoglycan biosynthesis II (staphylococci)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Biosynthesis|PWY-5265',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Biosynthesis|peptidoglycan biosynthesis II (staphylococci)',
    },
    {
        BioCyc_ID: 'PWY-5265',
        Common_Name: 'peptidoglycan biosynthesis II (staphylococci)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5265',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|peptidoglycan biosynthesis II (staphylococci)',
    },
    {
        BioCyc_ID: 'PWY-6224',
        Common_Name: 'salicylate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Salicylate-Degradation|PWY-6224',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Salicylate Degradation|salicylate degradation II',
    },
    {
        BioCyc_ID: 'PWY-7275',
        Common_Name: 'L-homophenylalanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-7275',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-homophenylalanine biosynthesis',
    },
    {
        BioCyc_ID: 'RUMP-PWY',
        Common_Name: 'formaldehyde oxidation I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|RUMP-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|formaldehyde oxidation I',
    },
    {
        BioCyc_ID: 'RUMP-PWY',
        Common_Name: 'formaldehyde oxidation I',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Oxidation|RUMP-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Oxidation|formaldehyde oxidation I',
    },
    {
        BioCyc_ID: 'PWY-7681',
        Common_Name: '1-chloro-2-nitrobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Dinitrotoluene-Degradation|PWY-7681',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Dinitrotoluene Degradation|1-chloro-2-nitrobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY1YI0-7',
        Common_Name: 'protective electron sinks in the thylakoid membrane (PSII to PTOX)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY1YI0-7',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|protective electron sinks in the thylakoid membrane (PSII to PTOX)',
    },
    {
        BioCyc_ID: 'PWY-8080',
        Common_Name: 'kainate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PYRROLIDINE-ALKALOIDS|PWY-8080',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Pyrrolidine, Piperidine and Pyridine Alkaloid Biosynthesis|kainate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5104',
        Common_Name: 'L-isoleucine biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ISOLEUCINE-SYN|PWY-5104',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-isoleucine Biosynthesis|L-isoleucine biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-7898',
        Common_Name: "4,4'-disulfanediyldibutanoate degradation",
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-7898',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|4,4'-disulfanediyldibutanoate degradation",
    },
    {
        BioCyc_ID: 'PWY-8247',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8247',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8247',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8247',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8247',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8247',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-6609',
        Common_Name: 'adenine and adenosine salvage III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Adenine-Adenosine-Salvage|PWY-6609',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Adenine and Adenosine Salvage|adenine and adenosine salvage III',
    },
    {
        BioCyc_ID: 'PWY1A0-6120',
        Common_Name: 'streptorubin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY1A0-6120',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|streptorubin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5405',
        Common_Name: 'superpathway of betalain biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|BETALAIN-ALKALOIDS|PWY-5405',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Betalaine Alkaloid Biosynthesis|superpathway of betalain biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5405',
        Common_Name: 'superpathway of betalain biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5405',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of betalain biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6183',
        Common_Name: 'salicylate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Salicylate-Degradation|PWY-6183',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Salicylate Degradation|salicylate degradation I',
    },
    {
        BioCyc_ID: 'PWY-6946',
        Common_Name: 'cholesterol degradation to androstenedione II (cholesterol dehydrogenase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Cholesterol-Degradation|PWY-6946',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Cholesterol Degradation|cholesterol degradation to androstenedione II (cholesterol dehydrogenase)',
    },
    {
        BioCyc_ID: 'PWY-7701',
        Common_Name: '4-hydroxy-4-methyl-L-glutamate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-7701',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|4-hydroxy-4-methyl-L-glutamate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6945',
        Common_Name: 'cholesterol degradation to androstenedione I (cholesterol oxidase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Cholesterol-Degradation|PWY-6945',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Cholesterol Degradation|cholesterol degradation to androstenedione I (cholesterol oxidase)',
    },
    {
        BioCyc_ID: 'GLYOXYLATE-BYPASS',
        Common_Name: 'glyoxylate cycle',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYOXYLATE-BYPASS',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|glyoxylate cycle',
    },
    {
        BioCyc_ID: 'PWY-6583',
        Common_Name: 'pyruvate fermentation to butanol I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-6583',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|pyruvate fermentation to butanol I',
    },
    {
        BioCyc_ID: 'PWY-6583',
        Common_Name: 'pyruvate fermentation to butanol I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Butanol-Fermentation|PWY-6583',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Butanol|pyruvate fermentation to butanol I',
    },
    {
        BioCyc_ID: 'PWY-6583',
        Common_Name: 'pyruvate fermentation to butanol I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Butanol-Fermentation|PWY-6583',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Butanol|pyruvate fermentation to butanol I',
    },
    {
        BioCyc_ID: 'PWY-5643',
        Common_Name: '2,6-dinitrotoluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Dinitrotoluene-Degradation|PWY-5643',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Dinitrotoluene Degradation|2,6-dinitrotoluene degradation',
    },
    {
        BioCyc_ID: 'PWY-8271',
        Common_Name: 'NADPH to cytochrome <i>c</i> oxidase via plastocyanin',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY-8271',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|NADPH to cytochrome <i>c</i> oxidase via plastocyanin',
    },
    {
        BioCyc_ID: 'PWY-8271',
        Common_Name: 'NADPH to cytochrome <i>c</i> oxidase via plastocyanin',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-8271',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADPH to cytochrome <i>c</i> oxidase via plastocyanin',
    },
    {
        BioCyc_ID: 'PWY-5316',
        Common_Name: 'nicotine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PYRROLIDINE-ALKALOIDS|PWY-5316',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Pyrrolidine, Piperidine and Pyridine Alkaloid Biosynthesis|nicotine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8151',
        Common_Name: 'cholesterol degradation to androstenedione III (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Cholesterol-Degradation|PWY-8151',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Cholesterol Degradation|cholesterol degradation to androstenedione III (anaerobic)',
    },
    {
        BioCyc_ID: 'ILEUSYN-PWY',
        Common_Name: 'L-isoleucine biosynthesis I (from threonine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ISOLEUCINE-SYN|ILEUSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-isoleucine Biosynthesis|L-isoleucine biosynthesis I (from threonine)',
    },
    {
        BioCyc_ID: 'PWY-6043',
        Common_Name: 'ethanedisulfonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6043',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|ethanedisulfonate degradation',
    },
    {
        BioCyc_ID: 'PWY-8109',
        Common_Name: 'proline betaine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Proline-Betaine-Degradation|PWY-8109',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|L-Proline Betaine Degradation|proline betaine degradation II',
    },
    {
        BioCyc_ID: 'PWY2DNV-2',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2DNV-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-2',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2DNV-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-2',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY2DNV-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY-6619',
        Common_Name: 'adenine and adenosine salvage VI',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Adenine-Adenosine-Salvage|PWY-6619',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Adenine and Adenosine Salvage|adenine and adenosine salvage VI',
    },
    {
        BioCyc_ID: 'PWY-5679',
        Common_Name: 'clavulanate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5679',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|clavulanate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5529',
        Common_Name: 'superpathway of bacteriochlorophyll <i>a</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-5529',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|superpathway of bacteriochlorophyll <i>a</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5529',
        Common_Name: 'superpathway of bacteriochlorophyll <i>a</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-5529',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|superpathway of bacteriochlorophyll <i>a</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5529',
        Common_Name: 'superpathway of bacteriochlorophyll <i>a</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5529',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of bacteriochlorophyll <i>a</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6640',
        Common_Name: 'salicylate degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Salicylate-Degradation|PWY-6640',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Salicylate Degradation|salicylate degradation IV',
    },
    {
        BioCyc_ID: 'P561-PWY',
        Common_Name: 'proline betaine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Proline-Betaine-Degradation|P561-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|L-Proline Betaine Degradation|proline betaine degradation I',
    },
    {
        BioCyc_ID: 'PWY-8353',
        Common_Name: '3-thia-L-glutamate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-8353',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|3-thia-L-glutamate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6876',
        Common_Name: 'isopropanol biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-6876',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|isopropanol biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-8078',
        Common_Name: 'quebrachitol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-8078',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|quebrachitol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7385',
        Common_Name: '1,3-propanediol biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-7385',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|1,3-propanediol biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-5165',
        Common_Name: '4-toluenesulfonate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Sulfoaromatics-Degradation|4-Toluenesulfonate-Degradation|PWY-5165',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Sulfoaromatic Compound Degradation|4-Toluenesulfonate Degradation|4-toluenesulfonate degradation II',
    },
    {
        BioCyc_ID: 'PWY0-1568',
        Common_Name: 'NADH to cytochrome <i>bd</i> oxidase electron transfer II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1568',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|NADH to cytochrome <i>bd</i> oxidase electron transfer II',
    },
    {
        BioCyc_ID: 'PWY0-1568',
        Common_Name: 'NADH to cytochrome <i>bd</i> oxidase electron transfer II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1568',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADH to cytochrome <i>bd</i> oxidase electron transfer II',
    },
    {
        BioCyc_ID: 'PWY-5110',
        Common_Name: 'trigonelline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PYRROLIDINE-ALKALOIDS|PWY-5110',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Pyrrolidine, Piperidine and Pyridine Alkaloid Biosynthesis|trigonelline biosynthesis',
    },
    {
        BioCyc_ID: 'SULFATE-CYS-PWY',
        Common_Name: 'superpathway of sulfate assimilation and cysteine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|SULFATE-CYS-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|superpathway of sulfate assimilation and cysteine biosynthesis',
    },
    {
        BioCyc_ID: 'SULFATE-CYS-PWY',
        Common_Name: 'superpathway of sulfate assimilation and cysteine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|SULFATE-CYS-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sulfate assimilation and cysteine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7119',
        Common_Name: 'ceramide and sphingolipid recycling and degradation (yeast)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Sphingolipid-Recyc-Deg|PWY-7119',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Ceramide and Sphingolipid Recycling and Degradation|ceramide and sphingolipid recycling and degradation (yeast)',
    },
    {
        BioCyc_ID: 'PWY-8075',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. putida</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8075',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. putida</i>)',
    },
    {
        BioCyc_ID: 'PWY-8075',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. putida</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8075',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. putida</i>)',
    },
    {
        BioCyc_ID: 'PWY-8075',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. putida</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Kdo-lipid-A-Biosynthesis|PWY-8075',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo-lipid A biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis (<i>P. putida</i>)',
    },
    {
        BioCyc_ID: 'PWY-6605',
        Common_Name: 'adenine and adenosine salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Adenine-Adenosine-Salvage|PWY-6605',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Adenine and Adenosine Salvage|adenine and adenosine salvage II',
    },
    {
        BioCyc_ID: 'PWY-6345',
        Common_Name: 'K-252 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6345',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|K-252 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5942',
        Common_Name: '<i>trans</i>-lycopene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|Lycopene-Biosynthesis|PWY-5942',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Lycopene Biosynthesis|<i>trans</i>-lycopene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5942',
        Common_Name: '<i>trans</i>-lycopene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|Lycopene-Biosynthesis|PWY-5942',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Lycopene Biosynthesis|<i>trans</i>-lycopene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5942',
        Common_Name: '<i>trans</i>-lycopene biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|Lycopene-Biosynthesis|PWY-5942',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Lycopene Biosynthesis|<i>trans</i>-lycopene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5942',
        Common_Name: '<i>trans</i>-lycopene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5942',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|<i>trans</i>-lycopene biosynthesis I',
    },
    {
        BioCyc_ID: 'P183-PWY',
        Common_Name: 'catechol degradation to 2-hydroxypentadienoate I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Catechol-Degradation|P183-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Catechol Degradation|catechol degradation to 2-hydroxypentadienoate I',
    },
    {
        BioCyc_ID: 'PWY-6483',
        Common_Name: 'ceramide degradation (generic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Sphingolipid-Recyc-Deg|PWY-6483',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Ceramide and Sphingolipid Recycling and Degradation|ceramide degradation (generic)',
    },
    {
        BioCyc_ID: 'PWY66-388',
        Common_Name: 'ceramide degradation by &alpha;-oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Sphingolipid-Recyc-Deg|PWY66-388',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Ceramide and Sphingolipid Recycling and Degradation|ceramide degradation by &alpha;-oxidation',
    },
    {
        BioCyc_ID: 'PWY-3041',
        Common_Name: 'monoterpene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-3041',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|monoterpene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3041',
        Common_Name: 'monoterpene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-3041',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|monoterpene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2301',
        Common_Name: '<i>myo</i>-inositol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-2301',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|<i>myo</i>-inositol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6883',
        Common_Name: 'pyruvate fermentation to butanol II (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-6883',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|pyruvate fermentation to butanol II (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6883',
        Common_Name: 'pyruvate fermentation to butanol II (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Butanol-Fermentation|PWY-6883',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Butanol|pyruvate fermentation to butanol II (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6883',
        Common_Name: 'pyruvate fermentation to butanol II (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Butanol-Fermentation|PWY-6883',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Butanol|pyruvate fermentation to butanol II (engineered)',
    },
    {
        BioCyc_ID: 'TOLSULFDEG-PWY',
        Common_Name: '4-toluenesulfonate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Sulfoaromatics-Degradation|4-Toluenesulfonate-Degradation|TOLSULFDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Sulfoaromatic Compound Degradation|4-Toluenesulfonate Degradation|4-toluenesulfonate degradation I',
    },
    {
        BioCyc_ID: 'PWY0-1334',
        Common_Name: 'NADH to cytochrome <i>bd</i> oxidase electron transfer I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY0-1334',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|NADH to cytochrome <i>bd</i> oxidase electron transfer I',
    },
    {
        BioCyc_ID: 'PWY0-1334',
        Common_Name: 'NADH to cytochrome <i>bd</i> oxidase electron transfer I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1334',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|NADH to cytochrome <i>bd</i> oxidase electron transfer I',
    },
    {
        BioCyc_ID: 'PWY-5468',
        Common_Name: 'lupanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|QUINOLIZIDINE-ALKALOIDS|PWY-5468',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Quinolizidine Alkaloid Biosynthesis|lupanine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY3DJ-11470',
        Common_Name: 'sphingosine and sphingosine-1-phosphate metabolism',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Sphingolipid-Recyc-Deg|PWY3DJ-11470',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Ceramide and Sphingolipid Recycling and Degradation|sphingosine and sphingosine-1-phosphate metabolism',
    },
    {
        BioCyc_ID: 'PWY-6058',
        Common_Name: 'dimethyl sulfone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6058',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|dimethyl sulfone degradation',
    },
    {
        BioCyc_ID: 'PWY-6611',
        Common_Name: 'adenine and adenosine salvage V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Adenine-Adenosine-Salvage|PWY-6611',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Adenine and Adenosine Salvage|adenine and adenosine salvage V',
    },
    {
        BioCyc_ID: 'PWY-7019',
        Common_Name: 'butirosin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7019',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|butirosin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6350',
        Common_Name: 'archaetidylinositol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Archaeal-Lipid-Biosynthesis|PWY-6350',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Archaeal Lipid Biosynthesis|archaetidylinositol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6350',
        Common_Name: 'archaetidylinositol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-6350',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|archaetidylinositol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6350',
        Common_Name: 'archaetidylinositol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6350',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|archaetidylinositol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5419',
        Common_Name: 'catechol degradation to 2-hydroxypentadienoate II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Catechol-Degradation|PWY-5419',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Catechol Degradation|catechol degradation to 2-hydroxypentadienoate II',
    },
    {
        BioCyc_ID: 'PWY-7007',
        Common_Name: 'methyl ketone biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-7007',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|methyl ketone biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6358',
        Common_Name: 'superpathway of D-<i>myo</i>-inositol (1,4,5)-trisphosphate metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-6358',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|superpathway of D-<i>myo</i>-inositol (1,4,5)-trisphosphate metabolism',
    },
    {
        BioCyc_ID: 'PWY-6358',
        Common_Name: 'superpathway of D-<i>myo</i>-inositol (1,4,5)-trisphosphate metabolism',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6358',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of D-<i>myo</i>-inositol (1,4,5)-trisphosphate metabolism',
    },
    {
        BioCyc_ID: 'PWY-7111',
        Common_Name: 'pyruvate fermentation to isobutanol (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-7111',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|pyruvate fermentation to isobutanol (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7111',
        Common_Name: 'pyruvate fermentation to isobutanol (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|PWY-7111',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|pyruvate fermentation to isobutanol (engineered)',
    },
    {
        BioCyc_ID: 'PWY-5319',
        Common_Name: 'coumarin metabolism (to melilotic acid)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-5319',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|coumarin metabolism (to melilotic acid)',
    },
    {
        BioCyc_ID: 'PWY-5319',
        Common_Name: 'coumarin metabolism (to melilotic acid)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-5319',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|coumarin metabolism (to melilotic acid)',
    },
    {
        BioCyc_ID: 'PWY0-1577',
        Common_Name: 'hydrogen to dimethyl sulfoxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY0-1577',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|hydrogen to dimethyl sulfoxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY0-1577',
        Common_Name: 'hydrogen to dimethyl sulfoxide electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY0-1577',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|hydrogen to dimethyl sulfoxide electron transfer',
    },
    {
        BioCyc_ID: 'PWY-6994',
        Common_Name: 'L-pyrrolysine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|Pyrrolysine-Biosynthesis|PWY-6994',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-pyrrolysine Biosynthesis|L-pyrrolysine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6593',
        Common_Name: 'sulfoacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-6593',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|sulfoacetate degradation',
    },
    {
        BioCyc_ID: 'P121-PWY',
        Common_Name: 'adenine and adenosine salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Adenine-Adenosine-Salvage|P121-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Adenine and Adenosine Salvage|adenine and adenosine salvage I',
    },
    {
        BioCyc_ID: 'PWY-7564',
        Common_Name: 'bacimethrin and bacimethrin pyrophosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7564',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|bacimethrin and bacimethrin pyrophosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6470',
        Common_Name: 'peptidoglycan biosynthesis V (&beta;-lactam resistance)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Antibiotic-Resistance|PWY-6470',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Antibiotic Resistance|peptidoglycan biosynthesis V (&beta;-lactam resistance)',
    },
    {
        BioCyc_ID: 'PWY-6470',
        Common_Name: 'peptidoglycan biosynthesis V (&beta;-lactam resistance)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Biosynthesis|PWY-6470',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Biosynthesis|peptidoglycan biosynthesis V (&beta;-lactam resistance)',
    },
    {
        BioCyc_ID: 'PWY-6470',
        Common_Name: 'peptidoglycan biosynthesis V (&beta;-lactam resistance)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6470',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|peptidoglycan biosynthesis V (&beta;-lactam resistance)',
    },
    {
        BioCyc_ID: 'CATECHOL-ORTHO-CLEAVAGE-PWY',
        Common_Name: 'catechol degradation to &beta;-ketoadipate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Catechol-Degradation|CATECHOL-ORTHO-CLEAVAGE-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Catechol Degradation|catechol degradation to &beta;-ketoadipate',
    },
    {
        BioCyc_ID: 'PWY-5302',
        Common_Name: 'sulfur disproportionation II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-5302',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|sulfur disproportionation II (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-5302',
        Common_Name: 'sulfur disproportionation II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-Disproportionation|PWY-5302',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Disproportionation|sulfur disproportionation II (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-6366',
        Common_Name: 'D-<i>myo</i>-inositol (1,4,5,6)-tetrakisphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-6366',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|D-<i>myo</i>-inositol (1,4,5,6)-tetrakisphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6863',
        Common_Name: 'pyruvate fermentation to hexanol (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-6863',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|pyruvate fermentation to hexanol (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6863',
        Common_Name: 'pyruvate fermentation to hexanol (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|PWY-6863',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|pyruvate fermentation to hexanol (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7046',
        Common_Name: '4-coumarate degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-7046',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|4-coumarate degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-3901',
        Common_Name: 'berberine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-3901',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|berberine biosynthesis',
    },
    {
        BioCyc_ID: 'ASPARAGINESYN-PWY',
        Common_Name: 'L-asparagine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ASPARAGINE-SYN|ASPARAGINESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-asparagine Biosynthesis|L-asparagine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-2601',
        Common_Name: 'isethionate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-2601',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|isethionate degradation',
    },
    {
        BioCyc_ID: 'PWY-6610',
        Common_Name: 'adenine salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|Adenine-Adenosine-Salvage|PWY-6610',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|Adenine and Adenosine Salvage|adenine salvage',
    },
    {
        BioCyc_ID: 'PWY-7718',
        Common_Name: 'actinomycin D biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7718',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|actinomycin D biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6595',
        Common_Name: 'superpathway of guanosine nucleotides degradation (plants)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|Guanosine-Nucleotides-Degradation|PWY-6595',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|Guanosine Nucleotide Degradation|superpathway of guanosine nucleotides degradation (plants)',
    },
    {
        BioCyc_ID: 'PWY-6595',
        Common_Name: 'superpathway of guanosine nucleotides degradation (plants)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6595',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of guanosine nucleotides degradation (plants)',
    },
    {
        BioCyc_ID: 'PWY-5417',
        Common_Name: 'catechol degradation III (ortho-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Catechol-Degradation|PWY-5417',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Catechol Degradation|catechol degradation III (ortho-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-5417',
        Common_Name: 'catechol degradation III (ortho-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5417',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|catechol degradation III (ortho-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-8136',
        Common_Name: 'NADPH repair (prokaryotes)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-8136',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|NADPH repair (prokaryotes)',
    },
    {
        BioCyc_ID: 'PWY-6371',
        Common_Name: 'superpathway of inositol phosphate compounds',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-6371',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|superpathway of inositol phosphate compounds',
    },
    {
        BioCyc_ID: 'PWY-6371',
        Common_Name: 'superpathway of inositol phosphate compounds',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-6371',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|superpathway of inositol phosphate compounds',
    },
    {
        BioCyc_ID: 'PWY-6371',
        Common_Name: 'superpathway of inositol phosphate compounds',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6371',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of inositol phosphate compounds',
    },
    {
        BioCyc_ID: 'PWY-7396',
        Common_Name: 'butanol and isobutanol biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-7396',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|butanol and isobutanol biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-5636',
        Common_Name: '2-nitrophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrophenol-Degradation|o-Nitrophenol-Degradation|PWY-5636',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrophenol Degradation|2-Nitrophenol Degradation|2-nitrophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-5636',
        Common_Name: '2-nitrophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Nitrophenol-Degradation|o-Nitrophenol-Degradation|PWY-5636',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Nitrophenol Degradation|2-Nitrophenol Degradation|2-nitrophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-8002',
        Common_Name: '4-coumarate degradation (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-8002',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|4-coumarate degradation (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-5470',
        Common_Name: 'palmatine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-5470',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|palmatine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8307',
        Common_Name: 'L-carnitine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|CARN-DEG|PWY-8307',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|L-Carnitine Degradation|L-carnitine degradation IV',
    },
    {
        BioCyc_ID: 'ASPARAGINE-BIOSYNTHESIS',
        Common_Name: 'L-asparagine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ASPARAGINE-SYN|ASPARAGINE-BIOSYNTHESIS',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-asparagine Biosynthesis|L-asparagine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6901',
        Common_Name: 'superpathway of glucose and xylose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-6901',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|superpathway of glucose and xylose degradation',
    },
    {
        BioCyc_ID: 'PWY-6901',
        Common_Name: 'superpathway of glucose and xylose degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6901',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glucose and xylose degradation',
    },
    {
        BioCyc_ID: 'PWY-7793',
        Common_Name: 'dimethyl sulfide biosynthesis from methionine',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-7793',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|dimethyl sulfide biosynthesis from methionine',
    },
    {
        BioCyc_ID: 'PWY-3641',
        Common_Name: 'L-carnitine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|CARN-DEG|PWY-3641',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|L-Carnitine Degradation|L-carnitine degradation II',
    },
    {
        BioCyc_ID: 'PWY-8012',
        Common_Name: 'mupirocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8012',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|mupirocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6897',
        Common_Name: 'thiamine diphosphate salvage II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6897',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|thiamine diphosphate salvage II',
    },
    {
        BioCyc_ID: 'PWY-6897',
        Common_Name: 'thiamine diphosphate salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6897',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate salvage II',
    },
    {
        BioCyc_ID: 'PWY-6897',
        Common_Name: 'thiamine diphosphate salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6897',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate salvage II',
    },
    {
        BioCyc_ID: 'PWY-5431',
        Common_Name: 'aromatic compounds degradation via &beta;-ketoadipate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Catechol-Degradation|PWY-5431',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Catechol Degradation|aromatic compounds degradation via &beta;-ketoadipate',
    },
    {
        BioCyc_ID: 'PWY-5431',
        Common_Name: 'aromatic compounds degradation via &beta;-ketoadipate',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5431',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|aromatic compounds degradation via &beta;-ketoadipate',
    },
    {
        BioCyc_ID: 'PWY-3602',
        Common_Name: 'L-carnitine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|CARN-DEG|PWY-3602',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|L-Carnitine Degradation|L-carnitine degradation III',
    },
    {
        BioCyc_ID: 'PWY-8426',
        Common_Name: '(2<i>R</i>)-methoxymalonyl-[acp] biosynthesis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-8426',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|(2<i>R</i>)-methoxymalonyl-[acp] biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6351',
        Common_Name: 'D-<i>myo</i>-inositol (1,4,5)-trisphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-6351',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|D-<i>myo</i>-inositol (1,4,5)-trisphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5487',
        Common_Name: '4-nitrophenol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrophenol-Degradation|p-Nitrophenol-Degradation|PWY-5487',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrophenol Degradation|4-Nitrophenol Degradation|4-nitrophenol degradation I',
    },
    {
        BioCyc_ID: 'PWY-5487',
        Common_Name: '4-nitrophenol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Nitrophenol-Degradation|p-Nitrophenol-Degradation|PWY-5487',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Nitrophenol Degradation|4-Nitrophenol Degradation|4-nitrophenol degradation I',
    },
    {
        BioCyc_ID: 'PWY-116',
        Common_Name: 'coniferin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNIN-SYN|PWY-116',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignin Biosynthesis|coniferin metabolism',
    },
    {
        BioCyc_ID: 'PWY-116',
        Common_Name: 'coniferin metabolism',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-116',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|coniferin metabolism',
    },
    {
        BioCyc_ID: 'PWY0-1338',
        Common_Name: 'polymyxin resistance',
        MetaCyc_hierarchy_IDs: 'Detoxification|Antibiotic-Resistance|PWY0-1338',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Antibiotic Resistance|polymyxin resistance',
    },
    {
        BioCyc_ID: 'PWY0-1338',
        Common_Name: 'polymyxin resistance',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|PWY0-1338',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|polymyxin resistance',
    },
    {
        BioCyc_ID: 'PWY0-1338',
        Common_Name: 'polymyxin resistance',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|PWY0-1338',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|polymyxin resistance',
    },
    {
        BioCyc_ID: 'PWY-6337',
        Common_Name: 'dehydroscoulerine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-6337',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|dehydroscoulerine biosynthesis',
    },
    {
        BioCyc_ID: 'HOMOSER-THRESYN-PWY',
        Common_Name: 'L-threonine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|THREONINE-BIOSYNTHESIS|HOMOSER-THRESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-threonine Biosynthesis|L-threonine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7459',
        Common_Name: 'kojibiose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-7459',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|kojibiose degradation',
    },
    {
        BioCyc_ID: 'PWY-5340',
        Common_Name: 'sulfate activation for sulfonation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|PWY-5340',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|sulfate activation for sulfonation',
    },
    {
        BioCyc_ID: 'PWY-5340',
        Common_Name: 'sulfate activation for sulfonation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY-5340',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|sulfate activation for sulfonation',
    },
    {
        BioCyc_ID: 'PWY-7221',
        Common_Name: 'guanosine ribonucleotides <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Ribonuc-De-Novo-Biosynthesis|PWY-7221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Riboucleotide <i>De Novo</i> Biosynthesis|guanosine ribonucleotides <i>de novo</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5770',
        Common_Name: 'phenazine-1-carboxylate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5770',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|phenazine-1-carboxylate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7021',
        Common_Name: 'superpathway of neomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7021',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|superpathway of neomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7021',
        Common_Name: 'superpathway of neomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7021',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of neomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5415',
        Common_Name: 'catechol degradation I (<I>meta</I>-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Catechol-Degradation|PWY-5415',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Catechol Degradation|catechol degradation I (<I>meta</I>-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-5415',
        Common_Name: 'catechol degradation I (<I>meta</I>-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5415',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|catechol degradation I (<I>meta</I>-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-5741',
        Common_Name: 'ethylmalonyl-CoA pathway',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-5741',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|ethylmalonyl-CoA pathway',
    },
    {
        BioCyc_ID: 'PWY-6365',
        Common_Name: 'D-<i>myo</i>-inositol (3,4,5,6)-tetrakisphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-6365',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|D-<i>myo</i>-inositol (3,4,5,6)-tetrakisphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5488',
        Common_Name: '4-nitrophenol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrophenol-Degradation|p-Nitrophenol-Degradation|PWY-5488',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrophenol Degradation|4-Nitrophenol Degradation|4-nitrophenol degradation II',
    },
    {
        BioCyc_ID: 'PWY-5488',
        Common_Name: '4-nitrophenol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Nitrophenol-Degradation|p-Nitrophenol-Degradation|PWY-5488',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Nitrophenol Degradation|4-Nitrophenol Degradation|4-nitrophenol degradation II',
    },
    {
        BioCyc_ID: 'PWY-6667',
        Common_Name: 'resveratrol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-6667',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|resveratrol degradation',
    },
    {
        BioCyc_ID: 'PWY2B4Q-8',
        Common_Name: 'lipid A-core biosynthesis (<i>Brucella</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Lipid-A-core-Biosynthesis|PWY2B4Q-8',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Brucella</i>)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-8',
        Common_Name: 'lipid A-core biosynthesis (<i>Brucella</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipid-A-core-Biosynthesis|PWY2B4Q-8',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Brucella</i>)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-8',
        Common_Name: 'lipid A-core biosynthesis (<i>Brucella</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-A-core-Biosynthesis|PWY2B4Q-8',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Brucella</i>)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-8',
        Common_Name: 'lipid A-core biosynthesis (<i>Brucella</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|PWY2B4Q-8',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Brucella</i>)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-8',
        Common_Name: 'lipid A-core biosynthesis (<i>Brucella</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|PWY2B4Q-8',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Brucella</i>)',
    },
    {
        BioCyc_ID: 'PWY-7522',
        Common_Name: '(<i>R</i>)-canadine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-7522',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|(<i>R</i>)-canadine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6499',
        Common_Name: 'D-glucarate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldaric-Acid-Degradation|D-Glucarate-Degradation|PWY-6499',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldaric Acid Degradation|D-Glucarate Degradation|D-glucarate degradation II',
    },
    {
        BioCyc_ID: 'GLUTAMATE-SYN2-PWY',
        Common_Name: 'L-glutamate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMATE-SYN|GLUTAMATE-SYN2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamate Biosynthesis|L-glutamate biosynthesis II',
    },
    {
        BioCyc_ID: 'DHGLUCONATE-PYR-CAT-PWY',
        Common_Name: 'glucose degradation (oxidative)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|DHGLUCONATE-PYR-CAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|glucose degradation (oxidative)',
    },
    {
        BioCyc_ID: 'PWY8J2-25',
        Common_Name: '<i>S</i>-(2-succinyl)-L-cysteine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|PWY8J2-25',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|<i>S</i>-(2-succinyl)-L-cysteine degradation',
    },
    {
        BioCyc_ID: 'GLUCARDEG-PWY',
        Common_Name: '<i>D</i>-glucarate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldaric-Acid-Degradation|D-Glucarate-Degradation|GLUCARDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldaric Acid Degradation|D-Glucarate Degradation|<i>D</i>-glucarate degradation I',
    },
    {
        BioCyc_ID: 'PWY-7219',
        Common_Name: 'adenosine ribonucleotides <i>de novo</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Ribonuc-De-Novo-Biosynthesis|PWY-7219',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Riboucleotide <i>De Novo</i> Biosynthesis|adenosine ribonucleotides <i>de novo</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6666',
        Common_Name: 'pyocyanin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6666',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|pyocyanin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7228',
        Common_Name: 'superpathway of guanosine nucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|PWY-7228',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|superpathway of guanosine nucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7228',
        Common_Name: 'superpathway of guanosine nucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7228',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of guanosine nucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5420',
        Common_Name: 'catechol degradation II (<I>meta</I>-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Catechol-Degradation|PWY-5420',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Catechol Degradation|catechol degradation II (<I>meta</I>-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-5420',
        Common_Name: 'catechol degradation II (<I>meta</I>-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5420',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|catechol degradation II (<I>meta</I>-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-7471',
        Common_Name: 'D-carnitine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|D-Carnitine-Degradation|PWY-7471',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|D-Carnitine Degradation|D-carnitine degradation I',
    },
    {
        BioCyc_ID: 'PWY-7472',
        Common_Name: 'D-carnitine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|D-Carnitine-Degradation|PWY-7472',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|D-Carnitine Degradation|D-carnitine degradation II',
    },
    {
        BioCyc_ID: 'PWY66-367',
        Common_Name: 'ketogenesis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY66-367',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|ketogenesis',
    },
    {
        BioCyc_ID: 'PWY-6369',
        Common_Name: 'inositol diphosphates biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-6369',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|inositol diphosphates biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5644',
        Common_Name: '4-nitrotoluene degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitro-Toluene-Degradation|4-Nitrotoluene-Degradation|PWY-5644',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrotoluene Degradation|4-Nitrotoluene Degradation|4-nitrotoluene degradation II',
    },
    {
        BioCyc_ID: 'PWY-6343',
        Common_Name: 'ferulate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-6343',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|ferulate degradation',
    },
    {
        BioCyc_ID: 'PWY1R65-1',
        Common_Name: 'lipid A-core biosynthesis (<i>Salmonella</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Lipid-A-core-Biosynthesis|PWY1R65-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Salmonella</i>)',
    },
    {
        BioCyc_ID: 'PWY1R65-1',
        Common_Name: 'lipid A-core biosynthesis (<i>Salmonella</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipid-A-core-Biosynthesis|PWY1R65-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Salmonella</i>)',
    },
    {
        BioCyc_ID: 'PWY1R65-1',
        Common_Name: 'lipid A-core biosynthesis (<i>Salmonella</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-A-core-Biosynthesis|PWY1R65-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Salmonella</i>)',
    },
    {
        BioCyc_ID: 'PWY1R65-1',
        Common_Name: 'lipid A-core biosynthesis (<i>Salmonella</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|PWY1R65-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Salmonella</i>)',
    },
    {
        BioCyc_ID: 'PWY1R65-1',
        Common_Name: 'lipid A-core biosynthesis (<i>Salmonella</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|PWY1R65-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>Salmonella</i>)',
    },
    {
        BioCyc_ID: 'PWY-5287',
        Common_Name: 'sanguinarine and macarpine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-5287',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|sanguinarine and macarpine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4341',
        Common_Name: 'L-glutamate biosynthesis V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMATE-SYN|PWY-4341',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamate Biosynthesis|L-glutamate biosynthesis V',
    },
    {
        BioCyc_ID: 'PWY0-1301',
        Common_Name: 'melibiose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY0-1301',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|melibiose degradation',
    },
    {
        BioCyc_ID: 'PWY-7807',
        Common_Name: 'glyphosate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Glyphosate-Degradation|PWY-7807',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Glyphosate Degradation|glyphosate degradation III',
    },
    {
        BioCyc_ID: 'PWY-7025',
        Common_Name: 'gentamicin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7025',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|gentamicin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7528',
        Common_Name: 'L-methionine salvage cycle I (bacteria and plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-Salvage|PWY-7528',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine Salvage|L-methionine salvage cycle I (bacteria and plants)',
    },
    {
        BioCyc_ID: 'PWY-7528',
        Common_Name: 'L-methionine salvage cycle I (bacteria and plants)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7528',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-methionine salvage cycle I (bacteria and plants)',
    },
    {
        BioCyc_ID: 'PWY-7394',
        Common_Name: 'urate conversion to allantoin II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Urate-Degradation|PWY-7394',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Urate Degradation|urate conversion to allantoin II',
    },
    {
        BioCyc_ID: 'PWY-6873',
        Common_Name: 'long chain fatty acid ester synthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-6873',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|long chain fatty acid ester synthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-5338',
        Common_Name: 'galactosylcyclitol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-5338',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|galactosylcyclitol biosynthesis',
    },
    {
        BioCyc_ID: 'P421-PWY',
        Common_Name: '4-nitrotoluene degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitro-Toluene-Degradation|4-Nitrotoluene-Degradation|P421-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrotoluene Degradation|4-Nitrotoluene Degradation|4-nitrotoluene degradation I',
    },
    {
        BioCyc_ID: 'PWY-7113',
        Common_Name: 'furcatin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-7113',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|furcatin degradation',
    },
    {
        BioCyc_ID: 'PWY-8248',
        Common_Name: 'lipid A-core biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Lipid-A-core-Biosynthesis|PWY-8248',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8248',
        Common_Name: 'lipid A-core biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipid-A-core-Biosynthesis|PWY-8248',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8248',
        Common_Name: 'lipid A-core biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-A-core-Biosynthesis|PWY-8248',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8248',
        Common_Name: 'lipid A-core biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|PWY-8248',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-8248',
        Common_Name: 'lipid A-core biosynthesis (<i>P. gingivalis</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|PWY-8248',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>P. gingivalis</i>)',
    },
    {
        BioCyc_ID: 'PWY-5876',
        Common_Name: 'magnoflorine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-5876',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|magnoflorine biosynthesis',
    },
    {
        BioCyc_ID: 'GLUGLNSYN-PWY',
        Common_Name: 'L-glutamate biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMATE-SYN|GLUGLNSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamate Biosynthesis|L-glutamate biosynthesis IV',
    },
    {
        BioCyc_ID: 'MALTOSECAT-PWY',
        Common_Name: 'maltose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|MALTOSECAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|maltose degradation',
    },
    {
        BioCyc_ID: 'PWY-7806',
        Common_Name: 'glyphosate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Glyphosate-Degradation|PWY-7806',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Glyphosate Degradation|glyphosate degradation II',
    },
    {
        BioCyc_ID: 'PWY-7735',
        Common_Name: 'echinomycin and triostin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7735',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|echinomycin and triostin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8094',
        Common_Name: 'superpathway of kavalactone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-8094',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|superpathway of kavalactone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8094',
        Common_Name: 'superpathway of kavalactone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8094',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of kavalactone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5691',
        Common_Name: 'urate conversion to allantoin I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Urate-Degradation|PWY-5691',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Urate Degradation|urate conversion to allantoin I',
    },
    {
        BioCyc_ID: 'PWY-7514',
        Common_Name: 'L-tyrosine degradation IV (to 4-methylphenol)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TYROSINE-DEG|PWY-7514',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tyrosine Degradation|L-tyrosine degradation IV (to 4-methylphenol)',
    },
    {
        BioCyc_ID: 'P203-PWY',
        Common_Name: 'sulfur disproportionation I (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|P203-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|sulfur disproportionation I (anaerobic)',
    },
    {
        BioCyc_ID: 'P203-PWY',
        Common_Name: 'sulfur disproportionation I (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-Disproportionation|P203-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Disproportionation|sulfur disproportionation I (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-6364',
        Common_Name: 'D-<i>myo</i>-inositol (1,3,4)-trisphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-6364',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|D-<i>myo</i>-inositol (1,3,4)-trisphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'CENTFERM-PWY',
        Common_Name: 'pyruvate fermentation to butanoate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetyl-CoA-Butyrate|CENTFERM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Butanoate|pyruvate fermentation to butanoate',
    },
    {
        BioCyc_ID: 'CENTFERM-PWY',
        Common_Name: 'pyruvate fermentation to butanoate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|CENTFERM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|pyruvate fermentation to butanoate',
    },
    {
        BioCyc_ID: 'PWY-8003',
        Common_Name: '<i>trans</i>-caffeate degradation (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-8003',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|<i>trans</i>-caffeate degradation (aerobic)',
    },
    {
        BioCyc_ID: 'PWY2DNV-4',
        Common_Name: 'lipid A-core biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Lipid-A-core-Biosynthesis|PWY2DNV-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-4',
        Common_Name: 'lipid A-core biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipid-A-core-Biosynthesis|PWY2DNV-4',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-4',
        Common_Name: 'lipid A-core biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-A-core-Biosynthesis|PWY2DNV-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-4',
        Common_Name: 'lipid A-core biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|PWY2DNV-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY2DNV-4',
        Common_Name: 'lipid A-core biosynthesis (<i>H. pylori</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|PWY2DNV-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>H. pylori</i>)',
    },
    {
        BioCyc_ID: 'PWY-7507',
        Common_Name: 'chelerythrine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-7507',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|chelerythrine biosynthesis',
    },
    {
        BioCyc_ID: 'GLUTSYNIII-PWY',
        Common_Name: 'L-glutamate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMATE-SYN|GLUTSYNIII-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamate Biosynthesis|L-glutamate biosynthesis III',
    },
    {
        BioCyc_ID: 'RIBOKIN-PWY',
        Common_Name: 'ribose phosphorylation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|RIBOKIN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|ribose phosphorylation',
    },
    {
        BioCyc_ID: 'PWY-7804',
        Common_Name: 'glyphosate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Glyphosate-Degradation|PWY-7804',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Glyphosate Degradation|glyphosate degradation I',
    },
    {
        BioCyc_ID: 'PWY-7417',
        Common_Name: 'phospholipid remodeling (phosphatidate, yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-7417',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|phospholipid remodeling (phosphatidate, yeast)',
    },
    {
        BioCyc_ID: 'PWY-8034',
        Common_Name: 'spectinabilin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8034',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|spectinabilin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1415',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from uroporphyrinogen-III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY0-1415',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|superpathway of heme <i>b</i> biosynthesis from uroporphyrinogen-III',
    },
    {
        BioCyc_ID: 'PWY0-1415',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from uroporphyrinogen-III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY0-1415',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|superpathway of heme <i>b</i> biosynthesis from uroporphyrinogen-III',
    },
    {
        BioCyc_ID: 'PWY0-1415',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from uroporphyrinogen-III',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-1415',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of heme <i>b</i> biosynthesis from uroporphyrinogen-III',
    },
    {
        BioCyc_ID: 'P165-PWY',
        Common_Name: 'superpathway of purines degradation in plants',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P165-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of purines degradation in plants',
    },
    {
        BioCyc_ID: 'P165-PWY',
        Common_Name: 'superpathway of purines degradation in plants',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Urate-Degradation|P165-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Urate Degradation|superpathway of purines degradation in plants',
    },
    {
        BioCyc_ID: 'PWY-3661',
        Common_Name: 'glycine betaine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Glycine-Betaine-Degradation|PWY-3661',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Glycine Betaine Degradation|glycine betaine degradation I',
    },
    {
        BioCyc_ID: 'TYRFUMCAT-PWY',
        Common_Name: 'L-tyrosine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TYROSINE-DEG|TYRFUMCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tyrosine Degradation|L-tyrosine degradation I',
    },
    {
        BioCyc_ID: 'PWY-8325',
        Common_Name: 'glycine betaine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Glycine-Betaine-Degradation|PWY-8325',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Glycine Betaine Degradation|glycine betaine degradation III',
    },
    {
        BioCyc_ID: 'PWY-6938-1',
        Common_Name: 'NADH repair (prokaryotes)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-6938-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|NADH repair (prokaryotes)',
    },
    {
        BioCyc_ID: 'PWY-6588',
        Common_Name: 'pyruvate fermentation to acetone',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|PWY-6588',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|pyruvate fermentation to acetone',
    },
    {
        BioCyc_ID: 'PWY-6367',
        Common_Name: 'D-<i>myo</i>-inositol-5-phosphate metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|PWY-6367',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|D-<i>myo</i>-inositol-5-phosphate metabolism',
    },
    {
        BioCyc_ID: 'PWY-6367',
        Common_Name: 'D-<i>myo</i>-inositol-5-phosphate metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-6367',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|D-<i>myo</i>-inositol-5-phosphate metabolism',
    },
    {
        BioCyc_ID: 'PWY-6122',
        Common_Name: '5-aminoimidazole ribonucleotide biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|AIR-Biosynthesis|PWY-6122',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|5-Aminoimidazole Ribonucleotide Biosynthesis|5-aminoimidazole ribonucleotide biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6534',
        Common_Name: 'phenylethylamine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Phenylethylamine-Degradation|PWY-6534',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Phenylethylamine Degradation|phenylethylamine degradation II',
    },
    {
        BioCyc_ID: 'PWY-6534',
        Common_Name: 'phenylethylamine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Phenylethylamine-Degradation|PWY-6534',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylethylamine Degradation|phenylethylamine degradation II',
    },
    {
        BioCyc_ID: 'LIPA-CORESYN-PWY',
        Common_Name: 'lipid A-core biosynthesis (<i>E. coli</i> K-12)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Lipid-A-core-Biosynthesis|LIPA-CORESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>E. coli</i> K-12)',
    },
    {
        BioCyc_ID: 'LIPA-CORESYN-PWY',
        Common_Name: 'lipid A-core biosynthesis (<i>E. coli</i> K-12)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipid-A-core-Biosynthesis|LIPA-CORESYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>E. coli</i> K-12)',
    },
    {
        BioCyc_ID: 'LIPA-CORESYN-PWY',
        Common_Name: 'lipid A-core biosynthesis (<i>E. coli</i> K-12)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Lipid-A-core-Biosynthesis|LIPA-CORESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>E. coli</i> K-12)',
    },
    {
        BioCyc_ID: 'LIPA-CORESYN-PWY',
        Common_Name: 'lipid A-core biosynthesis (<i>E. coli</i> K-12)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|LIPA-CORESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>E. coli</i> K-12)',
    },
    {
        BioCyc_ID: 'LIPA-CORESYN-PWY',
        Common_Name: 'lipid A-core biosynthesis (<i>E. coli</i> K-12)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|Lipid-A-core-Biosynthesis|LIPA-CORESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipid A-core Biosynthesis|lipid A-core biosynthesis (<i>E. coli</i> K-12)',
    },
    {
        BioCyc_ID: 'PWY-8031',
        Common_Name: 'epiberberine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-8031',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|epiberberine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8108',
        Common_Name: 'glycine betaine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Glycine-Betaine-Degradation|PWY-8108',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Glycine Betaine Degradation|glycine betaine degradation IV',
    },
    {
        BioCyc_ID: 'ARGASEDEG-PWY',
        Common_Name: 'L-arginine degradation I (arginase pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ARGASEDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation I (arginase pathway)',
    },
    {
        BioCyc_ID: 'ARGASEDEG-PWY',
        Common_Name: 'L-arginine degradation I (arginase pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMATE-SYN|ARGASEDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamate Biosynthesis|L-arginine degradation I (arginase pathway)',
    },
    {
        BioCyc_ID: 'P302-PWY',
        Common_Name: 'L-sorbose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|P302-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-sorbose degradation',
    },
    {
        BioCyc_ID: 'PWY-7419',
        Common_Name: 'FR-900098 and FR-33289 antibiotics biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7419',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|FR-900098 and FR-33289 antibiotics biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7419',
        Common_Name: 'FR-900098 and FR-33289 antibiotics biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Biosynthesis|PWY-7419',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Biosynthesis|FR-900098 and FR-33289 antibiotics biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3661-1',
        Common_Name: 'glycine betaine degradation II (mammalian)',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Glycine-Betaine-Degradation|PWY-3661-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Glycine Betaine Degradation|glycine betaine degradation II (mammalian)',
    },
    {
        BioCyc_ID: 'PWY-7411',
        Common_Name: 'phosphatidate biosynthesis (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-7411',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|phosphatidate biosynthesis (yeast)',
    },
    {
        BioCyc_ID: 'PWY-8367',
        Common_Name: 'hygromycin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8367',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|hygromycin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4FS-8',
        Common_Name: 'phosphatidylglycerol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylglycerolBiosynthesis|PWY4FS-8',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylglycerol Biosynthesis|phosphatidylglycerol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY4FS-8',
        Common_Name: 'phosphatidylglycerol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY4FS-8',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|phosphatidylglycerol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7849',
        Common_Name: 'urate conversion to allantoin III',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Urate-Degradation|PWY-7849',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Urate Degradation|urate conversion to allantoin III',
    },
    {
        BioCyc_ID: 'CHOLINE-BETAINE-ANA-PWY',
        Common_Name: 'choline degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Choline-Degradation|CHOLINE-BETAINE-ANA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Choline Degradation|choline degradation I',
    },
    {
        BioCyc_ID: 'PWY-5151',
        Common_Name: 'L-tyrosine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TYROSINE-DEG|PWY-5151',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tyrosine Degradation|L-tyrosine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7167',
        Common_Name: 'choline degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Choline-Degradation|PWY-7167',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Choline Degradation|choline degradation III',
    },
    {
        BioCyc_ID: 'PWY-5277',
        Common_Name: 'thiosulfate disproportionation I (thiol-dependent)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-5277',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|thiosulfate disproportionation I (thiol-dependent)',
    },
    {
        BioCyc_ID: 'PWY-5277',
        Common_Name: 'thiosulfate disproportionation I (thiol-dependent)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Disproportionation|PWY-5277',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Disproportionation|thiosulfate disproportionation I (thiol-dependent)',
    },
    {
        BioCyc_ID: 'PWY-6121',
        Common_Name: '5-aminoimidazole ribonucleotide biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|AIR-Biosynthesis|PWY-6121',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|5-Aminoimidazole Ribonucleotide Biosynthesis|5-aminoimidazole ribonucleotide biosynthesis I',
    },
    {
        BioCyc_ID: '2PHENDEG-PWY',
        Common_Name: 'phenylethylamine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Phenylethylamine-Degradation|2PHENDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Phenylethylamine Degradation|phenylethylamine degradation I',
    },
    {
        BioCyc_ID: '2PHENDEG-PWY',
        Common_Name: 'phenylethylamine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Phenylethylamine-Degradation|2PHENDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylethylamine Degradation|phenylethylamine degradation I',
    },
    {
        BioCyc_ID: 'PWY-7942',
        Common_Name: '5-oxo-L-proline metabolism',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7942',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|5-oxo-L-proline metabolism',
    },
    {
        BioCyc_ID: 'PWY-5270',
        Common_Name: 'morphine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-5270',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|morphine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3721',
        Common_Name: 'choline degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Choline-Degradation|PWY-3721',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Choline Degradation|choline degradation II',
    },
    {
        BioCyc_ID: 'GLUTSYN-PWY',
        Common_Name: 'L-glutamate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|GLUTAMATE-SYN|GLUTSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-glutamate Biosynthesis|L-glutamate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6778',
        Common_Name: 'laminaribiose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-6778',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|laminaribiose degradation',
    },
    {
        BioCyc_ID: 'PWY-6839',
        Common_Name: '2-aminoethylphosphonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Biosynthesis|PWY-6839',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Biosynthesis|2-aminoethylphosphonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3621',
        Common_Name: '&gamma;-butyrobetaine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Gamma-butyrobetaine-Degradation|PWY-3621',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|&gamma;-butyrobetaine degradation|&gamma;-butyrobetaine degradation II',
    },
    {
        BioCyc_ID: 'PWY-6352',
        Common_Name: '3-phosphoinositide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-6352',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|3-phosphoinositide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5887',
        Common_Name: 'albaflavenone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5887',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|albaflavenone biosynthesis',
    },
    {
        BioCyc_ID: '4AMINOBUTMETAB-PWY',
        Common_Name: 'superpathway of 4-aminobutanoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|4-Aminobutyraye-Degradation|4AMINOBUTMETAB-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|4-Aminobutanoate Degradation|superpathway of 4-aminobutanoate degradation',
    },
    {
        BioCyc_ID: '4AMINOBUTMETAB-PWY',
        Common_Name: 'superpathway of 4-aminobutanoate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|4AMINOBUTMETAB-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of 4-aminobutanoate degradation',
    },
    {
        BioCyc_ID: 'PWY-1381',
        Common_Name: 'fluorene degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Fluorene-Degradation|PWY-1381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Fluorene Degradation|fluorene degradation II',
    },
    {
        BioCyc_ID: 'PWY-8339',
        Common_Name: '&gamma;-butyrobetaine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Gamma-butyrobetaine-Degradation|PWY-8339',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|&gamma;-butyrobetaine degradation|&gamma;-butyrobetaine degradation III',
    },
    {
        BioCyc_ID: 'PWY3O-4108',
        Common_Name: 'L-tyrosine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TYROSINE-DEG|PWY3O-4108',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tyrosine Degradation|L-tyrosine degradation III',
    },
    {
        BioCyc_ID: 'PWY-7980',
        Common_Name: 'ATP biosynthesis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-7980',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|ATP biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-321',
        Common_Name: 'phenylacetate degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Phenylacetate-Degradation|PWY0-321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylacetate Degradation|phenylacetate degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-6012',
        Common_Name: 'acyl carrier protein metabolism',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-6012',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|acyl carrier protein metabolism',
    },
    {
        BioCyc_ID: 'PWY-5472',
        Common_Name: 'bisbenzylisoquinoline alkaloid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-5472',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|bisbenzylisoquinoline alkaloid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1801',
        Common_Name: 'formaldehyde oxidation II (glutathione-dependent)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Oxidation|PWY-1801',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Oxidation|formaldehyde oxidation II (glutathione-dependent)',
    },
    {
        BioCyc_ID: 'VALSYN-PWY',
        Common_Name: 'L-valine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|VALINE-BIOSYNTHESIS|VALSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-valine Biosynthesis|L-valine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7130',
        Common_Name: 'L-glucose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-7130',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-glucose degradation',
    },
    {
        BioCyc_ID: 'PWY-8067',
        Common_Name: 'methylphosphonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Biosynthesis|PWY-8067',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Biosynthesis|methylphosphonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6720',
        Common_Name: 'toyocamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6720',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|toyocamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY1G-170',
        Common_Name: 'formaldehyde oxidation III (mycothiol-dependent)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Oxidation|PWY1G-170',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Oxidation|formaldehyde oxidation III (mycothiol-dependent)',
    },
    {
        BioCyc_ID: 'PWY-7039',
        Common_Name: 'phosphatidate metabolism, as a signaling molecule',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-7039',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|phosphatidate metabolism, as a signaling molecule',
    },
    {
        BioCyc_ID: 'GLYCOLYSIS-E-D',
        Common_Name: 'superpathway of glycolysis and the Entner-Doudoroff pathway',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYCOLYSIS-E-D',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|superpathway of glycolysis and the Entner-Doudoroff pathway',
    },
    {
        BioCyc_ID: 'GLYCOLYSIS-E-D',
        Common_Name: 'superpathway of glycolysis and the Entner-Doudoroff pathway',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GLYCOLYSIS-E-D',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glycolysis and the Entner-Doudoroff pathway',
    },
    {
        BioCyc_ID: 'FLUORENE-DEG-9-ONE-PWY',
        Common_Name: 'fluorene degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Fluorene-Degradation|FLUORENE-DEG-9-ONE-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Fluorene Degradation|fluorene degradation I',
    },
    {
        BioCyc_ID: 'PWY-1723',
        Common_Name: 'formaldehyde oxidation VI (H<sub>4</sub>MPT pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Oxidation|PWY-1723',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Oxidation|formaldehyde oxidation VI (H<sub>4</sub>MPT pathway)',
    },
    {
        BioCyc_ID: 'P162-PWY',
        Common_Name: 'L-glutamate degradation V (via hydroxyglutarate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|P162-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|L-glutamate degradation V (via hydroxyglutarate)',
    },
    {
        BioCyc_ID: 'P162-PWY',
        Common_Name: 'L-glutamate degradation V (via hydroxyglutarate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetyl-CoA-Butyrate|P162-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Butanoate|L-glutamate degradation V (via hydroxyglutarate)',
    },
    {
        BioCyc_ID: 'P162-PWY',
        Common_Name: 'L-glutamate degradation V (via hydroxyglutarate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|P162-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation V (via hydroxyglutarate)',
    },
    {
        BioCyc_ID: 'PWY-7909',
        Common_Name: 'formaldehyde oxidation VII (THF pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Oxidation|PWY-7909',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Oxidation|formaldehyde oxidation VII (THF pathway)',
    },
    {
        BioCyc_ID: 'PWY-5352',
        Common_Name: 'thiosulfate disproportionation II (cytochrome)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-5352',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|thiosulfate disproportionation II (cytochrome)',
    },
    {
        BioCyc_ID: 'PWY-5352',
        Common_Name: 'thiosulfate disproportionation II (cytochrome)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Disproportionation|PWY-5352',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Disproportionation|thiosulfate disproportionation II (cytochrome)',
    },
    {
        BioCyc_ID: 'PWY-1341',
        Common_Name: 'phenylacetate degradation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Phenylacetate-Degradation|PWY-1341',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylacetate Degradation|phenylacetate degradation II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-8145',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system III type)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Cytochrome-C-Biogenesis|PWY-8145',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Cytochrome <i>c</i> Biogenesis|cytochrome <i>c</i> biogenesis (system III type)',
    },
    {
        BioCyc_ID: 'PWY-8145',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system III type)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Cytochrome-C-Biogenesis|PWY-8145',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Cytochrome <i>c</i> Biogenesis|cytochrome <i>c</i> biogenesis (system III type)',
    },
    {
        BioCyc_ID: 'PWY-8145',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system III type)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-8145',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|cytochrome <i>c</i> biogenesis (system III type)',
    },
    {
        BioCyc_ID: 'PWY-7363',
        Common_Name: 'papaverine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-7363',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|papaverine biosynthesis',
    },
    {
        BioCyc_ID: 'FORMASS-PWY',
        Common_Name: 'formaldehyde oxidation IV (thiol-independent)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Oxidation|FORMASS-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Oxidation|formaldehyde oxidation IV (thiol-independent)',
    },
    {
        BioCyc_ID: 'PWY-5029',
        Common_Name: '3-(imidazol-5-yl)lactate salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|HISTIDINE-SYN|PWY-5029',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-histidine Biosynthesis|3-(imidazol-5-yl)lactate salvage',
    },
    {
        BioCyc_ID: 'PWY-7287',
        Common_Name: 'novobiocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7287',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|novobiocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8324',
        Common_Name: 'D-tagatose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-8324',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-tagatose degradation',
    },
    {
        BioCyc_ID: 'PWY0-1533',
        Common_Name: 'methylphosphonate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|Methylphosphonate-Degradation|PWY0-1533',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|Methylphosphonate Degradation|methylphosphonate degradation I',
    },
    {
        BioCyc_ID: 'PWY-7908',
        Common_Name: 'formaldehyde oxidation V (bacillithiol-dependent)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Oxidation|PWY-7908',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Oxidation|formaldehyde oxidation V (bacillithiol-dependent)',
    },
    {
        BioCyc_ID: 'PWY-762',
        Common_Name: 'phospholipid desaturation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-762',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|phospholipid desaturation',
    },
    {
        BioCyc_ID: 'P142-PWY',
        Common_Name: 'pyruvate fermentation to acetate I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|P142-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate I',
    },
    {
        BioCyc_ID: 'P142-PWY',
        Common_Name: 'pyruvate fermentation to acetate I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|P142-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate I',
    },
    {
        BioCyc_ID: 'P142-PWY',
        Common_Name: 'pyruvate fermentation to acetate I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P142-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate I',
    },
    {
        BioCyc_ID: 'PWY-5766',
        Common_Name: 'L-glutamate degradation X',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|PWY-5766',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation X',
    },
    {
        BioCyc_ID: 'PWY-7999',
        Common_Name: 'vitamin K-epoxide cycle',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|PWY-7999',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|vitamin K-epoxide cycle',
    },
    {
        BioCyc_ID: 'PWY-8425',
        Common_Name: '(2<i>S</i>)-ethylmalonyl-CoA biosynthesis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-8425',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|(2<i>S</i>)-ethylmalonyl-CoA biosynthesis',
    },
    {
        BioCyc_ID: 'SALVPURINE2-PWY',
        Common_Name: 'xanthine and xanthosine salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|SALVPURINE2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|xanthine and xanthosine salvage',
    },
    {
        BioCyc_ID: 'PWY-5418',
        Common_Name: 'phenol degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Phenol-Degradation|PWY-5418',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenol Degradation|phenol degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-7798',
        Common_Name: 'protein <i>S</i>-nitrosylation and denitrosylation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7798',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|protein <i>S</i>-nitrosylation and denitrosylation',
    },
    {
        BioCyc_ID: 'PWY-8030',
        Common_Name: 'coptisine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|PWY-8030',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|coptisine biosynthesis',
    },
    {
        BioCyc_ID: 'HISTSYN-PWY',
        Common_Name: 'L-histidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|HISTIDINE-SYN|HISTSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-histidine Biosynthesis|L-histidine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7770',
        Common_Name: 'indolmycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7770',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|indolmycin biosynthesis',
    },
    {
        BioCyc_ID: 'LYXMET-PWY',
        Common_Name: 'L-lyxose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|LYXMET-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-lyxose degradation',
    },
    {
        BioCyc_ID: 'PWY-8066',
        Common_Name: 'methylphosphonate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|Methylphosphonate-Degradation|PWY-8066',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|Methylphosphonate Degradation|methylphosphonate degradation III',
    },
    {
        BioCyc_ID: 'GALACTARDEG-PWY',
        Common_Name: 'D-galactarate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldaric-Acid-Degradation|D-Galactarate-Degradation|GALACTARDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldaric Acid Degradation|D-Galactarate Degradation|D-galactarate degradation I',
    },
    {
        BioCyc_ID: 'PWY-6738',
        Common_Name: 'pinitol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|Pinitol-Biosynthesis|PWY-6738',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|Pinitol Biosynthesis|pinitol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5100',
        Common_Name: 'pyruvate fermentation to acetate and lactate II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5100',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate and lactate II',
    },
    {
        BioCyc_ID: 'PWY-5100',
        Common_Name: 'pyruvate fermentation to acetate and lactate II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5100',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate and lactate II',
    },
    {
        BioCyc_ID: 'PWY-5100',
        Common_Name: 'pyruvate fermentation to acetate and lactate II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-Lactate|Pyruvate-Lactate-Fermentation|PWY-5100',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Lactate|Pyruvate Fermentation to Lactate|pyruvate fermentation to acetate and lactate II',
    },
    {
        BioCyc_ID: 'PWY-5100',
        Common_Name: 'pyruvate fermentation to acetate and lactate II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Lactate-Fermentation|PWY-5100',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Lactate|pyruvate fermentation to acetate and lactate II',
    },
    {
        BioCyc_ID: 'PWY-5100',
        Common_Name: 'pyruvate fermentation to acetate and lactate II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5100',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate and lactate II',
    },
    {
        BioCyc_ID: 'PWY-5171',
        Common_Name: '<i>N</i>-cyclopropylmelamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|s-Triazine-Degradation|PWY-5171',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>s</i>-Triazine Degredation|<i>N</i>-cyclopropylmelamine degradation',
    },
    {
        BioCyc_ID: 'PWY-6497',
        Common_Name: 'D-galactarate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldaric-Acid-Degradation|D-Galactarate-Degradation|PWY-6497',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldaric Acid Degradation|D-Galactarate Degradation|D-galactarate degradation II',
    },
    {
        BioCyc_ID: 'GLUTDEG-PWY',
        Common_Name: 'L-glutamate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|GLUTDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation II',
    },
    {
        BioCyc_ID: 'PWY-5109',
        Common_Name: 'propanoate fermentation to 2-methylbutanoate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|PWY-5109',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|propanoate fermentation to 2-methylbutanoate',
    },
    {
        BioCyc_ID: 'PWY-6423',
        Common_Name: 'hemoglobin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Protein-Degradation|PWY-6423',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Protein Degradation|hemoglobin degradation',
    },
    {
        BioCyc_ID: 'PANTOSYN-PWY',
        Common_Name: 'superpathway of coenzyme A biosynthesis I (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|PANTOSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|superpathway of coenzyme A biosynthesis I (bacteria)',
    },
    {
        BioCyc_ID: 'PANTOSYN-PWY',
        Common_Name: 'superpathway of coenzyme A biosynthesis I (bacteria)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PANTOSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of coenzyme A biosynthesis I (bacteria)',
    },
    {
        BioCyc_ID: 'PANTOSYN-PWY',
        Common_Name: 'superpathway of coenzyme A biosynthesis I (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|PANTOSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|superpathway of coenzyme A biosynthesis I (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7321',
        Common_Name: 'ecdysteroid metabolism (arthropods)',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|PWY-7321',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|ecdysteroid metabolism (arthropods)',
    },
    {
        BioCyc_ID: 'PWY-7321',
        Common_Name: 'ecdysteroid metabolism (arthropods)',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|PWY-7321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|ecdysteroid metabolism (arthropods)',
    },
    {
        BioCyc_ID: 'PWY-7321',
        Common_Name: 'ecdysteroid metabolism (arthropods)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7321',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|ecdysteroid metabolism (arthropods)',
    },
    {
        BioCyc_ID: 'PWY-7183',
        Common_Name: 'pyrimidine nucleobases salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7183',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|pyrimidine nucleobases salvage I',
    },
    {
        BioCyc_ID: 'PHENOLDEG-PWY',
        Common_Name: 'phenol degradation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|Phenol-Degradation|PHENOLDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenol Degradation|phenol degradation II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY0-1599',
        Common_Name: 'periplasmic disulfide bond formation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY0-1599',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|periplasmic disulfide bond formation',
    },
    {
        BioCyc_ID: 'PWY-1861',
        Common_Name: 'formaldehyde assimilation II (assimilatory RuMP Cycle)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Assimilation|PWY-1861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Assimilation|formaldehyde assimilation II (assimilatory RuMP Cycle)',
    },
    {
        BioCyc_ID: 'LYSINE-AMINOAD-PWY',
        Common_Name: 'L-lysine biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|LYSINE-SYN|LYSINE-AMINOAD-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-lysine Biosynthesis|L-lysine biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-8374',
        Common_Name: 'antimycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8374',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|antimycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-44',
        Common_Name: 'D-allose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY0-44',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-allose degradation',
    },
    {
        BioCyc_ID: 'PWY-7399',
        Common_Name: 'methylphosphonate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|Methylphosphonate-Degradation|PWY-7399',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|Methylphosphonate Degradation|methylphosphonate degradation II',
    },
    {
        BioCyc_ID: 'PWY-1622',
        Common_Name: 'formaldehyde assimilation I (serine pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Assimilation|PWY-1622',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Assimilation|formaldehyde assimilation I (serine pathway)',
    },
    {
        BioCyc_ID: 'PWY-6739',
        Common_Name: 'pinitol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|Pinitol-Biosynthesis|PWY-6739',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|Pinitol Biosynthesis|pinitol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5294',
        Common_Name: 'superpathway of sulfide oxidation (<i>Acidithiobacillus ferrooxidans</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfide-Oxidation|PWY-5294',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfide Oxidation|superpathway of sulfide oxidation (<i>Acidithiobacillus ferrooxidans</i>)',
    },
    {
        BioCyc_ID: 'PWY-5294',
        Common_Name: 'superpathway of sulfide oxidation (<i>Acidithiobacillus ferrooxidans</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5294',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sulfide oxidation (<i>Acidithiobacillus ferrooxidans</i>)',
    },
    {
        BioCyc_ID: 'PWY-5170',
        Common_Name: 'melamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|s-Triazine-Degradation|PWY-5170',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>s</i>-Triazine Degredation|melamine degradation',
    },
    {
        BioCyc_ID: 'P185-PWY',
        Common_Name: 'formaldehyde assimilation III (dihydroxyacetone cycle)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|Formaldehyde-Assimilation|P185-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|Formaldehyde Assimilation|formaldehyde assimilation III (dihydroxyacetone cycle)',
    },
    {
        BioCyc_ID: 'GLUTAMATE-DEG1-PWY',
        Common_Name: 'L-glutamate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|GLUTAMATE-DEG1-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation I',
    },
    {
        BioCyc_ID: 'PWY-6076',
        Common_Name: 'vitamin D<sub>3</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-6076',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|vitamin D<sub>3</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6076',
        Common_Name: 'vitamin D<sub>3</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|PWY-6076',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|vitamin D<sub>3</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7802',
        Common_Name: 'N-end rule pathway II (prokaryotic)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7802',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|N-end rule pathway II (prokaryotic)',
    },
    {
        BioCyc_ID: 'PWY-7802',
        Common_Name: 'N-end rule pathway II (prokaryotic)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7802',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|N-end rule pathway II (prokaryotic)',
    },
    {
        BioCyc_ID: 'PWY-7195',
        Common_Name: 'pyrimidine ribonucleosides salvage III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7195',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|pyrimidine ribonucleosides salvage III',
    },
    {
        BioCyc_ID: 'PWY-7195',
        Common_Name: 'pyrimidine ribonucleosides salvage III',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Ribonucleosides-Degradation|PWY-7195',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Ribonucleoside Degradation|pyrimidine ribonucleosides salvage III',
    },
    {
        BioCyc_ID: 'PWY-6400',
        Common_Name: 'melatonin degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|Melatonin-Degradation|PWY-6400',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Melatonin Degradation|melatonin degradation III',
    },
    {
        BioCyc_ID: 'PWY-7887',
        Common_Name: 'protein SAMPylation and SAMP-mediated thiolation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7887',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|protein SAMPylation and SAMP-mediated thiolation',
    },
    {
        BioCyc_ID: 'PWY-5040',
        Common_Name: 'theobromine biosynthesis II (via xanthine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PURINE-ALKALOIDS|PWY-5040',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Purine Alkaloid Biosynthesis|theobromine biosynthesis II (via xanthine)',
    },
    {
        BioCyc_ID: 'PWY-3081',
        Common_Name: 'L-lysine biosynthesis V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|LYSINE-SYN|PWY-3081',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-lysine Biosynthesis|L-lysine biosynthesis V',
    },
    {
        BioCyc_ID: 'PWY-5940',
        Common_Name: 'streptomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5940',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|streptomycin biosynthesis',
    },
    {
        BioCyc_ID: 'P124-PWY',
        Common_Name: 'Bifidobacterium shunt',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|P124-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Bifidobacterium shunt',
    },
    {
        BioCyc_ID: 'P124-PWY',
        Common_Name: 'Bifidobacterium shunt',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-Lactate|P124-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Lactate|Bifidobacterium shunt',
    },
    {
        BioCyc_ID: 'P124-PWY',
        Common_Name: 'Bifidobacterium shunt',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|P124-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Bifidobacterium shunt',
    },
    {
        BioCyc_ID: 'PWY-4661',
        Common_Name: '1D-<i>myo</i>-inositol hexakisphosphate biosynthesis III (<i>Spirodela polyrrhiza</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|Phytate-Biosynthesis|PWY-4661',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|Phytate Biosynthesis|1D-<i>myo</i>-inositol hexakisphosphate biosynthesis III (<i>Spirodela polyrrhiza</i>)',
    },
    {
        BioCyc_ID: 'PWY-5600',
        Common_Name: 'pyruvate fermentation to acetate VII',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5600',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate VII',
    },
    {
        BioCyc_ID: 'PWY-5600',
        Common_Name: 'pyruvate fermentation to acetate VII',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5600',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate VII',
    },
    {
        BioCyc_ID: 'PWY-5600',
        Common_Name: 'pyruvate fermentation to acetate VII',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5600',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate VII',
    },
    {
        BioCyc_ID: 'PWY-5726',
        Common_Name: 'deethylsimazine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|s-Triazine-Degradation|PWY-5726',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>s</i>-Triazine Degredation|deethylsimazine degradation',
    },
    {
        BioCyc_ID: 'PWY-5087',
        Common_Name: 'L-glutamate degradation VI (to pyruvate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|PWY-5087',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation VI (to pyruvate)',
    },
    {
        BioCyc_ID: 'PWY3O-6',
        Common_Name: 'dehydro-D-arabinono-1,4-lactone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|PWY3O-6',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|dehydro-D-arabinono-1,4-lactone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5406',
        Common_Name: 'divinyl ether biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|FATTY-ACID-DERIVATIVE-SYN|Divinyl-Ether-Biosynthesis|PWY-5406',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Fatty Acid Derivative Biosynthesis|Divinyl Ether Biosynthesis|divinyl ether biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5406',
        Common_Name: 'divinyl ether biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-5406',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|divinyl ether biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7199',
        Common_Name: 'pyrimidine deoxyribonucleosides salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7199',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|pyrimidine deoxyribonucleosides salvage',
    },
    {
        BioCyc_ID: 'PWY-6399',
        Common_Name: 'melatonin degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|Melatonin-Degradation|PWY-6399',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Melatonin Degradation|melatonin degradation II',
    },
    {
        BioCyc_ID: 'PWY-7899',
        Common_Name: 'protein NEDDylation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7899',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|protein NEDDylation',
    },
    {
        BioCyc_ID: 'PWY-5039',
        Common_Name: 'theobromine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PURINE-ALKALOIDS|PWY-5039',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Purine Alkaloid Biosynthesis|theobromine biosynthesis I',
    },
    {
        BioCyc_ID: 'DAPLYSINESYN-PWY',
        Common_Name: 'L-lysine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|LYSINE-SYN|DAPLYSINESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-lysine Biosynthesis|L-lysine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6915',
        Common_Name: 'pentalenolactone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6915',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|pentalenolactone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5257',
        Common_Name: 'superpathway of pentose and pentitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|PWY-5257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|superpathway of pentose and pentitol degradation',
    },
    {
        BioCyc_ID: 'PWY-5257',
        Common_Name: 'superpathway of pentose and pentitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|PWY-5257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|superpathway of pentose and pentitol degradation',
    },
    {
        BioCyc_ID: 'PWY-5257',
        Common_Name: 'superpathway of pentose and pentitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-5257',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|superpathway of pentose and pentitol degradation',
    },
    {
        BioCyc_ID: 'PWY-5257',
        Common_Name: 'superpathway of pentose and pentitol degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5257',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pentose and pentitol degradation',
    },
    {
        BioCyc_ID: 'PWY-6372',
        Common_Name: '1D-<i>myo</i>-inositol hexakisphosphate biosynthesis IV (<i>Dictyostelium</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|Phytate-Biosynthesis|PWY-6372',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|Phytate Biosynthesis|1D-<i>myo</i>-inositol hexakisphosphate biosynthesis IV (<i>Dictyostelium</i>)',
    },
    {
        BioCyc_ID: 'PWY-5860',
        Common_Name: 'superpathway of demethylmenaquinol-6 biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|Demethylmenaquinol-6-Biosynthesis|PWY-5860',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|Demethylmenaquinol-6 Biosynthesis|superpathway of demethylmenaquinol-6 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5860',
        Common_Name: 'superpathway of demethylmenaquinol-6 biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5860',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of demethylmenaquinol-6 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6223',
        Common_Name: 'gentisate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Gentisate-Degradation|PWY-6223',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Gentisate Degradation|gentisate degradation I',
    },
    {
        BioCyc_ID: 'PWY-8190',
        Common_Name: 'L-glutamate degradation XI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetyl-CoA-Butyrate|PWY-8190',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Butanoate|L-glutamate degradation XI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8190',
        Common_Name: 'L-glutamate degradation XI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|PWY-8190',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation XI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8190',
        Common_Name: 'L-glutamate degradation XI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-8190',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|L-glutamate degradation XI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-5437',
        Common_Name: 'L-threonine degradation I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|PWY-5437',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|L-threonine degradation I',
    },
    {
        BioCyc_ID: 'PWY-5437',
        Common_Name: 'L-threonine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|THREONINE-DEG|PWY-5437',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-threonine Degradation|L-threonine degradation I',
    },
    {
        BioCyc_ID: 'PWY-5027',
        Common_Name: 'phylloquinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Phylloquinone-Biosynthesis|PWY-5027',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Phylloquinol Biosynthesis|phylloquinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5027',
        Common_Name: 'phylloquinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|PWY-5027',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|phylloquinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6801',
        Common_Name: 'volatile esters biosynthesis (during fruit ripening)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6801',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|volatile esters biosynthesis (during fruit ripening)',
    },
    {
        BioCyc_ID: 'PWY-6801',
        Common_Name: 'volatile esters biosynthesis (during fruit ripening)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-6801',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|volatile esters biosynthesis (during fruit ripening)',
    },
    {
        BioCyc_ID: 'PWY-6556',
        Common_Name: 'pyrimidine ribonucleosides salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-6556',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|pyrimidine ribonucleosides salvage II',
    },
    {
        BioCyc_ID: 'PWY-6556',
        Common_Name: 'pyrimidine ribonucleosides salvage II',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Ribonucleosides-Degradation|PWY-6556',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Ribonucleoside Degradation|pyrimidine ribonucleosides salvage II',
    },
    {
        BioCyc_ID: 'PWY-6398',
        Common_Name: 'melatonin degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|Melatonin-Degradation|PWY-6398',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Melatonin Degradation|melatonin degradation I',
    },
    {
        BioCyc_ID: 'PWY-7511',
        Common_Name: 'protein ubiquitination',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7511',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|protein ubiquitination',
    },
    {
        BioCyc_ID: 'PWY-5848',
        Common_Name: 'cinchona alkaloids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|QUINOLINE-ALKALOIDS|PWY-5848',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Quinoline Alkaloid Biosynthesis|cinchona alkaloids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2942',
        Common_Name: 'L-lysine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|LYSINE-SYN|PWY-2942',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-lysine Biosynthesis|L-lysine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7355',
        Common_Name: 'doxorubicin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7355',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|doxorubicin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6992',
        Common_Name: '1,5-anhydrofructose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-6992',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|1,5-anhydrofructose degradation',
    },
    {
        BioCyc_ID: 'PWY-6362',
        Common_Name: '1D-<i>myo</i>-inositol hexakisphosphate biosynthesis II (mammalian)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|Phytate-Biosynthesis|PWY-6362',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|Phytate Biosynthesis|1D-<i>myo</i>-inositol hexakisphosphate biosynthesis II (mammalian)',
    },
    {
        BioCyc_ID: 'PWY-5993',
        Common_Name: 'superpathway of rifamycin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5993',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|superpathway of rifamycin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5993',
        Common_Name: 'superpathway of rifamycin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5993',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of rifamycin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7469',
        Common_Name: 'gentisate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Gentisate-Degradation|PWY-7469',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Gentisate Degradation|gentisate degradation II',
    },
    {
        BioCyc_ID: 'HISDEG-PWY',
        Common_Name: 'L-histidine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|HISTIDINE-DEG|HISDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-histidine Degradation|L-histidine degradation I',
    },
    {
        BioCyc_ID: 'P283-PWY',
        Common_Name: 'hydrogen oxidation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|Hydrogen-Oxidation|P283-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|Hydrogen Oxidation|hydrogen oxidation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-7491',
        Common_Name: 'podophyllotoxin glucosides metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7491',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|podophyllotoxin glucosides metabolism',
    },
    {
        BioCyc_ID: 'PWY-7491',
        Common_Name: 'podophyllotoxin glucosides metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-7491',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|podophyllotoxin glucosides metabolism',
    },
    {
        BioCyc_ID: 'PWY-7491',
        Common_Name: 'podophyllotoxin glucosides metabolism',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7491',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|podophyllotoxin glucosides metabolism',
    },
    {
        BioCyc_ID: 'PWY-7205',
        Common_Name: 'CMP phosphorylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7205',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|CMP phosphorylation',
    },
    {
        BioCyc_ID: 'PWY-6261',
        Common_Name: 'thyroid hormone metabolism II (via conjugation and/or degradation)',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|Thyroid-Hormone-Metabolism|PWY-6261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Thyroid Hormone Metabolism|thyroid hormone metabolism II (via conjugation and/or degradation)',
    },
    {
        BioCyc_ID: 'PWY-8147',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system I type)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Cytochrome-C-Biogenesis|PWY-8147',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Cytochrome <i>c</i> Biogenesis|cytochrome <i>c</i> biogenesis (system I type)',
    },
    {
        BioCyc_ID: 'PWY-8147',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system I type)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Cytochrome-C-Biogenesis|PWY-8147',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Cytochrome <i>c</i> Biogenesis|cytochrome <i>c</i> biogenesis (system I type)',
    },
    {
        BioCyc_ID: 'PWY-8147',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system I type)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-8147',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|cytochrome <i>c</i> biogenesis (system I type)',
    },
    {
        BioCyc_ID: 'PWY-6326',
        Common_Name: 'camptothecin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|QUINOLINE-ALKALOIDS|PWY-6326',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Quinoline Alkaloid Biosynthesis|camptothecin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2941',
        Common_Name: 'L-lysine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|LYSINE-SYN|PWY-2941',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-lysine Biosynthesis|L-lysine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7810',
        Common_Name: 'chlorotetracycline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7810',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|chlorotetracycline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8004',
        Common_Name: 'Entner-Doudoroff pathway I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Entner-Duodoroff-Pathways|PWY-8004',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Entner-Doudoroff Pathways|Entner-Doudoroff pathway I',
    },
    {
        BioCyc_ID: 'PWY-8004',
        Common_Name: 'Entner-Doudoroff pathway I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-8004',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Entner-Doudoroff pathway I',
    },
    {
        BioCyc_ID: 'PWY-8004',
        Common_Name: 'Entner-Doudoroff pathway I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8004',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|Entner-Doudoroff pathway I',
    },
    {
        BioCyc_ID: 'PWY-6361',
        Common_Name: '1D-<i>myo</i>-inositol hexakisphosphate biosynthesis I (from Ins(1,4,5)P3)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|Phytate-Biosynthesis|PWY-6361',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|Phytate Biosynthesis|1D-<i>myo</i>-inositol hexakisphosphate biosynthesis I (from Ins(1,4,5)P3)',
    },
    {
        BioCyc_ID: 'PWY-6146',
        Common_Name: '<i>Methanobacterium thermoautotrophicum</i> biosynthetic metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|PWY-6146',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|<i>Methanobacterium thermoautotrophicum</i> biosynthetic metabolism',
    },
    {
        BioCyc_ID: 'PWY-6146',
        Common_Name: '<i>Methanobacterium thermoautotrophicum</i> biosynthetic metabolism',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6146',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|<i>Methanobacterium thermoautotrophicum</i> biosynthetic metabolism',
    },
    {
        BioCyc_ID: 'PWY-6505',
        Common_Name: 'L-tryptophan degradation XII (Geobacillus)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6505',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-tryptophan degradation XII (Geobacillus)',
    },
    {
        BioCyc_ID: 'PWY-6505',
        Common_Name: 'L-tryptophan degradation XII (Geobacillus)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-6505',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation XII (Geobacillus)',
    },
    {
        BioCyc_ID: 'PWY-5055',
        Common_Name: 'nicotinate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nicotinate-Degradation|PWY-5055',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nicotinate Degradation|nicotinate degradation III',
    },
    {
        BioCyc_ID: 'PWY-5028',
        Common_Name: 'L-histidine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|HISTIDINE-DEG|PWY-5028',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-histidine Degradation|L-histidine degradation II',
    },
    {
        BioCyc_ID: 'PWY-6512',
        Common_Name: 'hydrogen oxidation III (anaerobic, NADP)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|Hydrogen-Oxidation|PWY-6512',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|Hydrogen Oxidation|hydrogen oxidation III (anaerobic, NADP)',
    },
    {
        BioCyc_ID: 'PWY-83',
        Common_Name: 'monolignol glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-83',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|monolignol glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-83',
        Common_Name: 'monolignol glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNIN-SYN|PWY-83',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignin Biosynthesis|monolignol glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-83',
        Common_Name: 'monolignol glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-83',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|monolignol glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7193',
        Common_Name: 'pyrimidine ribonucleosides salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7193',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|pyrimidine ribonucleosides salvage I',
    },
    {
        BioCyc_ID: 'PWY-6260',
        Common_Name: 'thyroid hormone metabolism I (via deiodination)',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|Thyroid-Hormone-Metabolism|PWY-6260',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Thyroid Hormone Metabolism|thyroid hormone metabolism I (via deiodination)',
    },
    {
        BioCyc_ID: 'PWY-7884',
        Common_Name: 'lipoprotein posttranslational modification',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7884',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|lipoprotein posttranslational modification',
    },
    {
        BioCyc_ID: 'PWY-5318',
        Common_Name: 'calystegine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TROPANE-ALKALOIDS|PWY-5318',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Tropane Alkaloid Biosynthesis|calystegine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5097',
        Common_Name: 'L-lysine biosynthesis VI',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|LYSINE-SYN|PWY-5097',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-lysine Biosynthesis|L-lysine biosynthesis VI',
    },
    {
        BioCyc_ID: 'PWY-8119',
        Common_Name: 'microcin B17 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8119',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|microcin B17 biosynthesis',
    },
    {
        BioCyc_ID: 'GLUCOSE1PMETAB-PWY',
        Common_Name: 'glucose and glucose-1-phosphate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|GLUCOSE1PMETAB-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|glucose and glucose-1-phosphate degradation',
    },
    {
        BioCyc_ID: 'PWY-6554',
        Common_Name: '1D-<i>myo</i>-inositol hexakisphosphate biosynthesis V (from Ins(1,3,4)P3)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|Phytate-Biosynthesis|PWY-6554',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|Phytate Biosynthesis|1D-<i>myo</i>-inositol hexakisphosphate biosynthesis V (from Ins(1,3,4)P3)',
    },
    {
        BioCyc_ID: 'PWY-6621',
        Common_Name: 'salicylate glucosides biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|Salicylate-Glycosylation|PWY-6621',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|Salicylate Glycosylation|salicylate glucosides biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6621',
        Common_Name: 'salicylate glucosides biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6621',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|salicylate glucosides biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5033',
        Common_Name: 'nicotinate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nicotinate-Degradation|PWY-5033',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nicotinate Degradation|nicotinate degradation II',
    },
    {
        BioCyc_ID: 'HISTDEG-PWY',
        Common_Name: 'L-histidine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|HISTIDINE-DEG|HISTDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-histidine Degradation|L-histidine degradation IV',
    },
    {
        BioCyc_ID: 'PWY-5382',
        Common_Name: 'hydrogen oxidation II (aerobic, NAD)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|Hydrogen-Oxidation|PWY-5382',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|Hydrogen Oxidation|hydrogen oxidation II (aerobic, NAD)',
    },
    {
        BioCyc_ID: 'PWY-6035',
        Common_Name: '2,3-<i>cis</i>-flavanols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-6035',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|2,3-<i>cis</i>-flavanols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6035',
        Common_Name: '2,3-<i>cis</i>-flavanols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6035',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|2,3-<i>cis</i>-flavanols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4762',
        Common_Name: 'superpathway of choline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-4762',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|superpathway of choline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4762',
        Common_Name: 'superpathway of choline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-4762',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of choline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7102',
        Common_Name: 'bisabolene biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Sesquiterpenoid-Degradation|PWY-7102',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Sesquiterpenoid Degradation|bisabolene biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7894',
        Common_Name: 'procollagen hydroxylation and glycosylation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7894',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|procollagen hydroxylation and glycosylation',
    },
    {
        BioCyc_ID: 'PWY-5317',
        Common_Name: 'tropane alkaloids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TROPANE-ALKALOIDS|PWY-5317',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Tropane Alkaloid Biosynthesis|tropane alkaloids biosynthesis',
    },
    {
        BioCyc_ID: 'PROSYN-PWY',
        Common_Name: 'L-proline biosynthesis I (from L-glutamate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PROLINE-SYN|PROSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-proline Biosynthesis|L-proline biosynthesis I (from L-glutamate)',
    },
    {
        BioCyc_ID: 'PWY-8387',
        Common_Name: 'microcin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8387',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|microcin C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1314',
        Common_Name: 'fructose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY0-1314',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|fructose degradation',
    },
    {
        BioCyc_ID: 'PWY-7084',
        Common_Name: 'nitrifier denitrification',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY-7084',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|nitrifier denitrification',
    },
    {
        BioCyc_ID: 'PWY-7084',
        Common_Name: 'nitrifier denitrification',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-oxidation|PWY-7084',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Oxidation|nitrifier denitrification',
    },
    {
        BioCyc_ID: 'PWY-7084',
        Common_Name: 'nitrifier denitrification',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Denitrification|PWY-7084',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Denitrification|nitrifier denitrification',
    },
    {
        BioCyc_ID: 'PWY-7084',
        Common_Name: 'nitrifier denitrification',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7084',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|nitrifier denitrification',
    },
    {
        BioCyc_ID: 'PWY-722',
        Common_Name: 'nicotinate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nicotinate-Degradation|PWY-722',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nicotinate Degradation|nicotinate degradation I',
    },
    {
        BioCyc_ID: 'PWY-5031',
        Common_Name: 'L-histidine degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|HISTIDINE-DEG|PWY-5031',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-histidine Degradation|L-histidine degradation V',
    },
    {
        BioCyc_ID: 'PWY1QP9-2',
        Common_Name: 'fructosyllysine and glucosyllysine metabolism',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Glyco-Amino-Acids-Degradation|PWY1QP9-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|Glyco-Amino Acid Degradation|fructosyllysine and glucosyllysine metabolism',
    },
    {
        BioCyc_ID: 'PWY-6148',
        Common_Name: 'tetrahydromethanopterin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|PWY-6148',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|tetrahydromethanopterin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7615',
        Common_Name: 'pterocarpan phytoalexins modification (maackiain, medicarpin, pisatin, phaseollin)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7615',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pterocarpan phytoalexins modification (maackiain, medicarpin, pisatin, phaseollin)',
    },
    {
        BioCyc_ID: 'PWY-7615',
        Common_Name: 'pterocarpan phytoalexins modification (maackiain, medicarpin, pisatin, phaseollin)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PHYTOALEXIN-DEG|PWY-7615',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Phytoalexin Degradation|pterocarpan phytoalexins modification (maackiain, medicarpin, pisatin, phaseollin)',
    },
    {
        BioCyc_ID: 'PWY-7742',
        Common_Name: 'phenolphthiocerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-7742',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|phenolphthiocerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7187',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-7187',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis II",
    },
    {
        BioCyc_ID: 'PWY-7187',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-7187',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7640',
        Common_Name: 'abscisic acid degradation to neophaseic acid',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|Abscisic-Acid-Degradation|PWY-7640',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Abscisic Acid Degradation|abscisic acid degradation to neophaseic acid',
    },
    {
        BioCyc_ID: 'PWY-7640',
        Common_Name: 'abscisic acid degradation to neophaseic acid',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Sesquiterpenoid-Degradation|Abscisic-Acid-Degradation|PWY-7640',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Sesquiterpenoid Degradation|Abscisic Acid Degradation|abscisic acid degradation to neophaseic acid',
    },
    {
        BioCyc_ID: 'PWY-6012-1',
        Common_Name: 'acyl carrier protein activation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-6012-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|acyl carrier protein activation',
    },
    {
        BioCyc_ID: 'PWY-5846',
        Common_Name: 'colchicine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TROPANE-ALKALOIDS|PWY-5846',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Tropane Alkaloid Biosynthesis|colchicine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4981',
        Common_Name: 'L-proline biosynthesis II (from arginine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PROLINE-SYN|PWY-4981',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-proline Biosynthesis|L-proline biosynthesis II (from arginine)',
    },
    {
        BioCyc_ID: 'PWY-5631',
        Common_Name: 'deacetylcephalosporin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5631',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|deacetylcephalosporin C biosynthesis',
    },
    {
        BioCyc_ID: 'NPGLUCAT-PWY',
        Common_Name: 'Entner-Doudoroff pathway II (non-phosphorylative)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Entner-Duodoroff-Pathways|NPGLUCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Entner-Doudoroff Pathways|Entner-Doudoroff pathway II (non-phosphorylative)',
    },
    {
        BioCyc_ID: 'NPGLUCAT-PWY',
        Common_Name: 'Entner-Doudoroff pathway II (non-phosphorylative)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|NPGLUCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Entner-Doudoroff pathway II (non-phosphorylative)',
    },
    {
        BioCyc_ID: 'PWY-5706',
        Common_Name: 'alliin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-5706',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|alliin metabolism',
    },
    {
        BioCyc_ID: 'PWY-7237',
        Common_Name: '<i>myo</i>-, <i>chiro</i>- and <i>scyllo</i>-inositol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|PWY-7237',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|<i>myo</i>-, <i>chiro</i>- and <i>scyllo</i>-inositol degradation',
    },
    {
        BioCyc_ID: 'PWY-7237',
        Common_Name: '<i>myo</i>-, <i>chiro</i>- and <i>scyllo</i>-inositol degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7237',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|<i>myo</i>-, <i>chiro</i>- and <i>scyllo</i>-inositol degradation',
    },
    {
        BioCyc_ID: 'PWY-8386',
        Common_Name: 'benzene degradation II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzene-Degradation|PWY-8386',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzene Degradation|benzene degradation II (aerobic)',
    },
    {
        BioCyc_ID: 'HISHP-PWY',
        Common_Name: 'L-histidine degradation VI',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|HISTIDINE-DEG|HISHP-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-histidine Degradation|L-histidine degradation VI',
    },
    {
        BioCyc_ID: 'PWY0-521',
        Common_Name: 'fructoselysine and psicoselysine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Glyco-Amino-Acids-Degradation|PWY0-521',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|Glyco-Amino Acid Degradation|fructoselysine and psicoselysine degradation',
    },
    {
        BioCyc_ID: 'PWY18C3-21',
        Common_Name: 'phenylpropanoid volatiles glycoconjugation (tomato)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY18C3-21',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|phenylpropanoid volatiles glycoconjugation (tomato)',
    },
    {
        BioCyc_ID: 'PWY18C3-21',
        Common_Name: 'phenylpropanoid volatiles glycoconjugation (tomato)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY18C3-21',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|phenylpropanoid volatiles glycoconjugation (tomato)',
    },
    {
        BioCyc_ID: 'PWY-5148',
        Common_Name: 'acyl-CoA hydrolysis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-5148',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|acyl-CoA hydrolysis',
    },
    {
        BioCyc_ID: 'PWY-5271',
        Common_Name: 'abscisic acid degradation to phaseic acid',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|Abscisic-Acid-Degradation|PWY-5271',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Abscisic Acid Degradation|abscisic acid degradation to phaseic acid',
    },
    {
        BioCyc_ID: 'PWY-5271',
        Common_Name: 'abscisic acid degradation to phaseic acid',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Sesquiterpenoid-Degradation|Abscisic-Acid-Degradation|PWY-5271',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Sesquiterpenoid Degradation|Abscisic Acid Degradation|abscisic acid degradation to phaseic acid',
    },
    {
        BioCyc_ID: 'PWY-8146',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system II type)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Cytochrome-C-Biogenesis|PWY-8146',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Cytochrome <i>c</i> Biogenesis|cytochrome <i>c</i> biogenesis (system II type)',
    },
    {
        BioCyc_ID: 'PWY-8146',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system II type)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Cytochrome-C-Biogenesis|PWY-8146',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Cytochrome <i>c</i> Biogenesis|cytochrome <i>c</i> biogenesis (system II type)',
    },
    {
        BioCyc_ID: 'PWY-8146',
        Common_Name: 'cytochrome <i>c</i> biogenesis (system II type)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-8146',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|cytochrome <i>c</i> biogenesis (system II type)',
    },
    {
        BioCyc_ID: 'PWY-5843',
        Common_Name: 'cocaine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TROPANE-ALKALOIDS|PWY-5843',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Tropane Alkaloid Biosynthesis|cocaine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4281',
        Common_Name: 'L-proline biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PROLINE-SYN|PWY-4281',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-proline Biosynthesis|L-proline biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-7483',
        Common_Name: 'elloramycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7483',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|elloramycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2221',
        Common_Name: 'Entner-Doudoroff pathway III (semi-phosphorylative)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Entner-Duodoroff-Pathways|PWY-2221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Entner-Doudoroff Pathways|Entner-Doudoroff pathway III (semi-phosphorylative)',
    },
    {
        BioCyc_ID: 'PWY-2221',
        Common_Name: 'Entner-Doudoroff pathway III (semi-phosphorylative)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|PWY-2221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Entner-Doudoroff pathway III (semi-phosphorylative)',
    },
    {
        BioCyc_ID: 'PWY-7614',
        Common_Name: 'methiin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-7614',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|methiin metabolism',
    },
    {
        BioCyc_ID: 'PWY-7379',
        Common_Name: 'mRNA capping II',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7379',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|mRNA capping II',
    },
    {
        BioCyc_ID: 'PWY-7379',
        Common_Name: 'mRNA capping II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7379',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|mRNA capping II',
    },
    {
        BioCyc_ID: 'PWY-8376',
        Common_Name: 'benzene degradation III (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzene-Degradation|PWY-8376',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzene Degradation|benzene degradation III (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5905',
        Common_Name: 'hypusine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Amino-Acids-Modification|PWY-5905',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Modification of Amino Acid Within Proteins|hypusine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5030',
        Common_Name: 'L-histidine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|HISTIDINE-DEG|PWY-5030',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-histidine Degradation|L-histidine degradation III',
    },
    {
        BioCyc_ID: 'PWY-8110',
        Common_Name: 'L-dopa degradation II (bacterial)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|L-dopa-Degradation|PWY-8110',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|L-dopa-Degradation|L-dopa degradation II (bacterial)',
    },
    {
        BioCyc_ID: 'FOLSYN-PWY',
        Common_Name: 'superpathway of tetrahydrofolate biosynthesis and salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|FOLSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|superpathway of tetrahydrofolate biosynthesis and salvage',
    },
    {
        BioCyc_ID: 'FOLSYN-PWY',
        Common_Name: 'superpathway of tetrahydrofolate biosynthesis and salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|FOLSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|superpathway of tetrahydrofolate biosynthesis and salvage',
    },
    {
        BioCyc_ID: 'FOLSYN-PWY',
        Common_Name: 'superpathway of tetrahydrofolate biosynthesis and salvage',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|FOLSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of tetrahydrofolate biosynthesis and salvage',
    },
    {
        BioCyc_ID: 'PWY-2841',
        Common_Name: 'cytokinins degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|CYTOKININS-DEGRADATION|PWY-2841',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Cytokinin Degradation|cytokinins degradation',
    },
    {
        BioCyc_ID: 'PWY-2841',
        Common_Name: 'cytokinins degradation',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-2841',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|cytokinins degradation',
    },
    {
        BioCyc_ID: 'PWY-7745',
        Common_Name: 'p-HBAD biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-7745',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|p-HBAD biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7642',
        Common_Name: "abscisic acid degradation to 7'-hydroxyabscisate",
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|Abscisic-Acid-Degradation|PWY-7642',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Abscisic Acid Degradation|abscisic acid degradation to 7'-hydroxyabscisate",
    },
    {
        BioCyc_ID: 'PWY-7642',
        Common_Name: "abscisic acid degradation to 7'-hydroxyabscisate",
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Sesquiterpenoid-Degradation|Abscisic-Acid-Degradation|PWY-7642',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Sesquiterpenoid Degradation|Abscisic Acid Degradation|abscisic acid degradation to 7'-hydroxyabscisate",
    },
    {
        BioCyc_ID: 'PWY0-1600',
        Common_Name: 'periplasmic disulfide bond reduction',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY0-1600',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|periplasmic disulfide bond reduction',
    },
    {
        BioCyc_ID: 'PWY-5467',
        Common_Name: 'gramine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-5467',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|gramine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3341',
        Common_Name: 'L-proline biosynthesis III (from L-ornithine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PROLINE-SYN|PWY-3341',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-proline Biosynthesis|L-proline biosynthesis III (from L-ornithine)',
    },
    {
        BioCyc_ID: 'PWY-7671',
        Common_Name: 'saframycin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7671',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|saframycin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8060',
        Common_Name: '2-deoxy-D-ribose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|2-Deoxy-D-Ribose-Degradation|PWY-8060',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|2-Deoxy-D-ribose Degradation|2-deoxy-D-ribose degradation I',
    },
    {
        BioCyc_ID: 'PWY-8301',
        Common_Name: '<i>S</i>-(6-hydroxy-4-methylhexan-4-yl)-L-cysteinylglycine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-8301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|<i>S</i>-(6-hydroxy-4-methylhexan-4-yl)-L-cysteinylglycine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7592',
        Common_Name: 'arachidonate biosynthesis III (6-desaturase, mammals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Arachidonate-Biosynthesis|PWY-7592',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Arachidonate Biosynthesis|arachidonate biosynthesis III (6-desaturase, mammals)',
    },
    {
        BioCyc_ID: 'PWY-7592',
        Common_Name: 'arachidonate biosynthesis III (6-desaturase, mammals)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7592',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|arachidonate biosynthesis III (6-desaturase, mammals)',
    },
    {
        BioCyc_ID: 'PWY-8375',
        Common_Name: 'benzene degradation IV (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzene-Degradation|PWY-8375',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzene Degradation|benzene degradation IV (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-4921',
        Common_Name: 'protein citrullination',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Amino-Acids-Modification|PWY-4921',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Modification of Amino Acid Within Proteins|protein citrullination',
    },
    {
        BioCyc_ID: 'PWY-4921',
        Common_Name: 'protein citrullination',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|Citrulline-Biosynthesis|PWY-4921',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-citrulline Biosynthesis|protein citrullination',
    },
    {
        BioCyc_ID: 'PWY-7844',
        Common_Name: 'heme degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Heme-Degradation|PWY-7844',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Heme Degradation|heme degradation III',
    },
    {
        BioCyc_ID: 'PWY-6334',
        Common_Name: 'L-dopa degradation I (mammalian)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|L-dopa-Degradation|PWY-6334',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|L-dopa-Degradation|L-dopa degradation I (mammalian)',
    },
    {
        BioCyc_ID: 'PWY-6612',
        Common_Name: 'superpathway of tetrahydrofolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|PWY-6612',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|superpathway of tetrahydrofolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6612',
        Common_Name: 'superpathway of tetrahydrofolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|PWY-6612',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|superpathway of tetrahydrofolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6612',
        Common_Name: 'superpathway of tetrahydrofolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6612',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of tetrahydrofolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6502',
        Common_Name: '8-oxo-(d)GTP detoxification I',
        MetaCyc_hierarchy_IDs: 'Detoxification|8-Oxo-GTP-Detoxification|PWY-6502',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|8-oxo-GTP and 8-oxo-dGTP Detoxification|8-oxo-(d)GTP detoxification I',
    },
    {
        BioCyc_ID: 'PWY-6502',
        Common_Name: '8-oxo-(d)GTP detoxification I',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6502',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|8-oxo-(d)GTP detoxification I',
    },
    {
        BioCyc_ID: 'PWY-5885',
        Common_Name: 'wax esters biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-5885',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|wax esters biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8289',
        Common_Name: 'dZTP biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|PWY-8289',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|dZTP biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-8289',
        Common_Name: 'dZTP biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Deoxyribonuc-De-Novo-Biosynthesis|PWY-8289',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|dZTP biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7893',
        Common_Name: 'protein Pupylation and dePupylation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7893',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|protein Pupylation and dePupylation',
    },
    {
        BioCyc_ID: 'PWY-7532',
        Common_Name: 'acetylaszonalenin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-7532',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|acetylaszonalenin biosynthesis',
    },
    {
        BioCyc_ID: 'GLUTORN-PWY',
        Common_Name: 'L-ornithine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|L-Ornithine-Biosynthesis|GLUTORN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-Ornithine Biosynthesis|L-ornithine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7821',
        Common_Name: 'tunicamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7821',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|tunicamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8058',
        Common_Name: '2-deoxy-D-ribose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|2-Deoxy-D-Ribose-Degradation|PWY-8058',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|2-Deoxy-D-ribose Degradation|2-deoxy-D-ribose degradation II',
    },
    {
        BioCyc_ID: 'PWY-5389',
        Common_Name: '3-methylthiopropanoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-5389',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|3-methylthiopropanoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-341',
        Common_Name: 'cholesterol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY66-341',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|cholesterol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY66-341',
        Common_Name: 'cholesterol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY66-341',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|cholesterol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5450',
        Common_Name: 'benzene degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Benzene-Degradation|PWY-5450',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Benzene Degradation|benzene degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-6391',
        Common_Name: '<i>meso</i>-butanediol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Butanediol-Biosynthesis|PWY-6391',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Butanediol Biosynthesis|<i>meso</i>-butanediol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7847',
        Common_Name: 'heme degradation VI',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Heme-Degradation|PWY-7847',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Heme Degradation|heme degradation VI',
    },
    {
        BioCyc_ID: 'PWY-6422',
        Common_Name: 'D-arginine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|D-Amino-Acid-Degradation|PWY-6422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|D-Amino Acid Degradation|D-arginine degradation',
    },
    {
        BioCyc_ID: 'PWY2DNV-11',
        Common_Name: 'tetrahydrofolate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|PWY2DNV-11',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|tetrahydrofolate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY2DNV-11',
        Common_Name: 'tetrahydrofolate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|PWY2DNV-11',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|tetrahydrofolate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7197',
        Common_Name: 'pyrimidine deoxyribonucleotide phosphorylation',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7197',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pyrimidine deoxyribonucleotide phosphorylation',
    },
    {
        BioCyc_ID: 'PWY-7197',
        Common_Name: 'pyrimidine deoxyribonucleotide phosphorylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7197',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|pyrimidine deoxyribonucleotide phosphorylation',
    },
    {
        BioCyc_ID: 'PWY-8371',
        Common_Name: 'mannosylerythritol lipids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-8371',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|mannosylerythritol lipids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7832',
        Common_Name: 'ABH and Lewis epitopes biosynthesis from type 1 precursor disaccharide',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7832',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|ABH and Lewis epitopes biosynthesis from type 1 precursor disaccharide',
    },
    {
        BioCyc_ID: 'PWY-7832',
        Common_Name: 'ABH and Lewis epitopes biosynthesis from type 1 precursor disaccharide',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7832',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|ABH and Lewis epitopes biosynthesis from type 1 precursor disaccharide',
    },
    {
        BioCyc_ID: 'PWY-7832',
        Common_Name: 'ABH and Lewis epitopes biosynthesis from type 1 precursor disaccharide',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7832',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|ABH and Lewis epitopes biosynthesis from type 1 precursor disaccharide',
    },
    {
        BioCyc_ID: 'PWY-8345',
        Common_Name: 'malbrancheamide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-8345',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|malbrancheamide biosynthesis',
    },
    {
        BioCyc_ID: 'ARGININE-SYN4-PWY',
        Common_Name: 'L-ornithine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|L-Ornithine-Biosynthesis|ARGININE-SYN4-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-Ornithine Biosynthesis|L-ornithine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8293',
        Common_Name: 'alazopeptin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8293',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|alazopeptin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5708',
        Common_Name: 'ethiin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-5708',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|ethiin metabolism',
    },
    {
        BioCyc_ID: 'ALLANTOINDEG-PWY',
        Common_Name: 'superpathway of allantoin degradation in yeast',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Allantoin-degradation|ALLANTOINDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Allantoin Degradation|superpathway of allantoin degradation in yeast',
    },
    {
        BioCyc_ID: 'ALLANTOINDEG-PWY',
        Common_Name: 'superpathway of allantoin degradation in yeast',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ALLANTOINDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of allantoin degradation in yeast',
    },
    {
        BioCyc_ID: 'P184-PWY',
        Common_Name: 'protocatechuate degradation I (<I>meta</I>-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Protocatechuate-Degradation|P184-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Protocatechuate Degradation|protocatechuate degradation I (<I>meta</I>-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-6390',
        Common_Name: '(<i>S,S</i>)-butanediol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Butanediol-Biosynthesis|PWY-6390',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Butanediol Biosynthesis|(<i>S,S</i>)-butanediol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7843',
        Common_Name: 'heme degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Heme-Degradation|PWY-7843',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Heme Degradation|heme degradation IV',
    },
    {
        BioCyc_ID: 'PWY0-1535',
        Common_Name: 'D-serine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|D-Amino-Acid-Degradation|PWY0-1535',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|D-Amino Acid Degradation|D-serine degradation',
    },
    {
        BioCyc_ID: 'PWY-2161B',
        Common_Name: 'glutamate removal from folates',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|PWY-2161B',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|glutamate removal from folates',
    },
    {
        BioCyc_ID: 'PWY-2161B',
        Common_Name: 'glutamate removal from folates',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|PWY-2161B',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|glutamate removal from folates',
    },
    {
        BioCyc_ID: 'PWY-7656',
        Common_Name: '<i>Spodoptera littoralis</i> pheromone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-7656',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|<i>Spodoptera littoralis</i> pheromone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7656',
        Common_Name: '<i>Spodoptera littoralis</i> pheromone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7656',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|<i>Spodoptera littoralis</i> pheromone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6795',
        Common_Name: 'diacylglyceryl-<i>N,N,N</i>-trimethylhomoserine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-6795',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|diacylglyceryl-<i>N,N,N</i>-trimethylhomoserine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7918',
        Common_Name: 'protein <i>N</i>-glycosylation processing phase (endoplasmic reticulum, yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7918',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <i>N</i>-glycosylation processing phase (endoplasmic reticulum, yeast)',
    },
    {
        BioCyc_ID: 'PWY-7918',
        Common_Name: 'protein <i>N</i>-glycosylation processing phase (endoplasmic reticulum, yeast)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7918',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <i>N</i>-glycosylation processing phase (endoplasmic reticulum, yeast)',
    },
    {
        BioCyc_ID: 'PWY-7918',
        Common_Name: 'protein <i>N</i>-glycosylation processing phase (endoplasmic reticulum, yeast)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7918',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <i>N</i>-glycosylation processing phase (endoplasmic reticulum, yeast)',
    },
    {
        BioCyc_ID: 'PWY-5877',
        Common_Name: 'beta-carboline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-5877',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|beta-carboline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6922',
        Common_Name: 'L-<i>N<sup>&delta;</sup></i>-acetylornithine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|L-Ornithine-Biosynthesis|PWY-6922',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-Ornithine Biosynthesis|L-<i>N<sup>&delta;</sup></i>-acetylornithine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8443',
        Common_Name: 'cyclosporin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8443',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|cyclosporin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6900',
        Common_Name: '(<i>Z</i>)-butanethial-<i>S</i>-oxide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-6900',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|(<i>Z</i>)-butanethial-<i>S</i>-oxide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5796',
        Common_Name: 'malonate decarboxylase activation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|PWY-5796',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|malonate decarboxylase activation',
    },
    {
        BioCyc_ID: 'ENTBACSYN-PWY',
        Common_Name: 'enterobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|ENTBACSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|enterobactin biosynthesis',
    },
    {
        BioCyc_ID: 'ENTBACSYN-PWY',
        Common_Name: 'enterobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ENTBACSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|enterobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6336',
        Common_Name: 'protocatechuate degradation III (<I>para</I>-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Protocatechuate-Degradation|PWY-6336',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Protocatechuate Degradation|protocatechuate degradation III (<I>para</I>-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-5951',
        Common_Name: '(<i>R,R</i>)-butanediol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Butanediol-Biosynthesis|PWY-5951',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Butanediol Biosynthesis|(<i>R,R</i>)-butanediol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7846',
        Common_Name: 'heme degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Heme-Degradation|PWY-7846',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Heme Degradation|heme degradation V',
    },
    {
        BioCyc_ID: 'PWY-8448',
        Common_Name: 'D-alanine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|D-Amino-Acid-Degradation|PWY-8448',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|D-Amino Acid Degradation|D-alanine degradation',
    },
    {
        BioCyc_ID: 'PWY-6614',
        Common_Name: 'tetrahydrofolate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|PWY-6614',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|tetrahydrofolate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6614',
        Common_Name: 'tetrahydrofolate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|PWY-6614',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|tetrahydrofolate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-2902',
        Common_Name: 'cytokinin-<i>O</i>-glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|CYTOKININ-BIOSYNTHESIS|PWY-2902',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Cytokinin Biosynthesis|cytokinin-<i>O</i>-glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2902',
        Common_Name: 'cytokinin-<i>O</i>-glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-2902',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|cytokinin-<i>O</i>-glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2902',
        Common_Name: 'cytokinin-<i>O</i>-glucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-2902',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|cytokinin-<i>O</i>-glucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY3DJ-11281',
        Common_Name: 'sphingomyelin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY3DJ-11281',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|sphingomyelin metabolism',
    },
    {
        BioCyc_ID: 'PWY-7031',
        Common_Name: 'protein <i>N</i>-glycosylation (bacterial)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|PWY-7031',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|protein <i>N</i>-glycosylation (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-7031',
        Common_Name: 'protein <i>N</i>-glycosylation (bacterial)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7031',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <i>N</i>-glycosylation (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-7031',
        Common_Name: 'protein <i>N</i>-glycosylation (bacterial)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7031',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <i>N</i>-glycosylation (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-7031',
        Common_Name: 'protein <i>N</i>-glycosylation (bacterial)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7031',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <i>N</i>-glycosylation (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-7059',
        Common_Name: 'fumigaclavine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-7059',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|fumigaclavine biosynthesis',
    },
    {
        BioCyc_ID: 'CITRULBIO-PWY',
        Common_Name: 'L-citrulline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|Citrulline-Biosynthesis|CITRULBIO-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|L-citrulline Biosynthesis|L-citrulline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6324',
        Common_Name: 'rebeccamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6324',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|rebeccamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8045',
        Common_Name: 'cylindrospermopsin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-8045',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|cylindrospermopsin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8045',
        Common_Name: 'cylindrospermopsin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8045',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|cylindrospermopsin biosynthesis',
    },
    {
        BioCyc_ID: 'P2-PWY',
        Common_Name: 'citrate lyase activation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|P2-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|citrate lyase activation',
    },
    {
        BioCyc_ID: 'P344-PWY',
        Common_Name: 'superpathway of acrylonitrile degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Acrylonitrile-Degradation|P344-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Acrylonitrile Degradation|superpathway of acrylonitrile degradation',
    },
    {
        BioCyc_ID: 'P344-PWY',
        Common_Name: 'superpathway of acrylonitrile degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P344-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of acrylonitrile degradation',
    },
    {
        BioCyc_ID: 'PROTOCATECHUATE-ORTHO-CLEAVAGE-PWY',
        Common_Name: 'protocatechuate degradation II (ortho-cleavage pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Protocatechuate-Degradation|PROTOCATECHUATE-ORTHO-CLEAVAGE-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Protocatechuate Degradation|protocatechuate degradation II (ortho-cleavage pathway)',
    },
    {
        BioCyc_ID: 'PWY-6392',
        Common_Name: '<i>meso</i>-butanediol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Butanediol-Biosynthesis|PWY-6392',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Butanediol Biosynthesis|<i>meso</i>-butanediol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5874',
        Common_Name: 'heme degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Heme-Degradation|PWY-5874',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Heme Degradation|heme degradation I',
    },
    {
        BioCyc_ID: 'HYDROXYPRODEG-PWY',
        Common_Name: '<i>trans</i>-4-hydroxy-L-proline degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Hydroxyprolines-Degradation|HYDROXYPRODEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|hydroxy-L-proline Degradation|<i>trans</i>-4-hydroxy-L-proline degradation I',
    },
    {
        BioCyc_ID: 'PWY-7210',
        Common_Name: 'pyrimidine deoxyribonucleotides biosynthesis from CTP',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7210',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pyrimidine deoxyribonucleotides biosynthesis from CTP',
    },
    {
        BioCyc_ID: 'PWY-7210',
        Common_Name: 'pyrimidine deoxyribonucleotides biosynthesis from CTP',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7210',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|pyrimidine deoxyribonucleotides biosynthesis from CTP',
    },
    {
        BioCyc_ID: 'PWY-2161',
        Common_Name: 'folate polyglutamylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|PWY-2161',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|folate polyglutamylation',
    },
    {
        BioCyc_ID: 'PWY-2161',
        Common_Name: 'folate polyglutamylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|PWY-2161',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|folate polyglutamylation',
    },
    {
        BioCyc_ID: 'KDO-NAGLIPASYN-PWY',
        Common_Name: 'superpathway of (Kdo)<SUB>2</SUB>-lipid A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|KDO-NAGLIPASYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|superpathway of (Kdo)<SUB>2</SUB>-lipid A biosynthesis',
    },
    {
        BioCyc_ID: 'KDO-NAGLIPASYN-PWY',
        Common_Name: 'superpathway of (Kdo)<SUB>2</SUB>-lipid A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-NAGLIPASYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|superpathway of (Kdo)<SUB>2</SUB>-lipid A biosynthesis',
    },
    {
        BioCyc_ID: 'KDO-NAGLIPASYN-PWY',
        Common_Name: 'superpathway of (Kdo)<SUB>2</SUB>-lipid A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-NAGLIPASYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|superpathway of (Kdo)<SUB>2</SUB>-lipid A biosynthesis',
    },
    {
        BioCyc_ID: 'KDO-NAGLIPASYN-PWY',
        Common_Name: 'superpathway of (Kdo)<SUB>2</SUB>-lipid A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|KDO-NAGLIPASYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of (Kdo)<SUB>2</SUB>-lipid A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7921',
        Common_Name: 'protein <I>O</i>-mannosylation I (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7921',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <I>O</i>-mannosylation I (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7921',
        Common_Name: 'protein <I>O</i>-mannosylation I (yeast)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7921',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <I>O</i>-mannosylation I (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7921',
        Common_Name: 'protein <I>O</i>-mannosylation I (yeast)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7921',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <I>O</i>-mannosylation I (yeast)',
    },
    {
        BioCyc_ID: 'PWY-6852',
        Common_Name: 'senecionine N-oxide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PYRROLIZIDINE-ALKALOIDS|PWY-6852',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Pyrrolizidine Alkaloid Biosynthesis|senecionine N-oxide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5155',
        Common_Name: '&beta;-alanine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|Beta-Alanine-Biosynthesis|PWY-5155',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|&beta; Alanine Biosynthesis|&beta;-alanine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7018',
        Common_Name: 'paromomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7018',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|paromomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5707',
        Common_Name: 'propanethial <i>S</i>-oxide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-5707',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|propanethial <i>S</i>-oxide biosynthesis',
    },
    {
        BioCyc_ID: 'PROPFERM-PWY',
        Common_Name: 'superpathway of L-alanine fermentation (Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|PROPFERM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|superpathway of L-alanine fermentation (Stickland reaction)',
    },
    {
        BioCyc_ID: 'PROPFERM-PWY',
        Common_Name: 'superpathway of L-alanine fermentation (Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|PROPFERM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|superpathway of L-alanine fermentation (Stickland reaction)',
    },
    {
        BioCyc_ID: 'PROPFERM-PWY',
        Common_Name: 'superpathway of L-alanine fermentation (Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-propanoate|PROPFERM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Propanoate|superpathway of L-alanine fermentation (Stickland reaction)',
    },
    {
        BioCyc_ID: 'PROPFERM-PWY',
        Common_Name: 'superpathway of L-alanine fermentation (Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PROPFERM-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-alanine fermentation (Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8099',
        Common_Name: 'tetrahydropteridine recycling',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|PWY-8099',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|tetrahydropteridine recycling',
    },
    {
        BioCyc_ID: 'PWY-8287',
        Common_Name: 'carbofuran degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Carbofuran-Degradation|PWY-8287',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Carbofuran Degradation|carbofuran degradation II',
    },
    {
        BioCyc_ID: 'THIOREDOX-PWY',
        Common_Name: 'thioredoxin pathway',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|THIOREDOX-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|thioredoxin pathway',
    },
    {
        BioCyc_ID: 'PWY-7845',
        Common_Name: 'heme degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Heme-Degradation|PWY-7845',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Heme Degradation|heme degradation II',
    },
    {
        BioCyc_ID: 'PWY-7515',
        Common_Name: '<i>trans</i>-3-hydroxy-L-proline degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Hydroxyprolines-Degradation|PWY-7515',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|hydroxy-L-proline Degradation|<i>trans</i>-3-hydroxy-L-proline degradation',
    },
    {
        BioCyc_ID: 'PWY-7696',
        Common_Name: 'citreoisocoumarin and bikisocoumarin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7696',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|citreoisocoumarin and bikisocoumarin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7696',
        Common_Name: 'citreoisocoumarin and bikisocoumarin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7696',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|citreoisocoumarin and bikisocoumarin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6613',
        Common_Name: 'tetrahydrofolate salvage from 5,10-methenyltetrahydrofolate',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|PWY-6613',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|tetrahydrofolate salvage from 5,10-methenyltetrahydrofolate',
    },
    {
        BioCyc_ID: 'PWY-6613',
        Common_Name: 'tetrahydrofolate salvage from 5,10-methenyltetrahydrofolate',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|PWY-6613',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|tetrahydrofolate salvage from 5,10-methenyltetrahydrofolate',
    },
    {
        BioCyc_ID: 'PWY-6818',
        Common_Name: 'ornithine lipid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-6818',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|ornithine lipid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7433',
        Common_Name: 'mucin core 1 and core 2 <i>O</i>-glycosylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7433',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|mucin core 1 and core 2 <i>O</i>-glycosylation',
    },
    {
        BioCyc_ID: 'PWY-7433',
        Common_Name: 'mucin core 1 and core 2 <i>O</i>-glycosylation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7433',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|mucin core 1 and core 2 <i>O</i>-glycosylation',
    },
    {
        BioCyc_ID: 'PWY-7433',
        Common_Name: 'mucin core 1 and core 2 <i>O</i>-glycosylation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7433',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|mucin core 1 and core 2 <i>O</i>-glycosylation',
    },
    {
        BioCyc_ID: 'PWY-6991',
        Common_Name: '(-)-camphor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|Camphor-Biosynthesis|PWY-6991',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|Camphor Biosynthesis|(-)-camphor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3982',
        Common_Name: 'uracil degradation I (reductive)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|Beta-Alanine-Biosynthesis|PWY-3982',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|&beta; Alanine Biosynthesis|uracil degradation I (reductive)',
    },
    {
        BioCyc_ID: 'PWY-3982',
        Common_Name: 'uracil degradation I (reductive)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Base-Degradation|Uracil-Degradation|PWY-3982',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Nucleobase Degradation|Uracil Degradation|uracil degradation I (reductive)',
    },
    {
        BioCyc_ID: 'PWY-7547',
        Common_Name: 'prodigiosin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7547',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|prodigiosin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6539',
        Common_Name: '(<i>Z</i>)-phenylmethanethial <i>S</i>-oxide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-6539',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|(<i>Z</i>)-phenylmethanethial <i>S</i>-oxide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8096',
        Common_Name: '5-methoxybenzimidazole biosynthesis (anaerobic)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Lower-Ligand-Biosynthesis|PWY-8096',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Cobamide Lower Ligand Biosynthesis|5-methoxybenzimidazole biosynthesis (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5308',
        Common_Name: 'superpathway of sulfur metabolism (<i>Desulfocapsa sulfoexigens</i>)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|PWY-5308',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|superpathway of sulfur metabolism (<i>Desulfocapsa sulfoexigens</i>)',
    },
    {
        BioCyc_ID: 'PWY-5308',
        Common_Name: 'superpathway of sulfur metabolism (<i>Desulfocapsa sulfoexigens</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfite-Oxidation|PWY-5308',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfite Oxidation|superpathway of sulfur metabolism (<i>Desulfocapsa sulfoexigens</i>)',
    },
    {
        BioCyc_ID: 'PWY-5308',
        Common_Name: 'superpathway of sulfur metabolism (<i>Desulfocapsa sulfoexigens</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5308',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sulfur metabolism (<i>Desulfocapsa sulfoexigens</i>)',
    },
    {
        BioCyc_ID: 'PWY-5308',
        Common_Name: 'superpathway of sulfur metabolism (<i>Desulfocapsa sulfoexigens</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Disproportionation|PWY-5308',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Disproportionation|superpathway of sulfur metabolism (<i>Desulfocapsa sulfoexigens</i>)',
    },
    {
        BioCyc_ID: 'PWY-8286',
        Common_Name: 'carbofuran degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Carbofuran-Degradation|PWY-8286',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Carbofuran Degradation|carbofuran degradation I',
    },
    {
        BioCyc_ID: 'GLUTATHIONESYN-PWY',
        Common_Name: 'glutathione biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|GLUTATHIONESYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|glutathione biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7848',
        Common_Name: 'heme degradation VII',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Heme-Degradation|PWY-7848',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Heme Degradation|heme degradation VII',
    },
    {
        BioCyc_ID: 'PWY-5159',
        Common_Name: '<i>trans</i>-4-hydroxy-L-proline degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Hydroxyprolines-Degradation|PWY-5159',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|hydroxy-L-proline Degradation|<i>trans</i>-4-hydroxy-L-proline degradation II',
    },
    {
        BioCyc_ID: 'PWY-2201',
        Common_Name: 'folate transformations I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|Folate-Transformations|PWY-2201',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|Folate Transformations|folate transformations I',
    },
    {
        BioCyc_ID: 'PWY-2201',
        Common_Name: 'folate transformations I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|Folate-Transformations|PWY-2201',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|Folate Transformations|folate transformations I',
    },
    {
        BioCyc_ID: 'PWY-5045',
        Common_Name: 'pinosylvin metabolism',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-5045',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pinosylvin metabolism',
    },
    {
        BioCyc_ID: 'PWY-5045',
        Common_Name: 'pinosylvin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|STILBENE-PHYTOALEXINS|PWY-5045',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Stilbene Phytoalexin Biosynthesis|pinosylvin metabolism',
    },
    {
        BioCyc_ID: 'PWY-5045',
        Common_Name: 'pinosylvin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|STILBENE-SYN|PWY-5045',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Stilbene Biosynthesis|pinosylvin metabolism',
    },
    {
        BioCyc_ID: 'TRIGLSYN-PWY',
        Common_Name: 'diacylglycerol and triacylglycerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|TRIGLSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|diacylglycerol and triacylglycerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7981',
        Common_Name: '&alpha;-dystroglycan glycosylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7981',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|&alpha;-dystroglycan glycosylation',
    },
    {
        BioCyc_ID: 'PWY-7981',
        Common_Name: '&alpha;-dystroglycan glycosylation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7981',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|&alpha;-dystroglycan glycosylation',
    },
    {
        BioCyc_ID: 'PWY-7981',
        Common_Name: '&alpha;-dystroglycan glycosylation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7981',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|&alpha;-dystroglycan glycosylation',
    },
    {
        BioCyc_ID: 'PWY-6990',
        Common_Name: '(+)-camphor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|Camphor-Biosynthesis|PWY-6990',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|Camphor Biosynthesis|(+)-camphor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7694',
        Common_Name: 'zwittermicin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7694',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|zwittermicin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3981',
        Common_Name: '&beta;-alanine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|Beta-Alanine-Biosynthesis|PWY-3981',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|&beta; Alanine Biosynthesis|&beta;-alanine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8001',
        Common_Name: 'felinine and 3-methyl-3-sulfanylbutan-1-ol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-8001',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|felinine and 3-methyl-3-sulfanylbutan-1-ol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8095',
        Common_Name: '5-methoxy-6-methylbenzimidazole biosynthesis (anaerobic)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Lower-Ligand-Biosynthesis|PWY-8095',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Cobamide Lower Ligand Biosynthesis|5-methoxy-6-methylbenzimidazole biosynthesis (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5647',
        Common_Name: '2-nitrobenzoate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Nitroaromatic-Degradation|Nitrobenzoate-Degradation|2-Nirobenzoate-Degradation|PWY-5647',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Nitroaromatic Compound Degradation|Nitrobenzoate Degradation|2-Nitrobenzoate Degradation|2-nitrobenzoate degradation I',
    },
    {
        BioCyc_ID: 'PWY-5647',
        Common_Name: '2-nitrobenzoate degradation I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5647',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|2-nitrobenzoate degradation I',
    },
    {
        BioCyc_ID: 'PWY-8288',
        Common_Name: 'carbofuran degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Carbofuran-Degradation|PWY-8288',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Carbofuran Degradation|carbofuran degradation III',
    },
    {
        BioCyc_ID: 'PWY-6840',
        Common_Name: 'homoglutathione biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY-6840',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|homoglutathione biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6927',
        Common_Name: 'chlorophyll <i>a</i> degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Chlorophyll-A-Degradation|PWY-6927',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Chlorophyll <i>a</i> Degradation|chlorophyll <i>a</i> degradation II',
    },
    {
        BioCyc_ID: 'BETA-ALA-DEGRADATION-I-PWY',
        Common_Name: '&beta;-alanine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Beta-Alanine-Degradation|BETA-ALA-DEGRADATION-I-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|&beta;-Alanine Degradation|&beta;-alanine degradation I',
    },
    {
        BioCyc_ID: '1CMET2-PWY',
        Common_Name: 'folate transformations III (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|Folate-Transformations|1CMET2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|Folate Transformations|folate transformations III (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: '1CMET2-PWY',
        Common_Name: 'folate transformations III (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|Folate-Transformations|1CMET2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|Folate Transformations|folate transformations III (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-6733',
        Common_Name: 'sporopollenin precursors biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6733',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|sporopollenin precursors biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6733',
        Common_Name: 'sporopollenin precursors biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|PWY-6733',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|sporopollenin precursors biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-321',
        Common_Name: 'cutin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|EPIDERMAL-STRUCTURE|PWY-321',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|Epidermal Structures|cutin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-321',
        Common_Name: 'cutin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-321',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|cutin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7437',
        Common_Name: 'protein <i>O</i>-[<i>N</i>-acetyl]-glucosylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7437',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <i>O</i>-[<i>N</i>-acetyl]-glucosylation',
    },
    {
        BioCyc_ID: 'PWY-7437',
        Common_Name: 'protein <i>O</i>-[<i>N</i>-acetyl]-glucosylation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7437',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <i>O</i>-[<i>N</i>-acetyl]-glucosylation',
    },
    {
        BioCyc_ID: 'PWY-7437',
        Common_Name: 'protein <i>O</i>-[<i>N</i>-acetyl]-glucosylation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7437',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <i>O</i>-[<i>N</i>-acetyl]-glucosylation',
    },
    {
        BioCyc_ID: 'PWY-5063',
        Common_Name: 'phytyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|Phytyl-Diphosphate-Biosynthesis|PWY-5063',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Phytyl Diphosphate Biosynthesis|phytyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7716',
        Common_Name: 'penicillin G and penicillin V biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7716',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|penicillin G and penicillin V biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5760',
        Common_Name: '&beta;-alanine biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|Beta-Alanine-Biosynthesis|PWY-5760',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|&beta; Alanine Biosynthesis|&beta;-alanine biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-8356',
        Common_Name: 'maresin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Specialized-Proresolving-Mediators-Biosysthesis|PWY-8356',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Specialized Pro-resolving Mediators Biosynthesis|maresin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8097',
        Common_Name: '5-hydroxybenzimidazole biosynthesis (anaerobic)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Lower-Ligand-Biosynthesis|PWY-8097',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Cobamide Lower Ligand Biosynthesis|5-hydroxybenzimidazole biosynthesis (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-6270',
        Common_Name: 'isoprene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|PWY-6270',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|isoprene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6270',
        Common_Name: 'isoprene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6270',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|isoprene biosynthesis I',
    },
    {
        BioCyc_ID: 'TOLUENE-DEG-2-OH-PWY',
        Common_Name: 'toluene degradation to 2-hydroxypentadienoate I (<I>via</I> <i>o</I>-cresol)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|TOLUENE-DEG-2-OH-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation to 2-hydroxypentadienoate I (<I>via</I> <i>o</I>-cresol)',
    },
    {
        BioCyc_ID: 'PWY8J2-1',
        Common_Name: 'bacillithiol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY8J2-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|bacillithiol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5098',
        Common_Name: 'chlorophyll <i>a</i> degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Chlorophyll-A-Degradation|PWY-5098',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Chlorophyll <i>a</i> Degradation|chlorophyll <i>a</i> degradation I',
    },
    {
        BioCyc_ID: 'PWY-8120',
        Common_Name: '&beta;-alanine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Beta-Alanine-Degradation|PWY-8120',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|&beta;-Alanine Degradation|&beta;-alanine degradation III',
    },
    {
        BioCyc_ID: 'PWY-3841',
        Common_Name: 'folate transformations II (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Folate-Biosynthesis|Folate-Transformations|PWY-3841',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Folate Biosynthesis|Folate Transformations|folate transformations II (plants)',
    },
    {
        BioCyc_ID: 'PWY-3841',
        Common_Name: 'folate transformations II (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Folate-Biosynthesis|Folate-Transformations|PWY-3841',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Folate Biosynthesis|Folate Transformations|folate transformations II (plants)',
    },
    {
        BioCyc_ID: 'PWY-7261',
        Common_Name: 'anthocyanidin 3-malylglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin 3-malylglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-7261',
        Common_Name: 'anthocyanidin 3-malylglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin 3-malylglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-7261',
        Common_Name: 'anthocyanidin 3-malylglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|anthocyanidin 3-malylglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-7261',
        Common_Name: 'anthocyanidin 3-malylglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7261',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|anthocyanidin 3-malylglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-7741',
        Common_Name: 'phthiocerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-7741',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|phthiocerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7831',
        Common_Name: 'ABH and Lewis epitopes biosynthesis from type 2 precursor disaccharide',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7831',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|ABH and Lewis epitopes biosynthesis from type 2 precursor disaccharide',
    },
    {
        BioCyc_ID: 'PWY-7831',
        Common_Name: 'ABH and Lewis epitopes biosynthesis from type 2 precursor disaccharide',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7831',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|ABH and Lewis epitopes biosynthesis from type 2 precursor disaccharide',
    },
    {
        BioCyc_ID: 'PWY-7831',
        Common_Name: 'ABH and Lewis epitopes biosynthesis from type 2 precursor disaccharide',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7831',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|ABH and Lewis epitopes biosynthesis from type 2 precursor disaccharide',
    },
    {
        BioCyc_ID: 'PWY-7956',
        Common_Name: '3-[(<i>E</i>)-2-isocyanoethenyl]-1<i>H</i>-indole biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7956',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|3-[(<i>E</i>)-2-isocyanoethenyl]-1<i>H</i>-indole biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3941',
        Common_Name: '&beta;-alanine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|Beta-Alanine-Biosynthesis|PWY-3941',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|&beta; Alanine Biosynthesis|&beta;-alanine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY66-393',
        Common_Name: '15-<i>epi</i>-lipoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Specialized-Proresolving-Mediators-Biosysthesis|PWY66-393',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Specialized Pro-resolving Mediators Biosynthesis|15-<i>epi</i>-lipoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7378',
        Common_Name: 'aminopropanol phosphate biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Aminopropanol-Phosphate-Biosynthesis|PWY-7378',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Aminopropanol Phosphate Biosynthesis|aminopropanol phosphate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6630',
        Common_Name: 'superpathway of L-tyrosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6630',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-tyrosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6630',
        Common_Name: 'superpathway of L-tyrosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|TYROSINE-SYN|PWY-6630',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-tyrosine Biosynthesis|superpathway of L-tyrosine biosynthesis',
    },
    {
        BioCyc_ID: 'TOLUENE-DEG-CATECHOL-PWY',
        Common_Name: 'toluene degradation to benzoate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|TOLUENE-DEG-CATECHOL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation to benzoate',
    },
    {
        BioCyc_ID: 'GLUT-REDOX-PWY',
        Common_Name: 'glutathione-glutaredoxin redox reactions',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|GLUT-REDOX-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|glutathione-glutaredoxin redox reactions',
    },
    {
        BioCyc_ID: 'PWY-7164',
        Common_Name: 'chlorophyll <i>a</i> degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Tetrapyrrole-Degradation|Chlorophyll-A-Degradation|PWY-7164',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Tetrapyrrole Degradation|Chlorophyll <i>a</i> Degradation|chlorophyll <i>a</i> degradation III',
    },
    {
        BioCyc_ID: 'PWY-1781',
        Common_Name: '&beta;-alanine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|Beta-Alanine-Degradation|PWY-1781',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|&beta;-Alanine Degradation|&beta;-alanine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7801',
        Common_Name: 'N-end rule pathway I (prokaryotic)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7801',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|N-end rule pathway I (prokaryotic)',
    },
    {
        BioCyc_ID: 'PWY-7801',
        Common_Name: 'N-end rule pathway I (prokaryotic)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7801',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|N-end rule pathway I (prokaryotic)',
    },
    {
        BioCyc_ID: 'PWY-5143',
        Common_Name: 'long-chain fatty acid activation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|PWY-5143',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|long-chain fatty acid activation',
    },
    {
        BioCyc_ID: 'PWY-5143',
        Common_Name: 'long-chain fatty acid activation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-5143',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|long-chain fatty acid activation',
    },
    {
        BioCyc_ID: 'PWY-7837',
        Common_Name: 'i antigen and I antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7837',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|i antigen and I antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7837',
        Common_Name: 'i antigen and I antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7837',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|i antigen and I antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7837',
        Common_Name: 'i antigen and I antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7837',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|i antigen and I antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5034',
        Common_Name: 'gibberellin A<sub>12</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|PWY-5034',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|gibberellin A<sub>12</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5034',
        Common_Name: 'gibberellin A<sub>12</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|PWY-5034',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|gibberellin A<sub>12</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8336',
        Common_Name: 'valinomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8336',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|valinomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY8J2-22',
        Common_Name: 'kanosamine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|Kanosamine-Biosynthesis|PWY8J2-22',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Kanosamine Biosynthesis|kanosamine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY66-397',
        Common_Name: 'resolvin D biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Specialized-Proresolving-Mediators-Biosysthesis|PWY66-397',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Specialized Pro-resolving Mediators Biosynthesis|resolvin D biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5443',
        Common_Name: 'aminopropanol phosphate biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Aminopropanol-Phosphate-Biosynthesis|PWY-5443',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Aminopropanol Phosphate Biosynthesis|aminopropanol phosphate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8122',
        Common_Name: 'mycofactocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|PWY-8122',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|mycofactocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7149',
        Common_Name: 'superpathway of polymethylated quercetin/quercetagetin glucoside biosynthesis (Chrysosplenium)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7149',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|superpathway of polymethylated quercetin/quercetagetin glucoside biosynthesis (Chrysosplenium)',
    },
    {
        BioCyc_ID: 'PWY-7149',
        Common_Name: 'superpathway of polymethylated quercetin/quercetagetin glucoside biosynthesis (Chrysosplenium)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7149',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of polymethylated quercetin/quercetagetin glucoside biosynthesis (Chrysosplenium)',
    },
    {
        BioCyc_ID: 'PWY-5180',
        Common_Name: 'toluene degradation I (aerobic) (<I>via</I> <i>o</I>-cresol)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5180',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|toluene degradation I (aerobic) (<I>via</I> <i>o</I>-cresol)',
    },
    {
        BioCyc_ID: 'PWY-5180',
        Common_Name: 'toluene degradation I (aerobic) (<I>via</I> <i>o</I>-cresol)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-5180',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation I (aerobic) (<I>via</I> <i>o</I>-cresol)',
    },
    {
        BioCyc_ID: 'PWY-4081',
        Common_Name: 'glutathione-peroxide redox reactions',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY-4081',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|glutathione-peroxide redox reactions',
    },
    {
        BioCyc_ID: 'PWY-5922',
        Common_Name: '(4<i>R</i>)-carveol and (4<i>R</i>)-dihydrocarveol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Carveol-Degradation|PWY-5922',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Carveol Degradation|(4<i>R</i>)-carveol and (4<i>R</i>)-dihydrocarveol degradation',
    },
    {
        BioCyc_ID: 'PWY-5280',
        Common_Name: 'L-lysine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY-5280',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation IV',
    },
    {
        BioCyc_ID: 'PWY-5286',
        Common_Name: 'anthocyanidin sophoroside metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5286',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin sophoroside metabolism',
    },
    {
        BioCyc_ID: 'PWY-5286',
        Common_Name: 'anthocyanidin sophoroside metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5286',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin sophoroside metabolism',
    },
    {
        BioCyc_ID: 'PWY-5286',
        Common_Name: 'anthocyanidin sophoroside metabolism',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-5286',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|anthocyanidin sophoroside metabolism',
    },
    {
        BioCyc_ID: 'PWY-7744',
        Common_Name: 'dimycocerosyl phthiocerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-7744',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|dimycocerosyl phthiocerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6557',
        Common_Name: 'glycosaminoglycan-protein linkage region biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-6557',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|glycosaminoglycan-protein linkage region biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6557',
        Common_Name: 'glycosaminoglycan-protein linkage region biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-6557',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|glycosaminoglycan-protein linkage region biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6557',
        Common_Name: 'glycosaminoglycan-protein linkage region biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-6557',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|glycosaminoglycan-protein linkage region biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6653',
        Common_Name: '<i>ent</i> -kaurene biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|PWY-6653',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|<i>ent</i> -kaurene biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6653',
        Common_Name: '<i>ent</i> -kaurene biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|PWY-6653',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|<i>ent</i> -kaurene biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5757',
        Common_Name: 'fosfomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5757',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|fosfomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5978',
        Common_Name: 'kanosamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|Kanosamine-Biosynthesis|PWY-5978',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Kanosamine Biosynthesis|kanosamine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8355',
        Common_Name: 'peptido-conjugates in tissue regeneration biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Specialized-Proresolving-Mediators-Biosysthesis|PWY-8355',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Specialized Pro-resolving Mediators Biosynthesis|peptido-conjugates in tissue regeneration biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5254',
        Common_Name: 'methanofuran biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|PWY-5254',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|methanofuran biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7270',
        Common_Name: 'L-methionine salvage cycle II (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-Salvage|PWY-7270',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine Salvage|L-methionine salvage cycle II (plants)',
    },
    {
        BioCyc_ID: 'PWY-7270',
        Common_Name: 'L-methionine salvage cycle II (plants)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7270',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-methionine salvage cycle II (plants)',
    },
    {
        BioCyc_ID: 'PWY-5184',
        Common_Name: 'toluene degradation VI (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5184',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|toluene degradation VI (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5184',
        Common_Name: 'toluene degradation VI (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-5184',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation VI (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-6370',
        Common_Name: 'ascorbate recycling (cytosolic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY-6370',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|ascorbate recycling (cytosolic)',
    },
    {
        BioCyc_ID: 'PWY-5927',
        Common_Name: '(4<i>S</i>)-carveol and (4<i>S</i>)-dihydrocarveol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Carveol-Degradation|PWY-5927',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Carveol Degradation|(4<i>S</i>)-carveol and (4<i>S</i>)-dihydrocarveol degradation',
    },
    {
        BioCyc_ID: 'PWY-5311',
        Common_Name: 'L-lysine degradation VII',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY-5311',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation VII',
    },
    {
        BioCyc_ID: 'PWY-7424',
        Common_Name: 'sterol:steryl ester interconversion (yeast)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7424',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|sterol:steryl ester interconversion (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7424',
        Common_Name: 'sterol:steryl ester interconversion (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-7424',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|sterol:steryl ester interconversion (yeast)',
    },
    {
        BioCyc_ID: 'PWY-5884',
        Common_Name: 'wax esters biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-5884',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|wax esters biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7897',
        Common_Name: 'flavonoid di-C-glucosylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PWY-7897',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|flavonoid di-C-glucosylation',
    },
    {
        BioCyc_ID: 'PWY-7920',
        Common_Name: 'complex <i>N</i>-linked glycan biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7920',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|complex <i>N</i>-linked glycan biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-7920',
        Common_Name: 'complex <i>N</i>-linked glycan biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7920',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|complex <i>N</i>-linked glycan biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-7920',
        Common_Name: 'complex <i>N</i>-linked glycan biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7920',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|complex <i>N</i>-linked glycan biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-5032',
        Common_Name: '<i>ent</i>-kaurene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|PWY-5032',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|<i>ent</i>-kaurene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5032',
        Common_Name: '<i>ent</i>-kaurene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|PWY-5032',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|<i>ent</i>-kaurene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6511',
        Common_Name: '3-methylarginine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6511',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|3-methylarginine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY18C3-15',
        Common_Name: 'acylsucrose biosynthesis (<i>Solanum pennellii</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|ACYLSUGAR-BIOSYNTHESIS|PWY18C3-15',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Acylsugar biosynthesis|acylsucrose biosynthesis (<i>Solanum pennellii</i>)',
    },
    {
        BioCyc_ID: 'PWY66-392',
        Common_Name: 'lipoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Specialized-Proresolving-Mediators-Biosysthesis|PWY66-392',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Specialized Pro-resolving Mediators Biosynthesis|lipoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5509',
        Common_Name: 'adenosylcobalamin biosynthesis from adenosylcobinamide-GDP I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-5509',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|adenosylcobalamin biosynthesis from adenosylcobinamide-GDP I',
    },
    {
        BioCyc_ID: 'P241-PWY',
        Common_Name: 'coenzyme B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|P241-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|coenzyme B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7392',
        Common_Name: 'taxadiene biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7392',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|taxadiene biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7392',
        Common_Name: 'taxadiene biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7392',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|taxadiene biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-81',
        Common_Name: 'toluene degradation to benzoyl-CoA (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-81',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation to benzoyl-CoA (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY1G-126',
        Common_Name: 'mycothiol oxidation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY1G-126',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|mycothiol oxidation',
    },
    {
        BioCyc_ID: 'PWY-5924',
        Common_Name: 'limonene degradation II (L-limonene)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Limonene-Degradation|PWY-5924',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Limonene Degradation|limonene degradation II (L-limonene)',
    },
    {
        BioCyc_ID: 'PWY66-425',
        Common_Name: 'L-lysine degradation II (L-pipecolate pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY66-425',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation II (L-pipecolate pathway)',
    },
    {
        BioCyc_ID: 'PWY-8181',
        Common_Name: '2-methyl-branched fatty acid &beta;-oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Fatty-Acid-Degradation|PWY-8181',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Fatty Acid Degradation|2-methyl-branched fatty acid &beta;-oxidation',
    },
    {
        BioCyc_ID: 'PWY-8181',
        Common_Name: '2-methyl-branched fatty acid &beta;-oxidation',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-8181',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|2-methyl-branched fatty acid &beta;-oxidation',
    },
    {
        BioCyc_ID: 'PWY-8082',
        Common_Name: 'glycine lipid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-8082',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|glycine lipid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6325',
        Common_Name: 'echinatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PWY-6325',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|echinatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7426',
        Common_Name: 'complex <i>N</i>-linked glycan biosynthesis (vertebrates)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7426',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|complex <i>N</i>-linked glycan biosynthesis (vertebrates)',
    },
    {
        BioCyc_ID: 'PWY-7426',
        Common_Name: 'complex <i>N</i>-linked glycan biosynthesis (vertebrates)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7426',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|complex <i>N</i>-linked glycan biosynthesis (vertebrates)',
    },
    {
        BioCyc_ID: 'PWY-7426',
        Common_Name: 'complex <i>N</i>-linked glycan biosynthesis (vertebrates)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7426',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|complex <i>N</i>-linked glycan biosynthesis (vertebrates)',
    },
    {
        BioCyc_ID: 'PWY-5413',
        Common_Name: 'neoabietic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|Resin-Acids-Biosynthesis|PWY-5413',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Resin Acid Biosynthesis|neoabietic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7570',
        Common_Name: 'blasticidin S biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7570',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|blasticidin S biosynthesis',
    },
    {
        BioCyc_ID: 'PWY18C3-8',
        Common_Name: 'monoacylsucrose biosynthesis (<i>Solanum</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|ACYLSUGAR-BIOSYNTHESIS|PWY18C3-8',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Acylsugar biosynthesis|monoacylsucrose biosynthesis (<i>Solanum</i>)',
    },
    {
        BioCyc_ID: 'PWY66-395',
        Common_Name: 'aspirin triggered resolvin D biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Specialized-Proresolving-Mediators-Biosysthesis|PWY66-395',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Specialized Pro-resolving Mediators Biosynthesis|aspirin triggered resolvin D biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7964',
        Common_Name: 'adeninyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7964',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|adeninyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'PWY-8346',
        Common_Name: 'GABA shunt II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|4-Aminobutyraye-Degradation|PWY-8346',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|4-Aminobutanoate Degradation|GABA shunt II',
    },
    {
        BioCyc_ID: 'PWY-8346',
        Common_Name: 'GABA shunt II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|PWY-8346',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|GABA shunt II',
    },
    {
        BioCyc_ID: 'PWY-8346',
        Common_Name: 'GABA shunt II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8346',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|GABA shunt II',
    },
    {
        BioCyc_ID: 'TOLUENE-DEG-4-OH-PWY',
        Common_Name: 'toluene degradation to 4-methylphenol',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|TOLUENE-DEG-4-OH-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation to 4-methylphenol',
    },
    {
        BioCyc_ID: 'PWY-4181',
        Common_Name: 'glutathione amide metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY-4181',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|glutathione amide metabolism',
    },
    {
        BioCyc_ID: 'PWY-4781',
        Common_Name: 'phytate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|Phytate-Degradation|PWY-4781',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|Phytate Degradation|phytate degradation II',
    },
    {
        BioCyc_ID: 'PWY-5923',
        Common_Name: 'limonene degradation I (D-limonene)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Limonene-Degradation|PWY-5923',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Limonene Degradation|limonene degradation I (D-limonene)',
    },
    {
        BioCyc_ID: 'P163-PWY',
        Common_Name: 'L-lysine fermentation to acetate and butanoate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|P163-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|L-lysine fermentation to acetate and butanoate',
    },
    {
        BioCyc_ID: 'P163-PWY',
        Common_Name: 'L-lysine fermentation to acetate and butanoate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetyl-CoA-Butyrate|P163-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Butanoate|L-lysine fermentation to acetate and butanoate',
    },
    {
        BioCyc_ID: 'P163-PWY',
        Common_Name: 'L-lysine fermentation to acetate and butanoate',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|P163-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine fermentation to acetate and butanoate',
    },
    {
        BioCyc_ID: 'PWY-6029',
        Common_Name: '2,3-<i>trans</i>-flavanols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PWY-6029',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|2,3-<i>trans</i>-flavanols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6029',
        Common_Name: '2,3-<i>trans</i>-flavanols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6029',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|2,3-<i>trans</i>-flavanols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6453',
        Common_Name: 'stigma estolide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-6453',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|stigma estolide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7397',
        Common_Name: 'naringenin biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PWY-7397',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|naringenin biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7979',
        Common_Name: 'protein <I>O</i>-mannosylation III (mammals, core M3)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7979',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <I>O</i>-mannosylation III (mammals, core M3)',
    },
    {
        BioCyc_ID: 'PWY-7979',
        Common_Name: 'protein <I>O</i>-mannosylation III (mammals, core M3)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7979',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <I>O</i>-mannosylation III (mammals, core M3)',
    },
    {
        BioCyc_ID: 'PWY-7979',
        Common_Name: 'protein <I>O</i>-mannosylation III (mammals, core M3)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7979',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <I>O</i>-mannosylation III (mammals, core M3)',
    },
    {
        BioCyc_ID: 'PWY-5421',
        Common_Name: 'dehydroabietic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|Resin-Acids-Biosynthesis|PWY-5421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Resin Acid Biosynthesis|dehydroabietic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7734',
        Common_Name: 'quinoxaline-2-carboxylate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7734',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|quinoxaline-2-carboxylate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY18C3-16',
        Common_Name: 'acylsucrose biosynthesis (<i>Solanum habrochaites</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|ACYLSUGAR-BIOSYNTHESIS|PWY18C3-16',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Acylsugar biosynthesis|acylsucrose biosynthesis (<i>Solanum habrochaites</i>)',
    },
    {
        BioCyc_ID: 'PWY-8357',
        Common_Name: 'protectin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Specialized-Proresolving-Mediators-Biosysthesis|PWY-8357',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Specialized Pro-resolving Mediators Biosynthesis|protectin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7967',
        Common_Name: '5-methoxybenzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7967',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|5-methoxybenzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'PWY66-5',
        Common_Name: 'superpathway of cholesterol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY66-5',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|superpathway of cholesterol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-5',
        Common_Name: 'superpathway of cholesterol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY66-5',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of cholesterol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5179',
        Common_Name: 'toluene degradation V (aerobic) (<I>via</I> toluene-<I>cis</I>-diol)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5179',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|toluene degradation V (aerobic) (<I>via</I> toluene-<I>cis</I>-diol)',
    },
    {
        BioCyc_ID: 'PWY-5179',
        Common_Name: 'toluene degradation V (aerobic) (<I>via</I> toluene-<I>cis</I>-diol)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-5179',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation V (aerobic) (<I>via</I> toluene-<I>cis</I>-diol)',
    },
    {
        BioCyc_ID: 'PWY1G-0',
        Common_Name: 'mycothiol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY1G-0',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|mycothiol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4702',
        Common_Name: 'phytate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|Phytate-Degradation|PWY-4702',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|Phytate Degradation|phytate degradation I',
    },
    {
        BioCyc_ID: 'PWY-8029',
        Common_Name: 'limonene degradation IV (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Limonene-Degradation|PWY-8029',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Limonene Degradation|limonene degradation IV (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5298',
        Common_Name: 'L-lysine degradation VI',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY-5298',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation VI',
    },
    {
        BioCyc_ID: 'PWY-6829',
        Common_Name: 'tRNA methylation (yeast)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6829',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|tRNA methylation (yeast)',
    },
    {
        BioCyc_ID: 'PWY-6829',
        Common_Name: 'tRNA methylation (yeast)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-6829',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA methylation (yeast)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-7',
        Common_Name: '(Kdo)<sub>2</sub>-lipid A biosynthesis I (<i>Brucella</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY2B4Q-7',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|(Kdo)<sub>2</sub>-lipid A biosynthesis I (<i>Brucella</i>)',
    },
    {
        BioCyc_ID: 'PWY-6232',
        Common_Name: 'chrysoeriol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PWY-6232',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|chrysoeriol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7435',
        Common_Name: 'mucin core 3 and core 4 <i>O</i>-glycosylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7435',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|mucin core 3 and core 4 <i>O</i>-glycosylation',
    },
    {
        BioCyc_ID: 'PWY-7435',
        Common_Name: 'mucin core 3 and core 4 <i>O</i>-glycosylation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7435',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|mucin core 3 and core 4 <i>O</i>-glycosylation',
    },
    {
        BioCyc_ID: 'PWY-7435',
        Common_Name: 'mucin core 3 and core 4 <i>O</i>-glycosylation',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7435',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|mucin core 3 and core 4 <i>O</i>-glycosylation',
    },
    {
        BioCyc_ID: 'PWY-5412',
        Common_Name: 'levopimaric acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|Resin-Acids-Biosynthesis|PWY-5412',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Resin Acid Biosynthesis|levopimaric acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8033',
        Common_Name: 'aureothin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8033',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|aureothin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY18C3-9',
        Common_Name: 'diacylsucrose biosynthesis (<i>Solanum</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|ACYLSUGAR-BIOSYNTHESIS|PWY18C3-9',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Acylsugar biosynthesis|diacylsucrose biosynthesis (<i>Solanum</i>)',
    },
    {
        BioCyc_ID: 'PWY66-394',
        Common_Name: 'aspirin triggered resolvin E biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Specialized-Proresolving-Mediators-Biosysthesis|PWY66-394',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Specialized Pro-resolving Mediators Biosynthesis|aspirin triggered resolvin E biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7970',
        Common_Name: 'benzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7970',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|benzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'ARGDEG-PWY',
        Common_Name: 'superpathway of L-arginine, putrescine, and 4-aminobutanoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ARGDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|superpathway of L-arginine, putrescine, and 4-aminobutanoate degradation',
    },
    {
        BioCyc_ID: 'ARGDEG-PWY',
        Common_Name: 'superpathway of L-arginine, putrescine, and 4-aminobutanoate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ARGDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-arginine, putrescine, and 4-aminobutanoate degradation',
    },
    {
        BioCyc_ID: 'PWY-5183',
        Common_Name: 'superpathway of aerobic toluene degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5183',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of aerobic toluene degradation',
    },
    {
        BioCyc_ID: 'PWY-5183',
        Common_Name: 'superpathway of aerobic toluene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-5183',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|superpathway of aerobic toluene degradation',
    },
    {
        BioCyc_ID: 'PWY-6519',
        Common_Name: '8-amino-7-oxononanoate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|7-Keto-8-aminopelargonate-Biosynthesis|PWY-6519',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|8-Amino-7-oxononanoate Biosynthesis|8-amino-7-oxononanoate biosynthesis I',
    },
    {
        BioCyc_ID: 'SHIKIMATEDEG-PWY',
        Common_Name: 'shikimate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|Shikimate-Degradation|SHIKIMATEDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|Shikimate Degradation|shikimate degradation I',
    },
    {
        BioCyc_ID: 'PWY-6526',
        Common_Name: 'limonene degradation III (to perillate)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Limonene-Degradation|PWY-6526',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Limonene Degradation|limonene degradation III (to perillate)',
    },
    {
        BioCyc_ID: 'PWY-5324',
        Common_Name: 'L-lysine degradation IX',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY-5324',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation IX',
    },
    {
        BioCyc_ID: 'PWY-7541',
        Common_Name: '1,2-propanediol biosynthesis from lactate (engineered)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|PWY-7541',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|1,2-propanediol biosynthesis from lactate (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7541',
        Common_Name: '1,2-propanediol biosynthesis from lactate (engineered)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7541',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|1,2-propanediol biosynthesis from lactate (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6804',
        Common_Name: 'diacylglycerol biosynthesis (PUFA enrichment in oilseed)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-6804',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|diacylglycerol biosynthesis (PUFA enrichment in oilseed)',
    },
    {
        BioCyc_ID: 'PWY-6787',
        Common_Name: 'flavonoid biosynthesis (in equisetum)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PWY-6787',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|flavonoid biosynthesis (in equisetum)',
    },
    {
        BioCyc_ID: 'PWY-8323',
        Common_Name: 'protein <i>N</i>-glycosylation processing of mannoproteins (<i>cis-Golgi</i>, yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-8323',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <i>N</i>-glycosylation processing of mannoproteins (<i>cis-Golgi</i>, yeast)',
    },
    {
        BioCyc_ID: 'PWY-8323',
        Common_Name: 'protein <i>N</i>-glycosylation processing of mannoproteins (<i>cis-Golgi</i>, yeast)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-8323',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <i>N</i>-glycosylation processing of mannoproteins (<i>cis-Golgi</i>, yeast)',
    },
    {
        BioCyc_ID: 'PWY-8323',
        Common_Name: 'protein <i>N</i>-glycosylation processing of mannoproteins (<i>cis-Golgi</i>, yeast)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-8323',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <i>N</i>-glycosylation processing of mannoproteins (<i>cis-Golgi</i>, yeast)',
    },
    {
        BioCyc_ID: 'PWY-5411',
        Common_Name: 'abietic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|Resin-Acids-Biosynthesis|PWY-5411',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Resin Acid Biosynthesis|abietic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8366',
        Common_Name: 'septacidin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8366',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|septacidin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5409',
        Common_Name: 'divinyl ether biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|FATTY-ACID-DERIVATIVE-SYN|Divinyl-Ether-Biosynthesis|PWY-5409',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Fatty Acid Derivative Biosynthesis|Divinyl Ether Biosynthesis|divinyl ether biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7963',
        Common_Name: '4-methylphenyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7963',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|4-methylphenyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'FERMENTATION-PWY',
        Common_Name: 'mixed acid fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|FERMENTATION-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|mixed acid fermentation',
    },
    {
        BioCyc_ID: 'FERMENTATION-PWY',
        Common_Name: 'mixed acid fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Ethanol-Fermentation|FERMENTATION-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Ethanol|mixed acid fermentation',
    },
    {
        BioCyc_ID: 'FERMENTATION-PWY',
        Common_Name: 'mixed acid fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Ethanol-Fermentation|FERMENTATION-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Ethanol|mixed acid fermentation',
    },
    {
        BioCyc_ID: 'FERMENTATION-PWY',
        Common_Name: 'mixed acid fermentation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|FERMENTATION-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|mixed acid fermentation',
    },
    {
        BioCyc_ID: 'PWY-7703',
        Common_Name: '2,4-xylenol degradation to protocatechuate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-7703',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|2,4-xylenol degradation to protocatechuate',
    },
    {
        BioCyc_ID: 'PWY-8203',
        Common_Name: '8-amino-7-oxononanoate biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|7-Keto-8-aminopelargonate-Biosynthesis|PWY-8203',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|8-Amino-7-oxononanoate Biosynthesis|8-amino-7-oxononanoate biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-6419',
        Common_Name: 'shikimate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|Shikimate-Degradation|PWY-6419',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|Shikimate Degradation|shikimate degradation II',
    },
    {
        BioCyc_ID: 'PWY-6989',
        Common_Name: '(<i>S</i>)-camphor degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Camphor-Degradation|PWY-6989',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Camphor Degradation|(<i>S</i>)-camphor degradation',
    },
    {
        BioCyc_ID: 'PWY0-461',
        Common_Name: 'L-lysine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY0-461',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation I',
    },
    {
        BioCyc_ID: 'PWY18C3-11',
        Common_Name: 'tetraacylsucrose biosynthesis (<i>Solanum</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|ACYLSUGAR-BIOSYNTHESIS|PWY18C3-11',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Acylsugar biosynthesis|tetraacylsucrose biosynthesis (<i>Solanum</i>)',
    },
    {
        BioCyc_ID: 'PWY18C3-11',
        Common_Name: 'tetraacylsucrose biosynthesis (<i>Solanum</i>)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY18C3-11',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|tetraacylsucrose biosynthesis (<i>Solanum</i>)',
    },
    {
        BioCyc_ID: 'SOPHOROSYLOXYDOCOSANOATE-SYN-PWY',
        Common_Name: 'sophorolipid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|SOPHOROSYLOXYDOCOSANOATE-SYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|sophorolipid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY1F-FLAVSYN',
        Common_Name: 'flavonoid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PWY1F-FLAVSYN',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|flavonoid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7661',
        Common_Name: 'protein <I>N</i>-glycosylation (<i>Haloferax volcanii</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7661',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <I>N</i>-glycosylation (<i>Haloferax volcanii</i>)',
    },
    {
        BioCyc_ID: 'PWY-7661',
        Common_Name: 'protein <I>N</i>-glycosylation (<i>Haloferax volcanii</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7661',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <I>N</i>-glycosylation (<i>Haloferax volcanii</i>)',
    },
    {
        BioCyc_ID: 'PWY-7661',
        Common_Name: 'protein <I>N</i>-glycosylation (<i>Haloferax volcanii</i>)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7661',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <I>N</i>-glycosylation (<i>Haloferax volcanii</i>)',
    },
    {
        BioCyc_ID: 'PWY-5414',
        Common_Name: 'palustric acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|Resin-Acids-Biosynthesis|PWY-5414',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Resin Acid Biosynthesis|palustric acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5818',
        Common_Name: 'validamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5818',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|validamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7966',
        Common_Name: '5-methoxy-6-methylbenzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7966',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|5-methoxy-6-methylbenzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'P41-PWY',
        Common_Name: 'pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|P41-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
    },
    {
        BioCyc_ID: 'P41-PWY',
        Common_Name: 'pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|P41-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
    },
    {
        BioCyc_ID: 'P41-PWY',
        Common_Name: 'pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-Lactate|Pyruvate-Lactate-Fermentation|P41-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Lactate|Pyruvate Fermentation to Lactate|pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
    },
    {
        BioCyc_ID: 'P41-PWY',
        Common_Name: 'pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Lactate-Fermentation|P41-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Lactate|pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
    },
    {
        BioCyc_ID: 'P41-PWY',
        Common_Name: 'pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P41-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate and (<i>S</i>)-lactate I',
    },
    {
        BioCyc_ID: 'TOLUENE-DEG-3-OH-PWY',
        Common_Name: 'toluene degradation II (aerobic) (<I>via 4-methylcatechol</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|TOLUENE-DEG-3-OH-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation II (aerobic) (<I>via 4-methylcatechol</i>)',
    },
    {
        BioCyc_ID: 'PWY-7147',
        Common_Name: '8-amino-7-oxononanoate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|7-Keto-8-aminopelargonate-Biosynthesis|PWY-7147',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|8-Amino-7-oxononanoate Biosynthesis|8-amino-7-oxononanoate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6416',
        Common_Name: 'quinate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|Quinate-Degradation|PWY-6416',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|Quinate Degradation|quinate degradation II',
    },
    {
        BioCyc_ID: 'P601-PWY',
        Common_Name: '(<i>R</i>)-camphor degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Camphor-Degradation|P601-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Camphor Degradation|(<i>R</i>)-camphor degradation',
    },
    {
        BioCyc_ID: 'LYSINE-DEG1-PWY',
        Common_Name: 'L-lysine degradation XI',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|LYSINE-DEG1-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation XI',
    },
    {
        BioCyc_ID: 'PWY-1121',
        Common_Name: 'suberin monomers biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-1121',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|suberin monomers biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1121',
        Common_Name: 'suberin monomers biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|PWY-1121',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|suberin monomers biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-282',
        Common_Name: 'cuticular wax biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|EPIDERMAL-STRUCTURE|PWY-282',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|Epidermal Structures|cuticular wax biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-282',
        Common_Name: 'cuticular wax biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-282',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|cuticular wax biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6515',
        Common_Name: 'phloridzin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|PWY-6515',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|phloridzin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7833',
        Common_Name: 'biosynthesis of Lewis epitopes (H. pylori)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7833',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|biosynthesis of Lewis epitopes (H. pylori)',
    },
    {
        BioCyc_ID: 'PWY-7833',
        Common_Name: 'biosynthesis of Lewis epitopes (H. pylori)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7833',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|biosynthesis of Lewis epitopes (H. pylori)',
    },
    {
        BioCyc_ID: 'PWY-7833',
        Common_Name: 'biosynthesis of Lewis epitopes (H. pylori)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7833',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|biosynthesis of Lewis epitopes (H. pylori)',
    },
    {
        BioCyc_ID: 'PWY-5422',
        Common_Name: 'isopimaric acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|Resin-Acids-Biosynthesis|PWY-5422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Resin Acid Biosynthesis|isopimaric acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6682',
        Common_Name: 'dehydrophos biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6682',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|dehydrophos biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7391',
        Common_Name: 'isoprene biosynthesis II (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|PWY-7391',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|isoprene biosynthesis II (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7969',
        Common_Name: '5-hydroxybenzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7969',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|5-hydroxybenzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'PWY-1541',
        Common_Name: 'superpathway of taurine degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-1541',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of taurine degradation',
    },
    {
        BioCyc_ID: 'PWY-1541',
        Common_Name: 'superpathway of taurine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Taurine-degradation|PWY-1541',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Taurine Degradation|superpathway of taurine degradation',
    },
    {
        BioCyc_ID: 'PWY-1541',
        Common_Name: 'superpathway of taurine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Taurine-degradation|PWY-1541',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Taurine Degradation|superpathway of taurine degradation',
    },
    {
        BioCyc_ID: 'TOLUENE-DEG-DIOL-PWY',
        Common_Name: 'toluene degradation to 2-hydroxypentadienoate (<I>via</I> toluene-<I>cis</I>-diol)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|TOLUENE-DEG-DIOL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation to 2-hydroxypentadienoate (<I>via</I> toluene-<I>cis</I>-diol)',
    },
    {
        BioCyc_ID: 'PWY-6578',
        Common_Name: '8-amino-7-oxononanoate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|7-Keto-8-aminopelargonate-Biosynthesis|PWY-6578',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|8-Amino-7-oxononanoate Biosynthesis|8-amino-7-oxononanoate biosynthesis III',
    },
    {
        BioCyc_ID: 'QUINATEDEG-PWY',
        Common_Name: 'quinate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|Quinate-Degradation|QUINATEDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|Quinate Degradation|quinate degradation I',
    },
    {
        BioCyc_ID: 'PWY-5283',
        Common_Name: 'L-lysine degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY-5283',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation V',
    },
    {
        BioCyc_ID: 'PWY-6494',
        Common_Name: 'gibberellin inactivation III (epoxidation)',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|GIBBERELLINS-DEGRADATION|PWY-6494',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Gibberellin Degradation|gibberellin inactivation III (epoxidation)',
    },
    {
        BioCyc_ID: 'PWY-6494',
        Common_Name: 'gibberellin inactivation III (epoxidation)',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Gibberellin-Inactivation|PWY-6494',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Gibberellin Inactivation|gibberellin inactivation III (epoxidation)',
    },
    {
        BioCyc_ID: 'PWY-6494',
        Common_Name: 'gibberellin inactivation III (epoxidation)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6494',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|gibberellin inactivation III (epoxidation)',
    },
    {
        BioCyc_ID: 'CYCLOHEXANOL-OXIDATION-PWY',
        Common_Name: 'cyclohexanol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|CYCLOHEXANOL-OXIDATION-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|cyclohexanol degradation',
    },
    {
        BioCyc_ID: 'PWY-7740',
        Common_Name: '&beta;-D-mannosyl phosphomycoketide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-7740',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|&beta;-D-mannosyl phosphomycoketide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7632',
        Common_Name: 'hinokiresinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|NORLIGNANS|PWY-7632',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Norlignan Biosynthesis|hinokiresinol biosynthesis',
    },
    {
        BioCyc_ID: 'MANNOSYL-CHITO-DOLICHOL-BIOSYNTHESIS',
        Common_Name: 'protein <i>N</i>-glycosylation initial phase (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|MANNOSYL-CHITO-DOLICHOL-BIOSYNTHESIS',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|protein <i>N</i>-glycosylation initial phase (eukaryotic)',
    },
    {
        BioCyc_ID: 'MANNOSYL-CHITO-DOLICHOL-BIOSYNTHESIS',
        Common_Name: 'protein <i>N</i>-glycosylation initial phase (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|MANNOSYL-CHITO-DOLICHOL-BIOSYNTHESIS',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <i>N</i>-glycosylation initial phase (eukaryotic)',
    },
    {
        BioCyc_ID: 'MANNOSYL-CHITO-DOLICHOL-BIOSYNTHESIS',
        Common_Name: 'protein <i>N</i>-glycosylation initial phase (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|MANNOSYL-CHITO-DOLICHOL-BIOSYNTHESIS',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <i>N</i>-glycosylation initial phase (eukaryotic)',
    },
    {
        BioCyc_ID: 'MANNOSYL-CHITO-DOLICHOL-BIOSYNTHESIS',
        Common_Name: 'protein <i>N</i>-glycosylation initial phase (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|MANNOSYL-CHITO-DOLICHOL-BIOSYNTHESIS',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <i>N</i>-glycosylation initial phase (eukaryotic)',
    },
    {
        BioCyc_ID: 'PWY-7274',
        Common_Name: 'D-cycloserine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7274',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|D-cycloserine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6913',
        Common_Name: 'methylbutenol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|PWY-6913',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|methylbutenol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7961',
        Common_Name: 'phenyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7961',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|phenyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'PWY-4821',
        Common_Name: 'UDP-&alpha;-D-xylose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-4821',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|UDP-&alpha;-D-xylose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4821',
        Common_Name: 'UDP-&alpha;-D-xylose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-4821',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&alpha;-D-xylose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5178',
        Common_Name: 'toluene degradation IV (aerobic) (via catechol)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5178',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|toluene degradation IV (aerobic) (via catechol)',
    },
    {
        BioCyc_ID: 'PWY-5178',
        Common_Name: 'toluene degradation IV (aerobic) (via catechol)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-5178',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation IV (aerobic) (via catechol)',
    },
    {
        BioCyc_ID: 'PWY-7133',
        Common_Name: 'quercetin glucoside degradation (<i>Allium</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|FLAVONOID-DEG|PWY-7133',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Flavonoid Degradation|quercetin glucoside degradation (<i>Allium</i>)',
    },
    {
        BioCyc_ID: 'PWY-6411',
        Common_Name: 'ginsenoside degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Ginsenoside-Degradation|PWY-6411',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Ginsenoside Degradation|ginsenoside degradation I',
    },
    {
        BioCyc_ID: 'PWY-5314',
        Common_Name: 'L-lysine degradation VIII',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY-5314',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation VIII',
    },
    {
        BioCyc_ID: 'PWY-7013',
        Common_Name: '(<i>S</i>)-propane-1,2-diol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|PWY-7013',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|(<i>S</i>)-propane-1,2-diol degradation',
    },
    {
        BioCyc_ID: 'PWY-7013',
        Common_Name: '(<i>S</i>)-propane-1,2-diol degradation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-propanoate|PWY-7013',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Propanoate|(<i>S</i>)-propane-1,2-diol degradation',
    },
    {
        BioCyc_ID: 'PWY-7184',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7184',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7184',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-7184',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis I",
    },
    {
        BioCyc_ID: 'PWY-7184',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-7184',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5142',
        Common_Name: 'acyl-[acyl-carrier protein] thioesterase pathway',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-5142',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|acyl-[acyl-carrier protein] thioesterase pathway',
    },
    {
        BioCyc_ID: 'PWY-6665',
        Common_Name: 'pterostilbene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|STILBENE-SYN|PWY-6665',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Stilbene Biosynthesis|pterostilbene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7919',
        Common_Name: 'protein <i>N</i>-glycosylation processing phase (plants and animals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7919',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <i>N</i>-glycosylation processing phase (plants and animals)',
    },
    {
        BioCyc_ID: 'PWY-7919',
        Common_Name: 'protein <i>N</i>-glycosylation processing phase (plants and animals)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7919',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <i>N</i>-glycosylation processing phase (plants and animals)',
    },
    {
        BioCyc_ID: 'PWY-7919',
        Common_Name: 'protein <i>N</i>-glycosylation processing phase (plants and animals)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7919',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <i>N</i>-glycosylation processing phase (plants and animals)',
    },
    {
        BioCyc_ID: 'PWY-7769',
        Common_Name: 'phosalacine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7769',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|phosalacine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8084',
        Common_Name: 'daurichromenate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|PWY-8084',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|daurichromenate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7965',
        Common_Name: '2-methyladeninyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7965',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|2-methyladeninyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'PWY-5313',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from cyanidin and cyanidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5313',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|superpathway of anthocyanin biosynthesis (from cyanidin and cyanidin 3-<i>O</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5313',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from cyanidin and cyanidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5313',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|superpathway of anthocyanin biosynthesis (from cyanidin and cyanidin 3-<i>O</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5313',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from cyanidin and cyanidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-5313',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|superpathway of anthocyanin biosynthesis (from cyanidin and cyanidin 3-<i>O</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5313',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from cyanidin and cyanidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5313',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of anthocyanin biosynthesis (from cyanidin and cyanidin 3-<i>O</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5181',
        Common_Name: 'toluene degradation III (aerobic) (via <i>p</i>-cresol)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5181',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|toluene degradation III (aerobic) (via <i>p</i>-cresol)',
    },
    {
        BioCyc_ID: 'PWY-5181',
        Common_Name: 'toluene degradation III (aerobic) (via <i>p</i>-cresol)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-5181',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|toluene degradation III (aerobic) (via <i>p</i>-cresol)',
    },
    {
        BioCyc_ID: 'PWY-5339',
        Common_Name: "chalcone 2'-<i>O</i>-glucoside biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|CHALCONE-SYN|PWY-5339',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Chalcone Biosynthesis|chalcone 2'-<i>O</i>-glucoside biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-5339',
        Common_Name: "chalcone 2'-<i>O</i>-glucoside biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-5339',
        MetaCyc_hierarchy_Names: "All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|chalcone 2'-<i>O</i>-glucoside biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-8445',
        Common_Name: 'puerarin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|FLAVONOID-DEG|PWY-8445',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Flavonoid Degradation|puerarin degradation',
    },
    {
        BioCyc_ID: 'PWY-6412',
        Common_Name: 'ginsenoside degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Ginsenoside-Degradation|PWY-6412',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Ginsenoside Degradation|ginsenoside degradation II',
    },
    {
        BioCyc_ID: 'PWY-6328',
        Common_Name: 'L-lysine degradation X',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|PWY-6328',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation X',
    },
    {
        BioCyc_ID: 'PWY-7654',
        Common_Name: '(8<i>E</i>,10<i>E</i>)-dodeca-8,10-dienol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-7654',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|(8<i>E</i>,10<i>E</i>)-dodeca-8,10-dienol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7654',
        Common_Name: '(8<i>E</i>,10<i>E</i>)-dodeca-8,10-dienol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7654',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|(8<i>E</i>,10<i>E</i>)-dodeca-8,10-dienol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-389',
        Common_Name: 'phytol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|PWY66-389',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|phytol degradation',
    },
    {
        BioCyc_ID: 'PWY-7743',
        Common_Name: 'dimycocerosyl triglycosyl phenolphthiocerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-7743',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|dimycocerosyl triglycosyl phenolphthiocerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-84',
        Common_Name: 'resveratrol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|STILBENE-PHYTOALEXINS|PWY-84',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Stilbene Phytoalexin Biosynthesis|resveratrol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-84',
        Common_Name: 'resveratrol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|STILBENE-SYN|PWY-84',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Stilbene Biosynthesis|resveratrol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7037',
        Common_Name: 'protein <i>O</i>-glycosylation (Neisseria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|PWY-7037',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|protein <i>O</i>-glycosylation (Neisseria)',
    },
    {
        BioCyc_ID: 'PWY-7037',
        Common_Name: 'protein <i>O</i>-glycosylation (Neisseria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7037',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <i>O</i>-glycosylation (Neisseria)',
    },
    {
        BioCyc_ID: 'PWY-7037',
        Common_Name: 'protein <i>O</i>-glycosylation (Neisseria)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7037',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <i>O</i>-glycosylation (Neisseria)',
    },
    {
        BioCyc_ID: 'PWY-7037',
        Common_Name: 'protein <i>O</i>-glycosylation (Neisseria)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7037',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <i>O</i>-glycosylation (Neisseria)',
    },
    {
        BioCyc_ID: 'PWY-702',
        Common_Name: 'L-methionine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-De-novo-Biosynthesis|PWY-702',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine <i>De Novo</i> Biosynthesis|L-methionine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5930',
        Common_Name: 'terpentecin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5930',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|terpentecin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5815',
        Common_Name: 'rubber biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|POLYTERPENOID-SYN|PWY-5815',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Polyterpenoid Biosynthesis|rubber biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7968',
        Common_Name: '5-methylbenzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7968',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|5-methylbenzimidazolyl adenosylcobamide biosynthesis from adenosylcobinamide-GDP',
    },
    {
        BioCyc_ID: 'PWY-7436',
        Common_Name: 'vitamin E biosynthesis (tocotrienols)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|PWY-7436',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|vitamin E biosynthesis (tocotrienols)',
    },
    {
        BioCyc_ID: 'PWY-7436',
        Common_Name: 'vitamin E biosynthesis (tocotrienols)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|PWY-7436',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|vitamin E biosynthesis (tocotrienols)',
    },
    {
        BioCyc_ID: 'PWY-5459',
        Common_Name: 'methylglyoxal degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Methylglyoxal-Detoxification|PWY-5459',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Methylglyoxal Detoxification|methylglyoxal degradation IV',
    },
    {
        BioCyc_ID: 'PWY-5459',
        Common_Name: 'methylglyoxal degradation IV',
        MetaCyc_hierarchy_IDs: 'Detoxification|Methylglyoxal-Detoxification|PWY-5459',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Methylglyoxal Detoxification|methylglyoxal degradation IV',
    },
    {
        BioCyc_ID: 'PWY-5459',
        Common_Name: 'methylglyoxal degradation IV',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5459',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|methylglyoxal degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7700',
        Common_Name: '4-methylphenol degradation to protocatechuate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|TOLUENE-DEG|PWY-7700',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Toluene Degradation|4-methylphenol degradation to protocatechuate',
    },
    {
        BioCyc_ID: 'PWY-6288',
        Common_Name: 'zeaxanthin-&beta;-D-diglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-6288',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|zeaxanthin-&beta;-D-diglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6996',
        Common_Name: 'daidzin and daidzein degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|FLAVONOID-DEG|PWY-6996',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Flavonoid Degradation|daidzin and daidzein degradation',
    },
    {
        BioCyc_ID: 'PWY-8419',
        Common_Name: 'L-canavanine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|L-Canavanine-Degradation|PWY-8419',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|L-canavanine Degradation|L-canavanine degradation II',
    },
    {
        BioCyc_ID: 'LYSDEGII-PWY',
        Common_Name: 'L-lysine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LYSINE-DEG|LYSDEGII-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-lysine Degradation|L-lysine degradation III',
    },
    {
        BioCyc_ID: 'PWY66-426',
        Common_Name: 'hydrogen sulfide biosynthesis II (mammalian)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Hydrogen-Sulfide-Biosynthesis|PWY66-426',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Hydrogen Sulfide Biosynthesis|hydrogen sulfide biosynthesis II (mammalian)',
    },
    {
        BioCyc_ID: 'PWY66-426',
        Common_Name: 'hydrogen sulfide biosynthesis II (mammalian)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY66-426',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|hydrogen sulfide biosynthesis II (mammalian)',
    },
    {
        BioCyc_ID: 'PWY-6464',
        Common_Name: 'polyvinyl alcohol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|PWY-6464',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|polyvinyl alcohol degradation',
    },
    {
        BioCyc_ID: 'PWY-5670',
        Common_Name: 'epoxysqualene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-5670',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|epoxysqualene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5670',
        Common_Name: 'epoxysqualene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5670',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|epoxysqualene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5176',
        Common_Name: 'coumarin biosynthesis (via 2-coumarate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-5176',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|coumarin biosynthesis (via 2-coumarate)',
    },
    {
        BioCyc_ID: 'PWY-7922',
        Common_Name: 'protein <I>O</i>-mannosylation II (mammals, core M1 and core M2)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7922',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <I>O</i>-mannosylation II (mammals, core M1 and core M2)',
    },
    {
        BioCyc_ID: 'PWY-7922',
        Common_Name: 'protein <I>O</i>-mannosylation II (mammals, core M1 and core M2)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7922',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <I>O</i>-mannosylation II (mammals, core M1 and core M2)',
    },
    {
        BioCyc_ID: 'PWY-7922',
        Common_Name: 'protein <I>O</i>-mannosylation II (mammals, core M1 and core M2)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7922',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <I>O</i>-mannosylation II (mammals, core M1 and core M2)',
    },
    {
        BioCyc_ID: 'HOMOSER-METSYN-PWY',
        Common_Name: 'L-methionine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-De-novo-Biosynthesis|HOMOSER-METSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine <i>De Novo</i> Biosynthesis|L-methionine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6722',
        Common_Name: 'candicidin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6722',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|candicidin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5959',
        Common_Name: 'aflatoxins B<sub>1</sub> and G<sub>1</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-5959',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|aflatoxins B<sub>1</sub> and G<sub>1</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7975',
        Common_Name: 'adenosylcobalamin biosynthesis from adenosylcobinamide-GDP II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|PWY-7975',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|adenosylcobalamin biosynthesis from adenosylcobinamide-GDP II',
    },
    {
        BioCyc_ID: 'PWY-7993',
        Common_Name: '&beta;-dihydromenaquinone-9 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|PWY-7993',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|&beta;-dihydromenaquinone-9 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5692',
        Common_Name: 'allantoin degradation to glyoxylate II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Allantoin-degradation|PWY-5692',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Allantoin Degradation|allantoin degradation to glyoxylate II',
    },
    {
        BioCyc_ID: 'PWY-5692',
        Common_Name: 'allantoin degradation to glyoxylate II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5692',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|allantoin degradation to glyoxylate II',
    },
    {
        BioCyc_ID: 'PWY-8025',
        Common_Name: 'cyanuric acid degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Cyanurate-Degradation|PWY-8025',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Cyanurate Degradation|cyanuric acid degradation I',
    },
    {
        BioCyc_ID: 'PWY-7129',
        Common_Name: 'quercetin glucoside biosynthesis (<i>Allium</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7129',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|quercetin glucoside biosynthesis (<i>Allium</i>)',
    },
    {
        BioCyc_ID: 'PWY-7129',
        Common_Name: 'quercetin glucoside biosynthesis (<i>Allium</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7129',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|quercetin glucoside biosynthesis (<i>Allium</i>)',
    },
    {
        BioCyc_ID: 'PWY-7445',
        Common_Name: 'luteolin triglucuronide degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|FLAVONOID-DEG|PWY-7445',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Flavonoid Degradation|luteolin triglucuronide degradation',
    },
    {
        BioCyc_ID: 'PWY-31',
        Common_Name: 'L-canavanine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|L-Canavanine-Degradation|PWY-31',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|L-canavanine Degradation|L-canavanine degradation I',
    },
    {
        BioCyc_ID: 'PWY-8186',
        Common_Name: 'L-proline degradation II (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|PROLINE-DEG|PWY-8186',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-proline Degradation|L-proline degradation II (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8186',
        Common_Name: 'L-proline degradation II (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-8186',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|L-proline degradation II (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-2901',
        Common_Name: 'cytokinins 9-<i>N</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|CYTOKININ-BIOSYNTHESIS|PWY-2901',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Cytokinin Biosynthesis|cytokinins 9-<i>N</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2901',
        Common_Name: 'cytokinins 9-<i>N</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-2901',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|cytokinins 9-<i>N</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2901',
        Common_Name: 'cytokinins 9-<i>N</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-2901',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|cytokinins 9-<i>N</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1280',
        Common_Name: 'ethylene glycol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|PWY0-1280',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|ethylene glycol degradation',
    },
    {
        BioCyc_ID: 'PWY-7746',
        Common_Name: 'mycobacterial sulfolipid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-7746',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|mycobacterial sulfolipid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5365',
        Common_Name: 'linear furanocoumarin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-5365',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|linear furanocoumarin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8322',
        Common_Name: 'protein <i>N</i>-glycosylation processing of proteins targeted for retention in cellular organelles (<i>cis-Golgi</i>, yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-8322',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <i>N</i>-glycosylation processing of proteins targeted for retention in cellular organelles (<i>cis-Golgi</i>, yeast)',
    },
    {
        BioCyc_ID: 'PWY-8322',
        Common_Name: 'protein <i>N</i>-glycosylation processing of proteins targeted for retention in cellular organelles (<i>cis-Golgi</i>, yeast)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-8322',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <i>N</i>-glycosylation processing of proteins targeted for retention in cellular organelles (<i>cis-Golgi</i>, yeast)',
    },
    {
        BioCyc_ID: 'PWY-8322',
        Common_Name: 'protein <i>N</i>-glycosylation processing of proteins targeted for retention in cellular organelles (<i>cis-Golgi</i>, yeast)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-8322',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <i>N</i>-glycosylation processing of proteins targeted for retention in cellular organelles (<i>cis-Golgi</i>, yeast)',
    },
    {
        BioCyc_ID: 'PWY-7977',
        Common_Name: 'L-methionine biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-De-novo-Biosynthesis|PWY-7977',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine <i>De Novo</i> Biosynthesis|L-methionine biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-7354',
        Common_Name: 'aclacinomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7354',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|aclacinomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7906',
        Common_Name: 'rhizobitoxine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7906',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|rhizobitoxine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7972',
        Common_Name: 'adenosylcobinamide-GDP salvage from cobinamide II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-Salvage|B12-Salvage-From-Cobinamide|PWY-7972',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobinamide Salvage|Adenosylcobalamin Salvage from Cobinamide|adenosylcobinamide-GDP salvage from cobinamide II',
    },
    {
        BioCyc_ID: 'PWY-1422',
        Common_Name: 'vitamin E biosynthesis (tocopherols)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|PWY-1422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|vitamin E biosynthesis (tocopherols)',
    },
    {
        BioCyc_ID: 'PWY-1422',
        Common_Name: 'vitamin E biosynthesis (tocopherols)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|PWY-1422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|vitamin E biosynthesis (tocopherols)',
    },
    {
        BioCyc_ID: 'PWY-6126',
        Common_Name: 'superpathway of adenosine nucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|PWY-6126',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|superpathway of adenosine nucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6126',
        Common_Name: 'superpathway of adenosine nucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6126',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of adenosine nucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5169',
        Common_Name: 'cyanuric acid degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Cyanurate-Degradation|PWY-5169',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Cyanurate Degradation|cyanuric acid degradation II',
    },
    {
        BioCyc_ID: 'PWY-7173',
        Common_Name: 'quercetin triglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7173',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|quercetin triglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7173',
        Common_Name: 'quercetin triglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7173',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|quercetin triglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6848',
        Common_Name: 'rutin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|FLAVONOID-DEG|PWY-6848',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Flavonoid Degradation|rutin degradation',
    },
    {
        BioCyc_ID: 'PROUT-PWY',
        Common_Name: 'L-proline degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|PROLINE-DEG|PROUT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-proline Degradation|L-proline degradation I',
    },
    {
        BioCyc_ID: 'PWY-6545',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6545',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6545',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-6545',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis III",
    },
    {
        BioCyc_ID: 'PWY-6545',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-6545',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis III',
    },
    {
        BioCyc_ID: 'GLYCOL-GLYOXDEG-PWY',
        Common_Name: 'superpathway of glycol metabolism and degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|GLYCOL-GLYOXDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|superpathway of glycol metabolism and degradation',
    },
    {
        BioCyc_ID: 'GLYCOL-GLYOXDEG-PWY',
        Common_Name: 'superpathway of glycol metabolism and degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GLYCOL-GLYOXDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glycol metabolism and degradation',
    },
    {
        BioCyc_ID: 'PWY-6129',
        Common_Name: 'dolichol and dolichyl phosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-6129',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|dolichol and dolichyl phosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7055',
        Common_Name: 'daphnetin modification',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-7055',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|daphnetin modification',
    },
    {
        BioCyc_ID: 'PWY-7658',
        Common_Name: 'protein <I>N</i>-glycosylation (<i>Methanococcus voltae</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Protein-Glycosylation|PWY-7658',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Protein Glycosylation|protein <I>N</i>-glycosylation (<i>Methanococcus voltae</i>)',
    },
    {
        BioCyc_ID: 'PWY-7658',
        Common_Name: 'protein <I>N</i>-glycosylation (<i>Methanococcus voltae</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Protein-Glycosylation|PWY-7658',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Protein Glycosylation|protein <I>N</i>-glycosylation (<i>Methanococcus voltae</i>)',
    },
    {
        BioCyc_ID: 'PWY-7658',
        Common_Name: 'protein <I>N</i>-glycosylation (<i>Methanococcus voltae</i>)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|Protein-Glycosylation|PWY-7658',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Protein Glycosylation|protein <I>N</i>-glycosylation (<i>Methanococcus voltae</i>)',
    },
    {
        BioCyc_ID: 'PWY-8381',
        Common_Name: 'sulfazecin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|sulfazecin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8360',
        Common_Name: 'hectochlorin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8360',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|hectochlorin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7971',
        Common_Name: 'adenosylcobinamide-GDP salvage from cobinamide I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-Salvage|B12-Salvage-From-Cobinamide|PWY-7971',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobinamide Salvage|Adenosylcobalamin Salvage from Cobinamide|adenosylcobinamide-GDP salvage from cobinamide I',
    },
    {
        BioCyc_ID: 'PWY-7864',
        Common_Name: '8-methylmenaquinone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|PWY-7864',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|8-methylmenaquinone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6285',
        Common_Name: 'superpathway of fatty acids biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY-6285',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|superpathway of fatty acids biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-6285',
        Common_Name: 'superpathway of fatty acids biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6285',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of fatty acids biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-5428',
        Common_Name: '1,3-dimethylbenzene degradation to 3-methylbenzoate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Xylene-Degradation|PWY-5428',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Dimethylbenzene Degradation|1,3-dimethylbenzene degradation to 3-methylbenzoate',
    },
    {
        BioCyc_ID: 'PWY-7262',
        Common_Name: 'rose anthocyanin biosynthesis II (via cyanidin 3-<i>O</i>-&beta;-<i>D</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7262',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|rose anthocyanin biosynthesis II (via cyanidin 3-<i>O</i>-&beta;-<i>D</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-7262',
        Common_Name: 'rose anthocyanin biosynthesis II (via cyanidin 3-<i>O</i>-&beta;-<i>D</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7262',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|rose anthocyanin biosynthesis II (via cyanidin 3-<i>O</i>-&beta;-<i>D</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-7262',
        Common_Name: 'rose anthocyanin biosynthesis II (via cyanidin 3-<i>O</i>-&beta;-<i>D</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7262',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|rose anthocyanin biosynthesis II (via cyanidin 3-<i>O</i>-&beta;-<i>D</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-7134',
        Common_Name: 'rutin degradation (plants)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|FLAVONOID-DEG|PWY-7134',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Flavonoid Degradation|rutin degradation (plants)',
    },
    {
        BioCyc_ID: 'PWY-5461',
        Common_Name: 'betanidin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|ALKALOIDS|PWY-5461',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Alkaloid Degradation|betanidin degradation',
    },
    {
        BioCyc_ID: 'ARGDEG-III-PWY',
        Common_Name: 'L-arginine degradation IV (arginine decarboxylase/agmatine deiminase pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ARGDEG-III-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation IV (arginine decarboxylase/agmatine deiminase pathway)',
    },
    {
        BioCyc_ID: 'PWY-7206',
        Common_Name: 'pyrimidine deoxyribonucleotides dephosphorylation',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7206',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pyrimidine deoxyribonucleotides dephosphorylation',
    },
    {
        BioCyc_ID: 'PWY-7206',
        Common_Name: 'pyrimidine deoxyribonucleotides dephosphorylation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Deoxyribonucleosides-Deg|PWY-7206',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Deoxyribonucleoside Degradation|pyrimidine deoxyribonucleotides dephosphorylation',
    },
    {
        BioCyc_ID: 'PWY-7616',
        Common_Name: 'methanol oxidation to carbon dioxide',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|PWY-7616',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|methanol oxidation to carbon dioxide',
    },
    {
        BioCyc_ID: 'PWY-7616',
        Common_Name: 'methanol oxidation to carbon dioxide',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-7616',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|methanol oxidation to carbon dioxide',
    },
    {
        BioCyc_ID: 'PWY-7616',
        Common_Name: 'methanol oxidation to carbon dioxide',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7616',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|methanol oxidation to carbon dioxide',
    },
    {
        BioCyc_ID: 'PWY0-1264',
        Common_Name: 'biotin-carboxyl carrier protein assembly',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY0-1264',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|biotin-carboxyl carrier protein assembly',
    },
    {
        BioCyc_ID: 'PWY-7058',
        Common_Name: 'esculetin modification',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-7058',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|esculetin modification',
    },
    {
        BioCyc_ID: 'PWY-5630',
        Common_Name: 'penicillin K biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5630',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|penicillin K biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8423',
        Common_Name: '<i>Salmonella typhimurium</i> LT2 lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8423',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Salmonella typhimurium</i> LT2 lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8423',
        Common_Name: '<i>Salmonella typhimurium</i> LT2 lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|LPS-Biosynthesis-Final-Steps|PWY-8423',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Salmonella typhimurium</i> LT2 lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8423',
        Common_Name: '<i>Salmonella typhimurium</i> LT2 lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8423',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Salmonella typhimurium</i> LT2 lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8423',
        Common_Name: '<i>Salmonella typhimurium</i> LT2 lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8423',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Salmonella typhimurium</i> LT2 lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'ADENOSYLHOMOCYSCAT-PWY',
        Common_Name: 'L-methionine salvage from L-homocysteine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-Salvage|ADENOSYLHOMOCYSCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine Salvage|L-methionine salvage from L-homocysteine',
    },
    {
        BioCyc_ID: 'PWY18C3-7',
        Common_Name: 'cembratrienediol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY18C3-7',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|cembratrienediol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6495',
        Common_Name: 'ergotamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-6495',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|ergotamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6495',
        Common_Name: 'ergotamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-6495',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|ergotamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8282',
        Common_Name: 'adenosylcobinamide-GDP salvage from assorted adenosylcobamides',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-Salvage|B12-Salvage-From-Cobinamide|PWY-8282',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobinamide Salvage|Adenosylcobalamin Salvage from Cobinamide|adenosylcobinamide-GDP salvage from assorted adenosylcobamides',
    },
    {
        BioCyc_ID: 'PWY-7781',
        Common_Name: '&omega;-sulfo-II-dihydromenaquinone-9 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|PWY-7781',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|&omega;-sulfo-II-dihydromenaquinone-9 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6395',
        Common_Name: 'superpathway of seleno-compound metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|Other-Amino-Acid-Biosynthesis|PWY-6395',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Other Amino Acid Biosynthesis|superpathway of seleno-compound metabolism',
    },
    {
        BioCyc_ID: 'PWY-6395',
        Common_Name: 'superpathway of seleno-compound metabolism',
        MetaCyc_hierarchy_IDs: 'Detoxification|Seleno-Amino-Acid-Detoxification|PWY-6395',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Seleno-Amino Acid Detoxification|superpathway of seleno-compound metabolism',
    },
    {
        BioCyc_ID: 'PWY-6395',
        Common_Name: 'superpathway of seleno-compound metabolism',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Selenium-Metabolism|Seleno-Amino-Acid-Detoxification|PWY-6395',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Selenium Metabolism|Seleno-Amino Acid Detoxification|superpathway of seleno-compound metabolism',
    },
    {
        BioCyc_ID: 'PWY-6395',
        Common_Name: 'superpathway of seleno-compound metabolism',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6395',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of seleno-compound metabolism',
    },
    {
        BioCyc_ID: 'PWY-5429',
        Common_Name: '1,4-dimethylbenzene degradation to 4-methylbenzoate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Xylene-Degradation|PWY-5429',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Dimethylbenzene Degradation|1,4-dimethylbenzene degradation to 4-methylbenzoate',
    },
    {
        BioCyc_ID: 'PWY-7633',
        Common_Name: 'calycosin 7-<i>O</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7633',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|calycosin 7-<i>O</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7633',
        Common_Name: 'calycosin 7-<i>O</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-7633',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|calycosin 7-<i>O</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7652',
        Common_Name: 'echinocandin B degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|ANTIBIOTIC-DEGRADATION|PWY-7652',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Antibiotic degradation|echinocandin B degradation',
    },
    {
        BioCyc_ID: 'PWY18C3-5',
        Common_Name: '&alpha;-tomatine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|ALKALOIDS|PWY18C3-5',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Alkaloid Degradation|&alpha;-tomatine degradation',
    },
    {
        BioCyc_ID: 'ARGDEG-V-PWY',
        Common_Name: 'L-arginine degradation X (arginine monooxygenase pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ARGDEG-V-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation X (arginine monooxygenase pathway)',
    },
    {
        BioCyc_ID: 'PWY-7679',
        Common_Name: 'anthocyanidin acylglucoside and acylsambubioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7679',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin acylglucoside and acylsambubioside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7679',
        Common_Name: 'anthocyanidin acylglucoside and acylsambubioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7679',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin acylglucoside and acylsambubioside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7679',
        Common_Name: 'anthocyanidin acylglucoside and acylsambubioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7679',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|anthocyanidin acylglucoside and acylsambubioside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6388',
        Common_Name: '(S,S)-butanediol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|2-3-Butanediol-Degradation|PWY-6388',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|2,3-Butanediol Degradation|(S,S)-butanediol degradation',
    },
    {
        BioCyc_ID: 'PWY-6803',
        Common_Name: 'phosphatidylcholine acyl editing',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-6803',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|phosphatidylcholine acyl editing',
    },
    {
        BioCyc_ID: 'PWY-7398',
        Common_Name: 'coumarins biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-7398',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|coumarins biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6955',
        Common_Name: 'lincomycin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6955',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|lincomycin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8422',
        Common_Name: '<i>Porphyromonas gingivalis</i> lipopolysaccharide (O-LPS) biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Porphyromonas gingivalis</i> lipopolysaccharide (O-LPS) biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8422',
        Common_Name: '<i>Porphyromonas gingivalis</i> lipopolysaccharide (O-LPS) biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|LPS-Biosynthesis-Final-Steps|PWY-8422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Porphyromonas gingivalis</i> lipopolysaccharide (O-LPS) biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8422',
        Common_Name: '<i>Porphyromonas gingivalis</i> lipopolysaccharide (O-LPS) biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Porphyromonas gingivalis</i> lipopolysaccharide (O-LPS) biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8422',
        Common_Name: '<i>Porphyromonas gingivalis</i> lipopolysaccharide (O-LPS) biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8422',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Porphyromonas gingivalis</i> lipopolysaccharide (O-LPS) biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-6233',
        Common_Name: 'jasmonoyl-amino acid conjugates biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Jasmonates-Biosynthesis|PWY-6233',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Jasmonate Biosynthesis|jasmonoyl-amino acid conjugates biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7482',
        Common_Name: 'cyclooctatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7482',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|cyclooctatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7957',
        Common_Name: 'hapalindole H biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7957',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|hapalindole H biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8364',
        Common_Name: '7,8-dimethylmenaquinone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|PWY-8364',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|7,8-dimethylmenaquinone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6564',
        Common_Name: 'superpathway of heparan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6564',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|superpathway of heparan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6564',
        Common_Name: 'superpathway of heparan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6564',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|superpathway of heparan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6564',
        Common_Name: 'superpathway of heparan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6564',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of heparan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7772',
        Common_Name: '&gamma;-resorcylate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Gamma-Resorcylate-Degradation|PWY-7772',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|&gamma;-Resorcylate Degradation|&gamma;-resorcylate degradation II',
    },
    {
        BioCyc_ID: 'PWY-7071',
        Common_Name: 'steviol glucoside biosynthesis (rebaudioside A biosynthesis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7071',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|steviol glucoside biosynthesis (rebaudioside A biosynthesis)',
    },
    {
        BioCyc_ID: 'PWY-7071',
        Common_Name: 'steviol glucoside biosynthesis (rebaudioside A biosynthesis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7071',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|steviol glucoside biosynthesis (rebaudioside A biosynthesis)',
    },
    {
        BioCyc_ID: 'PWY-7808',
        Common_Name: 'tetracycline resistance',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|ANTIBIOTIC-DEGRADATION|PWY-7808',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Antibiotic degradation|tetracycline resistance',
    },
    {
        BioCyc_ID: 'PWY-7808',
        Common_Name: 'tetracycline resistance',
        MetaCyc_hierarchy_IDs: 'Detoxification|Antibiotic-Resistance|PWY-7808',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Antibiotic Resistance|tetracycline resistance',
    },
    {
        BioCyc_ID: 'PWY-6999',
        Common_Name: 'theophylline degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|ALKALOIDS|PWY-6999',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Alkaloid Degradation|theophylline degradation',
    },
    {
        BioCyc_ID: 'PWY-6344',
        Common_Name: 'L-arginine degradation XIV (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|PWY-6344',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation XIV (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-6344',
        Common_Name: 'L-arginine degradation XIV (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Oxidative|PWY-6344',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Oxidative Branch)|L-arginine degradation XIV (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-4203',
        Common_Name: 'volatile benzenoid biosynthesis I (ester formation)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-4203',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|volatile benzenoid biosynthesis I (ester formation)',
    },
    {
        BioCyc_ID: 'PWY-4203',
        Common_Name: 'volatile benzenoid biosynthesis I (ester formation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Volatile-Benzenoids-Biosynthesis|PWY-4203',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Volatile Benzenoid Biosynthesis|volatile benzenoid biosynthesis I (ester formation)',
    },
    {
        BioCyc_ID: 'PWY3O-246',
        Common_Name: '(R,R)-butanediol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|2-3-Butanediol-Degradation|PWY3O-246',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|2,3-Butanediol Degradation|(R,R)-butanediol degradation',
    },
    {
        BioCyc_ID: 'PWYQT-4427',
        Common_Name: 'sulfoquinovosyl diacylglycerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWYQT-4427',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|sulfoquinovosyl diacylglycerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5349',
        Common_Name: 'esculetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-5349',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|esculetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7441',
        Common_Name: 'polymyxin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7441',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|polymyxin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8421',
        Common_Name: '<i>Helicobacter pylori</i> 26695 lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Helicobacter pylori</i> 26695 lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8421',
        Common_Name: '<i>Helicobacter pylori</i> 26695 lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|LPS-Biosynthesis-Final-Steps|PWY-8421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Helicobacter pylori</i> 26695 lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8421',
        Common_Name: '<i>Helicobacter pylori</i> 26695 lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Helicobacter pylori</i> 26695 lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-8421',
        Common_Name: '<i>Helicobacter pylori</i> 26695 lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY-8421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Helicobacter pylori</i> 26695 lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-6220',
        Common_Name: 'jasmonoyl-amino acid conjugates biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Jasmonates-Biosynthesis|PWY-6220',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Jasmonate Biosynthesis|jasmonoyl-amino acid conjugates biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7572',
        Common_Name: 'lolitrem B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7572',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|lolitrem B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8407',
        Common_Name: 'fumagillin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8407',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|fumagillin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7374',
        Common_Name: '1,4-dihydroxy-6-naphthoate biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|14-Dihydroxy-6-Naphthoate-Biosynthesis|PWY-7374',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|1,4-dihydroxy-6-naphthoate biosynthesis|1,4-dihydroxy-6-naphthoate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6963',
        Common_Name: 'ammonia assimilation cycle I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-Assimilation|PWY-6963',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Assimilation|ammonia assimilation cycle I',
    },
    {
        BioCyc_ID: 'PWY-6963',
        Common_Name: 'ammonia assimilation cycle I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6963',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|ammonia assimilation cycle I',
    },
    {
        BioCyc_ID: 'PWY-7773',
        Common_Name: '&gamma;-resorcylate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Gamma-Resorcylate-Degradation|PWY-7773',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|&gamma;-Resorcylate Degradation|&gamma;-resorcylate degradation I',
    },
    {
        BioCyc_ID: 'PWY-7157',
        Common_Name: 'eupatolitin 3-<i>O</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-7157',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|eupatolitin 3-<i>O</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7157',
        Common_Name: 'eupatolitin 3-<i>O</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7157',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|eupatolitin 3-<i>O</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7635',
        Common_Name: 'kievitone detoxification',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PHYTOALEXIN-DEG|PWY-7635',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Phytoalexin Degradation|kievitone detoxification',
    },
    {
        BioCyc_ID: 'PWY0-823',
        Common_Name: 'L-arginine degradation III (arginine decarboxylase/agmatinase pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|PWY0-823',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation III (arginine decarboxylase/agmatinase pathway)',
    },
    {
        BioCyc_ID: 'PWY-6603',
        Common_Name: 'dicranin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Acetlylenic-Fatty-Acid-Biosynthesis|PWY-6603',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Fatty Acid Containing acetylenic bond Biosynthesis|dicranin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6603',
        Common_Name: 'dicranin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6603',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|dicranin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6603',
        Common_Name: 'dicranin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-6603',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|dicranin biosynthesis',
    },
    {
        BioCyc_ID: 'LPSSYN-PWY',
        Common_Name: 'superpathway of Kdo<sub>2</sub>-lipid A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|LPSSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|superpathway of Kdo<sub>2</sub>-lipid A biosynthesis',
    },
    {
        BioCyc_ID: 'LPSSYN-PWY',
        Common_Name: 'superpathway of Kdo<sub>2</sub>-lipid A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|LPSSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of Kdo<sub>2</sub>-lipid A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6982',
        Common_Name: 'umbelliferone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-6982',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|umbelliferone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7812',
        Common_Name: 'tetracycline and oxytetracycline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7812',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|tetracycline and oxytetracycline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2B4Q-9',
        Common_Name: '<i>Brucella abortus</i> lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY2B4Q-9',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Brucella abortus</i> lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-9',
        Common_Name: '<i>Brucella abortus</i> lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|LPS-Biosynthesis-Final-Steps|PWY2B4Q-9',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Brucella abortus</i> lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-9',
        Common_Name: '<i>Brucella abortus</i> lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY2B4Q-9',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Brucella abortus</i> lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY2B4Q-9',
        Common_Name: '<i>Brucella abortus</i> lipopolysaccharide biosynthesis (final steps)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|LPS-Biosynthesis-Final-Steps|PWY2B4Q-9',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Lipopolysaccharide Biosynthesis (Final Steps)|<i>Brucella abortus</i> lipopolysaccharide biosynthesis (final steps)',
    },
    {
        BioCyc_ID: 'PWY-5025',
        Common_Name: 'indole-3-acetate biosynthesis IV (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Auxin-Biosynthesis|PWY-5025',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Auxin Biosynthesis|indole-3-acetate biosynthesis IV (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6304',
        Common_Name: 'casbene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-6304',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|casbene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6831',
        Common_Name: 'pyrrolnitrin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-6831',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|pyrrolnitrin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7371',
        Common_Name: '1,4-dihydroxy-6-naphthoate biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|14-Dihydroxy-6-Naphthoate-Biosynthesis|PWY-7371',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|1,4-dihydroxy-6-naphthoate biosynthesis|1,4-dihydroxy-6-naphthoate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7194',
        Common_Name: 'pyrimidine nucleobases salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7194',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|pyrimidine nucleobases salvage II',
    },
    {
        BioCyc_ID: 'PWY-7194',
        Common_Name: 'pyrimidine nucleobases salvage II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7194',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyrimidine nucleobases salvage II',
    },
    {
        BioCyc_ID: 'PWY-5160',
        Common_Name: 'rose anthocyanin biosynthesis I (via cyanidin 5-<i>O</i>-&beta;-<i>D</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5160',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|rose anthocyanin biosynthesis I (via cyanidin 5-<i>O</i>-&beta;-<i>D</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5160',
        Common_Name: 'rose anthocyanin biosynthesis I (via cyanidin 5-<i>O</i>-&beta;-<i>D</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5160',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|rose anthocyanin biosynthesis I (via cyanidin 5-<i>O</i>-&beta;-<i>D</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5160',
        Common_Name: 'rose anthocyanin biosynthesis I (via cyanidin 5-<i>O</i>-&beta;-<i>D</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-5160',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|rose anthocyanin biosynthesis I (via cyanidin 5-<i>O</i>-&beta;-<i>D</i>-glucoside)',
    },
    {
        BioCyc_ID: 'AST-PWY',
        Common_Name: 'L-arginine degradation II (AST pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|AST-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation II (AST pathway)',
    },
    {
        BioCyc_ID: 'PWY-7251',
        Common_Name: 'pentacyclic triterpene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7251',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pentacyclic triterpene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7251',
        Common_Name: 'pentacyclic triterpene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7251',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|pentacyclic triterpene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7420',
        Common_Name: 'monoacylglycerol metabolism (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|PWY-7420',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|monoacylglycerol metabolism (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7304',
        Common_Name: '3-amino-4,7-dihydroxy-coumarin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-7304',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|3-amino-4,7-dihydroxy-coumarin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8179',
        Common_Name: 'platensimycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8179',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|platensimycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3161',
        Common_Name: 'indole-3-acetate biosynthesis III (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Auxin-Biosynthesis|PWY-3161',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Auxin Biosynthesis|indole-3-acetate biosynthesis III (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7994',
        Common_Name: 'dolabralexins biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7994',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|dolabralexins biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7991',
        Common_Name: 'toxoflavin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7991',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|toxoflavin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7305',
        Common_Name: 'superpathway of steroid hormone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-7305',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|superpathway of steroid hormone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7305',
        Common_Name: 'superpathway of steroid hormone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7305',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of steroid hormone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6215',
        Common_Name: '4-chlorobenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|PWY-6215',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|4-chlorobenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY-6215',
        Common_Name: '4-chlorobenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|PWY-6215',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|4-chlorobenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY-5348',
        Common_Name: 'kaempferol triglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVONOL-SYN|PWY-5348',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavonol Biosynthesis|kaempferol triglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5348',
        Common_Name: 'kaempferol triglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-5348',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|kaempferol triglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5742',
        Common_Name: 'L-arginine degradation IX (arginine:pyruvate transaminase pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|PWY-5742',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation IX (arginine:pyruvate transaminase pathway)',
    },
    {
        BioCyc_ID: 'PWY-7800',
        Common_Name: 'Ac/N-end rule pathway',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7800',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|Ac/N-end rule pathway',
    },
    {
        BioCyc_ID: 'PWY-7800',
        Common_Name: 'Ac/N-end rule pathway',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7800',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Ac/N-end rule pathway',
    },
    {
        BioCyc_ID: 'PWY-6792',
        Common_Name: 'scopoletin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-6792',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|scopoletin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8438',
        Common_Name: 'corallopyronin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8438',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|corallopyronin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-581',
        Common_Name: 'indole-3-acetate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Auxin-Biosynthesis|PWY-581',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Auxin Biosynthesis|indole-3-acetate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6691',
        Common_Name: 'plaunotol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-6691',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|plaunotol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-162',
        Common_Name: 'ethanol degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|Ethanol-Degradation|PWY66-162',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Ethanol Degradation|ethanol degradation IV',
    },
    {
        BioCyc_ID: 'PWY-8437',
        Common_Name: 'furaquinocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8437',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|furaquinocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7731',
        Common_Name: 'superpathway of photosynthetic hydrogen production',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-7731',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|superpathway of photosynthetic hydrogen production',
    },
    {
        BioCyc_ID: 'PWY-7731',
        Common_Name: 'superpathway of photosynthetic hydrogen production',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7731',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of photosynthetic hydrogen production',
    },
    {
        BioCyc_ID: 'PWY-6221',
        Common_Name: '2-chlorobenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|PWY-6221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|2-chlorobenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY-6221',
        Common_Name: '2-chlorobenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|PWY-6221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|2-chlorobenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY-7139',
        Common_Name: 'sesaminol glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7139',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|sesaminol glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7139',
        Common_Name: 'sesaminol glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-7139',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|sesaminol glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8187',
        Common_Name: 'L-arginine degradation XIII (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|PWY-8187',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation XIII (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8187',
        Common_Name: 'L-arginine degradation XIII (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-8187',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|L-arginine degradation XIII (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-5267',
        Common_Name: 'glucosinolate activation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|GLUCOSINOLATE-DEG|PWY-5267',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|Glucosinolate Activation|glucosinolate activation',
    },
    {
        BioCyc_ID: 'PWY-5267',
        Common_Name: 'glucosinolate activation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|GLUCOSINOLATE-DEG|PWY-5267',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Glucosinolate Activation|glucosinolate activation',
    },
    {
        BioCyc_ID: 'PWY-5267',
        Common_Name: 'glucosinolate activation',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-5267',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|glucosinolate activation',
    },
    {
        BioCyc_ID: 'PWY-5633',
        Common_Name: 'cephamycin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5633',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|cephamycin C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7851',
        Common_Name: 'coenzyme A biosynthesis II (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|PWY-7851',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|coenzyme A biosynthesis II (eukaryotic)',
    },
    {
        BioCyc_ID: 'PWY-6302',
        Common_Name: 'dihydroconiferyl alcohol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNIN-SYN|PWY-6302',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignin Biosynthesis|dihydroconiferyl alcohol biosynthesis',
    },
    {
        BioCyc_ID: 'TRPIAACAT-PWY',
        Common_Name: 'indole-3-acetate biosynthesis VI (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Auxin-Biosynthesis|TRPIAACAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Auxin Biosynthesis|indole-3-acetate biosynthesis VI (bacteria)',
    },
    {
        BioCyc_ID: 'PWY18C3-18',
        Common_Name: 'labdenediol and sclareol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY18C3-18',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|labdenediol and sclareol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-161',
        Common_Name: 'ethanol degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|Ethanol-Degradation|PWY66-161',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Ethanol Degradation|ethanol degradation III',
    },
    {
        BioCyc_ID: 'PWY-7493',
        Common_Name: 'paxilline and diprenylpaxilline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7493',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|paxilline and diprenylpaxilline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-841',
        Common_Name: 'superpathway of purine nucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|PWY-841',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|superpathway of purine nucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-841',
        Common_Name: 'superpathway of purine nucleotides <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-841',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of purine nucleotides <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY0-1324',
        Common_Name: '<i>N</i>-acetylneuraminate and <i>N</i>-acetylmannosamine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|NAN-MANNACs-degradation|PWY0-1324',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|<i>N</i>-Acetylneuraminate and <i>N</i>-Acetylmannosamine Degradation|<i>N</i>-acetylneuraminate and <i>N</i>-acetylmannosamine degradation I',
    },
    {
        BioCyc_ID: 'PWY-6217',
        Common_Name: '3,4-dichlorobenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|PWY-6217',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|3,4-dichlorobenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY-6217',
        Common_Name: '3,4-dichlorobenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzoate-Degradation|PWY-6217',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzoate Degradation|3,4-dichlorobenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY-7259',
        Common_Name: 'pelargonidin diglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7259',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|pelargonidin diglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-7259',
        Common_Name: 'pelargonidin diglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7259',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|pelargonidin diglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-7259',
        Common_Name: 'pelargonidin diglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7259',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|pelargonidin diglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'OCTOPINEDEG-PWY',
        Common_Name: 'octopine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|OCTOPINEDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|octopine degradation',
    },
    {
        BioCyc_ID: 'ARG-PRO-PWY',
        Common_Name: 'L-arginine degradation VI (arginase 2 pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ARG-PRO-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation VI (arginase 2 pathway)',
    },
    {
        BioCyc_ID: 'PWY-7423',
        Common_Name: 'bombykol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-7423',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|bombykol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7423',
        Common_Name: 'bombykol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7423',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|bombykol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6322',
        Common_Name: 'phosphinothricin tripeptide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-6322',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|phosphinothricin tripeptide biosynthesis',
    },
    {
        BioCyc_ID: 'COA-PWY',
        Common_Name: 'coenzyme A biosynthesis I (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|COA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|coenzyme A biosynthesis I (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-361',
        Common_Name: 'monolignol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNIN-SYN|PWY-361',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignin Biosynthesis|monolignol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-361',
        Common_Name: 'monolignol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|SECONDARY-CELL-WALL|PWY-361',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|Secondary Cell Wall|monolignol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1061',
        Common_Name: 'homogalacturonan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|PRIMARY-CELL-WALL|PWY-1061',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|Primary Cell Wall|homogalacturonan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1061',
        Common_Name: 'homogalacturonan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-1061',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|homogalacturonan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1061',
        Common_Name: 'homogalacturonan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-1061',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|homogalacturonan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5026',
        Common_Name: 'indole-3-acetate biosynthesis V (bacteria and fungi)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Auxin-Biosynthesis|PWY-5026',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Auxin Biosynthesis|indole-3-acetate biosynthesis V (bacteria and fungi)',
    },
    {
        BioCyc_ID: 'PWY-7070',
        Common_Name: 'steviol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7070',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|steviol biosynthesis',
    },
    {
        BioCyc_ID: 'ETOH-ACETYLCOA-ANA-PWY',
        Common_Name: 'ethanol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|Ethanol-Degradation|ETOH-ACETYLCOA-ANA-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Ethanol Degradation|ethanol degradation I',
    },
    {
        BioCyc_ID: 'PWY-8071',
        Common_Name: '<i>N</i>-3-oxalyl-L-2,3-diaminopropanoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|AMINE-SYN|PWY-8071',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Amine Biosynthesis|<i>N</i>-3-oxalyl-L-2,3-diaminopropanoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8071',
        Common_Name: '<i>N</i>-3-oxalyl-L-2,3-diaminopropanoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8071',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|<i>N</i>-3-oxalyl-L-2,3-diaminopropanoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY18C3-14',
        Common_Name: 'superpathway of glandular trichomes produced diterpenoids (tobacco)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY18C3-14',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|superpathway of glandular trichomes produced diterpenoids (tobacco)',
    },
    {
        BioCyc_ID: 'PWY18C3-14',
        Common_Name: 'superpathway of glandular trichomes produced diterpenoids (tobacco)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY18C3-14',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glandular trichomes produced diterpenoids (tobacco)',
    },
    {
        BioCyc_ID: 'PWY-7581',
        Common_Name: '<i>N</i>-acetylneuraminate and <i>N</i>-acetylmannosamine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|NAN-MANNACs-degradation|PWY-7581',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|<i>N</i>-Acetylneuraminate and <i>N</i>-Acetylmannosamine Degradation|<i>N</i>-acetylneuraminate and <i>N</i>-acetylmannosamine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7487',
        Common_Name: '(+)-secoisolariciresinol diglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-7487',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|(+)-secoisolariciresinol diglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7487',
        Common_Name: '(+)-secoisolariciresinol diglucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-7487',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|(+)-secoisolariciresinol diglucoside biosynthesis',
    },
    {
        BioCyc_ID: 'NOPALINEDEG-PWY',
        Common_Name: 'nopaline degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|NOPALINEDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|nopaline degradation',
    },
    {
        BioCyc_ID: 'ARGDEG-IV-PWY',
        Common_Name: 'L-arginine degradation VIII (arginine oxidase pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ARGDEG-IV-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation VIII (arginine oxidase pathway)',
    },
    {
        BioCyc_ID: 'PWY-8061',
        Common_Name: '8-oxo-(d)GTP detoxification II',
        MetaCyc_hierarchy_IDs: 'Detoxification|8-Oxo-GTP-Detoxification|PWY-8061',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|8-oxo-GTP and 8-oxo-dGTP Detoxification|8-oxo-(d)GTP detoxification II',
    },
    {
        BioCyc_ID: 'PWY-8061',
        Common_Name: '8-oxo-(d)GTP detoxification II',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-8061',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|8-oxo-(d)GTP detoxification II',
    },
    {
        BioCyc_ID: 'PWY-7016',
        Common_Name: 'neomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7016',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|neomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4221',
        Common_Name: 'superpathway of coenzyme A biosynthesis II (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|PWY-4221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|superpathway of coenzyme A biosynthesis II (plants)',
    },
    {
        BioCyc_ID: 'PWY-4221',
        Common_Name: 'superpathway of coenzyme A biosynthesis II (plants)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-4221',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of coenzyme A biosynthesis II (plants)',
    },
    {
        BioCyc_ID: 'PWY-5048',
        Common_Name: 'rosmarinic acid biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|Rosmarinate-Biosynthesis|PWY-5048',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Rosmarinate Biosynthesis|rosmarinic acid biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6403',
        Common_Name: 'carrageenan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-6403',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|carrageenan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6403',
        Common_Name: 'carrageenan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-6403',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|carrageenan biosynthesis',
    },
    {
        BioCyc_ID: 'PWYDQC-4',
        Common_Name: 'indole-3-acetate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Auxin-Biosynthesis|PWYDQC-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Auxin Biosynthesis|indole-3-acetate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7481',
        Common_Name: 'oryzalide A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7481',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|oryzalide A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7481',
        Common_Name: 'oryzalide A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-7481',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|oryzalide A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-21',
        Common_Name: 'ethanol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|Ethanol-Degradation|PWY66-21',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Ethanol Degradation|ethanol degradation II',
    },
    {
        BioCyc_ID: 'PWY-5826',
        Common_Name: 'hypoglycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-5826',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|hypoglycin biosynthesis',
    },
    {
        BioCyc_ID: 'SUCSYN-PWY',
        Common_Name: 'sucrose biosynthesis I (from photosynthesis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Sucrose-Biosynthesis|SUCSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Sucrose Biosynthesis|sucrose biosynthesis I (from photosynthesis)',
    },
    {
        BioCyc_ID: 'SUCSYN-PWY',
        Common_Name: 'sucrose biosynthesis I (from photosynthesis)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|SUCSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|sucrose biosynthesis I (from photosynthesis)',
    },
    {
        BioCyc_ID: 'PWY-6147',
        Common_Name: '6-hydroxymethyl-dihydropterin diphosphate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|6-HM-Dihydropterin-PP-Biosynthesis|PWY-6147',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|6-Hydroxymethyl-Dihydropterin Diphosphate Biosynthesis|6-hydroxymethyl-dihydropterin diphosphate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5024',
        Common_Name: 'L-arginine degradation XI',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|PWY-5024',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation XI',
    },
    {
        BioCyc_ID: 'PWY-6806',
        Common_Name: 'carotenoid cleavage',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6806',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|carotenoid cleavage',
    },
    {
        BioCyc_ID: 'PWY-6806',
        Common_Name: 'carotenoid cleavage',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|PWY-6806',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|carotenoid cleavage',
    },
    {
        BioCyc_ID: 'PWY-7510',
        Common_Name: 'rhizocticin A and B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7510',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|rhizocticin A and B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8342',
        Common_Name: 'coenzyme A biosynthesis III (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|PWY-8342',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|coenzyme A biosynthesis III (archaea)',
    },
    {
        BioCyc_ID: 'PWY-5049',
        Common_Name: 'rosmarinic acid biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|Rosmarinate-Biosynthesis|PWY-5049',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Rosmarinate Biosynthesis|rosmarinic acid biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6773',
        Common_Name: '1,3-&beta;-D-glucan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-6773',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|1,3-&beta;-D-glucan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6773',
        Common_Name: '1,3-&beta;-D-glucan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-6773',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|1,3-&beta;-D-glucan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6854',
        Common_Name: 'ethene biosynthesis III (microbes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Ethylene-Biosynthesis|PWY-6854',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Ethene Biosynthesis|ethene biosynthesis III (microbes)',
    },
    {
        BioCyc_ID: 'PWY-5107',
        Common_Name: 'phytol salvage pathway',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-5107',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|phytol salvage pathway',
    },
    {
        BioCyc_ID: 'PWY-7540',
        Common_Name: 'aflatrem biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7540',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|aflatrem biosynthesis',
    },
    {
        BioCyc_ID: 'ARO-PWY',
        Common_Name: 'chorismate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Chorismate-Biosynthesis|ARO-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Chorismate Biosynthesis|chorismate biosynthesis I',
    },
    {
        BioCyc_ID: 'ARO-PWY',
        Common_Name: 'chorismate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ARO-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|chorismate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7852',
        Common_Name: '6-hydroxymethyl-dihydropterin diphosphate biosynthesis IV (Plasmodium)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|6-HM-Dihydropterin-PP-Biosynthesis|PWY-7852',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|6-Hydroxymethyl-Dihydropterin Diphosphate Biosynthesis|6-hydroxymethyl-dihydropterin diphosphate biosynthesis IV (Plasmodium)',
    },
    {
        BioCyc_ID: 'PWY-7523',
        Common_Name: 'L-arginine degradation XII',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|PWY-7523',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation XII',
    },
    {
        BioCyc_ID: 'PWY-7498',
        Common_Name: 'phenylpropanoids methylation (ice plant)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7498',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|phenylpropanoids methylation (ice plant)',
    },
    {
        BioCyc_ID: 'PWY-7498',
        Common_Name: 'phenylpropanoids methylation (ice plant)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-7498',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|phenylpropanoids methylation (ice plant)',
    },
    {
        BioCyc_ID: 'PWY-7693',
        Common_Name: 'guadinomine B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7693',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|guadinomine B biosynthesis',
    },
    {
        BioCyc_ID: 'COA-PWY-1',
        Common_Name: 'superpathway of coenzyme A biosynthesis III (mammals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|COA-PWY-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|superpathway of coenzyme A biosynthesis III (mammals)',
    },
    {
        BioCyc_ID: 'COA-PWY-1',
        Common_Name: 'superpathway of coenzyme A biosynthesis III (mammals)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|COA-PWY-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of coenzyme A biosynthesis III (mammals)',
    },
    {
        BioCyc_ID: 'PWY-5665',
        Common_Name: 'vanillin biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|Vanillin-Biosynthesis|PWY-5665',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Vanillin Biosynthesis|vanillin biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-822',
        Common_Name: 'fructan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-822',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|fructan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-822',
        Common_Name: 'fructan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-822',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|fructan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6853',
        Common_Name: 'ethene biosynthesis II (microbes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Ethylene-Biosynthesis|PWY-6853',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Ethene Biosynthesis|ethene biosynthesis II (microbes)',
    },
    {
        BioCyc_ID: 'PWY-7517',
        Common_Name: 'brassicicene C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7517',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|brassicicene C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8083',
        Common_Name: 'domoic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8083',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|domoic acid biosynthesis',
    },
    {
        BioCyc_ID: 'GALACT-GLUCUROCAT-PWY',
        Common_Name: 'superpathway of hexuronide and hexuronate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GALACT-GLUCUROCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of hexuronide and hexuronate degradation',
    },
    {
        BioCyc_ID: 'PWY-6093',
        Common_Name: '4,5-dichlorocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|PWY-6093',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|4,5-dichlorocatechol degradation',
    },
    {
        BioCyc_ID: 'PWY-6093',
        Common_Name: '4,5-dichlorocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|PWY-6093',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|4,5-dichlorocatechol degradation',
    },
    {
        BioCyc_ID: 'PWY-7539',
        Common_Name: '6-hydroxymethyl-dihydropterin diphosphate biosynthesis III (Chlamydia)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|6-HM-Dihydropterin-PP-Biosynthesis|PWY-7539',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|6-Hydroxymethyl-Dihydropterin Diphosphate Biosynthesis|6-hydroxymethyl-dihydropterin diphosphate biosynthesis III (Chlamydia)',
    },
    {
        BioCyc_ID: 'ARG-GLU-PWY',
        Common_Name: 'L-arginine degradation VII (arginase 3 pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ARGININE-DEG|ARG-GLU-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-arginine Degradation|L-arginine degradation VII (arginase 3 pathway)',
    },
    {
        BioCyc_ID: 'PWY18C3-10',
        Common_Name: 'triacylsucrose biosynthesis (<i>Solanum</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|ACYLSUGAR-BIOSYNTHESIS|PWY18C3-10',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Acylsugar biosynthesis|triacylsucrose biosynthesis (<i>Solanum</i>)',
    },
    {
        BioCyc_ID: 'PWY18C3-10',
        Common_Name: 'triacylsucrose biosynthesis (<i>Solanum</i>)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY18C3-10',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|triacylsucrose biosynthesis (<i>Solanum</i>)',
    },
    {
        BioCyc_ID: 'PWY-8112',
        Common_Name: 'factor 420 biosynthesis I (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Factor-420-Biosynthesis|PWY-8112',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Factor 420 Biosynthesis|factor 420 biosynthesis I (archaea)',
    },
    {
        BioCyc_ID: 'PWY-2181',
        Common_Name: 'free phenylpropanoid acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-2181',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|free phenylpropanoid acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1001',
        Common_Name: 'cellulose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|PRIMARY-CELL-WALL|PWY-1001',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|Primary Cell Wall|cellulose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1001',
        Common_Name: 'cellulose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-1001',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|cellulose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1001',
        Common_Name: 'cellulose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-1001',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|cellulose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1001',
        Common_Name: 'cellulose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|SECONDARY-CELL-WALL|PWY-1001',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|Secondary Cell Wall|cellulose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7126',
        Common_Name: 'ethene biosynthesis IV (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Ethylene-Biosynthesis|PWY-7126',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Ethene Biosynthesis|ethene biosynthesis IV (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7721',
        Common_Name: 'methyl phomopsenoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7721',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|methyl phomopsenoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5956',
        Common_Name: 'sterigmatocystin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-5956',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|sterigmatocystin biosynthesis',
    },
    {
        BioCyc_ID: 'METHGLYUT-PWY',
        Common_Name: 'superpathway of methylglyoxal degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|METHGLYUT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|superpathway of methylglyoxal degradation',
    },
    {
        BioCyc_ID: 'METHGLYUT-PWY',
        Common_Name: 'superpathway of methylglyoxal degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|METHGLYUT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of methylglyoxal degradation',
    },
    {
        BioCyc_ID: 'PWY-6087',
        Common_Name: '4-chlorocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|PWY-6087',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|4-chlorocatechol degradation',
    },
    {
        BioCyc_ID: 'PWY-6087',
        Common_Name: '4-chlorocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|PWY-6087',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|4-chlorocatechol degradation',
    },
    {
        BioCyc_ID: 'PWY-6797',
        Common_Name: '6-hydroxymethyl-dihydropterin diphosphate biosynthesis II (<i>Methanocaldococcus</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|6-HM-Dihydropterin-PP-Biosynthesis|PWY-6797',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|6-Hydroxymethyl-Dihydropterin Diphosphate Biosynthesis|6-hydroxymethyl-dihydropterin diphosphate biosynthesis II (<i>Methanocaldococcus</i>)',
    },
    {
        BioCyc_ID: 'PWY-3162',
        Common_Name: 'L-tryptophan degradation V (side chain pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-3162',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation V (side chain pathway)',
    },
    {
        BioCyc_ID: 'PWY-102',
        Common_Name: 'gibberellin inactivation I (2&beta;-hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|GIBBERELLINS-DEGRADATION|PWY-102',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Gibberellin Degradation|gibberellin inactivation I (2&beta;-hydroxylation)',
    },
    {
        BioCyc_ID: 'PWY-102',
        Common_Name: 'gibberellin inactivation I (2&beta;-hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Gibberellin-Inactivation|PWY-102',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Gibberellin Inactivation|gibberellin inactivation I (2&beta;-hydroxylation)',
    },
    {
        BioCyc_ID: 'PWY-102',
        Common_Name: 'gibberellin inactivation I (2&beta;-hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-102',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|gibberellin inactivation I (2&beta;-hydroxylation)',
    },
    {
        BioCyc_ID: 'PWY-5199',
        Common_Name: 'factor 420 polyglutamylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Factor-420-Biosynthesis|PWY-5199',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Factor 420 Biosynthesis|factor 420 polyglutamylation',
    },
    {
        BioCyc_ID: 'PWY-5968',
        Common_Name: 'cinnamate esters biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-5968',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|cinnamate esters biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5980',
        Common_Name: 'xylogalacturonan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|PRIMARY-CELL-WALL|PWY-5980',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|Primary Cell Wall|xylogalacturonan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5980',
        Common_Name: 'xylogalacturonan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-5980',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|xylogalacturonan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5980',
        Common_Name: 'xylogalacturonan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-5980',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|xylogalacturonan biosynthesis',
    },
    {
        BioCyc_ID: 'ETHYL-PWY',
        Common_Name: 'ethene biosynthesis I (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Ethylene-Biosynthesis|ETHYL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Ethene Biosynthesis|ethene biosynthesis I (plants)',
    },
    {
        BioCyc_ID: 'PWY-6659',
        Common_Name: 'fusicoccin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-6659',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|fusicoccin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7708',
        Common_Name: 'lyngbyatoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7708',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|lyngbyatoxin biosynthesis',
    },
    {
        BioCyc_ID: 'P542-PWY',
        Common_Name: 'choline-<i>O</i>-sulfate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Choline-Degradation|P542-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Choline Degradation|choline-<i>O</i>-sulfate degradation',
    },
    {
        BioCyc_ID: 'P542-PWY',
        Common_Name: 'choline-<i>O</i>-sulfate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P542-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|choline-<i>O</i>-sulfate degradation',
    },
    {
        BioCyc_ID: 'PWY-6084',
        Common_Name: '3,5-dichlorocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|PWY-6084',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3,5-dichlorocatechol degradation',
    },
    {
        BioCyc_ID: 'PWY-6084',
        Common_Name: '3,5-dichlorocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|PWY-6084',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3,5-dichlorocatechol degradation',
    },
    {
        BioCyc_ID: 'PWY-7853',
        Common_Name: '6-hydroxymethyl-dihydropterin diphosphate biosynthesis V (<i>Pyrococcus</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|6-HM-Dihydropterin-PP-Biosynthesis|PWY-7853',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|6-Hydroxymethyl-Dihydropterin Diphosphate Biosynthesis|6-hydroxymethyl-dihydropterin diphosphate biosynthesis V (<i>Pyrococcus</i>)',
    },
    {
        BioCyc_ID: 'PWY-5651',
        Common_Name: 'L-tryptophan degradation to 2-amino-3-carboxymuconate semialdehyde',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-5651',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation to 2-amino-3-carboxymuconate semialdehyde',
    },
    {
        BioCyc_ID: 'PWY-6477',
        Common_Name: 'gibberellin inactivation II (methylation)',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|GIBBERELLINS-DEGRADATION|PWY-6477',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Gibberellin Degradation|gibberellin inactivation II (methylation)',
    },
    {
        BioCyc_ID: 'PWY-6477',
        Common_Name: 'gibberellin inactivation II (methylation)',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Gibberellin-Inactivation|PWY-6477',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Gibberellin Inactivation|gibberellin inactivation II (methylation)',
    },
    {
        BioCyc_ID: 'PWY-6477',
        Common_Name: 'gibberellin inactivation II (methylation)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6477',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|gibberellin inactivation II (methylation)',
    },
    {
        BioCyc_ID: 'PWY-5198',
        Common_Name: 'factor 420 biosynthesis II (mycobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Factor-420-Biosynthesis|PWY-5198',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Factor 420 Biosynthesis|factor 420 biosynthesis II (mycobacteria)',
    },
    {
        BioCyc_ID: 'PWY1F-467',
        Common_Name: 'phenylpropanoid biosynthesis, initial reactions',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY1F-467',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|phenylpropanoid biosynthesis, initial reactions',
    },
    {
        BioCyc_ID: 'PWY-6658',
        Common_Name: 'acetan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-6658',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|acetan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6658',
        Common_Name: 'acetan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-6658',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|acetan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7065',
        Common_Name: '2&alpha;,7&beta;-dihydroxylation of taxusin',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7065',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|2&alpha;,7&beta;-dihydroxylation of taxusin',
    },
    {
        BioCyc_ID: 'PWY-8358',
        Common_Name: 'curacin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8358',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|curacin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1882',
        Common_Name: 'superpathway of C1 compounds oxidation to CO<sub>2</sub>',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|PWY-1882',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|superpathway of C1 compounds oxidation to CO<sub>2</sub>',
    },
    {
        BioCyc_ID: 'PWY-1882',
        Common_Name: 'superpathway of C1 compounds oxidation to CO<sub>2</sub>',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-1882',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of C1 compounds oxidation to CO<sub>2</sub>',
    },
    {
        BioCyc_ID: 'PWY-6094',
        Common_Name: '3,4,6-trichlorocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|PWY-6094',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3,4,6-trichlorocatechol degradation',
    },
    {
        BioCyc_ID: 'PWY-6094',
        Common_Name: '3,4,6-trichlorocatechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorocatechol-Degradation|PWY-6094',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorocatechol Degradation|3,4,6-trichlorocatechol degradation',
    },
    {
        BioCyc_ID: 'PWY-7668',
        Common_Name: 'apicidin F biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7668',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|apicidin F biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7668',
        Common_Name: 'apicidin F biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|CYCLOPEPTIDES|PWY-7668',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Cyclopeptide Biosynthesis|apicidin F biosynthesis',
    },
    {
        BioCyc_ID: 'TRPCAT-PWY',
        Common_Name: 'L-tryptophan degradation I (via anthranilate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|TRPCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation I (via anthranilate)',
    },
    {
        BioCyc_ID: 'PWY-7120',
        Common_Name: 'esterified suberin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7120',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|esterified suberin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7120',
        Common_Name: 'esterified suberin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|PWY-7120',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|esterified suberin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8113',
        Common_Name: '3PG-factor 420 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Factor-420-Biosynthesis|PWY-8113',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Factor 420 Biosynthesis|3PG-factor 420 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5859',
        Common_Name: 'eugenol and isoeugenol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-5859',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|eugenol and isoeugenol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8133',
        Common_Name: 'succinoglycan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-8133',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|succinoglycan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8133',
        Common_Name: 'succinoglycan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-8133',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|succinoglycan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4FS-17',
        Common_Name: 'abscisic acid biosynthesis shunt',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Abscisic-Acid-Biosynthesis|PWY4FS-17',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Abscisic Acid Biosynthesis|abscisic acid biosynthesis shunt',
    },
    {
        BioCyc_ID: 'PWY4FS-17',
        Common_Name: 'abscisic acid biosynthesis shunt',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Abscisic-Acid-Biosynthesis|PWY4FS-17',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Abscisic Acid Biosynthesis|abscisic acid biosynthesis shunt',
    },
    {
        BioCyc_ID: 'PWY-2981',
        Common_Name: 'diterpene phytoalexins precursors biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-2981',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|diterpene phytoalexins precursors biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2981',
        Common_Name: 'diterpene phytoalexins precursors biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-2981',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|diterpene phytoalexins precursors biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6493',
        Common_Name: 'chanoclavine I aldehyde biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-6493',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|chanoclavine I aldehyde biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6493',
        Common_Name: 'chanoclavine I aldehyde biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-6493',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|chanoclavine I aldehyde biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5044',
        Common_Name: 'purine nucleotides degradation I (plants)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|PWY-5044',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|purine nucleotides degradation I (plants)',
    },
    {
        BioCyc_ID: 'PWY-5044',
        Common_Name: 'purine nucleotides degradation I (plants)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5044',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|purine nucleotides degradation I (plants)',
    },
    {
        BioCyc_ID: 'PWY-7667',
        Common_Name: 'apicidin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7667',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|apicidin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7667',
        Common_Name: 'apicidin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|CYCLOPEPTIDES|PWY-7667',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Cyclopeptide Biosynthesis|apicidin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5081',
        Common_Name: 'L-tryptophan degradation VIII (to tryptophol)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-5081',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation VIII (to tryptophol)',
    },
    {
        BioCyc_ID: 'PWY-7637',
        Common_Name: "2,2'-dihydroxyketocarotenoids biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7637',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|2,2'-dihydroxyketocarotenoids biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-7637',
        Common_Name: "2,2'-dihydroxyketocarotenoids biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7637',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|2,2'-dihydroxyketocarotenoids biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-7637',
        Common_Name: "2,2'-dihydroxyketocarotenoids biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7637',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|2,2'-dihydroxyketocarotenoids biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-7637',
        Common_Name: "2,2'-dihydroxyketocarotenoids biosynthesis",
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7637',
        MetaCyc_hierarchy_Names: "All Pathways and Reactions|Pathways|Metabolic Clusters|2,2'-dihydroxyketocarotenoids biosynthesis",
    },
    {
        BioCyc_ID: 'NADPHOS-DEPHOS-PWY-1',
        Common_Name: 'NAD phosphorylation and transhydrogenation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NADPHOS-DEPHOS-PWY-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD phosphorylation and transhydrogenation',
    },
    {
        BioCyc_ID: 'NADPHOS-DEPHOS-PWY-1',
        Common_Name: 'NAD phosphorylation and transhydrogenation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NADPHOS-DEPHOS-PWY-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD phosphorylation and transhydrogenation',
    },
    {
        BioCyc_ID: 'PWY-6040',
        Common_Name: 'chlorogenic acid biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-6040',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|chlorogenic acid biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8243',
        Common_Name: 'colanic acid (<i>Escherichia coli</i> K12) biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-8243',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|colanic acid (<i>Escherichia coli</i> K12) biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8243',
        Common_Name: 'colanic acid (<i>Escherichia coli</i> K12) biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-8243',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|colanic acid (<i>Escherichia coli</i> K12) biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-695',
        Common_Name: 'abscisic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Abscisic-Acid-Biosynthesis|PWY-695',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Abscisic Acid Biosynthesis|abscisic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-695',
        Common_Name: 'abscisic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Abscisic-Acid-Biosynthesis|PWY-695',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Abscisic Acid Biosynthesis|abscisic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7484',
        Common_Name: 'phytocassanes biosynthesis, shared reactions',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7484',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|phytocassanes biosynthesis, shared reactions',
    },
    {
        BioCyc_ID: 'PWY-7484',
        Common_Name: 'phytocassanes biosynthesis, shared reactions',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-7484',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|phytocassanes biosynthesis, shared reactions',
    },
    {
        BioCyc_ID: 'PWY-7954',
        Common_Name: 'tabtoxinine-&beta;-lactam biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7954',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|tabtoxinine-&beta;-lactam biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5335',
        Common_Name: 'superpathway of sulfide oxidation (<i>Starkeya novella</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfide-Oxidation|PWY-5335',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfide Oxidation|superpathway of sulfide oxidation (<i>Starkeya novella</i>)',
    },
    {
        BioCyc_ID: 'PWY-5335',
        Common_Name: 'superpathway of sulfide oxidation (<i>Starkeya novella</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5335',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sulfide oxidation (<i>Starkeya novella</i>)',
    },
    {
        BioCyc_ID: 'PWY-8156',
        Common_Name: 'omphalotin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-8156',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|omphalotin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8156',
        Common_Name: 'omphalotin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|CYCLOPEPTIDES|PWY-8156',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Cyclopeptide Biosynthesis|omphalotin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6678',
        Common_Name: 'geraniol and nerol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|PWY-6678',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|geraniol and nerol degradation',
    },
    {
        BioCyc_ID: 'PWY-6307',
        Common_Name: 'L-tryptophan degradation X (mammalian, via tryptamine)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-6307',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation X (mammalian, via tryptamine)',
    },
    {
        BioCyc_ID: 'PWY18C3-24',
        Common_Name: 'methylsalicylate degradation',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY18C3-24',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|methylsalicylate degradation',
    },
    {
        BioCyc_ID: 'PWY18C3-24',
        Common_Name: 'methylsalicylate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY18C3-24',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|methylsalicylate degradation',
    },
    {
        BioCyc_ID: 'PWY-7268',
        Common_Name: 'cytosolic NADPH production (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|PWY-7268',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|cytosolic NADPH production (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7268',
        Common_Name: 'cytosolic NADPH production (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|PWY-7268',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|cytosolic NADPH production (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7461',
        Common_Name: 'hydroxycinnamate sugar acid ester biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-7461',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|hydroxycinnamate sugar acid ester biosynthesis',
    },
    {
        BioCyc_ID: 'ECASYN-PWY',
        Common_Name: 'enterobacterial common antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|ECASYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|enterobacterial common antigen biosynthesis',
    },
    {
        BioCyc_ID: 'ECASYN-PWY',
        Common_Name: 'enterobacterial common antigen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|ECASYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|enterobacterial common antigen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2781',
        Common_Name: '<i>cis</i>-zeatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|CYTOKININ-BIOSYNTHESIS|PWY-2781',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Cytokinin Biosynthesis|<i>cis</i>-zeatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5660',
        Common_Name: 'taxol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-5660',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|taxol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8382',
        Common_Name: 'diazepinomicin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8382',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|diazepinomicin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5483',
        Common_Name: 'pyruvate fermentation to acetate III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5483',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate III',
    },
    {
        BioCyc_ID: 'PWY-5483',
        Common_Name: 'pyruvate fermentation to acetate III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5483',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate III',
    },
    {
        BioCyc_ID: 'PWY-5483',
        Common_Name: 'pyruvate fermentation to acetate III',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5483',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate III',
    },
    {
        BioCyc_ID: 'PWY-6845',
        Common_Name: 'nitric oxide biosynthesis I (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Nitric-Oxide-Biosynthesis|PWY-6845',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Nitric Oxide Biosynthesis|nitric oxide biosynthesis I (plants)',
    },
    {
        BioCyc_ID: 'PWY-6950',
        Common_Name: 'DIMBOA-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|PWY-6950',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|DIMBOA-glucoside biosynthesis',
    },
    {
        BioCyc_ID: '6-HYDROXYCINEOLE-DEGRADATION-PWY',
        Common_Name: '1,8-cineole degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|6-HYDROXYCINEOLE-DEGRADATION-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|1,8-cineole degradation',
    },
    {
        BioCyc_ID: 'TRYPDEG-PWY',
        Common_Name: 'L-tryptophan degradation II (via pyruvate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|TRYPDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation II (via pyruvate)',
    },
    {
        BioCyc_ID: 'PWY-2881',
        Common_Name: 'cytokinins 7-<i>N</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|CYTOKININ-BIOSYNTHESIS|PWY-2881',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Cytokinin Biosynthesis|cytokinins 7-<i>N</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2881',
        Common_Name: 'cytokinins 7-<i>N</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-2881',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|cytokinins 7-<i>N</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2881',
        Common_Name: 'cytokinins 7-<i>N</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-2881',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|cytokinins 7-<i>N</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'NADPHOS-DEPHOS-PWY',
        Common_Name: 'NAD phosphorylation and dephosphorylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NADPHOS-DEPHOS-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD phosphorylation and dephosphorylation',
    },
    {
        BioCyc_ID: 'NADPHOS-DEPHOS-PWY',
        Common_Name: 'NAD phosphorylation and dephosphorylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NADPHOS-DEPHOS-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD phosphorylation and dephosphorylation',
    },
    {
        BioCyc_ID: 'PWY-3301',
        Common_Name: 'sinapate ester biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-3301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|sinapate ester biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5936',
        Common_Name: 'xyloglucan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-5936',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|xyloglucan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5936',
        Common_Name: 'xyloglucan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-5936',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|xyloglucan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2681',
        Common_Name: '<i>trans</i>-zeatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|CYTOKININ-BIOSYNTHESIS|PWY-2681',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Cytokinin Biosynthesis|<i>trans</i>-zeatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7680',
        Common_Name: 'carnosate bioynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-7680',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|carnosate bioynthesis',
    },
    {
        BioCyc_ID: 'PWY-6644',
        Common_Name: 'fluoroacetate and fluorothreonine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-6644',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|fluoroacetate and fluorothreonine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5523',
        Common_Name: '5,6-dimethylbenzimidazole biosynthesis I (aerobic)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Lower-Ligand-Biosynthesis|DMB-Biosynthesis|PWY-5523',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Cobamide Lower Ligand Biosynthesis|5,6-Dimethylbenzimidazole Biosynthesis|5,6-dimethylbenzimidazole biosynthesis I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-5717',
        Common_Name: 'superpathway of formononetin derivative biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5717',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of formononetin derivative biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-385',
        Common_Name: 'dTMP <i>de novo</i> biosynthesis (mitochondrial)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PWY66-385',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|dTMP <i>de novo</i> biosynthesis (mitochondrial)',
    },
    {
        BioCyc_ID: 'PWY-4983',
        Common_Name: 'nitric oxide biosynthesis II (mammals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Nitric-Oxide-Biosynthesis|PWY-4983',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Nitric Oxide Biosynthesis|nitric oxide biosynthesis II (mammals)',
    },
    {
        BioCyc_ID: 'PWY-6949',
        Common_Name: 'DIBOA-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|PWY-6949',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|DIBOA-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6672',
        Common_Name: '<i>cis</i>-geranyl-CoA degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|PWY-6672',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|<i>cis</i>-geranyl-CoA degradation',
    },
    {
        BioCyc_ID: 'TRPKYNCAT-PWY',
        Common_Name: 'L-tryptophan degradation IV (via indole-3-lactate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|TRPKYNCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation IV (via indole-3-lactate)',
    },
    {
        BioCyc_ID: 'PWY-7198',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7198',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-7198',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-7198',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis IV",
    },
    {
        BioCyc_ID: 'PWY-7198',
        Common_Name: 'pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY-7198',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-7245',
        Common_Name: 'superpathway of NAD/NADP',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|PWY-7245',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|superpathway of NAD/NADP',
    },
    {
        BioCyc_ID: 'PWY-7245',
        Common_Name: 'superpathway of NAD/NADP',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|PWY-7245',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|superpathway of NAD/NADP',
    },
    {
        BioCyc_ID: 'PWY-7245',
        Common_Name: 'superpathway of NAD/NADP',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7245',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of NAD/NADP',
    },
    {
        BioCyc_ID: 'PWY-5168',
        Common_Name: 'ferulate and sinapate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-5168',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|ferulate and sinapate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6655',
        Common_Name: 'xanthan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-6655',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|xanthan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6655',
        Common_Name: 'xanthan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-6655',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|xanthan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5967',
        Common_Name: 'lupinate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|CYTOKININ-BIOSYNTHESIS|PWY-5967',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Cytokinin Biosynthesis|lupinate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6645',
        Common_Name: 'labdane-type diterpenes biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-6645',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|labdane-type diterpenes biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7959',
        Common_Name: '12-<i>epi</i>-hapalindole biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7959',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|12-<i>epi</i>-hapalindole biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7729',
        Common_Name: '5,6-dimethylbenzimidazole biosynthesis II (anaerobic)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Lower-Ligand-Biosynthesis|DMB-Biosynthesis|PWY-7729',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Cobamide Lower Ligand Biosynthesis|5,6-Dimethylbenzimidazole Biosynthesis|5,6-dimethylbenzimidazole biosynthesis II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5903',
        Common_Name: 'bacillibactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-5903',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|bacillibactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5903',
        Common_Name: 'bacillibactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5903',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|bacillibactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7860',
        Common_Name: 'nitric oxide biosynthesis III (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Nitric-Oxide-Biosynthesis|PWY-7860',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Nitric Oxide Biosynthesis|nitric oxide biosynthesis III (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7739',
        Common_Name: 'aucuparin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|PWY-7739',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|aucuparin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7136',
        Common_Name: '&beta; myrcene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|PWY-7136',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|&beta; myrcene degradation',
    },
    {
        BioCyc_ID: 'CYSTEINE-DEG-PWY',
        Common_Name: 'L-cysteine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|CYSTEINE-DEG|CYSTEINE-DEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-cysteine Degradation|L-cysteine degradation I',
    },
    {
        BioCyc_ID: 'PWY-7678',
        Common_Name: 'anthocyanidin sambubioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7678',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin sambubioside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7678',
        Common_Name: 'anthocyanidin sambubioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7678',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|anthocyanidin sambubioside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7678',
        Common_Name: 'anthocyanidin sambubioside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7678',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|anthocyanidin sambubioside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8148',
        Common_Name: 'NADP biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|PWY-8148',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NADP biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8148',
        Common_Name: 'NADP biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|PWY-8148',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NADP biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6039',
        Common_Name: 'chlorogenic acid biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-6039',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|chlorogenic acid biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6243',
        Common_Name: 'bergamotene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Bergamotene-Biosynthesis|PWY-6243',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Bergamotene Biosynthesis|bergamotene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY18C3-13',
        Common_Name: '<i>cis</i>-abienol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY18C3-13',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|<i>cis</i>-abienol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5196',
        Common_Name: 'factor 430 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|PWY-5196',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|factor 430 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5196',
        Common_Name: 'factor 430 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|PWY-5196',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|factor 430 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8424',
        Common_Name: 'ascomycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8424',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|ascomycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6123',
        Common_Name: "inosine-5'-phosphate biosynthesis I",
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Ribonuc-De-Novo-Biosynthesis|IMP-Biosynthesis|PWY-6123',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Riboucleotide <i>De Novo</i> Biosynthesis|Inosine-5'-phosphate Biosynthesis|inosine-5'-phosphate biosynthesis I",
    },
    {
        BioCyc_ID: 'PWY-6145',
        Common_Name: 'superpathway of CMP-sialic acids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|PWY-6145',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|superpathway of CMP-sialic acids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6145',
        Common_Name: 'superpathway of CMP-sialic acids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6145',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of CMP-sialic acids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-421',
        Common_Name: 'homocarnosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Dipeptide-Biosynthesis|PWY66-421',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Dipeptide Biosynthesis|homocarnosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7463',
        Common_Name: '<i>N</i>-methylanthraniloyl-&beta;-<i>D</i>-glucopyranose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|PWY-7463',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|<i>N</i>-methylanthraniloyl-&beta;-<i>D</i>-glucopyranose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6670',
        Common_Name: 'citronellol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|PWY-6670',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|citronellol degradation',
    },
    {
        BioCyc_ID: 'LCYSDEG-PWY',
        Common_Name: 'L-cysteine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|CYSTEINE-DEG|LCYSDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-cysteine Degradation|L-cysteine degradation II',
    },
    {
        BioCyc_ID: 'PWY-4201',
        Common_Name: 'volatile cinnamoic ester biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-4201',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|volatile cinnamoic ester biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4201',
        Common_Name: 'volatile cinnamoic ester biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-4201',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|volatile cinnamoic ester biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5083',
        Common_Name: 'NAD(P)/NADPH interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-5083',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|NAD(P)/NADPH interconversion',
    },
    {
        BioCyc_ID: 'PWY-5083',
        Common_Name: 'NAD(P)/NADPH interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|PWY-5083',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD(P)/NADPH interconversion',
    },
    {
        BioCyc_ID: 'PWY-5083',
        Common_Name: 'NAD(P)/NADPH interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|PWY-5083',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD(P)/NADPH interconversion',
    },
    {
        BioCyc_ID: 'PWY-7460',
        Common_Name: '2-<i>O</i>-acetyl-3-<i>O</i>-<i>trans</i>-coutarate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-7460',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|2-<i>O</i>-acetyl-3-<i>O</i>-<i>trans</i>-coutarate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6244',
        Common_Name: 'bergamotene biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|Bergamotene-Biosynthesis|PWY-6244',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Bergamotene Biosynthesis|bergamotene biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7928',
        Common_Name: 'lactate racemase nickel cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|PWY-7928',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|lactate racemase nickel cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7492',
        Common_Name: 'paspaline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7492',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|paspaline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7234',
        Common_Name: "inosine-5'-phosphate biosynthesis III",
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Ribonuc-De-Novo-Biosynthesis|IMP-Biosynthesis|PWY-7234',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Riboucleotide <i>De Novo</i> Biosynthesis|Inosine-5'-phosphate Biosynthesis|inosine-5'-phosphate biosynthesis III",
    },
    {
        BioCyc_ID: 'PWY-6305',
        Common_Name: 'superpathway of putrescine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Putrescine-Biosynthesis|PWY-6305',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Putrescine Biosynthesis|superpathway of putrescine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6305',
        Common_Name: 'superpathway of putrescine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6305',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of putrescine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8015',
        Common_Name: 'glycine degradation (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLYCINE-DEG|PWY-8015',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|Glycine Degradation|glycine degradation (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8015',
        Common_Name: 'glycine degradation (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-8015',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|glycine degradation (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-7850',
        Common_Name: 'taurine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|Taurine-Biosynthesis|PWY-7850',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|Taurine Biosynthesis|taurine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6413',
        Common_Name: 'ginsenoside degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|PWY-6413',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|ginsenoside degradation III',
    },
    {
        BioCyc_ID: 'PWY-6585',
        Common_Name: '2-methylketone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6585',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|2-methylketone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6585',
        Common_Name: '2-methylketone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|PWY-6585',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|2-methylketone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6585',
        Common_Name: '2-methylketone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-6585',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|2-methylketone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7269',
        Common_Name: 'mitochondrial NADPH production (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|PWY-7269',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|mitochondrial NADPH production (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7269',
        Common_Name: 'mitochondrial NADPH production (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|PWY-7269',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|mitochondrial NADPH production (yeast)',
    },
    {
        BioCyc_ID: 'PWY-6297',
        Common_Name: 'tuberonate glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Glycosylation-Pathways|PWY-6297',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Glycosylation|tuberonate glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6297',
        Common_Name: 'tuberonate glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|PWY-6297',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|tuberonate glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7497',
        Common_Name: '3&beta;-hydroxysesquiterpene lactone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|SESQUITERPENE-LACTONE|PWY-7497',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Sesquiterpene Lactone Biosynthesis|3&beta;-hydroxysesquiterpene lactone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5423',
        Common_Name: 'oleoresin monoterpene volatiles biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-5423',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|oleoresin monoterpene volatiles biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8159',
        Common_Name: 'dipyrromethane cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|PWY-8159',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|dipyrromethane cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8049',
        Common_Name: 'pederin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8049',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|pederin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8049',
        Common_Name: 'pederin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8049',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|pederin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6124',
        Common_Name: "inosine-5'-phosphate biosynthesis II",
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|Purine-Ribonuc-De-Novo-Biosynthesis|IMP-Biosynthesis|PWY-6124',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|Purine Riboucleotide <i>De Novo</i> Biosynthesis|Inosine-5'-phosphate Biosynthesis|inosine-5'-phosphate biosynthesis II",
    },
    {
        BioCyc_ID: 'PWY-6571',
        Common_Name: 'superpathway of dermatan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6571',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|superpathway of dermatan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6571',
        Common_Name: 'superpathway of dermatan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6571',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|superpathway of dermatan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6571',
        Common_Name: 'superpathway of dermatan sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6571',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of dermatan sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'GLYCLEAV-PWY',
        Common_Name: 'glycine cleavage',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLYCINE-DEG|GLYCLEAV-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|Glycine Degradation|glycine cleavage',
    },
    {
        BioCyc_ID: 'PWY-5331',
        Common_Name: 'taurine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|Taurine-Biosynthesis|PWY-5331',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|Taurine Biosynthesis|taurine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY0-1317',
        Common_Name: 'L-lactaldehyde degradation (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Lactaldehyde-Degradation|PWY0-1317',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Lactaldehyde Degradation|L-lactaldehyde degradation (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-7224',
        Common_Name: 'purine deoxyribonucleosides salvage',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7224',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|purine deoxyribonucleosides salvage',
    },
    {
        BioCyc_ID: 'PWY-7224',
        Common_Name: 'purine deoxyribonucleosides salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|PWY-7224',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|purine deoxyribonucleosides salvage',
    },
    {
        BioCyc_ID: 'RIBOSYN2-PWY',
        Common_Name: 'flavin biosynthesis I (bacteria and plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Flavin-Biosynthesis|RIBOSYN2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Flavin Biosynthesis|flavin biosynthesis I (bacteria and plants)',
    },
    {
        BioCyc_ID: 'RIBOSYN2-PWY',
        Common_Name: 'flavin biosynthesis I (bacteria and plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Flavin-Biosynthesis|RIBOSYN2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Flavin Biosynthesis|flavin biosynthesis I (bacteria and plants)',
    },
    {
        BioCyc_ID: 'PWY-7749',
        Common_Name: "(-)-4'-demethyl-epipodophyllotoxin biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-7749',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|(-)-4'-demethyl-epipodophyllotoxin biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-7859',
        Common_Name: 'jasmonoyl-L-isoleucine inactivation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|PWY-7859',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|jasmonoyl-L-isoleucine inactivation',
    },
    {
        BioCyc_ID: 'PWY-6540',
        Common_Name: 'costunolide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|SESQUITERPENE-LACTONE|PWY-6540',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Sesquiterpene Lactone Biosynthesis|costunolide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6437',
        Common_Name: 'fenchol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-6437',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|fenchol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7250',
        Common_Name: '[2Fe-2S] iron-sulfur cluster biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|PWY-7250',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|[2Fe-2S] iron-sulfur cluster biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7533',
        Common_Name: 'gliotoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7533',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|gliotoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7791',
        Common_Name: 'UMP biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Ribonucleot-De-Novo-Biosyn|UMP-Biosynthesis|PWY-7791',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Ribonucleotide <i>De Novo</i> Biosynthesis|UMP Biosynthesis|UMP biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6830',
        Common_Name: 'superpathway of methanogenesis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|PWY-6830',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|superpathway of methanogenesis',
    },
    {
        BioCyc_ID: 'PWY-6830',
        Common_Name: 'superpathway of methanogenesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6830',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of methanogenesis',
    },
    {
        BioCyc_ID: 'PWY-7085',
        Common_Name: 'triethylamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-7085',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|triethylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-5075',
        Common_Name: 'L-leucine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LEUCINE-DEG|PWY-5075',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-leucine Degradation|L-leucine degradation II',
    },
    {
        BioCyc_ID: 'PWY-8359',
        Common_Name: 'taurine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|Taurine-Biosynthesis|PWY-8359',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|Taurine Biosynthesis|taurine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY0-1315',
        Common_Name: 'L-lactaldehyde degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Lactaldehyde-Degradation|PWY0-1315',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Lactaldehyde Degradation|L-lactaldehyde degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7799',
        Common_Name: 'Arg/N-end rule pathway (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-7799',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|Arg/N-end rule pathway (eukaryotic)',
    },
    {
        BioCyc_ID: 'PWY-7799',
        Common_Name: 'Arg/N-end rule pathway (eukaryotic)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-7799',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|Arg/N-end rule pathway (eukaryotic)',
    },
    {
        BioCyc_ID: 'PWY-6167',
        Common_Name: 'flavin biosynthesis II (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Flavin-Biosynthesis|PWY-6167',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Flavin Biosynthesis|flavin biosynthesis II (archaea)',
    },
    {
        BioCyc_ID: 'PWY-6167',
        Common_Name: 'flavin biosynthesis II (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Flavin-Biosynthesis|PWY-6167',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Flavin Biosynthesis|flavin biosynthesis II (archaea)',
    },
    {
        BioCyc_ID: 'PWY-5466',
        Common_Name: 'matairesinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-5466',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|matairesinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8300',
        Common_Name: "D-cycloserine inhibition of pyridoxal 5'-phosphate",
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|PWY-8300',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|D-cycloserine inhibition of pyridoxal 5'-phosphate",
    },
    {
        BioCyc_ID: 'PWY-5195',
        Common_Name: 'artemisinin and arteannuin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|SESQUITERPENE-LACTONE|PWY-5195',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|Sesquiterpene Lactone Biosynthesis|artemisinin and arteannuin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6449',
        Common_Name: 'fenchone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-6449',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|fenchone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8063',
        Common_Name: 'flavin-N5-oxide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|PWY-8063',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|flavin-N5-oxide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8063',
        Common_Name: 'flavin-N5-oxide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-8063',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|flavin-N5-oxide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8077',
        Common_Name: 'ginkgotoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PYRROLIDINE-ALKALOIDS|PWY-8077',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Pyrrolidine, Piperidine and Pyridine Alkaloid Biosynthesis|ginkgotoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8077',
        Common_Name: 'ginkgotoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8077',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|ginkgotoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7790',
        Common_Name: 'UMP biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Ribonucleot-De-Novo-Biosyn|UMP-Biosynthesis|PWY-7790',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Ribonucleotide <i>De Novo</i> Biosynthesis|UMP Biosynthesis|UMP biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6981',
        Common_Name: 'chitin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|PWY-6981',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|chitin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6981',
        Common_Name: 'chitin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|PWY-6981',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|chitin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6981',
        Common_Name: 'chitin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6981',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|chitin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8185',
        Common_Name: 'L-leucine degradation V (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LEUCINE-DEG|PWY-8185',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-leucine Degradation|L-leucine degradation V (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8185',
        Common_Name: 'L-leucine degradation V (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Oxidative|PWY-8185',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Oxidative Branch)|L-leucine degradation V (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8337',
        Common_Name: '&gamma;-butyrobetaine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-8337',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|&gamma;-butyrobetaine degradation I',
    },
    {
        BioCyc_ID: 'PWY-8337',
        Common_Name: '&gamma;-butyrobetaine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Gamma-butyrobetaine-Degradation|PWY-8337',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|&gamma;-butyrobetaine degradation|&gamma;-butyrobetaine degradation I',
    },
    {
        BioCyc_ID: 'PWY3O-450',
        Common_Name: 'phosphatidylcholine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylcholineBiosynthesis|PWY3O-450',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylcholine Biosynthesis|phosphatidylcholine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5386',
        Common_Name: 'methylglyoxal degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Methylglyoxal-Detoxification|PWY-5386',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Methylglyoxal Detoxification|methylglyoxal degradation I',
    },
    {
        BioCyc_ID: 'PWY-5386',
        Common_Name: 'methylglyoxal degradation I',
        MetaCyc_hierarchy_IDs: 'Detoxification|Methylglyoxal-Detoxification|PWY-5386',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Methylglyoxal Detoxification|methylglyoxal degradation I',
    },
    {
        BioCyc_ID: 'PWY-6549',
        Common_Name: 'nitrogen remobilization from senescing leaves',
        MetaCyc_hierarchy_IDs: 'Transport-Pathways|PWY-6549',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Transport|nitrogen remobilization from senescing leaves',
    },
    {
        BioCyc_ID: 'PWY66-366',
        Common_Name: 'flavin salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Flavin-Biosynthesis|PWY66-366',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Flavin Biosynthesis|flavin salvage',
    },
    {
        BioCyc_ID: 'PWY66-366',
        Common_Name: 'flavin salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Flavin-Biosynthesis|PWY66-366',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Flavin Biosynthesis|flavin salvage',
    },
    {
        BioCyc_ID: 'PWY-6820',
        Common_Name: 'diphyllin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-6820',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|diphyllin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6007',
        Common_Name: '(3<i>E</i>)-4,8-dimethylnona-1,3,7-triene biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|DMNT-Biosynthesis|PWY-6007',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|(3<i>E</i>)-4,8-dimethylnona-1,3,7-triene biosynthesis|(3<i>E</i>)-4,8-dimethylnona-1,3,7-triene biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7182',
        Common_Name: 'linalool biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-7182',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|linalool biosynthesis I',
    },
    {
        BioCyc_ID: 'SORBDEG-PWY',
        Common_Name: 'D-sorbitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|SORBDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|D-sorbitol degradation II',
    },
    {
        BioCyc_ID: 'SORBDEG-PWY',
        Common_Name: 'D-sorbitol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|SORBDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|D-sorbitol degradation II',
    },
    {
        BioCyc_ID: 'PWY-6420',
        Common_Name: 'pyrroloquinoline quinone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|PWY-6420',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|pyrroloquinoline quinone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5955',
        Common_Name: 'versicolorin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-5955',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|versicolorin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5686',
        Common_Name: 'UMP biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Ribonucleot-De-Novo-Biosyn|UMP-Biosynthesis|PWY-5686',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Ribonucleotide <i>De Novo</i> Biosynthesis|UMP Biosynthesis|UMP biosynthesis I',
    },
    {
        BioCyc_ID: 'LEU-DEG2-PWY',
        Common_Name: 'L-leucine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LEUCINE-DEG|LEU-DEG2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-leucine Degradation|L-leucine degradation I',
    },
    {
        BioCyc_ID: 'PWY-7208',
        Common_Name: 'superpathway of pyrimidine nucleobases salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7208',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|superpathway of pyrimidine nucleobases salvage',
    },
    {
        BioCyc_ID: 'PWY-7208',
        Common_Name: 'superpathway of pyrimidine nucleobases salvage',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7208',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pyrimidine nucleobases salvage',
    },
    {
        BioCyc_ID: 'GLCMANNANAUT-PWY',
        Common_Name: 'superpathway of <i>N</i>-acetylglucosamine, <i>N</i>-acetylmannosamine and <i>N</i>-acetylneuraminate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|GLCMANNANAUT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|superpathway of <i>N</i>-acetylglucosamine, <i>N</i>-acetylmannosamine and <i>N</i>-acetylneuraminate degradation',
    },
    {
        BioCyc_ID: 'GLCMANNANAUT-PWY',
        Common_Name: 'superpathway of <i>N</i>-acetylglucosamine, <i>N</i>-acetylmannosamine and <i>N</i>-acetylneuraminate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GLCMANNANAUT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Superpathways|superpathway of <i>N</i>-acetylglucosamine, <i>N</i>-acetylmannosamine and <i>N</i>-acetylneuraminate degradation',
    },
    {
        BioCyc_ID: 'PWY4FS-4',
        Common_Name: 'phosphatidylcholine biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylcholineBiosynthesis|PWY4FS-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylcholine Biosynthesis|phosphatidylcholine biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-5456',
        Common_Name: 'methylglyoxal degradation VII',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Methylglyoxal-Detoxification|PWY-5456',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Methylglyoxal Detoxification|methylglyoxal degradation VII',
    },
    {
        BioCyc_ID: 'PWY-5456',
        Common_Name: 'methylglyoxal degradation VII',
        MetaCyc_hierarchy_IDs: 'Detoxification|Methylglyoxal-Detoxification|PWY-5456',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Methylglyoxal Detoxification|methylglyoxal degradation VII',
    },
    {
        BioCyc_ID: 'PWY0-1597',
        Common_Name: 'prenylated FMNH<sub>2</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Flavin-Biosynthesis|PWY0-1597',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Flavin Biosynthesis|prenylated FMNH<sub>2</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1597',
        Common_Name: 'prenylated FMNH<sub>2</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Flavin-Biosynthesis|PWY0-1597',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Flavin Biosynthesis|prenylated FMNH<sub>2</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7631',
        Common_Name: 'arctigenin and isoarctigenin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-7631',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|arctigenin and isoarctigenin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1921',
        Common_Name: 'indole-3-acetate activation II',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|PWY-1921',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|indole-3-acetate activation II',
    },
    {
        BioCyc_ID: 'PWY-5434',
        Common_Name: '(3<i>E</i>)-4,8-dimethylnona-1,3,7-triene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|DMNT-Biosynthesis|PWY-5434',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|(3<i>E</i>)-4,8-dimethylnona-1,3,7-triene biosynthesis|(3<i>E</i>)-4,8-dimethylnona-1,3,7-triene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-3061',
        Common_Name: 'menthol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-3061',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|menthol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7787',
        Common_Name: 'L-threitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|PWY-7787',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|L-threitol degradation',
    },
    {
        BioCyc_ID: 'PWY-7787',
        Common_Name: 'L-threitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|PWY-7787',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|L-threitol degradation',
    },
    {
        BioCyc_ID: 'PWY-8057',
        Common_Name: 'pyruvoyl group formation from L-serine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|PWY-8057',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|pyruvoyl group formation from L-serine',
    },
    {
        BioCyc_ID: 'PWY-8057',
        Common_Name: 'pyruvoyl group formation from L-serine',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Protein-Modification|PWY-8057',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Protein Modification|pyruvoyl group formation from L-serine',
    },
    {
        BioCyc_ID: 'PWY-7673',
        Common_Name: 'fusarin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7673',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|fusarin C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7673',
        Common_Name: 'fusarin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7673',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|fusarin C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6425',
        Common_Name: 'rotenoid biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|Rotenoids-Biosynthesis|PWY-6425',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|Rotenoid Biosynthesis|rotenoid biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7767',
        Common_Name: 'L-leucine degradation IV (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LEUCINE-DEG|PWY-7767',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-leucine Degradation|L-leucine degradation IV (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-7767',
        Common_Name: 'L-leucine degradation IV (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-7767',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|L-leucine degradation IV (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-7328',
        Common_Name: 'superpathway of UDP-glucose-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7328',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of UDP-glucose-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7328',
        Common_Name: 'superpathway of UDP-glucose-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7328',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|superpathway of UDP-glucose-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6071',
        Common_Name: 'superpathway of phenylethylamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-6071',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|superpathway of phenylethylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-6071',
        Common_Name: 'superpathway of phenylethylamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PWY-6071',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|superpathway of phenylethylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-6071',
        Common_Name: 'superpathway of phenylethylamine degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6071',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of phenylethylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-6323',
        Common_Name: 'benzoylanthranilate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-6323',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|benzoylanthranilate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8429',
        Common_Name: '1-(<i>sn</i>-glycero-1-phospho)-1D-myo-inositol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|PWY-8429',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|1-(<i>sn</i>-glycero-1-phospho)-1D-myo-inositol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6825',
        Common_Name: 'phosphatidylcholine biosynthesis V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylcholineBiosynthesis|PWY-6825',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylcholine Biosynthesis|phosphatidylcholine biosynthesis V',
    },
    {
        BioCyc_ID: 'PWY-5462',
        Common_Name: 'methylglyoxal degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Methylglyoxal-Detoxification|PWY-5462',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Methylglyoxal Detoxification|methylglyoxal degradation II',
    },
    {
        BioCyc_ID: 'PWY-5462',
        Common_Name: 'methylglyoxal degradation II',
        MetaCyc_hierarchy_IDs: 'Detoxification|Methylglyoxal-Detoxification|PWY-5462',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Methylglyoxal Detoxification|methylglyoxal degradation II',
    },
    {
        BioCyc_ID: 'PWY-6168',
        Common_Name: 'flavin biosynthesis III (fungi)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Flavin-Biosynthesis|PWY-6168',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Flavin Biosynthesis|flavin biosynthesis III (fungi)',
    },
    {
        BioCyc_ID: 'PWY-6168',
        Common_Name: 'flavin biosynthesis III (fungi)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Flavin-Biosynthesis|PWY-6168',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Flavin Biosynthesis|flavin biosynthesis III (fungi)',
    },
    {
        BioCyc_ID: 'PWY-5479',
        Common_Name: '6-methoxypodophyllotoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-5479',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|6-methoxypodophyllotoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7896',
        Common_Name: 'isoniazid activation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|PWY-7896',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|isoniazid activation',
    },
    {
        BioCyc_ID: 'PWY-8126',
        Common_Name: 'chlorophyll <i>a</i><sub>2</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-8126',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll <i>a</i><sub>2</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8126',
        Common_Name: 'chlorophyll <i>a</i><sub>2</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-8126',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll <i>a</i><sub>2</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5829',
        Common_Name: 'geraniol and geranial biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-5829',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|geraniol and geranial biosynthesis',
    },
    {
        BioCyc_ID: 'GALACTITOLCAT-PWY',
        Common_Name: 'galactitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|GALACTITOLCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|galactitol degradation',
    },
    {
        BioCyc_ID: 'GALACTITOLCAT-PWY',
        Common_Name: 'galactitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|GALACTITOLCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|galactitol degradation',
    },
    {
        BioCyc_ID: 'PWY-8319',
        Common_Name: 'NiFe(CO)(CN)<sub>2</sub> cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|PWY-8319',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NiFe(CO)(CN)<sub>2</sub> cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8117',
        Common_Name: 'colibactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8117',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|colibactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8117',
        Common_Name: 'colibactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8117',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|colibactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5775',
        Common_Name: 'rotenoid biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|Rotenoids-Biosynthesis|PWY-5775',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|Rotenoid Biosynthesis|rotenoid biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5076',
        Common_Name: 'L-leucine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|LEUCINE-DEG|PWY-5076',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-leucine Degradation|L-leucine degradation III',
    },
    {
        BioCyc_ID: 'PWY-7476',
        Common_Name: 'superpathway of avenacin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7476',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of avenacin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7476',
        Common_Name: 'superpathway of avenacin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7476',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|superpathway of avenacin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6968',
        Common_Name: 'trimethylamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-6968',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|trimethylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-6707',
        Common_Name: 'gallate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-6707',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|gallate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6664',
        Common_Name: 'di-myo-inositol phosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|PWY-6664',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|di-myo-inositol phosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7470',
        Common_Name: 'phosphatidylcholine biosynthesis VII',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylcholineBiosynthesis|PWY-7470',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylcholine Biosynthesis|phosphatidylcholine biosynthesis VII',
    },
    {
        BioCyc_ID: 'MGLDLCTANA-PWY',
        Common_Name: 'methylglyoxal degradation VI',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Methylglyoxal-Detoxification|MGLDLCTANA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Methylglyoxal Detoxification|methylglyoxal degradation VI',
    },
    {
        BioCyc_ID: 'MGLDLCTANA-PWY',
        Common_Name: 'methylglyoxal degradation VI',
        MetaCyc_hierarchy_IDs: 'Detoxification|Methylglyoxal-Detoxification|MGLDLCTANA-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Methylglyoxal Detoxification|methylglyoxal degradation VI',
    },
    {
        BioCyc_ID: 'PWY-5837',
        Common_Name: '2-carboxy-1,4-naphthoquinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|DHNA-Biosynthesis|PWY-5837',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|1,4-Dihydroxy-2-Naphthoate Biosynthesis|2-carboxy-1,4-naphthoquinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7630',
        Common_Name: 'hinokinin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-7630',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|hinokinin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1822',
        Common_Name: 'indole-3-acetate activation I',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|PWY-1822',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|indole-3-acetate activation I',
    },
    {
        BioCyc_ID: 'PWY-5068',
        Common_Name: 'chlorophyll cycle',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-5068',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll cycle',
    },
    {
        BioCyc_ID: 'PWY-5068',
        Common_Name: 'chlorophyll cycle',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-5068',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll cycle',
    },
    {
        BioCyc_ID: 'PWY-6436',
        Common_Name: 'perillyl aldehyde biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-6436',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|perillyl aldehyde biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4101',
        Common_Name: 'D-sorbitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|PWY-4101',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|D-sorbitol degradation I',
    },
    {
        BioCyc_ID: 'PWY-4101',
        Common_Name: 'D-sorbitol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|PWY-4101',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|D-sorbitol degradation I',
    },
    {
        BioCyc_ID: 'PWY-5960',
        Common_Name: 'aflatoxins B<sub>2</sub> and G<sub>2</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-5960',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|aflatoxins B<sub>2</sub> and G<sub>2</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6427',
        Common_Name: "rot-2'-enonate biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|Rotenoids-Biosynthesis|PWY-6427',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|Rotenoid Biosynthesis|rot-2'-enonate biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-5079',
        Common_Name: 'L-phenylalanine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|PHENYLALANINE-DEG|PWY-5079',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-phenylalanine Degradation|L-phenylalanine degradation III',
    },
    {
        BioCyc_ID: 'PWY-7933',
        Common_Name: 'superpathway of polybrominated aromatic compound biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|Halogenated-Aromatics-Biosynthesis|PWY-7933',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Halogenated Aromatic Compound Biosynthesis|superpathway of polybrominated aromatic compound biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7933',
        Common_Name: 'superpathway of polybrominated aromatic compound biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7933',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of polybrominated aromatic compound biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7855',
        Common_Name: 'ectoine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-7855',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|ectoine degradation',
    },
    {
        BioCyc_ID: 'PWY-7074',
        Common_Name: 'phenylethanol glycoconjugate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-7074',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|phenylethanol glycoconjugate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7074',
        Common_Name: 'phenylethanol glycoconjugate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-7074',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|phenylethanol glycoconjugate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4FS-3',
        Common_Name: 'phosphatidylcholine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylcholineBiosynthesis|PWY4FS-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylcholine Biosynthesis|phosphatidylcholine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8428',
        Common_Name: "2-<i>O</i>-(&beta;-D-mannosyl-(1&rarr;2)-&beta;-D-mannosyl)-bis(<i>myo</i>-inositol) 1,3'-phosphate biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|PWY-8428',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|2-<i>O</i>-(&beta;-D-mannosyl-(1&rarr;2)-&beta;-D-mannosyl)-bis(<i>myo</i>-inositol) 1,3'-phosphate biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-5453',
        Common_Name: 'methylglyoxal degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Methylglyoxal-Detoxification|PWY-5453',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Methylglyoxal Detoxification|methylglyoxal degradation III',
    },
    {
        BioCyc_ID: 'PWY-5453',
        Common_Name: 'methylglyoxal degradation III',
        MetaCyc_hierarchy_IDs: 'Detoxification|Methylglyoxal-Detoxification|PWY-5453',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Methylglyoxal Detoxification|methylglyoxal degradation III',
    },
    {
        BioCyc_ID: 'PWY-6552',
        Common_Name: 'caffeine degradation I (main, plants)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|ALKALOIDS|CAFFEINE|PWY-6552',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Alkaloid Degradation|Caffeine Degradation|caffeine degradation I (main, plants)',
    },
    {
        BioCyc_ID: 'PWY-5863',
        Common_Name: 'superpathway of phylloquinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Phylloquinone-Biosynthesis|PWY-5863',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Phylloquinol Biosynthesis|superpathway of phylloquinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5863',
        Common_Name: 'superpathway of phylloquinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5863',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of phylloquinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5469',
        Common_Name: 'sesamin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-5469',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|sesamin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7895',
        Common_Name: 'ethionamide activation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|PWY-7895',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|ethionamide activation',
    },
    {
        BioCyc_ID: 'PWY-7764',
        Common_Name: 'chlorophyll <i>a</i> biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-7764',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll <i>a</i> biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7764',
        Common_Name: 'chlorophyll <i>a</i> biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-7764',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll <i>a</i> biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6447',
        Common_Name: 'trichome monoterpenes biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-6447',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|trichome monoterpenes biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7862',
        Common_Name: 'D-altritol and galactitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|PWY-7862',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|D-altritol and galactitol degradation',
    },
    {
        BioCyc_ID: 'PWY-7862',
        Common_Name: 'D-altritol and galactitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|PWY-7862',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|D-altritol and galactitol degradation',
    },
    {
        BioCyc_ID: 'PWY-7907',
        Common_Name: '(2S,3E)-2-amino-4-methoxy-but-3-enoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7907',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|(2S,3E)-2-amino-4-methoxy-but-3-enoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1269',
        Common_Name: 'CMP-3-deoxy-D-<I>manno</I>-octulosonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|CMP-KDO-Biosynthesis|PWY-1269',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-3-deoxy-D-<I>manno</I>-octulosonate Biosynthesis|CMP-3-deoxy-D-<I>manno</I>-octulosonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8014',
        Common_Name: 'L-phenylalanine degradation VI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|PHENYLALANINE-DEG|PWY-8014',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-phenylalanine Degradation|L-phenylalanine degradation VI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8014',
        Common_Name: 'L-phenylalanine degradation VI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-8014',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|L-phenylalanine degradation VI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY18C3-6',
        Common_Name: 'superpathway of glycoalkaloid metabolism (Solanaceae)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY18C3-6',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|superpathway of glycoalkaloid metabolism (Solanaceae)',
    },
    {
        BioCyc_ID: 'PWY18C3-6',
        Common_Name: 'superpathway of glycoalkaloid metabolism (Solanaceae)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY18C3-6',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glycoalkaloid metabolism (Solanaceae)',
    },
    {
        BioCyc_ID: 'PWY6666-2',
        Common_Name: 'dopamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY6666-2',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|dopamine degradation',
    },
    {
        BioCyc_ID: 'PWY-5751',
        Common_Name: 'phenylethanol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-5751',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|phenylethanol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5751',
        Common_Name: 'phenylethanol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY-5751',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|phenylethanol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7416',
        Common_Name: 'phospholipid remodeling (phosphatidylcholine, yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylcholineBiosynthesis|PWY-7416',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylcholine Biosynthesis|phospholipid remodeling (phosphatidylcholine, yeast)',
    },
    {
        BioCyc_ID: 'PWY-8098',
        Common_Name: 'cyclic 2,3-bisphosphoglycerate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|PWY-8098',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|cyclic 2,3-bisphosphoglycerate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5458',
        Common_Name: 'methylglyoxal degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Methylglyoxal-Detoxification|PWY-5458',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Methylglyoxal Detoxification|methylglyoxal degradation V',
    },
    {
        BioCyc_ID: 'PWY-5458',
        Common_Name: 'methylglyoxal degradation V',
        MetaCyc_hierarchy_IDs: 'Detoxification|Methylglyoxal-Detoxification|PWY-5458',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Methylglyoxal Detoxification|methylglyoxal degradation V',
    },
    {
        BioCyc_ID: 'PWY-6633',
        Common_Name: 'caffeine degradation V (bacteria, via trimethylurate)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|ALKALOIDS|CAFFEINE|PWY-6633',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Alkaloid Degradation|Caffeine Degradation|caffeine degradation V (bacteria, via trimethylurate)',
    },
    {
        BioCyc_ID: 'PWY-5856',
        Common_Name: 'ubiquinol-9 biosynthesis (early decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-5856',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-9 biosynthesis (early decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-6824',
        Common_Name: 'justicidin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|PWY-6824',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|justicidin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4441',
        Common_Name: 'DIMBOA-glucoside activation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|PWY-4441',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|DIMBOA-glucoside activation',
    },
    {
        BioCyc_ID: 'PWY-5064',
        Common_Name: 'chlorophyll <i>a</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-5064',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll <i>a</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5064',
        Common_Name: 'chlorophyll <i>a</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-5064',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll <i>a</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7087',
        Common_Name: '2-methylisoborneol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-7087',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|2-methylisoborneol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7786',
        Common_Name: 'D-threitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|PWY-7786',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|D-threitol degradation',
    },
    {
        BioCyc_ID: 'PWY-7786',
        Common_Name: 'D-threitol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|PWY-7786',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|D-threitol degradation',
    },
    {
        BioCyc_ID: 'PWY-8361',
        Common_Name: 'apratoxin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8361',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|apratoxin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6138',
        Common_Name: 'CMP-<i>N</i>-acetylneuraminate biosynthesis I (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|CMP-N-Acetylneuraminate-Biosynthesis|PWY-6138',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-<i>N</i>-acetylneuraminate Biosynthesis|CMP-<i>N</i>-acetylneuraminate biosynthesis I (eukaryotes)',
    },
    {
        BioCyc_ID: 'PHENYLALANINE-DEG1-PWY',
        Common_Name: 'L-phenylalanine degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|PHENYLALANINE-DEG|PHENYLALANINE-DEG1-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-phenylalanine Degradation|L-phenylalanine degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'CAROTENOID-PWY',
        Common_Name: 'superpathway of carotenoid biosynthesis in plants',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|CAROTENOID-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|superpathway of carotenoid biosynthesis in plants',
    },
    {
        BioCyc_ID: 'CAROTENOID-PWY',
        Common_Name: 'superpathway of carotenoid biosynthesis in plants',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|CAROTENOID-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|superpathway of carotenoid biosynthesis in plants',
    },
    {
        BioCyc_ID: 'CAROTENOID-PWY',
        Common_Name: 'superpathway of carotenoid biosynthesis in plants',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|CAROTENOID-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|superpathway of carotenoid biosynthesis in plants',
    },
    {
        BioCyc_ID: 'CAROTENOID-PWY',
        Common_Name: 'superpathway of carotenoid biosynthesis in plants',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|CAROTENOID-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of carotenoid biosynthesis in plants',
    },
    {
        BioCyc_ID: 'M-CRESOL-DEGRADATION-PWY',
        Common_Name: '<I>m</I>-cresol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|M-CRESOL-DEGRADATION-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<I>m</I>-cresol degradation',
    },
    {
        BioCyc_ID: 'PWY-7303',
        Common_Name: '4-hydroxy-3-prenylbenzoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-7303',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|4-hydroxy-3-prenylbenzoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4FS-2',
        Common_Name: 'phosphatidylcholine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylcholineBiosynthesis|PWY4FS-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylcholine Biosynthesis|phosphatidylcholine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8430',
        Common_Name: '1-(<i>sn</i>-glycero-3-phospho)-1D-myo-inositol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|PWY-8430',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|1-(<i>sn</i>-glycero-3-phospho)-1D-myo-inositol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7128',
        Common_Name: 'nicotine degradation III (VPP pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Nicotine-Degradation|PWY-7128',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Nicotine Degradation|nicotine degradation III (VPP pathway)',
    },
    {
        BioCyc_ID: 'PWY-6538',
        Common_Name: 'caffeine degradation III (bacteria, via demethylation)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|ALKALOIDS|CAFFEINE|PWY-6538',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Alkaloid Degradation|Caffeine Degradation|caffeine degradation III (bacteria, via demethylation)',
    },
    {
        BioCyc_ID: 'PWY-5736',
        Common_Name: 'isopropylamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-5736',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|isopropylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-7780',
        Common_Name: 'butane degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Hydrocarbon-Degradation|PWY-7780',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hydrocarbon Degradation|butane degradation',
    },
    {
        BioCyc_ID: 'PWY-5871',
        Common_Name: 'ubiquinol-9 biosynthesis (late decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-5871',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-9 biosynthesis (late decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-5795',
        Common_Name: 'juglone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-5795',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|juglone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6303',
        Common_Name: 'methyl indole-3-acetate interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Auxin-Biosynthesis|PWY-6303',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Auxin Biosynthesis|methyl indole-3-acetate interconversion',
    },
    {
        BioCyc_ID: 'PWY-6303',
        Common_Name: 'methyl indole-3-acetate interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-6303',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|methyl indole-3-acetate interconversion',
    },
    {
        BioCyc_ID: 'PWY-5086',
        Common_Name: 'chlorophyll <i>a</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-5086',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll <i>a</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5086',
        Common_Name: 'chlorophyll <i>a</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyll-a-Biosynthesis|PWY-5086',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyll <i>a</i> Biosynthesis|chlorophyll <i>a</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7443',
        Common_Name: '(4S)-carvone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-7443',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|(4S)-carvone biosynthesis',
    },
    {
        BioCyc_ID: 'P461-PWY',
        Common_Name: 'hexitol fermentation to lactate, formate, ethanol and acetate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|P461-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|hexitol fermentation to lactate, formate, ethanol and acetate',
    },
    {
        BioCyc_ID: 'P461-PWY',
        Common_Name: 'hexitol fermentation to lactate, formate, ethanol and acetate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-Lactate|P461-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Lactate|hexitol fermentation to lactate, formate, ethanol and acetate',
    },
    {
        BioCyc_ID: 'P461-PWY',
        Common_Name: 'hexitol fermentation to lactate, formate, ethanol and acetate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Ethanol-Fermentation|P461-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Ethanol|hexitol fermentation to lactate, formate, ethanol and acetate',
    },
    {
        BioCyc_ID: 'P461-PWY',
        Common_Name: 'hexitol fermentation to lactate, formate, ethanol and acetate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Ethanol-Fermentation|P461-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Ethanol|hexitol fermentation to lactate, formate, ethanol and acetate',
    },
    {
        BioCyc_ID: 'P461-PWY',
        Common_Name: 'hexitol fermentation to lactate, formate, ethanol and acetate',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|P461-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|hexitol fermentation to lactate, formate, ethanol and acetate',
    },
    {
        BioCyc_ID: 'P461-PWY',
        Common_Name: 'hexitol fermentation to lactate, formate, ethanol and acetate',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|P461-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|hexitol fermentation to lactate, formate, ethanol and acetate',
    },
    {
        BioCyc_ID: 'P461-PWY',
        Common_Name: 'hexitol fermentation to lactate, formate, ethanol and acetate',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P461-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|hexitol fermentation to lactate, formate, ethanol and acetate',
    },
    {
        BioCyc_ID: 'PWY-6699',
        Common_Name: 'oxalate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|PWY-6699',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|oxalate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7958',
        Common_Name: '12-<i>epi</i>-fischerindole biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7958',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|12-<i>epi</i>-fischerindole biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6139',
        Common_Name: 'CMP-<i>N</i>-acetylneuraminate biosynthesis II (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|CMP-N-Acetylneuraminate-Biosynthesis|PWY-6139',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-<i>N</i>-acetylneuraminate Biosynthesis|CMP-<i>N</i>-acetylneuraminate biosynthesis II (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7158',
        Common_Name: 'L-phenylalanine degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|PHENYLALANINE-DEG|PWY-7158',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-phenylalanine Degradation|L-phenylalanine degradation V',
    },
    {
        BioCyc_ID: 'OANTIGEN-PWY',
        Common_Name: '<i>O</i>-antigen building blocks biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|OANTIGEN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|<i>O</i>-antigen building blocks biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'OANTIGEN-PWY',
        Common_Name: '<i>O</i>-antigen building blocks biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|OANTIGEN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|<i>O</i>-antigen building blocks biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'OANTIGEN-PWY',
        Common_Name: '<i>O</i>-antigen building blocks biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|OANTIGEN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|<i>O</i>-antigen building blocks biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-5490',
        Common_Name: 'paraoxon degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-5490',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|paraoxon degradation',
    },
    {
        BioCyc_ID: 'PWY-5886',
        Common_Name: '3-(4-hydroxyphenyl)pyruvate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-5886',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|3-(4-hydroxyphenyl)pyruvate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6826',
        Common_Name: 'phosphatidylcholine biosynthesis VI',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylcholineBiosynthesis|PWY-6826',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylcholine Biosynthesis|phosphatidylcholine biosynthesis VI',
    },
    {
        BioCyc_ID: 'PWY-7054',
        Common_Name: '<i>N</i>-acetylglutaminylglutamine amide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|PWY-7054',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|<i>N</i>-acetylglutaminylglutamine amide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6993',
        Common_Name: 'nicotine degradation II (pyrrolidine pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Nicotine-Degradation|PWY-6993',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Nicotine Degradation|nicotine degradation II (pyrrolidine pathway)',
    },
    {
        BioCyc_ID: 'PWY-6553',
        Common_Name: 'caffeine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|ALKALOIDS|CAFFEINE|PWY-6553',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Alkaloid Degradation|Caffeine Degradation|caffeine degradation II',
    },
    {
        BioCyc_ID: 'PWY-6962',
        Common_Name: 'superpathway of trimethylamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-6962',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|superpathway of trimethylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-6962',
        Common_Name: 'superpathway of trimethylamine degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6962',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of trimethylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-2724',
        Common_Name: 'alkane oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Hydrocarbon-Degradation|PWY-2724',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hydrocarbon Degradation|alkane oxidation',
    },
    {
        BioCyc_ID: 'PWY-6708',
        Common_Name: 'ubiquinol-8 biosynthesis (early decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-6708',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-8 biosynthesis (early decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-5987',
        Common_Name: 'sorgoleone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-5987',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|sorgoleone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2343',
        Common_Name: 'daidzein conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2343',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|daidzein conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2343',
        Common_Name: 'daidzein conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2343',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|daidzein conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2343',
        Common_Name: 'daidzein conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-2343',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|daidzein conjugates interconversion',
    },
    {
        BioCyc_ID: 'CHLOROPHYLL-SYN',
        Common_Name: '3,8-divinyl-chlorophyllide <i>a</i> biosynthesis I (aerobic, light-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyllide-a-Biosynthesis|CHLOROPHYLL-SYN',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyllide <i>a</i> Biosynthesis|3,8-divinyl-chlorophyllide <i>a</i> biosynthesis I (aerobic, light-dependent)',
    },
    {
        BioCyc_ID: 'CHLOROPHYLL-SYN',
        Common_Name: '3,8-divinyl-chlorophyllide <i>a</i> biosynthesis I (aerobic, light-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyllide-a-Biosynthesis|CHLOROPHYLL-SYN',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyllide <i>a</i> Biosynthesis|3,8-divinyl-chlorophyllide <i>a</i> biosynthesis I (aerobic, light-dependent)',
    },
    {
        BioCyc_ID: 'PWY-5813',
        Common_Name: 'bornyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-5813',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|bornyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6531',
        Common_Name: 'mannitol cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|PWY-6531',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|mannitol cycle',
    },
    {
        BioCyc_ID: 'PWY-6531',
        Common_Name: 'mannitol cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|PWY-6531',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|mannitol cycle',
    },
    {
        BioCyc_ID: 'PWY-5782',
        Common_Name: '2-keto-L-gulonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|PWY-5782',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|2-keto-L-gulonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8408',
        Common_Name: 'erinacine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8408',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|erinacine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7131',
        Common_Name: 'CMP-legionaminate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|CMP-Legionaminate-Biosynthesis|PWY-7131',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-legionaminate biosynthesis|CMP-legionaminate biosynthesis II',
    },
    {
        BioCyc_ID: 'ANAPHENOXI-PWY',
        Common_Name: 'L-phenylalanine degradation II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|PHENYLALANINE-DEG|ANAPHENOXI-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-phenylalanine Degradation|L-phenylalanine degradation II (anaerobic)',
    },
    {
        BioCyc_ID: 'PEPTIDOGLYCANSYN-PWY',
        Common_Name: 'peptidoglycan biosynthesis I (<I>meso</I>-diaminopimelate containing)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Biosynthesis|PEPTIDOGLYCANSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Biosynthesis|peptidoglycan biosynthesis I (<I>meso</I>-diaminopimelate containing)',
    },
    {
        BioCyc_ID: 'PEPTIDOGLYCANSYN-PWY',
        Common_Name: 'peptidoglycan biosynthesis I (<I>meso</I>-diaminopimelate containing)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PEPTIDOGLYCANSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|peptidoglycan biosynthesis I (<I>meso</I>-diaminopimelate containing)',
    },
    {
        BioCyc_ID: 'PWY-7002',
        Common_Name: '4-hydroxyacetophenone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7002',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|4-hydroxyacetophenone degradation',
    },
    {
        BioCyc_ID: 'PWY-6320',
        Common_Name: 'phaselate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-6320',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|phaselate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7625',
        Common_Name: 'phosphatidylinositol biosynthesis II (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Phosphatidylinositol-Biosynthesis|PWY-7625',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylinositol Biosynthesis|phosphatidylinositol biosynthesis II (eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-7882',
        Common_Name: 'IM-2 type &gamma;-butyrolactones biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Gamma-Butyrolactone-Biosynthesis|PWY-7882',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|&gamma;-butyrolactone biosynthesis|IM-2 type &gamma;-butyrolactones biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-221',
        Common_Name: 'nicotine degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Nicotine-Degradation|PWY66-221',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Nicotine Degradation|nicotine degradation V',
    },
    {
        BioCyc_ID: 'PWY-804',
        Common_Name: 'glycolate degradation II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|PWY-804',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|glycolate degradation II',
    },
    {
        BioCyc_ID: 'PWY-7431',
        Common_Name: 'aromatic biogenic amine degradation (bacteria)',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-7431',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|aromatic biogenic amine degradation (bacteria)',
    },
    {
        BioCyc_ID: 'PWY3O-19',
        Common_Name: 'ubiquinol-6 biosynthesis (late decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY3O-19',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-6 biosynthesis (late decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-7518',
        Common_Name: 'atromentin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-7518',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|atromentin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7057',
        Common_Name: 'cichoriin interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-7057',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|cichoriin interconversion',
    },
    {
        BioCyc_ID: 'PWY-7057',
        Common_Name: 'cichoriin interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-7057',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|cichoriin interconversion',
    },
    {
        BioCyc_ID: 'PWY-7159',
        Common_Name: '3,8-divinyl-chlorophyllide <i>a</i> biosynthesis III (aerobic, light independent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyllide-a-Biosynthesis|PWY-7159',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyllide <i>a</i> Biosynthesis|3,8-divinyl-chlorophyllide <i>a</i> biosynthesis III (aerobic, light independent)',
    },
    {
        BioCyc_ID: 'PWY-7159',
        Common_Name: '3,8-divinyl-chlorophyllide <i>a</i> biosynthesis III (aerobic, light independent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyllide-a-Biosynthesis|PWY-7159',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyllide <i>a</i> Biosynthesis|3,8-divinyl-chlorophyllide <i>a</i> biosynthesis III (aerobic, light independent)',
    },
    {
        BioCyc_ID: 'PWY-5928',
        Common_Name: '(4<i>R</i>)-carvone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-5928',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|(4<i>R</i>)-carvone biosynthesis',
    },
    {
        BioCyc_ID: 'HEXITOLDEGSUPER-PWY',
        Common_Name: 'superpathway of hexitol degradation (bacteria)',
        MetaCyc_hierarchy_IDs: 'Degradation|Alcohol-Degradation|SUGAR-ALCOHOLS-DEG|HEXITOLDEGSUPER-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Alcohol Degradation|Alditol Degradation|superpathway of hexitol degradation (bacteria)',
    },
    {
        BioCyc_ID: 'HEXITOLDEGSUPER-PWY',
        Common_Name: 'superpathway of hexitol degradation (bacteria)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|SUGAR-ALCOHOLS-DEG|HEXITOLDEGSUPER-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Alditol Degradation|superpathway of hexitol degradation (bacteria)',
    },
    {
        BioCyc_ID: 'HEXITOLDEGSUPER-PWY',
        Common_Name: 'superpathway of hexitol degradation (bacteria)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|HEXITOLDEGSUPER-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of hexitol degradation (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-8044',
        Common_Name: 'cyclohexane-1-carboxyl-CoA biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|PWY-8044',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|cyclohexane-1-carboxyl-CoA biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7490',
        Common_Name: 'patulin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7490',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|patulin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6749',
        Common_Name: 'CMP-legionaminate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|CMP-Legionaminate-Biosynthesis|PWY-6749',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-legionaminate biosynthesis|CMP-legionaminate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6318',
        Common_Name: 'L-phenylalanine degradation IV (mammalian, via side chain)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|PHENYLALANINE-DEG|PWY-6318',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-phenylalanine Degradation|L-phenylalanine degradation IV (mammalian, via side chain)',
    },
    {
        BioCyc_ID: 'PWY-7951',
        Common_Name: 'ellagic acid degradation to urolithins',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7951',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|ellagic acid degradation to urolithins',
    },
    {
        BioCyc_ID: 'PWY-5360',
        Common_Name: 'superpathway of tetrathionate reduction (<i>Salmonella typhimurium</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5360',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of tetrathionate reduction (<i>Salmonella typhimurium</i>)',
    },
    {
        BioCyc_ID: 'PWY-5360',
        Common_Name: 'superpathway of tetrathionate reduction (<i>Salmonella typhimurium</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Tetrathionate-Reduction|PWY-5360',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Tetrathionate Reduction|superpathway of tetrathionate reduction (<i>Salmonella typhimurium</i>)',
    },
    {
        BioCyc_ID: 'PWY-6458',
        Common_Name: 'benzoyl-CoA biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-6458',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|benzoyl-CoA biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6580',
        Common_Name: 'phosphatidylinositol biosynthesis I (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Phosphatidylinositol-Biosynthesis|PWY-6580',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylinositol Biosynthesis|phosphatidylinositol biosynthesis I (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7881',
        Common_Name: 'virginiae butanolide type &gamma;-butyrolactones biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Gamma-Butyrolactone-Biosynthesis|PWY-7881',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|&gamma;-butyrolactone biosynthesis|virginiae butanolide type &gamma;-butyrolactones biosynthesis',
    },
    {
        BioCyc_ID: 'P181-PWY',
        Common_Name: 'nicotine degradation I (pyridine pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Nicotine-Degradation|P181-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Nicotine Degradation|nicotine degradation I (pyridine pathway)',
    },
    {
        BioCyc_ID: 'PWY0-1312',
        Common_Name: 'acetate and ATP formation from acetyl-CoA I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|PWY0-1312',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|acetate and ATP formation from acetyl-CoA I',
    },
    {
        BioCyc_ID: 'PWY0-1477',
        Common_Name: 'ethanolamine utilization',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY0-1477',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|ethanolamine utilization',
    },
    {
        BioCyc_ID: 'PWY-5855',
        Common_Name: 'ubiquinol-7 biosynthesis (early decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-5855',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-7 biosynthesis (early decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-5780',
        Common_Name: 'hypericin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-5780',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|hypericin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2701',
        Common_Name: 'maackiain conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2701',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|maackiain conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2701',
        Common_Name: 'maackiain conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2701',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|maackiain conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2701',
        Common_Name: 'maackiain conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-2701',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|maackiain conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-5531',
        Common_Name: '3,8-divinyl-chlorophyllide <i>a</i> biosynthesis II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyllide-a-Biosynthesis|PWY-5531',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|Chlorophyllide <i>a</i> Biosynthesis|3,8-divinyl-chlorophyllide <i>a</i> biosynthesis II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5531',
        Common_Name: '3,8-divinyl-chlorophyllide <i>a</i> biosynthesis II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|Chlorophyllide-a-Biosynthesis|PWY-5531',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|Chlorophyllide <i>a</i> Biosynthesis|3,8-divinyl-chlorophyllide <i>a</i> biosynthesis II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-6445',
        Common_Name: 'fenchol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-6445',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|fenchol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8143',
        Common_Name: 'L-ascorbate biosynthesis VII (plants, D-galacturonate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Ascorbate-Biosynthesis|PWY-8143',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VII (plants, D-galacturonate pathway)',
    },
    {
        BioCyc_ID: 'PWY-8143',
        Common_Name: 'L-ascorbate biosynthesis VII (plants, D-galacturonate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Ascorbate-Biosynthesis|PWY-8143',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VII (plants, D-galacturonate pathway)',
    },
    {
        BioCyc_ID: 'PWY-8143',
        Common_Name: 'L-ascorbate biosynthesis VII (plants, D-galacturonate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Ascorbate-Biosynthesis|PWY-8143',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VII (plants, D-galacturonate pathway)',
    },
    {
        BioCyc_ID: 'PWY-7531',
        Common_Name: 'mannojirimycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7531',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|mannojirimycin biosynthesis',
    },
    {
        BioCyc_ID: 'UDPNACETYLGALSYN-PWY',
        Common_Name: 'UDP-<i>N</i>-acetyl-D-glucosamine biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|UDP-NAc-Glucosamine-Biosynthesis|UDPNACETYLGALSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-D-glucosamine Biosynthesis|UDP-<i>N</i>-acetyl-D-glucosamine biosynthesis II',
    },
    {
        BioCyc_ID: 'ALACAT2-PWY',
        Common_Name: 'L-alanine degradation II (to D-lactate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ALANINE-DEG|ALACAT2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-alanine Degradation|L-alanine degradation II (to D-lactate)',
    },
    {
        BioCyc_ID: 'PWY-5507',
        Common_Name: 'adenosylcobalamin biosynthesis I (anaerobic)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-De-Novo-Biosynthesis|Adenosylcobamide-Biosynthesis|De-Novo-Adenosylcobalamin-Biosynthesis|PWY-5507',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobamide <i>de novo</i> Biosynthesis|Adenosylcobamide Biosynthesis|Adenosylcobalamin <i>de novo</i> Biosynthesis|adenosylcobalamin biosynthesis I (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5507',
        Common_Name: 'adenosylcobalamin biosynthesis I (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5507',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|adenosylcobalamin biosynthesis I (anaerobic)',
    },
    {
        BioCyc_ID: 'P343-PWY',
        Common_Name: 'resorcinol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|P343-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|resorcinol degradation',
    },
    {
        BioCyc_ID: 'PWY-6766',
        Common_Name: 'salicin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-6766',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|salicin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5269',
        Common_Name: 'cardiolipin biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Cardiolipin-Biosynthesis|PWY-5269',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Cardiolipin Biosynthesis|cardiolipin biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7878',
        Common_Name: 'A-factor &gamma;-butyrolactone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Gamma-Butyrolactone-Biosynthesis|PWY-7878',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|&gamma;-butyrolactone biosynthesis|A-factor &gamma;-butyrolactone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-201',
        Common_Name: 'nicotine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Nicotine-Degradation|PWY66-201',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Nicotine Degradation|nicotine degradation IV',
    },
    {
        BioCyc_ID: 'P161-PWY',
        Common_Name: 'acetylene degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|P161-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|acetylene degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'P161-PWY',
        Common_Name: 'acetylene degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Ethanol-Fermentation|P161-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Ethanol|acetylene degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'P161-PWY',
        Common_Name: 'acetylene degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Ethanol-Fermentation|P161-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Ethanol|acetylene degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'ORNDEG-PWY',
        Common_Name: 'superpathway of ornithine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|ORNDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|superpathway of ornithine degradation',
    },
    {
        BioCyc_ID: 'ORNDEG-PWY',
        Common_Name: 'superpathway of ornithine degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ORNDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of ornithine degradation',
    },
    {
        BioCyc_ID: 'PWY-5870',
        Common_Name: 'ubiquinol-8 biosynthesis (late decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-5870',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-8 biosynthesis (late decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-5802',
        Common_Name: 'alizarin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-5802',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|alizarin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7949',
        Common_Name: 'diadinoxanthin and diatoxanthin interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7949',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|diadinoxanthin and diatoxanthin interconversion',
    },
    {
        BioCyc_ID: 'PWY-7949',
        Common_Name: 'diadinoxanthin and diatoxanthin interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7949',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|diadinoxanthin and diatoxanthin interconversion',
    },
    {
        BioCyc_ID: 'PWY-7949',
        Common_Name: 'diadinoxanthin and diatoxanthin interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-7949',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|diadinoxanthin and diatoxanthin interconversion',
    },
    {
        BioCyc_ID: 'PWY-7949',
        Common_Name: 'diadinoxanthin and diatoxanthin interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-7949',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|diadinoxanthin and diatoxanthin interconversion',
    },
    {
        BioCyc_ID: 'PWY-7347',
        Common_Name: 'sucrose biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Sucrose-Biosynthesis|PWY-7347',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Sucrose Biosynthesis|sucrose biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6451',
        Common_Name: '3-carene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-6451',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|3-carene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6341',
        Common_Name: 'guaiacylglycerol-&beta;-guaiacyl ether degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|PWY-6341',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|guaiacylglycerol-&beta;-guaiacyl ether degradation',
    },
    {
        BioCyc_ID: 'PWY4FS-11',
        Common_Name: 'L-ascorbate biosynthesis II (plants, L-gulose pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Ascorbate-Biosynthesis|PWY4FS-11',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis II (plants, L-gulose pathway)',
    },
    {
        BioCyc_ID: 'PWY4FS-11',
        Common_Name: 'L-ascorbate biosynthesis II (plants, L-gulose pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Ascorbate-Biosynthesis|PWY4FS-11',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis II (plants, L-gulose pathway)',
    },
    {
        BioCyc_ID: 'PWY4FS-11',
        Common_Name: 'L-ascorbate biosynthesis II (plants, L-gulose pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Ascorbate-Biosynthesis|PWY4FS-11',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis II (plants, L-gulose pathway)',
    },
    {
        BioCyc_ID: 'PWY-8076',
        Common_Name: '8-<i>O</i>-methylated benzoxazinoid glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8076',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|8-<i>O</i>-methylated benzoxazinoid glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'UDPNAGSYN-PWY',
        Common_Name: 'UDP-<i>N</i>-acetyl-D-glucosamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|UDP-NAc-Glucosamine-Biosynthesis|UDPNAGSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-D-glucosamine Biosynthesis|UDP-<i>N</i>-acetyl-D-glucosamine biosynthesis I',
    },
    {
        BioCyc_ID: 'ALANINE-DEG3-PWY',
        Common_Name: 'L-alanine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ALANINE-DEG|ALANINE-DEG3-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-alanine Degradation|L-alanine degradation III',
    },
    {
        BioCyc_ID: 'PWY-5920',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from glycine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY-5920',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|superpathway of heme <i>b</i> biosynthesis from glycine',
    },
    {
        BioCyc_ID: 'PWY-5920',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from glycine',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|Heme-b-Biosynthesis|PWY-5920',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|Heme <i>b</i> Biosynthesis|superpathway of heme <i>b</i> biosynthesis from glycine',
    },
    {
        BioCyc_ID: 'PWY-5920',
        Common_Name: 'superpathway of heme <i>b</i> biosynthesis from glycine',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5920',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of heme <i>b</i> biosynthesis from glycine',
    },
    {
        BioCyc_ID: 'PWY-6185',
        Common_Name: '4-methylcatechol degradation (<i>ortho</i> cleavage)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6185',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|4-methylcatechol degradation (<i>ortho</i> cleavage)',
    },
    {
        BioCyc_ID: 'PWY-7076',
        Common_Name: '3,5-dimethoxytoluene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-7076',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|3,5-dimethoxytoluene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7076',
        Common_Name: '3,5-dimethoxytoluene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-7076',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|3,5-dimethoxytoluene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5668',
        Common_Name: 'cardiolipin biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Cardiolipin-Biosynthesis|PWY-5668',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Cardiolipin Biosynthesis|cardiolipin biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY66-374',
        Common_Name: 'C20 prostanoid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Signaling-Compound-Biosynthesis|PWY66-374',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Signaling Molecule Biosynthesis|C20 prostanoid biosynthesis',
    },
    {
        BioCyc_ID: 'P581-PWY',
        Common_Name: 'thiocyanate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Thiocyanate-Degradation|P581-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Thiocyanate Degradation|thiocyanate degradation I',
    },
    {
        BioCyc_ID: 'PWY-5536',
        Common_Name: 'acetate formation from acetyl-CoA (succinate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|PWY-5536',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|acetate formation from acetyl-CoA (succinate)',
    },
    {
        BioCyc_ID: 'PWY-6181',
        Common_Name: 'histamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|PWY-6181',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|histamine degradation',
    },
    {
        BioCyc_ID: 'PWY-5873',
        Common_Name: 'ubiquinol-7 biosynthesis (late decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-5873',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-7 biosynthesis (late decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-7080',
        Common_Name: 'asterrate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-7080',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|asterrate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5441',
        Common_Name: 'S-methyl-L-methionine cycle',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-5441',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|S-methyl-L-methionine cycle',
    },
    {
        BioCyc_ID: 'PWY-7238',
        Common_Name: 'sucrose biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|Sucrose-Biosynthesis|PWY-7238',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|Sucrose Biosynthesis|sucrose biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY0-1546',
        Common_Name: 'muropeptide degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|PWY0-1546',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|muropeptide degradation',
    },
    {
        BioCyc_ID: 'PWY-5521',
        Common_Name: 'L-ascorbate biosynthesis III (D-sorbitol pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Ascorbate-Biosynthesis|PWY-5521',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis III (D-sorbitol pathway)',
    },
    {
        BioCyc_ID: 'PWY-5521',
        Common_Name: 'L-ascorbate biosynthesis III (D-sorbitol pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Ascorbate-Biosynthesis|PWY-5521',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis III (D-sorbitol pathway)',
    },
    {
        BioCyc_ID: 'PWY-5521',
        Common_Name: 'L-ascorbate biosynthesis III (D-sorbitol pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Ascorbate-Biosynthesis|PWY-5521',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis III (D-sorbitol pathway)',
    },
    {
        BioCyc_ID: 'PWY-5954',
        Common_Name: "(1'<i>S</i>,5'<i>S</i>)-averufin biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-5954',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|(1'<i>S</i>,5'<i>S</i>)-averufin biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-8013',
        Common_Name: 'UDP-<i>N</i>-acetyl-D-galactosamine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|UDP-Nac-Galactosamine-Biosynthesis|PWY-8013',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-D-galactosamine Biosynthesis|UDP-<i>N</i>-acetyl-D-galactosamine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY1-2',
        Common_Name: 'L-alanine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ALANINE-DEG|PWY1-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-alanine Degradation|L-alanine degradation IV',
    },
    {
        BioCyc_ID: 'PWY-6176',
        Common_Name: 'superpathway of Allium flavor precursors',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|S-CONTAINING-SECONDARY-CMPD-SYN|PWY-6176',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sulfur-Containing Secondary Compound Biosynthesis|superpathway of Allium flavor precursors',
    },
    {
        BioCyc_ID: 'PWY-6176',
        Common_Name: 'superpathway of Allium flavor precursors',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6176',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of Allium flavor precursors',
    },
    {
        BioCyc_ID: 'PWY0-1545',
        Common_Name: 'cardiolipin biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Cardiolipin-Biosynthesis|PWY0-1545',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Cardiolipin Biosynthesis|cardiolipin biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-5787',
        Common_Name: 'oligomeric urushiol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-5787',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|oligomeric urushiol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7009',
        Common_Name: "2,2'-dihydroxybiphenyl degradation",
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7009',
        MetaCyc_hierarchy_Names: "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2,2'-dihydroxybiphenyl degradation",
    },
    {
        BioCyc_ID: 'PWY0-1433',
        Common_Name: 'tetrahydromonapterin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Signaling-Compound-Biosynthesis|PWY0-1433',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Signaling Molecule Biosynthesis|tetrahydromonapterin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-743',
        Common_Name: 'thiocyanate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Thiocyanate-Degradation|PWY-743',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Thiocyanate Degradation|thiocyanate degradation II',
    },
    {
        BioCyc_ID: 'PWY-8377',
        Common_Name: 'lactate fermentation to acetate, CO<sub>2</sub> and hydrogen (<i>Desulfovibrionales</i>)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|PWY-8377',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|lactate fermentation to acetate, CO<sub>2</sub> and hydrogen (<i>Desulfovibrionales</i>)',
    },
    {
        BioCyc_ID: 'PWY-6517',
        Common_Name: '<i>N</i>-acetylglucosamine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|N-Acetylglucosamine-Degradation|PWY-6517',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|<i>N</i>-acetylglucosamine degradation|<i>N</i>-acetylglucosamine degradation II',
    },
    {
        BioCyc_ID: 'PWY-6517',
        Common_Name: '<i>N</i>-acetylglucosamine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|N-Acetylglucosamine-Degradation|PWY-6517',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|<i>N</i>-acetylglucosamine degradation|<i>N</i>-acetylglucosamine degradation II',
    },
    {
        BioCyc_ID: 'PWY-6517',
        Common_Name: '<i>N</i>-acetylglucosamine degradation II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6517',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|<i>N</i>-acetylglucosamine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7235',
        Common_Name: 'superpathway of ubiquinol-6 biosynthesis (late decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7235',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of ubiquinol-6 biosynthesis (late decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-7235',
        Common_Name: 'superpathway of ubiquinol-6 biosynthesis (late decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-7235',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|superpathway of ubiquinol-6 biosynthesis (late decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-5701',
        Common_Name: 'shikonin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-5701',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|shikonin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5945',
        Common_Name: 'violaxanthin, antheraxanthin and zeaxanthin interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5945',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|violaxanthin, antheraxanthin and zeaxanthin interconversion',
    },
    {
        BioCyc_ID: 'PWY-5945',
        Common_Name: 'violaxanthin, antheraxanthin and zeaxanthin interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5945',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|violaxanthin, antheraxanthin and zeaxanthin interconversion',
    },
    {
        BioCyc_ID: 'PWY-5945',
        Common_Name: 'violaxanthin, antheraxanthin and zeaxanthin interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|PWY-5945',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|violaxanthin, antheraxanthin and zeaxanthin interconversion',
    },
    {
        BioCyc_ID: 'PWY-5945',
        Common_Name: 'violaxanthin, antheraxanthin and zeaxanthin interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-5945',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|violaxanthin, antheraxanthin and zeaxanthin interconversion',
    },
    {
        BioCyc_ID: 'PWY-6473',
        Common_Name: '4-aminobutanoate degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|4-Aminobutyraye-Degradation|PWY-6473',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|4-Aminobutanoate Degradation|4-aminobutanoate degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7794',
        Common_Name: 'polyethylene terephthalate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|PWY-7794',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|polyethylene terephthalate degradation',
    },
    {
        BioCyc_ID: 'PWY-8142',
        Common_Name: 'L-ascorbate biosynthesis VI (plants, <i>myo</i>-inositol pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Ascorbate-Biosynthesis|PWY-8142',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VI (plants, <i>myo</i>-inositol pathway)',
    },
    {
        BioCyc_ID: 'PWY-8142',
        Common_Name: 'L-ascorbate biosynthesis VI (plants, <i>myo</i>-inositol pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Ascorbate-Biosynthesis|PWY-8142',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VI (plants, <i>myo</i>-inositol pathway)',
    },
    {
        BioCyc_ID: 'PWY-8142',
        Common_Name: 'L-ascorbate biosynthesis VI (plants, <i>myo</i>-inositol pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Ascorbate-Biosynthesis|PWY-8142',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VI (plants, <i>myo</i>-inositol pathway)',
    },
    {
        BioCyc_ID: 'PWY-7659',
        Common_Name: 'viridicatumtoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-7659',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|viridicatumtoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7659',
        Common_Name: 'viridicatumtoxin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-7659',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|viridicatumtoxin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5514',
        Common_Name: 'UDP-<i>N</i>-acetyl-D-galactosamine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|UDP-Nac-Galactosamine-Biosynthesis|PWY-5514',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-D-galactosamine Biosynthesis|UDP-<i>N</i>-acetyl-D-galactosamine biosynthesis II',
    },
    {
        BioCyc_ID: 'ALADEG-PWY',
        Common_Name: 'L-alanine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ALANINE-DEG|ALADEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-alanine Degradation|L-alanine degradation I',
    },
    {
        BioCyc_ID: 'PWY-6467',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Chlamydia</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-6467',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>Chlamydia</i>)',
    },
    {
        BioCyc_ID: 'PWY-6467',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Chlamydia</i>)',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Lipopolysaccharide-Biosynthesis|KDO-Lipid-IV-Transfer|PWY-6467',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Lipopolysaccharide Biosynthesis|Kdo Transfer to Lipid IV<SUB>A</SUB>|Kdo transfer to lipid IV<sub>A</sub> (<i>Chlamydia</i>)',
    },
    {
        BioCyc_ID: 'PWY-6467',
        Common_Name: 'Kdo transfer to lipid IV<sub>A</sub> (<i>Chlamydia</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6467',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|Kdo transfer to lipid IV<sub>A</sub> (<i>Chlamydia</i>)',
    },
    {
        BioCyc_ID: 'PWY-7782',
        Common_Name: 'plasmalogen biosynthesis I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Plasmalogen-Biosynthesis|PWY-7782',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Plasmalogen Biosynthesis|plasmalogen biosynthesis I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-5979',
        Common_Name: '3-amino-5-hydroxybenzoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-5979',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|3-amino-5-hydroxybenzoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5979',
        Common_Name: '3-amino-5-hydroxybenzoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5979',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|3-amino-5-hydroxybenzoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8111',
        Common_Name: 'carbaryl degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-8111',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|carbaryl degradation',
    },
    {
        BioCyc_ID: 'PWY66-375',
        Common_Name: 'leukotriene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Signaling-Compound-Biosynthesis|PWY66-375',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Signaling Molecule Biosynthesis|leukotriene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7466',
        Common_Name: 'acetone degradation III (to propane-1,2-diol)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Acetone-Degradation|PWY-7466',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Acetone Degradation|acetone degradation III (to propane-1,2-diol)',
    },
    {
        BioCyc_ID: 'PWY-5535',
        Common_Name: 'acetate and ATP formation from acetyl-CoA II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|PWY-5535',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|acetate and ATP formation from acetyl-CoA II',
    },
    {
        BioCyc_ID: 'GLUAMCAT-PWY',
        Common_Name: '<i>N</i>-acetylglucosamine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|N-Acetylglucosamine-Degradation|GLUAMCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|<i>N</i>-acetylglucosamine degradation|<i>N</i>-acetylglucosamine degradation I',
    },
    {
        BioCyc_ID: 'GLUAMCAT-PWY',
        Common_Name: '<i>N</i>-acetylglucosamine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Sugar-Derivatives|N-Acetylglucosamine-Degradation|GLUAMCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Sugar Derivative Degradation|<i>N</i>-acetylglucosamine degradation|<i>N</i>-acetylglucosamine degradation I',
    },
    {
        BioCyc_ID: 'PWY-5857',
        Common_Name: 'ubiquinol-10 biosynthesis (early decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-5857',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-10 biosynthesis (early decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-5801',
        Common_Name: 'lawsone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-5801',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|lawsone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7056',
        Common_Name: 'daphnin interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-7056',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|daphnin interconversion',
    },
    {
        BioCyc_ID: 'PWY-7056',
        Common_Name: 'daphnin interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-7056',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|daphnin interconversion',
    },
    {
        BioCyc_ID: 'PWY-6537',
        Common_Name: '4-aminobutanoate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|4-Aminobutyraye-Degradation|PWY-6537',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|4-Aminobutanoate Degradation|4-aminobutanoate degradation II',
    },
    {
        BioCyc_ID: 'PWY3DJ-35471',
        Common_Name: 'L-ascorbate biosynthesis IV (animals, D-glucuronate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Ascorbate-Biosynthesis|PWY3DJ-35471',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis IV (animals, D-glucuronate pathway)',
    },
    {
        BioCyc_ID: 'PWY3DJ-35471',
        Common_Name: 'L-ascorbate biosynthesis IV (animals, D-glucuronate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Ascorbate-Biosynthesis|PWY3DJ-35471',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis IV (animals, D-glucuronate pathway)',
    },
    {
        BioCyc_ID: 'PWY3DJ-35471',
        Common_Name: 'L-ascorbate biosynthesis IV (animals, D-glucuronate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Ascorbate-Biosynthesis|PWY3DJ-35471',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis IV (animals, D-glucuronate pathway)',
    },
    {
        BioCyc_ID: 'PWY-8116',
        Common_Name: 'ochratoxin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|POLYKETIDE-SYN|PWY-8116',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Polyketide Biosynthesis|ochratoxin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8116',
        Common_Name: 'ochratoxin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PWY-8116',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|ochratoxin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5512',
        Common_Name: 'UDP-<i>N</i>-acetyl-D-galactosamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|UDP-Nac-Galactosamine-Biosynthesis|PWY-5512',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-D-galactosamine Biosynthesis|UDP-<i>N</i>-acetyl-D-galactosamine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8189',
        Common_Name: 'L-alanine degradation V (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ALANINE-DEG|PWY-8189',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-alanine Degradation|L-alanine degradation V (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8189',
        Common_Name: 'L-alanine degradation V (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Oxidative|PWY-8189',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Oxidative Branch)|L-alanine degradation V (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-6594',
        Common_Name: 'superpathway of <i>Clostridium acetobutylicum</i> solventogenic fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|PWY-6594',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|superpathway of <i>Clostridium acetobutylicum</i> solventogenic fermentation',
    },
    {
        BioCyc_ID: 'PWY-6594',
        Common_Name: 'superpathway of <i>Clostridium acetobutylicum</i> solventogenic fermentation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6594',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of <i>Clostridium acetobutylicum</i> solventogenic fermentation',
    },
    {
        BioCyc_ID: 'PWY-8335',
        Common_Name: 'plasmalogen biosynthesis II (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Plasmalogen-Biosynthesis|PWY-8335',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Plasmalogen Biosynthesis|plasmalogen biosynthesis II (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-6457',
        Common_Name: 'cinnamoyl-CoA biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-6457',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|cinnamoyl-CoA biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6457',
        Common_Name: 'cinnamoyl-CoA biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|CINNAMATE-SYN|PWY-6457',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Cinnamate Biosynthesis|cinnamoyl-CoA biosynthesis',
    },
    {
        BioCyc_ID: 'P662-PWY',
        Common_Name: 'dibenzofuran degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|P662-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|dibenzofuran degradation',
    },
    {
        BioCyc_ID: 'PWY-6030',
        Common_Name: 'serotonin and melatonin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Neurotransmitter-Biosynthesis|PWY-6030',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Neurotransmitter Biosynthesis|serotonin and melatonin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6373',
        Common_Name: 'acrylate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Acrylate-Degradation|PWY-6373',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Acrylate Degradation|acrylate degradation I',
    },
    {
        BioCyc_ID: 'PWY-8328',
        Common_Name: 'acetate and ATP formation from acetyl-CoA III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|PWY-8328',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|acetate and ATP formation from acetyl-CoA III',
    },
    {
        BioCyc_ID: 'PWY-5533',
        Common_Name: 'acetone degradation II (to acetoacetate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Acetone-Degradation|PWY-5533',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Acetone Degradation|acetone degradation II (to acetoacetate)',
    },
    {
        BioCyc_ID: 'PWY-5872',
        Common_Name: 'ubiquinol-10 biosynthesis (late decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-5872',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-10 biosynthesis (late decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-7079',
        Common_Name: 'geodin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-7079',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|geodin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2561',
        Common_Name: 'medicarpin conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2561',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|medicarpin conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2561',
        Common_Name: 'medicarpin conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2561',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|medicarpin conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2561',
        Common_Name: 'medicarpin conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-2561',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|medicarpin conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-7090',
        Common_Name: 'UDP-2,3-diacetamido-2,3-dideoxy-&alpha;-D-mannuronate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7090',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-2,3-diacetamido-2,3-dideoxy-&alpha;-D-mannuronate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6536',
        Common_Name: '4-aminobutanoate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|4-Aminobutyraye-Degradation|PWY-6536',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|4-Aminobutanoate Degradation|4-aminobutanoate degradation III',
    },
    {
        BioCyc_ID: 'PWY-7165',
        Common_Name: 'L-ascorbate biosynthesis VIII (engineered pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Ascorbate-Biosynthesis|PWY-7165',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VIII (engineered pathway)',
    },
    {
        BioCyc_ID: 'PWY-7165',
        Common_Name: 'L-ascorbate biosynthesis VIII (engineered pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Ascorbate-Biosynthesis|PWY-7165',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VIII (engineered pathway)',
    },
    {
        BioCyc_ID: 'PWY-7165',
        Common_Name: 'L-ascorbate biosynthesis VIII (engineered pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Ascorbate-Biosynthesis|PWY-7165',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis VIII (engineered pathway)',
    },
    {
        BioCyc_ID: 'PWY-6154',
        Common_Name: 'autoinducer AI-2 biosynthesis II (<i>Vibrio</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Autoinducer-Biosynthesis|PWY-6154',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Autoinducer Biosynthesis|autoinducer AI-2 biosynthesis II (<i>Vibrio</i>)',
    },
    {
        BioCyc_ID: 'PWY66-428',
        Common_Name: 'L-threonine degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|THREONINE-DEG|PWY66-428',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-threonine Degradation|L-threonine degradation V',
    },
    {
        BioCyc_ID: 'PWY-7020',
        Common_Name: 'superpathway of butirocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7020',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|superpathway of butirocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7020',
        Common_Name: 'superpathway of butirocin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7020',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of butirocin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5667',
        Common_Name: 'CDP-diacylglycerol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|CDP-diacylglycerol-Biosynthesis|PWY-5667',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|CDP-diacylglycerol Biosynthesis|CDP-diacylglycerol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6763',
        Common_Name: 'salicortin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-6763',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|salicortin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6340',
        Common_Name: "5,5'-dehydrodivanillate degradation",
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6340',
        MetaCyc_hierarchy_Names: "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|5,5'-dehydrodivanillate degradation",
    },
    {
        BioCyc_ID: 'PWY66-301',
        Common_Name: 'catecholamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Neurotransmitter-Biosynthesis|PWY66-301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Neurotransmitter Biosynthesis|catecholamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8180',
        Common_Name: 'acrylate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Acrylate-Degradation|PWY-8180',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Acrylate Degradation|acrylate degradation II',
    },
    {
        BioCyc_ID: 'PWY-8086',
        Common_Name: '(<i>S</i>)-lactate fermentation to propanoate, acetate and hydrogen',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-propanoate|PWY-8086',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Propanoate|(<i>S</i>)-lactate fermentation to propanoate, acetate and hydrogen',
    },
    {
        BioCyc_ID: 'PWY-5451',
        Common_Name: 'acetone degradation I (to methylglyoxal)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Acetone-Degradation|PWY-5451',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Acetone Degradation|acetone degradation I (to methylglyoxal)',
    },
    {
        BioCyc_ID: 'PWY-7230',
        Common_Name: 'ubiquinol-6 biosynthesis from 4-aminobenzoate (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|PWY-7230',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|ubiquinol-6 biosynthesis from 4-aminobenzoate (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7520',
        Common_Name: 'terrequinone A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-7520',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|terrequinone A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7520',
        Common_Name: 'terrequinone A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|QUINONE-SYN|PWY-7520',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Quinone Secondary Metabolite Biosynthesis|terrequinone A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7369',
        Common_Name: 'thiamine triphosphate metabolism',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-7369',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|thiamine triphosphate metabolism',
    },
    {
        BioCyc_ID: 'PWY-8229',
        Common_Name: 'UDP-3-acetamido-2,3-dideoxy-2-(seryl)amino-&alpha;-D-glucuronamide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-8229',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-3-acetamido-2,3-dideoxy-2-(seryl)amino-&alpha;-D-glucuronamide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6535',
        Common_Name: '4-aminobutanoate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|4-Aminobutyraye-Degradation|PWY-6535',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|4-Aminobutanoate Degradation|4-aminobutanoate degradation I',
    },
    {
        BioCyc_ID: 'PWY-882',
        Common_Name: 'L-ascorbate biosynthesis I (plants, L-galactose pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Ascorbate-Biosynthesis|PWY-882',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis I (plants, L-galactose pathway)',
    },
    {
        BioCyc_ID: 'PWY-882',
        Common_Name: 'L-ascorbate biosynthesis I (plants, L-galactose pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Ascorbate-Biosynthesis|PWY-882',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis I (plants, L-galactose pathway)',
    },
    {
        BioCyc_ID: 'PWY-882',
        Common_Name: 'L-ascorbate biosynthesis I (plants, L-galactose pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Ascorbate-Biosynthesis|PWY-882',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis I (plants, L-galactose pathway)',
    },
    {
        BioCyc_ID: 'PWY-6153',
        Common_Name: 'autoinducer AI-2 biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Autoinducer-Biosynthesis|PWY-6153',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Autoinducer Biosynthesis|autoinducer AI-2 biosynthesis I',
    },
    {
        BioCyc_ID: 'THREONINE-DEG2-PWY',
        Common_Name: 'L-threonine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|THREONINE-DEG|THREONINE-DEG2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-threonine Degradation|L-threonine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7342',
        Common_Name: 'superpathway of nicotine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PYRROLIDINE-ALKALOIDS|PWY-7342',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Pyrrolidine, Piperidine and Pyridine Alkaloid Biosynthesis|superpathway of nicotine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7342',
        Common_Name: 'superpathway of nicotine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7342',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of nicotine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1319',
        Common_Name: 'CDP-diacylglycerol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|CDP-diacylglycerol-Biosynthesis|PWY0-1319',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|CDP-diacylglycerol Biosynthesis|CDP-diacylglycerol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7075',
        Common_Name: 'phenylethyl acetate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-7075',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|phenylethyl acetate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7075',
        Common_Name: 'phenylethyl acetate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-7075',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|phenylethyl acetate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7075',
        Common_Name: 'phenylethyl acetate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-7075',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|phenylethyl acetate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7081',
        Common_Name: '4-aminophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7081',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|4-aminophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-6960',
        Common_Name: 'L-ascorbate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Ascorbate-Degradation|PWY-6960',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|L-Ascorbate Degradation|L-ascorbate degradation III',
    },
    {
        BioCyc_ID: 'PWY-1164',
        Common_Name: 'carbon disulfide oxidation I (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Carbon-disulfide-degradation|PWY-1164',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Carbon Disulfide Degradation|carbon disulfide oxidation I (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5494',
        Common_Name: '(R)-lactate fermentation to propanoate II (acrylate pathway)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-propanoate|PWY-5494',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Propanoate|(R)-lactate fermentation to propanoate II (acrylate pathway)',
    },
    {
        BioCyc_ID: 'P401-PWY',
        Common_Name: 'cyanide degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|P401-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|cyanide degradation',
    },
    {
        BioCyc_ID: 'UBISYN-PWY',
        Common_Name: 'superpathway of ubiquinol-8 biosynthesis (early decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|UBISYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of ubiquinol-8 biosynthesis (early decarboxylation)',
    },
    {
        BioCyc_ID: 'UBISYN-PWY',
        Common_Name: 'superpathway of ubiquinol-8 biosynthesis (early decarboxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Ubiquinone-Biosynthesis|UBISYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Ubiquinol Biosynthesis|superpathway of ubiquinol-8 biosynthesis (early decarboxylation)',
    },
    {
        BioCyc_ID: 'PWY-2904',
        Common_Name: 'formononetin conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2904',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|formononetin conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2904',
        Common_Name: 'formononetin conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2904',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|formononetin conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2904',
        Common_Name: 'formononetin conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-2904',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|formononetin conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-7331',
        Common_Name: 'UDP-<i>N</i>-acetyl-&beta;-L-quinovosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7331',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-&beta;-L-quinovosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6415',
        Common_Name: 'L-ascorbate biosynthesis V (euglena, D-galacturonate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Ascorbate-Biosynthesis|PWY-6415',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis V (euglena, D-galacturonate pathway)',
    },
    {
        BioCyc_ID: 'PWY-6415',
        Common_Name: 'L-ascorbate biosynthesis V (euglena, D-galacturonate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Ascorbate-Biosynthesis|PWY-6415',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis V (euglena, D-galacturonate pathway)',
    },
    {
        BioCyc_ID: 'PWY-6415',
        Common_Name: 'L-ascorbate biosynthesis V (euglena, D-galacturonate pathway)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Ascorbate-Biosynthesis|PWY-6415',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Ascorbate Biosynthesis|L-ascorbate biosynthesis V (euglena, D-galacturonate pathway)',
    },
    {
        BioCyc_ID: 'PWY-7621',
        Common_Name: 'autoinducer CAI-1 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Autoinducer-Biosynthesis|PWY-7621',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Autoinducer Biosynthesis|autoinducer CAI-1 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5436',
        Common_Name: 'L-threonine degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|THREONINE-DEG|PWY-5436',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-threonine Degradation|L-threonine degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7527',
        Common_Name: 'L-methionine salvage cycle III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-Salvage|PWY-7527',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine Salvage|L-methionine salvage cycle III',
    },
    {
        BioCyc_ID: 'PWY-7527',
        Common_Name: 'L-methionine salvage cycle III',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7527',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-methionine salvage cycle III',
    },
    {
        BioCyc_ID: 'PWY-5981',
        Common_Name: 'CDP-diacylglycerol biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|CDP-diacylglycerol-Biosynthesis|PWY-5981',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|CDP-diacylglycerol Biosynthesis|CDP-diacylglycerol biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-5765',
        Common_Name: '1,3,5-trimethoxybenzene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-5765',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|1,3,5-trimethoxybenzene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5765',
        Common_Name: '1,3,5-trimethoxybenzene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-5765',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|1,3,5-trimethoxybenzene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8306',
        Common_Name: 'methoxylated aromatic compound degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-8306',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|methoxylated aromatic compound degradation I',
    },
    {
        BioCyc_ID: 'PWY-6959',
        Common_Name: 'L-ascorbate degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Ascorbate-Degradation|PWY-6959',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|L-Ascorbate Degradation|L-ascorbate degradation V',
    },
    {
        BioCyc_ID: 'PWY-7926',
        Common_Name: 'carbon disulfide oxidation III (metazoa)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Carbon-disulfide-degradation|PWY-7926',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Carbon Disulfide Degradation|carbon disulfide oxidation III (metazoa)',
    },
    {
        BioCyc_ID: 'P122-PWY',
        Common_Name: 'heterolactic fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-Lactate|P122-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Lactate|heterolactic fermentation',
    },
    {
        BioCyc_ID: 'P122-PWY',
        Common_Name: 'heterolactic fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Ethanol-Fermentation|P122-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Ethanol|heterolactic fermentation',
    },
    {
        BioCyc_ID: 'P122-PWY',
        Common_Name: 'heterolactic fermentation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Ethanol-Fermentation|P122-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Ethanol|heterolactic fermentation',
    },
    {
        BioCyc_ID: 'PWY-6028',
        Common_Name: 'acetoin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY-6028',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|acetoin degradation',
    },
    {
        BioCyc_ID: 'PWY-5890',
        Common_Name: 'menaquinol-10 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5890',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-10 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5272',
        Common_Name: 'abscisic acid degradation by glucosylation',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|PLANT-HORMONE-DEG|Abscisic-Acid-Degradation|PWY-5272',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Plant Hormone Degradation|Abscisic Acid Degradation|abscisic acid degradation by glucosylation',
    },
    {
        BioCyc_ID: 'PWY-5272',
        Common_Name: 'abscisic acid degradation by glucosylation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|TERPENOID-DEG|Sesquiterpenoid-Degradation|Abscisic-Acid-Degradation|PWY-5272',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Terpenoid Degradation|Sesquiterpenoid Degradation|Abscisic Acid Degradation|abscisic acid degradation by glucosylation',
    },
    {
        BioCyc_ID: 'PWY-5272',
        Common_Name: 'abscisic acid degradation by glucosylation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-5272',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|abscisic acid degradation by glucosylation',
    },
    {
        BioCyc_ID: 'PWY-7334',
        Common_Name: 'UDP-<i>N</i>-acetyl-&alpha;-D-quinovosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7334',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-&alpha;-D-quinovosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6157',
        Common_Name: 'autoinducer AI-1 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Autoinducer-Biosynthesis|PWY-6157',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Autoinducer Biosynthesis|autoinducer AI-1 biosynthesis',
    },
    {
        BioCyc_ID: 'THRDLCTCAT-PWY',
        Common_Name: 'L-threonine degradation III (to methylglyoxal)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|THREONINE-DEG|THRDLCTCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-threonine Degradation|L-threonine degradation III (to methylglyoxal)',
    },
    {
        BioCyc_ID: 'PWY-8017',
        Common_Name: 'L-tryptophan degradation XIII (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-8017',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|L-tryptophan degradation XIII (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8017',
        Common_Name: 'L-tryptophan degradation XIII (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8017',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-tryptophan degradation XIII (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8017',
        Common_Name: 'L-tryptophan degradation XIII (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-8017',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation XIII (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-5669',
        Common_Name: 'phosphatidylserine and phosphatidylethanolamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylethanolamineBiosynthesis|PWY-5669',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylethanolamine Biosynthesis|phosphatidylserine and phosphatidylethanolamine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5669',
        Common_Name: 'phosphatidylserine and phosphatidylethanolamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Phosphatidylserine-Biosynthesis|PWY-5669',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylserine Biosynthesis|phosphatidylserine and phosphatidylethanolamine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8088',
        Common_Name: 'dipicolinate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-8088',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|dipicolinate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2504',
        Common_Name: 'superpathway of aromatic compound degradation via 3-oxoadipate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-2504',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|superpathway of aromatic compound degradation via 3-oxoadipate',
    },
    {
        BioCyc_ID: 'PWY-2504',
        Common_Name: 'superpathway of aromatic compound degradation via 3-oxoadipate',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-2504',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of aromatic compound degradation via 3-oxoadipate',
    },
    {
        BioCyc_ID: 'PWY-8200',
        Common_Name: 'backdoor pathway of androgen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-8200',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|backdoor pathway of androgen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-301',
        Common_Name: 'L-ascorbate degradation I (bacterial, anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Ascorbate-Degradation|PWY0-301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|L-Ascorbate Degradation|L-ascorbate degradation I (bacterial, anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-5022',
        Common_Name: '4-aminobutanoate degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|4-Aminobutyraye-Degradation|PWY-5022',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|4-Aminobutanoate Degradation|4-aminobutanoate degradation V',
    },
    {
        BioCyc_ID: 'PWY-5022',
        Common_Name: '4-aminobutanoate degradation V',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetyl-CoA-Butyrate|PWY-5022',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Butanoate|4-aminobutanoate degradation V',
    },
    {
        BioCyc_ID: 'PWY-5336',
        Common_Name: 'carbon disulfide oxidation II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Carbon-disulfide-degradation|PWY-5336',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Carbon Disulfide Degradation|carbon disulfide oxidation II (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-6909',
        Common_Name: 'thiazole component of thiamine diphosphate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiazole-Biosynthesis|PWY-6909',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|Thiazole Biosynthesis|thiazole component of thiamine diphosphate biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6909',
        Common_Name: 'thiazole component of thiamine diphosphate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiazole-Biosynthesis|PWY-6909',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|Thiazole Biosynthesis|thiazole component of thiamine diphosphate biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7779',
        Common_Name: 'methyl <i>tert</i>-butyl ether degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY-7779',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|methyl <i>tert</i>-butyl ether degradation',
    },
    {
        BioCyc_ID: 'PWY-5895',
        Common_Name: 'menaquinol-13 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5895',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-13 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5475',
        Common_Name: 'pentagalloylglucose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|TANNIN-SYN|PWY-5475',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Hydrolyzable Tannin Biosynthesis|pentagalloylglucose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5926',
        Common_Name: 'afrormosin conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-5926',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|afrormosin conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-5926',
        Common_Name: 'afrormosin conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-5926',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|afrormosin conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-7343',
        Common_Name: 'UDP-&alpha;-D-glucose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7343',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&alpha;-D-glucose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6875',
        Common_Name: 'retinoate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-A-Biosynthesis|PWY-6875',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin A Biosynthesis|retinoate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8440',
        Common_Name: 'nucleoside and nucleotide degradation (halobacteria)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|PWY-8440',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|nucleoside and nucleotide degradation (halobacteria)',
    },
    {
        BioCyc_ID: 'PWY-8294',
        Common_Name: 'L-aspartate degradation III (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ASPARTATE-DEG|PWY-8294',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-aspartate Degradation|L-aspartate degradation III (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY0-1325',
        Common_Name: 'superpathway of L-asparagine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ASPARAGINE-SYN|PWY0-1325',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-asparagine Biosynthesis|superpathway of L-asparagine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1325',
        Common_Name: 'superpathway of L-asparagine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-1325',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-asparagine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7409',
        Common_Name: 'phospholipid remodeling (phosphatidylethanolamine, yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylethanolamineBiosynthesis|PWY-7409',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylethanolamine Biosynthesis|phospholipid remodeling (phosphatidylethanolamine, yeast)',
    },
    {
        BioCyc_ID: 'PWY-5901',
        Common_Name: '2,3-dihydroxybenzoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PWY-5901',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|2,3-dihydroxybenzoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6550',
        Common_Name: 'carbazole degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6550',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|carbazole degradation',
    },
    {
        BioCyc_ID: 'PWY66-378',
        Common_Name: 'androgen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY66-378',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|androgen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6704',
        Common_Name: 'L-ascorbate degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Ascorbate-Degradation|PWY-6704',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|L-Ascorbate Degradation|L-ascorbate degradation IV',
    },
    {
        BioCyc_ID: 'PWY-5677',
        Common_Name: 'succinate fermentation to butanoate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetyl-CoA-Butyrate|PWY-5677',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Butanoate|succinate fermentation to butanoate',
    },
    {
        BioCyc_ID: 'PWY-6892',
        Common_Name: 'thiazole component of thiamine diphosphate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiazole-Biosynthesis|PWY-6892',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|Thiazole Biosynthesis|thiazole component of thiamine diphosphate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6892',
        Common_Name: 'thiazole component of thiamine diphosphate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiazole-Biosynthesis|PWY-6892',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|Thiazole Biosynthesis|thiazole component of thiamine diphosphate biosynthesis I',
    },
    {
        BioCyc_ID: 'P221-PWY',
        Common_Name: 'octane oxidation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|P221-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|octane oxidation',
    },
    {
        BioCyc_ID: 'PWY-5898',
        Common_Name: 'superpathway of menaquinol-12 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5898',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-12 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5898',
        Common_Name: 'superpathway of menaquinol-12 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5898',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-12 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY18C3-20',
        Common_Name: 'gentisate 5-<i>O</i>-&beta;-<i>D</i>-xylopyranoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|BENZENOID-SYN|PWY18C3-20',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Benzenoids Biosynthesis|gentisate 5-<i>O</i>-&beta;-<i>D</i>-xylopyranoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6972',
        Common_Name: 'oleandomycin activation/inactivation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-6972',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|oleandomycin activation/inactivation',
    },
    {
        BioCyc_ID: 'PWY-5113',
        Common_Name: 'UDP-D-apiose biosynthesis (from UDP-D-glucuronate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-5113',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-D-apiose biosynthesis (from UDP-D-glucuronate)',
    },
    {
        BioCyc_ID: 'PWY-6872',
        Common_Name: 'retinoate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-A-Biosynthesis|PWY-6872',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin A Biosynthesis|retinoate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5532',
        Common_Name: 'nucleoside and nucleotide degradation (archaea)',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|PWY-5532',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|nucleoside and nucleotide degradation (archaea)',
    },
    {
        BioCyc_ID: 'PWY-8291',
        Common_Name: 'L-aspartate degradation II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ASPARTATE-DEG|PWY-8291',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-aspartate Degradation|L-aspartate degradation II (aerobic)',
    },
    {
        BioCyc_ID: 'PWY4FS-7',
        Common_Name: 'phosphatidylglycerol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylglycerolBiosynthesis|PWY4FS-7',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylglycerol Biosynthesis|phosphatidylglycerol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY4FS-7',
        Common_Name: 'phosphatidylglycerol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY4FS-7',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|phosphatidylglycerol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6273',
        Common_Name: 'phosphatidylethanolamine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylethanolamineBiosynthesis|PWY-6273',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylethanolamine Biosynthesis|phosphatidylethanolamine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7430',
        Common_Name: 'indole degradation to anthranil and anthranilate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7430',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|indole degradation to anthranil and anthranilate',
    },
    {
        BioCyc_ID: 'PWY-6961',
        Common_Name: 'L-ascorbate degradation II (bacterial, aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Ascorbate-Degradation|PWY-6961',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|L-Ascorbate Degradation|L-ascorbate degradation II (bacterial, aerobic)',
    },
    {
        BioCyc_ID: 'PWY66-382',
        Common_Name: 'mineralocorticoid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY66-382',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|mineralocorticoid biosynthesis',
    },
    {
        BioCyc_ID: 'P108-PWY',
        Common_Name: 'pyruvate fermentation to propanoate I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-propanoate|Pyruvate-Propanoate-Fermentation|P108-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Propanoate|Pyruvate Fermentation to Propanoate|pyruvate fermentation to propanoate I',
    },
    {
        BioCyc_ID: 'P108-PWY',
        Common_Name: 'pyruvate fermentation to propanoate I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Propanoate-Fermentation|P108-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Propanoate|pyruvate fermentation to propanoate I',
    },
    {
        BioCyc_ID: 'PWY-6891',
        Common_Name: 'thiazole component of thiamine diphosphate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiazole-Biosynthesis|PWY-6891',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|Thiazole Biosynthesis|thiazole component of thiamine diphosphate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6891',
        Common_Name: 'thiazole component of thiamine diphosphate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiazole-Biosynthesis|PWY-6891',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|Thiazole Biosynthesis|thiazole component of thiamine diphosphate biosynthesis II',
    },
    {
        BioCyc_ID: 'P621-PWY',
        Common_Name: 'nylon-6 oligomer degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|P621-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|nylon-6 oligomer degradation',
    },
    {
        BioCyc_ID: 'PWY-5838',
        Common_Name: 'superpathway of menaquinol-8 biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5838',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-8 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5838',
        Common_Name: 'superpathway of menaquinol-8 biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5838',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-8 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5120',
        Common_Name: 'geranylgeranyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|GGPP-Biosynthesis|PWY-5120',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|Geranylgeranyl Diphosphate Biosynthesis|geranylgeranyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2345',
        Common_Name: 'genistein conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2345',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|genistein conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2345',
        Common_Name: 'genistein conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2345',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|genistein conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2345',
        Common_Name: 'genistein conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-2345',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|genistein conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-7467',
        Common_Name: 'UDP-2-acetamido-4-amino-2,4,6-trideoxy-&alpha;-D-galactose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7467',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-2-acetamido-4-amino-2,4,6-trideoxy-&alpha;-D-galactose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6857',
        Common_Name: 'retinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-A-Biosynthesis|PWY-6857',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin A Biosynthesis|retinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4361',
        Common_Name: '<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-Salvage|MTR-1P-Degradation|PWY-4361',
        MetaCyc_hierarchy_Names:
            'Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine Salvage|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation I',
    },
    {
        BioCyc_ID: 'PWY-4361',
        Common_Name: '<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|MTR-1P-Degradation|PWY-4361',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation I',
    },
    {
        BioCyc_ID: 'ASPARTATE-DEG1-PWY',
        Common_Name: 'L-aspartate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ASPARTATE-DEG|ASPARTATE-DEG1-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-aspartate Degradation|L-aspartate degradation I',
    },
    {
        BioCyc_ID: 'THRESYN-PWY',
        Common_Name: 'superpathway of L-threonine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|THRESYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-threonine biosynthesis',
    },
    {
        BioCyc_ID: 'THRESYN-PWY',
        Common_Name: 'superpathway of L-threonine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|THREONINE-BIOSYNTHESIS|THRESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-threonine Biosynthesis|superpathway of L-threonine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY4FS-6',
        Common_Name: 'phosphatidylethanolamine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PhosphatidylethanolamineBiosynthesis|PWY4FS-6',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylethanolamine Biosynthesis|phosphatidylethanolamine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5785',
        Common_Name: 'di-<i>trans</i>,poly-<i>cis</i>-undecaprenyl phosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Lipid-Anchor-Biosynthesis|PWY-5785',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Lipid Anchor Biosynthesis|di-<i>trans</i>,poly-<i>cis</i>-undecaprenyl phosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5785',
        Common_Name: 'di-<i>trans</i>,poly-<i>cis</i>-undecaprenyl phosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|PWY-5785',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|di-<i>trans</i>,poly-<i>cis</i>-undecaprenyl phosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY5F9-12',
        Common_Name: 'biphenyl degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY5F9-12',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|biphenyl degradation',
    },
    {
        BioCyc_ID: 'PWY-7300',
        Common_Name: 'ecdysone and 20-hydroxyecdysone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-7300',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|ecdysone and 20-hydroxyecdysone biosynthesis',
    },
    {
        BioCyc_ID: 'GLYOXDEG-PWY',
        Common_Name: 'glycolate and glyoxylate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Glycolate-Degradation|GLYOXDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Glycolate Degradation|glycolate and glyoxylate degradation II',
    },
    {
        BioCyc_ID: 'PWY-5482',
        Common_Name: 'pyruvate fermentation to acetate II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5482',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate II',
    },
    {
        BioCyc_ID: 'PWY-5482',
        Common_Name: 'pyruvate fermentation to acetate II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5482',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate II',
    },
    {
        BioCyc_ID: 'PWY0-1261',
        Common_Name: 'peptidoglycan recycling I',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Anhydromuropeptides-Recycling|PWY0-1261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Anhydromuropeptide Recycling|peptidoglycan recycling I',
    },
    {
        BioCyc_ID: 'PWY-8308',
        Common_Name: 'thiazole component of thiamine diphosphate biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiazole-Biosynthesis|PWY-8308',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|Thiazole Biosynthesis|thiazole component of thiamine diphosphate biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-8308',
        Common_Name: 'thiazole component of thiamine diphosphate biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiazole-Biosynthesis|PWY-8308',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|Thiazole Biosynthesis|thiazole component of thiamine diphosphate biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-5744',
        Common_Name: 'glyoxylate assimilation',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|PWY-5744',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|glyoxylate assimilation',
    },
    {
        BioCyc_ID: 'PWY-5744',
        Common_Name: 'glyoxylate assimilation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY-5744',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|glyoxylate assimilation',
    },
    {
        BioCyc_ID: 'PWY-7992',
        Common_Name: 'superpathway of menaquinol-8 biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-7992',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-8 biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7992',
        Common_Name: 'superpathway of menaquinol-8 biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7992',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-8 biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-5123',
        Common_Name: '<i>trans, trans</i>-farnesyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|All-Trans-Farnesyl-PP-Biosynthesis|PWY-5123',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|All-Trans-Farnesyl-PP-Biosynthesis|<i>trans, trans</i>-farnesyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2861',
        Common_Name: 'biochanin A conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|biochanin A conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2861',
        Common_Name: 'biochanin A conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|biochanin A conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-2861',
        Common_Name: 'biochanin A conjugates interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-2861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|biochanin A conjugates interconversion',
    },
    {
        BioCyc_ID: 'PWY-7028',
        Common_Name: "UDP-<i>N,N'</i>-diacetylbacillosamine biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7028',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N,N'</i>-diacetylbacillosamine biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-8132',
        Common_Name: '<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-Salvage|MTR-1P-Degradation|PWY-8132',
        MetaCyc_hierarchy_Names:
            'Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine Salvage|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation III',
    },
    {
        BioCyc_ID: 'PWY-8132',
        Common_Name: '<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|MTR-1P-Degradation|PWY-8132',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation III',
    },
    {
        BioCyc_ID: 'PWY-5057',
        Common_Name: 'L-valine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|VALINE-DEG|PWY-5057',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-valine Degradation|L-valine degradation II',
    },
    {
        BioCyc_ID: '3-HYDROXYPHENYLACETATE-DEGRADATION-PWY',
        Common_Name: '4-hydroxyphenylacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|3-HYDROXYPHENYLACETATE-DEGRADATION-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|4-hydroxyphenylacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-7506',
        Common_Name: 'phosphatidylserine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Phosphatidylserine-Biosynthesis|PWY-7506',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylserine Biosynthesis|phosphatidylserine biosynthesis II',
    },
    {
        BioCyc_ID: 'POLYISOPRENSYN-PWY',
        Common_Name: 'polyisoprenoid biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|POLYISOPRENSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|polyisoprenoid biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'POLYISOPRENSYN-PWY',
        Common_Name: 'polyisoprenoid biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|POLYISOPRENSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|polyisoprenoid biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'GLYCOLATEMET-PWY',
        Common_Name: 'glycolate and glyoxylate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Glycolate-Degradation|GLYCOLATEMET-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Glycolate Degradation|glycolate and glyoxylate degradation I',
    },
    {
        BioCyc_ID: 'PWY-7410',
        Common_Name: 'ipsdienol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-7410',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|ipsdienol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7410',
        Common_Name: 'ipsdienol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-7410',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|ipsdienol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7883',
        Common_Name: 'peptidoglycan recycling II',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Anhydromuropeptides-Recycling|PWY-7883',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Anhydromuropeptide Recycling|peptidoglycan recycling II',
    },
    {
        BioCyc_ID: 'PWY-5768',
        Common_Name: 'pyruvate fermentation to acetate VIII',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5768',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate VIII',
    },
    {
        BioCyc_ID: 'PWY-5768',
        Common_Name: 'pyruvate fermentation to acetate VIII',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5768',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate VIII',
    },
    {
        BioCyc_ID: 'PWY-7600',
        Common_Name: 'peramine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7600',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|peramine biosynthesis',
    },
    {
        BioCyc_ID: 'URDEGR-PWY',
        Common_Name: 'superpathway of allantoin degradation in plants',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Allantoin-degradation|URDEGR-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Allantoin Degradation|superpathway of allantoin degradation in plants',
    },
    {
        BioCyc_ID: 'URDEGR-PWY',
        Common_Name: 'superpathway of allantoin degradation in plants',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|URDEGR-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of allantoin degradation in plants',
    },
    {
        BioCyc_ID: 'PWY-6898',
        Common_Name: 'thiamine diphosphate salvage III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6898',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate salvage III',
    },
    {
        BioCyc_ID: 'PWY-6898',
        Common_Name: 'thiamine diphosphate salvage III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6898',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate salvage III',
    },
    {
        BioCyc_ID: 'PWY-7582',
        Common_Name: 'mercaptosuccinate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY-7582',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|mercaptosuccinate degradation',
    },
    {
        BioCyc_ID: 'PWY-5844',
        Common_Name: 'menaquinol-9 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5844',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-9 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5530',
        Common_Name: 'sorbitol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Sugar-Alcohols-Biosynthesis|Sorbitol-Biosynthesis|PWY-5530',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Alditol Biosynthesis|Sorbitol Biosynthesis|sorbitol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-801',
        Common_Name: 'homocysteine and cysteine interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-801',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|homocysteine and cysteine interconversion',
    },
    {
        BioCyc_ID: 'PWY-82',
        Common_Name: 'UDP-&beta;-L-arabinose biosynthesis II (from &beta;-L-arabinose)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-82',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&beta;-L-arabinose biosynthesis II (from &beta;-L-arabinose)',
    },
    {
        BioCyc_ID: 'PWY-3181',
        Common_Name: 'L-tryptophan degradation VI (via tryptamine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-3181',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|L-tryptophan degradation VI (via tryptamine)',
    },
    {
        BioCyc_ID: 'PWY-3181',
        Common_Name: 'L-tryptophan degradation VI (via tryptamine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-3181',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|L-tryptophan degradation VI (via tryptamine)',
    },
    {
        BioCyc_ID: 'PWY-3181',
        Common_Name: 'L-tryptophan degradation VI (via tryptamine)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TRYPTOPHAN-DEG|PWY-3181',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tryptophan Degradation|L-tryptophan degradation VI (via tryptamine)',
    },
    {
        BioCyc_ID: 'PWY-7174',
        Common_Name: '<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|METHIONINE-SYN|Methionine-Salvage|MTR-1P-Degradation|PWY-7174',
        MetaCyc_hierarchy_Names:
            'Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-methionine Biosynthesis|L-methionine Salvage|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation II',
    },
    {
        BioCyc_ID: 'PWY-7174',
        Common_Name: '<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|MTR-1P-Degradation|PWY-7174',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate Degradation|<i>S</i>-methyl-5-thio-&alpha;-D-ribose 1-phosphate degradation II',
    },
    {
        BioCyc_ID: 'VALDEG-PWY',
        Common_Name: 'L-valine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|VALINE-DEG|VALDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-valine Degradation|L-valine degradation I',
    },
    {
        BioCyc_ID: 'P125-PWY',
        Common_Name: 'superpathway of (<i>R,R</i>)-butanediol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Butanediol-Biosynthesis|P125-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Butanediol Biosynthesis|superpathway of (<i>R,R</i>)-butanediol biosynthesis',
    },
    {
        BioCyc_ID: 'P125-PWY',
        Common_Name: 'superpathway of (<i>R,R</i>)-butanediol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P125-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of (<i>R,R</i>)-butanediol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7501',
        Common_Name: 'phosphatidylserine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Phosphatidylserine-Biosynthesis|PWY-7501',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Phosphatidylserine Biosynthesis|phosphatidylserine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6383',
        Common_Name: 'mono-<i>trans</i>, poly-<i>cis</i> decaprenyl phosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Lipid-Anchor-Biosynthesis|PWY-6383',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Lipid Anchor Biosynthesis|mono-<i>trans</i>, poly-<i>cis</i> decaprenyl phosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6383',
        Common_Name: 'mono-<i>trans</i>, poly-<i>cis</i> decaprenyl phosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|PWY-6383',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|mono-<i>trans</i>, poly-<i>cis</i> decaprenyl phosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5163',
        Common_Name: '<I>p</I>-cumate degradation to 2-hydroxypentadienoate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-5163',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<I>p</I>-cumate degradation to 2-hydroxypentadienoate',
    },
    {
        BioCyc_ID: 'PWY66-377',
        Common_Name: 'pregnenolone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY66-377',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|pregnenolone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6649',
        Common_Name: 'glycolate and glyoxylate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Glycolate-Degradation|PWY-6649',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Glycolate Degradation|glycolate and glyoxylate degradation III',
    },
    {
        BioCyc_ID: 'PWY-8274',
        Common_Name: 'pyruvate fermentation to (<i>R</i>)-lactate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-Lactate|Pyruvate-Lactate-Fermentation|PWY-8274',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Lactate|Pyruvate Fermentation to Lactate|pyruvate fermentation to (<i>R</i>)-lactate',
    },
    {
        BioCyc_ID: 'PWY-8274',
        Common_Name: 'pyruvate fermentation to (<i>R</i>)-lactate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Lactate-Fermentation|PWY-8274',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Lactate|pyruvate fermentation to (<i>R</i>)-lactate',
    },
    {
        BioCyc_ID: 'PWY18C3-3',
        Common_Name: '&alpha;-tomatine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY18C3-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|&alpha;-tomatine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7559',
        Common_Name: 'glutathione degradation (DUG pathway)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Reductant-Degradation|PWY-7559',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Reductant Degradation|glutathione degradation (DUG pathway)',
    },
    {
        BioCyc_ID: 'PWY-7356',
        Common_Name: 'thiamine diphosphate salvage IV (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-7356',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate salvage IV (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7356',
        Common_Name: 'thiamine diphosphate salvage IV (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-7356',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate salvage IV (yeast)',
    },
    {
        BioCyc_ID: 'PWY66-241',
        Common_Name: 'bupropion degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY66-241',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|bupropion degradation',
    },
    {
        BioCyc_ID: 'PWY-5850',
        Common_Name: 'superpathway of menaquinol-6 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5850',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-6 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5850',
        Common_Name: 'superpathway of menaquinol-6 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5850',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-6 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5054',
        Common_Name: 'D-sorbitol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Sugar-Alcohols-Biosynthesis|Sorbitol-Biosynthesis|PWY-5054',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Alditol Biosynthesis|Sorbitol Biosynthesis|D-sorbitol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5835',
        Common_Name: 'geranyl acetate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-5835',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|geranyl acetate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5835',
        Common_Name: 'geranyl acetate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-5835',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|geranyl acetate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7330',
        Common_Name: 'UDP-<i>N</i>-acetyl-&beta;-L-fucosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7330',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-&beta;-L-fucosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8006',
        Common_Name: 'plant arabinogalactan type II degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-8006',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|plant arabinogalactan type II degradation',
    },
    {
        BioCyc_ID: 'PWY-8006',
        Common_Name: 'plant arabinogalactan type II degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-8006',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|plant arabinogalactan type II degradation',
    },
    {
        BioCyc_ID: 'PWY-8006',
        Common_Name: 'plant arabinogalactan type II degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-8006',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|plant arabinogalactan type II degradation',
    },
    {
        BioCyc_ID: 'PWY-6442',
        Common_Name: 'spermidine hydroxycinnamic acid conjugates biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-6442',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|spermidine hydroxycinnamic acid conjugates biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6174',
        Common_Name: 'mevalonate pathway II (haloarchaea)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|Isopentenyl-Diphosphate-Biosynthesis|Mevalonate-Pathways|PWY-6174',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|Isopentenyl Diphosphate Biosynthesis|Mevalonate Pathways|mevalonate pathway II (haloarchaea)',
    },
    {
        BioCyc_ID: 'PWY-8183',
        Common_Name: 'L-valine degradation III (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Oxidative|PWY-8183',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Oxidative Branch)|L-valine degradation III (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8183',
        Common_Name: 'L-valine degradation III (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|VALINE-DEG|PWY-8183',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-valine Degradation|L-valine degradation III (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PHOTOALL-PWY',
        Common_Name: 'oxygenic photosynthesis',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photosynthesis|PHOTOALL-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photosynthesis|oxygenic photosynthesis',
    },
    {
        BioCyc_ID: 'PHOTOALL-PWY',
        Common_Name: 'oxygenic photosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PHOTOALL-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|oxygenic photosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8173',
        Common_Name: '<i>anteiso</i>-branched-chain fatty acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Branched-Fatty-Acids-Biosynthesis|PWY-8173',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Branched Fatty Acid Biosynthesis|<i>anteiso</i>-branched-chain fatty acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-681',
        Common_Name: 'dibenzothiophene desulfurization',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-681',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|dibenzothiophene desulfurization',
    },
    {
        BioCyc_ID: 'PWY-6696',
        Common_Name: 'oxalate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Oxalate-Degradation|PWY-6696',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Oxalate Degradation|oxalate degradation III',
    },
    {
        BioCyc_ID: 'PWY66-381',
        Common_Name: 'glucocorticoid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY66-381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|glucocorticoid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7308',
        Common_Name: 'acrylonitrile degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Acrylonitrile-Degradation|PWY-7308',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Acrylonitrile Degradation|acrylonitrile degradation I',
    },
    {
        BioCyc_ID: 'PWY-5481',
        Common_Name: 'pyruvate fermentation to (<i>S</i>)-lactate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-Lactate|Pyruvate-Lactate-Fermentation|PWY-5481',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Lactate|Pyruvate Fermentation to Lactate|pyruvate fermentation to (<i>S</i>)-lactate',
    },
    {
        BioCyc_ID: 'PWY-5481',
        Common_Name: 'pyruvate fermentation to (<i>S</i>)-lactate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Lactate-Fermentation|PWY-5481',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Lactate|pyruvate fermentation to (<i>S</i>)-lactate',
    },
    {
        BioCyc_ID: 'PWY-5748',
        Common_Name: '&gamma;-coniciene and coniine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-5748',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|&gamma;-coniciene and coniine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6910',
        Common_Name: 'hydroxymethylpyrimidine salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6910',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|hydroxymethylpyrimidine salvage',
    },
    {
        BioCyc_ID: 'PWY-6910',
        Common_Name: 'hydroxymethylpyrimidine salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6910',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|hydroxymethylpyrimidine salvage',
    },
    {
        BioCyc_ID: 'P201-PWY',
        Common_Name: 'nitroglycerin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|P201-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|nitroglycerin degradation',
    },
    {
        BioCyc_ID: 'PWY-5892',
        Common_Name: 'menaquinol-12 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5892',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-12 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8373',
        Common_Name: 'erythritol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Sugar-Alcohols-Biosynthesis|Erythritol-Biosynthesis|PWY-8373',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Alditol Biosynthesis|Erythritol Biosynthesis|erythritol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7333',
        Common_Name: 'UDP-<i>N</i>-acetyl-&alpha;-D-fucosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7333',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-&alpha;-D-fucosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6813',
        Common_Name: 'glucuronoarabinoxylan degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6813',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|glucuronoarabinoxylan degradation',
    },
    {
        BioCyc_ID: 'PWY-6813',
        Common_Name: 'glucuronoarabinoxylan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-6813',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|glucuronoarabinoxylan degradation',
    },
    {
        BioCyc_ID: 'PWY-6813',
        Common_Name: 'glucuronoarabinoxylan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-6813',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|glucuronoarabinoxylan degradation',
    },
    {
        BioCyc_ID: 'PWY-8052',
        Common_Name: '2-arachidonoylglycerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-8052',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|2-arachidonoylglycerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-922',
        Common_Name: 'mevalonate pathway I (eukaryotes and bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|Isopentenyl-Diphosphate-Biosynthesis|Mevalonate-Pathways|PWY-922',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|Isopentenyl Diphosphate Biosynthesis|Mevalonate Pathways|mevalonate pathway I (eukaryotes and bacteria)',
    },
    {
        BioCyc_ID: 'GLUTAMINEFUM-PWY',
        Common_Name: 'L-glutamine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMINE-DEG|GLUTAMINEFUM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamine Degradation|L-glutamine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7698',
        Common_Name: '2,5-xylenol and 3,5-xylenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7698',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2,5-xylenol and 3,5-xylenol degradation',
    },
    {
        BioCyc_ID: 'PWY-8172',
        Common_Name: '10-methylstearate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Branched-Fatty-Acids-Biosynthesis|PWY-8172',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Branched Fatty Acid Biosynthesis|10-methylstearate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3282',
        Common_Name: 'superpathway of ammonia assimilation (plants)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-Assimilation|PWY-3282',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Assimilation|superpathway of ammonia assimilation (plants)',
    },
    {
        BioCyc_ID: 'PWY-3282',
        Common_Name: 'superpathway of ammonia assimilation (plants)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-3282',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of ammonia assimilation (plants)',
    },
    {
        BioCyc_ID: 'PWY-7985',
        Common_Name: 'oxalate degradation VI',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Oxalate-Degradation|PWY-7985',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Oxalate Degradation|oxalate degradation VI',
    },
    {
        BioCyc_ID: 'PWY-7299',
        Common_Name: 'progesterone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-7299',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|progesterone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7309',
        Common_Name: 'acrylonitrile degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|Acrylonitrile-Degradation|PWY-7309',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|Acrylonitrile Degradation|acrylonitrile degradation II',
    },
    {
        BioCyc_ID: 'PWY-5938',
        Common_Name: 'pyruvate fermentation to (<i>R</i>)-acetoin I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Acetoin-Biosynthesis|PWY-5938',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetoin|pyruvate fermentation to (<i>R</i>)-acetoin I',
    },
    {
        BioCyc_ID: 'PWY-6027',
        Common_Name: 'capsiconiate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-6027',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|capsiconiate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6896',
        Common_Name: 'thiamine diphosphate salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6896',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate salvage I',
    },
    {
        BioCyc_ID: 'PWY-6896',
        Common_Name: 'thiamine diphosphate salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6896',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|thiamine diphosphate salvage I',
    },
    {
        BioCyc_ID: 'P481-PWY',
        Common_Name: 'adamantanone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|P481-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|adamantanone degradation',
    },
    {
        BioCyc_ID: 'PWY-5897',
        Common_Name: 'superpathway of menaquinol-11 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5897',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-11 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5897',
        Common_Name: 'superpathway of menaquinol-11 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5897',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-11 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8372',
        Common_Name: 'erythritol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Sugar-Alcohols-Biosynthesis|Erythritol-Biosynthesis|PWY-8372',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Alditol Biosynthesis|Erythritol Biosynthesis|erythritol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-4',
        Common_Name: 'UDP-&alpha;-D-galacturonate biosynthesis II (from D-galacturonate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&alpha;-D-galacturonate biosynthesis II (from D-galacturonate)',
    },
    {
        BioCyc_ID: 'PWY-6827',
        Common_Name: 'gellan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-6827',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|gellan degradation',
    },
    {
        BioCyc_ID: 'PWY-6827',
        Common_Name: 'gellan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-6827',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|gellan degradation',
    },
    {
        BioCyc_ID: 'THISYNARA-PWY',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis III (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|THISYNARA-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of thiamine diphosphate biosynthesis III (eukaryotes)',
    },
    {
        BioCyc_ID: 'THISYNARA-PWY',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis III (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|THISYNARA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|superpathway of thiamine diphosphate biosynthesis III (eukaryotes)',
    },
    {
        BioCyc_ID: 'THISYNARA-PWY',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis III (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|THISYNARA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|superpathway of thiamine diphosphate biosynthesis III (eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-5474',
        Common_Name: 'hydroxycinnamic acid tyramine amides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-5474',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|hydroxycinnamic acid tyramine amides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8125',
        Common_Name: 'mevalonate pathway IV (archaea)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|Isopentenyl-Diphosphate-Biosynthesis|Mevalonate-Pathways|PWY-8125',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|Isopentenyl Diphosphate Biosynthesis|Mevalonate Pathways|mevalonate pathway IV (archaea)',
    },
    {
        BioCyc_ID: 'GLUTAMINDEG-PWY',
        Common_Name: 'L-glutamine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMINE-DEG|GLUTAMINDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamine Degradation|L-glutamine degradation I',
    },
    {
        BioCyc_ID: 'PWY-5096',
        Common_Name: 'pyruvate fermentation to acetate and alanine',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5096',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate and alanine',
    },
    {
        BioCyc_ID: 'PWY-5096',
        Common_Name: 'pyruvate fermentation to acetate and alanine',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5096',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate and alanine',
    },
    {
        BioCyc_ID: 'PWY-5096',
        Common_Name: 'pyruvate fermentation to acetate and alanine',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5096',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate and alanine',
    },
    {
        BioCyc_ID: 'PWY-8175',
        Common_Name: 'even <i>iso</i>-branched-chain fatty acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Branched-Fatty-Acids-Biosynthesis|PWY-8175',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Branched Fatty Acid Biosynthesis|even <i>iso</i>-branched-chain fatty acid biosynthesis',
    },
    {
        BioCyc_ID: 'BENZCOA-PWY',
        Common_Name: 'anaerobic aromatic compound degradation (<i>Thauera aromatica</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|BENZCOA-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|anaerobic aromatic compound degradation (<i>Thauera aromatica</i>)',
    },
    {
        BioCyc_ID: 'BENZCOA-PWY',
        Common_Name: 'anaerobic aromatic compound degradation (<i>Thauera aromatica</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|BENZCOA-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|anaerobic aromatic compound degradation (<i>Thauera aromatica</i>)',
    },
    {
        BioCyc_ID: 'PWY-6695',
        Common_Name: 'oxalate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Oxalate-Degradation|PWY-6695',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Oxalate Degradation|oxalate degradation II',
    },
    {
        BioCyc_ID: 'PWY-7306',
        Common_Name: 'estradiol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-7306',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|estradiol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7775',
        Common_Name: 'propane degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Hydrocarbon-Degradation|Propane-Degradation|PWY-7775',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hydrocarbon Degradation|Propane Degradation|propane degradation II',
    },
    {
        BioCyc_ID: 'PWY3O-440',
        Common_Name: 'pyruvate fermentation to acetoin',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Acetoin-Biosynthesis|PWY3O-440',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetoin|pyruvate fermentation to acetoin',
    },
    {
        BioCyc_ID: 'PWY-7605',
        Common_Name: 'roquefortine C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7605',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|roquefortine C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6899',
        Common_Name: 'base-degraded thiamine salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6899',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|base-degraded thiamine salvage',
    },
    {
        BioCyc_ID: 'PWY-6899',
        Common_Name: 'base-degraded thiamine salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Salvage|PWY-6899',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|thiamine Diphosphate Salvage|base-degraded thiamine salvage',
    },
    {
        BioCyc_ID: 'PWY-5355',
        Common_Name: 'nitroethane degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY-5355',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|nitroethane degradation',
    },
    {
        BioCyc_ID: 'MENAQUINONESYN-PWY',
        Common_Name: 'menaquinol-8 biosynthesis',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|MENAQUINONESYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-8 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7904',
        Common_Name: 'tRNA-uridine 2-thiolation (thermophilic bacteria)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7904',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA-uridine 2-thiolation (thermophilic bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7336',
        Common_Name: 'UDP-<i>N</i>-acetyl-&alpha;-D-galactosaminuronate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7336',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-&alpha;-D-galactosaminuronate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7647',
        Common_Name: 'ulvan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-7647',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|ulvan degradation',
    },
    {
        BioCyc_ID: 'PWY-7647',
        Common_Name: 'ulvan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-7647',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|ulvan degradation',
    },
    {
        BioCyc_ID: 'THISYN-PWY',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|THISYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of thiamine diphosphate biosynthesis I',
    },
    {
        BioCyc_ID: 'THISYN-PWY',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|THISYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|superpathway of thiamine diphosphate biosynthesis I',
    },
    {
        BioCyc_ID: 'THISYN-PWY',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|THISYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|superpathway of thiamine diphosphate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8024',
        Common_Name: '4-hydroxyindole-3-carbonyl nitrile biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-8024',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|4-hydroxyindole-3-carbonyl nitrile biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7524',
        Common_Name: 'mevalonate pathway III (Thermoplasma)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|Isopentenyl-Diphosphate-Biosynthesis|Mevalonate-Pathways|PWY-7524',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|Isopentenyl Diphosphate Biosynthesis|Mevalonate Pathways|mevalonate pathway III (Thermoplasma)',
    },
    {
        BioCyc_ID: 'PWY-5078',
        Common_Name: 'L-isoleucine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ISOLEUCINE-DEG|PWY-5078',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-isoleucine Degradation|L-isoleucine degradation II',
    },
    {
        BioCyc_ID: 'PWY-5416',
        Common_Name: 'superpathway of diterpene resin acids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|Resin-Acids-Biosynthesis|PWY-5416',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Resin Acid Biosynthesis|superpathway of diterpene resin acids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5416',
        Common_Name: 'superpathway of diterpene resin acids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5416',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of diterpene resin acids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7691',
        Common_Name: '10,13-epoxy-11-methyl-octadecadienoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Branched-Fatty-Acids-Biosynthesis|PWY-7691',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Branched Fatty Acid Biosynthesis|10,13-epoxy-11-methyl-octadecadienoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7691',
        Common_Name: '10,13-epoxy-11-methyl-octadecadienoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PWY-7691',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|10,13-epoxy-11-methyl-octadecadienoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5489',
        Common_Name: 'methyl parathion degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-5489',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|methyl parathion degradation',
    },
    {
        BioCyc_ID: 'PWY-6698',
        Common_Name: 'oxalate degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Oxalate-Degradation|PWY-6698',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Oxalate Degradation|oxalate degradation V',
    },
    {
        BioCyc_ID: 'PWY-8202',
        Common_Name: '11-oxyandrogens biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-8202',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|11-oxyandrogens biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7774',
        Common_Name: 'propane degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Hydrocarbon-Degradation|Propane-Degradation|PWY-7774',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hydrocarbon Degradation|Propane Degradation|propane degradation I',
    },
    {
        BioCyc_ID: 'PWY-6389',
        Common_Name: 'pyruvate fermentation to (<i>S</i>)-acetoin',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Acetoin-Biosynthesis|PWY-6389',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetoin|pyruvate fermentation to (<i>S</i>)-acetoin',
    },
    {
        BioCyc_ID: 'PWY-7135',
        Common_Name: 'emetine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7135',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|emetine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6894',
        Common_Name: 'thiamine diphosphate biosynthesis I (E. coli)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Diphosphate-Biosynthesis|PWY-6894',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|Thiamine Diphosphate Biosynthesis|thiamine diphosphate biosynthesis I (E. coli)',
    },
    {
        BioCyc_ID: 'PWY-6894',
        Common_Name: 'thiamine diphosphate biosynthesis I (E. coli)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Diphosphate-Biosynthesis|PWY-6894',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|Thiamine Diphosphate Biosynthesis|thiamine diphosphate biosynthesis I (E. coli)',
    },
    {
        BioCyc_ID: 'PWY-723',
        Common_Name: 'alkylnitronates degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY-723',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|alkylnitronates degradation',
    },
    {
        BioCyc_ID: 'PWY-6263',
        Common_Name: 'superpathway of menaquinol-8 biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-6263',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-8 biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6263',
        Common_Name: 'superpathway of menaquinol-8 biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6263',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-8 biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY0-1479',
        Common_Name: 'tRNA processing',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY0-1479',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA processing',
    },
    {
        BioCyc_ID: 'PWY-4861',
        Common_Name: 'UDP-&alpha;-D-galacturonate biosynthesis I (from UDP-D-glucuronate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-4861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&alpha;-D-galacturonate biosynthesis I (from UDP-D-glucuronate)',
    },
    {
        BioCyc_ID: 'PWY-862',
        Common_Name: 'fructan degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-862',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|fructan degradation',
    },
    {
        BioCyc_ID: 'PWY-862',
        Common_Name: 'fructan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-862',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|fructan degradation',
    },
    {
        BioCyc_ID: 'PWY-862',
        Common_Name: 'fructan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-862',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|fructan degradation',
    },
    {
        BioCyc_ID: 'PWY-6895',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6895',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of thiamine diphosphate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6895',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|PWY-6895',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|superpathway of thiamine diphosphate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6895',
        Common_Name: 'superpathway of thiamine diphosphate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|PWY-6895',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|superpathway of thiamine diphosphate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8299',
        Common_Name: '(3<i>S</i>)-diazinane-3-carboxylate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-8299',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|(3<i>S</i>)-diazinane-3-carboxylate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7560',
        Common_Name: 'methylerythritol phosphate pathway II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|Isopentenyl-Diphosphate-Biosynthesis|MEP-Pathways|PWY-7560',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|Isopentenyl Diphosphate Biosynthesis|Methylerythritol Phosphate Pathways|methylerythritol phosphate pathway II',
    },
    {
        BioCyc_ID: 'ILEUDEG-PWY',
        Common_Name: 'L-isoleucine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ISOLEUCINE-DEG|ILEUDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-isoleucine Degradation|L-isoleucine degradation I',
    },
    {
        BioCyc_ID: 'PWY-5538',
        Common_Name: 'pyruvate fermentation to acetate VI',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetate-Formation|Pyruvate-Acetate-Fermentation|PWY-5538',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Acetate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate VI',
    },
    {
        BioCyc_ID: 'PWY-5538',
        Common_Name: 'pyruvate fermentation to acetate VI',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Acetate-Fermentation|PWY-5538',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetate|pyruvate fermentation to acetate VI',
    },
    {
        BioCyc_ID: 'PWY-5538',
        Common_Name: 'pyruvate fermentation to acetate VI',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5538',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|pyruvate fermentation to acetate VI',
    },
    {
        BioCyc_ID: 'PWY-8174',
        Common_Name: 'odd <i>iso</i>-branched-chain fatty acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Branched-Fatty-Acids-Biosynthesis|PWY-8174',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Branched Fatty Acid Biosynthesis|odd <i>iso</i>-branched-chain fatty acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6956',
        Common_Name: 'naphthalene degradation to acetyl-CoA',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6956',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|naphthalene degradation to acetyl-CoA',
    },
    {
        BioCyc_ID: 'PWY-6956',
        Common_Name: 'naphthalene degradation to acetyl-CoA',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6956',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|naphthalene degradation to acetyl-CoA',
    },
    {
        BioCyc_ID: 'PWY-6694',
        Common_Name: 'oxalate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Oxalate-Degradation|PWY-6694',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Oxalate Degradation|oxalate degradation I',
    },
    {
        BioCyc_ID: 'PWY66-380',
        Common_Name: 'estradiol biosynthesis I (via estrone)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY66-380',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|estradiol biosynthesis I (via estrone)',
    },
    {
        BioCyc_ID: 'PWY-7776',
        Common_Name: 'ethene and chloroethene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Hydrocarbon-Degradation|Alkene-Degradation|PWY-7776',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hydrocarbon Degradation|Alkene Degradation|ethene and chloroethene degradation',
    },
    {
        BioCyc_ID: 'PWY-5939',
        Common_Name: 'pyruvate fermentation to (<i>R</i>)-acetoin II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Acetoin-Biosynthesis|PWY-5939',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Acetoin|pyruvate fermentation to (<i>R</i>)-acetoin II',
    },
    {
        BioCyc_ID: 'PWY-7525',
        Common_Name: 'fumitremorgin C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7525',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|fumitremorgin C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6893',
        Common_Name: 'thiamine diphosphate biosynthesis II (Bacillus)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Diphosphate-Biosynthesis|PWY-6893',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|Thiamine Diphosphate Biosynthesis|thiamine diphosphate biosynthesis II (Bacillus)',
    },
    {
        BioCyc_ID: 'PWY-6893',
        Common_Name: 'thiamine diphosphate biosynthesis II (Bacillus)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Diphosphate-Biosynthesis|PWY-6893',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|Thiamine Diphosphate Biosynthesis|thiamine diphosphate biosynthesis II (Bacillus)',
    },
    {
        BioCyc_ID: 'PWY-7978',
        Common_Name: '<i>N</i>-methylpyrrolidone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY-7978',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|<i>N</i>-methylpyrrolidone degradation',
    },
    {
        BioCyc_ID: 'PWY-5840',
        Common_Name: 'superpathway of menaquinol-7 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5840',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-7 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5840',
        Common_Name: 'superpathway of menaquinol-7 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5840',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-7 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7286',
        Common_Name: '7-(3-amino-3-carboxypropyl)-wyosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7286',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|7-(3-amino-3-carboxypropyl)-wyosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7346',
        Common_Name: 'UDP-&alpha;-D-glucuronate biosynthesis (from UDP-glucose)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7346',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&alpha;-D-glucuronate biosynthesis (from UDP-glucose)',
    },
    {
        BioCyc_ID: 'PWY-6816',
        Common_Name: 'agarose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-6816',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|agarose degradation',
    },
    {
        BioCyc_ID: 'PWY-6816',
        Common_Name: 'agarose degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-6816',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|agarose degradation',
    },
    {
        BioCyc_ID: 'PWY-6466',
        Common_Name: "pyridoxal 5'-phosphate biosynthesis II",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Vitamin-B6-Biosynthesis|PWY-6466',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|pyridoxal 5'-phosphate biosynthesis II",
    },
    {
        BioCyc_ID: 'PWY-6466',
        Common_Name: "pyridoxal 5'-phosphate biosynthesis II",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-B6-Biosynthesis|PWY-6466',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|pyridoxal 5'-phosphate biosynthesis II",
    },
    {
        BioCyc_ID: 'PWY-5473',
        Common_Name: 'hydroxycinnamic acid serotonin amides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-5473',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|hydroxycinnamic acid serotonin amides biosynthesis',
    },
    {
        BioCyc_ID: 'NONMEVIPP-PWY',
        Common_Name: 'methylerythritol phosphate pathway I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|ISOPRENOIDS|Isopentenyl-Diphosphate-Biosynthesis|MEP-Pathways|NONMEVIPP-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Hemiterpene Biosynthesis|Isopentenyl Diphosphate Biosynthesis|Methylerythritol Phosphate Pathways|methylerythritol phosphate pathway I',
    },
    {
        BioCyc_ID: 'PWY-8184',
        Common_Name: 'L-isoleucine degradation III (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ISOLEUCINE-DEG|PWY-8184',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-isoleucine Degradation|L-isoleucine degradation III (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8184',
        Common_Name: 'L-isoleucine degradation III (oxidative Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Oxidative|PWY-8184',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Oxidative Branch)|L-isoleucine degradation III (oxidative Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-7825',
        Common_Name: 'juglone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7825',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|juglone degradation',
    },
    {
        BioCyc_ID: 'PWY-4381',
        Common_Name: 'fatty acid biosynthesis initiation (type II)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Fatty-Acid-Biosyn-Initiation|PWY-4381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Fatty Acid Biosynthesis Initiation|fatty acid biosynthesis initiation (type II)',
    },
    {
        BioCyc_ID: 'PWY-6697',
        Common_Name: 'oxalate degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Oxalate-Degradation|PWY-6697',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Oxalate Degradation|oxalate degradation IV',
    },
    {
        BioCyc_ID: 'PWY-5991',
        Common_Name: 'superpathway of linamarin and lotaustralin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5991',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of linamarin and lotaustralin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6241',
        Common_Name: 'thyroid hormone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|PWY-6241',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|thyroid hormone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5534',
        Common_Name: 'propene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Hydrocarbon-Degradation|Alkene-Degradation|PWY-5534',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hydrocarbon Degradation|Alkene Degradation|propene degradation',
    },
    {
        BioCyc_ID: 'PWY-5480',
        Common_Name: 'pyruvate fermentation to ethanol I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Ethanol-Fermentation|PWY-5480',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Ethanol|pyruvate fermentation to ethanol I',
    },
    {
        BioCyc_ID: 'PWY-5480',
        Common_Name: 'pyruvate fermentation to ethanol I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Ethanol-Fermentation|PWY-5480',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Ethanol|pyruvate fermentation to ethanol I',
    },
    {
        BioCyc_ID: 'PWY-7704',
        Common_Name: 'viridicatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7704',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|viridicatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6908',
        Common_Name: 'thiamine diphosphate biosynthesis IV (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Diphosphate-Biosynthesis|PWY-6908',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|Thiamine Diphosphate Biosynthesis|thiamine diphosphate biosynthesis IV (eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-6908',
        Common_Name: 'thiamine diphosphate biosynthesis IV (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Diphosphate-Biosynthesis|PWY-6908',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|Thiamine Diphosphate Biosynthesis|thiamine diphosphate biosynthesis IV (eukaryotes)',
    },
    {
        BioCyc_ID: 'ACETOACETATE-DEG-PWY',
        Common_Name: 'acetoacetate degradation (to acetyl CoA)',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|ACETOACETATE-DEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|acetoacetate degradation (to acetyl CoA)',
    },
    {
        BioCyc_ID: 'PWY-7998',
        Common_Name: 'menaquinol-4 biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-7998',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-4 biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7888',
        Common_Name: 'tRNA-uridine 2-thiolation (cytoplasmic)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7888',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA-uridine 2-thiolation (cytoplasmic)',
    },
    {
        BioCyc_ID: 'PWY-63',
        Common_Name: 'UDP-&beta;-L-arabinose biosynthesis I (from UDP-&alpha;-D-xylose)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-63',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&beta;-L-arabinose biosynthesis I (from UDP-&alpha;-D-xylose)',
    },
    {
        BioCyc_ID: 'PWY-7456',
        Common_Name: '&beta;-(1,4)-mannan degradation',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-7456',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|&beta;-(1,4)-mannan degradation',
    },
    {
        BioCyc_ID: 'PWY-7456',
        Common_Name: '&beta;-(1,4)-mannan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-7456',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|&beta;-(1,4)-mannan degradation',
    },
    {
        BioCyc_ID: 'PWY-7456',
        Common_Name: '&beta;-(1,4)-mannan degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-7456',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|&beta;-(1,4)-mannan degradation',
    },
    {
        BioCyc_ID: 'PYRIDOXSYN-PWY',
        Common_Name: "pyridoxal 5'-phosphate biosynthesis I",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Vitamin-B6-Biosynthesis|PYRIDOXSYN-PWY',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|pyridoxal 5'-phosphate biosynthesis I",
    },
    {
        BioCyc_ID: 'PYRIDOXSYN-PWY',
        Common_Name: "pyridoxal 5'-phosphate biosynthesis I",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-B6-Biosynthesis|PYRIDOXSYN-PWY',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|pyridoxal 5'-phosphate biosynthesis I",
    },
    {
        BioCyc_ID: 'PWY-6448',
        Common_Name: 'hordatine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-6448',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|hordatine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5035',
        Common_Name: 'gibberellin biosynthesis III (early C-13 hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-5035',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis III (early C-13 hydroxylation)',
    },
    {
        BioCyc_ID: 'PWY-5035',
        Common_Name: 'gibberellin biosynthesis III (early C-13 hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-5035',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis III (early C-13 hydroxylation)',
    },
    {
        BioCyc_ID: 'PWY-5082',
        Common_Name: 'L-methionine degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|METHIONINE-DEG|PWY-5082',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-methionine Degradation|L-methionine degradation III',
    },
    {
        BioCyc_ID: 'P342-PWY',
        Common_Name: 'orcinol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|P342-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|orcinol degradation',
    },
    {
        BioCyc_ID: 'PWY66-429',
        Common_Name: 'fatty acid biosynthesis initiation (mitochondria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Fatty-Acid-Biosyn-Initiation|PWY66-429',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Fatty Acid Biosynthesis Initiation|fatty acid biosynthesis initiation (mitochondria)',
    },
    {
        BioCyc_ID: '2OXOBUTYRATECAT-PWY',
        Common_Name: '2-oxobutanoate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|2-Oxobutanoate-Degradation|2OXOBUTYRATECAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|2-Oxobutanoate Degradation|2-oxobutanoate degradation II',
    },
    {
        BioCyc_ID: 'PWY-6234',
        Common_Name: 'superpathway of jasmonoyl-amino acid conjugates biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6234',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of jasmonoyl-amino acid conjugates biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7778',
        Common_Name: '2-methylpropene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Hydrocarbon-Degradation|Alkene-Degradation|PWY-7778',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hydrocarbon Degradation|Alkene Degradation|2-methylpropene degradation',
    },
    {
        BioCyc_ID: 'PWY-6587',
        Common_Name: 'pyruvate fermentation to ethanol III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Ethanol-Fermentation|PWY-6587',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Ethanol|pyruvate fermentation to ethanol III',
    },
    {
        BioCyc_ID: 'PWY-6587',
        Common_Name: 'pyruvate fermentation to ethanol III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Ethanol-Fermentation|PWY-6587',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Ethanol|pyruvate fermentation to ethanol III',
    },
    {
        BioCyc_ID: 'PWY-7543',
        Common_Name: '5-<i>N</i>-acetylardeemin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7543',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|5-<i>N</i>-acetylardeemin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6907',
        Common_Name: 'thiamine diphosphate biosynthesis III (Staphylococcus)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Diphosphate-Biosynthesis|PWY-6907',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|Thiamine Diphosphate Biosynthesis|thiamine diphosphate biosynthesis III (Staphylococcus)',
    },
    {
        BioCyc_ID: 'PWY-6907',
        Common_Name: 'thiamine diphosphate biosynthesis III (Staphylococcus)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|Thiamin-Diphosphate-Biosynthesis|PWY-6907',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|Thiamine Diphosphate Biosynthesis|thiamine diphosphate biosynthesis III (Staphylococcus)',
    },
    {
        BioCyc_ID: 'PWY-5849',
        Common_Name: 'menaquinol-6 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5849',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-6 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7892',
        Common_Name: 'tRNA-uridine 2-thiolation and selenation (bacteria)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7892',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA-uridine 2-thiolation and selenation (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7842',
        Common_Name: 'UDP-yelosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7842',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-yelosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8314',
        Common_Name: 'inulin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-8314',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|inulin degradation',
    },
    {
        BioCyc_ID: 'PWY-8314',
        Common_Name: 'inulin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-8314',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|inulin degradation',
    },
    {
        BioCyc_ID: 'PLPSAL-PWY',
        Common_Name: "pyridoxal 5'-phosphate salvage I",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Vitamin-B6-Biosynthesis|PLPSAL-PWY',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|pyridoxal 5'-phosphate salvage I",
    },
    {
        BioCyc_ID: 'PLPSAL-PWY',
        Common_Name: "pyridoxal 5'-phosphate salvage I",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-B6-Biosynthesis|PLPSAL-PWY',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|pyridoxal 5'-phosphate salvage I",
    },
    {
        BioCyc_ID: 'PWY-8055',
        Common_Name: 'palmitoyl ethanolamide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|PWY-8055',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|palmitoyl ethanolamide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5070',
        Common_Name: 'gibberellin biosynthesis I (non C-3, non C-13 hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-5070',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis I (non C-3, non C-13 hydroxylation)',
    },
    {
        BioCyc_ID: 'PWY-5070',
        Common_Name: 'gibberellin biosynthesis I (non C-3, non C-13 hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-5070',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis I (non C-3, non C-13 hydroxylation)',
    },
    {
        BioCyc_ID: 'METHIONINE-DEG1-PWY',
        Common_Name: 'L-methionine degradation I (to L-homocysteine)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|METHIONINE-DEG|METHIONINE-DEG1-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-methionine Degradation|L-methionine degradation I (to L-homocysteine)',
    },
    {
        BioCyc_ID: 'PWY-6503',
        Common_Name: 'superpathway of ergotamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-6503',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|superpathway of ergotamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6503',
        Common_Name: 'superpathway of ergotamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6503',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of ergotamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6799',
        Common_Name: 'fatty acid biosynthesis initiation (plant mitochondria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Fatty-Acid-Biosyn-Initiation|PWY-6799',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Fatty Acid Biosynthesis Initiation|fatty acid biosynthesis initiation (plant mitochondria)',
    },
    {
        BioCyc_ID: 'PWY-6404',
        Common_Name: 'superpathway of mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|PWY-6404',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|superpathway of mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6404',
        Common_Name: 'superpathway of mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|PWY-6404',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|superpathway of mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6404',
        Common_Name: 'superpathway of mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|PWY-6404',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Glycan Pathways|superpathway of mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6404',
        Common_Name: 'superpathway of mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6404',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of mycolyl-arabinogalactan-peptidoglycan complex biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6184',
        Common_Name: 'methylsalicylate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6184',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|methylsalicylate degradation',
    },
    {
        BioCyc_ID: 'PWY-5130',
        Common_Name: '2-oxobutanoate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|2-Oxobutanoate-Degradation|PWY-5130',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|2-Oxobutanoate Degradation|2-oxobutanoate degradation I',
    },
    {
        BioCyc_ID: 'PWY-5130',
        Common_Name: '2-oxobutanoate degradation I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5130',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|2-oxobutanoate degradation I',
    },
    {
        BioCyc_ID: 'PWY-7777',
        Common_Name: 'isoprene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Hydrocarbon-Degradation|Alkene-Degradation|PWY-7777',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hydrocarbon Degradation|Alkene Degradation|isoprene degradation',
    },
    {
        BioCyc_ID: 'PWY-5486',
        Common_Name: 'pyruvate fermentation to ethanol II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Alcohol-Biosynthesis|Pyruvate-Ethanol-Fermentation|PWY-5486',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Alcohols|Pyruvate Fermentation to Ethanol|pyruvate fermentation to ethanol II',
    },
    {
        BioCyc_ID: 'PWY-5486',
        Common_Name: 'pyruvate fermentation to ethanol II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Pyruvate-Degradation|Pyruvate-Ethanol-Fermentation|PWY-5486',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation of Pyruvate|Pyruvate Fermentation to Ethanol|pyruvate fermentation to ethanol II',
    },
    {
        BioCyc_ID: 'PWY-8100',
        Common_Name: 'crotonosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-8100',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|crotonosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6890',
        Common_Name: '4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|HMP-PP-Biosynthesis|PWY-6890',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis|4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6890',
        Common_Name: '4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|HMP-PP-Biosynthesis|PWY-6890',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis|4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5386-1',
        Common_Name: 'methylglyoxal degradation VIII',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|PWY-5386-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|methylglyoxal degradation VIII',
    },
    {
        BioCyc_ID: 'PWY-5386-1',
        Common_Name: 'methylglyoxal degradation VIII',
        MetaCyc_hierarchy_IDs: 'Degradation|Aldehyde-Degradation|Methylglyoxal-Detoxification|PWY-5386-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aldehyde Degradation|Methylglyoxal Detoxification|methylglyoxal degradation VIII',
    },
    {
        BioCyc_ID: 'PWY-5386-1',
        Common_Name: 'methylglyoxal degradation VIII',
        MetaCyc_hierarchy_IDs: 'Detoxification|Methylglyoxal-Detoxification|PWY-5386-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Methylglyoxal Detoxification|methylglyoxal degradation VIII',
    },
    {
        BioCyc_ID: 'PWY-5891',
        Common_Name: 'menaquinol-11 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5891',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-11 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8341',
        Common_Name: '<i>N</i><sup>1</sup>-methyl-<i>N</i><sup>3</sup>-aminocarboxypropyl-pseudouridine-modified rRNA biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-8341',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|<i>N</i><sup>1</sup>-methyl-<i>N</i><sup>3</sup>-aminocarboxypropyl-pseudouridine-modified rRNA biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8273',
        Common_Name: 'UDP-3-acetamido-2-amino-2,3-dideoxy-&alpha;-D-glucopyranose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-8273',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-3-acetamido-2-amino-2,3-dideoxy-&alpha;-D-glucopyranose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6815',
        Common_Name: 'porphyran degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-6815',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|porphyran degradation',
    },
    {
        BioCyc_ID: 'PWY-6815',
        Common_Name: 'porphyran degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-6815',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|porphyran degradation',
    },
    {
        BioCyc_ID: 'PWY0-845',
        Common_Name: "superpathway of pyridoxal 5'-phosphate biosynthesis and salvage",
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-845',
        MetaCyc_hierarchy_Names: "All Pathways and Reactions|Pathways|Superpathways|superpathway of pyridoxal 5'-phosphate biosynthesis and salvage",
    },
    {
        BioCyc_ID: 'PWY0-845',
        Common_Name: "superpathway of pyridoxal 5'-phosphate biosynthesis and salvage",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Vitamin-B6-Biosynthesis|PWY0-845',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|superpathway of pyridoxal 5'-phosphate biosynthesis and salvage",
    },
    {
        BioCyc_ID: 'PWY0-845',
        Common_Name: "superpathway of pyridoxal 5'-phosphate biosynthesis and salvage",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-B6-Biosynthesis|PWY0-845',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|superpathway of pyridoxal 5'-phosphate biosynthesis and salvage",
    },
    {
        BioCyc_ID: 'PWY-5047',
        Common_Name: 'gibberellin biosynthesis IV (<i>Gibberella fujikuroi</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-5047',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis IV (<i>Gibberella fujikuroi</i>)',
    },
    {
        BioCyc_ID: 'PWY-5047',
        Common_Name: 'gibberellin biosynthesis IV (<i>Gibberella fujikuroi</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-5047',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis IV (<i>Gibberella fujikuroi</i>)',
    },
    {
        BioCyc_ID: 'PWY-701',
        Common_Name: 'L-methionine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|METHIONINE-DEG|PWY-701',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-methionine Degradation|L-methionine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7008',
        Common_Name: '2-hydroxybiphenyl degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7008',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2-hydroxybiphenyl degradation',
    },
    {
        BioCyc_ID: 'PWY-5966-1',
        Common_Name: 'fatty acid biosynthesis initiation (type I)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Fatty-Acid-Biosyn-Initiation|PWY-5966-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Fatty Acid Biosynthesis Initiation|fatty acid biosynthesis initiation (type I)',
    },
    {
        BioCyc_ID: 'COLANSYN-PWY',
        Common_Name: 'colanic acid building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|COLANSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|colanic acid building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'COLANSYN-PWY',
        Common_Name: 'colanic acid building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|COLANSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|colanic acid building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6928',
        Common_Name: 'superpathway of cholesterol degradation I (cholesterol oxidase)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Cholesterol-Degradation|PWY-6928',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Cholesterol Degradation|superpathway of cholesterol degradation I (cholesterol oxidase)',
    },
    {
        BioCyc_ID: 'PWY-6928',
        Common_Name: 'superpathway of cholesterol degradation I (cholesterol oxidase)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6928',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of cholesterol degradation I (cholesterol oxidase)',
    },
    {
        BioCyc_ID: 'PWY-7871',
        Common_Name: 'D-threonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|PWY-7871',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|D-threonate degradation',
    },
    {
        BioCyc_ID: 'HOMOCYSDEGR-PWY',
        Common_Name: 'L-cysteine biosynthesis III (from L-homocysteine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|HOMOCYSDEGR-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|L-cysteine biosynthesis III (from L-homocysteine)',
    },
    {
        BioCyc_ID: 'HOMOCYSDEGR-PWY',
        Common_Name: 'L-cysteine biosynthesis III (from L-homocysteine)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|HOMOCYSTEINE-DEG|HOMOCYSDEGR-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|L-homocysteine Degradation|L-cysteine biosynthesis III (from L-homocysteine)',
    },
    {
        BioCyc_ID: 'PWY-7558',
        Common_Name: '&alpha;-cyclopiazonate detoxification',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7558',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|&alpha;-cyclopiazonate detoxification',
    },
    {
        BioCyc_ID: 'PWY-5976',
        Common_Name: 'dhurrin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|CYANOGENIC-GLUCOSIDE-DEG|PWY-5976',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Cyanogenic Glucoside Degradation|dhurrin degradation',
    },
    {
        BioCyc_ID: 'PWY-7282',
        Common_Name: '4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Thiamine-Biosynthesis|HMP-PP-Biosynthesis|PWY-7282',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Thiamine Biosynthesis|4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis|4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7282',
        Common_Name: '4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Thiamine-Biosynthesis|HMP-PP-Biosynthesis|PWY-7282',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Thiamine Biosynthesis|4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis|4-amino-2-methyl-5-diphosphomethylpyrimidine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5896',
        Common_Name: 'superpathway of menaquinol-10 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5896',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-10 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5896',
        Common_Name: 'superpathway of menaquinol-10 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5896',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-10 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-399',
        Common_Name: 'gluconeogenesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Gluconeogenesis|PWY66-399',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Gluconeogenesis|gluconeogenesis III',
    },
    {
        BioCyc_ID: 'PWY-6711',
        Common_Name: 'archaeosine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-6711',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|archaeosine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-3261',
        Common_Name: 'UDP-&beta;-L-rhamnose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-3261',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&beta;-L-rhamnose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6986',
        Common_Name: 'alginate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|POLYSACCHARIDES-DEG|PWY-6986',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Polysaccharide Degradation|alginate degradation',
    },
    {
        BioCyc_ID: 'PWY-6986',
        Common_Name: 'alginate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Polymer-Degradation|POLYSACCHARIDES-DEG|PWY-6986',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Polymeric Compound Degradation|Polysaccharide Degradation|alginate degradation',
    },
    {
        BioCyc_ID: 'PWY-7204',
        Common_Name: "pyridoxal 5'-phosphate salvage II (plants)",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Vitamin-B6-Biosynthesis|PWY-7204',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|pyridoxal 5'-phosphate salvage II (plants)",
    },
    {
        BioCyc_ID: 'PWY-7204',
        Common_Name: "pyridoxal 5'-phosphate salvage II (plants)",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-B6-Biosynthesis|PWY-7204',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin B<sub>6</sub> Biosynthesis|pyridoxal 5'-phosphate salvage II (plants)",
    },
    {
        BioCyc_ID: 'PWY-5036',
        Common_Name: 'gibberellin biosynthesis II (early C-3 hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-5036',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis II (early C-3 hydroxylation)',
    },
    {
        BioCyc_ID: 'PWY-5036',
        Common_Name: 'gibberellin biosynthesis II (early C-3 hydroxylation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-5036',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis II (early C-3 hydroxylation)',
    },
    {
        BioCyc_ID: 'SERDEG-PWY',
        Common_Name: 'L-serine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|SERINE-DEG|SERDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-serine Degradation|L-serine degradation',
    },
    {
        BioCyc_ID: 'PWY-7082',
        Common_Name: 'ammonia oxidation IV (autotrophic ammonia oxidizers)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-oxidation|PWY-7082',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Oxidation|ammonia oxidation IV (autotrophic ammonia oxidizers)',
    },
    {
        BioCyc_ID: 'PWY-7082',
        Common_Name: 'ammonia oxidation IV (autotrophic ammonia oxidizers)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|PWY-7082',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|ammonia oxidation IV (autotrophic ammonia oxidizers)',
    },
    {
        BioCyc_ID: 'PWY-7082',
        Common_Name: 'ammonia oxidation IV (autotrophic ammonia oxidizers)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7082',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|ammonia oxidation IV (autotrophic ammonia oxidizers)',
    },
    {
        BioCyc_ID: 'PWY-5972',
        Common_Name: 'stearate biosynthesis I (animals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Stearate-Biosynthesis|PWY-5972',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Stearate Biosynthesis|stearate biosynthesis I (animals)',
    },
    {
        BioCyc_ID: 'PWY-8347',
        Common_Name: 'adipate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|PWY-8347',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|adipate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8065',
        Common_Name: 'chlorpyrifos degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-8065',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|chlorpyrifos degradation',
    },
    {
        BioCyc_ID: 'PWY18C3-2',
        Common_Name: 'solasodine and soladulcidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY18C3-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|solasodine and soladulcidine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7874',
        Common_Name: 'L-threonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|PWY-7874',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|L-threonate degradation',
    },
    {
        BioCyc_ID: 'PWY-7089',
        Common_Name: 'taxiphyllin bioactivation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|CYANOGENIC-GLUCOSIDE-DEG|PWY-7089',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Cyanogenic Glucoside Degradation|taxiphyllin bioactivation',
    },
    {
        BioCyc_ID: 'PWY-7041',
        Common_Name: 'the visual cycle II (molluscs)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-A-Biosynthesis|Visual-Cycle|PWY-7041',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin A Biosynthesis|Visual Cycles|the visual cycle II (molluscs)',
    },
    {
        BioCyc_ID: 'PWY-5899',
        Common_Name: 'superpathway of menaquinol-13 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5899',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-13 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5899',
        Common_Name: 'superpathway of menaquinol-13 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5899',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-13 biosynthesis',
    },
    {
        BioCyc_ID: 'GLUCONEO-PWY',
        Common_Name: 'gluconeogenesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Gluconeogenesis|GLUCONEO-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Gluconeogenesis|gluconeogenesis I',
    },
    {
        BioCyc_ID: 'PWY0-1587',
        Common_Name: 'N<sup>6</sup>-L-threonylcarbamoyladenosine<sup>37</sup>-modified tRNA biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY0-1587',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|N<sup>6</sup>-L-threonylcarbamoyladenosine<sup>37</sup>-modified tRNA biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7335',
        Common_Name: 'UDP-<i>N</i>-acetyl-&alpha;-D-mannosaminouronate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7335',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-<i>N</i>-acetyl-&alpha;-D-mannosaminouronate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8213',
        Common_Name: 'sulfoquinovose degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sulfoquinovose-Degradation|PWY-8213',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sulfoquinovose Degradation|sulfoquinovose degradation III',
    },
    {
        BioCyc_ID: 'PWY-5207',
        Common_Name: 'coenzyme B/coenzyme M regeneration I (methanophenazine-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|CoB-CoM-Reduction|PWY-5207',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Coenzyme B/Coenzyme M Regeneration|coenzyme B/coenzyme M regeneration I (methanophenazine-dependent)',
    },
    {
        BioCyc_ID: 'PWY-7232',
        Common_Name: 'gibberellin biosynthesis V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-7232',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis V',
    },
    {
        BioCyc_ID: 'PWY-7232',
        Common_Name: 'gibberellin biosynthesis V',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|GIBBERELLIN-SYN|GIBBERELLINS-BIOSYNTHESIS|PWY-7232',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Gibberellin and Gibberellin Precursor Biosynthesis|Gibberellin biosynthesis|gibberellin biosynthesis V',
    },
    {
        BioCyc_ID: 'PWY-4002',
        Common_Name: 'L-asparagine degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ASPARAGINE-DEG|PWY-4002',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-asparagine Degradation|L-asparagine degradation II',
    },
    {
        BioCyc_ID: 'PWY-7373',
        Common_Name: 'superpathway of demethylmenaquinol-6 biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|Demethylmenaquinol-6-Biosynthesis|PWY-7373',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|Demethylmenaquinol-6 Biosynthesis|superpathway of demethylmenaquinol-6 biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7373',
        Common_Name: 'superpathway of demethylmenaquinol-6 biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7373',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of demethylmenaquinol-6 biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY3O-355',
        Common_Name: 'stearate biosynthesis III (fungi)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Stearate-Biosynthesis|PWY3O-355',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Stearate Biosynthesis|stearate biosynthesis III (fungi)',
    },
    {
        BioCyc_ID: 'PWY-5710',
        Common_Name: 'capsaicin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-5710',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|capsaicin biosynthesis',
    },
    {
        BioCyc_ID: 'P661-PWY',
        Common_Name: 'dibenzo-<I>p</I>-dioxin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|P661-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|dibenzo-<I>p</I>-dioxin degradation',
    },
    {
        BioCyc_ID: 'PWY-6455',
        Common_Name: 'vancomycin resistance II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|PWY-6455',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|vancomycin resistance II',
    },
    {
        BioCyc_ID: 'PWY-6455',
        Common_Name: 'vancomycin resistance II',
        MetaCyc_hierarchy_IDs: 'Detoxification|Antibiotic-Resistance|Vancomycin-Resistnace|PWY-6455',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Antibiotic Resistance|Vancomycin Resistance|vancomycin resistance II',
    },
    {
        BioCyc_ID: 'IDNCAT-PWY',
        Common_Name: 'L-idonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|IDNCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|L-idonate degradation',
    },
    {
        BioCyc_ID: 'PWY-7093',
        Common_Name: 'vicianin bioactivation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|CYANOGENIC-GLUCOSIDE-DEG|PWY-7093',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Cyanogenic Glucoside Degradation|vicianin bioactivation',
    },
    {
        BioCyc_ID: 'PWY-6861',
        Common_Name: 'the visual cycle I (vertebrates)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-A-Biosynthesis|Visual-Cycle|PWY-6861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin A Biosynthesis|Visual Cycles|the visual cycle I (vertebrates)',
    },
    {
        BioCyc_ID: 'PWY-5839',
        Common_Name: 'menaquinol-7 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5839',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-7 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7285',
        Common_Name: 'methylwyosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7285',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|methylwyosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7344',
        Common_Name: 'UDP-&alpha;-D-galactose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-7344',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&alpha;-D-galactose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8350',
        Common_Name: 'sulfoquinovose degradation VI',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sulfoquinovose-Degradation|PWY-8350',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sulfoquinovose Degradation|sulfoquinovose degradation VI',
    },
    {
        BioCyc_ID: 'PWY-7867',
        Common_Name: 'coenzyme B/coenzyme M regeneration IV (H<sub>2</sub>-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|CoB-CoM-Reduction|PWY-7867',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Coenzyme B/Coenzyme M Regeneration|coenzyme B/coenzyme M regeneration IV (H<sub>2</sub>-dependent)',
    },
    {
        BioCyc_ID: 'PWY-7442',
        Common_Name: 'drosopterin and aurodrosopterin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|PWY-7442',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|drosopterin and aurodrosopterin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5038',
        Common_Name: 'caffeine biosynthesis II (via paraxanthine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PURINE-ALKALOIDS|Caffeine-Biosynthesis|PWY-5038',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Purine Alkaloid Biosynthesis|Caffeine Biosynthesis|caffeine biosynthesis II (via paraxanthine)',
    },
    {
        BioCyc_ID: 'ASPARAGINE-DEG1-PWY',
        Common_Name: 'L-asparagine degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ASPARAGINE-DEG|ASPARAGINE-DEG1-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-asparagine Degradation|L-asparagine degradation I',
    },
    {
        BioCyc_ID: 'PWY-7575',
        Common_Name: 'superpathway of candicidin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-7575',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|superpathway of candicidin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7575',
        Common_Name: 'superpathway of candicidin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7575',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of candicidin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8280',
        Common_Name: 'stearate biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Stearate-Biosynthesis|PWY-8280',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Stearate Biosynthesis|stearate biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-5189',
        Common_Name: 'tetrapyrrole biosynthesis II (from glycine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|PWY-5189',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|tetrapyrrole biosynthesis II (from glycine)',
    },
    {
        BioCyc_ID: 'PWY-7604',
        Common_Name: 'notoamide C and D biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7604',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|notoamide C and D biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8439',
        Common_Name: 'poly-&gamma;-D-glutamate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|PWY-8439',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|poly-&gamma;-D-glutamate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6339',
        Common_Name: 'syringate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6339',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|syringate degradation',
    },
    {
        BioCyc_ID: 'PWY-6339',
        Common_Name: 'syringate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6339',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|syringate degradation',
    },
    {
        BioCyc_ID: 'PWY-7566',
        Common_Name: 'L-gulonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|PWY-7566',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|L-gulonate degradation',
    },
    {
        BioCyc_ID: 'PWY-3121',
        Common_Name: 'linamarin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|CYANOGENIC-GLUCOSIDE-DEG|PWY-3121',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Cyanogenic Glucoside Degradation|linamarin degradation',
    },
    {
        BioCyc_ID: 'PWY-7043',
        Common_Name: '11-<i>cis</i>-3-hydroxyretinal biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-A-Biosynthesis|Visual-Cycle|PWY-7043',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin A Biosynthesis|Visual Cycles|11-<i>cis</i>-3-hydroxyretinal biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6116',
        Common_Name: 'mannosylfructose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Disaccharide-Biosynthesis|PWY-6116',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Disaccharide Biosynthesis|mannosylfructose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7891',
        Common_Name: 'tRNA-uridine 2-thiolation (yeast mitochondria)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7891',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA-uridine 2-thiolation (yeast mitochondria)',
    },
    {
        BioCyc_ID: 'PWY0-1241',
        Common_Name: 'ADP-L-<i>glycero</i>-&beta;-D-<i>manno</i>-heptose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|ADP-Sugar-Biosynthesis|PWY0-1241',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|ADP-sugar Biosynthesis|ADP-L-<i>glycero</i>-&beta;-D-<i>manno</i>-heptose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7996',
        Common_Name: 'menaquinol-4 biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-7996',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|menaquinol-4 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7722',
        Common_Name: 'sulfoquinovose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sulfoquinovose-Degradation|PWY-7722',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sulfoquinovose Degradation|sulfoquinovose degradation II',
    },
    {
        BioCyc_ID: 'PWY-7866',
        Common_Name: 'coenzyme B/coenzyme M regeneration III (coenzyme F<sub>420</sub>-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|CoB-CoM-Reduction|PWY-7866',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Coenzyme B/Coenzyme M Regeneration|coenzyme B/coenzyme M regeneration III (coenzyme F<sub>420</sub>-dependent)',
    },
    {
        BioCyc_ID: 'PWY-6498',
        Common_Name: 'eumelanin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|PWY-6498',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|eumelanin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5037',
        Common_Name: 'caffeine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PURINE-ALKALOIDS|Caffeine-Biosynthesis|PWY-5037',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Purine Alkaloid Biosynthesis|Caffeine Biosynthesis|caffeine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8016',
        Common_Name: 'L-tyrosine degradation V (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-8016',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|L-tyrosine degradation V (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8016',
        Common_Name: 'L-tyrosine degradation V (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|TYROSINE-DEG|PWY-8016',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-tyrosine Degradation|L-tyrosine degradation V (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8188',
        Common_Name: 'L-alanine degradation VI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|ALANINE-DEG|PWY-8188',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-alanine Degradation|L-alanine degradation VI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8188',
        Common_Name: 'L-alanine degradation VI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Fermentation-to-propanoate|PWY-8188',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Propanoate|L-alanine degradation VI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8188',
        Common_Name: 'L-alanine degradation VI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Amino-Acid-Fermentation|Stickland-Reductive|PWY-8188',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Amino Acid Fermentation (Stickland reactions)|Stickland Fermentation (Reductive Branch)|L-alanine degradation VI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-8188',
        Common_Name: 'L-alanine degradation VI (reductive Stickland reaction)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8188',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-alanine degradation VI (reductive Stickland reaction)',
    },
    {
        BioCyc_ID: 'PWY-5989',
        Common_Name: 'stearate biosynthesis II (bacteria and plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Stearate-Biosynthesis|PWY-5989',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Stearate Biosynthesis|stearate biosynthesis II (bacteria and plants)',
    },
    {
        BioCyc_ID: 'PWY-5188',
        Common_Name: 'tetrapyrrole biosynthesis I (from glutamate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|PWY-5188',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|tetrapyrrole biosynthesis I (from glutamate)',
    },
    {
        BioCyc_ID: 'PWY-5958',
        Common_Name: 'acridone alkaloid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-5958',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|acridone alkaloid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6454',
        Common_Name: 'vancomycin resistance I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|PWY-6454',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|vancomycin resistance I',
    },
    {
        BioCyc_ID: 'PWY-6454',
        Common_Name: 'vancomycin resistance I',
        MetaCyc_hierarchy_IDs: 'Detoxification|Antibiotic-Resistance|Vancomycin-Resistnace|PWY-6454',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Antibiotic Resistance|Vancomycin Resistance|vancomycin resistance I',
    },
    {
        BioCyc_ID: 'PWY-7011',
        Common_Name: '2-isopropylphenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7011',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2-isopropylphenol degradation',
    },
    {
        BioCyc_ID: 'PWY-7873',
        Common_Name: 'D-erythronate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|PWY-7873',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|D-erythronate degradation II',
    },
    {
        BioCyc_ID: 'PWY-6011',
        Common_Name: 'amygdalin and prunasin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|CYANOGENIC-GLUCOSIDE-DEG|PWY-6011',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Cyanogenic Glucoside Degradation|amygdalin and prunasin degradation',
    },
    {
        BioCyc_ID: 'PWY-7042',
        Common_Name: 'the visual cycle (insects)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Vitamin-Biosynthesis|Vitamin-A-Biosynthesis|Visual-Cycle|PWY-7042',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Vitamin Biosynthesis|Vitamin A Biosynthesis|Visual Cycles|the visual cycle (insects)',
    },
    {
        BioCyc_ID: 'PWY0-662',
        Common_Name: 'PRPP biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Sugar-Phosphate-Biosynthesis|PWY0-662',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Phosphate Biosynthesis|PRPP biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7923',
        Common_Name: 'archaeosine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7923',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|archaeosine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7610',
        Common_Name: 'GDP-6-deoxy-D-<i>altro</i>-heptose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-7610',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-6-deoxy-D-<i>altro</i>-heptose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5845',
        Common_Name: 'superpathway of menaquinol-9 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Menaquinone-Biosynthesis|PWY-5845',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Menaquinol Biosynthesis|superpathway of menaquinol-9 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5845',
        Common_Name: 'superpathway of menaquinol-9 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5845',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of menaquinol-9 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8349',
        Common_Name: 'sulfoquinovose degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sulfoquinovose-Degradation|PWY-8349',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sulfoquinovose Degradation|sulfoquinovose degradation V',
    },
    {
        BioCyc_ID: 'PWY-7865',
        Common_Name: 'coenzyme B/coenzyme M regeneration II (ferredoxin-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|CoB-CoM-Reduction|PWY-7865',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Coenzyme B/Coenzyme M Regeneration|coenzyme B/coenzyme M regeneration II (ferredoxin-dependent)',
    },
    {
        BioCyc_ID: 'PWY-6432',
        Common_Name: 'curcuminoid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|PWY-6432',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|curcuminoid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3581',
        Common_Name: '(<i>S</i>)-reticuline biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|S-Reticuline-Biosynthesis|PWY-3581',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|(<i>S</i>)-reticuline biosynthesis|(<i>S</i>)-reticuline biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY0-166',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|Deoxyribonucleotide-Biosynthesis|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY0-166',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|2'-Deoxyribonucleotide Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis (<i>E. coli</i>)",
    },
    {
        BioCyc_ID: 'PWY0-166',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-De-Novo-Biosyn|Pyrimid-Deoxyribonucleot-De-Novo-Biosyn|PWY0-166',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide <i>De Novo</i> Biosynthesis|Pyrimidine Deoxyribonucleotide <i>De Novo</i> Biosynthesis|superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY0-166',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-166',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pyrimidine deoxyribonucleotides <i>de novo</i> biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-8305',
        Common_Name: 'methoxylated aromatic compound degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-8305',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|methoxylated aromatic compound degradation II',
    },
    {
        BioCyc_ID: 'PWY-6923',
        Common_Name: 'ricinine degradation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-6923',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|ricinine degradation',
    },
    {
        BioCyc_ID: 'GLUCONSUPER-PWY',
        Common_Name: 'D-gluconate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|GLUCONSUPER-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|D-gluconate degradation',
    },
    {
        BioCyc_ID: 'PWY-7820',
        Common_Name: 'teichuronic acid biosynthesis (<i>B. subtilis</i> 168)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|PWY-7820',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|teichuronic acid biosynthesis (<i>B. subtilis</i> 168)',
    },
    {
        BioCyc_ID: 'PWY-7092',
        Common_Name: 'neolinustatin bioactivation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|CYANOGENIC-GLUCOSIDE-DEG|PWY-7092',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Cyanogenic Glucoside Degradation|neolinustatin bioactivation',
    },
    {
        BioCyc_ID: 'PWY-7578',
        Common_Name: 'phycoviolobilin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Phycobilin-Biosynthesis|PWY-7578',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Phycobilin Biosynthesis|phycoviolobilin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1554',
        Common_Name: '5-(methoxycarbonylmethoxy)uridine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY0-1554',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|5-(methoxycarbonylmethoxy)uridine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY2B4Q-2',
        Common_Name: 'GDP-<i>N</i>-formyl-&alpha;-D-perosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY2B4Q-2',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-<i>N</i>-formyl-&alpha;-D-perosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5889',
        Common_Name: 'rhodoquinone-9 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Rhodoquinone-Biosynthesis|PWY-5889',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Rhodoquinone Biosynthesis|rhodoquinone-9 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7446',
        Common_Name: 'sulfoquinovose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sulfoquinovose-Degradation|PWY-7446',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sulfoquinovose Degradation|sulfoquinovose degradation I',
    },
    {
        BioCyc_ID: 'PWY-7868',
        Common_Name: 'coenzyme B/coenzyme M regeneration V (formate-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|CoB-CoM-Reduction|PWY-7868',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Coenzyme B/Coenzyme M Regeneration|coenzyme B/coenzyme M regeneration V (formate-dependent)',
    },
    {
        BioCyc_ID: 'PWY-7917',
        Common_Name: 'pheomelanin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|PWY-7917',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|pheomelanin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6133',
        Common_Name: '(S)-reticuline biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|ISOQUINOLINE-ALKALOIDS|S-Reticuline-Biosynthesis|PWY-6133',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Isoquinoline and Benzylisoquinoline Alkaloid Biosynthesis|(<i>S</i>)-reticuline biosynthesis|(S)-reticuline biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8279',
        Common_Name: 'palmitate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Palmitate-Biosynthesis|PWY-8279',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Palmitate Biosynthesis|palmitate biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY66-3',
        Common_Name: 'cholesterol biosynthesis II (via 24,25-dihydrolanosterol)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY66-3',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|cholesterol biosynthesis II (via 24,25-dihydrolanosterol)',
    },
    {
        BioCyc_ID: 'PWY66-3',
        Common_Name: 'cholesterol biosynthesis II (via 24,25-dihydrolanosterol)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY66-3',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|cholesterol biosynthesis II (via 24,25-dihydrolanosterol)',
    },
    {
        BioCyc_ID: 'PWY-142',
        Common_Name: '<i>m</i>-xylene degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-142',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>m</i>-xylene degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7608',
        Common_Name: 'neoxaline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7608',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|neoxaline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7516',
        Common_Name: 'L-lyxonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|PWY-7516',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|L-lyxonate degradation',
    },
    {
        BioCyc_ID: 'PWY-6626',
        Common_Name: "cytidine-5'-diphosphate-glycerol biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|PWY-6626',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|cytidine-5'-diphosphate-glycerol biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6002',
        Common_Name: 'lotaustralin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|CYANOGENIC-GLUCOSIDE-DEG|PWY-6002',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Cyanogenic Glucoside Degradation|lotaustralin degradation',
    },
    {
        BioCyc_ID: 'PWY-7170',
        Common_Name: 'phytochromobilin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Phycobilin-Biosynthesis|PWY-7170',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Phycobilin Biosynthesis|phytochromobilin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7375',
        Common_Name: 'mRNA capping I',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7375',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|mRNA capping I',
    },
    {
        BioCyc_ID: 'PWY-5659',
        Common_Name: 'GDP-mannose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-5659',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-mannose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5888',
        Common_Name: 'rhodoquinone-10 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Rhodoquinone-Biosynthesis|PWY-5888',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Rhodoquinone Biosynthesis|rhodoquinone-10 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8348',
        Common_Name: 'sulfoquinovose degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sulfoquinovose-Degradation|PWY-8348',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sulfoquinovose Degradation|sulfoquinovose degradation IV',
    },
    {
        BioCyc_ID: 'PWY-6151',
        Common_Name: '<i>S</i>-adenosyl-L-methionine salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|S-adenosyl-L-methionine-Biosynthesis|PWY-6151',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|<i>S</i>-adenosyl-L-methionine Biosynthesis|<i>S</i>-adenosyl-L-methionine salvage I',
    },
    {
        BioCyc_ID: 'PWY-6151',
        Common_Name: '<i>S</i>-adenosyl-L-methionine salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|S-adenosyl-L-methionine-Biosynthesis|PWY-6151',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|<i>S</i>-adenosyl-L-methionine Biosynthesis|<i>S</i>-adenosyl-L-methionine salvage I',
    },
    {
        BioCyc_ID: 'PWY-7987',
        Common_Name: 'vanchrobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7987',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|vanchrobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6475',
        Common_Name: '<i>trans</i>-lycopene biosynthesis II (oxygenic phototrophs and green sulfur bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|Lycopene-Biosynthesis|PWY-6475',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Lycopene Biosynthesis|<i>trans</i>-lycopene biosynthesis II (oxygenic phototrophs and green sulfur bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6475',
        Common_Name: '<i>trans</i>-lycopene biosynthesis II (oxygenic phototrophs and green sulfur bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|Lycopene-Biosynthesis|PWY-6475',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Lycopene Biosynthesis|<i>trans</i>-lycopene biosynthesis II (oxygenic phototrophs and green sulfur bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6475',
        Common_Name: '<i>trans</i>-lycopene biosynthesis II (oxygenic phototrophs and green sulfur bacteria)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|Lycopene-Biosynthesis|PWY-6475',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Lycopene Biosynthesis|<i>trans</i>-lycopene biosynthesis II (oxygenic phototrophs and green sulfur bacteria)',
    },
    {
        BioCyc_ID: 'PWY-5994',
        Common_Name: 'palmitate biosynthesis I (type I fatty acid synthase)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Palmitate-Biosynthesis|PWY-5994',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Palmitate Biosynthesis|palmitate biosynthesis I (type I fatty acid synthase)',
    },
    {
        BioCyc_ID: 'PWY-6533',
        Common_Name: 'aniline degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6533',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|aniline degradation',
    },
    {
        BioCyc_ID: 'PWY-7872',
        Common_Name: 'D-erythronate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|PWY-7872',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|D-erythronate degradation I',
    },
    {
        BioCyc_ID: 'PWY-6998',
        Common_Name: 'CDP-D-arabitol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Alditols-Biosynthesis|PWY-6998',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Nucleotide-Alditol Biosynthesis|CDP-D-arabitol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7265',
        Common_Name: 'lampranthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7265',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|lampranthin biosynthesis',
    },
    {
        BioCyc_ID: 'ALL-CHORISMATE-PWY',
        Common_Name: 'superpathway of chorismate metabolism',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ALL-CHORISMATE-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of chorismate metabolism',
    },
    {
        BioCyc_ID: 'PWY-7091',
        Common_Name: 'linustatin bioactivation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|CYANOGENIC-GLUCOSIDE-DEG|PWY-7091',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Cyanogenic Glucoside Degradation|linustatin bioactivation',
    },
    {
        BioCyc_ID: 'PWY-6995',
        Common_Name: '5-hydroxymethylfurfural degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PWY-6995',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|5-hydroxymethylfurfural degradation',
    },
    {
        BioCyc_ID: 'PWY-7580',
        Common_Name: 'phycoerythrobilin biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Phycobilin-Biosynthesis|PWY-7580',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Phycobilin Biosynthesis|phycoerythrobilin biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7889',
        Common_Name: 'tRNA-uridine 2-thiolation (mammalian mitochondria)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7889',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA-uridine 2-thiolation (mammalian mitochondria)',
    },
    {
        BioCyc_ID: 'PWY-5739',
        Common_Name: 'GDP-D-perosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-5739',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-D-perosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5041',
        Common_Name: '<i>S</i>-adenosyl-L-methionine salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|S-adenosyl-L-methionine-Biosynthesis|PWY-5041',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|<i>S</i>-adenosyl-L-methionine Biosynthesis|<i>S</i>-adenosyl-L-methionine salvage II',
    },
    {
        BioCyc_ID: 'PWY-5041',
        Common_Name: '<i>S</i>-adenosyl-L-methionine salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|S-adenosyl-L-methionine-Biosynthesis|PWY-5041',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|<i>S</i>-adenosyl-L-methionine Biosynthesis|<i>S</i>-adenosyl-L-methionine salvage II',
    },
    {
        BioCyc_ID: 'PWY-6378',
        Common_Name: 'putrebactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6378',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|putrebactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7941',
        Common_Name: 'isorenieratene biosynthesis II (<i>Chlorobiaceae</i>)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|Isorenieratene-Biosynthesis|PWY-7941',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Isorenieratene Biosynthesis|isorenieratene biosynthesis II (<i>Chlorobiaceae</i>)',
    },
    {
        BioCyc_ID: 'PWY-7941',
        Common_Name: 'isorenieratene biosynthesis II (<i>Chlorobiaceae</i>)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|Isorenieratene-Biosynthesis|PWY-7941',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Isorenieratene Biosynthesis|isorenieratene biosynthesis II (<i>Chlorobiaceae</i>)',
    },
    {
        BioCyc_ID: 'PWY-7941',
        Common_Name: 'isorenieratene biosynthesis II (<i>Chlorobiaceae</i>)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|Isorenieratene-Biosynthesis|PWY-7941',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Isorenieratene Biosynthesis|isorenieratene biosynthesis II (<i>Chlorobiaceae</i>)',
    },
    {
        BioCyc_ID: 'PWY-5971',
        Common_Name: 'palmitate biosynthesis II (type II fatty acid synthase)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Palmitate-Biosynthesis|PWY-5971',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Palmitate Biosynthesis|palmitate biosynthesis II (type II fatty acid synthase)',
    },
    {
        BioCyc_ID: 'PWY-741',
        Common_Name: '<I>p</I>-cymene degradation to <I>p</I>-cumate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-741',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<I>p</I>-cymene degradation to <I>p</I>-cumate',
    },
    {
        BioCyc_ID: 'PWY0-1306',
        Common_Name: 'L-galactonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|PWY0-1306',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|L-galactonate degradation',
    },
    {
        BioCyc_ID: 'PWY-8416',
        Common_Name: 'CDP-ribitol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Alditols-Biosynthesis|PWY-8416',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Nucleotide-Alditol Biosynthesis|CDP-ribitol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7660',
        Common_Name: 'tryptoquialanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7660',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|tryptoquialanine biosynthesis',
    },
    {
        BioCyc_ID: 'DETOX1-PWY-1',
        Common_Name: 'reactive oxygen species degradation',
        MetaCyc_hierarchy_IDs: 'Detoxification|REACTIVE-OXYGEN-SPECIES-DEGRADATION|DETOX1-PWY-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Reactive Oxygen Species Degradation|reactive oxygen species degradation',
    },
    {
        BioCyc_ID: 'DETOX1-PWY-1',
        Common_Name: 'reactive oxygen species degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|DETOX1-PWY-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|reactive oxygen species degradation',
    },
    {
        BioCyc_ID: 'PWY0-862',
        Common_Name: '(5Z)-dodecenoate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|5Z-Dodec-5-enoate-Biosynthesis|PWY0-862',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|(5Z)-dodecenoate Biosynthesis|(5Z)-dodecenoate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8302',
        Common_Name: '<i>S</i>-(6-hydroxy-4-methylhexan-4-yl)-L-cysteinylglycine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PWY-8302',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|<i>S</i>-(6-hydroxy-4-methylhexan-4-yl)-L-cysteinylglycine degradation',
    },
    {
        BioCyc_ID: 'PWY-5917',
        Common_Name: 'phycocyanobilin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Phycobilin-Biosynthesis|PWY-5917',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Phycobilin Biosynthesis|phycocyanobilin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1305',
        Common_Name: 'L-glutamate degradation IX (via 4-aminobutanoate)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Acid-Resistance|PWY0-1305',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Acid Resistance|L-glutamate degradation IX (via 4-aminobutanoate)',
    },
    {
        BioCyc_ID: 'PWY0-1305',
        Common_Name: 'L-glutamate degradation IX (via 4-aminobutanoate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|GLUTAMATE-DEG|PWY0-1305',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-glutamate Degradation|L-glutamate degradation IX (via 4-aminobutanoate)',
    },
    {
        BioCyc_ID: 'PWY-6478',
        Common_Name: 'GDP-D-<i>glycero</i>-&alpha;-D-<i>manno</i>-heptose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-6478',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-D-<i>glycero</i>-&alpha;-D-<i>manno</i>-heptose biosynthesis',
    },
    {
        BioCyc_ID: 'MET-SAM-PWY',
        Common_Name: 'superpathway of <i>S</i>-adenosyl-L-methionine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|S-adenosyl-L-methionine-Biosynthesis|MET-SAM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|<i>S</i>-adenosyl-L-methionine Biosynthesis|superpathway of <i>S</i>-adenosyl-L-methionine biosynthesis',
    },
    {
        BioCyc_ID: 'MET-SAM-PWY',
        Common_Name: 'superpathway of <i>S</i>-adenosyl-L-methionine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|S-adenosyl-L-methionine-Biosynthesis|MET-SAM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|<i>S</i>-adenosyl-L-methionine Biosynthesis|superpathway of <i>S</i>-adenosyl-L-methionine biosynthesis',
    },
    {
        BioCyc_ID: 'MET-SAM-PWY',
        Common_Name: 'superpathway of <i>S</i>-adenosyl-L-methionine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|MET-SAM-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of <i>S</i>-adenosyl-L-methionine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7990',
        Common_Name: 'staphyloferrin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7990',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|staphyloferrin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7938',
        Common_Name: 'isorenieratene biosynthesis I (actinobacteria)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|Isorenieratene-Biosynthesis|PWY-7938',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Isorenieratene Biosynthesis|isorenieratene biosynthesis I (actinobacteria)',
    },
    {
        BioCyc_ID: 'PWY-7938',
        Common_Name: 'isorenieratene biosynthesis I (actinobacteria)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|Isorenieratene-Biosynthesis|PWY-7938',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Isorenieratene Biosynthesis|isorenieratene biosynthesis I (actinobacteria)',
    },
    {
        BioCyc_ID: 'PWY-7938',
        Common_Name: 'isorenieratene biosynthesis I (actinobacteria)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|Isorenieratene-Biosynthesis|PWY-7938',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Isorenieratene Biosynthesis|isorenieratene biosynthesis I (actinobacteria)',
    },
    {
        BioCyc_ID: 'PWY0-541',
        Common_Name: 'cyclopropane fatty acid (CFA) biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Cyclopropane-fatty-acid-biosyn|PWY0-541',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Cyclopropane Fatty Acid Biosynthesis|cyclopropane fatty acid (CFA) biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1277',
        Common_Name: '3-phenylpropanoate and 3-(3-hydroxyphenyl)propanoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY0-1277',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|3-phenylpropanoate and 3-(3-hydroxyphenyl)propanoate degradation',
    },
    {
        BioCyc_ID: 'PWY0-1277',
        Common_Name: '3-phenylpropanoate and 3-(3-hydroxyphenyl)propanoate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-1277',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|3-phenylpropanoate and 3-(3-hydroxyphenyl)propanoate degradation',
    },
    {
        BioCyc_ID: 'PWY-4041',
        Common_Name: '&gamma;-glutamyl cycle',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY-4041',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|&gamma;-glutamyl cycle',
    },
    {
        BioCyc_ID: 'PWY-4041',
        Common_Name: '&gamma;-glutamyl cycle',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-4041',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|&gamma;-glutamyl cycle',
    },
    {
        BioCyc_ID: 'PWY-7127',
        Common_Name: 'CDP-D-mannitol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Alditols-Biosynthesis|PWY-7127',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Nucleotide-Alditol Biosynthesis|CDP-D-mannitol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7542',
        Common_Name: 'fumiquinazoline D biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7542',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|fumiquinazoline D biosynthesis',
    },
    {
        BioCyc_ID: 'GALACTCAT-PWY',
        Common_Name: 'D-galactonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|GALACTCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|D-galactonate degradation',
    },
    {
        BioCyc_ID: 'PWY-7858',
        Common_Name: '(5Z)-dodecenoate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|5Z-Dodec-5-enoate-Biosynthesis|PWY-7858',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|(5Z)-dodecenoate Biosynthesis|(5Z)-dodecenoate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7554',
        Common_Name: 'heme <i>d</i><sub>1</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|PWY-7554',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|heme <i>d</i><sub>1</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7554',
        Common_Name: 'heme <i>d</i><sub>1</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|PWY-7554',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|heme <i>d</i><sub>1</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1569',
        Common_Name: 'autoinducer AI-2 degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PWY0-1569',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|autoinducer AI-2 degradation',
    },
    {
        BioCyc_ID: 'PWY-7579',
        Common_Name: 'phycourobilin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Phycobilin-Biosynthesis|PWY-7579',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Phycobilin Biosynthesis|phycourobilin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1299',
        Common_Name: 'arginine dependent acid resistance',
        MetaCyc_hierarchy_IDs: 'Detoxification|Acid-Resistance|PWY0-1299',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Acid Resistance|arginine dependent acid resistance',
    },
    {
        BioCyc_ID: 'PWY-7573',
        Common_Name: 'GDP-mycosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-7573',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-mycosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5862',
        Common_Name: 'superpathway of demethylmenaquinol-9 biosynthesis',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|PWY-5862',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|superpathway of demethylmenaquinol-9 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5862',
        Common_Name: 'superpathway of demethylmenaquinol-9 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5862',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of demethylmenaquinol-9 biosynthesis',
    },
    {
        BioCyc_ID: 'SAM-PWY',
        Common_Name: 'S-adenosyl-L-methionine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|S-adenosyl-L-methionine-Biosynthesis|SAM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|<i>S</i>-adenosyl-L-methionine Biosynthesis|S-adenosyl-L-methionine biosynthesis',
    },
    {
        BioCyc_ID: 'SAM-PWY',
        Common_Name: 'S-adenosyl-L-methionine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|S-adenosyl-L-methionine-Biosynthesis|SAM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|<i>S</i>-adenosyl-L-methionine Biosynthesis|S-adenosyl-L-methionine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6407',
        Common_Name: 'yersiniabactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6407',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|yersiniabactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5397',
        Common_Name: 'crocetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5397',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|crocetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5397',
        Common_Name: 'crocetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5397',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|crocetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5397',
        Common_Name: 'crocetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5397',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|crocetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4942',
        Common_Name: 'sterculate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Cyclopropane-fatty-acid-biosyn|PWY-4942',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Cyclopropane Fatty Acid Biosynthesis|sterculate biosynthesis',
    },
    {
        BioCyc_ID: '2ASDEG-PWY',
        Common_Name: 'orthanilate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|2ASDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|orthanilate degradation',
    },
    {
        BioCyc_ID: 'PWY-5121',
        Common_Name: 'superpathway of geranylgeranyl diphosphate biosynthesis II (via MEP)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|DITERPENOID-SYN|PWY-5121',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Diterpenoid Biosynthesis|superpathway of geranylgeranyl diphosphate biosynthesis II (via MEP)',
    },
    {
        BioCyc_ID: 'PWY-5121',
        Common_Name: 'superpathway of geranylgeranyl diphosphate biosynthesis II (via MEP)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|GGPP-Biosynthesis|PWY-5121',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|Geranylgeranyl Diphosphate Biosynthesis|superpathway of geranylgeranyl diphosphate biosynthesis II (via MEP)',
    },
    {
        BioCyc_ID: 'PWY-5121',
        Common_Name: 'superpathway of geranylgeranyl diphosphate biosynthesis II (via MEP)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5121',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of geranylgeranyl diphosphate biosynthesis II (via MEP)',
    },
    {
        BioCyc_ID: 'PWY-7826',
        Common_Name: '<i>Amaryllidacea</i> alkaloids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7826',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|<i>Amaryllidacea</i> alkaloids biosynthesis',
    },
    {
        BioCyc_ID: 'KETOGLUCONMET-PWY',
        Common_Name: 'ketogluconate metabolism',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Aldonate-Degradation|KETOGLUCONMET-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Aldonic Acid Degradation|ketogluconate metabolism',
    },
    {
        BioCyc_ID: 'KETOGLUCONMET-PWY',
        Common_Name: 'ketogluconate metabolism',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|KETOGLUCONMET-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|ketogluconate metabolism',
    },
    {
        BioCyc_ID: 'PWY-3561',
        Common_Name: 'choline biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Choline-Biosynthesis|PWY-3561',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Choline Biosynthesis|choline biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7594',
        Common_Name: '&gamma;-linolenate biosynthesis III (cyanobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Gamma-linolenate-Biosynthesis|PWY-7594',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|&gamma;-Linolenate Biosynthesis|&gamma;-linolenate biosynthesis III (cyanobacteria)',
    },
    {
        BioCyc_ID: 'PWY-7856',
        Common_Name: 'heme <i>a</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|HEME-SYN|PWY-7856',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Heme Biosynthesis|heme <i>a</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7856',
        Common_Name: 'heme <i>a</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|HEME-SYN|PWY-7856',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Heme Biosynthesis|heme <i>a</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8118',
        Common_Name: 'patellamide A and C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PWY-8118',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|patellamide A and C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5915',
        Common_Name: 'phycoerythrobilin biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Phycobilin-Biosynthesis|PWY-5915',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Phycobilin Biosynthesis|phycoerythrobilin biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7142',
        Common_Name: 'cyanide detoxification II',
        MetaCyc_hierarchy_IDs: 'Detoxification|Cyanide-Detoxification|PWY-7142',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Cyanide Detoxification|cyanide detoxification II',
    },
    {
        BioCyc_ID: 'PWY-8225',
        Common_Name: 'GDP-<i>N</i>-acetyl-&alpha;-D-perosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-8225',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-<i>N</i>-acetyl-&alpha;-D-perosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5851',
        Common_Name: 'demethylmenaquinol-9 biosynthesis',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|PWY-5851',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|demethylmenaquinol-9 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6643',
        Common_Name: 'coenzyme M biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Coenzyme-M-Biosynthesis|PWY-6643',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Coenzyme M Biosynthesis|coenzyme M biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8023',
        Common_Name: 'salmochelin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-8023',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|salmochelin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5305',
        Common_Name: 'bixin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5305',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|bixin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5305',
        Common_Name: 'bixin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5305',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|bixin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5305',
        Common_Name: 'bixin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5305',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|bixin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6710',
        Common_Name: 'poly-hydroxy fatty acids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Hydroxy-Fatty-Acids-Biosynthesis|PWY-6710',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Hydroxylated Fatty Acid Biosynthesis|poly-hydroxy fatty acids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5062',
        Common_Name: 'superpathway of nicotinate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-5062',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|superpathway of nicotinate degradation',
    },
    {
        BioCyc_ID: 'PWY-5062',
        Common_Name: 'superpathway of nicotinate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5062',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of nicotinate degradation',
    },
    {
        BioCyc_ID: 'PWY-5306',
        Common_Name: 'superpathway of thiosulfate metabolism (<i>Desulfovibrio sulfodismutans</i>)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-5306',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|superpathway of thiosulfate metabolism (<i>Desulfovibrio sulfodismutans</i>)',
    },
    {
        BioCyc_ID: 'PWY-5306',
        Common_Name: 'superpathway of thiosulfate metabolism (<i>Desulfovibrio sulfodismutans</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfite-Oxidation|PWY-5306',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfite Oxidation|superpathway of thiosulfate metabolism (<i>Desulfovibrio sulfodismutans</i>)',
    },
    {
        BioCyc_ID: 'PWY-5306',
        Common_Name: 'superpathway of thiosulfate metabolism (<i>Desulfovibrio sulfodismutans</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-Disproportionation|PWY-5306',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Disproportionation|superpathway of thiosulfate metabolism (<i>Desulfovibrio sulfodismutans</i>)',
    },
    {
        BioCyc_ID: 'PWY-5306',
        Common_Name: 'superpathway of thiosulfate metabolism (<i>Desulfovibrio sulfodismutans</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5306',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of thiosulfate metabolism (<i>Desulfovibrio sulfodismutans</i>)',
    },
    {
        BioCyc_ID: 'PWY-7555',
        Common_Name: '&alpha;-cyclopiazonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7555',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|&alpha;-cyclopiazonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8091',
        Common_Name: 'D-apionate degradation I (xylose isomerase family decarboxylase)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|D-Apionate-Degradation|PWY-8091',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|D-Apionate Degradation|D-apionate degradation I (xylose isomerase family decarboxylase)',
    },
    {
        BioCyc_ID: 'PWY-3542',
        Common_Name: 'choline biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Choline-Biosynthesis|PWY-3542',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Choline Biosynthesis|choline biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6000',
        Common_Name: '&gamma;-linolenate biosynthesis II (animals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Gamma-linolenate-Biosynthesis|PWY-6000',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|&gamma;-Linolenate Biosynthesis|&gamma;-linolenate biosynthesis II (animals)',
    },
    {
        BioCyc_ID: 'PWY-6983',
        Common_Name: '<i>threo</i>-tetrahydrobiopterin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Tetrahydropterin-Biosynthesis|PWY-6983',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Tetrahydropterin Biosynthesis|<i>threo</i>-tetrahydrobiopterin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1527',
        Common_Name: 'curcumin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PWY0-1527',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|curcumin degradation',
    },
    {
        BioCyc_ID: 'ASPSYNII-PWY',
        Common_Name: 'cyanide detoxification I',
        MetaCyc_hierarchy_IDs: 'Detoxification|Cyanide-Detoxification|ASPSYNII-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Cyanide Detoxification|cyanide detoxification I',
    },
    {
        BioCyc_ID: 'PWY-5115',
        Common_Name: 'GDP-L-galactose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-5115',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-L-galactose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7997',
        Common_Name: 'demethylmenaquinol-4 biosynthesis',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|PWY-7997',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|demethylmenaquinol-4 biosynthesis',
    },
    {
        BioCyc_ID: 'P261-PWY',
        Common_Name: 'coenzyme M biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|C1-Carrier-Biosynthesis|Coenzyme-M-Biosynthesis|P261-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Single Carbon Carrier Biosynthesis|Coenzyme M Biosynthesis|coenzyme M biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6574',
        Common_Name: 'achromobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6574',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|achromobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6681',
        Common_Name: 'neurosporaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-6681',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|neurosporaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6681',
        Common_Name: 'neurosporaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-6681',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|neurosporaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6681',
        Common_Name: 'neurosporaxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-6681',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|neurosporaxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6433',
        Common_Name: 'hydroxylated fatty acid biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Hydroxy-Fatty-Acids-Biosynthesis|PWY-6433',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Hydroxylated Fatty Acid Biosynthesis|hydroxylated fatty acid biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-6781',
        Common_Name: 'chlorogenic acid degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6781',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|chlorogenic acid degradation',
    },
    {
        BioCyc_ID: 'PWY-5424',
        Common_Name: 'superpathway of oleoresin turpentine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5424',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of oleoresin turpentine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5424',
        Common_Name: 'superpathway of oleoresin turpentine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|PWY-5424',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|superpathway of oleoresin turpentine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8389',
        Common_Name: 'penigequinolone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-8389',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|penigequinolone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8090',
        Common_Name: 'D-apionate degradation II (RLP decarboxylase)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|D-Apionate-Degradation|PWY-8090',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|D-Apionate Degradation|D-apionate degradation II (RLP decarboxylase)',
    },
    {
        BioCyc_ID: 'PWY-3385',
        Common_Name: 'choline biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Choline-Biosynthesis|PWY-3385',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Choline Biosynthesis|choline biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5998',
        Common_Name: '&gamma;-linolenate biosynthesis I (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Gamma-linolenate-Biosynthesis|PWY-5998',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|&gamma;-Linolenate Biosynthesis|&gamma;-linolenate biosynthesis I (plants)',
    },
    {
        BioCyc_ID: 'PWY-5663',
        Common_Name: '<i>erythro</i>-tetrahydrobiopterin biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Tetrahydropterin-Biosynthesis|PWY-5663',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Tetrahydropterin Biosynthesis|<i>erythro</i>-tetrahydrobiopterin biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8022',
        Common_Name: 'salmochelin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PWY-8022',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|salmochelin degradation',
    },
    {
        BioCyc_ID: 'DETOX1-PWY',
        Common_Name: 'superoxide radicals degradation',
        MetaCyc_hierarchy_IDs: 'Detoxification|REACTIVE-OXYGEN-SPECIES-DEGRADATION|DETOX1-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Reactive Oxygen Species Degradation|superoxide radicals degradation',
    },
    {
        BioCyc_ID: 'PWY-5738',
        Common_Name: 'GDP-6-deoxy-D-talose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-5738',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-6-deoxy-D-talose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6978',
        Common_Name: 'plastoquinol-9 biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Plastoquinone-Biosynthesis|PWY-6978',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Plastoquinol Biosynthesis|plastoquinol-9 biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY185E-1',
        Common_Name: 'mycobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY185E-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|mycobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5398',
        Common_Name: 'crocetin esters biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5398',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|crocetin esters biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5398',
        Common_Name: 'crocetin esters biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5398',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|crocetin esters biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5398',
        Common_Name: 'crocetin esters biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|C40-Carotenoids-Biosynthesis|APOCAROTENOID-SYN|PWY-5398',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|C<sub>40</sub> Carotenoid Biosynthesis|Apocarotenoid Biosynthesis|crocetin esters biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7618',
        Common_Name: 'ricinoleate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Hydroxy-Fatty-Acids-Biosynthesis|PWY-7618',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Hydroxylated Fatty Acid Biosynthesis|ricinoleate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7618',
        Common_Name: 'ricinoleate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PWY-7618',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|ricinoleate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7557',
        Common_Name: '(-)-dehydrodiconiferyl alcohol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7557',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|(-)-dehydrodiconiferyl alcohol degradation',
    },
    {
        BioCyc_ID: 'PWY-5634',
        Common_Name: 'superpathway of penicillin, cephalosporin and cephamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|PWY-5634',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|superpathway of penicillin, cephalosporin and cephamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5634',
        Common_Name: 'superpathway of penicillin, cephalosporin and cephamycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5634',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of penicillin, cephalosporin and cephamycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5666',
        Common_Name: '&alpha;-solanine/&alpha;-chaconine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-5666',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|&alpha;-solanine/&alpha;-chaconine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8092',
        Common_Name: 'D-apionate degradation III (RLP transcarboxylase/hydrolase)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|D-Apionate-Degradation|PWY-8092',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|D-Apionate Degradation|D-apionate degradation III (RLP transcarboxylase/hydrolase)',
    },
    {
        BioCyc_ID: 'BSUBPOLYAMSYN-PWY',
        Common_Name: 'spermidine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Spermidine-Biosynthesis|BSUBPOLYAMSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Spermidine Biosynthesis|spermidine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY0-1507',
        Common_Name: 'biotin biosynthesis from 8-amino-7-oxononanoate I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|BIOTIN-SYN|PWY0-1507',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Biotin Biosynthesis|biotin biosynthesis from 8-amino-7-oxononanoate I',
    },
    {
        BioCyc_ID: 'PWY-5526',
        Common_Name: 'bacteriochlorophyll <i>a</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|PWY-5526',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>a</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5526',
        Common_Name: 'bacteriochlorophyll <i>a</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|PWY-5526',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>a</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8446',
        Common_Name: 'carminate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|PWY-8446',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|carminate degradation',
    },
    {
        BioCyc_ID: 'PWY-7214',
        Common_Name: 'baicalein degradation (hydrogen peroxide detoxification)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Phenolic-Compounds-Degradation|PHENYLPROPANOID-DERIVATIVE-DEG|PWY-7214',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Phenolic Compound Degradation|Phenylpropanoid Derivative Degradation|baicalein degradation (hydrogen peroxide detoxification)',
    },
    {
        BioCyc_ID: 'PWY-7214',
        Common_Name: 'baicalein degradation (hydrogen peroxide detoxification)',
        MetaCyc_hierarchy_IDs: 'Detoxification|REACTIVE-OXYGEN-SPECIES-DEGRADATION|PWY-7214',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Reactive Oxygen Species Degradation|baicalein degradation (hydrogen peroxide detoxification)',
    },
    {
        BioCyc_ID: 'PWY-6',
        Common_Name: 'GDP-L-fucose biosynthesis II (from L-fucose)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-6',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-L-fucose biosynthesis II (from L-fucose)',
    },
    {
        BioCyc_ID: 'PWY-5864',
        Common_Name: 'superpathway of plastoquinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Plastoquinone-Biosynthesis|PWY-5864',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Plastoquinol Biosynthesis|superpathway of plastoquinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5864',
        Common_Name: 'superpathway of plastoquinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5864',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of plastoquinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-761',
        Common_Name: 'rhizobactin 1021 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-761',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|rhizobactin 1021 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7585',
        Common_Name: 'docosahexaenoate biosynthesis II (bacteria)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Docosahexaenoate-Biosynthesis|PWY-7585',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Docosahexaenoate Biosynthesis|docosahexaenoate biosynthesis II (bacteria)',
    },
    {
        BioCyc_ID: '4TOLCARBDEG-PWY',
        Common_Name: '4-toluenecarboxylate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|4TOLCARBDEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|4-toluenecarboxylate degradation',
    },
    {
        BioCyc_ID: 'PWY-6269',
        Common_Name: 'superpathway of adenosylcobalamin salvage from cobinamide II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobalamin-Biosynthesis|Cobamide-Salvage|B12-Salvage-From-Cobinamide|PWY-6269',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Cobamide Biosynthesis|Cobinamide Salvage|Adenosylcobalamin Salvage from Cobinamide|superpathway of adenosylcobalamin salvage from cobinamide II',
    },
    {
        BioCyc_ID: 'PWY-6269',
        Common_Name: 'superpathway of adenosylcobalamin salvage from cobinamide II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6269',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of adenosylcobalamin salvage from cobinamide II',
    },
    {
        BioCyc_ID: 'PWY-7603',
        Common_Name: 'stephacidin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7603',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|stephacidin A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5794',
        Common_Name: 'malonate degradation I (biotin-independent)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Malonate-Degradation|PWY-5794',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Malonate Degradation|malonate degradation I (biotin-independent)',
    },
    {
        BioCyc_ID: 'PWY-6834',
        Common_Name: 'spermidine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Spermidine-Biosynthesis|PWY-6834',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Spermidine Biosynthesis|spermidine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8128',
        Common_Name: 'biotin biosynthesis from 8-amino-7-oxononanoate III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|BIOTIN-SYN|PWY-8128',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Biotin Biosynthesis|biotin biosynthesis from 8-amino-7-oxononanoate III',
    },
    {
        BioCyc_ID: 'PWY-7760',
        Common_Name: 'bacteriochlorophyll <i>e</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|PWY-7760',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>e</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7760',
        Common_Name: 'bacteriochlorophyll <i>e</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|PWY-7760',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>e</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2261',
        Common_Name: 'ascorbate glutathione cycle',
        MetaCyc_hierarchy_IDs: 'Detoxification|REACTIVE-OXYGEN-SPECIES-DEGRADATION|PWY-2261',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Reactive Oxygen Species Degradation|ascorbate glutathione cycle',
    },
    {
        BioCyc_ID: 'PWY-7613',
        Common_Name: 'GDP-6-deoxy-D-<i>manno</i>-heptose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-7613',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-6-deoxy-D-<i>manno</i>-heptose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1581',
        Common_Name: 'plastoquinol-9 biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Plastoquinone-Biosynthesis|PWY-1581',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Plastoquinol Biosynthesis|plastoquinol-9 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6289',
        Common_Name: 'petrobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6289',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|petrobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7053',
        Common_Name: 'docosahexaenoate biosynthesis I (lower eukaryotes)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Docosahexaenoate-Biosynthesis|PWY-7053',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Docosahexaenoate Biosynthesis|docosahexaenoate biosynthesis I (lower eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-5273',
        Common_Name: '<i>p</i>-cumate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-5273',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>p</i>-cumate degradation',
    },
    {
        BioCyc_ID: 'PWY-5273',
        Common_Name: '<i>p</i>-cumate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5273',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|<i>p</i>-cumate degradation',
    },
    {
        BioCyc_ID: 'PWY-6629',
        Common_Name: 'superpathway of L-tryptophan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6629',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-tryptophan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6629',
        Common_Name: 'superpathway of L-tryptophan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|TRYPTOPHAN-BIOSYNTHESIS|PWY-6629',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-tryptophan Biosynthesis|superpathway of L-tryptophan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6456',
        Common_Name: 'serinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-6456',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|serinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY18C3-4',
        Common_Name: 'solasodine glycosylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY18C3-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|solasodine glycosylation',
    },
    {
        BioCyc_ID: 'PWY-6559',
        Common_Name: 'spermidine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Spermidine-Biosynthesis|PWY-6559',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Spermidine Biosynthesis|spermidine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6060',
        Common_Name: 'malonate degradation II (biotin-dependent)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Malonate-Degradation|PWY-6060',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Malonate Degradation|malonate degradation II (biotin-dependent)',
    },
    {
        BioCyc_ID: 'PWY-7380',
        Common_Name: 'biotin biosynthesis from 8-amino-7-oxononanoate II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|BIOTIN-SYN|PWY-7380',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Biotin Biosynthesis|biotin biosynthesis from 8-amino-7-oxononanoate II',
    },
    {
        BioCyc_ID: 'PWY-7759',
        Common_Name: 'bacteriochlorophyll <i>c</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|PWY-7759',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>c</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7759',
        Common_Name: 'bacteriochlorophyll <i>c</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|PWY-7759',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>c</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6504',
        Common_Name: 'anthranilate degradation IV (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|2-Aminobenzoate-Degradation|PWY-6504',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2-Aminobenzoate Degradation|anthranilate degradation IV (aerobic)',
    },
    {
        BioCyc_ID: 'GDPRHAMSYN-PWY',
        Common_Name: 'GDP-D-rhamnose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|GDPRHAMSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-D-rhamnose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7986',
        Common_Name: 'anguibactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7986',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|anguibactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7727',
        Common_Name: 'docosahexaenoate biosynthesis IV (4-desaturase, mammals)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Docosahexaenoate-Biosynthesis|PWY-7727',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Docosahexaenoate Biosynthesis|docosahexaenoate biosynthesis IV (4-desaturase, mammals)',
    },
    {
        BioCyc_ID: 'PWY-6954',
        Common_Name: 'superpathway of aromatic compound degradation via 2-hydroxypentadienoate',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6954',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|superpathway of aromatic compound degradation via 2-hydroxypentadienoate',
    },
    {
        BioCyc_ID: 'PWY-6954',
        Common_Name: 'superpathway of aromatic compound degradation via 2-hydroxypentadienoate',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6954',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of aromatic compound degradation via 2-hydroxypentadienoate',
    },
    {
        BioCyc_ID: 'PWY-7124',
        Common_Name: 'ethene biosynthesis V (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Ethylene-Biosynthesis|PWY-7124',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Ethene Biosynthesis|ethene biosynthesis V (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7124',
        Common_Name: 'ethene biosynthesis V (engineered)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7124',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|ethene biosynthesis V (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7297',
        Common_Name: 'octopamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-7297',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|octopamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5883',
        Common_Name: 'ephedrine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-5883',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|ephedrine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3722',
        Common_Name: 'glycine betaine biosynthesis II (Gram-positive bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Betaine-Biosynthesis|PWY-3722',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Betaine Biosynthesis|glycine betaine biosynthesis II (Gram-positive bacteria)',
    },
    {
        BioCyc_ID: 'PWY-3722',
        Common_Name: 'glycine betaine biosynthesis II (Gram-positive bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Betaine-Biosynthesis|PWY-3722',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Betaine Biosynthesis|glycine betaine biosynthesis II (Gram-positive bacteria)',
    },
    {
        BioCyc_ID: 'PWY0-43',
        Common_Name: 'conversion of succinate to propanoate',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUCC-DEG|PWY0-43',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Succinate Degradation|conversion of succinate to propanoate',
    },
    {
        BioCyc_ID: 'PWY-7639',
        Common_Name: 'bis(guanylyl molybdenum cofactor) biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-7639',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|bis(guanylyl molybdenum cofactor) biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8127',
        Common_Name: 'chlorophyll <i>b</i><sub>2</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|PWY-8127',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|chlorophyll <i>b</i><sub>2</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8127',
        Common_Name: 'chlorophyll <i>b</i><sub>2</sub> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|PWY-8127',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|chlorophyll <i>b</i><sub>2</sub> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6079',
        Common_Name: 'anthranilate degradation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|2-Aminobenzoate-Degradation|PWY-6079',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2-Aminobenzoate Degradation|anthranilate degradation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-5661',
        Common_Name: 'GDP-&alpha;-D-glucose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-5661',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-&alpha;-D-glucose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6376',
        Common_Name: 'desferrioxamine B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6376',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|desferrioxamine B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7606',
        Common_Name: 'docosahexaenoate biosynthesis III (6-desaturase, mammals)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Docosahexaenoate-Biosynthesis|PWY-7606',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Docosahexaenoate Biosynthesis|docosahexaenoate biosynthesis III (6-desaturase, mammals)',
    },
    {
        BioCyc_ID: 'PWY-7795',
        Common_Name: 'terephthalate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7795',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|terephthalate degradation',
    },
    {
        BioCyc_ID: 'PWY-7389',
        Common_Name: 'superpathway of anaerobic energy metabolism (invertebrates)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|PWY-7389',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|superpathway of anaerobic energy metabolism (invertebrates)',
    },
    {
        BioCyc_ID: 'PWY-7389',
        Common_Name: 'superpathway of anaerobic energy metabolism (invertebrates)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7389',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of anaerobic energy metabolism (invertebrates)',
    },
    {
        BioCyc_ID: 'PWY-8292',
        Common_Name: 'trimethylamine <i>N</i>-oxide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-8292',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|trimethylamine <i>N</i>-oxide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7607',
        Common_Name: 'meleagrin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7607',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|meleagrin biosynthesis',
    },
    {
        BioCyc_ID: 'P541-PWY',
        Common_Name: 'glycine betaine biosynthesis IV (from glycine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Betaine-Biosynthesis|P541-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Betaine Biosynthesis|glycine betaine biosynthesis IV (from glycine)',
    },
    {
        BioCyc_ID: 'P541-PWY',
        Common_Name: 'glycine betaine biosynthesis IV (from glycine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Betaine-Biosynthesis|P541-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Betaine Biosynthesis|glycine betaine biosynthesis IV (from glycine)',
    },
    {
        BioCyc_ID: 'PWY-6598',
        Common_Name: 'sciadonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-6598',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|sciadonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8164',
        Common_Name: '<i>bis</i>(guanylyl molybdopterin) cofactor sulfurylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-8164',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|<i>bis</i>(guanylyl molybdopterin) cofactor sulfurylation',
    },
    {
        BioCyc_ID: 'PWY-4841',
        Common_Name: 'UDP-&alpha;-D-glucuronate biosynthesis (from <i>myo</i>-inositol)',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|PWY-4841',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|UDP-&alpha;-D-glucuronate biosynthesis (from <i>myo</i>-inositol)',
    },
    {
        BioCyc_ID: 'PWY-4841',
        Common_Name: 'UDP-&alpha;-D-glucuronate biosynthesis (from <i>myo</i>-inositol)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-4841',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-&alpha;-D-glucuronate biosynthesis (from <i>myo</i>-inositol)',
    },
    {
        BioCyc_ID: 'PWY-7758',
        Common_Name: 'bacteriochlorophyll <i>d</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|PWY-7758',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>d</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7758',
        Common_Name: 'bacteriochlorophyll <i>d</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|PWY-7758',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>d</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6077',
        Common_Name: 'anthranilate degradation II (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|2-Aminobenzoate-Degradation|PWY-6077',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2-Aminobenzoate Degradation|anthranilate degradation II (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-5740',
        Common_Name: 'GDP-L-colitose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-5740',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-L-colitose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7989',
        Common_Name: 'acinetoferrin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7989',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|acinetoferrin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5368',
        Common_Name: 'dimorphecolate biosynthesis',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Conjugated-Fatty-Acid-Biosynthesis|PWY-5368',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Conjugated Fatty Acid Biosynthesis|dimorphecolate biosynthesis',
    },
    {
        BioCyc_ID: 'METHYLGALLATE-DEGRADATION-PWY',
        Common_Name: 'methylgallate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|METHYLGALLATE-DEGRADATION-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|methylgallate degradation',
    },
    {
        BioCyc_ID: 'PWY-7609',
        Common_Name: 'superpathway of roquefortine, meleagrin and neoxaline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7609',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|superpathway of roquefortine, meleagrin and neoxaline biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7609',
        Common_Name: 'superpathway of roquefortine, meleagrin and neoxaline biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7609',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of roquefortine, meleagrin and neoxaline biosynthesis',
    },
    {
        BioCyc_ID: 'ARGSPECAT-PWY',
        Common_Name: 'spermine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|ARGSPECAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|spermine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7138',
        Common_Name: 'noscapine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7138',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|noscapine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY1F-353',
        Common_Name: 'glycine betaine biosynthesis III (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Betaine-Biosynthesis|PWY1F-353',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Betaine Biosynthesis|glycine betaine biosynthesis III (plants)',
    },
    {
        BioCyc_ID: 'PWY1F-353',
        Common_Name: 'glycine betaine biosynthesis III (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Betaine-Biosynthesis|PWY1F-353',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Betaine Biosynthesis|glycine betaine biosynthesis III (plants)',
    },
    {
        BioCyc_ID: 'PWY-7590',
        Common_Name: '(7Z,10Z,13Z)-hexadecatrienoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-7590',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|(7Z,10Z,13Z)-hexadecatrienoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8168',
        Common_Name: '<i>bis</i>(guanylyl tungstenpterin) cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-8168',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|<i>bis</i>(guanylyl tungstenpterin) cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7762',
        Common_Name: 'bacteriochlorophyll <i>b</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|Chlorophyll-Biosynthesis|PWY-7762',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>b</i> biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7762',
        Common_Name: 'bacteriochlorophyll <i>b</i> biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|Chlorophyll-Biosynthesis|PWY-7762',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|Chlorophyll Biosynthesis|bacteriochlorophyll <i>b</i> biosynthesis',
    },
    {
        BioCyc_ID: '2AMINOBENZDEG-PWY',
        Common_Name: 'anthranilate degradation III (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|2-Aminobenzoate-Degradation|2AMINOBENZDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2-Aminobenzoate Degradation|anthranilate degradation III (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-6363',
        Common_Name: 'D-<i>myo</i>-inositol (1,4,5)-trisphosphate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|PWY-6363',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|D-<i>myo</i>-inositol (1,4,5)-trisphosphate degradation',
    },
    {
        BioCyc_ID: 'PWY-8259',
        Common_Name: 'arsenic detoxification (plants)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|PWY-8259',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|arsenic detoxification (plants)',
    },
    {
        BioCyc_ID: 'PWY-66',
        Common_Name: 'GDP-L-fucose biosynthesis I (from GDP-D-mannose)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-66',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|GDP-L-fucose biosynthesis I (from GDP-D-mannose)',
    },
    {
        BioCyc_ID: 'PWY-6381',
        Common_Name: 'bisucaberin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|bisucaberin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5375',
        Common_Name: '&alpha;-eleostearate biosynthesis',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Conjugated-Fatty-Acid-Biosynthesis|PWY-5375',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Conjugated Fatty Acid Biosynthesis|&alpha;-eleostearate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6182',
        Common_Name: 'superpathway of salicylate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6182',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|superpathway of salicylate degradation',
    },
    {
        BioCyc_ID: 'PWY-6182',
        Common_Name: 'superpathway of salicylate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6182',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of salicylate degradation',
    },
    {
        BioCyc_ID: 'PWY-8240',
        Common_Name: 'superpathway of sterol biosynthesis in diatoms',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|PWY-8240',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|superpathway of sterol biosynthesis in diatoms',
    },
    {
        BioCyc_ID: 'PWY-8240',
        Common_Name: 'superpathway of sterol biosynthesis in diatoms',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8240',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sterol biosynthesis in diatoms',
    },
    {
        BioCyc_ID: 'PWY-8240',
        Common_Name: 'superpathway of sterol biosynthesis in diatoms',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-8240',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|superpathway of sterol biosynthesis in diatoms',
    },
    {
        BioCyc_ID: 'TRYPANOSYN-PWY',
        Common_Name: 'trypanothione biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|TRYPANOSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|trypanothione biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7612',
        Common_Name: 'chaetoglobosin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7612',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|chaetoglobosin A biosynthesis',
    },
    {
        BioCyc_ID: 'BETSYN-PWY',
        Common_Name: 'glycine betaine biosynthesis I (Gram-negative bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Betaine-Biosynthesis|BETSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Betaine Biosynthesis|glycine betaine biosynthesis I (Gram-negative bacteria)',
    },
    {
        BioCyc_ID: 'BETSYN-PWY',
        Common_Name: 'glycine betaine biosynthesis I (Gram-negative bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Betaine-Biosynthesis|BETSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Betaine Biosynthesis|glycine betaine biosynthesis I (Gram-negative bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7619',
        Common_Name: 'juniperonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-7619',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|juniperonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5963',
        Common_Name: 'molybdenum cofactor sulfulylation (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-5963',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|molybdenum cofactor sulfulylation (eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-7035',
        Common_Name: '(9<i>Z</i>)-tricosene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Olefins-Biosynthesis|Alkenes-Biosynthesis|PWY-7035',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Olefin Biosynthesis|Alkene Biosynthesis|(9<i>Z</i>)-tricosene biosynthesis',
    },
    {
        BioCyc_ID: 'P562-PWY',
        Common_Name: '<I>myo</I>-inositol degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|P562-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|<I>myo</I>-inositol degradation I',
    },
    {
        BioCyc_ID: 'PWY-4202',
        Common_Name: 'arsenic detoxification (mammals)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|PWY-4202',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|arsenic detoxification (mammals)',
    },
    {
        BioCyc_ID: 'PWY-7723',
        Common_Name: 'bacterial bioluminescence',
        MetaCyc_hierarchy_IDs: 'Bioluminescence|PWY-7723',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Bioluminescence|bacterial bioluminescence',
    },
    {
        BioCyc_ID: 'PWY-8008',
        Common_Name: 'staphyloferrin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-8008',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|staphyloferrin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5374',
        Common_Name: 'punicate biosynthesis',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Conjugated-Fatty-Acid-Biosynthesis|PWY-5374',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Conjugated Fatty Acid Biosynthesis|punicate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7006',
        Common_Name: '4-amino-3-hydroxybenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7006',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|4-amino-3-hydroxybenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY-7526',
        Common_Name: 'fumitremorgin A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7526',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|fumitremorgin A biosynthesis',
    },
    {
        BioCyc_ID: 'POLYAMSYN-PWY',
        Common_Name: 'superpathway of polyamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|POLYAMSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|superpathway of polyamine biosynthesis I',
    },
    {
        BioCyc_ID: 'POLYAMSYN-PWY',
        Common_Name: 'superpathway of polyamine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|POLYAMSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of polyamine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY0-781',
        Common_Name: 'aspartate superpathway',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-781',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|aspartate superpathway',
    },
    {
        BioCyc_ID: 'PWY-40',
        Common_Name: 'putrescine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Putrescine-Biosynthesis|PWY-40',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Putrescine Biosynthesis|putrescine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6013',
        Common_Name: 'crepenynate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Acetlylenic-Fatty-Acid-Biosynthesis|PWY-6013',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Fatty Acid Containing acetylenic bond Biosynthesis|crepenynate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6013',
        Common_Name: 'crepenynate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-6013',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|crepenynate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6823',
        Common_Name: 'molybdopterin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-6823',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|molybdopterin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5380',
        Common_Name: 'A series fagopyritols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|PWY-5380',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|A series fagopyritols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5380',
        Common_Name: 'A series fagopyritols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|PWY-5380',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|A series fagopyritols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7029',
        Common_Name: 'terminal olefins biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Olefins-Biosynthesis|Alkenes-Biosynthesis|PWY-7029',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Olefin Biosynthesis|Alkene Biosynthesis|terminal olefins biosynthesis II',
    },
    {
        BioCyc_ID: 'P482-PWY',
        Common_Name: 'arsonoacetate degradation',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|P482-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|arsonoacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-5697',
        Common_Name: 'allantoin degradation to ureidoglycolate I (urea producing)',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Allantoin-degradation|PWY-5697',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Allantoin Degradation|allantoin degradation to ureidoglycolate I (urea producing)',
    },
    {
        BioCyc_ID: 'PWY-7914',
        Common_Name: 'coral bioluminescence',
        MetaCyc_hierarchy_IDs: 'Bioluminescence|PWY-7914',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Bioluminescence|coral bioluminescence',
    },
    {
        BioCyc_ID: 'PWY-6021',
        Common_Name: 'nitrilotriacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-6021',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|nitrilotriacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-6409',
        Common_Name: 'pyoverdine I biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6409',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|pyoverdine I biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5373',
        Common_Name: 'calendate biosynthesis',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Conjugated-Fatty-Acid-Biosynthesis|PWY-5373',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Conjugated Fatty Acid Biosynthesis|calendate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7705',
        Common_Name: 'quinolinone B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7705',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|quinolinone B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7982',
        Common_Name: 'pinoresinol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7982',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|pinoresinol degradation',
    },
    {
        BioCyc_ID: 'PWY66-409',
        Common_Name: 'superpathway of purine nucleotide salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotides-Salvage|PWY66-409',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide Salvage|superpathway of purine nucleotide salvage',
    },
    {
        BioCyc_ID: 'PWY66-409',
        Common_Name: 'superpathway of purine nucleotide salvage',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY66-409',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of purine nucleotide salvage',
    },
    {
        BioCyc_ID: 'PWY-6173',
        Common_Name: 'histamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-6173',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|histamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5698',
        Common_Name: 'allantoin degradation to ureidoglycolate II (ammonia producing)',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Allantoin-degradation|PWY-5698',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Allantoin Degradation|allantoin degradation to ureidoglycolate II (ammonia producing)',
    },
    {
        BioCyc_ID: 'PWY-46',
        Common_Name: 'putrescine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Putrescine-Biosynthesis|PWY-46',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Putrescine Biosynthesis|putrescine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-7152',
        Common_Name: 'pinolenate and coniferonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-7152',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|pinolenate and coniferonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8163',
        Common_Name: 'cytidylyl copper-molybdenum cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-8163',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|cytidylyl copper-molybdenum cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7026',
        Common_Name: 'terminal olefins biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Olefins-Biosynthesis|Alkenes-Biosynthesis|PWY-7026',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Olefin Biosynthesis|Alkene Biosynthesis|terminal olefins biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7241',
        Common_Name: '<I>myo</I>-inositol degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|PWY-7241',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|<I>myo</I>-inositol degradation II',
    },
    {
        BioCyc_ID: 'PWY-4621',
        Common_Name: 'arsenic detoxification (yeast)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|PWY-4621',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|arsenic detoxification (yeast)',
    },
    {
        BioCyc_ID: 'PWY-7937',
        Common_Name: 'fungal bioluminescence',
        MetaCyc_hierarchy_IDs: 'Bioluminescence|PWY-7937',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Bioluminescence|fungal bioluminescence',
    },
    {
        BioCyc_ID: 'PWY-7401',
        Common_Name: 'crotonate fermentation (to acetate and cyclohexane carboxylate)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-7401',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|crotonate fermentation (to acetate and cyclohexane carboxylate)',
    },
    {
        BioCyc_ID: 'PWY-7401',
        Common_Name: 'crotonate fermentation (to acetate and cyclohexane carboxylate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|PWY-7401',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|crotonate fermentation (to acetate and cyclohexane carboxylate)',
    },
    {
        BioCyc_ID: 'PWY-7401',
        Common_Name: 'crotonate fermentation (to acetate and cyclohexane carboxylate)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7401',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|crotonate fermentation (to acetate and cyclohexane carboxylate)',
    },
    {
        BioCyc_ID: 'PWY-8383',
        Common_Name: 'myxochelin A and B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-8383',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|myxochelin A and B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7725',
        Common_Name: 'arachidonate biosynthesis V (8-detaturase, mammals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Arachidonate-Biosynthesis|PWY-7725',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Arachidonate Biosynthesis|arachidonate biosynthesis V (8-detaturase, mammals)',
    },
    {
        BioCyc_ID: 'PWY-7549',
        Common_Name: 'asperlicin E biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-7549',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|asperlicin E biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5362',
        Common_Name: 'sapienate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PWY-5362',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|sapienate biosynthesis',
    },
    {
        BioCyc_ID: 'P345-PWY',
        Common_Name: 'aldoxime degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|P345-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|aldoxime degradation',
    },
    {
        BioCyc_ID: 'FASYN-INITIAL-PWY',
        Common_Name: 'superpathway of fatty acid biosynthesis initiation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Fatty-Acid-Biosyn-Initiation|FASYN-INITIAL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Fatty Acid Biosynthesis Initiation|superpathway of fatty acid biosynthesis initiation',
    },
    {
        BioCyc_ID: 'FASYN-INITIAL-PWY',
        Common_Name: 'superpathway of fatty acid biosynthesis initiation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|FASYN-INITIAL-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of fatty acid biosynthesis initiation',
    },
    {
        BioCyc_ID: 'PWY-6565',
        Common_Name: 'superpathway of polyamine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-6565',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|superpathway of polyamine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6565',
        Common_Name: 'superpathway of polyamine biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6565',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of polyamine biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-43',
        Common_Name: 'putrescine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Putrescine-Biosynthesis|PWY-43',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Putrescine Biosynthesis|putrescine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7728',
        Common_Name: '(4Z,7Z,10Z,13Z,16Z)-docosa-4,7,10,13,16-pentaenoate biosynthesis II (4-desaturase)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-7728',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|(4Z,7Z,10Z,13Z,16Z)-docosa-4,7,10,13,16-pentaenoate biosynthesis II (4-desaturase)',
    },
    {
        BioCyc_ID: 'PWY-8167',
        Common_Name: '<i>bis</i>(tungstenpterin) cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-8167',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|<i>bis</i>(tungstenpterin) cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5379',
        Common_Name: 'B series fagopyritols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|CYCLITOLS-DEG|PWY-5379',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Cyclitol Degradation|B series fagopyritols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5379',
        Common_Name: 'B series fagopyritols biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|PWY-5379',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|B series fagopyritols biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7869',
        Common_Name: '<i>cis</i>-alkene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Olefins-Biosynthesis|Alkenes-Biosynthesis|PWY-7869',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Olefin Biosynthesis|Alkene Biosynthesis|<i>cis</i>-alkene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8266',
        Common_Name: 'arsenite methylation (bacterial)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|PWY-8266',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|arsenite methylation (bacterial)',
    },
    {
        BioCyc_ID: 'PWY-6140',
        Common_Name: 'CMP-2-keto-3-deoxy-D-<i>glycero</i>-D-<i>galacto</i>-nononate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|PWY-6140',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-2-keto-3-deoxy-D-<i>glycero</i>-D-<i>galacto</i>-nononate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7913',
        Common_Name: 'firefly bioluminescence',
        MetaCyc_hierarchy_IDs: 'Bioluminescence|PWY-7913',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Bioluminescence|firefly bioluminescence',
    },
    {
        BioCyc_ID: 'ACETATEUTIL-PWY',
        Common_Name: 'superpathway of acetate utilization and formation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|ACETATEUTIL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|superpathway of acetate utilization and formation',
    },
    {
        BioCyc_ID: 'ACETATEUTIL-PWY',
        Common_Name: 'superpathway of acetate utilization and formation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ACETATEUTIL-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of acetate utilization and formation',
    },
    {
        BioCyc_ID: 'PWY-7577',
        Common_Name: 'ferrichrome biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7577',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|ferrichrome biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5353',
        Common_Name: 'arachidonate biosynthesis I (6-desaturase, lower eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Arachidonate-Biosynthesis|PWY-5353',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Arachidonate Biosynthesis|arachidonate biosynthesis I (6-desaturase, lower eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-8388',
        Common_Name: 'aspoquinolone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-8388',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|aspoquinolone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5361',
        Common_Name: '(5<i>Z</i>)-icosenoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PWY-5361',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|(5<i>Z</i>)-icosenoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6210',
        Common_Name: '2-aminophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6210',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2-aminophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-13',
        Common_Name: 'superpathway of tetrahydroxyxanthone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-13',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of tetrahydroxyxanthone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-13',
        Common_Name: 'superpathway of tetrahydroxyxanthone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|XANTHONE-SYN|PWY-13',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Xanthone Biosynthesis|superpathway of tetrahydroxyxanthone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8115',
        Common_Name: 'long-chain polyamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-8115',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|long-chain polyamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6111',
        Common_Name: 'mitochondrial L-carnitine shuttle',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|PWY-6111',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|mitochondrial L-carnitine shuttle',
    },
    {
        BioCyc_ID: 'PWY-5907',
        Common_Name: 'homospermidine biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Homospermidine-Biosynthesis|PWY-5907',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|homospermidine biosynthesis|homospermidine biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7595',
        Common_Name: 'stearidonate biosynthesis (cyanobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-7595',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|stearidonate biosynthesis (cyanobacteria)',
    },
    {
        BioCyc_ID: 'PWY-6476',
        Common_Name: 'cytidylyl molybdenum cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-6476',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|cytidylyl molybdenum cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5800',
        Common_Name: 'xylan biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|PLANT-CELL-STRUCTURE|SECONDARY-CELL-WALL|PWY-5800',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Plant Cell Structures|Secondary Cell Wall|xylan biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7719',
        Common_Name: 'CMP-diacetamido-8-epilegionaminic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|PWY-7719',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-diacetamido-8-epilegionaminic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7916',
        Common_Name: 'squid bioluminescence',
        MetaCyc_hierarchy_IDs: 'Bioluminescence|PWY-7916',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Bioluminescence|squid bioluminescence',
    },
    {
        BioCyc_ID: 'PWY-8085',
        Common_Name: 'picolinate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-8085',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|picolinate degradation',
    },
    {
        BioCyc_ID: 'PWY-5925',
        Common_Name: 'hydroxylated mugineic acid phytosiderophore biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-5925',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|hydroxylated mugineic acid phytosiderophore biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7601',
        Common_Name: 'arachidonate biosynthesis IV (8-detaturase, lower eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Arachidonate-Biosynthesis|PWY-7601',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Arachidonate Biosynthesis|arachidonate biosynthesis IV (8-detaturase, lower eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-5315',
        Common_Name: '1-methylpyrrolinium biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|PWY-5315',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|1-methylpyrrolinium biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5973',
        Common_Name: '<i>cis</i>-vaccenate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PWY-5973',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|<i>cis</i>-vaccenate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7010',
        Common_Name: '2-propylphenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7010',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2-propylphenol degradation',
    },
    {
        BioCyc_ID: 'PWY-5156',
        Common_Name: 'superpathway of fatty acid biosynthesis II (plant)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|PWY-5156',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|superpathway of fatty acid biosynthesis II (plant)',
    },
    {
        BioCyc_ID: 'PWY-5156',
        Common_Name: 'superpathway of fatty acid biosynthesis II (plant)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5156',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of fatty acid biosynthesis II (plant)',
    },
    {
        BioCyc_ID: 'ARG+POLYAMINE-SYN',
        Common_Name: 'superpathway of arginine and polyamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|ARG+POLYAMINE-SYN',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|superpathway of arginine and polyamine biosynthesis',
    },
    {
        BioCyc_ID: 'ARG+POLYAMINE-SYN',
        Common_Name: 'superpathway of arginine and polyamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ARG+POLYAMINE-SYN',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of arginine and polyamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7367',
        Common_Name: 'phosphatidylcholine resynthesis via glycerophosphocholine',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|PWY-7367',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|phosphatidylcholine resynthesis via glycerophosphocholine',
    },
    {
        BioCyc_ID: 'PWY-8149',
        Common_Name: 'homospermidine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Homospermidine-Biosynthesis|PWY-8149',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|homospermidine biosynthesis|homospermidine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7710',
        Common_Name: 'FeMo cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-7710',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|FeMo cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7726',
        Common_Name: '(4Z,7Z,10Z,13Z,16Z)-docosapentaenoate biosynthesis (6-desaturase)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|PWY-7726',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|(4Z,7Z,10Z,13Z,16Z)-docosapentaenoate biosynthesis (6-desaturase)',
    },
    {
        BioCyc_ID: 'PWY-6387',
        Common_Name: 'UDP-<i>N</i>-acetylmuramoyl-pentapeptide biosynthesis I (<i>meso</i>-diaminopimelate containing)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Acetylmuramoyl-Pentapeptide-Biosynthesis|PWY-6387',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|UDP-<i>N</i>-Acetylmuramoyl-Pentapeptide Biosynthesis|UDP-<i>N</i>-acetylmuramoyl-pentapeptide biosynthesis I (<i>meso</i>-diaminopimelate containing)',
    },
    {
        BioCyc_ID: 'PWY-7096',
        Common_Name: 'triclosan resistance',
        MetaCyc_hierarchy_IDs: 'Detoxification|Antibiotic-Resistance|PWY-7096',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Antibiotic Resistance|triclosan resistance',
    },
    {
        BioCyc_ID: 'PWY-6144',
        Common_Name: 'CMP-<i>N</i>-glycoloylneuraminate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|PWY-6144',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-<i>N</i>-glycoloylneuraminate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7912',
        Common_Name: 'dinoflagellate bioluminescence',
        MetaCyc_hierarchy_IDs: 'Bioluminescence|PWY-7912',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Bioluminescence|dinoflagellate bioluminescence',
    },
    {
        BioCyc_ID: 'PWY-301',
        Common_Name: 'cyclohexane-1-carboxylate degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|cyclohexane-1-carboxylate degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7984',
        Common_Name: 'acinetobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7984',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|acinetobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5301',
        Common_Name: 'ajmaline and sarpagine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-5301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|ajmaline and sarpagine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5301',
        Common_Name: 'ajmaline and sarpagine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TERPENOID-ALKALOIDS|PWY-5301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Terpenoid Alkaloid Biosynthesis|ajmaline and sarpagine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5301',
        Common_Name: 'ajmaline and sarpagine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TERPENOID-ALKALOIDS|PWY-5301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Terpenoid Alkaloid Biosynthesis|ajmaline and sarpagine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7583',
        Common_Name: 'arachidonate biosynthesis II (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Arachidonate-Biosynthesis|PWY-7583',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Arachidonate Biosynthesis|arachidonate biosynthesis II (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7663',
        Common_Name: 'gondoate biosynthesis (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PWY-7663',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|gondoate biosynthesis (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-8161',
        Common_Name: 'D-phenylglycine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-8161',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|D-phenylglycine degradation',
    },
    {
        BioCyc_ID: 'PWY-5312',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from pelargonidin 3-O-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5312',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|superpathway of anthocyanin biosynthesis (from pelargonidin 3-O-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5312',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from pelargonidin 3-O-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5312',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|superpathway of anthocyanin biosynthesis (from pelargonidin 3-O-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-5312',
        Common_Name: 'superpathway of anthocyanin biosynthesis (from pelargonidin 3-O-glucoside)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5312',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of anthocyanin biosynthesis (from pelargonidin 3-O-glucoside)',
    },
    {
        BioCyc_ID: 'PWY0-1601',
        Common_Name: 'cadaverine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Detoxification|Acid-Resistance|PWY0-1601',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Acid Resistance|cadaverine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1601',
        Common_Name: 'cadaverine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY0-1601',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|cadaverine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8165',
        Common_Name: 'cytidylyl molybdenum cofactor sulfurylation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-8165',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|cytidylyl molybdenum cofactor sulfurylation',
    },
    {
        BioCyc_ID: 'PWY-8351',
        Common_Name: 'sulfoquinovosyl diacylglycerides and sulfoquinovosyl glycerol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|PWY-8351',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|sulfoquinovosyl diacylglycerides and sulfoquinovosyl glycerol degradation',
    },
    {
        BioCyc_ID: 'PWY-5997',
        Common_Name: '&alpha;-linolenate biosynthesis I (plants and red algae)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Alpha-Linolenate-Biosynthesis|PWY-5997',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|&alpha;-Linolenate Biosynthesis|&alpha;-linolenate biosynthesis I (plants and red algae)',
    },
    {
        BioCyc_ID: 'PWY-6386',
        Common_Name: 'UDP-<i>N</i>-acetylmuramoyl-pentapeptide biosynthesis II (lysine-containing)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Acetylmuramoyl-Pentapeptide-Biosynthesis|PWY-6386',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|UDP-<i>N</i>-Acetylmuramoyl-Pentapeptide Biosynthesis|UDP-<i>N</i>-acetylmuramoyl-pentapeptide biosynthesis II (lysine-containing)',
    },
    {
        BioCyc_ID: 'PWY-6828',
        Common_Name: 'linezolid resistance',
        MetaCyc_hierarchy_IDs: 'Detoxification|Antibiotic-Resistance|PWY-6828',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Antibiotic Resistance|linezolid resistance',
    },
    {
        BioCyc_ID: 'PWY-7674',
        Common_Name: 'CMP-8-amino-3,8-dideoxy-D-<I>manno</I>-octulosonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|CMP-KDO-Biosynthesis|PWY-7674',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-3-deoxy-D-<I>manno</I>-octulosonate Biosynthesis|CMP-8-amino-3,8-dideoxy-D-<I>manno</I>-octulosonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7674',
        Common_Name: 'CMP-8-amino-3,8-dideoxy-D-<I>manno</I>-octulosonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|PWY-7674',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-8-amino-3,8-dideoxy-D-<I>manno</I>-octulosonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7915',
        Common_Name: 'jellyfish bioluminescence',
        MetaCyc_hierarchy_IDs: 'Bioluminescence|PWY-7915',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Bioluminescence|jellyfish bioluminescence',
    },
    {
        BioCyc_ID: 'PWY1G01-4',
        Common_Name: 'glutarate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY1G01-4',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|glutarate degradation',
    },
    {
        BioCyc_ID: 'PWY-5292',
        Common_Name: 'vindoline, vindorosine and vinblastine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-5292',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|vindoline, vindorosine and vinblastine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5292',
        Common_Name: 'vindoline, vindorosine and vinblastine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TERPENOID-ALKALOIDS|PWY-5292',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Terpenoid Alkaloid Biosynthesis|vindoline, vindorosine and vinblastine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5292',
        Common_Name: 'vindoline, vindorosine and vinblastine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TERPENOID-ALKALOIDS|PWY-5292',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Terpenoid Alkaloid Biosynthesis|vindoline, vindorosine and vinblastine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6375',
        Common_Name: 'desferrioxamine E biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6375',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|desferrioxamine E biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6958',
        Common_Name: 'icosapentaenoate biosynthesis I (lower eukaryotes)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Eicosapentaenoate-Biosynthesis|PWY-6958',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Icosapentaenoate Biosynthesis|icosapentaenoate biosynthesis I (lower eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-8171',
        Common_Name: 'molybdenum cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-8171',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|molybdenum cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PARATHION-DEGRADATION-PWY',
        Common_Name: 'parathion degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PARATHION-DEGRADATION-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|parathion degradation',
    },
    {
        BioCyc_ID: 'LIPASYN-PWY',
        Common_Name: 'phospholipases',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|LIPASYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|phospholipases',
    },
    {
        BioCyc_ID: 'LIPASYN-PWY',
        Common_Name: 'phospholipases',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|LIPASYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|phospholipases',
    },
    {
        BioCyc_ID: 'POLYAMINSYN3-PWY',
        Common_Name: 'superpathway of polyamine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|POLYAMINSYN3-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|superpathway of polyamine biosynthesis II',
    },
    {
        BioCyc_ID: 'POLYAMINSYN3-PWY',
        Common_Name: 'superpathway of polyamine biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|POLYAMINSYN3-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of polyamine biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5433',
        Common_Name: 'superpathway of lipoxygenase',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|FATTY-ACID-DERIVATIVE-SYN|PWY-5433',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Fatty Acid Derivative Biosynthesis|superpathway of lipoxygenase',
    },
    {
        BioCyc_ID: 'PWY-5433',
        Common_Name: 'superpathway of lipoxygenase',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5433',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of lipoxygenase',
    },
    {
        BioCyc_ID: 'PWY-7027',
        Common_Name: 'hentriaconta-3,6,9,12,15,19,22,25,28-nonaene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Olefins-Biosynthesis|PWY-7027',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Olefin Biosynthesis|hentriaconta-3,6,9,12,15,19,22,25,28-nonaene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5367',
        Common_Name: 'petroselinate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PWY-5367',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|petroselinate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7598',
        Common_Name: '&alpha;-linolenate biosynthesis II (cyanobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Alpha-Linolenate-Biosynthesis|PWY-7598',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|&alpha;-Linolenate Biosynthesis|&alpha;-linolenate biosynthesis II (cyanobacteria)',
    },
    {
        BioCyc_ID: 'PWY-7953',
        Common_Name: 'UDP-<i>N</i>-acetylmuramoyl-pentapeptide biosynthesis III (<i>meso</i>-diaminopimelate containing)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Acetylmuramoyl-Pentapeptide-Biosynthesis|PWY-7953',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|UDP-<i>N</i>-Acetylmuramoyl-Pentapeptide Biosynthesis|UDP-<i>N</i>-acetylmuramoyl-pentapeptide biosynthesis III (<i>meso</i>-diaminopimelate containing)',
    },
    {
        BioCyc_ID: 'P641-PWY',
        Common_Name: 'phenylmercury acetate degradation',
        MetaCyc_hierarchy_IDs: 'Detoxification|Mercury-Detoxification|P641-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Mercury Detoxification|phenylmercury acetate degradation',
    },
    {
        BioCyc_ID: 'PWY-6143',
        Common_Name: 'CMP-pseudaminate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|PWY-6143',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-pseudaminate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5177',
        Common_Name: 'glutaryl-CoA degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-5177',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|glutaryl-CoA degradation',
    },
    {
        BioCyc_ID: 'PWY-5290',
        Common_Name: 'secologanin and strictosidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-5290',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|secologanin and strictosidine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5290',
        Common_Name: 'secologanin and strictosidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TERPENOID-ALKALOIDS|PWY-5290',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Terpenoid Alkaloid Biosynthesis|secologanin and strictosidine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5290',
        Common_Name: 'secologanin and strictosidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TERPENOID-ALKALOIDS|PWY-5290',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Terpenoid Alkaloid Biosynthesis|secologanin and strictosidine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7988',
        Common_Name: 'baumannoferrin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7988',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|baumannoferrin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5964',
        Common_Name: 'guanylyl molybdenum cofactor biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Molybdenum-Cofactor-Biosynthesis|PWY-5964',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Molybdenum-Containing Cofactor Biosynthesis|guanylyl molybdenum cofactor biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7602',
        Common_Name: 'icosapentaenoate biosynthesis V (8-desaturase, lower eukaryotes)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Eicosapentaenoate-Biosynthesis|PWY-7602',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Icosapentaenoate Biosynthesis|icosapentaenoate biosynthesis V (8-desaturase, lower eukaryotes)',
    },
    {
        BioCyc_ID: 'PWY-7756',
        Common_Name: 'iso-bile acids biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|Iso-bile-Acids-Biosynthesis|PWY-7756',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|Iso-bile Acid Biosynthesis|iso-bile acids biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6532',
        Common_Name: 'diphenylamine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6532',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|diphenylamine degradation',
    },
    {
        BioCyc_ID: 'PWY-5676',
        Common_Name: 'acetyl-CoA fermentation to butanoate',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|Fermentation-to-Acids|Acetyl-CoA-Butyrate|PWY-5676',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|Fermentation to Short-Chain Fatty Acids|Fermentation to Butanoate|acetyl-CoA fermentation to butanoate',
    },
    {
        BioCyc_ID: 'PWY-5676',
        Common_Name: 'acetyl-CoA fermentation to butanoate',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5676',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|acetyl-CoA fermentation to butanoate',
    },
    {
        BioCyc_ID: 'PWY-6158',
        Common_Name: 'creatine phosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|PWY-6158',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|creatine phosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6158',
        Common_Name: 'creatine phosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-6158',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|creatine phosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7366',
        Common_Name: 'phosphatidylglycerol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|PWY-7366',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|phosphatidylglycerol degradation',
    },
    {
        BioCyc_ID: 'PWY-7943',
        Common_Name: 'lycopadiene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Olefins-Biosynthesis|PWY-7943',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Olefin Biosynthesis|lycopadiene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7943',
        Common_Name: 'lycopadiene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TETRATERPENOID-SYN|PWY-7943',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Tetraterpenoid Biosynthesis|lycopadiene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8431',
        Common_Name: 'L-mimosine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|PWY-8431',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|L-mimosine degradation',
    },
    {
        BioCyc_ID: 'PWY-7664',
        Common_Name: 'oleate biosynthesis IV (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Oleate-Biosynthesis|PWY-7664',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Oleate Biosynthesis|oleate biosynthesis IV (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7815',
        Common_Name: 'poly(ribitol phosphate) wall teichoic acid biosynthesis I (<i>B. subtilis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Teichoic-Acids-Biosynthesis|PWY-7815',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Teichoic Acid Biosynthesis|poly(ribitol phosphate) wall teichoic acid biosynthesis I (<i>B. subtilis</i>)',
    },
    {
        BioCyc_ID: 'PWY-6931',
        Common_Name: 'seleno-amino acid detoxification and volatilization I',
        MetaCyc_hierarchy_IDs: 'Detoxification|Seleno-Amino-Acid-Detoxification|PWY-6931',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Seleno-Amino Acid Detoxification|seleno-amino acid detoxification and volatilization I',
    },
    {
        BioCyc_ID: 'PWY-6931',
        Common_Name: 'seleno-amino acid detoxification and volatilization I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Selenium-Metabolism|Seleno-Amino-Acid-Detoxification|PWY-6931',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Selenium Metabolism|Seleno-Amino Acid Detoxification|seleno-amino acid detoxification and volatilization I',
    },
    {
        BioCyc_ID: 'PWY-7529',
        Common_Name: 'CMP-<i>N</i>-acetyl-7-<i>O</i>-acetylneuraminate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CMP-Sugar-Biosynthesis|PWY-7529',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CMP-sugar Biosynthesis|CMP-<i>N</i>-acetyl-7-<i>O</i>-acetylneuraminate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5749',
        Common_Name: 'itaconate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-5749',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|itaconate degradation',
    },
    {
        BioCyc_ID: 'PWY-8216',
        Common_Name: 'strychnine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|INDOLE-ALKALOIDS|PWY-8216',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Indole Alkaloid Biosynthesis|strychnine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8216',
        Common_Name: 'strychnine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|N-CONTAINING-SECONDARY-CMPD-SYN|ALKALOIDS-SYN|TERPENOID-ALKALOIDS|PWY-8216',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Nitrogen-Containing Secondary Compound Biosynthesis|Alkaloid Biosynthesis|Terpenoid Alkaloid Biosynthesis|strychnine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8216',
        Common_Name: 'strychnine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TERPENOID-ALKALOIDS|PWY-8216',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Terpenoid Alkaloid Biosynthesis|strychnine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6379',
        Common_Name: 'alcaligin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6379',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|alcaligin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7376',
        Common_Name: 'cob(II)yrinate <i>a,c</i>-diamide biosynthesis II (late cobalt incorporation)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobyrinate-diamide-Biosynthesis|PWY-7376',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|cob(II)yrinate <i>a,c</i>-diamide biosynthesis|cob(II)yrinate <i>a,c</i>-diamide biosynthesis II (late cobalt incorporation)',
    },
    {
        BioCyc_ID: 'PWY-6940',
        Common_Name: 'icosapentaenoate biosynthesis VI (fungi)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Eicosapentaenoate-Biosynthesis|PWY-6940',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Icosapentaenoate Biosynthesis|icosapentaenoate biosynthesis VI (fungi)',
    },
    {
        BioCyc_ID: 'PWY-7755',
        Common_Name: 'iso-bile acids biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|Iso-bile-Acids-Biosynthesis|PWY-7755',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|Iso-bile Acid Biosynthesis|iso-bile acids biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-721',
        Common_Name: '3-methylquinoline degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-721',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|3-methylquinoline degradation',
    },
    {
        BioCyc_ID: 'PWY-6125',
        Common_Name: 'superpathway of guanosine nucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|PWY-6125',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|superpathway of guanosine nucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6125',
        Common_Name: 'superpathway of guanosine nucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6125',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of guanosine nucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6562',
        Common_Name: 'norspermidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-6562',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|norspermidine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8056',
        Common_Name: 'anandamide lipoxygenation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|PWY-8056',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|anandamide lipoxygenation',
    },
    {
        BioCyc_ID: 'PWY-7032',
        Common_Name: 'alkane biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Alkanes-Biosynthesis|PWY-7032',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Alkane Biosynthesis|alkane biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-4021',
        Common_Name: '&beta;-alanine betaine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|Organic-solutes-Biosynthesis|Betaine-Biosynthesis|PWY-4021',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Organic Solute Biosynthesis|Betaine Biosynthesis|&beta;-alanine betaine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4021',
        Common_Name: '&beta;-alanine betaine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|Betaine-Biosynthesis|PWY-4021',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|Betaine Biosynthesis|&beta;-alanine betaine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4021',
        Common_Name: '&beta;-alanine betaine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|PWY-4021',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|&beta;-alanine betaine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7587',
        Common_Name: 'oleate biosynthesis III (cyanobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Oleate-Biosynthesis|PWY-7587',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Oleate Biosynthesis|oleate biosynthesis III (cyanobacteria)',
    },
    {
        BioCyc_ID: 'PWY-7818',
        Common_Name: 'type IV lipoteichoic acid biosynthesis (<i>S. pneumoniae</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Teichoic-Acids-Biosynthesis|PWY-7818',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Teichoic Acid Biosynthesis|type IV lipoteichoic acid biosynthesis (<i>S. pneumoniae</i>)',
    },
    {
        BioCyc_ID: 'PWY-6935',
        Common_Name: 'seleno-amino acid detoxification and volatilization II',
        MetaCyc_hierarchy_IDs: 'Detoxification|Seleno-Amino-Acid-Detoxification|PWY-6935',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Seleno-Amino Acid Detoxification|seleno-amino acid detoxification and volatilization II',
    },
    {
        BioCyc_ID: 'PWY-6935',
        Common_Name: 'seleno-amino acid detoxification and volatilization II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Selenium-Metabolism|Seleno-Amino-Acid-Detoxification|PWY-6935',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Selenium Metabolism|Seleno-Amino Acid Detoxification|seleno-amino acid detoxification and volatilization II',
    },
    {
        BioCyc_ID: 'PWY-6808',
        Common_Name: 'dTDP-&alpha;-D-forosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-6808',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&alpha;-D-forosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7310',
        Common_Name: 'D-glucosaminate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-7310',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|D-glucosaminate degradation',
    },
    {
        BioCyc_ID: 'PWY-8007',
        Common_Name: 'staphylopine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-8007',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|staphylopine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7377',
        Common_Name: 'cob(II)yrinate <i>a,c</i>-diamide biosynthesis I (early cobalt insertion)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Cobyrinate-diamide-Biosynthesis|PWY-7377',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|cob(II)yrinate <i>a,c</i>-diamide biosynthesis|cob(II)yrinate <i>a,c</i>-diamide biosynthesis I (early cobalt insertion)',
    },
    {
        BioCyc_ID: 'PWY-7050',
        Common_Name: 'icosapentaenoate biosynthesis IV (bacteria)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Eicosapentaenoate-Biosynthesis|PWY-7050',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Icosapentaenoate Biosynthesis|icosapentaenoate biosynthesis IV (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7154',
        Common_Name: 'ergosterol biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|Ergosterol-Biosynthesis|PWY-7154',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|Ergosterol Biosynthesis|ergosterol biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-8432',
        Common_Name: '4-hydroxypyridine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-8432',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|4-hydroxypyridine degradation',
    },
    {
        BioCyc_ID: 'PWY-6284',
        Common_Name: 'superpathway of unsaturated fatty acids biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6284',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of unsaturated fatty acids biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-6284',
        Common_Name: 'superpathway of unsaturated fatty acids biosynthesis (<i>E. coli</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PWY-6284',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|superpathway of unsaturated fatty acids biosynthesis (<i>E. coli</i>)',
    },
    {
        BioCyc_ID: 'PWY-8114',
        Common_Name: 'branched-chain polyamines biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-8114',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|branched-chain polyamines biosynthesis',
    },
    {
        BioCyc_ID: 'SOPHOROSYLOXYDOCOSANOATE-DEG-PWY',
        Common_Name: 'sophorosyloxydocosanoate deacetylation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|SOPHOROSYLOXYDOCOSANOATE-DEG-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|sophorosyloxydocosanoate deacetylation',
    },
    {
        BioCyc_ID: 'PWY-6622',
        Common_Name: 'heptadecane biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Alkanes-Biosynthesis|PWY-6622',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Alkane Biosynthesis|heptadecane biosynthesis',
    },
    {
        BioCyc_ID: 'ORN-AMINOPENTANOATE-CAT-PWY',
        Common_Name: 'L-ornithine degradation I (L-proline biosynthesis)',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|ORN-AMINOPENTANOATE-CAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|L-ornithine degradation I (L-proline biosynthesis)',
    },
    {
        BioCyc_ID: 'ORN-AMINOPENTANOATE-CAT-PWY',
        Common_Name: 'L-ornithine degradation I (L-proline biosynthesis)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|PROLINE-SYN|ORN-AMINOPENTANOATE-CAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-proline Biosynthesis|L-ornithine degradation I (L-proline biosynthesis)',
    },
    {
        BioCyc_ID: 'PWY-5996',
        Common_Name: 'oleate biosynthesis II (animals and fungi)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Oleate-Biosynthesis|PWY-5996',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Oleate Biosynthesis|oleate biosynthesis II (animals and fungi)',
    },
    {
        BioCyc_ID: 'PWY-7817',
        Common_Name: 'type I lipoteichoic acid biosynthesis (<i>S. aureus</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Teichoic-Acids-Biosynthesis|PWY-7817',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Teichoic Acid Biosynthesis|type I lipoteichoic acid biosynthesis (<i>S. aureus</i>)',
    },
    {
        BioCyc_ID: 'PWY-5491',
        Common_Name: 'diethylphosphate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|PWY-5491',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|diethylphosphate degradation',
    },
    {
        BioCyc_ID: 'PWY-6933',
        Common_Name: 'seleno-amino acid detoxification and volatilization III',
        MetaCyc_hierarchy_IDs: 'Detoxification|Seleno-Amino-Acid-Detoxification|PWY-6933',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Seleno-Amino Acid Detoxification|seleno-amino acid detoxification and volatilization III',
    },
    {
        BioCyc_ID: 'PWY-6933',
        Common_Name: 'seleno-amino acid detoxification and volatilization III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Selenium-Metabolism|Seleno-Amino-Acid-Detoxification|PWY-6933',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Selenium Metabolism|Seleno-Amino Acid Detoxification|seleno-amino acid detoxification and volatilization III',
    },
    {
        BioCyc_ID: 'PWY-7439',
        Common_Name: 'dTDP-&beta;-L-evernitrose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7439',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&beta;-L-evernitrose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7948',
        Common_Name: '4-oxopentanoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-7948',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|4-oxopentanoate degradation',
    },
    {
        BioCyc_ID: 'PWY-6408',
        Common_Name: 'pyochelin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-6408',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|pyochelin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6987',
        Common_Name: 'lipoate biosynthesis and incorporation III (Bacillus)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Lipoate-Biosynthesis|PWY-6987',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Lipoate Biosynthesis and Salvage|lipoate biosynthesis and incorporation III (Bacillus)',
    },
    {
        BioCyc_ID: 'PWY-7049',
        Common_Name: 'icosapentaenoate biosynthesis II (6-desaturase, mammals)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Eicosapentaenoate-Biosynthesis|PWY-7049',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Icosapentaenoate Biosynthesis|icosapentaenoate biosynthesis II (6-desaturase, mammals)',
    },
    {
        BioCyc_ID: 'PWY-6075',
        Common_Name: 'ergosterol biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|Ergosterol-Biosynthesis|PWY-6075',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|Ergosterol Biosynthesis|ergosterol biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-481',
        Common_Name: 'ethylbenzene degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-481',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|ethylbenzene degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-6385',
        Common_Name: 'peptidoglycan biosynthesis III (mycobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Biosynthesis|PWY-6385',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Biosynthesis|peptidoglycan biosynthesis III (mycobacteria)',
    },
    {
        BioCyc_ID: 'PWY-6385',
        Common_Name: 'peptidoglycan biosynthesis III (mycobacteria)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6385',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|peptidoglycan biosynthesis III (mycobacteria)',
    },
    {
        BioCyc_ID: 'PWY0-1303',
        Common_Name: 'aminopropylcadaverine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY0-1303',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|aminopropylcadaverine biosynthesis',
    },
    {
        BioCyc_ID: 'LIPAS-PWY',
        Common_Name: 'triacylglycerol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|LIPAS-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|triacylglycerol degradation',
    },
    {
        BioCyc_ID: 'PWY-7033',
        Common_Name: 'alkane biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Hydrocarbon-Biosynthesis|Alkanes-Biosynthesis|PWY-7033',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Hydrocarbon Biosynthesis|Alkane Biosynthesis|alkane biosynthesis II',
    },
    {
        BioCyc_ID: 'CITRULLINE-DEG-PWY',
        Common_Name: 'L-citrulline degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|MISCELLANEOUS-DEG|CITRULLINE-DEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Other Amino Acid Degradation|L-citrulline degradation',
    },
    {
        BioCyc_ID: 'PWY-5147',
        Common_Name: 'oleate biosynthesis I (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Oleate-Biosynthesis|PWY-5147',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Oleate Biosynthesis|oleate biosynthesis I (plants)',
    },
    {
        BioCyc_ID: 'TEICHOICACID-PWY',
        Common_Name: 'poly(glycerol phosphate) wall teichoic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Teichoic-Acids-Biosynthesis|TEICHOICACID-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Teichoic Acid Biosynthesis|poly(glycerol phosphate) wall teichoic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8138',
        Common_Name: 'polyphosphate metabolism',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|PWY-8138',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|polyphosphate metabolism',
    },
    {
        BioCyc_ID: 'PWY-7372',
        Common_Name: 'demethylmenaquinol-6 biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|Demethylmenaquinol-6-Biosynthesis|PWY-7372',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|Demethylmenaquinol-6 Biosynthesis|demethylmenaquinol-6 biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6973',
        Common_Name: 'dTDP-&alpha;-D-olivose, dTDP-&alpha;-D-oliose and dTDP-&alpha;-D-mycarose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-6973',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&alpha;-D-olivose, dTDP-&alpha;-D-oliose and dTDP-&alpha;-D-mycarose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5759',
        Common_Name: 'saponin biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5759',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|saponin biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-2361',
        Common_Name: '3-oxoadipate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-2361',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|3-oxoadipate degradation',
    },
    {
        BioCyc_ID: 'PWY-8370',
        Common_Name: 'leporin B biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-8370',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|leporin B biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-501',
        Common_Name: 'lipoate biosynthesis and incorporation I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Lipoate-Biosynthesis|PWY0-501',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Lipoate Biosynthesis and Salvage|lipoate biosynthesis and incorporation I',
    },
    {
        BioCyc_ID: 'PWY-7724',
        Common_Name: 'icosapentaenoate biosynthesis III (8-desaturase, mammals)',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|PUFA-Biosynthesis|Eicosapentaenoate-Biosynthesis|PWY-7724',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Polyunsaturated Fatty Acid Biosynthesis|Icosapentaenoate Biosynthesis|icosapentaenoate biosynthesis III (8-desaturase, mammals)',
    },
    {
        BioCyc_ID: 'PWY-699',
        Common_Name: 'brassinolide biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Brassinosteroid-Biosynthesis|PWY-699',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Brassinosteroid Biosynthesis|brassinolide biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-699',
        Common_Name: 'brassinolide biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|Brassinosteroid-Biosynthesis|PWY-699',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|Brassinosteroid Biosynthesis|brassinolide biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6752',
        Common_Name: '<i>ortho</i>-quinones biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6752',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<i>ortho</i>-quinones biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6555',
        Common_Name: 'superpathway of 1D-<i>myo</i>-inositol hexakisphosphate biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cyclitols-Biosynthesis|Phytate-Biosynthesis|PWY-6555',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cyclitol Biosynthesis|Phytate Biosynthesis|superpathway of 1D-<i>myo</i>-inositol hexakisphosphate biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'PWY-6555',
        Common_Name: 'superpathway of 1D-<i>myo</i>-inositol hexakisphosphate biosynthesis (plants)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6555',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of 1D-<i>myo</i>-inositol hexakisphosphate biosynthesis (plants)',
    },
    {
        BioCyc_ID: 'P101-PWY',
        Common_Name: 'ectoine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|P101-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|ectoine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6368',
        Common_Name: '3-phosphoinositide degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|PWY-6368',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|3-phosphoinositide degradation',
    },
    {
        BioCyc_ID: 'GLYCOLYSIS',
        Common_Name: 'glycolysis I (from glucose 6-phosphate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYCOLYSIS-VARIANTS|GLYCOLYSIS',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Glycolysis|glycolysis I (from glucose 6-phosphate)',
    },
    {
        BioCyc_ID: 'PWY-6014',
        Common_Name: 'vernolate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Epoxy-Fatty-Acids-Biosynthesis|Vernolate-Biosynthesis|PWY-6014',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Epoxylated Fatty Acid Biosynthesis|Vernolate Biosynthesis|vernolate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6014',
        Common_Name: 'vernolate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Vernolate-Biosynthesis|PWY-6014',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Vernolate Biosynthesis|vernolate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7816',
        Common_Name: 'poly(ribitol phosphate) wall teichoic acid biosynthesis II (<i>S. aureus</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Teichoic-Acids-Biosynthesis|PWY-7816',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Teichoic Acid Biosynthesis|poly(ribitol phosphate) wall teichoic acid biosynthesis II (<i>S. aureus</i>)',
    },
    {
        BioCyc_ID: 'PWY-6348',
        Common_Name: 'phosphate acquisition',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|PWY-6348',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|phosphate acquisition',
    },
    {
        BioCyc_ID: 'PWY-5853',
        Common_Name: 'demethylmenaquinol-6 biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|Demethylmenaquinol-6-Biosynthesis|PWY-5853',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|Demethylmenaquinol-6 Biosynthesis|demethylmenaquinol-6 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7688',
        Common_Name: 'dTDP-&alpha;-D-ravidosamine and dTDP-4-acetyl-&alpha;-D-ravidosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7688',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&alpha;-D-ravidosamine and dTDP-4-acetyl-&alpha;-D-ravidosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5992',
        Common_Name: 'thalianol and derivatives biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5992',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|thalianol and derivatives biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6984',
        Common_Name: 'lipoate salvage II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Lipoate-Biosynthesis|PWY-6984',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Lipoate Biosynthesis and Salvage|lipoate salvage II',
    },
    {
        BioCyc_ID: 'PWY0-1465',
        Common_Name: 'D-malate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY0-1465',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|D-malate degradation',
    },
    {
        BioCyc_ID: 'AEROBACTINSYN-PWY',
        Common_Name: 'aerobactin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|AEROBACTINSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|aerobactin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8131',
        Common_Name: "5'-deoxyadenosine degradation II",
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|5-Deoxyadenosine-Degradation|PWY-8131',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|5'-Deoxyadenosine Degradation|5'-deoxyadenosine degradation II",
    },
    {
        BioCyc_ID: 'PWY-2582',
        Common_Name: 'brassinolide biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Brassinosteroid-Biosynthesis|PWY-2582',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Brassinosteroid Biosynthesis|brassinolide biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-2582',
        Common_Name: 'brassinolide biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sterol-Biosynthesis|Brassinosteroid-Biosynthesis|PWY-2582',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sterol Biosynthesis|Brassinosteroid Biosynthesis|brassinolide biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7480',
        Common_Name: '2,3-dihydroxybenzoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7480',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|2,3-dihydroxybenzoate degradation',
    },
    {
        BioCyc_ID: 'PWY-7783',
        Common_Name: 'plasmalogen degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|PWY-7783',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|plasmalogen degradation',
    },
    {
        BioCyc_ID: 'URSIN-PWY',
        Common_Name: 'ureide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|URSIN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|ureide biosynthesis',
    },
    {
        BioCyc_ID: 'URSIN-PWY',
        Common_Name: 'ureide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|URSIN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|ureide biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6641',
        Common_Name: 'superpathway of sulfolactate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfolactate-Degradation|PWY-6641',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfolactate Degradation|superpathway of sulfolactate degradation',
    },
    {
        BioCyc_ID: 'PWY-6641',
        Common_Name: 'superpathway of sulfolactate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6641',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sulfolactate degradation',
    },
    {
        BioCyc_ID: 'PWY-5194',
        Common_Name: 'siroheme biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|PWY-5194',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|siroheme biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5484',
        Common_Name: 'glycolysis II (from fructose 6-phosphate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYCOLYSIS-VARIANTS|PWY-5484',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Glycolysis|glycolysis II (from fructose 6-phosphate)',
    },
    {
        BioCyc_ID: 'PWY-7634',
        Common_Name: 'vernolate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Epoxy-Fatty-Acids-Biosynthesis|Vernolate-Biosynthesis|PWY-7634',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Epoxylated Fatty Acid Biosynthesis|Vernolate Biosynthesis|vernolate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7634',
        Common_Name: 'vernolate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Vernolate-Biosynthesis|PWY-7634',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Vernolate Biosynthesis|vernolate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7819',
        Common_Name: 'poly(3-O-&beta;-D-glucopyranosyl-<i>N</i>-acetylgalactosamine 1-phosphate) wall teichoic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Teichoic-Acids-Biosynthesis|PWY-7819',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Teichoic Acid Biosynthesis|poly(3-O-&beta;-D-glucopyranosyl-<i>N</i>-acetylgalactosamine 1-phosphate) wall teichoic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6262',
        Common_Name: 'demethylmenaquinol-8 biosynthesis II',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|Demethylmenaquinol-8-Biosynthesis|PWY-6262',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|Demethylmenaquinol-8 Biosynthesis|demethylmenaquinol-8 biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7104',
        Common_Name: 'dTDP-&beta;-L-megosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7104',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&beta;-L-megosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7475',
        Common_Name: 'des-methyl avenacin A-1 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7475',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|des-methyl avenacin A-1 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1275',
        Common_Name: 'lipoate biosynthesis and incorporation II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Lipoate-Biosynthesis|PWY0-1275',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Lipoate Biosynthesis and Salvage|lipoate biosynthesis and incorporation II',
    },
    {
        BioCyc_ID: 'PWY-5162',
        Common_Name: '2-hydroxypenta-2,4-dienoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-5162',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|2-hydroxypenta-2,4-dienoate degradation',
    },
    {
        BioCyc_ID: 'PWY-7571',
        Common_Name: 'ferrichrome A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7571',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|ferrichrome A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8130',
        Common_Name: "5'-deoxyadenosine degradation I",
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|5-Deoxyadenosine-Degradation|PWY-8130',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|5'-Deoxyadenosine Degradation|5'-deoxyadenosine degradation I",
    },
    {
        BioCyc_ID: 'PWY-8101',
        Common_Name: 'arsenate detoxification II',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Arsenate-Detoxification|PWY-8101',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Arsenate Detoxification|arsenate detoxification II',
    },
    {
        BioCyc_ID: 'PWY5F9-3233',
        Common_Name: 'phthalate degradation (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY5F9-3233',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|phthalate degradation (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-7186',
        Common_Name: 'superpathway of scopolin and esculin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|COUMARIN-SYN|PWY-7186',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Coumarins Biosynthesis|superpathway of scopolin and esculin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7186',
        Common_Name: 'superpathway of scopolin and esculin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7186',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of scopolin and esculin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4121',
        Common_Name: 'glutathionylspermidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyamine-Biosynthesis|PWY-4121',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Amide, Amidine, Amine, and Polyamine Biosynthesis|glutathionylspermidine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4121',
        Common_Name: 'glutathionylspermidine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Other-biosynthesis|Reductants|PWY-4121',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Other Biosynthesis|Reductant Biosynthesis|glutathionylspermidine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY6666-1',
        Common_Name: 'anandamide degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|PWY6666-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|anandamide degradation',
    },
    {
        BioCyc_ID: 'PWY-5297',
        Common_Name: 'siroheme amide biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Tetrapyrrole-Biosynthesis|Porphyrin-Compounds-Biosynthesis|PWY-5297',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Tetrapyrrole Biosynthesis|Porphyrin Compound Biosynthesis|siroheme amide biosynthesis',
    },
    {
        BioCyc_ID: 'ANAGLYCOLYSIS-PWY',
        Common_Name: 'glycolysis III (from glucose)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYCOLYSIS-VARIANTS|ANAGLYCOLYSIS-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Glycolysis|glycolysis III (from glucose)',
    },
    {
        BioCyc_ID: 'PWY-6917',
        Common_Name: 'vernolate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Epoxy-Fatty-Acids-Biosynthesis|Vernolate-Biosynthesis|PWY-6917',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Epoxylated Fatty Acid Biosynthesis|Vernolate Biosynthesis|vernolate biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6917',
        Common_Name: 'vernolate biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Vernolate-Biosynthesis|PWY-6917',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Vernolate Biosynthesis|vernolate biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6459',
        Common_Name: 'peptidoglycan cross-bridge biosynthesis I (<i>S. aureus</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Cross-Bridge-Biosynthesis|PWY-6459',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Cross-Bridge Biosynthesis|peptidoglycan cross-bridge biosynthesis I (<i>S. aureus</i>)',
    },
    {
        BioCyc_ID: 'PWY-5852',
        Common_Name: 'demethylmenaquinol-8 biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|Demethylmenaquinol-8-Biosynthesis|PWY-5852',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|Demethylmenaquinol-8 Biosynthesis|demethylmenaquinol-8 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7315',
        Common_Name: 'dTDP-<i>N</i>-acetylthomosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7315',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-<i>N</i>-acetylthomosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6095',
        Common_Name: 'dammara-20,24-diene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-6095',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|dammara-20,24-diene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-522',
        Common_Name: 'lipoate salvage I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Lipoate-Biosynthesis|PWY0-522',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Lipoate Biosynthesis and Salvage|lipoate salvage I',
    },
    {
        BioCyc_ID: 'PWY-5654',
        Common_Name: '2-amino-3-carboxymuconate semialdehyde degradation to 2-hydroxypentadienoate',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-5654',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|2-amino-3-carboxymuconate semialdehyde degradation to 2-hydroxypentadienoate',
    },
    {
        BioCyc_ID: 'PWY-5912',
        Common_Name: "2'-deoxymugineic acid phytosiderophore biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-5912',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|2'-deoxymugineic acid phytosiderophore biosynthesis",
    },
    {
        BioCyc_ID: 'PWY1YI0-1',
        Common_Name: 'arsenate detoxification V',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Arsenate-Detoxification|PWY1YI0-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Arsenate Detoxification|arsenate detoxification V',
    },
    {
        BioCyc_ID: '4-HYDROXYMANDELATE-DEGRADATION-PWY',
        Common_Name: '4-hydroxymandelate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|4-HYDROXYMANDELATE-DEGRADATION-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|4-hydroxymandelate degradation',
    },
    {
        BioCyc_ID: 'PWY-7283',
        Common_Name: 'wybutosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|PWY-7283',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|wybutosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7283',
        Common_Name: 'wybutosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7283',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|wybutosine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6308',
        Common_Name: 'L-cysteine biosynthesis II (tRNA-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Aminoacyl-tRNAs-Charging|PWY-6308',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aminoacyl-tRNA Charging|L-cysteine biosynthesis II (tRNA-dependent)',
    },
    {
        BioCyc_ID: 'PWY-6308',
        Common_Name: 'L-cysteine biosynthesis II (tRNA-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|PWY-6308',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|L-cysteine biosynthesis II (tRNA-dependent)',
    },
    {
        BioCyc_ID: 'PWY-1042',
        Common_Name: 'glycolysis IV',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYCOLYSIS-VARIANTS|PWY-1042',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Glycolysis|glycolysis IV',
    },
    {
        BioCyc_ID: 'PWY-7641',
        Common_Name: '5-hexynoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Acetlylenic-Fatty-Acid-Biosynthesis|PWY-7641',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Fatty Acid Containing acetylenic bond Biosynthesis|5-hexynoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6463',
        Common_Name: 'peptidoglycan cross-bridge biosynthesis IV (<i>Weissella viridescens</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Cross-Bridge-Biosynthesis|PWY-6463',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Cross-Bridge Biosynthesis|peptidoglycan cross-bridge biosynthesis IV (<i>Weissella viridescens</i>)',
    },
    {
        BioCyc_ID: 'PWY-8129',
        Common_Name: 'cell-surface glycoconjugate-linked phosphonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|PWY-8129',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|cell-surface glycoconjugate-linked phosphonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8129',
        Common_Name: 'cell-surface glycoconjugate-linked phosphonate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|PWY-8129',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|cell-surface glycoconjugate-linked phosphonate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7761',
        Common_Name: 'NAD salvage pathway II (PNC IV cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PWY-7761',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD salvage pathway II (PNC IV cycle)',
    },
    {
        BioCyc_ID: 'PWY-7761',
        Common_Name: 'NAD salvage pathway II (PNC IV cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-7761',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway II (PNC IV cycle)',
    },
    {
        BioCyc_ID: 'PWY-7761',
        Common_Name: 'NAD salvage pathway II (PNC IV cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-7761',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway II (PNC IV cycle)',
    },
    {
        BioCyc_ID: 'PWY-7318',
        Common_Name: 'dTDP-3-acetamido-3,6-dideoxy-&alpha;-D-glucose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7318',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-3-acetamido-3,6-dideoxy-&alpha;-D-glucose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7112',
        Common_Name: '4-hydroxy-2-nonenal detoxification',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-7112',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|4-hydroxy-2-nonenal detoxification',
    },
    {
        BioCyc_ID: 'PWY-112',
        Common_Name: 'lupeol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-112',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|lupeol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7382',
        Common_Name: 'lipoate biosynthesis and incorporation IV (yeast)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Lipoate-Biosynthesis|PWY-7382',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Lipoate Biosynthesis and Salvage|lipoate biosynthesis and incorporation IV (yeast)',
    },
    {
        BioCyc_ID: 'PWY-6038',
        Common_Name: 'citrate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-6038',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|citrate degradation',
    },
    {
        BioCyc_ID: 'PWY-7983',
        Common_Name: 'pseudomonine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Siderophores-Biosynthesis|PWY-7983',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Siderophore and Metallophore Biosynthesis|pseudomonine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6421',
        Common_Name: 'arsenate detoxification IV (mycothiol)',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Arsenate-Detoxification|PWY-6421',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Arsenate Detoxification|arsenate detoxification IV (mycothiol)',
    },
    {
        BioCyc_ID: 'PWY-5266',
        Common_Name: '<I>p</I>-cymene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-5266',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|<I>p</I>-cymene degradation',
    },
    {
        BioCyc_ID: 'PWY-5266',
        Common_Name: '<I>p</I>-cymene degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5266',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|<I>p</I>-cymene degradation',
    },
    {
        BioCyc_ID: 'PWY-7715',
        Common_Name: 'superpathway of trichothecene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7715',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of trichothecene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5921',
        Common_Name: 'glutaminyl-tRNA<sup>gln</sup> biosynthesis via transamidation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Aminoacyl-tRNAs-Charging|PWY-5921',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aminoacyl-tRNA Charging|glutaminyl-tRNA<sup>gln</sup> biosynthesis via transamidation',
    },
    {
        BioCyc_ID: 'P341-PWY',
        Common_Name: 'glycolysis V (Pyrococcus)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYCOLYSIS-VARIANTS|P341-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Glycolysis|glycolysis V (Pyrococcus)',
    },
    {
        BioCyc_ID: 'PWY-8141',
        Common_Name: '9-decynoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Acetlylenic-Fatty-Acid-Biosynthesis|PWY-8141',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Fatty Acid Containing acetylenic bond Biosynthesis|9-decynoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6462',
        Common_Name: 'peptidoglycan cross-bridge biosynthesis III (<i>Enterococcus faecalis</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Cross-Bridge-Biosynthesis|PWY-6462',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Cross-Bridge Biosynthesis|peptidoglycan cross-bridge biosynthesis III (<i>Enterococcus faecalis</i>)',
    },
    {
        BioCyc_ID: 'PWY3O-4106',
        Common_Name: 'NAD salvage pathway IV (from nicotinamide riboside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PWY3O-4106',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD salvage pathway IV (from nicotinamide riboside)',
    },
    {
        BioCyc_ID: 'PWY3O-4106',
        Common_Name: 'NAD salvage pathway IV (from nicotinamide riboside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY3O-4106',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway IV (from nicotinamide riboside)',
    },
    {
        BioCyc_ID: 'PWY3O-4106',
        Common_Name: 'NAD salvage pathway IV (from nicotinamide riboside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY3O-4106',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway IV (from nicotinamide riboside)',
    },
    {
        BioCyc_ID: 'DTDPRHAMSYN-PWY',
        Common_Name: 'dTDP-&beta;-L-rhamnose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|DTDPRHAMSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&beta;-L-rhamnose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3821',
        Common_Name: 'D-galactose detoxification',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-3821',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|D-galactose detoxification',
    },
    {
        BioCyc_ID: 'PWY-7402',
        Common_Name: 'benzoate fermentation (to acetate and cyclohexane carboxylate)',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-7402',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|benzoate fermentation (to acetate and cyclohexane carboxylate)',
    },
    {
        BioCyc_ID: 'PWY-7402',
        Common_Name: 'benzoate fermentation (to acetate and cyclohexane carboxylate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Fermentation|PWY-7402',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Fermentation|benzoate fermentation (to acetate and cyclohexane carboxylate)',
    },
    {
        BioCyc_ID: 'PWY-7402',
        Common_Name: 'benzoate fermentation (to acetate and cyclohexane carboxylate)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7402',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|benzoate fermentation (to acetate and cyclohexane carboxylate)',
    },
    {
        BioCyc_ID: 'PWY0-501-1',
        Common_Name: 'lipoate biosynthesis and incorporation V (mammals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|Lipoate-Biosynthesis|PWY0-501-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Lipoate Biosynthesis and Salvage|lipoate biosynthesis and incorporation V (mammals)',
    },
    {
        BioCyc_ID: 'PWY-6109',
        Common_Name: 'mangrove triterpenoid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-6109',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|mangrove triterpenoid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8264',
        Common_Name: 'arsenate detoxification I',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Arsenate-Detoxification|PWY-8264',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Arsenate Detoxification|arsenate detoxification I',
    },
    {
        BioCyc_ID: 'PWY-6019',
        Common_Name: 'pseudouridine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|PWY-6019',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|pseudouridine degradation',
    },
    {
        BioCyc_ID: 'PWY-6941',
        Common_Name: 'styrene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-6941',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|styrene degradation',
    },
    {
        BioCyc_ID: 'PWY-8401',
        Common_Name: 'superpathway of cardiolipin biosynthesis (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|Cardiolipin-Biosynthesis|PWY-8401',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|Cardiolipin Biosynthesis|superpathway of cardiolipin biosynthesis (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-8401',
        Common_Name: 'superpathway of cardiolipin biosynthesis (bacteria)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-8401',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of cardiolipin biosynthesis (bacteria)',
    },
    {
        BioCyc_ID: 'TRNA-CHARGING-PWY',
        Common_Name: 'tRNA charging',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Aminoacyl-tRNAs-Charging|TRNA-CHARGING-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aminoacyl-tRNA Charging|tRNA charging',
    },
    {
        BioCyc_ID: 'TRNA-CHARGING-PWY',
        Common_Name: 'tRNA charging',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|TRNA-CHARGING-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|tRNA charging',
    },
    {
        BioCyc_ID: 'PWY-8135',
        Common_Name: 'bile acids deconjugation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|PWY-8135',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|bile acids deconjugation',
    },
    {
        BioCyc_ID: 'PWY-8404',
        Common_Name: 'glycolysis VI (from fructose)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYCOLYSIS-VARIANTS|PWY-8404',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Glycolysis|glycolysis VI (from fructose)',
    },
    {
        BioCyc_ID: 'PWY-7593',
        Common_Name: 'linoleate biosynthesis III (cyanobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Linoleate-Biosynthesis|PWY-7593',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Linoleate Biosynthesis|linoleate biosynthesis III (cyanobacteria)',
    },
    {
        BioCyc_ID: 'PWY-6461',
        Common_Name: 'peptidoglycan cross-bridge biosynthesis II (<i>E. faecium</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Cross-Bridge-Biosynthesis|PWY-6461',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Cross-Bridge Biosynthesis|peptidoglycan cross-bridge biosynthesis II (<i>E. faecium</i>)',
    },
    {
        BioCyc_ID: 'PYRIDNUCSYN-PWY',
        Common_Name: 'NAD <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PYRIDNUCSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PYRIDNUCSYN-PWY',
        Common_Name: 'NAD <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PYRIDNUCSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PYRIDNUCSYN-PWY',
        Common_Name: 'NAD <i>de novo</i> biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PYRIDNUCSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6953',
        Common_Name: 'dTDP-3-acetamido-&alpha;-D-fucose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-6953',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-3-acetamido-&alpha;-D-fucose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6646',
        Common_Name: 'fluoroacetate degradation',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-6646',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|fluoroacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-8434',
        Common_Name: 'cucurbitacin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-8434',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|cucurbitacin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6160',
        Common_Name: '3-dehydroquinate biosynthesis II (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|3-Dehydroquinate-Biosynthesis|PWY-6160',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|3-Dehydroquinate Biosynthesis|3-dehydroquinate biosynthesis II (archaea)',
    },
    {
        BioCyc_ID: 'P441-PWY',
        Common_Name: 'superpathway of <i>N</i>-acetylneuraminate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|P441-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|superpathway of <i>N</i>-acetylneuraminate degradation',
    },
    {
        BioCyc_ID: 'P441-PWY',
        Common_Name: 'superpathway of <i>N</i>-acetylneuraminate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|P441-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of <i>N</i>-acetylneuraminate degradation',
    },
    {
        BioCyc_ID: 'PWY-8263',
        Common_Name: 'arsenate detoxification III',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Arsenate-Detoxification|PWY-8263',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Arsenate Detoxification|arsenate detoxification III',
    },
    {
        BioCyc_ID: 'PWY-7179-1',
        Common_Name: 'purine deoxyribonucleosides degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|PWY-7179-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|purine deoxyribonucleosides degradation II',
    },
    {
        BioCyc_ID: 'PWY-7747',
        Common_Name: 'diphenyl ethers degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|PWY-7747',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|diphenyl ethers degradation',
    },
    {
        BioCyc_ID: 'PWY18C3-12',
        Common_Name: 'superpathway of acylsucrose biosynthesis (cultivated tomato)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|SUGAR-DERIVS|ACYLSUGAR-BIOSYNTHESIS|PWY18C3-12',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Sugar Derivative Biosynthesis|Acylsugar biosynthesis|superpathway of acylsucrose biosynthesis (cultivated tomato)',
    },
    {
        BioCyc_ID: 'PWY18C3-12',
        Common_Name: 'superpathway of acylsucrose biosynthesis (cultivated tomato)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY18C3-12',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of acylsucrose biosynthesis (cultivated tomato)',
    },
    {
        BioCyc_ID: 'PWY490-4',
        Common_Name: 'L-asparagine biosynthesis III (tRNA-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ASPARAGINE-SYN|PWY490-4',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-asparagine Biosynthesis|L-asparagine biosynthesis III (tRNA-dependent)',
    },
    {
        BioCyc_ID: 'PWY490-4',
        Common_Name: 'L-asparagine biosynthesis III (tRNA-dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Aminoacyl-tRNAs-Charging|PWY490-4',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Aminoacyl-tRNA Charging|L-asparagine biosynthesis III (tRNA-dependent)',
    },
    {
        BioCyc_ID: 'PWY-6948',
        Common_Name: 'sitosterol degradation to androstenedione',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|PWY-6948',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|sitosterol degradation to androstenedione',
    },
    {
        BioCyc_ID: 'PWY-8363',
        Common_Name: 'photorespiration III',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photorespiration|PWY-8363',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photorespiration|photorespiration III',
    },
    {
        BioCyc_ID: 'PWY-6001',
        Common_Name: 'linoleate biosynthesis II (animals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Linoleate-Biosynthesis|PWY-6001',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Linoleate Biosynthesis|linoleate biosynthesis II (animals)',
    },
    {
        BioCyc_ID: 'PWY0-1586',
        Common_Name: 'peptidoglycan maturation (<i>meso</i>-diaminopimelate containing)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cell-Structure-Biosynthesis|Cell-Wall-Biosynthesis|Peptidoglycan-Biosynthesis|PWY0-1586',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cell Structure Biosynthesis|Cell Wall Biosynthesis|Peptidoglycan Biosynthesis|peptidoglycan maturation (<i>meso</i>-diaminopimelate containing)',
    },
    {
        BioCyc_ID: 'P483-PWY',
        Common_Name: 'phosphonoacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|P483-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|phosphonoacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-5653',
        Common_Name: 'NAD biosynthesis from 2-amino-3-carboxymuconate semialdehyde',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PWY-5653',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD biosynthesis from 2-amino-3-carboxymuconate semialdehyde',
    },
    {
        BioCyc_ID: 'PWY-5653',
        Common_Name: 'NAD biosynthesis from 2-amino-3-carboxymuconate semialdehyde',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-5653',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD biosynthesis from 2-amino-3-carboxymuconate semialdehyde',
    },
    {
        BioCyc_ID: 'PWY-5653',
        Common_Name: 'NAD biosynthesis from 2-amino-3-carboxymuconate semialdehyde',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-5653',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD biosynthesis from 2-amino-3-carboxymuconate semialdehyde',
    },
    {
        BioCyc_ID: 'PWY-7657',
        Common_Name: 'dTDP-&beta;-L-digitoxose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7657',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&beta;-L-digitoxose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6841',
        Common_Name: 'homophytochelatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-6841',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|homophytochelatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5377',
        Common_Name: '&alpha;-amyrin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5377',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|&alpha;-amyrin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6164',
        Common_Name: '3-dehydroquinate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|3-Dehydroquinate-Biosynthesis|PWY-6164',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|3-Dehydroquinate Biosynthesis|3-dehydroquinate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY0-1313',
        Common_Name: 'acetate conversion to acetyl-CoA',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY0-1313',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|acetate conversion to acetyl-CoA',
    },
    {
        BioCyc_ID: 'PWY-8261',
        Common_Name: 'roxarsone degradation II',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Roxarsone-Degradation|PWY-8261',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Roxarsone Degradation|roxarsone degradation II',
    },
    {
        BioCyc_ID: 'PWY-5695',
        Common_Name: "inosine 5'-phosphate degradation",
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|PWY-5695',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|inosine 5'-phosphate degradation",
    },
    {
        BioCyc_ID: 'SO4ASSIM-PWY',
        Common_Name: 'assimilatory sulfate reduction I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Assimilatory-Sulfate-Reduction|SO4ASSIM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Assimilatory Sulfate Reduction|assimilatory sulfate reduction I',
    },
    {
        BioCyc_ID: 'SO4ASSIM-PWY',
        Common_Name: 'assimilatory sulfate reduction I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|SO4ASSIM-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|assimilatory sulfate reduction I',
    },
    {
        BioCyc_ID: 'PWY-6943',
        Common_Name: 'testosterone and androsterone degradation to androstendione (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|PWY-6943',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|testosterone and androsterone degradation to androstendione (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-7052',
        Common_Name: 'cyanophycin metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Storage-Compounds-Biosynthesis|PWY-7052',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Storage Compound Biosynthesis|cyanophycin metabolism',
    },
    {
        BioCyc_ID: 'CALVIN-PWY',
        Common_Name: 'Calvin-Benson-Bassham cycle',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|CALVIN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|Calvin-Benson-Bassham cycle',
    },
    {
        BioCyc_ID: 'CALVIN-PWY',
        Common_Name: 'Calvin-Benson-Bassham cycle',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|CALVIN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Calvin-Benson-Bassham cycle',
    },
    {
        BioCyc_ID: 'PWY-8362',
        Common_Name: 'photorespiration II',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photorespiration|PWY-8362',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photorespiration|photorespiration II',
    },
    {
        BioCyc_ID: 'PWY-5995',
        Common_Name: 'linoleate biosynthesis I (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Linoleate-Biosynthesis|PWY-5995',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Linoleate Biosynthesis|linoleate biosynthesis I (plants)',
    },
    {
        BioCyc_ID: 'PWY-8124',
        Common_Name: '(2-trimethylamino)ethylphosphonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|PWY-8124',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|(2-trimethylamino)ethylphosphonate degradation',
    },
    {
        BioCyc_ID: 'PWY-8352',
        Common_Name: 'NAD <i>de novo</i> biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PWY-8352',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8352',
        Common_Name: 'NAD <i>de novo</i> biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-8352',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-8352',
        Common_Name: 'NAD <i>de novo</i> biosynthesis III',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-8352',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis III',
    },
    {
        BioCyc_ID: 'PWY-6976',
        Common_Name: 'dTDP-&beta;-L-mycarose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-6976',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&beta;-L-mycarose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6997',
        Common_Name: 'furfural degradation',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-6997',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|furfural degradation',
    },
    {
        BioCyc_ID: 'PWY-7067',
        Common_Name: 'betulinate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7067',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|betulinate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8018',
        Common_Name: 'itaconate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Itaconate-Biosynthesis|PWY-8018',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Itaconate Biosynthesis|itaconate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5074',
        Common_Name: 'mevalonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-5074',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|mevalonate degradation',
    },
    {
        BioCyc_ID: 'PWY-8260',
        Common_Name: 'roxarsone degradation I',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Roxarsone-Degradation|PWY-8260',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Roxarsone Degradation|roxarsone degradation I',
    },
    {
        BioCyc_ID: 'PWY-7179',
        Common_Name: 'purine deoxyribonucleosides degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|PWY-7179',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|purine deoxyribonucleosides degradation I',
    },
    {
        BioCyc_ID: 'ARGSYN-PWY',
        Common_Name: 'L-arginine biosynthesis I (via L-ornithine)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ARGININE-SYN|ARGSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-arginine Biosynthesis|L-arginine biosynthesis I (via L-ornithine)',
    },
    {
        BioCyc_ID: 'ARGSYN-PWY',
        Common_Name: 'L-arginine biosynthesis I (via L-ornithine)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|ARGSYN-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|L-arginine biosynthesis I (via L-ornithine)',
    },
    {
        BioCyc_ID: 'PWY-8155',
        Common_Name: 'testosterone degradation (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|PWY-8155',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|testosterone degradation (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY1-3',
        Common_Name: 'polyhydroxybutanoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Storage-Compounds-Biosynthesis|PWY1-3',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Storage Compound Biosynthesis|polyhydroxybutanoate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8317',
        Common_Name: 'cyclobis-(1&rarr;6)-&alpha;-nigerosyl biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Oligosaccharides-Biosynthesis|PWY-8317',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Oligosaccharide Biosynthesis|cyclobis-(1&rarr;6)-&alpha;-nigerosyl biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-181',
        Common_Name: 'photorespiration I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Photorespiration|PWY-181',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Photorespiration|photorespiration I',
    },
    {
        BioCyc_ID: 'PWY-6282',
        Common_Name: 'palmitoleate biosynthesis I (from (5Z)-dodec-5-enoate)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Palmitoleate-Biosynthesis|PWY-6282',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Palmitoleate Biosynthesis|palmitoleate biosynthesis I (from (5Z)-dodec-5-enoate)',
    },
    {
        BioCyc_ID: 'PWY-7805',
        Common_Name: '(aminomethyl)phosphonate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|PWY-7805',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|(aminomethyl)phosphonate degradation',
    },
    {
        BioCyc_ID: 'PYRIDNUCSAL-PWY',
        Common_Name: 'NAD salvage pathway I (PNC VI cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PYRIDNUCSAL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD salvage pathway I (PNC VI cycle)',
    },
    {
        BioCyc_ID: 'PYRIDNUCSAL-PWY',
        Common_Name: 'NAD salvage pathway I (PNC VI cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PYRIDNUCSAL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway I (PNC VI cycle)',
    },
    {
        BioCyc_ID: 'PYRIDNUCSAL-PWY',
        Common_Name: 'NAD salvage pathway I (PNC VI cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PYRIDNUCSAL-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway I (PNC VI cycle)',
    },
    {
        BioCyc_ID: 'PWY-8380',
        Common_Name: 'dTDP-sibirosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-8380',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-sibirosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5756',
        Common_Name: 'saponin biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5756',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|saponin biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY1G-1',
        Common_Name: 'mycothiol-mediated detoxification',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY1G-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|mycothiol-mediated detoxification',
    },
    {
        BioCyc_ID: 'PYRUVDEHYD-PWY',
        Common_Name: 'pyruvate decarboxylation to acetyl CoA I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Acetyl-CoA-Biosynthesis|PYRUVDEHYD-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Acetyl-CoA Biosynthesis|pyruvate decarboxylation to acetyl CoA I',
    },
    {
        BioCyc_ID: 'PYRUVDEHYD-PWY',
        Common_Name: 'pyruvate decarboxylation to acetyl CoA I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PYRUVDEHYD-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|pyruvate decarboxylation to acetyl CoA I',
    },
    {
        BioCyc_ID: 'PWY-5750',
        Common_Name: 'itaconate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Itaconate-Biosynthesis|PWY-5750',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Itaconate Biosynthesis|itaconate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8265',
        Common_Name: 'roxarsone (and nitarsone) degradation IV',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Roxarsone-Degradation|PWY-8265',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Roxarsone Degradation|roxarsone (and nitarsone) degradation IV',
    },
    {
        BioCyc_ID: 'PWY0-1296',
        Common_Name: 'purine ribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|PWY0-1296',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|purine ribonucleosides degradation',
    },
    {
        BioCyc_ID: 'FUC-RHAMCAT-PWY',
        Common_Name: 'superpathway of fucose and rhamnose degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|FUC-RHAMCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of fucose and rhamnose degradation',
    },
    {
        BioCyc_ID: 'PWY-6937',
        Common_Name: 'superpathway of testosterone and androsterone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|PWY-6937',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|superpathway of testosterone and androsterone degradation',
    },
    {
        BioCyc_ID: 'PWY-6937',
        Common_Name: 'superpathway of testosterone and androsterone degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6937',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of testosterone and androsterone degradation',
    },
    {
        BioCyc_ID: 'PWY-6657',
        Common_Name: 'polyhydroxydecanoate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Storage-Compounds-Biosynthesis|PWY-6657',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Storage Compound Biosynthesis|polyhydroxydecanoate biosynthesis',
    },
    {
        BioCyc_ID: 'TCA',
        Common_Name: 'TCA cycle I (prokaryotic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|TCA',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|TCA cycle I (prokaryotic)',
    },
    {
        BioCyc_ID: 'PWY-5366',
        Common_Name: 'palmitoleate biosynthesis II (plants and bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Palmitoleate-Biosynthesis|PWY-5366',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Palmitoleate Biosynthesis|palmitoleate biosynthesis II (plants and bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7447',
        Common_Name: '2-aminoethylphosphonate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|2-Aminoethylphosphonate-Degradation|PWY-7447',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|2-Aminoethylphosphonate Degradation|2-aminoethylphosphonate degradation III',
    },
    {
        BioCyc_ID: 'NAD-BIOSYNTHESIS-III',
        Common_Name: 'NAD biosynthesis from nicotinamide',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|NAD-BIOSYNTHESIS-III',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD biosynthesis from nicotinamide',
    },
    {
        BioCyc_ID: 'NAD-BIOSYNTHESIS-III',
        Common_Name: 'NAD biosynthesis from nicotinamide',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|NAD-BIOSYNTHESIS-III',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD biosynthesis from nicotinamide',
    },
    {
        BioCyc_ID: 'NAD-BIOSYNTHESIS-III',
        Common_Name: 'NAD biosynthesis from nicotinamide',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|NAD-BIOSYNTHESIS-III',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD biosynthesis from nicotinamide',
    },
    {
        BioCyc_ID: 'PWY-7312',
        Common_Name: 'dTDP-&beta;-D-fucofuranose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7312',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&beta;-D-fucofuranose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7072',
        Common_Name: 'hopanoid biosynthesis (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7072',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|hopanoid biosynthesis (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-6577',
        Common_Name: 'farnesylcysteine salvage pathway',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|All-Trans-Farnesyl-PP-Biosynthesis|PWY-6577',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|All-Trans-Farnesyl-PP-Biosynthesis|farnesylcysteine salvage pathway',
    },
    {
        BioCyc_ID: 'PWY-6577',
        Common_Name: 'farnesylcysteine salvage pathway',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-6577',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|farnesylcysteine salvage pathway',
    },
    {
        BioCyc_ID: 'PWY-5652',
        Common_Name: '2-amino-3-carboxymuconate semialdehyde degradation to glutaryl-CoA',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|PWY-5652',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|2-amino-3-carboxymuconate semialdehyde degradation to glutaryl-CoA',
    },
    {
        BioCyc_ID: 'PWY-6163',
        Common_Name: 'chorismate biosynthesis from 3-dehydroquinate',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carboxylate-Biosynthesis|Chorismate-Biosynthesis|PWY-6163',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carboxylic Acid Biosynthesis|Chorismate Biosynthesis|chorismate biosynthesis from 3-dehydroquinate',
    },
    {
        BioCyc_ID: 'PWY-8262',
        Common_Name: 'roxarsone (and nitarsone) degradation III',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Roxarsone-Degradation|PWY-8262',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Roxarsone Degradation|roxarsone (and nitarsone) degradation III',
    },
    {
        BioCyc_ID: 'PWY-6756',
        Common_Name: "<i>S</i>-methyl-5'-thioadenosine degradation II",
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Methylthioadenosine-Degradation|PWY-6756',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|<i>S</i>-methyl-5'-thioadenosine Degradation|<i>S</i>-methyl-5'-thioadenosine degradation II",
    },
    {
        BioCyc_ID: 'PWY-1782',
        Common_Name: 'superpathway of indole-3-acetate conjugate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-1782',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|superpathway of indole-3-acetate conjugate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1782',
        Common_Name: 'superpathway of indole-3-acetate conjugate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-1782',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of indole-3-acetate conjugate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6518',
        Common_Name: 'bile acids epimerization',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Bile-Acids-Degradation|PWY-6518',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Bile Acid Degradation|bile acids epimerization',
    },
    {
        BioCyc_ID: 'PWY-6518',
        Common_Name: 'bile acids epimerization',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Bile-Acids-Degradation|PWY-6518',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Bile Acid Degradation|bile acids epimerization',
    },
    {
        BioCyc_ID: 'PWY-6518',
        Common_Name: 'bile acids epimerization',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6518',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|bile acids epimerization',
    },
    {
        BioCyc_ID: 'PWY-6518',
        Common_Name: 'bile acids epimerization',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6518',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|bile acids epimerization',
    },
    {
        BioCyc_ID: 'PWY-7218',
        Common_Name: 'photosynthetic 3-hydroxybutanoate biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Storage-Compounds-Biosynthesis|PWY-7218',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Storage Compound Biosynthesis|photosynthetic 3-hydroxybutanoate biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-7218',
        Common_Name: 'photosynthetic 3-hydroxybutanoate biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7218',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|photosynthetic 3-hydroxybutanoate biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'P105-PWY',
        Common_Name: 'TCA cycle IV (2-oxoglutarate decarboxylase)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|P105-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|TCA cycle IV (2-oxoglutarate decarboxylase)',
    },
    {
        BioCyc_ID: 'PWY3O-1801',
        Common_Name: 'palmitoleate biosynthesis IV (fungi and animals)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Palmitoleate-Biosynthesis|PWY3O-1801',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Palmitoleate Biosynthesis|palmitoleate biosynthesis IV (fungi and animals)',
    },
    {
        BioCyc_ID: 'PWY-6832',
        Common_Name: '2-aminoethylphosphonate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|2-Aminoethylphosphonate-Degradation|PWY-6832',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|2-Aminoethylphosphonate Degradation|2-aminoethylphosphonate degradation II',
    },
    {
        BioCyc_ID: 'PWY-5381',
        Common_Name: 'NAD salvage (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PWY-5381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD salvage (plants)',
    },
    {
        BioCyc_ID: 'PWY-5381',
        Common_Name: 'NAD salvage (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-5381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage (plants)',
    },
    {
        BioCyc_ID: 'PWY-5381',
        Common_Name: 'NAD salvage (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-5381',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage (plants)',
    },
    {
        BioCyc_ID: 'PWY-7414',
        Common_Name: 'dTDP-&alpha;-D-mycaminose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7414',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&alpha;-D-mycaminose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5875',
        Common_Name: 'staphyloxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C30-Carotenoids-Biosynthesis|PWY-5875',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>30</sub> Carotenoid Biosynthesis|staphyloxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5875',
        Common_Name: 'staphyloxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C30-Carotenoids-Biosynthesis|PWY-5875',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>30</sub> Carotenoid Biosynthesis|staphyloxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5875',
        Common_Name: 'staphyloxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|C30-Carotenoids-Biosynthesis|PWY-5875',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|C<sub>30</sub> Carotenoid Biosynthesis|staphyloxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5875',
        Common_Name: 'staphyloxanthin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5875',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|staphyloxanthin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6786',
        Common_Name: 'detoxification of reactive carbonyls in chloroplasts',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-6786',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|detoxification of reactive carbonyls in chloroplasts',
    },
    {
        BioCyc_ID: 'PWY-6786',
        Common_Name: 'detoxification of reactive carbonyls in chloroplasts',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6786',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|detoxification of reactive carbonyls in chloroplasts',
    },
    {
        BioCyc_ID: 'PWY1ZNC-1',
        Common_Name: 'assimilatory sulfate reduction IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Assimilatory-Sulfate-Reduction|PWY1ZNC-1',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Assimilatory Sulfate Reduction|assimilatory sulfate reduction IV',
    },
    {
        BioCyc_ID: 'PWY-8268',
        Common_Name: 'methylarsonous acid detoxification II',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Methylarsonite-Detoxification|PWY-8268',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Methylarsonous Acid Detoxification|methylarsonous acid detoxification II',
    },
    {
        BioCyc_ID: 'PWY-6754',
        Common_Name: "<i>S</i>-methyl-5'-thioadenosine degradation I",
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Methylthioadenosine-Degradation|PWY-6754',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|<i>S</i>-methyl-5'-thioadenosine Degradation|<i>S</i>-methyl-5'-thioadenosine degradation I",
    },
    {
        BioCyc_ID: 'PWY-5005',
        Common_Name: 'biotin biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|BIOTIN-SYN|PWY-5005',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|Biotin Biosynthesis|biotin biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5005',
        Common_Name: 'biotin biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5005',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|biotin biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7496',
        Common_Name: 'linuron degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-7496',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|linuron degradation',
    },
    {
        BioCyc_ID: 'PWY-7496',
        Common_Name: 'linuron degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-7496',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|linuron degradation',
    },
    {
        BioCyc_ID: 'PWY-7216',
        Common_Name: '(R)- and (S)-3-hydroxybutanoate biosynthesis (engineered)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Storage-Compounds-Biosynthesis|PWY-7216',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Storage Compound Biosynthesis|(R)- and (S)-3-hydroxybutanoate biosynthesis (engineered)',
    },
    {
        BioCyc_ID: 'PWY-8254',
        Common_Name: 'bile acids 7-O epimerization',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Bile-Acids-Degradation|PWY-8254',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Bile Acid Degradation|bile acids 7-O epimerization',
    },
    {
        BioCyc_ID: 'PWY-8254',
        Common_Name: 'bile acids 7-O epimerization',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Bile-Acids-Degradation|PWY-8254',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Bile Acid Degradation|bile acids 7-O epimerization',
    },
    {
        BioCyc_ID: 'PWY-3881',
        Common_Name: 'mannitol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Sugar-Alcohols-Biosynthesis|PWY-3881',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Alditol Biosynthesis|mannitol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6969',
        Common_Name: 'TCA cycle V (2-oxoglutarate synthase)',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2-Fixation|Autotrophic-CO2-Fixation|Reductive-TCA-Cycles|PWY-6969',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|CO<sub>2</sub> Fixation|Autotrophic CO<sub>2</sub> Fixation|Reductive TCA Cycles|TCA cycle V (2-oxoglutarate synthase)',
    },
    {
        BioCyc_ID: 'PWY-6969',
        Common_Name: 'TCA cycle V (2-oxoglutarate synthase)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|PWY-6969',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|TCA cycle V (2-oxoglutarate synthase)',
    },
    {
        BioCyc_ID: 'PWY-7589',
        Common_Name: 'palmitoleate biosynthesis III (cyanobacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Fatty-acid-biosynthesis|Unsaturated-Fatty-Acids-Biosynthesis|Palmitoleate-Biosynthesis|PWY-7589',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Fatty Acid Biosynthesis|Unsaturated Fatty Acid Biosynthesis|Palmitoleate Biosynthesis|palmitoleate biosynthesis III (cyanobacteria)',
    },
    {
        BioCyc_ID: 'PWY-6290',
        Common_Name: '&beta;-cubebene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6290',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|&beta;-cubebene biosynthesis',
    },
    {
        BioCyc_ID: 'PHOSPHONOTASE-PWY',
        Common_Name: '2-aminoethylphosphonate degradation I',
        MetaCyc_hierarchy_IDs:
            'Degradation|Noncarbon-Nutrients|Phosphorus-Compounds|Phophonate-Metabolism|Phosphonate-Degradation|2-Aminoethylphosphonate-Degradation|PHOSPHONOTASE-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Phosphorus Compound Metabolism|Phosphonate Metabolism|Phosphonate Compound Degradation|2-Aminoethylphosphonate Degradation|2-aminoethylphosphonate degradation I',
    },
    {
        BioCyc_ID: 'PWY-8277',
        Common_Name: 'NAD <i>de novo</i> biosynthesis IV (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PWY-8277',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis IV (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-8277',
        Common_Name: 'NAD <i>de novo</i> biosynthesis IV (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-8277',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis IV (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-8277',
        Common_Name: 'NAD <i>de novo</i> biosynthesis IV (anaerobic)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-8277',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD <i>de novo</i> biosynthesis IV (anaerobic)',
    },
    {
        BioCyc_ID: 'PWY-7474',
        Common_Name: 'avenacin A-2 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7474',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|avenacin A-2 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6942',
        Common_Name: 'dTDP-D-desosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-6942',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-D-desosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6930',
        Common_Name: 'phenolic malonylglucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-6930',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|phenolic malonylglucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6930',
        Common_Name: 'phenolic malonylglucosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Metabolic-Clusters|PWY-6930',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolic Clusters|phenolic malonylglucosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6683',
        Common_Name: 'assimilatory sulfate reduction III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Assimilatory-Sulfate-Reduction|PWY-6683',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Assimilatory Sulfate Reduction|assimilatory sulfate reduction III',
    },
    {
        BioCyc_ID: 'PWY-8267',
        Common_Name: 'methylarsonous acid detoxification I',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Methylarsonite-Detoxification|PWY-8267',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Methylarsonous Acid Detoxification|methylarsonous acid detoxification I',
    },
    {
        BioCyc_ID: 'PWY-6753',
        Common_Name: "<i>S</i>-methyl-5'-thioadenosine degradation III",
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Methylthioadenosine-Degradation|PWY-6753',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|<i>S</i>-methyl-5'-thioadenosine Degradation|<i>S</i>-methyl-5'-thioadenosine degradation III",
    },
    {
        BioCyc_ID: 'PWY-7823',
        Common_Name: 'chlorzoxazone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-7823',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|chlorzoxazone degradation',
    },
    {
        BioCyc_ID: 'PWY-7823',
        Common_Name: 'chlorzoxazone degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-7823',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|chlorzoxazone degradation',
    },
    {
        BioCyc_ID: 'PWY-8134',
        Common_Name: 'bile acid 7&beta;-dehydroxylation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Bile-Acids-Degradation|PWY-8134',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Bile Acid Degradation|bile acid 7&beta;-dehydroxylation',
    },
    {
        BioCyc_ID: 'PWY-8134',
        Common_Name: 'bile acid 7&beta;-dehydroxylation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Bile-Acids-Degradation|PWY-8134',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Bile Acid Degradation|bile acid 7&beta;-dehydroxylation',
    },
    {
        BioCyc_ID: 'PWY-5328',
        Common_Name: 'superpathway of L-methionine salvage and degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Amino-Acid-Degradation|Proteinogenic-Amino-Acids-Degradation|METHIONINE-DEG|PWY-5328',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amino Acid Degradation|Proteinogenic Amino Acid Degradation|L-methionine Degradation|superpathway of L-methionine salvage and degradation',
    },
    {
        BioCyc_ID: 'PWY-5328',
        Common_Name: 'superpathway of L-methionine salvage and degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5328',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-methionine salvage and degradation',
    },
    {
        BioCyc_ID: 'REDCITCYC',
        Common_Name: 'TCA cycle VI (Helicobacter)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|REDCITCYC',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|TCA cycle VI (Helicobacter)',
    },
    {
        BioCyc_ID: 'PWY-4502',
        Common_Name: 'wighteone and luteone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-4502',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|wighteone and luteone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4502',
        Common_Name: 'wighteone and luteone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-4502',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|wighteone and luteone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2961',
        Common_Name: 'sesquiterpenoid phytoalexins biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-2961',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|sesquiterpenoid phytoalexins biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2961',
        Common_Name: 'sesquiterpenoid phytoalexins biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-2961',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|sesquiterpenoid phytoalexins biosynthesis',
    },
    {
        BioCyc_ID: 'PWY3O-4107',
        Common_Name: 'NAD salvage pathway V (PNC V cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PWY3O-4107',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD salvage pathway V (PNC V cycle)',
    },
    {
        BioCyc_ID: 'PWY3O-4107',
        Common_Name: 'NAD salvage pathway V (PNC V cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY3O-4107',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway V (PNC V cycle)',
    },
    {
        BioCyc_ID: 'PWY3O-4107',
        Common_Name: 'NAD salvage pathway V (PNC V cycle)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY3O-4107',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway V (PNC V cycle)',
    },
    {
        BioCyc_ID: 'PWY-6008',
        Common_Name: 'baruol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-6008',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|baruol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7440',
        Common_Name: 'dTDP-&beta;-L-4-<i>epi</i>-vancosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7440',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&beta;-L-4-<i>epi</i>-vancosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4061',
        Common_Name: 'glutathione-mediated detoxification I',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-4061',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|glutathione-mediated detoxification I',
    },
    {
        BioCyc_ID: 'PWY-4061',
        Common_Name: 'glutathione-mediated detoxification I',
        MetaCyc_hierarchy_IDs: 'Degradation|Other-Degradation|PWY-4061',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Degradation/Utilization/Assimilation|glutathione-mediated detoxification I',
    },
    {
        BioCyc_ID: 'PWY-7242',
        Common_Name: 'D-fructuronate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|PWY-7242',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-fructuronate degradation',
    },
    {
        BioCyc_ID: 'PWY-7242',
        Common_Name: 'D-fructuronate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|PWY-7242',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-fructuronate degradation',
    },
    {
        BioCyc_ID: 'SULFMETII-PWY',
        Common_Name: 'assimilatory sulfate reduction II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Assimilatory-Sulfate-Reduction|SULFMETII-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Assimilatory Sulfate Reduction|assimilatory sulfate reduction II',
    },
    {
        BioCyc_ID: 'PWY-8269',
        Common_Name: 'methylarsonous acid detoxification III',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|Methylarsonite-Detoxification|PWY-8269',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|Methylarsonous Acid Detoxification|methylarsonous acid detoxification III',
    },
    {
        BioCyc_ID: 'PWY0-1391',
        Common_Name: "<i>S</i>-methyl-5'-thioadenosine degradation IV",
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Methylthioadenosine-Degradation|PWY0-1391',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|<i>S</i>-methyl-5'-thioadenosine Degradation|<i>S</i>-methyl-5'-thioadenosine degradation IV",
    },
    {
        BioCyc_ID: 'PWY-5478',
        Common_Name: 'superpathway of hydrolyzable tannin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5478',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of hydrolyzable tannin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5478',
        Common_Name: 'superpathway of hydrolyzable tannin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|TANNIN-SYN|PWY-5478',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Hydrolyzable Tannin Biosynthesis|superpathway of hydrolyzable tannin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6085',
        Common_Name: '2,4-dichlorophenoxyacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-6085',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|2,4-dichlorophenoxyacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-6085',
        Common_Name: '2,4-dichlorophenoxyacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-6085',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|2,4-dichlorophenoxyacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-8256',
        Common_Name: 'bile acids 12-O-epimerization',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Bile-Acids-Degradation|PWY-8256',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Bile Acid Degradation|bile acids 12-O-epimerization',
    },
    {
        BioCyc_ID: 'PWY-8256',
        Common_Name: 'bile acids 12-O-epimerization',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Bile-Acids-Degradation|PWY-8256',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Bile Acid Degradation|bile acids 12-O-epimerization',
    },
    {
        BioCyc_ID: 'PWY-5913',
        Common_Name: 'partial TCA cycle (obligate autotrophs)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|PWY-5913',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|partial TCA cycle (obligate autotrophs)',
    },
    {
        BioCyc_ID: 'PWY-2321',
        Common_Name: 'formononetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|formononetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2321',
        Common_Name: 'formononetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2321',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|formononetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6669',
        Common_Name: '&delta;-guaiene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6669',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|&delta;-guaiene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7428',
        Common_Name: '2-chloroacrylate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|2-Chloroacrylates-Degradation|PWY-7428',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|2-Chloroacrylate-Degradation|2-chloroacrylate degradation II',
    },
    {
        BioCyc_ID: 'NAD-BIOSYNTHESIS-II',
        Common_Name: 'NAD salvage pathway III (to nicotinamide riboside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|NAD-BIOSYNTHESIS-II',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|NAD salvage pathway III (to nicotinamide riboside)',
    },
    {
        BioCyc_ID: 'NAD-BIOSYNTHESIS-II',
        Common_Name: 'NAD salvage pathway III (to nicotinamide riboside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|NAD-BIOSYNTHESIS-II',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway III (to nicotinamide riboside)',
    },
    {
        BioCyc_ID: 'NAD-BIOSYNTHESIS-II',
        Common_Name: 'NAD salvage pathway III (to nicotinamide riboside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|NAD-BIOSYNTHESIS-II',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|NAD salvage pathway III (to nicotinamide riboside)',
    },
    {
        BioCyc_ID: 'PWY-6105',
        Common_Name: 'botryococcenes and methylated squalene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-6105',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|botryococcenes and methylated squalene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6974',
        Common_Name: 'dTDP-&beta;-L-olivose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-6974',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-&beta;-L-olivose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6689',
        Common_Name: 'tRNA splicing I',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|tRNA-Splicing|PWY-6689',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA Splicing|tRNA splicing I',
    },
    {
        BioCyc_ID: 'PWY-6516',
        Common_Name: 'superpathway of microbial D-galacturonate and D-glucuronate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|PWY-6516',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|superpathway of microbial D-galacturonate and D-glucuronate degradation',
    },
    {
        BioCyc_ID: 'PWY-6516',
        Common_Name: 'superpathway of microbial D-galacturonate and D-glucuronate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|PWY-6516',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|superpathway of microbial D-galacturonate and D-glucuronate degradation',
    },
    {
        BioCyc_ID: 'PWY-6516',
        Common_Name: 'superpathway of microbial D-galacturonate and D-glucuronate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6516',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of microbial D-galacturonate and D-glucuronate degradation',
    },
    {
        BioCyc_ID: 'PWY-5359',
        Common_Name: 'tetrathionate reductiuon II (to trithionate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Tetrathionate-Reduction|PWY-5359',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Tetrathionate Reduction|tetrathionate reductiuon II (to trithionate)',
    },
    {
        BioCyc_ID: 'PWY-6745',
        Common_Name: 'phytochelatins biosynthesis',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-6745',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|phytochelatins biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6668',
        Common_Name: '(<i>E,E</i>)-4,8,12-trimethyltrideca-1,3,7,11-tetraene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|PWY-6668',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|(<i>E,E</i>)-4,8,12-trimethyltrideca-1,3,7,11-tetraene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6107',
        Common_Name: 'chlorosalicylate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-6107',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|chlorosalicylate degradation',
    },
    {
        BioCyc_ID: 'PWY-6107',
        Common_Name: 'chlorosalicylate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-6107',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|chlorosalicylate degradation',
    },
    {
        BioCyc_ID: 'PWY-7754',
        Common_Name: 'bile acid 7&alpha;-dehydroxylation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Bile-Acids-Degradation|PWY-7754',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Bile Acid Degradation|bile acid 7&alpha;-dehydroxylation',
    },
    {
        BioCyc_ID: 'PWY-7754',
        Common_Name: 'bile acid 7&alpha;-dehydroxylation',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Bile-Acids-Degradation|PWY-7754',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Bile Acid Degradation|bile acid 7&alpha;-dehydroxylation',
    },
    {
        BioCyc_ID: 'PWY-5705',
        Common_Name: 'allantoin degradation to glyoxylate III',
        MetaCyc_hierarchy_IDs: 'Degradation|AMINE-DEG|Allantoin-degradation|PWY-5705',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Amide, Amidine, Amine, and Polyamine Degradation|Allantoin Degradation|allantoin degradation to glyoxylate III',
    },
    {
        BioCyc_ID: 'PWY-5705',
        Common_Name: 'allantoin degradation to glyoxylate III',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5705',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|allantoin degradation to glyoxylate III',
    },
    {
        BioCyc_ID: 'PWY-5817',
        Common_Name: 'dodecaprenyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-5817',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|dodecaprenyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY66-398',
        Common_Name: 'TCA cycle III (animals)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|PWY66-398',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|TCA cycle III (animals)',
    },
    {
        BioCyc_ID: 'PWY-2463',
        Common_Name: '(-)-medicarpin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2463',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|(-)-medicarpin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2463',
        Common_Name: '(-)-medicarpin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2463',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|(-)-medicarpin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5733',
        Common_Name: 'germacrene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-5733',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|germacrene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7425',
        Common_Name: '2-chloroacrylate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|2-Chloroacrylates-Degradation|PWY-7425',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|2-Chloroacrylate-Degradation|2-chloroacrylate degradation I',
    },
    {
        BioCyc_ID: 'PANTO-PWY',
        Common_Name: 'phosphopantothenate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|Pantothenate-Biosynthesis|PANTO-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|Phosphopantothenate Biosynthesis|phosphopantothenate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-7814',
        Common_Name: 'dTDP-L-daunosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7814',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-L-daunosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5203',
        Common_Name: 'soybean saponin I biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5203',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|soybean saponin I biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7803',
        Common_Name: 'tRNA splicing II',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|tRNA-Splicing|PWY-7803',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|tRNA Splicing|tRNA splicing II',
    },
    {
        BioCyc_ID: 'PWY-6052',
        Common_Name: 'dimethylsulfoniopropanoate degradation III (demethylation)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Dimethylsulfoniopropionate-Degradation|PWY-6052',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Dimethylsulfoniopropanoate Degradation|dimethylsulfoniopropanoate degradation III (demethylation)',
    },
    {
        BioCyc_ID: 'PWY-7178',
        Common_Name: 'D-xylose degradation to ethylene glycol (engineered)',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Xylose-Degradation|PWY-7178',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-xylose Degradation|D-xylose degradation to ethylene glycol (engineered)',
    },
    {
        BioCyc_ID: 'PWY-6842',
        Common_Name: 'glutathione-mediated detoxification II',
        MetaCyc_hierarchy_IDs: 'Detoxification|PWY-6842',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|glutathione-mediated detoxification II',
    },
    {
        BioCyc_ID: 'PWY-7141',
        Common_Name: '(3<i>S</i>)-linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-7141',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|(3<i>S</i>)-linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7141',
        Common_Name: '(3<i>S</i>)-linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|Linalool-Biosynthesis|PWY-7141',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|Linalool Biosynthesis|(3<i>S</i>)-linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7141',
        Common_Name: '(3<i>S</i>)-linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|Linalool-Biosynthesis|PWY-7141',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Linalool Biosynthesis|(3<i>S</i>)-linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6142',
        Common_Name: 'gluconeogenesis II (<i>Methanobacterium thermoautotrophicum</i>)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|Gluconeogenesis|PWY-6142',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Gluconeogenesis|gluconeogenesis II (<i>Methanobacterium thermoautotrophicum</i>)',
    },
    {
        BioCyc_ID: 'PWY-6142',
        Common_Name: 'gluconeogenesis II (<i>Methanobacterium thermoautotrophicum</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6142',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|gluconeogenesis II (<i>Methanobacterium thermoautotrophicum</i>)',
    },
    {
        BioCyc_ID: 'PWY-6200',
        Common_Name: '2,4,5-trichlorophenoxyacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-6200',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|2,4,5-trichlorophenoxyacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-6200',
        Common_Name: '2,4,5-trichlorophenoxyacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-6200',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|2,4,5-trichlorophenoxyacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-8255',
        Common_Name: 'bile acids 3-O-epimerization',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Bile-Acids-Degradation|PWY-8255',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Bile Acid Degradation|bile acids 3-O-epimerization',
    },
    {
        BioCyc_ID: 'PWY-8255',
        Common_Name: 'bile acids 3-O-epimerization',
        MetaCyc_hierarchy_IDs: 'Degradation|Fatty-Acid-and-Lipid-Degradation|Steroids-Degradation|Bile-Acids-Degradation|PWY-8255',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Fatty Acid and Lipid Degradation|Steroid Degradation|Bile Acid Degradation|bile acids 3-O-epimerization',
    },
    {
        BioCyc_ID: 'HEXPPSYN-PWY',
        Common_Name: 'hexaprenyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|HEXPPSYN-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|hexaprenyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'TCA-1',
        Common_Name: 'TCA cycle VIII (Chlamydia)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|TCA-1',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|TCA cycle VIII (Chlamydia)',
    },
    {
        BioCyc_ID: 'PWY-2762',
        Common_Name: 'glyceollin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2762',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|glyceollin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2762',
        Common_Name: 'glyceollin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2762',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|glyceollin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5828',
        Common_Name: 'lacinilene C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-5828',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|lacinilene C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6654',
        Common_Name: 'phosphopantothenate biosynthesis III (archaea)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|Pantothenate-Biosynthesis|PWY-6654',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|Phosphopantothenate Biosynthesis|phosphopantothenate biosynthesis III (archaea)',
    },
    {
        BioCyc_ID: 'PWY-7301',
        Common_Name: 'dTDP-4-<i>O</i>-demethyl-&beta;-L-noviose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7301',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-4-<i>O</i>-demethyl-&beta;-L-noviose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7066',
        Common_Name: 'glycyrrhetinate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7066',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|glycyrrhetinate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8105',
        Common_Name: 'queuosine biosynthesis II (queuine salvage)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|Queuosine-Biosynthesis|PWY-8105',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|Queuosine Biosynthesis and Salvage|queuosine biosynthesis II (queuine salvage)',
    },
    {
        BioCyc_ID: 'PWY-6046',
        Common_Name: 'dimethylsulfoniopropanoate degradation I (cleavage)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Dimethylsulfoniopropionate-Degradation|PWY-6046',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Dimethylsulfoniopropanoate Degradation|dimethylsulfoniopropanoate degradation I (cleavage)',
    },
    {
        BioCyc_ID: 'PWY-8330',
        Common_Name: 'D-xylose degradation VI',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Xylose-Degradation|PWY-8330',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-xylose Degradation|D-xylose degradation VI',
    },
    {
        BioCyc_ID: 'PWY-7910',
        Common_Name: 'homofuraneol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-7910',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|homofuraneol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6293',
        Common_Name: 'superpathway of L-cysteine biosynthesis (fungi)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|CYSTEINE-SYN|PWY-6293',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-cysteine Biosynthesis|superpathway of L-cysteine biosynthesis (fungi)',
    },
    {
        BioCyc_ID: 'PWY-6293',
        Common_Name: 'superpathway of L-cysteine biosynthesis (fungi)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6293',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-cysteine biosynthesis (fungi)',
    },
    {
        BioCyc_ID: 'PWY-7771',
        Common_Name: 'butachlor degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-7771',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|butachlor degradation',
    },
    {
        BioCyc_ID: 'PWY-7771',
        Common_Name: 'butachlor degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-7771',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|butachlor degradation',
    },
    {
        BioCyc_ID: 'PWY-7685',
        Common_Name: 'L-malate degradation I',
        MetaCyc_hierarchy_IDs: 'Detoxification|Acid-Resistance|PWY-7685',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Acid Resistance|L-malate degradation I',
    },
    {
        BioCyc_ID: 'PWY-7685',
        Common_Name: 'L-malate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|L-Malate-Degradation|PWY-7685',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|(<i>S</i>)-Malate Degradation|L-malate degradation I',
    },
    {
        BioCyc_ID: 'PWY-5805',
        Common_Name: 'nonaprenyl diphosphate biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-5805',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|nonaprenyl diphosphate biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5690',
        Common_Name: 'TCA cycle II (plants and fungi)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|PWY-5690',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|TCA cycle II (plants and fungi)',
    },
    {
        BioCyc_ID: 'PWY-3042',
        Common_Name: 'phaseollin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-3042',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|phaseollin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3042',
        Common_Name: 'phaseollin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-3042',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|phaseollin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6888',
        Common_Name: 'zealexin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6888',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|zealexin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6888',
        Common_Name: 'zealexin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-6888',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|zealexin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-3961',
        Common_Name: 'phosphopantothenate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|CoA-Biosynthesis|Pantothenate-Biosynthesis|PWY-3961',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Coenzyme A Biosynthesis|Phosphopantothenate Biosynthesis|phosphopantothenate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-7316',
        Common_Name: 'dTDP-<i>N</i>-acetylviosamine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7316',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-<i>N</i>-acetylviosamine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5672',
        Common_Name: 'ginsenosides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5672',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|ginsenosides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6700',
        Common_Name: 'queuosine biosynthesis I (<i>de novo</i>)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|Queuosine-Biosynthesis|PWY-6700',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|Queuosine Biosynthesis and Salvage|queuosine biosynthesis I (<i>de novo</i>)',
    },
    {
        BioCyc_ID: 'PWY-6056',
        Common_Name: 'dimethylsulfoniopropanoate degradation II (cleavage)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Dimethylsulfoniopropionate-Degradation|PWY-6056',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Dimethylsulfoniopropanoate Degradation|dimethylsulfoniopropanoate degradation II (cleavage)',
    },
    {
        BioCyc_ID: 'PWY-6760',
        Common_Name: 'D-xylose degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Xylose-Degradation|PWY-6760',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-xylose Degradation|D-xylose degradation III',
    },
    {
        BioCyc_ID: 'PWY-7114',
        Common_Name: 'tea aroma glycosidic precursor bioactivation',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-7114',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|tea aroma glycosidic precursor bioactivation',
    },
    {
        BioCyc_ID: 'PWY-6102',
        Common_Name: '5-chloro-3-methyl-catechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-6102',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|5-chloro-3-methyl-catechol degradation',
    },
    {
        BioCyc_ID: 'PWY-6102',
        Common_Name: '5-chloro-3-methyl-catechol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-6102',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|5-chloro-3-methyl-catechol degradation',
    },
    {
        BioCyc_ID: 'PWY-6402',
        Common_Name: 'superpathway of melatonin degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|HORMONE-DEG|Melatonin-Degradation|PWY-6402',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Hormone, Neurotransmitter, and Signaling Molecule Degradation|Melatonin Degradation|superpathway of melatonin degradation',
    },
    {
        BioCyc_ID: 'PWY-6402',
        Common_Name: 'superpathway of melatonin degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6402',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of melatonin degradation',
    },
    {
        BioCyc_ID: 'PWY-7686',
        Common_Name: 'L-malate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|L-Malate-Degradation|PWY-7686',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|(<i>S</i>)-Malate Degradation|L-malate degradation II',
    },
    {
        BioCyc_ID: 'PWY-5816',
        Common_Name: 'all <i>trans</i> undecaprenyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-5816',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|all <i>trans</i> undecaprenyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7254',
        Common_Name: 'TCA cycle VII (acetate-producers)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|TCA-VARIANTS|PWY-7254',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|TCA cycle|TCA cycle VII (acetate-producers)',
    },
    {
        BioCyc_ID: 'PWY-2083',
        Common_Name: 'isoflavonoid biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2083',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|isoflavonoid biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-2083',
        Common_Name: 'isoflavonoid biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2083',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|isoflavonoid biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6254',
        Common_Name: 'santalene biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6254',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|santalene biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8415',
        Common_Name: 'macrocyclic archaeol phospholipid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Archaeal-Lipid-Biosynthesis|PWY-8415',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Archaeal Lipid Biosynthesis|macrocyclic archaeol phospholipid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7413',
        Common_Name: 'dTDP-6-deoxy-&alpha;-D-allose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7413',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|dTDP-6-deoxy-&alpha;-D-allose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7069',
        Common_Name: 'oleanolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7069',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|oleanolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8106',
        Common_Name: 'queuosine biosynthesis III (queuosine salvage)',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|Nucleic-Acid-Processing|Queuosine-Biosynthesis|PWY-8106',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Macromolecule Modification|Nucleic Acid Processing|Queuosine Biosynthesis and Salvage|queuosine biosynthesis III (queuosine salvage)',
    },
    {
        BioCyc_ID: 'PWY-6048',
        Common_Name: 'methylthiopropanoate degradation I (cleavage)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Methylthiopropionate-Degradation|PWY-6048',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Methylthiopropanoate Degradation|methylthiopropanoate degradation I (cleavage)',
    },
    {
        BioCyc_ID: 'PWY-8020',
        Common_Name: 'D-xylose degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Xylose-Degradation|PWY-8020',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-xylose Degradation|D-xylose degradation V',
    },
    {
        BioCyc_ID: 'PWY-7886',
        Common_Name: 'cell-surface glycoconjugate-linked phosphocholine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Macromolecule-Modification|PWY-7886',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Macromolecule Modification|cell-surface glycoconjugate-linked phosphocholine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7709',
        Common_Name: '(3<i>R</i>)-linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-7709',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|(3<i>R</i>)-linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7709',
        Common_Name: '(3<i>R</i>)-linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|Linalool-Biosynthesis|PWY-7709',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|Linalool Biosynthesis|(3<i>R</i>)-linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7709',
        Common_Name: '(3<i>R</i>)-linalool biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|Linalool-Biosynthesis|PWY-7709',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Linalool Biosynthesis|(3<i>R</i>)-linalool biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1295',
        Common_Name: 'pyrimidine ribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Ribonucleosides-Degradation|PWY0-1295',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Ribonucleoside Degradation|pyrimidine ribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY-6569',
        Common_Name: 'superpathway of chondroitin sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6569',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|superpathway of chondroitin sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6569',
        Common_Name: 'superpathway of chondroitin sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Glycan-Pathways|Polysaccharides-Biosynthesis|Glycosaminoglycans-Biosynthesis|PWY-6569',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Glycan Pathways|Polysaccharide Biosynthesis|Glycosaminoglycan Biosynthesis|superpathway of chondroitin sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6569',
        Common_Name: 'superpathway of chondroitin sulfate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6569',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of chondroitin sulfate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6197',
        Common_Name: 'chlorinated phenols degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-6197',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|chlorinated phenols degradation',
    },
    {
        BioCyc_ID: 'PWY-6197',
        Common_Name: 'chlorinated phenols degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-6197',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|chlorinated phenols degradation',
    },
    {
        BioCyc_ID: 'PWY-6520',
        Common_Name: 'nonaprenyl diphosphate biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-6520',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|nonaprenyl diphosphate biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-2467',
        Common_Name: '(+)-pisatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2467',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|(+)-pisatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2467',
        Common_Name: '(+)-pisatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2467',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|(+)-pisatin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8064',
        Common_Name: 'sodorifen biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-8064',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|sodorifen biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5774',
        Common_Name: 'saponin biosynthesis IV',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-5774',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|saponin biosynthesis IV',
    },
    {
        BioCyc_ID: 'PWY-6349',
        Common_Name: 'C<sub>20,20</sub> CDP-archaeol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Archaeal-Lipid-Biosynthesis|PWY-6349',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Archaeal Lipid Biosynthesis|C<sub>20,20</sub> CDP-archaeol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6349',
        Common_Name: 'C<sub>20,20</sub> CDP-archaeol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-6349',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|C<sub>20,20</sub> CDP-archaeol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5832',
        Common_Name: 'CDP-paratose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CDP-Sugar-Biosynthesis|PWY-5832',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CDP-sugar Biosynthesis|CDP-paratose biosynthesis',
    },
    {
        BioCyc_ID: 'DENOVOPURINE2-PWY',
        Common_Name: 'superpathway of purine nucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PUR-NUC-SYN|Purine-Nucleotide-De-Novo-Biosynthesis|DENOVOPURINE2-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Purine Nucleotide Biosynthesis|Purine Nucleotide <i>De Novo</i> Biosynthesis|superpathway of purine nucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'DENOVOPURINE2-PWY',
        Common_Name: 'superpathway of purine nucleotides <i>de novo</i> biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|DENOVOPURINE2-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of purine nucleotides <i>de novo</i> biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-6045',
        Common_Name: 'methylthiopropanonate degradation II (demethylation)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Methylthiopropionate-Degradation|PWY-6045',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Methylthiopropanoate Degradation|methylthiopropanonate degradation II (demethylation)',
    },
    {
        BioCyc_ID: 'XYLCAT-PWY',
        Common_Name: 'D-xylose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Xylose-Degradation|XYLCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-xylose Degradation|D-xylose degradation I',
    },
    {
        BioCyc_ID: 'PWY-8177',
        Common_Name: 'nerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-8177',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|nerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8177',
        Common_Name: 'nerol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-8177',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|nerol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7181',
        Common_Name: 'pyrimidine deoxyribonucleosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Deoxyribonucleosides-Deg|PWY-7181',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Deoxyribonucleoside Degradation|pyrimidine deoxyribonucleosides degradation',
    },
    {
        BioCyc_ID: 'PWY-6751',
        Common_Name: 'superpathway of hydrogen production',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Hydrogen-Production|PWY-6751',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Hydrogen Production|superpathway of hydrogen production',
    },
    {
        BioCyc_ID: 'PWY-6751',
        Common_Name: 'superpathway of hydrogen production',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6751',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of hydrogen production',
    },
    {
        BioCyc_ID: 'PWY-7512',
        Common_Name: '3,5,6-trichloro-2-pyridinol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-7512',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|3,5,6-trichloro-2-pyridinol degradation',
    },
    {
        BioCyc_ID: 'PWY-7512',
        Common_Name: '3,5,6-trichloro-2-pyridinol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-7512',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|3,5,6-trichloro-2-pyridinol degradation',
    },
    {
        BioCyc_ID: 'PWY-7574',
        Common_Name: 'propanoyl-CoA degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Propionate-Degradation|PWY-7574',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Propanoate Degradation|propanoyl-CoA degradation II',
    },
    {
        BioCyc_ID: 'PWY-5783',
        Common_Name: 'octaprenyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-5783',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|octaprenyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5276',
        Common_Name: 'sulfite oxidation I',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|PWY-5276',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|sulfite oxidation I',
    },
    {
        BioCyc_ID: 'PWY-5276',
        Common_Name: 'sulfite oxidation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfite-Oxidation|PWY-5276',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfite Oxidation|sulfite oxidation I',
    },
    {
        BioCyc_ID: 'PWY-2761',
        Common_Name: '(-)-glycinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2761',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|(-)-glycinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2761',
        Common_Name: '(-)-glycinol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2761',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|(-)-glycinol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6265',
        Common_Name: 'zerumbone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6265',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|zerumbone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7473',
        Common_Name: 'avenacin A-1 biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7473',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|avenacin A-1 biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8414',
        Common_Name: 'glycerol dibiphytanyl glycerol tetraether lipid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Archaeal-Lipid-Biosynthesis|PWY-8414',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Archaeal Lipid Biosynthesis|glycerol dibiphytanyl glycerol tetraether lipid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8139',
        Common_Name: 'CDP-6-deoxy-D-gulose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CDP-Sugar-Biosynthesis|PWY-8139',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CDP-sugar Biosynthesis|CDP-6-deoxy-D-gulose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1281',
        Common_Name: 'sulfoacetaldehyde degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfoacetaldehyde-Degradation|PWY-1281',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfoacetaldehyde Degradation|sulfoacetaldehyde degradation I',
    },
    {
        BioCyc_ID: 'PWYQT-4476',
        Common_Name: 'indole glucosinolate activation (herbivore attack)',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|GLUCOSINOLATE-DEG|PWYQT-4476',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|Glucosinolate Activation|indole glucosinolate activation (herbivore attack)',
    },
    {
        BioCyc_ID: 'PWYQT-4476',
        Common_Name: 'indole glucosinolate activation (herbivore attack)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|GLUCOSINOLATE-DEG|PWYQT-4476',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Glucosinolate Activation|indole glucosinolate activation (herbivore attack)',
    },
    {
        BioCyc_ID: 'GLYCOLYSIS-TCA-GLYOX-BYPASS',
        Common_Name: 'superpathway of glycolysis, pyruvate dehydrogenase, TCA, and glyoxylate bypass',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|GLYCOLYSIS-TCA-GLYOX-BYPASS',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|superpathway of glycolysis, pyruvate dehydrogenase, TCA, and glyoxylate bypass',
    },
    {
        BioCyc_ID: 'GLYCOLYSIS-TCA-GLYOX-BYPASS',
        Common_Name: 'superpathway of glycolysis, pyruvate dehydrogenase, TCA, and glyoxylate bypass',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GLYCOLYSIS-TCA-GLYOX-BYPASS',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glycolysis, pyruvate dehydrogenase, TCA, and glyoxylate bypass',
    },
    {
        BioCyc_ID: 'PWY-5516',
        Common_Name: 'D-xylose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Xylose-Degradation|PWY-5516',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-xylose Degradation|D-xylose degradation II',
    },
    {
        BioCyc_ID: 'PWY-6291',
        Common_Name: 'valencene and 7-epi-&alpha;-selinene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-6291',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|valencene and 7-epi-&alpha;-selinene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6291',
        Common_Name: 'valencene and 7-epi-&alpha;-selinene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6291',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|valencene and 7-epi-&alpha;-selinene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6977',
        Common_Name: 'superpathway of erythromycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|Antibiotic-Biosynthesis|Macrolides-Biosynthesis|PWY-6977',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Antibiotic Biosynthesis|Macrolide Antibiotic Biosynthesis|superpathway of erythromycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6977',
        Common_Name: 'superpathway of erythromycin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6977',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of erythromycin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6086',
        Common_Name: '4-chloro-2-methylphenoxyacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-6086',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|4-chloro-2-methylphenoxyacetate degradation',
    },
    {
        BioCyc_ID: 'PWY-6086',
        Common_Name: '4-chloro-2-methylphenoxyacetate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-6086',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|4-chloro-2-methylphenoxyacetate degradation',
    },
    {
        BioCyc_ID: 'PROPIONMET-PWY',
        Common_Name: 'propanoyl CoA degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|Propionate-Degradation|PROPIONMET-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Propanoate Degradation|propanoyl CoA degradation I',
    },
    {
        BioCyc_ID: 'PWY-5807',
        Common_Name: 'heptaprenyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-5807',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|heptaprenyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'SULFUROX-PWY',
        Common_Name: 'sulfur oxidation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|SULFUROX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|sulfur oxidation I (aerobic)',
    },
    {
        BioCyc_ID: 'SULFUROX-PWY',
        Common_Name: 'sulfur oxidation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-Oxidation|SULFUROX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Oxidation|sulfur oxidation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-4681',
        Common_Name: 'kievitone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-4681',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|kievitone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4681',
        Common_Name: 'kievitone biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-4681',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|kievitone biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6278',
        Common_Name: 'botrydial biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6278',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|botrydial biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6005',
        Common_Name: 'marneral biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-6005',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|marneral biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8365',
        Common_Name: 'C<sub>25,25</sub> CDP-archaeol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Archaeal-Lipid-Biosynthesis|PWY-8365',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Archaeal Lipid Biosynthesis|C<sub>25,25</sub> CDP-archaeol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8365',
        Common_Name: 'C<sub>25,25</sub> CDP-archaeol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Phospholipid-Biosynthesis|PWY-8365',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Phospholipid Biosynthesis|C<sub>25,25</sub> CDP-archaeol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5831',
        Common_Name: 'CDP-abequose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CDP-Sugar-Biosynthesis|PWY-5831',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CDP-sugar Biosynthesis|CDP-abequose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8062',
        Common_Name: 'sulfoacetaldehyde degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfoacetaldehyde-Degradation|PWY-8062',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfoacetaldehyde Degradation|sulfoacetaldehyde degradation IV',
    },
    {
        BioCyc_ID: 'PWY-6684',
        Common_Name: 'aromatic  glucosinolate activation',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|GLUCOSINOLATE-DEG|PWY-6684',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|Glucosinolate Activation|aromatic  glucosinolate activation',
    },
    {
        BioCyc_ID: 'PWY-6684',
        Common_Name: 'aromatic  glucosinolate activation',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|GLUCOSINOLATE-DEG|PWY-6684',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Glucosinolate Activation|aromatic  glucosinolate activation',
    },
    {
        BioCyc_ID: 'PWY-3502',
        Common_Name: 'superpathway of NAD biosynthesis in eukaryotes',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-SYN|PWY-3502',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Biosynthesis|superpathway of NAD biosynthesis in eukaryotes',
    },
    {
        BioCyc_ID: 'PWY-3502',
        Common_Name: 'superpathway of NAD biosynthesis in eukaryotes',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-3502',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|NAD Metabolism|NAD Biosynthesis|superpathway of NAD biosynthesis in eukaryotes',
    },
    {
        BioCyc_ID: 'PWY-3502',
        Common_Name: 'superpathway of NAD biosynthesis in eukaryotes',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Cofactor-Biosynthesis|Enzyme-Cofactor-Biosynthesis|NAD-Metabolism|NAD-SYN|PWY-3502',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Enzyme Cofactor Biosynthesis|NAD Metabolism|NAD Biosynthesis|superpathway of NAD biosynthesis in eukaryotes',
    },
    {
        BioCyc_ID: 'PWY-3502',
        Common_Name: 'superpathway of NAD biosynthesis in eukaryotes',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-3502',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of NAD biosynthesis in eukaryotes',
    },
    {
        BioCyc_ID: 'PWY-7294',
        Common_Name: 'D-xylose degradation IV',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Xylose-Degradation|PWY-7294',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-xylose Degradation|D-xylose degradation IV',
    },
    {
        BioCyc_ID: 'PWY-7697',
        Common_Name: 'geranyl &beta;-primeveroside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-7697',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|geranyl &beta;-primeveroside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7697',
        Common_Name: 'geranyl &beta;-primeveroside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-7697',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|geranyl &beta;-primeveroside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6430',
        Common_Name: 'thymine degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Pyrimidine-Degradation|Pyrimidine-Base-Degradation|PWY-6430',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Pyrimidine Nucleotide Degradation|Pyrimidine Nucleobase Degradation|thymine degradation',
    },
    {
        BioCyc_ID: 'PWY-7200',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleoside salvage',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Nucleotide-Biosynthesis|PYR-NUC-SYN|Pyrimidine-Nucleotide-Salvage|PWY-7200',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Nucleoside and Nucleotide Biosynthesis|Pyrimidine Nucleotide Biosynthesis|Pyrimidine Nucleotide Salvage|superpathway of pyrimidine deoxyribonucleoside salvage',
    },
    {
        BioCyc_ID: 'PWY-7200',
        Common_Name: 'superpathway of pyrimidine deoxyribonucleoside salvage',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7200',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of pyrimidine deoxyribonucleoside salvage',
    },
    {
        BioCyc_ID: 'PWY-6178',
        Common_Name: '2,4,6-trichlorophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|PWY-6178',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|2,4,6-trichlorophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-6178',
        Common_Name: '2,4,6-trichlorophenol degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|PWY-6178',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|2,4,6-trichlorophenol degradation',
    },
    {
        BioCyc_ID: 'PWY-5525',
        Common_Name: 'D-glucuronate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|PWY-5525',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|D-glucuronate degradation I',
    },
    {
        BioCyc_ID: 'PWY-5525',
        Common_Name: 'D-glucuronate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|PWY-5525',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|D-glucuronate degradation I',
    },
    {
        BioCyc_ID: 'PWY-5893',
        Common_Name: 'tridecaprenyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-5893',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|tridecaprenyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'FESULFOX-PWY',
        Common_Name: 'sulfur oxidation II (Fe<sup>+3</sup>-dependent)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|FESULFOX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|sulfur oxidation II (Fe<sup>+3</sup>-dependent)',
    },
    {
        BioCyc_ID: 'FESULFOX-PWY',
        Common_Name: 'sulfur oxidation II (Fe<sup>+3</sup>-dependent)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-Oxidation|FESULFOX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Oxidation|sulfur oxidation II (Fe<sup>+3</sup>-dependent)',
    },
    {
        BioCyc_ID: 'PWY-2002',
        Common_Name: 'isoflavonoid biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2002',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|isoflavonoid biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-2002',
        Common_Name: 'isoflavonoid biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2002',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|isoflavonoid biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-6098',
        Common_Name: 'diploterol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-6098',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|diploterol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2921',
        Common_Name: 'capsidiol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-2921',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|capsidiol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2921',
        Common_Name: 'capsidiol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-2921',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|capsidiol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8397',
        Common_Name: 'arachidonate metabolites biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8397',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|arachidonate metabolites biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5834',
        Common_Name: 'CDP-tyvelose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CDP-Sugar-Biosynthesis|PWY-5834',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CDP-sugar Biosynthesis|CDP-tyvelose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6718',
        Common_Name: 'sulfoacetaldehyde degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfoacetaldehyde-Degradation|PWY-6718',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfoacetaldehyde Degradation|sulfoacetaldehyde degradation III',
    },
    {
        BioCyc_ID: 'PWYQT-4477',
        Common_Name: 'indole glucosinolate activation (intact plant cell)',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Activation|GLUCOSINOLATE-DEG|PWYQT-4477',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Activation|Glucosinolate Activation|indole glucosinolate activation (intact plant cell)',
    },
    {
        BioCyc_ID: 'PWYQT-4477',
        Common_Name: 'indole glucosinolate activation (intact plant cell)',
        MetaCyc_hierarchy_IDs: 'Degradation|SECONDARY-METABOLITE-DEGRADATION|N-CONTAINING-SECONDARY-CMPD-DEG|N-CONTAINING-GLUCOSIDE-DEG|GLUCOSINOLATE-DEG|PWYQT-4477',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Secondary Metabolite Degradation|Nitrogen Containing Secondary Compound Degradation|Nitrogen Containing Glucoside Degradation|Glucosinolate Activation|indole glucosinolate activation (intact plant cell)',
    },
    {
        BioCyc_ID: 'PWY-5114',
        Common_Name: 'UDP-sugars interconversion',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Interconversion|PWY-5114',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Interconversion|UDP-sugars interconversion',
    },
    {
        BioCyc_ID: 'PWY-5114',
        Common_Name: 'UDP-sugars interconversion',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5114',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|UDP-sugars interconversion',
    },
    {
        BioCyc_ID: 'PWY-5114',
        Common_Name: 'UDP-sugars interconversion',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|UDP-Sugar-Biosynthesis|PWY-5114',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|UDP-sugar Biosynthesis|UDP-sugars interconversion',
    },
    {
        BioCyc_ID: 'PWY-8334',
        Common_Name: 'D-arabinose degradation V',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|Xylose-Degradation|PWY-8334',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-xylose Degradation|D-arabinose degradation V',
    },
    {
        BioCyc_ID: 'PWY-8176',
        Common_Name: 'geraniol biosynthesis (cytosol)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-8176',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|geraniol biosynthesis (cytosol)',
    },
    {
        BioCyc_ID: 'PWY-8176',
        Common_Name: 'geraniol biosynthesis (cytosol)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|MONOTERPENOID-SYN|PWY-8176',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Monoterpenoid Biosynthesis|geraniol biosynthesis (cytosol)',
    },
    {
        BioCyc_ID: 'PWY-6606',
        Common_Name: 'guanosine nucleotides degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|Guanosine-Nucleotides-Degradation|PWY-6606',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|Guanosine Nucleotide Degradation|guanosine nucleotides degradation II',
    },
    {
        BioCyc_ID: 'PWY-7323',
        Common_Name: 'superpathway of GDP-mannose-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|GDP-Sugar-Biosynthesis|PWY-7323',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|GDP-sugar Biosynthesis|superpathway of GDP-mannose-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7323',
        Common_Name: 'superpathway of GDP-mannose-derived O-antigen building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7323',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of GDP-mannose-derived O-antigen building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6083',
        Common_Name: 'chlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6083',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|chlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-6083',
        Common_Name: 'chlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6083',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|chlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-6100',
        Common_Name: 'L-carnitine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|PWY-6100',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|L-carnitine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8390',
        Common_Name: 'D-glucuronate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|PWY-8390',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|D-glucuronate degradation III',
    },
    {
        BioCyc_ID: 'PWY-8390',
        Common_Name: 'D-glucuronate degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|PWY-8390',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|D-glucuronate degradation III',
    },
    {
        BioCyc_ID: 'PWY-5122',
        Common_Name: 'geranyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-5122',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|geranyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'P301-PWY',
        Common_Name: 'galena oxidation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|P301-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|galena oxidation',
    },
    {
        BioCyc_ID: 'PWY-2464',
        Common_Name: '(-)-maackiain biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|ISOFLAVONOID-PHYTOALEXINS|PWY-2464',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Isoflavonoid Phytoalexin Biosynthesis|(-)-maackiain biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-2464',
        Common_Name: '(-)-maackiain biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ISOFLAVONOID-SYN|PWY-2464',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Isoflavonoid Biosynthesis|(-)-maackiain biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6767',
        Common_Name: "4,4'-diapolycopenedioate biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|CAROTENOID-SYN|C30-Carotenoids-Biosynthesis|PWY-6767',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Carotenoid Biosynthesis|C<sub>30</sub> Carotenoid Biosynthesis|4,4'-diapolycopenedioate biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6767',
        Common_Name: "4,4'-diapolycopenedioate biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|CAROTENOID-SYN|C30-Carotenoids-Biosynthesis|PWY-6767',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Carotenoid Biosynthesis|C<sub>30</sub> Carotenoid Biosynthesis|4,4'-diapolycopenedioate biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6767',
        Common_Name: "4,4'-diapolycopenedioate biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|C30-Carotenoids-Biosynthesis|PWY-6767',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|C<sub>30</sub> Carotenoid Biosynthesis|4,4'-diapolycopenedioate biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6767',
        Common_Name: "4,4'-diapolycopenedioate biosynthesis",
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-6767',
        MetaCyc_hierarchy_Names:
            "All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|4,4'-diapolycopenedioate biosynthesis",
    },
    {
        BioCyc_ID: 'PWY-6294',
        Common_Name: 'selinene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6294',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|selinene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8400',
        Common_Name: 'docosahexaenoate metabolites biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8400',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|docosahexaenoate metabolites biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5830',
        Common_Name: 'CDP-ascarylose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CDP-Sugar-Biosynthesis|PWY-5830',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CDP-sugar Biosynthesis|CDP-ascarylose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5982',
        Common_Name: 'sulfoacetaldehyde degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfoacetaldehyde-Degradation|PWY-5982',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfoacetaldehyde Degradation|sulfoacetaldehyde degradation II',
    },
    {
        BioCyc_ID: 'PWY-5784',
        Common_Name: 'indole-3-acetate inactivation VIII',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-5784',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation VIII',
    },
    {
        BioCyc_ID: 'PWY-5304',
        Common_Name: 'superpathway of sulfur oxidation (<i>Acidianus ambivalens</i>)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-Oxidation|PWY-5304',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Oxidation|superpathway of sulfur oxidation (<i>Acidianus ambivalens</i>)',
    },
    {
        BioCyc_ID: 'PWY-5304',
        Common_Name: 'superpathway of sulfur oxidation (<i>Acidianus ambivalens</i>)',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5304',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of sulfur oxidation (<i>Acidianus ambivalens</i>)',
    },
    {
        BioCyc_ID: 'MANNCAT-PWY',
        Common_Name: 'D-mannose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|D-Mannose-Degradation|MANNCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-Mannose-Degradation|D-mannose degradation I',
    },
    {
        BioCyc_ID: 'PWY-5975',
        Common_Name: 'furaneol and mesifurane biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Aroma-Compound-Biosynthesis|PWY-5975',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Aroma Compound Biosynthesis|furaneol and mesifurane biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6608',
        Common_Name: 'guanosine nucleotides degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|Guanosine-Nucleotides-Degradation|PWY-6608',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|Guanosine Nucleotide Degradation|guanosine nucleotides degradation III',
    },
    {
        BioCyc_ID: 'PWY-7438',
        Common_Name: 'superpathway of dTDP-glucose-derived antibiotic building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7438',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of dTDP-glucose-derived antibiotic building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7438',
        Common_Name: 'superpathway of dTDP-glucose-derived antibiotic building blocks biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|dTDP-Sugar-Biosynthesis|PWY-7438',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|dTDP-sugar Biosynthesis|superpathway of dTDP-glucose-derived antibiotic building blocks biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6099',
        Common_Name: '1,2,4,5-tetrachlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6099',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,2,4,5-tetrachlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-6099',
        Common_Name: '1,2,4,5-tetrachlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6099',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,2,4,5-tetrachlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-7535',
        Common_Name: 'lovastatin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|PWY-7535',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|lovastatin biosynthesis',
    },
    {
        BioCyc_ID: 'GLUCUROCAT-PWY',
        Common_Name: 'superpathway of &beta;-D-glucuronosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|GLUCUROCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|superpathway of &beta;-D-glucuronosides degradation',
    },
    {
        BioCyc_ID: 'GLUCUROCAT-PWY',
        Common_Name: 'superpathway of &beta;-D-glucuronosides degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|GLUCUROCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|superpathway of &beta;-D-glucuronosides degradation',
    },
    {
        BioCyc_ID: 'GLUCUROCAT-PWY',
        Common_Name: 'superpathway of &beta;-D-glucuronosides degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|GLUCUROCAT-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of &beta;-D-glucuronosides degradation',
    },
    {
        BioCyc_ID: 'PWY-5806',
        Common_Name: 'all-<i>trans</i>-decaprenyl diphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Polyprenyl-Biosynthesis|All-Trans-Polyprenyl-Biosynthesis|PWY-5806',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Polyprenyl Biosynthesis|<i>All-trans</i> Polyprenyl Diphosphate Biosynthesis|all-<i>trans</i>-decaprenyl diphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-4521',
        Common_Name: 'arsenite to oxygen electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|AEROBIC-RESPIRATION|PWY-4521',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Aerobic Respiration|arsenite to oxygen electron transfer',
    },
    {
        BioCyc_ID: 'PWY-4521',
        Common_Name: 'arsenite to oxygen electron transfer',
        MetaCyc_hierarchy_IDs: 'Detoxification|Arsenic-Detoxification|PWY-4521',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Detoxification|Arsenic Compound Detoxification|arsenite to oxygen electron transfer',
    },
    {
        BioCyc_ID: 'PWY-4521',
        Common_Name: 'arsenite to oxygen electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|PWY-4521',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|arsenite to oxygen electron transfer',
    },
    {
        BioCyc_ID: 'PWY-4521',
        Common_Name: 'arsenite to oxygen electron transfer',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|PWY-4521',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|arsenite to oxygen electron transfer',
    },
    {
        BioCyc_ID: 'CAMALEXIN-SYN',
        Common_Name: 'camalexin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|INDOLE-PHYTOALEXIN-SYN|CAMALEXIN-SYN',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Indole Phytoalexin Biosynthesis|camalexin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7068',
        Common_Name: 'ursolate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|TRITERPENOID-SYN|PWY-7068',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Triterpenoid Biosynthesis|ursolate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5725',
        Common_Name: 'farnesene biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-5725',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|farnesene biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8412',
        Common_Name: 'representative whole plasma DHA-derived icosanoids',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8412',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|representative whole plasma DHA-derived icosanoids',
    },
    {
        BioCyc_ID: 'PWY-5833',
        Common_Name: 'CDP-4-dehydro-3,6-dideoxy-D-glucose biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Carbohydrates-Biosynthesis|CARBO-BIOSYNTHESIS|SUGAR-NUCLEOTIDES|CDP-Sugar-Biosynthesis|PWY-5833',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Carbohydrate Biosynthesis|Sugar Biosynthesis|Sugar Nucleotide Biosynthesis|CDP-sugar Biosynthesis|CDP-4-dehydro-3,6-dideoxy-D-glucose biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6675',
        Common_Name: 'sulfur oxidation IV (intracellular sulfur)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfur-Oxidation|PWY-6675',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfur Oxidation|sulfur oxidation IV (intracellular sulfur)',
    },
    {
        BioCyc_ID: 'PWY-5811',
        Common_Name: 'indole-3-acetate inactivation VII',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-5811',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation VII',
    },
    {
        BioCyc_ID: 'PWY-561',
        Common_Name: 'superpathway of glyoxylate cycle and fatty acid degradation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|PWY-561',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|superpathway of glyoxylate cycle and fatty acid degradation',
    },
    {
        BioCyc_ID: 'PWY-561',
        Common_Name: 'superpathway of glyoxylate cycle and fatty acid degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-561',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glyoxylate cycle and fatty acid degradation',
    },
    {
        BioCyc_ID: 'PWY3O-1743',
        Common_Name: 'D-mannose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|D-Mannose-Degradation|PWY3O-1743',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|D-Mannose-Degradation|D-mannose degradation II',
    },
    {
        BioCyc_ID: 'PWY-6607',
        Common_Name: 'guanosine nucleotides degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|Guanosine-Nucleotides-Degradation|PWY-6607',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|Guanosine Nucleotide Degradation|guanosine nucleotides degradation I',
    },
    {
        BioCyc_ID: 'PWY-7835',
        Common_Name: 'superpathway of glycosphingolipids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Sphingolipid-Biosynthesis|PWY-7835',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Sphingolipid Biosynthesis|superpathway of glycosphingolipids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7835',
        Common_Name: 'superpathway of glycosphingolipids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-7835',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of glycosphingolipids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6081',
        Common_Name: '1,3-dichlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6081',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,3-dichlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-6081',
        Common_Name: '1,3-dichlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6081',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,3-dichlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PPGPPMET-PWY',
        Common_Name: 'ppGpp metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|PPGPPMET-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|ppGpp metabolism',
    },
    {
        BioCyc_ID: 'PWY-7247',
        Common_Name: '&beta;-D-glucuronide and D-glucuronate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|PWY-7247',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|&beta;-D-glucuronide and D-glucuronate degradation',
    },
    {
        BioCyc_ID: 'PWY-7247',
        Common_Name: '&beta;-D-glucuronide and D-glucuronate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|PWY-7247',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|&beta;-D-glucuronide and D-glucuronate degradation',
    },
    {
        BioCyc_ID: 'PWY-5755',
        Common_Name: '4-hydroxybenzoate biosynthesis II (bacteria)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|4-Hydroxybenzoate-Biosynthesis|PWY-5755',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|4-Hydroxybenzoate Biosynthesis|4-hydroxybenzoate biosynthesis II (bacteria)',
    },
    {
        BioCyc_ID: 'PWY-7457',
        Common_Name: 'sulfite oxidation V (SoeABC)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|PWY-7457',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|sulfite oxidation V (SoeABC)',
    },
    {
        BioCyc_ID: 'PWY-7457',
        Common_Name: 'sulfite oxidation V (SoeABC)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Sulfite-Oxidation|PWY-7457',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Sulfite Oxidation|sulfite oxidation V (SoeABC)',
    },
    {
        BioCyc_ID: 'PWY-7477',
        Common_Name: 'momilactone A biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-7477',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|momilactone A biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7253',
        Common_Name: 'apigeninidin 5-<i>O</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7253',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|apigeninidin 5-<i>O</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7253',
        Common_Name: 'apigeninidin 5-<i>O</i>-glucoside biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7253',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|apigeninidin 5-<i>O</i>-glucoside biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5827',
        Common_Name: 'heliocides biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-5827',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|heliocides biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8396',
        Common_Name: 'di-homo-&gamma;-linolenate metabolites biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8396',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|di-homo-&gamma;-linolenate metabolites biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5477',
        Common_Name: 'gallotannin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|TANNIN-SYN|GALLOTANNINS|PWY-5477',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Hydrolyzable Tannin Biosynthesis|Gallotannin Biosynthesis|gallotannin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-1961',
        Common_Name: 'indole-3-acetate inactivation I',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-1961',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation I',
    },
    {
        BioCyc_ID: 'PWY-5861',
        Common_Name: 'superpathway of demethylmenaquinol-8 biosynthesis I',
        MetaCyc_hierarchy_IDs:
            'Biosynthesis|Cofactor-Biosynthesis|Carriers-Biosynthesis|Electron-Carrier-Biosynthesis|Quinone-Biosynthesis|Demethylmenaquinone-Biosynthesis|Demethylmenaquinol-8-Biosynthesis|PWY-5861',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Cofactor, Carrier, and Vitamin Biosynthesis|Carrier Biosynthesis|Electron Carrier Biosynthesis|Quinol and Quinone Biosynthesis|Demethylmenaquinol Biosynthesis|Demethylmenaquinol-8 Biosynthesis|superpathway of demethylmenaquinol-8 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-5861',
        Common_Name: 'superpathway of demethylmenaquinol-8 biosynthesis I',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-5861',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of demethylmenaquinol-8 biosynthesis I',
    },
    {
        BioCyc_ID: 'PWY-8444',
        Common_Name: 'L-fucose degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-Fucose-Degradation|PWY-8444',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-Fucose Degradation|L-fucose degradation II',
    },
    {
        BioCyc_ID: 'PWY-5408',
        Common_Name: '9-lipoxygenase and 9-hydroperoxide lyase pathway',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|FATTY-ACID-DERIVATIVE-SYN|PWY-5408',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Fatty Acid Derivative Biosynthesis|9-lipoxygenase and 9-hydroperoxide lyase pathway',
    },
    {
        BioCyc_ID: 'SALVADEHYPOX-PWY',
        Common_Name: 'adenosine nucleotides degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|Adenosine-Nucleotides-Degradation|SALVADEHYPOX-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|Adenosine Nucleotide Degradation|adenosine nucleotides degradation II',
    },
    {
        BioCyc_ID: 'PWY0-1061',
        Common_Name: 'superpathway of L-alanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Amino-Acid-Biosynthesis|IND-AMINO-ACID-SYN|ALANINE-SYN|PWY0-1061',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Amino Acid Biosynthesis|Proteinogenic Amino Acid Biosynthesis|L-alanine Biosynthesis|superpathway of L-alanine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY0-1061',
        Common_Name: 'superpathway of L-alanine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY0-1061',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of L-alanine biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6091',
        Common_Name: '1,2,4-trichlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6091',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,2,4-trichlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-6091',
        Common_Name: '1,2,4-trichlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6091',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,2,4-trichlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-6405',
        Common_Name: 'Rapoport-Luebering glycolytic shunt',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|PWY-6405',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|Rapoport-Luebering glycolytic shunt',
    },
    {
        BioCyc_ID: 'PWY-6501',
        Common_Name: 'D-glucuronate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|PWY-6501',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|D-glucuronate degradation II',
    },
    {
        BioCyc_ID: 'PWY-6501',
        Common_Name: 'D-glucuronate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Glucuronate-Degradation|PWY-6501',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Glucuronate Degradation|D-glucuronate degradation II',
    },
    {
        BioCyc_ID: 'PWY-5754',
        Common_Name: '4-hydroxybenzoate biosynthesis I (eukaryotes)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|4-Hydroxybenzoate-Biosynthesis|PWY-5754',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|4-Hydroxybenzoate Biosynthesis|4-hydroxybenzoate biosynthesis I (eukaryotes)',
    },
    {
        BioCyc_ID: 'AMMOXID-PWY',
        Common_Name: 'ammonia oxidation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|NITROGEN-DEG|Ammonia-oxidation|AMMOXID-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Nitrogen Compound Metabolism|Ammonia Oxidation|ammonia oxidation I (aerobic)',
    },
    {
        BioCyc_ID: 'AMMOXID-PWY',
        Common_Name: 'ammonia oxidation I (aerobic)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|AMMOXID-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|ammonia oxidation I (aerobic)',
    },
    {
        BioCyc_ID: 'PWY-7478',
        Common_Name: 'oryzalexin D and E biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-7478',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|oryzalexin D and E biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5125',
        Common_Name: 'anthocyanin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-5125',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|anthocyanin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5125',
        Common_Name: 'anthocyanin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-5125',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|anthocyanin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6859',
        Common_Name: '<i>all-trans</i>-farnesol biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6859',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|<i>all-trans</i>-farnesol biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8399',
        Common_Name: 'icosapentaenoate metabolites biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8399',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|icosapentaenoate metabolites biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5476',
        Common_Name: 'cornusiin E biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|TANNIN-SYN|ELLAGITANNINS|PWY-5476',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Hydrolyzable Tannin Biosynthesis|Ellagitannin Biosynthesis|cornusiin E biosynthesis',
    },
    {
        BioCyc_ID: 'DISSULFRED-PWY',
        Common_Name: 'dissimilatory sulfate reduction I (to hydrogen sufide))',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|DISSULFRED-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|dissimilatory sulfate reduction I (to hydrogen sufide))',
    },
    {
        BioCyc_ID: 'DISSULFRED-PWY',
        Common_Name: 'dissimilatory sulfate reduction I (to hydrogen sufide))',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Dissimilatory-Sulfate-Reduction|DISSULFRED-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Dissimilatory Sulfate Reduction|dissimilatory sulfate reduction I (to hydrogen sufide))',
    },
    {
        BioCyc_ID: 'PWY-2021',
        Common_Name: 'indole-3-acetate inactivation IV',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-2021',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation IV',
    },
    {
        BioCyc_ID: 'PWY-6049',
        Common_Name: 'superpathway of dimethylsulfoniopropanoate degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Dimethylsulfoniopropionate-Degradation|PWY-6049',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Dimethylsulfoniopropanoate Degradation|superpathway of dimethylsulfoniopropanoate degradation',
    },
    {
        BioCyc_ID: 'PWY-6049',
        Common_Name: 'superpathway of dimethylsulfoniopropanoate degradation',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6049',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of dimethylsulfoniopropanoate degradation',
    },
    {
        BioCyc_ID: 'PWY-8318',
        Common_Name: 'L-fucose degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-Fucose-Degradation|PWY-8318',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-Fucose Degradation|L-fucose degradation III',
    },
    {
        BioCyc_ID: 'PWY-735',
        Common_Name: 'jasmonic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|FATTY-ACID-DERIVATIVE-SYN|PWY-735',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Fatty Acid Derivative Biosynthesis|jasmonic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-735',
        Common_Name: 'jasmonic acid biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|HORMONE-SYN|Hormone-Biosynthesis|Plant-Hormone-Biosynthesis|Jasmonates-Biosynthesis|PWY-735',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Hormone, Neurotransmitter, and Signaling Molecule Biosynthesis|Hormone Biosynthesis|Plant Hormone Biosynthesis|Jasmonate Biosynthesis|jasmonic acid biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6617',
        Common_Name: 'adenosine nucleotides degradation III',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|Adenosine-Nucleotides-Degradation|PWY-6617',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|Adenosine Nucleotide Degradation|adenosine nucleotides degradation III',
    },
    {
        BioCyc_ID: 'PWY18C3-25',
        Common_Name: 'superpathway of methylsalicylate metabolism',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|PWY18C3-25',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|superpathway of methylsalicylate metabolism',
    },
    {
        BioCyc_ID: 'PWY18C3-25',
        Common_Name: 'superpathway of methylsalicylate metabolism',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY18C3-25',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of methylsalicylate metabolism',
    },
    {
        BioCyc_ID: '14DICHLORBENZDEG-PWY',
        Common_Name: '1,4-dichlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|14DICHLORBENZDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,4-dichlorobenzene degradation',
    },
    {
        BioCyc_ID: '14DICHLORBENZDEG-PWY',
        Common_Name: '1,4-dichlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|14DICHLORBENZDEG-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,4-dichlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY66-423',
        Common_Name: 'fructose 2,6-bisphosphate biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|PWY66-423',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|fructose 2,6-bisphosphate biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-6486',
        Common_Name: 'D-galacturonate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Galacturonate-Degradation|PWY-6486',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Galacturonate Degradation|D-galacturonate degradation II',
    },
    {
        BioCyc_ID: 'PWY-6486',
        Common_Name: 'D-galacturonate degradation II',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Galacturonate-Degradation|PWY-6486',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Galacturonate Degradation|D-galacturonate degradation II',
    },
    {
        BioCyc_ID: 'PWY-6435',
        Common_Name: '4-hydroxybenzoate biosynthesis III (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|4-Hydroxybenzoate-Biosynthesis|PWY-6435',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|4-Hydroxybenzoate Biosynthesis|4-hydroxybenzoate biosynthesis III (plants)',
    },
    {
        BioCyc_ID: 'P282-PWY',
        Common_Name: 'nitrite oxidation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|P282-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|nitrite oxidation',
    },
    {
        BioCyc_ID: 'P282-PWY',
        Common_Name: 'nitrite oxidation',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Electron-Transfer|P282-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Electron Transfer Chains|nitrite oxidation',
    },
    {
        BioCyc_ID: 'PWY-7489',
        Common_Name: 'oryzalexin A, B, and C biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|TERPENOID-PHYTOALEXINS|PWY-7489',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Terpenoid Phytoalexin Biosynthesis|oryzalexin A, B, and C biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7260',
        Common_Name: 'delphinidin diglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7260',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|delphinidin diglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-7260',
        Common_Name: 'delphinidin diglucoside biosynthesis (acyl-glucose dependent)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7260',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|delphinidin diglucoside biosynthesis (acyl-glucose dependent)',
    },
    {
        BioCyc_ID: 'PWY-6128',
        Common_Name: 'cis-calamenene related sesquiterpenoids biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Terpenoid-Biosynthesis|SESQUITERPENOID-SYN|PWY-6128',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Terpenoid Biosynthesis|Sesquiterpenoid Biosynthesis|cis-calamenene related sesquiterpenoids biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-8411',
        Common_Name: 'representative whole plasma arachidonate-derived icosanoids II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Lipid-Biosynthesis|Icosanoid-Metabolism|PWY-8411',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Fatty Acid and Lipid Biosynthesis|Icosanoid Metabolism|representative whole plasma arachidonate-derived icosanoids II',
    },
    {
        BioCyc_ID: 'PWY-7629',
        Common_Name: 'yatein biosynthesis II',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|LIGNAN-SYN|Yatein-Biosynthesis|PWY-7629',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Lignan Biosynthesis|Yatein Biosynthesis|yatein biosynthesis II',
    },
    {
        BioCyc_ID: 'PWY-5303',
        Common_Name: 'thiosulfate oxidation II (via tetrathionate)',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|PWY-5303',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|thiosulfate oxidation II (via tetrathionate)',
    },
    {
        BioCyc_ID: 'PWY-5303',
        Common_Name: 'thiosulfate oxidation II (via tetrathionate)',
        MetaCyc_hierarchy_IDs: 'Degradation|Noncarbon-Nutrients|Sulfur-Metabolism|Thiosulfate-Oxidation|PWY-5303',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Inorganic Nutrient Metabolism|Sulfur Compound Metabolism|Thiosulfate Oxidation|thiosulfate oxidation II (via tetrathionate)',
    },
    {
        BioCyc_ID: 'PWY-5797',
        Common_Name: 'indole-3-acetate inactivation VI',
        MetaCyc_hierarchy_IDs: 'Activation-Inactivation-Interconversion|Inactivation|Indole-3-Acetate-Inactivation|PWY-5797',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Metabolite Activation/Inactivation/Interconversion|Metabolite Inactivation|Indole-3-acetate Inactivation|indole-3-acetate inactivation VI',
    },
    {
        BioCyc_ID: 'PWY-6266',
        Common_Name: 'superpathway of flavones and derivatives biosynthesis',
        MetaCyc_hierarchy_IDs: 'Super-Pathways|PWY-6266',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Superpathways|superpathway of flavones and derivatives biosynthesis',
    },
    {
        BioCyc_ID: 'FUCCAT-PWY',
        Common_Name: 'L-fucose degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|L-Fucose-Degradation|FUCCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|L-Fucose Degradation|L-fucose degradation I',
    },
    {
        BioCyc_ID: 'PWY-5407',
        Common_Name: '9-lipoxygenase and 9-allene oxide synthase pathway',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|FATTY-ACID-DERIVATIVE-SYN|PWY-5407',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Fatty Acid Derivative Biosynthesis|9-lipoxygenase and 9-allene oxide synthase pathway',
    },
    {
        BioCyc_ID: 'PWY-6596',
        Common_Name: 'adenosine nucleotides degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|NUCLEO-DEG|Purine-Degradation|Adenosine-Nucleotides-Degradation|PWY-6596',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Nucleoside and Nucleotide Degradation|Purine Nucleotide Degradation|Adenosine Nucleotide Degradation|adenosine nucleotides degradation I',
    },
    {
        BioCyc_ID: 'PWY-6090',
        Common_Name: '1,2-dichlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|AROMATIC-COMPOUNDS-DEGRADATION|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6090',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Aromatic Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,2-dichlorobenzene degradation',
    },
    {
        BioCyc_ID: 'PWY-6090',
        Common_Name: '1,2-dichlorobenzene degradation',
        MetaCyc_hierarchy_IDs: 'Degradation|CHLORINATED-COMPOUNDS-DEG|Chloroaromatic-Compounds-Degradation|Chlorobenzene-Degradation|PWY-6090',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Chlorinated Compound Degradation|Chloroaromatic Compound Degradation|Chlorobenzene Degradation|1,2-dichlorobenzene degradation',
    },
    {
        BioCyc_ID: 'GALACTUROCAT-PWY',
        Common_Name: 'D-galacturonate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|CARBOXYLATES-DEG|SUGAR-ACIDS-DEG|D-Galacturonate-Degradation|GALACTUROCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carboxylic Acid Degradation|Uronic Acid Degradation|D-Galacturonate Degradation|D-galacturonate degradation I',
    },
    {
        BioCyc_ID: 'GALACTUROCAT-PWY',
        Common_Name: 'D-galacturonate degradation I',
        MetaCyc_hierarchy_IDs: 'Degradation|Carbohydrates-Degradation|Sugars-And-Polysaccharides-Degradation|SUGAR-ACIDS-DEG|D-Galacturonate-Degradation|GALACTUROCAT-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|Carbohydrate Degradation|Sugar Degradation|Uronic Acid Degradation|D-Galacturonate Degradation|D-galacturonate degradation I',
    },
    {
        BioCyc_ID: 'PWY66-420',
        Common_Name: 'carnosine biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|Metabolic-Regulators|PWY66-420',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Biosynthesis|Metabolic Regulator Biosynthesis|carnosine biosynthesis',
    },
    {
        BioCyc_ID: 'CO2FORM-PWY',
        Common_Name: 'methanogenesis from methanol',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|CO2FORM-PWY',
        MetaCyc_hierarchy_Names: 'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|methanogenesis from methanol',
    },
    {
        BioCyc_ID: 'CO2FORM-PWY',
        Common_Name: 'methanogenesis from methanol',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|Respiration|ANAEROBIC-RESPIRATION|METHANOGENESIS|CO2FORM-PWY',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Respiration|Anaerobic Respiration|Methanogenesis|methanogenesis from methanol',
    },
    {
        BioCyc_ID: 'PWY-6431',
        Common_Name: '4-hydroxybenzoate biosynthesis IV (plants)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|4-Hydroxybenzoate-Biosynthesis|PWY-6431',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|4-Hydroxybenzoate Biosynthesis|4-hydroxybenzoate biosynthesis IV (plants)',
    },
    {
        BioCyc_ID: 'PWY-1881',
        Common_Name: 'formate oxidation to CO<sub>2</sub>',
        MetaCyc_hierarchy_IDs: 'Degradation|C1-COMPOUNDS|PWY-1881',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Degradation/Utilization/Assimilation|C1 Compound Utilization and Assimilation|formate oxidation to CO<sub>2</sub>',
    },
    {
        BioCyc_ID: 'PWY-1881',
        Common_Name: 'formate oxidation to CO<sub>2</sub>',
        MetaCyc_hierarchy_IDs: 'Energy-Metabolism|CHEMOAUTOTROPHIC-ENERGY-METABOLISM|PWY-1881',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Generation of Precursor Metabolites and Energy|Chemoautotrophic Energy Metabolism|formate oxidation to CO<sub>2</sub>',
    },
    {
        BioCyc_ID: 'PWY-5116',
        Common_Name: 'sakuranetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|FLAVANONES-SYN|PWY-5116',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Flavanone Biosynthesis|sakuranetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-5116',
        Common_Name: 'sakuranetin biosynthesis',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Toxin-Biosynthesis|PHYTOALEXIN-SYN|FLAVONOID-PHYTOALEXINS|PWY-5116',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Toxin Biosynthesis|Phytoalexin Biosynthesis|Flavonoid Phytoalexin Biosynthesis|sakuranetin biosynthesis',
    },
    {
        BioCyc_ID: 'PWY-7267',
        Common_Name: 'anthocyanin biosynthesis (pelargonidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|AROMATIC-COMPOUNDS-BIOSYN|PHENYLPROPANOID-SYN|FLAVONOID-SYN|ANTHOCYANIN-SYN|PWY-7267',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Aromatic Compound Biosynthesis|Phenylpropanoid Derivative Biosynthesis|Flavonoid Biosynthesis|Anthocyanin Biosynthesis|anthocyanin biosynthesis (pelargonidin 3-<i>O</i>-glucoside)',
    },
    {
        BioCyc_ID: 'PWY-7267',
        Common_Name: 'anthocyanin biosynthesis (pelargonidin 3-<i>O</i>-glucoside)',
        MetaCyc_hierarchy_IDs: 'Biosynthesis|SECONDARY-METABOLITE-BIOSYNTHESIS|Pigment-Biosynthesis|ANTHOCYANIN-SYN|PWY-7267',
        MetaCyc_hierarchy_Names:
            'All Pathways and Reactions|Pathways|Biosynthesis|Secondary Metabolite Biosynthesis|Pigment Biosynthesis|Anthocyanin Biosynthesis|anthocyanin biosynthesis (pelargonidin 3-<i>O</i>-glucoside)',
    },
]
