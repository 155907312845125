import { useState } from 'react'
import { Grid, Container } from '@mui/material'
import { useStorageContext } from '../../context/Storage'
import { GridSamples } from '../../components/grid-samples'
import { StatusMenu } from './StatusMenu'
import { useNotificationContext } from '../../context/NotificationContext'
import { getSamplesBySampleId, updateSampleStatus } from '../../libs/api/samples/SampleAPI'

// TODO: Show progress indicator when marking samples as received

export default function Status() {
    const { samplesSearchContext, updateSamplesSearchContext } = useStorageContext()
    const openNotification = useNotificationContext().openNotification
    const [sampleId, setSampleId] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])

    async function loadSample() {
        if (samplesSearchContext && samplesSearchContext.samples.find((item) => item.id === sampleId)) {
            openNotification(`Sample ID ${sampleId} already in table`)
            setSampleId('')
            return
        }

        setLoading(true)
        const { succeeded, data, errors } = await getSamplesBySampleId(sampleId)
        if (succeeded) {
            const newSamples = samplesSearchContext ? [...samplesSearchContext.samples, ...data] : data
            updateSamplesSearchContext({
                ...samplesSearchContext,
                samples: newSamples,
            })
        } else {
            openNotification(errors.message, errors.severity)
        }
        setLoading(false)
        setSampleId('')
    }

    async function updateStatus() {
        setLoading(true)
        const success: string[] = []
        const fail: string[] = []
        for (const id of selectedRows) {
            const { succeeded } = await updateSampleStatus(id, 'Received')
            if (succeeded) {
                success.push(id)
            } else {
                fail.push(id)
            }
        }
        if (fail.length === 0) {
            openNotification('All samples successfully updated', 'success')
        } else {
            openNotification(`Samples updated: ${success.length > 0 ? JSON.stringify(success) : 'None'} / Failed to update: ${JSON.stringify(fail)}`, 'warning')
        }
        setLoading(false)
    }

    const deleteSample = (id) => {
        const newSamples = samplesSearchContext.samples.filter((item) => item.id !== id)
        updateSamplesSearchContext({
            ...samplesSearchContext,
            samples: newSamples,
        })
    }

    const getSelectedRows = (ids: string[]) => {
        setSelectedRows(ids)
    }

    const updateSampleId = (id: string) => {
        setSampleId(id)
    }

    return (
        <Container maxWidth={false} sx={{ padding: '20px' }}>
            <Grid container spacing={2}>
                <Grid item xs={10} sx={{ paddingRight: '20px' }}>
                    <GridSamples
                        samples={samplesSearchContext && samplesSearchContext.samples}
                        loading={loading}
                        enableCheckbox={true}
                        deleteRow={deleteSample}
                        getSelectedRows={getSelectedRows}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    sx={{
                        minWidth: '230px',
                        paddingLeft: '20px',
                        borderLeft: '1px solid rgba(19, 19, 32, .12)',
                    }}
                >
                    <StatusMenu
                        sampleId={sampleId}
                        loading={loading}
                        loadSample={loadSample}
                        updateSampleId={updateSampleId}
                        updateSamplesContext={updateSamplesSearchContext}
                        updateStatus={updateStatus}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
