import { Fragment, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import EditSample from './EditSample'
import InfoCard from '../InfoCard'
import SampleMap from '../../../../components/Map'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import { collectionFields, environmentalFields } from './Summary.model'
import { Main } from '../../../../components/SidebarDrawer'
import { SummarySidebar } from './SummarySidebar'

export interface SummaryProps {
    isSampleLoaded: boolean
    sampleData: any
    editSampleData: boolean
    setEditSampleData: (editMode: boolean) => void
}

/**
 * The Summary tab component for the samples-details page
 */
export default function Summary(props: SummaryProps) {
    const { isSampleLoaded, sampleData, editSampleData, setEditSampleData } = props

    const [showSidebar, setShowSidebar] = useState<boolean>(true)

    return (
        <Box id="summary" sx={{ display: 'flex', width: '100%' }}>
            <SummarySidebar open={showSidebar} onClose={() => setShowSidebar(false)} />
            <Main open={showSidebar} sx={{ width: 'auto', flex: 1, overflow: 'hidden' }}>
                <Grid display="flex" flexDirection="row">
                    <IconButton
                        onClick={() => setShowSidebar(true)}
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            mar: 2,
                            paddingTop: '2px',
                            ...(showSidebar && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>
                <Grid container spacing={2}>
                    {sampleData ? (
                        editSampleData ? (
                            <Grid item xs={12}>
                                <EditSample setEditData={setEditSampleData} />
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item xs={4}>
                                    <InfoCard title="Collection Information" gridItems={collectionFields} data={sampleData} />
                                </Grid>
                                <Grid item xs={4}>
                                    <InfoCard title="Environmental Information" gridItems={environmentalFields} data={sampleData} />
                                </Grid>
                                <Grid item xs={4}>
                                    <SampleMap />
                                </Grid>
                            </Fragment>
                        )
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                height: '600px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <p>Loading sample data</p>
                            <ProgressIndicator open={!isSampleLoaded}></ProgressIndicator>
                        </Grid>
                    )}
                </Grid>
            </Main>
        </Box>
    )
}
