import { Project, ProjectStatus, ProjectStatusHelperMessage } from '../../Projects.model'
import _ from 'lodash'

// Minimun length allowed for project name property
export const MIN_PROJECT_NAME_LENGTH = 5

/**
 * Get project status description text based on project status
 * @param status The project status
 * @returns The project status description text
 */
export function getCurrentStatusHelperMessage(status: ProjectStatus): string {
    let message = ''
    switch (status) {
        case ProjectStatus.open:
            message = ProjectStatusHelperMessage.open
            break
        case ProjectStatus.close:
            message = ProjectStatusHelperMessage.close
            break
        case ProjectStatus.archive:
            message = ProjectStatusHelperMessage.archive
            break
    }
    return message
}

/**
 * Gets the project privacy description text based on project privacy level
 * @param isPublic The project privacy level
 * @returns The project privacy description text
 */
export function getIsPublicHelperMessage(isPublic: boolean): string {
    let message = ''
    if (isPublic) {
        message = 'M-MAP users can view the following information about samples'
    } else {
        message = 'Samples information is restricted to invited users'
    }
    return message
}

/**
 * Verify is form data has been modified
 * @param project Project data present in the form
 * @param currentProject The current project data in storage
 * @returns True if project form is modified
 */
export function isFormModified(project: Project, currentProject: Project): boolean {
    let isModified = false
    if (project.id) {
        isModified = !(
            (!currentProject.name || project.name === currentProject.name) &&
            project.description === currentProject.description &&
            (project.isPublic || false) === currentProject.isPublic &&
            project.status === currentProject.status &&
            _.isEqual(project.publicSampleProps || [], currentProject.publicSampleProps)
        )
    } else {
        isModified = currentProject.name !== undefined && currentProject.name.length >= MIN_PROJECT_NAME_LENGTH
    }
    return isModified
}
