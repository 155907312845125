import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        appbar: React.CSSProperties
        heroHeading: React.CSSProperties
        heroSubHeading: React.CSSProperties
        heroBody: React.CSSProperties
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        appbar?: React.CSSProperties
        heroHeading?: React.CSSProperties
        heroSubHeading?: React.CSSProperties
        heroBody?: React.CSSProperties
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        appbar: true
        heroHeading: true
        heroSubHeading: true
        heroBody: true
    }
}

export const mmapTheme = createTheme({
    palette: {
        primary: {
            main: '#1B49D4',
            light: '#E3E7FF',
            dark: '#22223A',
        },
        secondary: {
            main: '#F2742F',
        },
    },
    typography: {
        fontFamily: '"Graphik", sans-serif',
        h1: {
            // Heading XXL
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 480,
            fontSize: '96px',
        },
        h2: {
            // Heading XL
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 300,
            fontSize: '60px',
        },
        h3: {
            // Heading L
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 500,
            fontSize: '48px',
        },
        h4: {
            // Heading M
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 500,
            fontSize: '34px',
        },
        h5: {
            // Heading S
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 400,
            fontSize: '24px',
        },
        subtitle1: {
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 500,
            fontSize: '16px',
        },
        appbar: {
            fontFamily: '"Fraunces", serif',
            fontWeight: 600,
            fontSize: '25px',
        },
        heroHeading: {
            // Heading XXL
            fontFamily: '"Fraunces", serif',
            fontWeight: 700,
            fontSize: '43px',
        },
        heroSubHeading: {
            // Heading XL
            fontFamily: '"Fraunces", serif',
            fontWeight: 700,
            fontSize: '37px',
        },
        heroBody: {
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 400,
            fontSize: '17px',
        },
        body1: {
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 400,
            fontSize: '16px',
        },
        body2: {
            fontFamily: '"Graphik", sans-serif',
            fontWeight: 400,
            fontWeightBold: 400,
            fontSize: '14px',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
            },
        },
    },
})
