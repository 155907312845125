import { Md5 } from 'ts-md5'
import { highlightKeyword, isKeywordInText } from '../../../../components/SearchText'
import { MetabolicPathwaysData } from './MetabolicPathways'

/**
 * Searches for the keyword in names and appends html elements for highlight on rendering
 * Also filter out the items that don't contain keyword
 *
 * @param keyword The keyword text to search and highlight
 * @param pathways The naming pathways array
 * @returns The filtered naming pathways arrays with highlighted texts
 */
export function highlightNamingPathways(keyword: string, pathways: any[]): any[] {
    if (!keyword) {
        return pathways
    }

    const highlightedPathways = []
    pathways.forEach((item) => {
        if (isKeywordInText(item.name, keyword)) {
            highlightedPathways.push({
                ...item,
                name: highlightKeyword(item.name, keyword),
            })
        }
    })

    return highlightedPathways
}

/**
 * Searches for the keyword in names and group fields (0, 1, 2, ...) and appends html elements for highlight on rendering
 * This function highlights the name/group and every parent pathway in order to help group expansion
 * Also filter out the items that don't contain keyword
 *
 * @param keyword The keyword text to search and highlight
 * @param pathways The full metabolic pathways array
 * @returns The filtered full metabolic pathways arrays with highlighted texts
 */
export function highlightFullPathways(keyword: string, pathways: any[]): any[] {
    if (!keyword) {
        return pathways
    }

    const result = []
    pathways.forEach((item) => {
        let indexFoundInGroup = -1
        const foundInName = isKeywordInText(item.name, keyword)
        if (!foundInName) {
            indexFoundInGroup = higherIndexOfKeywordInGroups(keyword, item)
            if (indexFoundInGroup < 0) {
                return null
            }
        }

        result.push({
            ...item,
            name: highlightKeyword(item.name, keyword),
            '0': item['0'] && (foundInName || indexFoundInGroup >= 0) ? highlightKeyword(item['0'], keyword) : undefined,
            '1': item['1'] && (foundInName || indexFoundInGroup > 0) ? highlightKeyword(item['1'], keyword) : undefined,
            '2': item['2'] && (foundInName || indexFoundInGroup > 1) ? highlightKeyword(item['2'], keyword) : undefined,
            '3': item['3'] && (foundInName || indexFoundInGroup > 2) ? highlightKeyword(item['3'], keyword) : undefined,
            '4': item['4'] && (foundInName || indexFoundInGroup > 3) ? highlightKeyword(item['4'], keyword) : undefined,
            '5': item['5'] && (foundInName || indexFoundInGroup > 4) ? highlightKeyword(item['5'], keyword) : undefined,
            '6': item['6'] && (foundInName || indexFoundInGroup > 5) ? highlightKeyword(item['6'], keyword) : undefined,
        })
    })

    return result
}

/**
 * Returns the highest group index  (1, 2, 3, ...) where the keyword was found
 *
 * @param keyword The text to search for
 * @param pathwayItem The full pathway item
 * @returns The group index where the searched text was found. -1 is not in text.
 */
function higherIndexOfKeywordInGroups(keyword: string, pathwayItem: any): number {
    let index = -1
    for (let i = 0; i < 7; i++) {
        const text = pathwayItem[i]
        if (text && isKeywordInText(text, keyword)) {
            index = i
        }
    }

    return index
}

/**
 * Maps the functional pathway profiles data from the sample with the functional inference DB
 * in order to build the Metabolic Pathways
 *
 * @param pathwayProfiles The functional pathway profiles data for the sample
 * @param functionalInferenceDB The general functional inference data
 * @returns The mapped metabolic pathways
 */
export function mapMetabolicPathways(pathwayProfiles: any, functionalInferenceDB: any[]): MetabolicPathwaysData {
    const namingPathways: PathwayItem[] = []
    const nameSet = new Set() // Used to detect duplicates in name

    const fullPathways = functionalInferenceDB.map((item) => {
        const hierarchyIds: string[] = item.MetaCyc_hierarchy_IDs.split('|')
        // Removed pathwayId (last item from array)
        hierarchyIds.pop()

        const hierarchyNames: string[] = item.MetaCyc_hierarchy_Names.replace('All Pathways and Reactions|Pathways|', '').split('|')
        const id = Md5.hashStr(item.MetaCyc_hierarchy_IDs)

        const pathwayProfile = pathwayProfiles.find((x) => x.name === item.BioCyc_ID)

        let pathwayObject = {
            id: id,
            name: '',
            asvsCount: pathwayProfile && pathwayProfile.asvCount,
            abundance: pathwayProfile && pathwayProfile.abundance,
            confidence: pathwayProfile && Math.trunc(pathwayProfile.confidence),
        }

        if (!nameSet.has(item.Common_Name)) {
            nameSet.add(item.Common_Name)
            namingPathways.push({
                ...pathwayObject,
                name: item.Common_Name,
            })
        }

        hierarchyNames.map((item, index) => {
            if (index === hierarchyNames.length - 1) {
                pathwayObject = {
                    ...pathwayObject,
                    name: item,
                }
            } else {
                pathwayObject = {
                    ...pathwayObject,
                    [index]: item,
                }
            }
            return index
        })

        return pathwayObject
    })

    namingPathways.sort(pathwayNamesSort)
    fullPathways.sort(pathwaySort)

    return {
        originalPathways: { namingPathways: namingPathways, fullPathways: fullPathways },
        searchedPathways: { namingPathways: namingPathways, fullPathways: fullPathways },
    }
}

/**
 * Sort algorithm for the metabolic pathways array
 */
const pathwaySort = (a, b) => {
    return a['0'] > b['0'] ? 1 : -1
}

/**
 * Sort algorithm for the metabolic pathways names array
 */
const pathwayNamesSort = (a, b) => {
    return a['name'] > b['name'] ? 1 : -1
}

interface PathwayItem {
    id: string
    name: string
    asvsCount: number
    abundance: number
    confidence: number
}
