import { useContext, useEffect, useState } from 'react'
import { Avatar, Button, Container, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import SearchText from '../../components/SearchText'
import ProjectView from './subcomponents/ProjectView'
import ProjectForm from './subcomponents/ProjectForm'
import { GridProjectOptions, Project, ViewMode, projectNavItems } from './Projects.model'
import { useStorageContext } from '../../context/Storage'
import { getProjectsDataGrid } from '../../libs/api/projects/ProjectAPI'
import { useNotificationContext } from '../../context/NotificationContext'
import { UserProfileContext } from '../../context/CustomContext'

/**
 * The Projects Management component
 */
export default function Projects() {
    const { getContextProjects, updateContextProjects } = useStorageContext()
    const openNotification = useNotificationContext().openNotification
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [selectedProjectOption, setSelectedProjectOption] = useState<GridProjectOptions>(GridProjectOptions.myprojects)
    const [projectList, setProjectList] = useState<Project[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.grid)
    const [projectFormData, setProjectFormData] = useState<Project>(null)
    const [refreshLocalStorage, setRefreshLocalStorage] = useState<boolean>(false)
    const userProfile = useContext(UserProfileContext)

    const handleSearchTerm = (searchTerm: string) => {
        setSearchTerm(searchTerm)
    }

    const handleGridProjectSelection = (selectedGridProject: GridProjectOptions) => {
        setSelectedProjectOption(selectedGridProject)
    }

    const handleViewModeUpdate = (viewMode: ViewMode, project?: Project) => {
        setProjectFormData(project)
        setTimeout(() => {
            setViewMode(viewMode)
        }, 0)
    }

    const handleNewProjectCreated = () => {
        setRefreshLocalStorage(true)
        setSelectedProjectOption(GridProjectOptions.myprojects)
        handleViewModeUpdate(ViewMode.grid)
    }

    const handleProjectDeleted = () => {
        setRefreshLocalStorage(true)
        setSelectedProjectOption(GridProjectOptions.myprojects)
        handleViewModeUpdate(ViewMode.grid)
    }

    useEffect(() => {
        // Try to load the projects from local storage, falling back to API call
        async function loadProjects(selected: GridProjectOptions) {
            let projects = getContextProjects(selectedProjectOption)
            if (projects.length <= 0 || refreshLocalStorage) {
                setProjectList([])
                setLoading(true)
                const { succeeded, data, errors } = await getProjectsDataGrid(selected)
                if (succeeded) {
                    projects = data
                    updateContextProjects(selected, projects)
                    if (refreshLocalStorage) {
                        setRefreshLocalStorage(false)
                        if (projectFormData) {
                            setProjectFormData(projects.find((x) => x.id === projectFormData.id))
                        }
                    }
                } else {
                    openNotification(errors.message, errors.severity)
                }

                setLoading(false)
            }
            setProjectList(projects)
        }

        loadProjects(selectedProjectOption)
    }, [selectedProjectOption, refreshLocalStorage])

    const projectsNav = projectNavItems.map((item) => {
        return (
            <ListItem key={item.gridOption} sx={{ p: 0 }}>
                <ListItemButton
                    disabled={loading || !item.enabled || viewMode !== ViewMode.grid}
                    selected={item.gridOption === selectedProjectOption}
                    sx={{ p: 0 }}
                    onClick={() => handleGridProjectSelection(item.gridOption)}
                >
                    <ListItemIcon>
                        <IconButton color="default" size="medium">
                            {item.icon}
                        </IconButton>
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant="body1" style={{ color: 'rgba(19, 19, 32, 0.87)' }}>
                                {item.label}
                            </Typography>
                        }
                    />
                </ListItemButton>
            </ListItem>
        )
    })

    return (
        <Container maxWidth={false}>
            <Grid container spacing={2} wrap="nowrap">
                <Grid
                    item
                    xs={2}
                    sx={{
                        minWidth: '230px',
                        paddingRight: '20px',
                        borderRight: '1px solid rgba(19, 19, 32, .12)',
                    }}
                >
                    <Grid
                        sx={{
                            p: '20px 0',
                            borderBottom: '1px solid rgba(19, 19, 32, .12)',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <Avatar alt="Anonimous Avatar" src="/images/avatar-anonimous.svg" />
                            <Typography variant="subtitle1" 
                                sx={{ 
                                    marginLeft: '10px',
                                    overflow: 'hidden'
                                }}>
                                <Grid sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {userProfile.email}
                                </Grid>
                                {userProfile.organizationName && 
                                    <small>{userProfile.organizationName}</small>
                                }
                            </Typography>
                        </Grid>
                        <SearchText disabled={viewMode !== ViewMode.grid} label="Search project..." onSearchChange={handleSearchTerm} />
                    </Grid>
                    <Grid item sx={{ paddingTop: '20px' }}>
                        <Button
                            disabled={viewMode !== ViewMode.grid}
                            variant="contained"
                            color="primary"
                            onClick={() => handleViewModeUpdate(ViewMode.new)}
                            sx={{
                                padding: '10px 30px',
                                borderRadius: '10px',
                                margin: '0 0 15px 5px',
                            }}
                        >
                            <Typography textTransform="capitalize">+ Project</Typography>
                        </Button>
                        <List>{projectsNav}</List>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    {viewMode === ViewMode.grid && (
                        <ProjectView
                            viewTitle={projectNavItems.find((x) => x.gridOption === selectedProjectOption).label}
                            selectedProjectOption={selectedProjectOption}
                            searchTerm={searchTerm}
                            projects={projectList}
                            loading={loading}
                            onEditProjectView={(project) => handleViewModeUpdate(ViewMode.update, project)}
                            refreshLocalStorage={() => setRefreshLocalStorage(true)}
                        />
                    )}
                    {(viewMode === ViewMode.new || viewMode === ViewMode.update) && (
                        <ProjectForm
                            closeForm={() => handleViewModeUpdate(ViewMode.grid)}
                            refreshLocalStorage={() => setRefreshLocalStorage(true)}
                            project={projectFormData}
                            onProjectCreated={handleNewProjectCreated}
                            onProjectDeleted={handleProjectDeleted}
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}
