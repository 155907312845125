export enum GridFileTypeOption {
    solidGeochemicalData,
    trash,
}

export type FileTypeNavItemData = {
    gridOption: GridFileTypeOption
    label: string
    icon: React.ReactElement
    enabled: boolean
}

export const fileTypeNavItems = [
    {
        gridOption: GridFileTypeOption.solidGeochemicalData,
        label: 'Solid Geochemical Data',
        icon: <img alt="Element Icon" className="icon" src="/images/element.svg" />,
        enabled: true,
    },
]

export type SolidGeochemicalFile = {
    id: string
    fileName: string
    associatedSampleIDs: string[]
    vendor: string
    uploadDate: string
}
