import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Organization } from '../../libs/api/organization/OrganizationModel'

const APPROVE_DIALOG_TITLE = 'Approve request for'
const REJECT_DIALOG_TITLE = 'Reject request for'

export interface UserRequestConfirmationDialogProps {
    openDialog: boolean
    userName: string
    isApproved: boolean
    organizations: Organization[]
    onApprove: (organization?: string) => void
    onReject: (rejectReason?: string) => void
    onCancel: () => void
}

export default function UserRequestConfirmationDialog(props: UserRequestConfirmationDialogProps) {
    const { openDialog, userName, isApproved, organizations, onApprove, onReject, onCancel } = props
    const [selectedOrganization, setSelectedOrganization] = useState<string>('')
    const [rejectReason, setRejectReason] = useState<string>('')

    const resetDialog = () => {
        setRejectReason('')
        setSelectedOrganization('')
    }

    const handleOrganizationClick = (event: SelectChangeEvent) => {
        setSelectedOrganization(event.target.value as string)
    }

    let timer = null
    const handleRejectReason = (event) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            const reason = event.target.value
            setRejectReason(reason)
        }, 1000)
    }

    return (
        <React.Fragment>
            <Dialog
                open={openDialog}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ style: { borderRadius: 12, padding: 15 } }}
            >
                <DialogTitle id="alert-dialog-title">{`${isApproved ? APPROVE_DIALOG_TITLE : REJECT_DIALOG_TITLE} ${userName}`}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ margin: '8px 0' }}>
                        {isApproved ? (
                            <>
                                <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    displayEmpty
                                    value={selectedOrganization}
                                    label="Organization"
                                    onChange={handleOrganizationClick}
                                >
                                    {organizations &&
                                        organizations.map((organization) => {
                                            return (
                                                <MenuItem key={organization.id} value={organization.id}>
                                                    {organization.name}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </>
                        ) : (
                            <TextField required id="outlined-required" label="Reject Reason" multiline rows={3} defaultValue="" onChange={handleRejectReason}></TextField>
                        )}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            height: '36px',
                            padding: '9px 16px',
                            marginLeft: 'auto',
                            borderRadius: '12px',
                            alignItems: 'flex-start',
                        }}
                        onClick={() => {
                            onCancel()
                            resetDialog()
                        }}
                    >
                        <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                            {'Cancel'}
                        </Typography>
                    </Button>
                    <Button
                        disabled={(isApproved && selectedOrganization.length === 0) || (!isApproved && rejectReason.length <= 3)}
                        variant="contained"
                        color="primary"
                        sx={{
                            height: '36px',
                            padding: '9px 16px',
                            marginLeft: '15px',
                            borderRadius: '12px',
                            alignItems: 'flex-start',
                        }}
                        onClick={() => {
                            if (isApproved) {
                                onApprove(selectedOrganization)
                            } else {
                                onReject(rejectReason)
                            }

                            resetDialog()
                        }}
                        autoFocus
                    >
                        <Typography variant="body2" textTransform="capitalize">
                            {isApproved ? 'Approve' : 'Decline'}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
