import { DataGridPremium, GridCsvExportOptions, useGridApiRef } from '@mui/x-data-grid-premium'
import { useEffect, useState, useContext } from 'react'
import { highlightKeyword } from '../../../../components/SearchText'
import { SampleDataContext } from '../../../../context/CustomContext'

export interface ElementsTableProps {
    elements: any[]
    loading: boolean
    searchTerm: string
    deleteRow?: (id: string) => void
    getSelectedRows?: (ids: string[]) => void
}

export default function ElementsTable(props: ElementsTableProps) {
    const { elements, loading, searchTerm } = props
    const { triggerExport, setTriggerExport, sampleData } = useContext(SampleDataContext)
    const apiRef = useGridApiRef()

    const builtColumns = buildColumns(searchTerm)

    useEffect(() => {
        if (triggerExport) {
            const options: GridCsvExportOptions = {
                fileName: `${sampleData.id} GeochemicalData`,
                fields: ['name', 'valueWithUnit', 'method', 'provider'],
            }
            apiRef.current.exportDataAsCsv(options)
            setTriggerExport(false)
        }
    }, [apiRef, triggerExport, setTriggerExport])

    return (
        <DataGridPremium
            getRowId={(r) => r.id}
            apiRef={apiRef}
            pagination
            autoHeight
            disableAggregation
            disableColumnPinning
            disableColumnSelector
            pageSizeOptions={[25, 50, 100, 250]}
            sortingOrder={['asc', 'desc']}
            rows={elements || []}
            columns={builtColumns}
            checkboxSelection={false}
            loading={loading}
            initialState={initialState}
            slots={{
                noRowsOverlay: () => <NoDataOverlay />,
            }}
            disableRowSelectionOnClick
            sx={{
                '--DataGrid-cellOffsetMultiplier': 2,
                '.MuiDataGrid-cell:not(:has(.MuiDataGrid-groupingCriteriaCell))': {
                    '&:has(.name)': {
                        paddingLeft: '170px',
                    },
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                    outline: 'none',
                },
                '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                },
                '.keyword': {
                    backgroundColor: '#FFEBFF',
                    padding: '8px 0',
                },
                '.MuiDataGrid-row:has([data-testid="KeyboardArrowRightIcon"])': {
                    cursor: 'pointer',
                },
                '.MuiDataGrid-row:has([data-testid="ExpandMoreIcon"])': {
                    cursor: 'pointer',
                    '&:has(.level-0)': {
                        borderBottom: '2px solid rgba(27, 73, 212, 1)',
                    },
                },
                '.MuiDataGrid-row:not(:has(.level-0)):not(:has(.name))': {
                    backgroundImage: 'linear-gradient(rgba(240, 240, 255, 0.35), rgba(240, 240, 255, 0.1))',
                },
                '.MuiDataGrid-columnHeaders': {
                    backgroundImage: 'linear-gradient(rgba(240, 240, 255, 0.35), rgba(240, 240, 255, 0.1))',
                },
                '& .project-name': {
                    fontWeight: 'bold',
                },
                borderTop: 'none',
                borderRight: 'none',
                borderLeft: 'none',
                borderWidth: '1px',
                marginTop: '15px',
                width: '100%',
            }}
        />
    )
}

const buildColumns = (searchTerm) => {
    return [
        { field: 'name', headerName: 'Analyte', flex: 2, groupable: false, renderCell: (params) => renderHTML(params.value, searchTerm) },
        { field: 'valueWithUnit', headerName: 'Value', flex: 2, groupable: false, renderCell: (params) => renderHTML(params.value, searchTerm) },
        { field: 'method', headerName: 'Method', flex: 2, groupable: false, renderCell: (params) => renderHTML(params.value, searchTerm) },
        { field: 'provider', headerName: 'Provider', flex: 1, groupable: false, renderCell: (params) => renderHTML(params.value, searchTerm) },
    ]
}

const initialState = {
    pagination: {
        paginationModel: { pageSize: 50, page: 0 },
    },
}

const NoDataOverlay = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 300)
        return () => clearTimeout(timeout)
    }, [])
    if (!show) return null
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <p>No data found</p>
        </div>
    )
}

const renderHTML = (value: string, searchTerm: string, classes?: string) => {
    const htmlValue = highlightKeyword(value, searchTerm)
    return <div className={classes} dangerouslySetInnerHTML={{ __html: htmlValue }} />
}
