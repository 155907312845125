import { GridProjects } from './projects-grid'
import { GridProjectOptions, Project } from '../Projects.model'
import { ProjectInvite } from '../../../libs/api/projects/ProjectModel'
import { useCallback, useEffect, useState } from 'react'
import { getProjectInvites } from '../../../libs/api/projects/ProjectAPI'
import { useNotificationContext } from '../../../context/NotificationContext'
import { Grid, Typography } from '@mui/material'
import { ProjectInvitesGrid } from './projects-grid/ProjectInvitesGrid'

interface ProjecViewProps {
    viewTitle: string
    selectedProjectOption: GridProjectOptions
    searchTerm: string
    projects: any
    loading: boolean
    onEditProjectView: (project: Project) => void
    refreshLocalStorage: () => void
}

/**
 * Project Management component to visualized the list of projects in a datagrid format
 */
export default function ProjectView(props: ProjecViewProps) {
    const { viewTitle, selectedProjectOption, searchTerm, projects, loading, onEditProjectView, refreshLocalStorage } = props
    const [invites, setInvites] = useState<ProjectInvite[]>([])

    const openNotification = useNotificationContext().openNotification

    const loadProjectInvites = useCallback(async () => {
        const { succeeded, data, errors } = await getProjectInvites()
        if (succeeded) {
            const newData = data.map((x) => {
                return {
                    ...x,
                    invites: 'Pending Invitations',
                }
            })

            setInvites(newData)
        } else {
            openNotification(errors.message, errors.severity)
        }
    }, [])

    useEffect(() => {
        loadProjectInvites()
    }, [])

    const handleRefreshProjectView = () => {
        refreshLocalStorage()
        loadProjectInvites()
    }

    const handleProjectEditView = (projectId: string) => {
        const project = projects.find((x) => x.id === projectId)
        onEditProjectView(project)
    }

    return (
        <Grid>
            <Typography variant="h4" style={{ padding: '20px 0' }}>
                {viewTitle}
            </Typography>
            <>
                {selectedProjectOption === GridProjectOptions.shared && invites && invites.length > 0 ? (
                    <ProjectInvitesGrid invites={invites} onRefreshProjectView={handleRefreshProjectView} />
                ) : null}
            </>
            <GridProjects
                projects={projects.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))}
                loading={loading}
                onEditProjectView={handleProjectEditView}
            />
        </Grid>
    )
}
