import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { FileTypeNavItemData } from './ManageData.model'

export interface FileTypeNavItemProps {
    item: FileTypeNavItemData
    isSelected: boolean
    onSelect: any
}

export default function FileTypeNavItem(props: FileTypeNavItemProps) {
    const { item, isSelected, onSelect } = props
    return (
        <ListItem key={item.gridOption} sx={{ p: 0 }}>
            <ListItemButton selected={isSelected} sx={{ p: 0 }} onClick={() => onSelect(item.gridOption)}>
                <ListItemIcon>
                    <IconButton color="default" size="medium">
                        {item.icon}
                    </IconButton>
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="body1" style={{ color: 'rgba(19, 19, 32, 0.87)' }}>
                            {item.label}
                        </Typography>
                    }
                />
            </ListItemButton>
        </ListItem>
    )
}
