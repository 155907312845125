import { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Grid, TextField, MenuItem, Card, CardHeader, CardContent, CardActions, Button, Divider, Box } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { SampleDataContext } from '../../../../context/CustomContext'
import {
    phValidTypes,
    projects,
    sampleTypes,
    samplingMethods,
    editSampleFormSchema,
    shippingConditions,
    statusOptions,
    waterPreservationMethods,
    waterSampleTypes,
    weatherConditions,
} from './Summary.model'
import { useNotificationContext } from '../../../../context/NotificationContext'
import { updateSample } from '../../../../libs/api/samples/SampleAPI'

// TODO: Add ability to add and delete photos

/**
 * Form component for samples data update
 */
export default function EditSample(props: any) {
    const { setEditData } = props
    const { sampleData, setSampleData } = useContext(SampleDataContext)
    const openNotification = useNotificationContext().openNotification

    const { control, handleSubmit, watch } = useForm({
        resolver: yupResolver(editSampleFormSchema),
        defaultValues: {
            ...sampleData,
            collectionDate: dayjs(sampleData.collectionDate),
        },
        mode: 'onChange',
    })

    // Disable fields and associated validation based on sample type
    const watchSampleType = watch('sampleType', false)
    const [phDisabled, setPhDisabled] = useState(false)
    const [waterDisabled, setWaterDisabled] = useState(false)

    useEffect(() => {
        setPhDisabled(!phValidTypes.includes(watchSampleType))
        setWaterDisabled(!waterSampleTypes.includes(watchSampleType))
    }, [watchSampleType])

    const watchWeather = watch('weather', false)
    const watchSamplingMethod = watch('samplingMethod', false)

    async function onSubmit(sampleData: any) {
        const newSampleData = {
            ...sampleData,
            collectionDate: sampleData.collectionDate.format(),
        }
        const { succeeded, errors } = await updateSample(newSampleData)
        if (succeeded) {
            setSampleData(newSampleData)
            setEditData(false)
            openNotification('Sample Successfully updated', 'success')
        } else {
            openNotification(errors.message, errors.severity)
        }
    }

    return (
        <Card variant="outlined">
            <CardHeader title="Edit Sample" />
            <CardContent>
                <Box sx={{ my: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="userDefinedSampleId" label="User Defined Sample ID" control={control} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomSelect name="projectName" label="Project" control={control} items={projects} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="sampleLocationId" label="Sample Location ID" control={control} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <Controller
                                name="collectionDate"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => <DateTimePicker label="Collection Date" value={value} onChange={onChange} inputRef={ref} />}
                            />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomSelect name="sampleType" label="Sample Type" control={control} items={sampleTypes} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomSelect name="samplingMethod" label="Sampling Method" control={control} items={samplingMethods} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="otherSamplingMethod" label="Other Sampling Method" control={control} disabled={watchSamplingMethod !== 'Other'} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomSelect name="shippingCondition" label="Shipping Condition" control={control} items={shippingConditions} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomSelect name="waterPreservation" label="Water Preservation Method" control={control} items={waterPreservationMethods} disabled={waterDisabled} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="waterQualitySampleId" label="Water Quality Sample ID" control={control} disabled={waterDisabled} />
                        </Grid>
                    </Grid>
                </Box>
                <Divider variant="middle" />
                <Box sx={{ my: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} xl={3}>
                            <CustomSelect name="weather" label="Weather" control={control} items={weatherConditions} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="otherWeather" label="Other Weather" control={control} disabled={watchWeather !== 'Other'} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="depth" label="Depth" control={control} rules={{ required: 'Depth is required' }} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput
                                name="temperature"
                                label="Temperature"
                                control={control}
                                rules={{
                                    min: -40,
                                    max: 100,
                                    required: 'Temperature is required',
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="ph" label="pH" control={control} disabled={phDisabled} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="do" label="Disolved Oxygen (DO)" control={control} disabled={waterDisabled} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="orp" label="Oxidation-Reduction Potential (ORP)" control={control} disabled={waterDisabled} />
                        </Grid>
                        <Grid item xs={4} xl={3}>
                            <CustomInput name="conductivity" label="Conductivity" control={control} disabled={waterDisabled} />
                        </Grid>
                    </Grid>
                </Box>
                <Divider variant="middle" />
                <Box sx={{ my: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomInput name="notes" label="Notes" multiline={true} control={control} />
                        </Grid>
                    </Grid>
                </Box>
                <Divider variant="middle" />
                <Box sx={{ my: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} xl={3}>
                            <CustomSelect name="status" label="Status" control={control} items={statusOptions} />
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={() => setEditData(false)}>
                    Cancel
                </Button>
                <Button size="small" onClick={handleSubmit(onSubmit)}>
                    Save
                </Button>
            </CardActions>
        </Card>
    )
}

function CustomInput(props: any) {
    const { name, label, control, rules, disabled, multiline } = props

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <TextField
                    fullWidth
                    label={label}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    inputRef={ref}
                    multiline={multiline}
                    error={error ? true : false}
                    helperText={error ? error.message : ''}
                />
            )}
        />
    )
}

function CustomSelect(props: any) {
    const { name, label, control, items, disabled } = props

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value, ref } }) => (
                <TextField fullWidth select label={label} value={value} onChange={onChange} inputRef={ref} disabled={disabled}>
                    {items.map((item: any) => {
                        return (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </TextField>
            )}
        />
    )
}
