import { AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomAccordion } from './SampleSearch.styles'
import { SELECTION_FILTERS, SampleSearchFilter } from './SampleSearch.constants'

export interface SelectionFilter {
    name: string
    title: string
    collection: any
}

export interface SelectionFilterProps {
    selectionTypeName: SampleSearchFilter
    currentSelection: string[]
    onSelectionUpdate: (property: string, key: string, checked: boolean) => void
}

/**
 * Generic list selection component
 */
export function SelectionFilter(props: SelectionFilterProps) {
    const { selectionTypeName, currentSelection, onSelectionUpdate } = props
    const { name, title, collection } = SELECTION_FILTERS.find((item) => item.name === selectionTypeName)

    return (
        <CustomAccordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={name} id={name} className={currentSelection && 'active'}>
                {title}
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    {Object.keys(collection).map((key) => {
                        const value = collection[key]
                        const isChecked = (currentSelection && currentSelection.includes(value)) || false
                        return <FormControlLabel key={key} control={<Checkbox checked={isChecked} onChange={() => onSelectionUpdate(name, value, !isChecked)} />} label={value} />
                    })}
                </FormGroup>
            </AccordionDetails>
        </CustomAccordion>
    )
}
