import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

const instrumentationKey: string = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING || 'InstrumentationKey=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
    config: {
        connectionString: instrumentationKey,
        correlationHeaderExcludedDomains: ['*.arcgisonline.com', '*.arcgis.com'], // Otherwise we get CORS errors for arcgis
        extensions: [reactPlugin as any],
        extensionConfig: {},
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
    },
})
appInsights.loadAppInsights()

appInsights.addTelemetryInitializer((env) => {
    if (env.baseData && (env.baseType === 'PageviewData' || env.baseType === 'PageviewPerformanceData')) {
        env.baseData.name = window.location.pathname
    }
})

export { reactPlugin, appInsights }
