import { FunctionalInferenceDTO } from './FunctionalInferenceDTO'
import { FunctionalInference } from './FunctionalInferenceModel'
import BaseAPI, { ApiResponse } from '../BaseAPI'
import { mapFunctionInferenceDtoToFunctionalInference } from './FunctionalInferenceMapper'

/**
 * Gets the functional inference data from API
 * @param ssuRunId The ssuuRunId
 * @returns An APIResponse model with the functional inference model
 */
export async function getFunctionalInference(ssuRunId: string): Promise<ApiResponse<FunctionalInference>> {
    const response = await BaseAPI.get<FunctionalInferenceDTO>(`/functionalInference/id`, ssuRunId)

    const functionalInferenceModel = response.data && mapFunctionInferenceDtoToFunctionalInference(response.data)

    return {
        ...response,
        data: functionalInferenceModel,
    }
}
