import { Box } from '@mui/material'
import { PageUnderConstruction } from '../../PageUnderConstruction'

export function Mags() {
    return (
        <Box id="mags" sx={{ display: 'flex', justifyContent: 'center' }}>
            <PageUnderConstruction pageName="Mags" />
        </Box>
    )
}
