export enum TabItemValues {
    Summary = 'summary',
    Taxonomy = 'taxonomy',
    PredictedGenes = 'predicted-genes',
    MetabolicPathways = 'metabolic-pathways',
    MAGs = 'mags',
    Photos = 'photos',
    GeochemicalData = 'geochemical-data',
}

/**
 * publicProjectVisibilityKey: Indicates the publicSampleProps key required in public projects to be displayed
 */
export const TabItems = [
    { name: 'Summary', value: TabItemValues.Summary },
    { name: 'Taxonomy', value: TabItemValues.Taxonomy },
    { name: 'Predicted Genes', value: TabItemValues.PredictedGenes },
    { name: 'Metabolic Pathways', value: TabItemValues.MetabolicPathways },
    { name: 'MAGs', value: TabItemValues.MAGs },
    { name: 'Geochemical Data', value: TabItemValues.GeochemicalData, publicProjectVisibilityKey: 'geochemicalData' },
    { name: 'Photos', value: TabItemValues.Photos, publicProjectVisibilityKey: 'photos' },
]
