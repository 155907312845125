import { Grid, Typography } from '@mui/material'

export interface PageUnderConstructionProps {
    pageName: string
}

/**
 * Renders a message page notifying user that this is under construction
 */
export function PageUnderConstruction(props: PageUnderConstructionProps) {
    const { pageName } = props
    const description = `DNA is missing! We are actively working to make ${pageName} accessible to you soon.`
    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
            <img alt="under construction" src="/images/construction.svg" />
            <Typography sx={{ width: '800px', margin: '10px 0 20px' }} variant="h3" align="center" fontWeight={'bold'}>
                Stay Tuned!
            </Typography>
            <Typography sx={{ width: '800px' }} variant="h4" align="center">
                {description}
            </Typography>
        </Grid>
    )
}
