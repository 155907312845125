import { createContext, useEffect, useContext, useState } from 'react'
import { appInsights } from '../libs/AppInsights'
import { StorageError } from '../libs/AppErrors'
import { GridProjectOptions } from '../pages/projects'
import { SearchSampleFilters } from '../pages/search/SampleSearchSidebar'

interface samplesSearch {
    samples: any[]
    filters: SearchSampleFilters
    searchTerm: string
}

interface StorageContextType {
    samplesSearchContext: samplesSearch
    updateSamplesSearchContext: (samplesSearch: samplesSearch) => void
    getContextProjects: (projectOption: GridProjectOptions) => any[]
    updateContextProjects: (projectOption: GridProjectOptions, projects: any[]) => void
    clearLocalStorage: () => void
}

const StorageContext = createContext<StorageContextType | null>(null)

function clearLocalStorage() {
    try {
        window.localStorage.clear()
    } catch (e) {
        appInsights.trackException({
            ...StorageError.FailToClear,
        })
    }
}

export function StorageProvider({ children }: any) {
    const [samplesSearch, setSamplesSearch] = useState<any>()
    const [myProjects, setMyProjects] = useState<any[]>([])
    const [sharedProjects, setSharedProjects] = useState<any[]>([])
    const [publicProjects, setPublicProjects] = useState<any[]>([])

    const updateSamplesSearch = (updatedSamplesSearch: samplesSearch) => {
        setSamplesSearch(updatedSamplesSearch)
    }

    const getProjects = (projectOption: GridProjectOptions): any[] => {
        let projects = []
        switch (projectOption) {
            case GridProjectOptions.myprojects:
                projects = myProjects
                break
            case GridProjectOptions.shared:
                projects = sharedProjects
                break
            case GridProjectOptions.public:
                projects = publicProjects
                break
        }
        return projects
    }

    const updateProjects = (projectOption: GridProjectOptions, projects: any[]) => {
        switch (projectOption) {
            case GridProjectOptions.myprojects:
                setMyProjects(projects)
                break
            case GridProjectOptions.shared:
                setSharedProjects(projects)
                break
            case GridProjectOptions.public:
                setPublicProjects(projects)
                break
        }
    }

    const clearStorage = () => {
        // TODO: Send telemetry log
        clearLocalStorage()
    }

    useEffect(() => {
        if (samplesSearch) {
            setSamplesSearch(samplesSearch)
        }
    }, [samplesSearch])

    useEffect(() => {
        if (myProjects) {
            setMyProjects(myProjects)
        }
    }, [myProjects])

    useEffect(() => {
        if (sharedProjects) {
            setSharedProjects(sharedProjects)
        }
    }, [sharedProjects])

    useEffect(() => {
        if (publicProjects) {
            setPublicProjects(publicProjects)
        }
    }, [publicProjects])



    return (
        <StorageContext.Provider
            value={{
                samplesSearchContext: samplesSearch,
                updateSamplesSearchContext: updateSamplesSearch,
                getContextProjects: getProjects,
                updateContextProjects: updateProjects,
                clearLocalStorage: clearStorage,
            }}
        >
            {children}
        </StorageContext.Provider>
    )
}

export function useStorageContext() {
    return useContext(StorageContext)
}

export const getAuthSsoOrg = () => window.localStorage.getItem("AUTH_SSO_ORG")
export const setAuthSsoOrg = (org: string) => org ? window.localStorage.setItem("AUTH_SSO_ORG", org) : window.localStorage.removeItem("AUTH_SSO_ORG")
