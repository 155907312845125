import { ProjectInvitedUser } from '../../../../libs/api/users/UserModel'
import { UserRole } from '../../Projects.model'
import { ContributorGridItem, ContributorStatus } from './ContributorsGrid'

export function mapUsersToContributors(dbUsers: any): ContributorGridItem[] {
    if (!dbUsers) {
        return []
    }

    const contributors: ContributorGridItem[] = []
    if (dbUsers.owner) {
        contributors.push(mapUserToContributor(dbUsers.owner, UserRole.owner))
    }

    if (dbUsers.admins) {
        dbUsers.admins.forEach((user) => {
            if (!contributors.find((x) => x.id === user.id)) {
                contributors.push(mapUserToContributor(user, UserRole.admin))
            }
        })
    }

    if (dbUsers.users) {
        dbUsers.users.forEach((user) => {
            if (!contributors.find((x) => x.id === user.id)) {
                contributors.push(mapUserToContributor(user, UserRole.user))
            }
        })
    }

    return sortContributors(contributors)
}

export function mapInvitedUsersToContributors(users: ProjectInvitedUser[]): ContributorGridItem[] {
    if (!users || users.length === 0) {
        return []
    }

    const contributors: ContributorGridItem[] = users.map((invitedUser) => {
        return {
            id: invitedUser.email,
            email: invitedUser.email,
            role: invitedUser.isAdmin ? UserRole.admin : UserRole.user,
            status: ContributorStatus.pending,
            statusMessage: 'Invited - Pending acceptance',
        }
    })

    return sortContributors(contributors)
}

export function updateContributorRole(currentContributors: ContributorGridItem[], contributorId: string, newRole: UserRole): ContributorGridItem[] {
    if (!currentContributors || currentContributors.length === 0) {
        return []
    }

    const updatedContributors = [
        ...currentContributors.filter((x) => x.id !== contributorId),
        {
            ...currentContributors.find((x) => x.id === contributorId),
            role: newRole,
        },
    ]
    return sortContributors(updatedContributors)
}

export function getUpdatedContributors(originalContributors: ContributorGridItem[], currentContributors: ContributorGridItem[]): ContributorGridItem[] {
    if (!originalContributors || !currentContributors) {
        return []
    }

    const updatedContributors: ContributorGridItem[] = []
    originalContributors.forEach((contributor) => {
        const matchedContributor = currentContributors.find((x) => x.id === contributor.id)
        if (matchedContributor && contributor.role !== matchedContributor.role) {
            updatedContributors.push(matchedContributor)
        }
    })
    return updatedContributors
}

export function getUserRoleInProject(userId: string, contributors: ContributorGridItem[]): UserRole {
    const userRole = contributors.find((x) => x.id === userId)?.role
    return userRole
}

export function canDeleteUser(userRole: UserRole, targetUserRole: UserRole, status: ContributorStatus, isGlobalAdmin: boolean): boolean {
    if(isGlobalAdmin) return true
    return (
        status === ContributorStatus.active &&
        ((userRole === UserRole.owner && targetUserRole !== UserRole.owner) || (userRole === UserRole.admin && targetUserRole === UserRole.user))
    )
}

export function isRoleDropDownEnabled(userRole: UserRole, targetUserRole: UserRole, status: ContributorStatus, isGlobalAdmin: boolean): boolean {
    if(isGlobalAdmin) return true
    return userRole === UserRole.owner && targetUserRole !== UserRole.owner && status === ContributorStatus.active
}

/** Private Mthods */

function mapUserToContributor(user: any, role: UserRole): ContributorGridItem {
    return {
        id: user.id,
        email: user.email,
        role: role,
        status: ContributorStatus.active,
    }
}

function sortContributors(constributor: ContributorGridItem[]): ContributorGridItem[] {
    const sortedContributors = constributor && constributor.length > 0 && constributor.sort((a, b) => a.email.localeCompare(b.email))
    return sortedContributors
}
