import { Box, Grid, Typography } from "@mui/material";
import Layout from "../Layout";

export default function NotApproved() {
    return (
        <>
            <Layout />
            <Box id="error-page" height={'100vh'}>
                <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20vh' }}>
                    <Typography variant="h4" sx={{ marginBottom: '30px' }}>
                        Sorry, your access is waiting approval.
                    </Typography>
                </Grid>
            </Box>
        </>
    )
}