import { Divider, Grid, Typography } from '@mui/material'
import { ReactNode } from 'react'

export enum TaxonomyNotesCardState {
    Disabled = 'disabled',
    Attributes = 'attributes',
}

export interface TaxonomyAttributeCardItem {
    key: string
    title: string
    description: string
}

export interface TaxonomyAttributes {
    readsCount: number
    qcReadsCount: number
    domain: number
    phylum: number
    class: number
    order: number
    family: number
    genus: number
    species?: number
}

const AttributesItems = [
    { key: 'readsCount', title: 'Number of reads:', property: 'readsCount' },
    {
        key: 'qcReadsCount',
        title: 'Number of QC reads:',
        property: 'qcReadsCount',
    },
    { key: 'domain', title: 'Domain:', property: 'domain' },
    { key: 'phylum', title: 'Phylum:', property: 'phylum' },
    { key: 'class', title: 'Class:', property: 'class' },
    { key: 'order', title: 'Order:', property: 'order' },
    { key: 'family', title: 'Family:', property: 'family' },
    { key: 'genus', title: 'Genus:', property: 'genus' },
    { key: 'species', title: 'Species:', property: 'species' },
]

export function renderTaxonomyCardItems(notesCardState: TaxonomyNotesCardState, attributeItems: TaxonomyAttributes): ReactNode {
    if (notesCardState === TaxonomyNotesCardState.Disabled) {
        return null
    }

    return (
        <Grid sx={{ padding: '25px 20px' }}>
            <Typography variant="subtitle2" fontWeight="bold" marginBottom="5px">
                Total counts:
            </Typography>
            <Grid sx={{ display: 'flex', padding: '0 10px', height: 'auto' }}>
                <Divider
                    orientation="vertical"
                    sx={{
                        height: 'auto',
                        borderWidth: '1px',
                        marginRight: '8px',
                    }}
                />
                <Grid>
                    {renderAttributeItem('Number of reads', attributeItems.readsCount.toLocaleString('en-US'))}
                    {renderAttributeItem('Number of QC reads', attributeItems.qcReadsCount.toLocaleString('en-US'))}
                </Grid>
            </Grid>
            <Divider sx={{ margin: '20px 0' }} />
            <Typography variant="subtitle2" fontWeight="bold" marginBottom="5px">
                Classified reads:
            </Typography>
            <Grid sx={{ display: 'flex', padding: '0 10px', height: 'auto' }}>
                <Divider
                    orientation="vertical"
                    sx={{
                        height: 'auto',
                        borderWidth: '1px',
                        marginRight: '8px',
                    }}
                />
                <Grid>
                    {renderAttributeItem('Domain', attributeItems.domain + '%')}
                    {renderAttributeItem('Phylum', attributeItems.phylum + '%')}
                    {renderAttributeItem('Class', attributeItems.class + '%')}
                    {renderAttributeItem('Order', attributeItems.order + '%')}
                    {renderAttributeItem('Family', attributeItems.family + '%')}
                    {renderAttributeItem('Genus', attributeItems.genus + '%')}
                    {attributeItems.species && renderAttributeItem('Species', attributeItems.species + '%')}
                </Grid>
            </Grid>
        </Grid>
    )
}

function renderAttributeItem(title: string, value: string) {
    return (
        <Typography variant="subtitle2" fontWeight="bold" margin="5px 0" whiteSpace="pre-wrap">
            {title}: <span style={{ fontWeight: '400' }}>{value}</span>
        </Typography>
    )
}

/**
 * Maps a new NotesCard item based on taxonomic attributes
 *
 * @param readCount The ssrun total number of reads
 * @param qcReadCount The ssrun total number of qc reads
 * @param taxonomicProfile The taxonomic profile data
 * @returns The populated attribute card for Taxonomy page
 */
export const buildAttributeCardItems = (readCount: number, qcReadCount: number, taxonomicProfile: any): TaxonomyAttributes => {
    return {
        readsCount: readCount || 0,
        qcReadsCount: qcReadCount || 0,
        domain: getTaxonomicAbundanceValue(taxonomicProfile?.domain?.abundance),
        phylum: getTaxonomicAbundanceValue(taxonomicProfile?.phylum?.abundance),
        class: getTaxonomicAbundanceValue(taxonomicProfile?.class?.abundance),
        order: getTaxonomicAbundanceValue(taxonomicProfile?.order?.abundance),
        family: getTaxonomicAbundanceValue(taxonomicProfile?.family?.abundance),
        genus: getTaxonomicAbundanceValue(taxonomicProfile?.genus?.abundance),
    }
}

const getTaxonomicAbundanceValue = (value: number): number => {
    return value ? Math.round(value * 1000) / 1000 : 0
}

export function mapTaxonomyNotesCardItemsFromAtributes(attributes: TaxonomyAttributes): TaxonomyAttributeCardItem[] {
    const noteCardItems = AttributesItems.map((item) => {
        return {
            key: item.key,
            title: item.title,
            description: attributes[`${item.property}`],
        }
    })

    return noteCardItems
}
