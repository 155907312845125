import { AxiosError, AxiosResponse } from 'axios'
import { NETWORK_ERROR, SERVER_ERROR, getRequestError } from './Error'
import { ApiResponse } from './BaseAPI'

/**
 * Maps AxioResponse to BaseApiResponse
 * @param response AxiosResponse
 * @returns The mapped BaseApiResponse promise
 */
export const mapAxiosResponse = (response: AxiosResponse): Promise<ApiResponse<any>> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
        const result: ApiResponse<any> = {
            data: response.data,
            succeeded: response.status < 400,
            errors: response.data?.errors,
        }
        resolve(result)
    })
}

/**
 * Maps AxiosResponse for failures
 * @param error
 * @returns The mapped ApiResponse for the error
 */
export const mapAxiosResponseError = (error: AxiosError): ApiResponse<any> => {
    let errorResult: ApiResponse<any> = null
    if (error.code === 'ERR_NETWORK') {
        errorResult = NETWORK_ERROR
    } else if (error.code === 'ERR_BAD_RESPONSE') {
        errorResult = SERVER_ERROR
    } else if (error.code === 'ERR_BAD_REQUEST') {
        errorResult = getRequestError(error.response)
    } else {
        errorResult = {
            succeeded: false,
            errors: {
                code: error.response ? error.response.status.toString() : error.code,
                message: error.message,
                severity: 'error',
            },
        }
    }
    return errorResult
}
