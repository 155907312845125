import { isKeywordInText } from '../../../../components/SearchText'

// CSS style to indicate the last item with data from taxonomic hierarchy
export const LEAF_LEVEL_STYLE_NAME = 'leaf'
// String value retrieve from ASV DB table when not data is available
const RANK_VALUE_NOT_AVAILABLE = 'NA'

/**
 * Builds the taxonomic hierarchy list
 * @param taxonomicProfile The complete taxonomic profile data
 * @returns The taxonomic hieararchy list
 */
export function mapTaxonomicHierarchy(taxonomicProfile: any): Taxon[] {
    if (!taxonomicProfile?.asv?.taxa) {
        return []
    }

    return mapAsvHierarchy(taxonomicProfile.asv.taxa)
}

/**
 * Maps a taxonomy profile rank entity list to a taxonomy rendering object of same rank classification
 * @param asvItems The asv items list
 * @returns A list of asv items with its taxonomic hierarchy
 */
function mapAsvHierarchy(asvItems: any[]): Taxon[] {
    const rankItems: Taxon[] = asvItems.map((item) => {
        return {
            id: item.asvId,
            name: item.asvId,
            rank: '',
            abundance: item.abundance || 0,
            readCount: item.readCount || 0,
            taxonomy: '',
            domain: filterAsvProperty(item.domain),
            phylum: filterAsvProperty(item.phylum),
            class: filterAsvProperty(item.class),
            order: filterAsvProperty(item.order),
            family: filterAsvProperty(item.family),
            genus: filterAsvProperty(item.genus),
            species: filterAsvProperty(item.species),
        }
    })

    if (rankItems.length > 0) {
        rankItems[rankItems.length - 1].level = LEAF_LEVEL_STYLE_NAME
    }

    return rankItems
}

/**
 * Helper to transform incalid data values to null
 * @param asvValue The asv value as it is from database
 * @returns asvValue if valid or null
 */
function filterAsvProperty(asvValue: string): string {
    return asvValue === RANK_VALUE_NOT_AVAILABLE ? null : asvValue
}

/**
 * The classification names for the taxonomy ranks
 */
export enum taxonomicRankNames {
    domain = 'Domain',
    phylum = 'Phylum',
    class = 'Class',
    order = 'Order',
    family = 'Family',
    genus = 'Genus',
    species = 'Species',
    asv = 'ASV',
}

/**
 * The taxonomic ranks hierarchy
 */
export interface TaxonomicRanks {
    domain?: string
    phylum?: string
    class?: string
    order?: string
    family?: string
    genus?: string
    species?: string
}

/**
 * The taxonomy rank object interface
 */
export interface Taxon extends TaxonomicRanks {
    id: string
    rank: string
    name: string
    abundance: number
    readCount: number
    taxonomy: string
    level?: string
}

/**
 * Maps a complete taxonomy profile entity to a taxonomy rendering object
 * @param taxonomicProfile The complete taxonomy profile entity
 * @returns The complete mapped taxonomy rendering object
 */
export function mapTaxonomicRank(taxonomicProfile: any): Taxon[] {
    if (!taxonomicProfile) {
        return []
    }

    const rankHierarchyMap = buildRankHierarchy(taxonomicProfile.asv.taxa)

    return [
        ...mapTaxonomicProfileProperties(taxonomicProfile.domain.taxa, taxonomicRankNames.domain, rankHierarchyMap),
        ...mapTaxonomicProfileProperties(taxonomicProfile.phylum.taxa, taxonomicRankNames.phylum, rankHierarchyMap),
        ...mapTaxonomicProfileProperties(taxonomicProfile.class.taxa, taxonomicRankNames.class, rankHierarchyMap),
        ...mapTaxonomicProfileProperties(taxonomicProfile.order.taxa, taxonomicRankNames.order, rankHierarchyMap),
        ...mapTaxonomicProfileProperties(taxonomicProfile.family.taxa, taxonomicRankNames.family, rankHierarchyMap),
        ...mapTaxonomicProfileProperties(taxonomicProfile.genus.taxa, taxonomicRankNames.genus, rankHierarchyMap),
        ...mapTaxonomicProfileProperties(taxonomicProfile.species.taxa, taxonomicRankNames.species, rankHierarchyMap),
        ...mapTaxonomicProfileProperties(taxonomicProfile.asv.taxa, taxonomicRankNames.asv, rankHierarchyMap),
    ]
}

/**
 * Maps a taxonomy profile rank entity list to a taxonomy rendering object of same rank classification
 * @param taxaRankItems The raxonomy rank entity list
 * @param rankName The rank classification option to be mapped
 * @param hierarchyMap A Map structure with all child parent relationship between named ranks found in taxa profile data
 * @returns A list of taxonomy obejcts of same name classification
 */
function mapTaxonomicProfileProperties(taxaRankItems: any[], rankName: string, hierarchyMap: Map<string, string>): Taxon[] {
    const rankItems: Taxon[] = taxaRankItems.map((item) => {
        const name = rankName === taxonomicRankNames.asv ? item.asvId : item.name
        return {
            id: name,
            name: name,
            rank: rankName,
            readCount: item.readCount || 0,
            abundance: item.abundance || 0,
            taxonomy: getRankHierarchy(name, hierarchyMap),
        }
    })

    if (rankItems.length > 0) {
        rankItems[rankItems.length - 1].level = LEAF_LEVEL_STYLE_NAME
    }

    return rankItems
}

/**
 * Builds the taxonomic hierarchy for specific named rank item
 * @param itemName The name of the rank item to build hierarchy
 * @param hierarchyMap A Map structure with all child parent relationship between named ranks found in taxa profile data
 * @returns A string containing the hierarchy chain from Domain to first parent of the named item
 */
function getRankHierarchy(itemName: string, hierarchyMap: Map<string, string>): string {
    const hierarchyArray = []
    let currParent = hierarchyMap.get(itemName)
    while (currParent) {
        hierarchyArray.push(currParent)
        currParent = hierarchyMap.get(currParent)
    }

    return hierarchyArray.reverse().join(' > ')
}

/**
 * Builds a Map structure containing the child parent relationship between all taxonomy items in the profile
 * @param asvItems The ASV items list from profile, including its taxonomic hierarchy
 * @returns The Map structure containing all the child-parent relationship from Asvs
 */
function buildRankHierarchy(asvItems: any[]): Map<string, string> {
    const rankOrder = [
        taxonomicRankNames.domain,
        taxonomicRankNames.phylum,
        taxonomicRankNames.class,
        taxonomicRankNames.order,
        taxonomicRankNames.family,
        taxonomicRankNames.genus,
        taxonomicRankNames.species,
    ]
    const hierarchyMap = new Map<string, string>()

    for (const asvItem of asvItems) {
        for (let i = 0; i <= rankOrder.length - 2; i++) {
            const parentRankName = asvItem[rankOrder[i].toLowerCase()]
            const childRankName = asvItem[rankOrder[i + 1].toLowerCase()]
            if (parentRankName !== RANK_VALUE_NOT_AVAILABLE && childRankName !== RANK_VALUE_NOT_AVAILABLE) {
                if (!hierarchyMap.has(childRankName)) {
                    hierarchyMap.set(childRankName, parentRankName)
                }

                if (rankOrder[i + 1] === taxonomicRankNames.species) {
                    // Add ASV taxa in a complete hierarchy
                    hierarchyMap.set(asvItem.asvId, childRankName)
                }
            } else if (parentRankName !== RANK_VALUE_NOT_AVAILABLE) {
                // Add ASV taxa in a incomplete hierarchy
                hierarchyMap.set(asvItem.asvId, parentRankName)
            } else {
                break
            }
        }
    }

    return hierarchyMap
}

/**
 * Searches for the keyword in names and parents in taxonomic hierarchy, returning only the items where keyword is found
 *
 * @param keyword The keyword text to search and filter
 * @param taxonomicHierarchy The taxonomic array containing taxon hierarchy
 * @returns The filtered taxonomic items
 */
export function filterTaxonomicHierarchyByKeyword(keyword: string, taxonomicHierarchy: Taxon[]): Taxon[] {
    if (!keyword || !taxonomicHierarchy) {
        return taxonomicHierarchy
    }

    const filteredHierarchy = []

    taxonomicHierarchy.forEach((item) => {
        if (isKeywordInText(item.name, keyword)) {
            filteredHierarchy.push(item)
            return
        } else {
            const parentIndexFound = higherIndexOfKeywordInParents(keyword, item)
            if (parentIndexFound < 0) {
                return null
            }

            filteredHierarchy.push({
                ...item,
                domain: item.domain && parentIndexFound >= 0 ? item.domain : undefined,
                phylum: item.phylum && parentIndexFound > 0 ? item.phylum : undefined,
                class: item.class && parentIndexFound > 1 ? item.class : undefined,
                order: item.order && parentIndexFound > 2 ? item.order : undefined,
                family: item.family && parentIndexFound > 3 ? item.family : undefined,
                genus: item.genus && parentIndexFound > 4 ? item.genus : undefined,
                species: item.species && parentIndexFound > 5 ? item.species : undefined,
            })
        }
    })

    return filteredHierarchy
}

/**
 * Searches for the keyword in names and parents in taxonomic rank, returning only the items where keyword is found
 *
 * @param keyword The keyword text to search and filter
 * @param taxonomicRank The taxonomic array containing taxon hierarchy
 * @returns The filtered taxonomic items
 */
export function filterTaxonomicRankByKeyword(keyword: string, taxonomicRank: Taxon[]): Taxon[] {
    if (!keyword || !taxonomicRank) {
        return taxonomicRank
    }

    const filteredRank = []

    taxonomicRank.forEach((item) => {
        if (isKeywordInText(item.name, keyword) || isKeywordInText(item.rank, keyword) || isKeywordInText(item.taxonomy, keyword)) {
            filteredRank.push(item)
        }
    })

    return filteredRank
}

/**
 * Returns the highest rank where the keyword is found
 *
 * @param keyword The text to search for
 * @param taxonomyItem The taxonomy item with hierarchy
 * @returns The group index where the searched text is found. -1 is not in text.
 */
function higherIndexOfKeywordInParents(keyword: string, taxonomyItem: any): number {
    const rankOrder = [
        taxonomicRankNames.domain,
        taxonomicRankNames.phylum,
        taxonomicRankNames.class,
        taxonomicRankNames.order,
        taxonomicRankNames.family,
        taxonomicRankNames.genus,
        taxonomicRankNames.species,
    ]

    let index = -1
    for (let i = 0; i < 7; i++) {
        const text = taxonomyItem[rankOrder[i].toLowerCase()]
        if (text && isKeywordInText(text, keyword)) {
            index = i
        }
    }

    return index
}
