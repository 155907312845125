import { SampleType, SamplingMethod, SequencingType, ShippingCondition, Weather } from '../../libs/api/samples/SampleModel'
import { GeochemRangeType, GeochemicalItem } from './GeochemicalFilter'
import { SelectionFilter } from './SelectionFilter'

export const ERROR_COLOR_RED = '#d32f2f'

export enum SampleSearchFilter {
    Weather = 'weather',
    SampleType = 'sampleType',
    SamplingMethod = 'samplingMethod',
    ShippingCondition = 'shippingCondition',
    SequencingType = 'sequencingType',
    PHRange = 'phRange',
    TemperatureRange = 'temperatureRange',
    DepthRange = 'depthRange',
    DORange = 'doRange',
    ConductivityRange = 'conductivityRange',
    GeochemElements = 'geochemElements',
    geochemCompounds = 'geochemCompounds',
}

/**
 * List of available filter of the type list selection
 */
export const SELECTION_FILTERS: SelectionFilter[] = [
    {
        name: SampleSearchFilter.Weather,
        title: 'Weather',
        collection: Weather,
    },
    {
        name: SampleSearchFilter.SampleType,
        title: 'Sample Type',
        collection: SampleType,
    },
    {
        name: SampleSearchFilter.SamplingMethod,
        title: 'Sampling Method',
        collection: SamplingMethod,
    },
    {
        name: SampleSearchFilter.ShippingCondition,
        title: 'Shipping Condition',
        collection: ShippingCondition,
    },
    {
        name: SampleSearchFilter.SequencingType,
        title: 'Sequencing Type',
        collection: SequencingType,
    },
]

export interface RangeValues {
    minValue?: number
    maxValue?: number
}

export interface PropertyRangeType {
    name: string
    title: string
    shortTitle: string
    unitSymbol: string
    unitName: string
    ariaLabel: string
    sampleAttr: string
    minAllowed: number
    maxAllowed: number
}

/**
 * List of available filter of the range type
 */
export const RANGE_PROPERTIES: PropertyRangeType[] = [
    {
        name: SampleSearchFilter.PHRange,
        title: 'pH Range',
        shortTitle: 'pH',
        unitSymbol: '',
        unitName: 'pH',
        ariaLabel: 'pH',
        sampleAttr: 'ph',
        minAllowed: 0,
        maxAllowed: 14,
    },
    {
        name: SampleSearchFilter.TemperatureRange,
        title: 'Temperature Range',
        shortTitle: 'Temp',
        unitSymbol: '\xB0C',
        unitName: 'Celcius',
        ariaLabel: 'temperature-in-celcius',
        sampleAttr: 'temperature',
        minAllowed: -273,
        maxAllowed: 10000,
    },
    {
        name: SampleSearchFilter.DepthRange,
        title: 'Depth Range',
        shortTitle: 'Depth',
        unitSymbol: 'm',
        unitName: 'meters',
        ariaLabel: 'depth-in-meters',
        sampleAttr: 'depth',
        minAllowed: -10000,
        maxAllowed: 10000,
    },
    {
        name: SampleSearchFilter.DORange,
        title: 'Dissolved Oxygen (DO)',
        shortTitle: 'DO',
        unitSymbol: 'mg/L',
        unitName: 'miligram per litre',
        ariaLabel: 'dissolved-oxygen',
        sampleAttr: 'do',
        minAllowed: 0,
        maxAllowed: 100000,
    },
    {
        name: SampleSearchFilter.ConductivityRange,
        title: 'Conductivity',
        shortTitle: 'Cond',
        unitSymbol: 'uS/cm',
        unitName: 'microsiemens per centimeter',
        ariaLabel: 'conductivity',
        sampleAttr: 'conductivity',
        minAllowed: 0,
        maxAllowed: 50000,
    },
]

export const GEOCHEMICAL_COMPOUNDS: GeochemicalItem[] = [
    { id: 'Al2O3', name: 'Al2O3' },
    { id: 'BaO', name: 'BaO' },
    { id: 'CaO', name: 'CaO' },
    { id: 'Cr2O3', name: 'Cr2O3' },
    { id: 'Fe2O3', name: 'Fe2O3' },
    { id: 'K2O', name: 'K2O' },
    { id: 'LOI', name: 'LOI' },
    { id: 'MgO', name: 'MgO' },
    { id: 'MnO', name: 'MnO' },
    { id: 'Na2O', name: 'Na2O' },
    { id: 'P2O5', name: 'P2O5' },
    { id: 'SiO2', name: 'SiO2' },
    { id: 'SO4', name: 'SO4', isCommon: true },
    { id: 'SrO', name: 'SrO' },
    { id: 'TiO2', name: 'TiO2' },
]

export const GEOCHEMICAL_ELEMENTS: GeochemicalItem[] = [
    { id: 'Ac', name: 'Actinium' },
    { id: 'Al', name: 'Aluminum' },
    { id: 'Am', name: 'Americium' },
    { id: 'Sb', name: 'Antimony', isCommon: true },
    { id: 'Ar', name: 'Argon' },
    { id: 'As', name: 'Arsenic', isCommon: true },
    { id: 'At', name: 'Astatine' },
    { id: 'Ba', name: 'Barium' },
    { id: 'Bk', name: 'Berkelium' },
    { id: 'Be', name: 'Beryllium' },
    { id: 'Bi', name: 'Bismuth' },
    { id: 'Bh', name: 'Bohrium' },
    { id: 'B', name: 'Boron' },
    { id: 'Br', name: 'Bromine' },
    { id: 'Cd', name: 'Cadmium', isCommon: true },
    { id: 'Ca', name: 'Calcium' },
    { id: 'Cf', name: 'Californium' },
    { id: 'C', name: 'Carbon' },
    { id: 'Ce', name: 'Cerium' },
    { id: 'Cs', name: 'Cesium' },
    { id: 'Cl', name: 'Chlorine' },
    { id: 'Cr', name: 'Chromium', isCommon: true },
    { id: 'Co', name: 'Cobalt', isCommon: true },
    { id: 'Cu', name: 'Copper', isCommon: true },
    { id: 'Cm', name: 'Curium' },
    { id: 'Ds', name: 'Darmstadtium' },
    { id: 'Db', name: 'Dubnium' },
    { id: 'Dy', name: 'Dysprosium' },
    { id: 'Es', name: 'Einsteinium' },
    { id: 'Er', name: 'Erbium' },
    { id: 'Eu', name: 'Europium' },
    { id: 'Fm', name: 'Fermium' },
    { id: 'F', name: 'Fluorine' },
    { id: 'Fr', name: 'Francium' },
    { id: 'Gd', name: 'Gadolinium' },
    { id: 'Ga', name: 'Gallium' },
    { id: 'Ge', name: 'Germanium' },
    { id: 'Au', name: 'Gold' },
    { id: 'Hf', name: 'Hafnium' },
    { id: 'Hs', name: 'Hassium' },
    { id: 'He', name: 'Helium' },
    { id: 'Ho', name: 'Holmium' },
    { id: 'H', name: 'Hydrogen' },
    { id: 'In', name: 'Indium' },
    { id: 'I', name: 'Iodine' },
    { id: 'Ir', name: 'Iridium' },
    { id: 'Fe', name: 'Iron', isCommon: true },
    { id: 'Kr', name: 'Krypton' },
    { id: 'La', name: 'Lanthanum' },
    { id: 'Lr', name: 'Lawrencium' },
    { id: 'Pb', name: 'Lead', isCommon: true },
    { id: 'Li', name: 'Lithium' },
    { id: 'Lu', name: 'Lutetium' },
    { id: 'Mg', name: 'Magnesium' },
    { id: 'Mn', name: 'Manganese', isCommon: true },
    { id: 'Mt', name: 'Meitnerium' },
    { id: 'Md', name: 'Mendelevium' },
    { id: 'Hg', name: 'Mercury', isCommon: true },
    { id: 'Mo', name: 'Molybdenum', isCommon: true },
    { id: 'Nd', name: 'Neodymium' },
    { id: 'Ne', name: 'Neon' },
    { id: 'Np', name: 'Neptunium' },
    { id: 'Ni', name: 'Nickel', isCommon: true },
    { id: 'Nb', name: 'Niobium' },
    { id: 'N', name: 'Nitrogen' },
    { id: 'No', name: 'Nobelium' },
    { id: 'Uub', name: 'nunbiium' },
    { id: 'Uuq', name: 'nunquadium' },
    { id: 'Os', name: 'Osmium' },
    { id: 'O', name: 'Oxygen' },
    { id: 'Pd', name: 'Palladium' },
    { id: 'P', name: 'Phosphorus' },
    { id: 'Pt', name: 'Platinum' },
    { id: 'Pu', name: 'Plutonium' },
    { id: 'Po', name: 'Polonium' },
    { id: 'K', name: 'Potassium' },
    { id: 'Pr', name: 'Praseodymium' },
    { id: 'Pm', name: 'Promethium' },
    { id: 'Pa', name: 'Protactinium' },
    { id: 'Ra', name: 'Radium' },
    { id: 'Rn', name: 'Radon' },
    { id: 'Re', name: 'Rhenium' },
    { id: 'Rh', name: 'Rhodium' },
    { id: 'Rg', name: 'Roentgenium' },
    { id: 'Rb', name: 'Rubidium' },
    { id: 'Ru', name: 'Ruthenium' },
    { id: 'Rf', name: 'Rutherfordium' },
    { id: 'Sm', name: 'Samarium' },
    { id: 'Sc', name: 'Scandium' },
    { id: 'Sg', name: 'Seaborgium' },
    { id: 'Se', name: 'Selenium', isCommon: true },
    { id: 'Si', name: 'Silicon' },
    { id: 'Ag', name: 'Silver', isCommon: true },
    { id: 'Na', name: 'Sodium' },
    { id: 'Sr', name: 'Strontium' },
    { id: 'S', name: 'Sulfur' },
    { id: 'Ta', name: 'Tantalum' },
    { id: 'Tc', name: 'Technetium' },
    { id: 'Te', name: 'Tellurium' },
    { id: 'Tb', name: 'Terbium' },
    { id: 'Tl', name: 'Thallium' },
    { id: 'Th', name: 'Thorium' },
    { id: 'Tm', name: 'Thulium' },
    { id: 'Sn', name: 'Tin', isCommon: true },
    { id: 'Ti', name: 'Titanium' },
    { id: 'W', name: 'Tungsten' },
    { id: 'U', name: 'Uranium' },
    { id: 'V', name: 'Vanadium' },
    { id: 'Xe', name: 'Xenon' },
    { id: 'Yb', name: 'Ytterbium' },
    { id: 'Y', name: 'Yttrium' },
    { id: 'Zn', name: 'Zinc', isCommon: true },
    { id: 'Zr', name: 'Zirconium' },
]

/**
 * List of available filter of the geochemical items type
 */
export const GEOCHEM_RANGE_PROPERTIES: GeochemRangeType[] = [
    {
        name: SampleSearchFilter.GeochemElements,
        title: 'Geochemical Elements',
        minAllowed: 0,
        maxAllowed: 100000,
        collection: GEOCHEMICAL_ELEMENTS,
    },
    {
        name: SampleSearchFilter.geochemCompounds,
        title: 'Geochemical Compounds',
        minAllowed: 0,
        maxAllowed: 100000,
        collection: GEOCHEMICAL_COMPOUNDS,
    },
]
