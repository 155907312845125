import { AccordionSummary, Typography, AccordionDetails, Divider, Grid, Button } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SIDEBAR_FONT_COLOR } from './SampleDetails.constants'
import { CustomAccordionSidebar } from './subcomponents/summary/SummarySidebar'
import { useContext } from 'react'
import { SampleDataContext } from '../../context/CustomContext'

export function SidebarAccessions() {
    const { ssuRunIds, selectedSsuRunId, setSelectedSsuRunId, isAccessionSidebarOpen, setIsAccessionSidebarOpen } = useContext(SampleDataContext)

    const handleAccessionChange = (ssuRunId: string) => {
        if (ssuRunId !== selectedSsuRunId) {
            setSelectedSsuRunId(ssuRunId)
        }
    }

    const handleExpandToggle = () => {
        setIsAccessionSidebarOpen(!isAccessionSidebarOpen)
    }

    return (
        <CustomAccordionSidebar elevation={0} disableGutters expanded={isAccessionSidebarOpen} onChange={handleExpandToggle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1" padding="20px 20px 5px" fontWeight={'bold'} color={SIDEBAR_FONT_COLOR}>
                    All Accessions
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="subtitle1" fontWeight={'bold'} color={SIDEBAR_FONT_COLOR}>
                    WGS Accession
                </Typography>
                <Typography variant="caption" fontStyle="italic" letterSpacing={0.5} padding="0 20px 20px" color={SIDEBAR_FONT_COLOR}>
                    Not available for this sample
                </Typography>
                <Divider sx={{ borderColor: 'white', margin: '14px 0' }} />
                <Typography variant="subtitle1" fontWeight={'bold'} sx={{ marginBottom: '14px', color: SIDEBAR_FONT_COLOR }}>
                    16S rRNA Accession
                </Typography>
                <Grid sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '14px' }}>
                    {ssuRunIds &&
                        ssuRunIds.map((ssuRunId) => (
                            <AccessionButton key={ssuRunId} ssuRunId={ssuRunId} isSelected={ssuRunId === selectedSsuRunId} handleClick={handleAccessionChange} />
                        ))}
                </Grid>
            </AccordionDetails>
        </CustomAccordionSidebar>
    )
}

interface AccessionButtonProps {
    ssuRunId: string
    isSelected: boolean
    handleClick: (ssuRunId: string) => void
}

function AccessionButton(props: AccessionButtonProps) {
    const { ssuRunId, isSelected, handleClick } = props

    const backgroundColor = isSelected ? '#EBEEFD' : ''
    const dividerColor = isSelected ? '#1B49D4' : ''
    const textColor = isSelected ? '#1B49D4' : 'rgba(19, 19, 32, 0.87)'

    return (
        <Grid
            key={ssuRunId}
            sx={{
                display: 'flex',
                height: '32px',
            }}
        >
            <Button
                disabled={isSelected}
                onClick={() => handleClick(ssuRunId)}
                sx={{ width: '260px', padding: '0 0 0 10px', backgroundColor: backgroundColor, justifyContent: 'flex-start' }}
            >
                <Divider orientation="vertical" sx={{ borderColor: dividerColor, borderWidth: '1px', marginRight: '10px' }} />
                <Typography variant="subtitle2" sx={{ padding: '0 0 0 20px', color: textColor, '&:hover': { color: '#1B49D4' } }}>
                    {ssuRunId}
                </Typography>
            </Button>
        </Grid>
    )
}
