import { object, string, number } from 'yup'
import { GridItem } from '../InfoCard'

export const sampleTypes = [
    'Bedrock',
    'Biofilm',
    'Biosolids',
    'Coal',
    'Groundwater',
    'Landfill',
    'Mineral Soil',
    'Oil Associated Bedrock',
    'Oil Associated Enrichments',
    'Oil Associated Groundwater',
    'Oil Associated Sediments',
    'Oil Pipeline Associated',
    'Oil Production Associated',
    'Oil Storage Associated',
    'Ore',
    'Organic Soil',
    'Process Water',
    'Sediments (mine impacted)',
    'Sediments (natural)',
    'Seawater',
    'Shale',
    'Surface Water (mine impacted)',
    'Surface Water (natural)',
    'Tailings',
    'Waste Rock',
    'In Silico',
    'Wasterock',
    'Water treatment plant',
    'Other',
]

export const waterSampleTypes = ['Groundwater', 'Surface Water (mine impacted)', 'Surface Water (natural)']

export const phValidTypes = ['Mineral Soil', 'Organic Soil', 'Groundwater', 'Surface Water (mine impacted)', 'Surface Water (natural)']

export const samplingMethods = ['Auger', 'Bailer', 'Barge Sample', 'Drilling', 'Excavator', 'Hand Sample', 'Hand Shovel', 'Peristaltic Pump', 'Other']

export const shippingConditions = ['Ice', 'Dry Ice', 'Blue Ice']

export const weatherConditions = ['Clear', 'Light Rain', 'Partly cloudy', 'Pouring Rain', 'Snowing', 'Other']

export const waterPreservationMethods = ['Filter in Field', 'Ethanol', 'Filter in Lab']

export const projects = ['Teck-EVO', 'Rio-Default']

export const statusOptions = ['Created', 'Data received', 'Shipped', 'Sample received']

// See https://github.com/jquense/yup for more information
export const editSampleFormSchema = object({
    temperature: number().min(-40).max(100).required(),
    depth: number().positive().max(100000).required(),
    ph: number().when('sampleType', {
        is: (sampleType: string) => phValidTypes.includes(sampleType),
        then: (schema) => schema.min(0).max(14).required(),
    }),
    do: number().when('sampleType', {
        is: (sampleType: string) => waterSampleTypes.includes(sampleType),
        then: (schema) => schema.min(0).max(20).required(),
    }),
    orp: number().when('sampleType', {
        is: (sampleType: string) => waterSampleTypes.includes(sampleType),
        then: (schema) => schema.min(-1000).max(1000).required(),
    }),
    conductivity: number().when('sampleType', {
        is: (sampleType: string) => waterSampleTypes.includes(sampleType),
        then: (schema) => schema.min(0).max(100000).required(),
    }),
    waterPreservation: string().when('sampleType', {
        is: (sampleType: string) => waterSampleTypes.includes(sampleType),
        then: (schema) => schema.required(),
    }),
    otherWeather: string().when('weather', {
        is: (weather: string) => weather === 'Other',
        then: (schema) => schema.required(),
    }),
    otherSamplingMethod: string().when('samplingMethod', {
        is: (samplingMethod: string) => samplingMethod === 'Other',
        then: (schema) => schema.required(),
    }),
})

export const collectionFields: GridItem[] = [
    { label: 'User Defined Sample ID:', field: 'userDefinedSampleId' },
    { label: 'Country:', field: 'country' },
    { label: 'Project:', field: 'projectName' },
    { label: 'Sample Location ID:', field: 'sampleLocationId' },
    { label: 'Collection Date:', field: 'collectionDate', type: 'date' },
    { label: 'Sample Site:', field: 'site' },
    { label: 'Sample Type:', field: 'sampleType' },
    { label: 'Sampling Method:', field: 'samplingMethod' },
    { label: 'Shipping Condition:', field: 'shippingCondition' },
    { label: 'Water Preservation Method:', field: 'waterPreservation' },
    { label: 'Water Quality Sample ID:', field: 'waterQualitySampleId' },
    { label: 'Source:', field: 'source' },
    { label: 'Status:', field: 'status' },
]

export const environmentalFields: GridItem[] = [
    { label: 'Depth (m):', field: 'depth' },
    { label: 'Weather:', field: 'weather' },
    { label: 'Temperature (C):', field: 'temperature' },
    { label: 'pH:', field: 'ph' },
    { label: 'Disolved Oxygen (DO) (mg/L):', field: 'do' },
    { label: 'Oxidation-Reduction Potential (ORP) (mV):', field: 'orp' },
    { label: 'Conductivity (uS/cm):', field: 'conductivity' },
]
