import BaseAPI, { ApiResponse } from '../BaseAPI'
import { TaxonomicProfileDTO } from './TaxonomicProfileDTO'
import { mapTaxaProfileDtoToTaxaProfileModel } from './TaxonomicProfileMapper'
import { TaxonomicProfile } from './TaxonomicProfileModel'

/**
 * Gets the taxonomic profile data for a given ssurun
 * @param ssuRunId The ssurun id
 * @returns The taxonomic profile model
 */
export async function getTaxonomicProfile(ssuRunId: string): Promise<ApiResponse<TaxonomicProfile>> {
    const config = { params: { isExpanded: true } }
    const response = await BaseAPI.get<TaxonomicProfileDTO>(`/taxonomic-profile/id`, ssuRunId, config)

    const taxaProfileModel = response.data && mapTaxaProfileDtoToTaxaProfileModel(response.data)

    return {
        ...response,
        data: taxaProfileModel,
    }
}
