import { ImageDTO } from './ImageDTO'
import { Image, PhotoType } from './ImageModel'

export const mapImageDtoToImage = (image: ImageDTO): Image => {
    const imageModel: Image = {
        id: image.id,
        sampleId: image.sampleId,
        projectId: image.projectId,
        photoType: image.photoType as PhotoType,
        createdById: image.createdById,
        url: image.url,
    }

    return imageModel
}
