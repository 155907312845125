import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { UserRequestsGrid } from './UserRequestsGrid'
import { UserRequest } from '../../libs/api/users/UserModel'
import { getUserRequests, respondUserRequest } from '../../libs/api/users/UserAPI'
import { useNotificationContext } from '../../context/NotificationContext'
import { getAllOrganizations } from '../../libs/api/organization/OrganizationAPI'
import { Organization } from '../../libs/api/organization/OrganizationModel'

export function UserRequests() {
    const [userRequests, setUserRequests] = useState<UserRequest[]>()
    const [organizations, setOrganizations] = useState<Organization[]>()
    const [refresh, setRefresh] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const openNotification = useNotificationContext().openNotification

    const getRequests = async () => {
        const { succeeded, data, errors } = await getUserRequests()
        if (succeeded) {
            setUserRequests(data)
        } else {
            setUserRequests([])
            openNotification(errors.message, errors.severity)
        }
        setRefresh(false)
    }

    const getOrganizations = async () => {
        const { succeeded, data, errors } = await getAllOrganizations()
        if (succeeded) {
            setOrganizations(data)
        } else {
            setOrganizations([])
            openNotification(errors.message, errors.severity)
        }
        setRefresh(false)
    }

    useEffect(() => {
        const refreshUserRequests = async () => {
            setLoading(true)
            if (!userRequests || refresh) {
                await getRequests()
            }

            if (!organizations || refresh) {
                await getOrganizations()
            }

            if (refresh) {
                setRefresh(false)
            }
            setLoading(false)
        }

        refreshUserRequests()
    }, [refresh])

    const handleInviteResponse = async (userId: string, hasApproved?: boolean, organizationId?: string, rejectReason?: string) => {
        const { succeeded, data, errors } = await respondUserRequest(userId, hasApproved, organizationId, rejectReason)
        if (succeeded) {
            openNotification(data.message, 'success')
            setRefresh(true)
        } else {
            openNotification(errors.message, errors.severity)
        }
    }

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h4" style={{ padding: '20px 0' }}>
                Pending User Requests
            </Typography>
            <UserRequestsGrid userRequests={userRequests} organizations={organizations} loading={loading} onInviteResponse={handleInviteResponse} />
        </Box>
    )
}
