import { Check, TableChart } from '@mui/icons-material'
import { SxProps, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material'
import { useState } from 'react'

export const enum TaxonomyTabState {
    krona = 'krona',
    hierarchy = 'hierarchy',
    rank = 'rank',
}

export interface TaxonomyTabProps {
    tabState: TaxonomyTabState
    sx?: SxProps
    onTabStateChange: (newState: TaxonomyTabState) => void
}

export function TaxonomyTab(props: TaxonomyTabProps) {
    const { tabState, sx, onTabStateChange } = props
    const [currTabState, setCurrTabState] = useState<TaxonomyTabState>(tabState)

    const CustomToggle = styled(ToggleButton)({
        width: '160px',
        borderRadius: '12px',
        textTransform: 'inherit',
        '&.Mui-selected': {
            backgroundColor: '#EDF1FC',
            '&:hover': {
                backgroundColor: '#EDF1FC',
                cursor: 'unset',
            },
        },
    })

    const handleTabStateChange = (newValue: TaxonomyTabState) => {
        if (newValue && newValue !== currTabState) {
            setCurrTabState(newValue)
            onTabStateChange(newValue)
        }
    }

    return (
        <ToggleButtonGroup value={tabState} exclusive onChange={(e, value) => handleTabStateChange(value)} sx={sx}>
            <CustomToggle value={TaxonomyTabState.krona}>
                {tabState === TaxonomyTabState.krona ? <Check className="icon" sx={{ color: '#1B49D4' }} /> : <img alt="Krona Icon" src="/images/krona.svg" />}
                <Typography variant="body2" fontWeight="Bold" marginLeft="5px">
                    Krona-plot
                </Typography>
            </CustomToggle>
            <CustomToggle value={TaxonomyTabState.hierarchy}>
                {tabState === TaxonomyTabState.hierarchy ? <Check className="icon" sx={{ color: '#1B49D4' }} /> : <TableChart />}
                <Typography variant="body2" fontWeight="Bold" marginLeft="5px">
                    Hierarchy-table
                </Typography>
            </CustomToggle>
            <CustomToggle value={TaxonomyTabState.rank}>
                {tabState === TaxonomyTabState.rank ? <Check className="icon" sx={{ color: '#1B49D4' }} /> : <img alt="Rank table icon" src="/images/rank-table.svg" />}
                <Typography variant="body2" fontWeight="Bold" marginLeft="5px">
                    Rank table
                </Typography>
            </CustomToggle>
        </ToggleButtonGroup>
    )
}
