import { Accordion, styled } from '@mui/material'
import { ERROR_COLOR_RED } from './SampleSearch.constants'

const ACTIVE_COLOR = 'rgb(27, 73, 212)'

export const CustomAccordion = styled(Accordion)({
    borderBottom: '2px solid #EBEBEC',
    '&:before': {
        content: 'none',
    },
    '& .active': {
        color: ACTIVE_COLOR,
    },
    '& .error': {
        color: ERROR_COLOR_RED,
    },
})

export const GeochemAccordion = styled(Accordion)({
    padding: 0,
    '&:before': {
        content: 'none',
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .active': {
        color: ACTIVE_COLOR,
    },
    '& .error': {
        color: ERROR_COLOR_RED,
    },
})
