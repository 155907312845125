import { useContext, useEffect, useState } from 'react'
import { Grid, Box, IconButton, Typography, Button, Divider } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { TaxonomySidebar } from './TaxonomySidebar'
import { Main } from '../../../../components/SidebarDrawer'
import { NotesCardDrawer } from '../NotesCardDrawer'
import { TaxonomyAttributes, TaxonomyNotesCardState, buildAttributeCardItems, renderTaxonomyCardItems } from './TaxonomyNotesCard'
import { UnfoldLess, UnfoldMore } from '@mui/icons-material'
import { TaxonomyTab, TaxonomyTabState } from './TaxonomyTab'
import { RankGrid } from './RankGrid'
import { HierarchyGrid } from './HierarchyGrid'
import { Krona } from './Krona'
import { Taxon, filterTaxonomicHierarchyByKeyword, filterTaxonomicRankByKeyword, mapTaxonomicHierarchy, mapTaxonomicRank } from './Taxonomy.service'
import SearchText from '../../../../components/SearchText'
import { SampleDataContext } from '../../../../context/CustomContext'
import { useNotificationContext } from '../../../../context/NotificationContext'
import { getTaxonomicProfile } from '../../../../libs/api/taxonomic-profile/TaxonomicProfileAPI'

// Grid default to collapsed if number of rows is higher that this threshold
const GRID_COLLAPSE_ROW_COUNT = 200

export const enum ExpandState {
    expand,
    collapse,
    neutral,
}

export interface KronaItem {
    ssuRunId: string
    url: string
}

export interface TaxonomicProfileItem {
    ssuRunId: string
    taxaProfile: any
}

export interface TaxonomicHierarchyItem {
    ssuRunId: string
    taxons: Taxon[]
}

export interface TaxonomicRankItem {
    ssuRunId: string
    taxons: Taxon[]
}

export interface TaxonomyProps {
    taxonomicProfiles: TaxonomicProfileItem[]
    taxonomicHierarchies: TaxonomicHierarchyItem[]
    taxonomicRanks: TaxonomicRankItem[]
    setTaxonomicProfiles: (taxonomicProfiles: TaxonomicProfileItem[]) => void
    setTaxonomicHierarchies: (taxonomicHierarchies: TaxonomicHierarchyItem[]) => void
    setTaxonomicRanks: (taxonomicRanks: TaxonomicRankItem[]) => void
}

export default function Taxonomy(props: TaxonomyProps) {
    const { taxonomicProfiles, taxonomicHierarchies, taxonomicRanks, setTaxonomicProfiles, setTaxonomicHierarchies, setTaxonomicRanks } = props
    const { selectedSsuRunId, selectedSsuRun, setExportButtonEnabled } = useContext(SampleDataContext)
    const openNotification = useNotificationContext().openNotification

    const [showSidebar, setShowSidebar] = useState<boolean>(true)
    const [noteCardAttributeItems, setNoteCardAttributeItems] = useState<TaxonomyAttributes>()
    const [notesCardState, setNotesCardState] = useState<TaxonomyNotesCardState>(TaxonomyNotesCardState.Disabled)
    const [taxonomyTabState, setTaxonomyTabState] = useState<TaxonomyTabState>(TaxonomyTabState.krona)
    const [expandState, setExpandState] = useState<ExpandState>(ExpandState.neutral)
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const [selectedTaxonomicProfile, setSelectedTaxonomicProfile] = useState<any>()

    const [selectedTaxonomicHierarchy, setSelectedTaxonomicHierarchy] = useState<Taxon[]>([])
    const [searchedTaxonomicHierarchy, setSearchedTaxonomicHierarchy] = useState<Taxon[]>([])

    const [selectedTaxonomicRank, setSelectedTaxonomicRank] = useState<Taxon[]>([])
    const [searchedTaxonomicRank, setSearchedTaxonomicRank] = useState<Taxon[]>([])

    const [searchTerm, setSearchTerm] = useState<string>('')

    useEffect(() => {
        async function loadTaxonomyProfile() {
            setLoading(true)
            const currentTaxonomicProfiles = taxonomicProfiles || []
            const { succeeded, data, errors } = await getTaxonomicProfile(selectedSsuRunId)
            if (succeeded) {
                setTaxonomicProfiles([...currentTaxonomicProfiles, { ssuRunId: selectedSsuRunId, taxaProfile: data }])

                const mappedTaxonomicHierarchy = mapTaxonomicHierarchy(data)
                const currentTaxonomicHierarchies = taxonomicHierarchies || []
                setTaxonomicHierarchies([...currentTaxonomicHierarchies, { ssuRunId: selectedSsuRunId, taxons: mappedTaxonomicHierarchy }])
                setSearchedTaxonomicHierarchy(mappedTaxonomicHierarchy)

                const mappedTaxonomicRank = mapTaxonomicRank(data)
                const currentTaxonomicRanks = taxonomicRanks || []
                setTaxonomicRanks([...currentTaxonomicRanks, { ssuRunId: selectedSsuRunId, taxons: mappedTaxonomicRank }])
                setSearchedTaxonomicRank(mappedTaxonomicRank)
            } else {
                setTaxonomicProfiles([...currentTaxonomicProfiles, { ssuRunId: selectedSsuRunId, taxaProfile: {} }])
                openNotification(errors.message, errors.severity)
            }
            setLoading(false)
        }

        const currSelectedTaxonomicProfile = taxonomicProfiles && taxonomicProfiles.find((x) => x.ssuRunId === selectedSsuRunId)
        if (currSelectedTaxonomicProfile) {
            setSelectedTaxonomicProfile(currSelectedTaxonomicProfile.taxaProfile)
        } else if (selectedSsuRunId) {
            loadTaxonomyProfile()
        }
    }, [taxonomicProfiles, selectedSsuRunId, setSelectedTaxonomicProfile])

    useEffect(() => {
        const taxaHierarchy = taxonomicHierarchies.find((x) => x.ssuRunId === selectedSsuRunId)
        setSelectedTaxonomicHierarchy(taxaHierarchy?.taxons || [])

        const taxaRank = taxonomicRanks.find((x) => x.ssuRunId === selectedSsuRunId)
        setSelectedTaxonomicRank(taxaRank?.taxons || [])
    }, [selectedSsuRunId])

    useEffect(() => {
        if (selectedSsuRun && selectedTaxonomicProfile) {
            const noteCardItems = buildAttributeCardItems(selectedSsuRun.sequencing?.fwd?.readCount, selectedSsuRun.readQC?.fwd?.readCount, selectedTaxonomicProfile)
            setNoteCardAttributeItems(noteCardItems)
        }
    }, [selectedSsuRun, selectedTaxonomicProfile])

    useEffect(() => {
        if (expandState !== ExpandState.neutral) {
            setExpandState(ExpandState.neutral)
        }
    }, [expandState])

    useEffect(() => {
        setExportButtonEnabled(taxonomyTabState !== TaxonomyTabState.krona)
    }, [taxonomyTabState])

    useEffect(() => {
        const filterTaxonomicHierarchy = () => {
            const lowerCaseTerm = searchTerm.toLowerCase()

            const searchedTaxonomicHierarchy = filterTaxonomicHierarchyByKeyword(lowerCaseTerm, selectedTaxonomicHierarchy)
            setSearchedTaxonomicHierarchy(searchedTaxonomicHierarchy)

            const searchedTaxonomicRank = filterTaxonomicRankByKeyword(lowerCaseTerm, selectedTaxonomicRank)
            setSearchedTaxonomicRank(searchedTaxonomicRank)

            if (searchedTaxonomicHierarchy.length > GRID_COLLAPSE_ROW_COUNT || searchedTaxonomicRank.length > GRID_COLLAPSE_ROW_COUNT) {
                setExpandState(ExpandState.collapse)
            } else {
                setExpandState(ExpandState.expand)
            }
        }

        filterTaxonomicHierarchy()
    }, [searchTerm, selectedTaxonomicHierarchy, selectedTaxonomicRank])

    return (
        <>
            {!selectedSsuRunId ? (
                <Box id="taxonomy" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid sx={{ marginTop: '200px' }}>
                        <Typography variant="h4">Taxonomic data is not available for the sample</Typography>
                    </Grid>
                </Box>
            ) : (
                <Box id="taxonomy" sx={{ display: 'flex', width: '100%' }}>
                    <TaxonomySidebar open={showSidebar} onClose={() => setShowSidebar(false)} />
                    <Main open={showSidebar} sx={{ width: 'auto', flex: 1, overflow: 'hidden' }}>
                        <Grid display="flex" flexDirection="row">
                            <IconButton
                                onClick={() => setShowSidebar(true)}
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{
                                    mar: 2,
                                    paddingTop: '2px',
                                    ...(showSidebar && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h5">{selectedSsuRunId}</Typography>
                        </Grid>
                        <Grid sx={{ display: 'flex', width: '100%', height: '40px' }}>
                            <Button disabled={!noteCardAttributeItems} onClick={() => setNotesCardState(TaxonomyNotesCardState.Attributes)} sx={{ alignSelf: 'flex-end' }}>
                                <Typography variant="body2" textTransform="capitalize" fontWeight={500}>
                                    View attributes
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid display="flex" flexDirection="row" alignItems="center">
                            <TaxonomyTab tabState={taxonomyTabState} onTabStateChange={(value) => setTaxonomyTabState(value)} sx={{ padding: '20px 0' }} />
                            <Grid display="flex" width="100%" height="50px" justifyContent="space-between">
                                <Grid display="flex" flexDirection="row" alignItems="center">
                                    <Divider orientation="vertical" sx={{ borderWidth: '1px', marginLeft: '10px' }} />
                                    <SearchText
                                        label="Search by keyword..."
                                        disabled={taxonomyTabState === TaxonomyTabState.krona}
                                        onSearchChange={setSearchTerm}
                                        sx={{
                                            width: '80%',
                                            minWidth: '300px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </Grid>
                                <Grid>
                                    <Button
                                        onClick={() => setExpandState(ExpandState.expand)}
                                        sx={{ marginLeft: '10px', display: `${taxonomyTabState === TaxonomyTabState.krona ? 'none' : ''}` }}
                                    >
                                        <UnfoldMore sx={{ color: 'black' }} />
                                        <Typography
                                            variant="body2"
                                            textTransform="capitalize"
                                            sx={{
                                                color: 'rgba(34, 34, 58, 1)',
                                                marginLeft: '5px',
                                                padding: '4px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Expand all
                                        </Typography>
                                    </Button>
                                    <Button
                                        onClick={() => setExpandState(ExpandState.collapse)}
                                        sx={{ marginLeft: '10px', display: `${taxonomyTabState === TaxonomyTabState.krona ? 'none' : ''}` }}
                                    >
                                        <UnfoldLess sx={{ color: 'black' }} />
                                        <Typography
                                            variant="body2"
                                            textTransform="capitalize"
                                            sx={{
                                                color: 'rgba(34, 34, 58, 1)',
                                                marginLeft: '5px',
                                                padding: '4px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Collapse all
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {taxonomyTabState === TaxonomyTabState.krona ? <Krona /> : null}
                        {taxonomyTabState === TaxonomyTabState.hierarchy ? (
                            <HierarchyGrid
                                taxonomicProfile={selectedTaxonomicProfile}
                                taxonomicHierarchy={searchedTaxonomicHierarchy}
                                expandState={expandState}
                                isExpanded={isExpanded}
                                searchTerm={searchTerm}
                                loading={loading}
                                setIsExpanded={setIsExpanded}
                            />
                        ) : null}
                        {taxonomyTabState === TaxonomyTabState.rank ? (
                            <RankGrid
                                taxonomicProfileId={selectedTaxonomicProfile?.id}
                                taxonomicRank={searchedTaxonomicRank}
                                expandState={expandState}
                                isExpanded={isExpanded}
                                searchTerm={searchTerm}
                                loading={loading}
                                setIsExpanded={setIsExpanded}
                            />
                        ) : null}
                    </Main>
                    <NotesCardDrawer
                        open={notesCardState !== TaxonomyNotesCardState.Disabled}
                        title="Attributes (16S rRNA)"
                        onClose={() => setNotesCardState(TaxonomyNotesCardState.Disabled)}
                    >
                        {renderTaxonomyCardItems(notesCardState, noteCardAttributeItems)}
                    </NotesCardDrawer>
                </Box>
            )}
        </>
    )
}
