import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import Layout from '../Layout'
import { Box, Grid, Typography } from '@mui/material'

export default function ErrorPage() {
    const error = useRouteError()

    let errorMessage: string

    if (isRouteErrorResponse(error)) {
        errorMessage = error.data.message || error.statusText
    } else if (error instanceof Error) {
        errorMessage = error.message
    } else if (typeof error === 'string') {
        errorMessage = error
    } else {
        console.error(error)
        errorMessage = 'Unknown error'
    }

    return (
        <>
            <Layout />
            <Box id="error-page" height={'100vh'}>
                <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20vh' }}>
                    <Typography variant="h4" sx={{ marginBottom: '30px' }}>
                        Sorry, an unexpected error has occurred.
                    </Typography>
                    <Typography>
                        <i>{errorMessage}</i>
                    </Typography>
                </Grid>
            </Box>
        </>
    )
}
