import BaseAPI, { ApiResponse } from '../BaseAPI'
import { CreateStaticReport, StaticReport, StaticReportRow } from './StaticReportModel'
import { mapStaticReportToStaticReportRow } from './ReportMapper'

/**
 * Gets report data for the ReportsDataGrid page
 * @returns An array of grid report data
 */
export async function getReportsDataGrid(): Promise<ApiResponse<StaticReportRow[]>> {
    const response = await BaseAPI.getMany<StaticReport>('/static_reports/')

    const reportRows = response.data && response.data.flatMap((report) => mapStaticReportToStaticReportRow(report))

    return {
        ...response,
        data: reportRows,
    }
}

/**
 * Deletes all report data for the ReportsDataGrid page
 * @param reportId The Id of the report to be deleted
 * @returns Success or failure. No data included in the response
 */
export async function deleteReport(reportId: string): Promise<ApiResponse<StaticReport>> {
    const response = await BaseAPI.delete<StaticReport>(`/static_reports/${reportId}/delete/`)

    return {
        ...response,
        data: null,
    }
}

/**
 * Deletes report file data for the ReportsDataGrid page
 * @param reportId The Id of the report to be deleted
 * @param fileType The file type of the report to be deleted
 * @returns Success or failure. No data included in the response
 */
export async function deleteReportFile(reportId: string, fileType: string): Promise<ApiResponse<StaticReport>> {
    const response = await BaseAPI.delete<StaticReport>(`/static_reports/${reportId}/delete/${fileType}`)

    return {
        ...response,
        data: null,
    }
}

/**
 * Creates a new static report generation request
 * @param staticReport Static Report opbject to be created
 * @returns Created report object
 */
export async function createReport(staticReport: CreateStaticReport): Promise<ApiResponse<StaticReport>> {
    const response = await BaseAPI.create<CreateStaticReport>(`/static_reports/`, staticReport)

    return {
        ...response,
        data: null,
    }
}

/**
 * Downloads static report through xhr and then applies to a link
 * @param staticReport Static Report opbject to be created
 * @returns Report as a blob
 */
export async function downloadReport(reportId: string, fileType: string): Promise<ApiResponse<Blob>> {
    const response = await BaseAPI.get<Blob>(`/static_reports/${reportId}/download/`, fileType, {responseType: 'blob'})

    return response
}