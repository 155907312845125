import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'

export interface DeleteConfirmationDialogProps {
    open: boolean
    onClose: () => void
    onConfirm: () => void
}

export default function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {
    const { open, onClose, onConfirm } = props
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete this file?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ textTransform: 'none' }}>
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained" sx={{ textTransform: 'none' }}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}
