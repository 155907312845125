import { updateUsersAccessLevel } from '../../../libs/api/projects/ProjectAPI'
import { UpdateProjectPayload } from '../../../libs/api/projects/ProjectModel'
import { InviteUserResponse } from '../../../libs/api/users/UserModel'
import { Contributor, Project, ProjectStatus, UserRole } from '../Projects.model'
import { ContributorGridItem } from './projects-form/ContributorsGrid'

/**
 * Updates users access level for the project
 * @param projectId The project Id to have the permission updated
 * @param contributors The contributors list to have the permission updated
 * @returns Response notification message with update status
 */
export async function updateUsersAccess(projectId: string, contributors: ContributorGridItem[]): Promise<string> {
    const responses: InviteUserResponse[] = []
    for (let i = 0; i < contributors.length; i++) {
        const { succeeded, errors } = await updateUsersAccessLevel(
            projectId,
            contributors[i].email,
            contributors[i].role === UserRole.admin,
            contributors[i].role === UserRole.owner
        )
        if (succeeded) {
            responses.push({
                email: contributors[i].email,
                status: 'Update successful',
                note: 'Access level successfully updated',
            })
        } else {
            responses.push({
                email: contributors[i].email,
                status: 'Update failed',
                note: errors.message,
            })
        }
    }
    const responseMessage = NotificationMessageForProjectAccessUpdate(responses)
    return responseMessage
}

/**
 * Maps project object data to a simplified object required for the update
 * @param project The project object
 * @returns A simplified object data required for the update
 */
export function mapProjectUpdatePayload(project: Project): UpdateProjectPayload {
    return {
        id: project.id,
        name: project.name,
        description: project.description,
        status: project.status.toString(),
        isPublic: project.isPublic || false,
        publicSampleProps: project.publicSampleProps || [],
    }
}

/**
 * Generates a new project object for project creation
 * @param userProfile The user profile object that is creating the new project
 * @returns The new project object
 */
export function getNewProjectData(userProfile: Contributor): Project {
    return {
        id: null,
        name: '',
        organizationName: userProfile.organizationName,
        description: '',
        contributors: [userProfile],
        status: ProjectStatus.open,
        isPublic: false,
    }
}

/**
 * Builds a notification message to be displayed for the user based on project access update
 * @param responses The response data obtained from project access update
 * @returns The response message to be displayed after project access update
 */
export function NotificationMessageForProjectAccessUpdate(responses: InviteUserResponse[]): string {
    let responseMessage = ''
    responses.forEach((response) => {
        responseMessage = responseMessage + response.email + ': ' + response.note + '\n'
    })
    return responseMessage
}
