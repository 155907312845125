import { useState, useEffect, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import TabContext from '@mui/lab/TabContext'
import { TabPanel } from '@mui/lab'
import { SampleDataContext, UserProfileContext } from '../../context/CustomContext'
import Photos, { PhotoImage } from './subcomponents/Photos'
import Taxonomy, { KronaItem, TaxonomicHierarchyItem, TaxonomicProfileItem, TaxonomicRankItem } from './subcomponents/taxonomy/Taxonomy'
import { TopBar } from './subcomponents/TopBar'
import { TabItemValues } from './SampleDetails.model'
import { MetabolicPathwayItem, MetabolicPathways, NoteCardAttributeItem } from './subcomponents/metabolic-pathways/MetabolicPathways'
import GeochemicalData from './subcomponents/geochemical-data/GeochemicalData'
import Summary from './subcomponents/summary/Summary'
import { PredictedGenes } from './subcomponents/predicted-genes/PredictedGenes'
import { Mags } from './subcomponents/mags/Mags'
import { getSample } from '../../libs/api/samples/SampleAPI'
import { useNotificationContext } from '../../context/NotificationContext'
import { Sample } from '../../libs/api/samples/SampleModel'
import { getSsuRun, getSsuRunIds } from '../../libs/api/ssuruns/SsurunAPI'
import { SsuRun } from '../../libs/api/ssuruns/SsurunModel'
import { useStorageContext } from '../../context/Storage'
import { mapSearchFilters } from '../search/SampleSearch.services'
import { SearchFilters } from './SidebarSearchFilters'

/**
 * The Sample Details base component. Holds states of its children tabs
 */
export default function SampleDetails() {
    const { sampleId } = useParams()
    const userProfile = useContext(UserProfileContext)
    const { samplesSearchContext } = useStorageContext()
    const openNotification = useNotificationContext().openNotification

    const [sampleData, setSampleData] = useState<Sample>()
    const [isSampleLoaded, setIsSampleLoaded] = useState<boolean>(false)
    const [isUserMemberOfProject, setIsUserMemberOfProject] = useState<boolean>(false)
    const [editData, setEditData] = useState(false)
    const [currentTab, setCurrentTab] = useState(TabItemValues.Summary)

    // Export
    const [exportButtonEnabled, setExportButtonEnabled] = useState<boolean>(true)
    const [triggerExport, setTriggerExport] = useState<boolean>(false)

    // Krona
    const [kronas, setKronas] = useState<KronaItem[]>()

    // ssuRuns
    const [ssuRunIds, setSsuRunIds] = useState<string[]>()
    const [ssuRuns, setSsuRuns] = useState<SsuRun[]>()
    const [selectedSsuRunId, setSelectedSsuRunId] = useState<string>()
    const [selectedSsuRun, setSelectedSsuRun] = useState<SsuRun>()

    // Taxonomy
    const [taxonomicProfiles, setTaxonomicProfiles] = useState<TaxonomicProfileItem[]>()
    const [taxonomicHierarchies, setTaxonomicHierarchies] = useState<TaxonomicHierarchyItem[]>([])
    const [taxonomicRanks, setTaxonomicRanks] = useState<TaxonomicRankItem[]>([])

    // Metabolic Pathways
    const [metabolicPathways, setMetabolicPathways] = useState<MetabolicPathwayItem[]>([])
    const [noteCardAttributeItems, setNoteCardAttributeItems] = useState<NoteCardAttributeItem[]>([])

    // Images
    const [images, setImages] = useState<PhotoImage[]>()

    // Sidebar
    const [searchFilters, setSearchFilters] = useState<SearchFilters>()
    const [isAccessionSidebarOpen, setIsAccessionSidebarOpen] = useState<boolean>(true)

    const updateSearchFilters = useCallback(
        (sample: Sample) => {
            const filters = samplesSearchContext?.filters
            if (filters) {
                const result = mapSearchFilters(filters, sample)
                setSearchFilters(result)
            }
        },
        [samplesSearchContext]
    )

    useEffect(() => {
        async function getSampleData() {
            const { succeeded, data, errors } = await getSample(sampleId)
            if (succeeded) {
                setSampleData(data)
                updateSearchFilters(data)
            } else {
                openNotification(errors.message, errors.severity)
            }
            setIsSampleLoaded(true)
        }

        getSampleData()
    }, [sampleId])

    useEffect(() => {
        let isUserMember = false
        if (sampleData && sampleData.projectId && userProfile.access) {
            isUserMember = userProfile.access.includes(sampleData.projectId)
        }
        setIsUserMemberOfProject(isUserMember)
    }, [sampleData, userProfile])

    useEffect(() => {
        async function loadSsuRunIds() {
            const { succeeded, data, errors } = await getSsuRunIds(sampleId)
            if (succeeded) {
                setSsuRunIds(data)
                setSelectedSsuRunId(data[0])
            } else {
                setSsuRunIds([])
                openNotification(errors.message, errors.severity)
            }
        }

        loadSsuRunIds()
    }, [sampleId])

    useEffect(() => {
        const loadSsuRun = async () => {
            const { succeeded, data, errors } = await getSsuRun(selectedSsuRunId)
            if (succeeded) {
                const currentSsuRuns = ssuRuns || []
                setSsuRuns([...currentSsuRuns, data])
            } else {
                openNotification(errors.message, errors.severity)
            }
        }
        const currSelectedSsuRun = ssuRuns && ssuRuns.find((x) => x.id === selectedSsuRunId)
        if (currSelectedSsuRun) {
            setSelectedSsuRun(currSelectedSsuRun)
        } else if (selectedSsuRunId) {
            loadSsuRun()
        }
    }, [selectedSsuRunId, ssuRuns])

    return (
        <SampleDataContext.Provider
            value={{
                sampleData,
                exportButtonEnabled,
                triggerExport,
                isUserMemberOfProject,
                ssuRunIds,
                selectedSsuRunId,
                selectedSsuRun,
                kronas,
                searchFilters,
                isAccessionSidebarOpen,
                setSampleData,
                setExportButtonEnabled,
                setTriggerExport,
                setSelectedSsuRunId,
                setKronas,
                setSearchFilters,
                setIsAccessionSidebarOpen,
            }}
        >
            <TabContext value={currentTab}>
                <TopBar sampleId={sampleId} currentTab={currentTab} publicSampleProps={sampleData?.publicSampleProps} onEditData={setEditData} updateCurrentTab={setCurrentTab} />
                <TabPanel value={TabItemValues.Summary} sx={{ padding: 0 }}>
                    <Summary isSampleLoaded={isSampleLoaded} sampleData={sampleData} editSampleData={editData} setEditSampleData={setEditData} />
                </TabPanel>
                <TabPanel value={TabItemValues.Taxonomy} sx={{ padding: 0 }}>
                    <Taxonomy
                        taxonomicProfiles={taxonomicProfiles}
                        taxonomicHierarchies={taxonomicHierarchies}
                        taxonomicRanks={taxonomicRanks}
                        setTaxonomicProfiles={setTaxonomicProfiles}
                        setTaxonomicHierarchies={setTaxonomicHierarchies}
                        setTaxonomicRanks={setTaxonomicRanks}
                    />
                </TabPanel>
                <TabPanel value={TabItemValues.PredictedGenes} sx={{ padding: 0 }}>
                    <PredictedGenes />
                </TabPanel>
                <TabPanel value={TabItemValues.MetabolicPathways} sx={{ padding: 0 }}>
                    <MetabolicPathways
                        metabolicPathways={metabolicPathways}
                        noteCardAttributeItems={noteCardAttributeItems}
                        setMetabolicPathways={setMetabolicPathways}
                        setNoteCardAttributeItems={setNoteCardAttributeItems}
                    />
                </TabPanel>
                <TabPanel value={TabItemValues.MAGs} sx={{ padding: 0 }}>
                    <Mags />
                </TabPanel>
                <TabPanel value={TabItemValues.GeochemicalData} sx={{ padding: 0 }}>
                    <GeochemicalData />
                </TabPanel>
                <TabPanel value={TabItemValues.Photos}>
                    <Photos sampleId={sampleId} images={images} setImages={setImages} />
                </TabPanel>
            </TabContext>
        </SampleDataContext.Provider>
    )
}
