import qs from 'qs'
import BaseAPI, { ApiResponse } from '../BaseAPI'
import { BarcodeDTO } from './BarcodesDTO'
import { mapBarcodeDTOtoBarcode } from './BarcodesMapper'

export async function searchBarcodes(start: Date, end: Date): Promise<ApiResponse<BarcodeDTO[]>> {
    console.log(start)
    console.log(end)
    const config = {
        params: { start, end },
        paramsSerializer: (params: any) => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        },
    }
    const response = await BaseAPI.getMany<BarcodeDTO>(`/searchBarcodes`, null, config)

    if (response.succeeded) {
        const barcodes = response.data?.map((fileObj) => mapBarcodeDTOtoBarcode(fileObj))

        return {
            ...response,
            data: barcodes ?? [],
        }
    } else {
        return {
            ...response,
            data: null,
        }
    }
}

export async function generateBarcodes(numBarcodes:number,customer:string,barcode_output_format:string,codeType:string): Promise<ApiResponse<Blob | MediaSource>>{
    
    const config = {
        responseType: 'blob',
        params:{
        numBarcodes,customer,barcode_output_format,codeType
    }}
    const response = await BaseAPI.getBlob("/sample/barcode/generator",null,config)
    if (response.succeeded && response.data instanceof Blob) {

        return {
            ...response            
        }
    } else {
        return {
            ...response,
            data: null,
        }
    }
}