import { Toolbar, AppBar, Grid, Typography, Button, Box, CssBaseline, Card, Stack, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, Input, FormGroup, FormControl, InputLabel, Grow } from '@mui/material'
import { useState } from 'react'
import { checkSsoOrg, loginRedirect } from '../libs/api/ServicesAuth'
import { useNotificationContext } from '../context/NotificationContext';
import ProgressIndicator from '../components/ProgressIndicator';

export default function Login() {
    const notifications = useNotificationContext();

    const [loginModalOpen, setLoginModalOpen] = useState(false)
    const [orgFieldVisible, setOrgFieldVisible] = useState(false)
    const [ssoOrg, setSsoOrg] = useState<string>()
    const [loading, setLoading] = useState(false)

    function emailPasswordLogin() {
        loginRedirect()
    }

    async function ssoLogin() {
        if (!ssoOrg)
            return

        setLoading(true)

        if (await checkSsoOrg(ssoOrg))
            loginRedirect(ssoOrg)
        else
            notifications.openNotification(`Could not find your Organization ID "${ssoOrg}"`, 'error')

        setLoading(false)
    }

    function onSsoOrgInput_keyup(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (e.key === "Enter") {
            ssoLogin();
        }
    }

    return (
        <>
            <Dialog
                open={loginModalOpen}
                onClose={() => setLoginModalOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ style: { borderRadius: 12, padding: 15 } }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title">Log in</DialogTitle>
                <DialogContent>
                    <>
                        <ProgressIndicator open={loading}></ProgressIndicator>
                        <Grid container spacing={'4'} alignItems={'center'}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={emailPasswordLogin}>
                                    Log in with email and password
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => setOrgFieldVisible(v => !v)}>
                                    Log in with organization (SSO)
                                </Button>
                            </Grid>
                            {orgFieldVisible && <Grow in={orgFieldVisible}>
                                <Grid container xs={12} alignItems={'end'} paddingRight={'15px'}>
                                    <Grid item xs={9}>
                                        <FormGroup>
                                            <FormControl sx={{ marginTop: '20px' }}>
                                                <InputLabel htmlFor="sso-org">Organization ID</InputLabel>
                                                <Input autoFocus id="sso-org" value={ssoOrg} onChange={(e) => setSsoOrg(e.target.value)} onKeyUp={onSsoOrgInput_keyup} />
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                marginLeft: '15px',
                                            }}
                                            fullWidth
                                            disabled={!ssoOrg}
                                            onClick={ssoLogin}
                                        >
                                            Log in
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grow>}
                        </Grid>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            height: '36px',
                            padding: '9px 16px',
                            marginLeft: 'auto',
                            borderRadius: '12px',
                            alignItems: 'flex-start',
                        }}
                        onClick={() => setLoginModalOpen(false)}
                    >
                        <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                            Cancel
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ flexGrow: 1 }}>
                <CssBaseline />
                <AppBar position="static" sx={{ bgcolor: '#FFFFFF', color: 'text.primary' }}>
                    <Toolbar>
                        <Typography variant="appbar" component="div" sx={{ flexGrow: 1 }}>
                            M-MAP
                        </Typography>
                        <Button color="inherit" onClick={() => setLoginModalOpen(true)}>
                            Log in
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box component="main" sx={{ width: '100%' }}>
                    <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: 300,
                            backgroundColor: 'primary.main',
                            backgroundImage: `url(/images/hero-pattern.svg)`,
                            backgroundSize: 'cover',
                        }}
                    >
                        <Grid item xs={11}>
                            <Typography variant="heroHeading" component="div" sx={{ flexGrow: 1, color: 'common.white', mb: 2 }}>
                                Unearth the mining microbiome
                            </Typography>
                            <Typography variant="heroBody" component="div" sx={{ flexGrow: 1, color: 'common.white' }}>
                                M-MAP provides data and services to help create and deploy bacterial microbial solutions for mining applications.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} justifyContent="center">
                        <Grid item xs={11}>
                            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                <Grid item xs={12}>
                                    <Stack alignItems="center">
                                        <Typography variant="heroSubHeading" component="div" sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
                                            Mining Microbiome Analytics Platform
                                        </Typography>
                                        <Typography paragraph variant="body1" component="div" sx={{ flexGrow: 1 }}>
                                            The Mining Microbiome Analytics Platform (M-MAP) is an online sample analysis platform that accelerates the creation and deployment of
                                            microbial solutions for mining. Solutions to mining’s biggest challenges are right underneath our feet. The world is teeming with
                                            microscopic organisms - microbes.
                                        </Typography>
                                        <Typography paragraph variant="body1" component="div" sx={{ flexGrow: 1 }}>
                                            Using microbes to recover copper, uranium, and gold from tailings has already proven to be cost effective and efficient. Why stop there? One
                                            of the biggest obstacles in developing innovative microbial-based technologies is that microbes are complex. M-MAP addresses this complexity
                                            using genetic sequencing and state-of-the-art computational analyses that extract key information about microbial communities so you can
                                            quickly understand who is there and what they are doing.
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack alignItems="center">
                                        <img width="80%" alt="Overview" src="/images/m-map-overview.png" />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ height: '150px' }}>
                                        <CardContent>
                                            <Stack alignItems="center">
                                                <img src="/images/frame-1.svg" alt="Icon" width="48px" />
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, mt: 4 }}>
                                                    Streamlined solution development
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ height: '150px' }}>
                                        <CardContent>
                                            <Stack alignItems="center">
                                                <img src="/images/frame-2.svg" alt="Icon" width="48px" />
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, mt: 4 }}>
                                                    Enhanced microbial community characterization
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ height: '150px' }}>
                                        <CardContent>
                                            <Stack alignItems="center">
                                                <img src="/images/frame-3.svg" alt="Icon" width="48px" />
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, mt: 4 }}>
                                                    Rapid results and secure data sharing
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={3} sx={{ opacity: '0.5' }}>
                                    <img alt="Allonnia Logo" src="/images/allonnia.svg" />
                                </Grid>
                                <Grid item xs={3} sx={{ opacity: '0.5' }}>
                                    <img alt="Teck Logo" src="/images/teck.svg" />
                                </Grid>
                                <Grid item xs={3} sx={{ opacity: '0.5' }}>
                                    <img alt="Rio Tinto Logo" src="/images/rio-tinto.svg" />
                                </Grid>
                                <Grid item xs={3} sx={{ opacity: '0.5' }}>
                                    <img alt="BRIMM Logo" src="/images/brimm.svg" />
                                </Grid>
                                <Grid item xs={3} sx={{ opacity: '0.5' }}>
                                    <img alt="Genome BC Logo" src="/images/genome-bc.svg" />
                                </Grid>
                                <Grid item xs={3} sx={{ opacity: '0.5' }}>
                                    <img alt="CEMI Logo" src="/images/cemi.svg" />
                                </Grid>
                                <Grid item xs={3} sx={{ opacity: '0.5' }}>
                                    <img alt="Koonkie Logo" src="/images/koonkie.svg" />
                                </Grid>
                                <Grid item xs={3} sx={{ opacity: '0.5' }}>
                                    <img alt="BGC Logo" src="/images/bgc.svg" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack alignItems="center">
                                        <Typography variant="heroSubHeading" component="div" sx={{ flexGrow: 1, mt: 6, mb: 4 }}>
                                            M-MAP Features
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, mb: 2 }}>
                                        Sample Library
                                    </Typography>
                                    <Typography paragraph variant="body1" component="div" sx={{ flexGrow: 1 }}>
                                        M-MAP’s sample library provides data from thousands of samples taken from a broad range of mining-related environments and mine waste
                                        structures. You can use the sample library to assist in the discovery of novel species, genes, and metabolic pathways that can be applied to
                                        processes spanning the entire mining lifecycle. With data from the sample library, you can answer questions about microbial community
                                        composition and function in a specific environment, how communities change over time, and the geographic range of individual genes or species.
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Stack alignItems="center">
                                        <img alt="Screenshot 1" src="/images/group-1.png" width="90%" />
                                    </Stack>
                                </Grid>
                                <Grid item xs={7}>
                                    <Stack alignItems="center">
                                        <img alt="Screenshot 2" src="/images/group-2.png" width="90%" />
                                    </Stack>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, mb: 2 }}>
                                        Sample Analysis Service
                                    </Typography>
                                    <Typography paragraph variant="body1" component="div" sx={{ flexGrow: 1 }}>
                                        You can use M-MAP’s sample analysis platform to accurately determine the composition and functionality of the microbial community at your sites.
                                        To start using the platform, request a sample kit from M-MAP’s online portal and download the sampling application for iOS or Android. Our
                                        sampling application will guide you through the sampling process and help you collect essential metadata such as sample location and
                                        temperature. Once M-MAP receives the sample, we store, prepare, and sequence it at accredited Canadian biobank and sequencing facilities. M-MAP
                                        also accepts sequencing data from third-party sequencing facilities that are compatible with our standard operating procedures. Sequencing data
                                        is processed using state-of-the-art bioinformatic pipelines and made available online in a searchable and analysis ready format. M-MAP provides
                                        additional environmental data collected from remote-sensing platforms to provide further environmental context and enhanced bioinformatic
                                        insights. Finally, you can request additional sequencing runs on samples stored in the biobank, reducing the need to re-acquire the sample from
                                        the field.
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, mb: 2 }}>
                                        Enhanced Data Control
                                    </Typography>
                                    <Typography paragraph variant="body1" component="div" sx={{ flexGrow: 1 }}>
                                        M-MAP’s online platform makes it easier to control your sample data. Using M-MAP’s fine-grained access controls, you can securely share data
                                        from your samples internally, with specified external individuals or organizations, or publicly in complete or anonymized form.
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Stack alignItems="center">
                                        <img alt="Screenshot 3" src="/images/group-3.png" width="90%" />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} alignItems="center" justifyContent="center" sx={{ height: 200, backgroundColor: 'primary.dark' }}>
                        <Grid item xs={8}>
                            <Typography variant="appbar" component="div" sx={{ flexGrow: 1, color: 'common.white' }}>
                                M-MAP
                            </Typography>
                            <Typography component="div" sx={{ flexGrow: 1, color: 'common.white', mt: 3 }}>
                                © 2024 MMAP. All rights reserved.
                            </Typography>
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
