import { ProjectDTO, ProjectGridDTO, ProjectInviteDTO, ProjectMembersDTO } from './ProjectDTO'
import { Project, ProjectGrid, ProjectInvite, ProjectMembers } from './ProjectModel'

export const mapProjectDtoToProject = (projectDTO: ProjectDTO): Project => {
    const project: Project = {
        id: projectDTO.id,
        name: projectDTO.name,
        description: projectDTO.description,
        status: projectDTO.status,
        isPublic: projectDTO.isPublic,
        lastSampleAdded: projectDTO.lastSampleAdded,
        lastSampleAddedBy: projectDTO.lastSampleAddedBy,
        publicSampleProps: projectDTO.publicSampleProps,
    }
    return project
}

export const mapProjectGridDtoToProjectGrid = (projectDTO: ProjectGridDTO): ProjectGrid => {
    const project: ProjectGrid = {
        id: projectDTO.id,
        name: projectDTO.name,
        description: projectDTO.description,
        organizationId: projectDTO.organizationId,
        organizationName: projectDTO.organizationName,
        status: projectDTO.status,
        owner: projectDTO.owner,
        isPublic: projectDTO.isPublic,
        lastSampleAdded: projectDTO.lastSampleAdded,
        lastSampleAddedBy: projectDTO.lastSampleAddedBy,
        publicSampleProps: projectDTO.publicSampleProps,
        contributors: projectDTO.contributors,
        samples: projectDTO.samples,
        lastModified: projectDTO.lastModified,
        isUserMember: projectDTO.isUserMember,
        allowProjectUpdate: projectDTO.allowProjectUpdate,
    }
    return project
}

export const mapProjectMembersDtoToProjectMembers = (projectMembersDTO: ProjectMembersDTO): ProjectMembers => {
    const projectMembers: ProjectMembers = {
        owner: projectMembersDTO.owner,
        admins: projectMembersDTO.admins,
        users: projectMembersDTO.users,
    }
    return projectMembers
}

export const mapProjectInvitesDtoToProjectInvites = (projectInvitesDTO: ProjectInviteDTO[]): ProjectInvite[] => {
    const projectInvites: ProjectInvite[] = projectInvitesDTO.map((projectInvite) => {
        return {
            projectId: projectInvite.projectId,
            projectName: projectInvite.name,
            isAdmin: projectInvite.admin,
        }
    })

    return projectInvites
}
