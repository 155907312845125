import { Grid, Container, Typography } from '@mui/material'
import SearchText from '../../components/SearchText'
import { GridReports } from './subcomponents/projects-grid'
import { useNotificationContext } from '../../context/NotificationContext'
import { getReportsDataGrid } from '../../libs/api/static-reports/ReportAPI'
import { useEffect, useReducer, useState } from 'react'
import { StaticReportRow } from '../../libs/api/static-reports/StaticReportModel'

export default function Reports() {

    const openNotification = useNotificationContext().openNotification
    const [reportList, setReportList] = useState<StaticReportRow[]>([])
    const [filteredRecords, setFilteredRecords] = useState<StaticReportRow[]>([])

    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState<string>(null)
    const [loadCount, updateLoadCount] = useReducer(
        (c) => c + 1, 0
    )

    useEffect(() => {
        async function loadReports() {
            setReportList([])
            setLoading(true)
            const { succeeded, data, errors } = await getReportsDataGrid()
            if (succeeded) {
                setReportList(data)
            } else {
                openNotification(errors.message, errors.severity)
            }

            setLoading(false)
        }

        loadReports()
    }, [loadCount])

    useEffect(() => {
        if (searchText) {
            const searchLower = searchText.toLowerCase()
            setFilteredRecords(reportList.filter(r =>
                r.name.toLowerCase().includes(searchLower) ||
                r.reportType.toLowerCase().includes(searchLower) ||
                r.sequencingType.toLowerCase().includes(searchLower)
            ))
        }
        else
            setFilteredRecords(reportList)
    }, [searchText, reportList])

    return (
        <Container maxWidth={false} sx={{ padding: '20px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h4" style={{ padding: '20px 0' }}>
                        Static Reports
                    </Typography>
                    <SearchText label="Search by keywords..." disabled={false} onSearchChange={(s) => {
                        setSearchText(s)
                    }} />
                    <GridReports loading={loading} reportList={filteredRecords} reportDeleted={updateLoadCount} />
                </Grid>
            </Grid>
        </Container>
    )
}