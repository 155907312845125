import { useContext, useEffect, useState } from 'react'
import { useNotificationContext } from '../../../../context/NotificationContext'
import { UserRole } from '../../Projects.model'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { removeUserFromProject } from '../../../../libs/api/projects/ProjectAPI'
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { canDeleteUser, getUpdatedContributors, isRoleDropDownEnabled, updateContributorRole } from './ContributorsForm.service'
import { Check } from '@mui/icons-material'
import { UserProfileContext } from '../../../../context/CustomContext'

export interface ContributorGridItem {
    id?: string
    email: string
    role: UserRole
    status: ContributorStatus
    statusMessage?: string
}

export enum ContributorStatus {
    active = 'Active',
    pending = 'Pending',
}

interface ContributorsDataGridProps {
    projectId: string
    contributors: ContributorGridItem[]
    invitedUsers: ContributorGridItem[]
    loading: boolean
    userRole: UserRole
    onLoadContributors: () => void
    onModifiedContributors: (contributorChange: ContributorGridItem[]) => void
}

/**
 * The data grid component for project members
 */
export const ContributorsGrid = (props: ContributorsDataGridProps) => {
    const userProfile = useContext(UserProfileContext)
    const { projectId, contributors, invitedUsers, loading, userRole, onLoadContributors, onModifiedContributors } = props
    const openNotification = useNotificationContext().openNotification
    const [currentContributors, setCurrentContributors] = useState<ContributorGridItem[]>([])

    useEffect(() => {
        setCurrentContributors(contributors)
    }, [contributors])

    const handleRoleChange = (contributorId: string, newRole: UserRole) => {
        const updatedContributors = updateContributorRole(currentContributors, contributorId, newRole)
        setCurrentContributors(updatedContributors)
    }

    useEffect(() => {
        const updatedContributors = getUpdatedContributors(contributors, currentContributors)
        onModifiedContributors(updatedContributors)
    }, [contributors, currentContributors, onModifiedContributors])

    const handleRemoveUser = async (userEmail: string, userRole: UserRole) => {
        const { succeeded, errors } = await removeUserFromProject(projectId, userEmail, userRole === UserRole.admin)
        if (succeeded) {
            onLoadContributors()
            openNotification(`User ${userEmail} is successfully removed from project`, 'success')
        } else {
            openNotification(errors.message, errors.severity)
        }
    }

    const getActions = getActionsArray(userRole, handleRemoveUser, userProfile?.globalAdminPermission)
    const builtColumns = buildColumns(userRole, handleRoleChange, getActions, userProfile?.globalAdminPermission)

    return (
        <form id="project-form" style={{ display: 'flex', flexDirection: 'column' }}>
            <DataGrid
                getRowId={(r) => r.id}
                autoHeight={true}
                rows={[...invitedUsers, ...currentContributors]}
                loading={loading}
                columns={builtColumns}
                initialState={initialState}
                slots={{ noRowsOverlay: () => <NoProjectsOverlay /> }}
                rowHeight={60}
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    '& .project-name': {
                        fontWeight: 'bold',
                    },
                    borderTop: 'none',
                    borderRight: 'none',
                    borderLeft: 'none',
                }}
            />
        </form>
    )
}

/**
 * Fallback rendering component when no data is available in grid
 */
const NoProjectsOverlay = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 300)
        return () => clearTimeout(timeout)
    }, [])

    if (!show) return null
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <p>No users found</p>
        </div>
    )
}

/**
 * Grid columns setup
 */
const buildColumns = (userRole: UserRole, onRoleChange: (userId: string, newRole: UserRole) => void, getActionsArray: (params: any) => React.JSX.Element[], isGlobalAdmin: boolean) => {
    return [
        {
            field: 'email',
            headerName: 'Name',
            flex: 3,
            cellClassName: 'project-name',
            renderCell: (params) => (
                <div>
                    {params.row.statusMessage ? (
                        <Typography color="lightslategrey" variant="body2">
                            {params.row.statusMessage}
                        </Typography>
                    ) : null}
                    <Typography color="textSecondary" variant="body2">
                        {params.value}
                    </Typography>
                </div>
            ),
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 150,
            renderCell: (params) => (
                <FormControl variant="standard" disabled={!isRoleDropDownEnabled(userRole, params.value, params.row.status, isGlobalAdmin)}>
                    <Select
                        labelId="role-selection-label"
                        id="role-selection"
                        disableUnderline
                        value={params.value}
                        onChange={(x) => onRoleChange(params.id, x.target.value)}
                        sx={{
                            '& .caption': {
                                display: 'none',
                            },
                            '& .icon': {
                                display: 'none',
                            },
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    borderRadius: '10px',
                                },
                            },
                        }}
                    >
                        <MenuItem value={UserRole.owner}>
                            <Check className="icon" sx={params.value === UserRole.owner ? { color: '#1B49D4' } : { visibility: 'hidden' }}></Check>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '10px',
                                }}
                            >
                                <Typography>Owner</Typography>
                                <Typography className="caption" variant="caption">
                                    Full Control
                                </Typography>
                            </Grid>{' '}
                        </MenuItem>
                        <MenuItem className="caption" value={UserRole.admin}>
                            <Check className="icon" sx={params.value === UserRole.admin ? { color: '#1B49D4' } : { visibility: 'hidden' }}></Check>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '10px',
                                }}
                            >
                                <Typography>Admin</Typography>
                                <Typography className="caption" variant="caption">
                                    Manage project and samples
                                </Typography>
                            </Grid>
                        </MenuItem>
                        <MenuItem className="caption" value={UserRole.user}>
                            <Check className="icon" sx={params.value === UserRole.user ? { color: '#1B49D4' } : { visibility: 'hidden' }}></Check>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '10px',
                                }}
                            >
                                <Typography>Contributor</Typography>
                                <Typography className="caption" variant="caption">
                                    Manage samples
                                </Typography>
                            </Grid>
                        </MenuItem>
                    </Select>
                </FormControl>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            flex: 1,
            getActions: getActionsArray,
        },
    ]
}

function getActionsArray(userRole: UserRole, onRemoveUser: (email: string, userRole: UserRole) => void, isGlobalAdmin: boolean) {
    const actionItem = (params: any) => {
        return [
            <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                    onRemoveUser(params.row.email, params.row.role)
                }}
                sx={canDeleteUser(userRole, params.row.role, params.row.status, isGlobalAdmin) ? {} : { display: 'none' }}
            />,
        ]
    }

    return actionItem
}

/**
 * The initial state of the grid
 */
const initialState = {
    pagination: {
        paginationModel: { pageSize: 50, page: 0 },
    },
}
