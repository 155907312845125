import { useState, useEffect, useContext } from 'react'
import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements, Navigate } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { UserProfileContext } from './context/CustomContext'
import { StorageProvider } from './context/Storage'
import { appInsights, reactPlugin } from './libs/AppInsights'
import Layout from './Layout'
import Login from './pages/Login'
import Search from './pages/search'
import Status from './pages/status'
import SampleDetails from './pages/sample-details/SampleDetails'
import Projects from './pages/projects'
import { LicenseInfo } from '@mui/x-license-pro'
import ErrorPage from './pages/Error'
import { getUser } from './libs/api/users/UserAPI'
import { UserRequests } from './pages/user-requests/UserRequests'
import UploadPage from './pages/manage-data'
import NotApproved from './pages/NotApproved'
import Reports from './pages/reports/Reports'
import NotificationBar from './components/NotificationBar'
import GenerateBarcodesForm from './pages/barcodes/GenerateBarcodesForm'
import SearchBarcodes from './pages/barcodes/SearchBarcodes'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)

/**
 * Checks if user has status update permission before enabling status page route
 * Redirects to base route if not authorized
 */
const AuthStatus = () => {
    const userProfile = useContext(UserProfileContext)
    const hasStatusPermission = userProfile?.statusUpdatePermission
    return hasStatusPermission ? <Status /> : <Navigate to={'../'} />
}

/**
 * Checks if user has global admin permission before enabling users requests page route
 * Redirects to base route if not authorized
 */
const AuthUserRequests = () => {
    const userProfile = useContext(UserProfileContext)
    const hasGlobalAdminPermission = userProfile?.globalAdminPermission
    return hasGlobalAdminPermission ? <UserRequests /> : <Navigate to={'../'} />
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout />} errorElement={<ErrorPage />}>
            <Route index element={<Navigate replace to="/projects" />} />
            <Route path="search/:projectId?" element={<Search />} />
            <Route path="projects" element={<Projects />} />
            <Route path="status" element={<AuthStatus />} />
            <Route path="management" element={<AuthUserRequests />} />
            <Route path="upload" element={<UploadPage />} />
            <Route path="reports" element={<Reports />} />
            <Route path="barcodes/generate" element={<GenerateBarcodesForm />} />
            <Route path="barcodes/search" element={<SearchBarcodes />} />
            <Route path="sample/:projectId/:sampleId" element={<SampleDetails />} />
        </Route>
    )
)

function App() {
    const { accounts } = useMsal()

    const [userProfile, setUserProfile] = useState({})
    const [isApproved, setIsApproved] = useState(undefined)

    async function loadUserProfile() {
        const { succeeded, data } = await getUser()
        if (succeeded) {
            setIsApproved(data.isApproved)
            setUserProfile(data)
        }
    }

    useEffect(() => {
        if (accounts.length > 0) {
            appInsights.addTelemetryInitializer((env) => {
                env.tags = env.tags || []
                env.tags['ai.user.authUserId'] = accounts[0].username
            })

            loadUserProfile()
        }
    }, [accounts])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthenticatedTemplate>
                <StorageProvider>
                    <UserProfileContext.Provider value={userProfile}>
                        {isApproved !== undefined &&
                            <>
                                {isApproved ?
                                    <RouterProvider router={router} />
                                    :
                                    <NotApproved />
                                }
                            </>
                        }
                    </UserProfileContext.Provider>
                </StorageProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
            <NotificationBar />
        </LocalizationProvider>
    )
}

export default withAITracking(reactPlugin, App)
