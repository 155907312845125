import { useCallback, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DeleteIcon from '@mui/icons-material/Delete'
import { uploadGeochemicalDataFile } from '../../libs/api/geochemical-files/GeochemicalFilesAPI'
import { ApiError } from '../../libs/api/BaseAPI'
import CircularProgress from '@mui/material/CircularProgress'
import CancelIcon from '@mui/icons-material/Cancel'

const BASE_HEIGHT = 60 // base height in vh for the dialog without files
const MAX_HEIGHT = 80
const HEIGHT_PER_FILE = 5

const activeStyle = {
    borderColor: '#2196f3', // change to the color you want when hovering
    backgroundColor: '#e3f2fd', // optional: change background color when hovering
}

const rejectStyle = {
    borderColor: '#ff1744', // change to the color you want when rejected
    backgroundColor: '#ffcccb', // optional: change background color when rejected
    // Any other styles you want to apply to indicate a rejection
}

const errorStyle = {
    color: '#ff1744', // Red color for text and icons
    // Any other styles you want to apply for error case
}

const downloadTemplate = () => {
    // Create a new anchor element dynamically
    const link = document.createElement('a')
    link.href = `${process.env.PUBLIC_URL}/geochemical_data_template.xlsx` // The path to the file
    link.setAttribute('download', 'geochemical_data_template.xlsx') // The default filename for download

    // Append to the DOM and trigger the download
    document.body.appendChild(link)
    link.click()

    // Clean up by removing the element you created
    document.body.removeChild(link)
}

export interface UploadDialogProps {
    open: boolean
    onClose: () => void
    onRefreshFiles: () => Promise<void>
}

interface FileWithStatus {
    file: File
    errors?: string[]
    status: 'pending' | 'uploading' | 'complete' | 'error'
}

export default function UploadDialog(props: UploadDialogProps) {
    const { open, onClose } = props

    const [fileRejected, setFileRejected] = useState<boolean>(false)
    const [files, setFiles] = useState<FileWithStatus[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false) // New state for tracking loading status
    const [uploadAttempted, setUploadAttempted] = useState<boolean>(false)

    // Calculate the height based on the number of files
    const dialogHeight = Math.min(BASE_HEIGHT + files.length * HEIGHT_PER_FILE, MAX_HEIGHT)

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        const isFileRejected = fileRejections.length > 0
        setFileRejected(isFileRejected)

        if (!isFileRejected) {
            const filesWithStatus = acceptedFiles.map((file) => ({
                file, // Directly using the File object here
                status: 'pending',
                errors: [],
            }))
            setFiles((prevFiles) => [...prevFiles, ...filesWithStatus])
        }
    }, [])

    const handleClose = () => {
        setFileRejected(false) // Reset the state to false
        onClose() // Call the original onClose prop
    }

    const handleRemoveFile = (index) => {
        // Use a functional update to remove the file at the specified index
        setFiles((currentFiles) => currentFiles.filter((_, i) => index !== i))
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'text/plain': ['.tsv'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        // This will allow you to capture rejection events
        onDropRejected: () => {
            setFileRejected(true)
        },
    })

    const uploadFiles = async (filesToUpload: FileWithStatus[]) => {
        setIsLoading(true)
        setUploadAttempted(true)
        for (const fileWithStatus of filesToUpload) {
            const processedFile = await processFile(fileWithStatus.file)

            setFiles((prevFiles) =>
                prevFiles.map((fws) =>
                    fws.file.name === fileWithStatus.file.name
                        ? {
                              ...fws,
                              status: processedFile && processedFile.errors ? 'error' : 'complete',
                              errors: processedFile ? processedFile.errors : [],
                          }
                        : fws
                )
            )
            await props.onRefreshFiles()
        }

        setIsLoading(false)
    }

    const processFile = async (file: File) => {
        const formData = createFormData(file)
        const response = await uploadGeochemicalDataFile(formData)

        if (!response.succeeded) {
            return attachErrorsToFile(file, response.errors)
        }
    }

    const createFormData = (file: File) => {
        const formData = new FormData()
        formData.append('file', file)
        return formData
    }

    const attachErrorsToFile = (file: File, errors: ApiError) => {
        // Create a copy of the file with added 'errors' object
        return {
            ...file,
            errors: [errors.message],
        }
    }

    const resetDialog = () => {
        setFiles([]) // Clears out any files
        setUploadAttempted(false) // Resets the upload attempted state
        setIsLoading(false) // Make sure to set loading to false
        // Add any other state resets you may have here
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    height: `${dialogHeight}vh`,
                    width: '60%',
                    maxWidth: 'none',
                },
            }}
        >
            <DialogTitle style={{ paddingTop: '36px', paddingLeft: '36px' }}>
                Upload file
                {isLoading && <CircularProgress size={24} style={{ position: 'absolute', right: 45, top: 15 }} />}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ paddingLeft: '36px', paddingRight: '36px', paddingBottom: '20px' }}>
                {!uploadAttempted && (
                    <div
                        {...getRootProps()}
                        style={{
                            border: '2px dashed #000',
                            padding: '20px',
                            textAlign: 'center',
                            height: '40vh',
                            borderRadius: '5px',
                            borderColor: '#1c49d4',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ...(isDragActive ? activeStyle : {}),
                            ...(fileRejected ? rejectStyle : {}),
                        }}
                    >
                        <input {...getInputProps()} />
                        <div
                            style={{
                                borderRadius: '50%',
                                backgroundColor: fileRejected ? '#ff1744' : '#1c49d4',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '50px',
                                height: '50px',
                                marginBottom: '8px',
                            }}
                        >
                            <UploadFileIcon style={{ color: 'white' }} />
                        </div>
                        <p>
                            <span style={{ textDecoration: 'underline', color: '#1c49d4' }}>Click to upload</span> or drag and drop
                        </p>
                        {fileRejected && (
                            <div style={{ display: 'flex', alignItems: 'center', color: '#ff1744' }}>
                                <ErrorIcon style={{ marginRight: '8px' }} />
                                <p>Unsupported format. Please only upload XLS, CSV, or TSV file.</p>
                            </div>
                        )}
                        {!fileRejected && <p style={{ fontSize: '0.8rem', color: 'grey' }}>XLS, CSV or TSV (max. #MB)</p>}
                    </div>
                )}
                {files.map((fileWithStatus, index) => (
                    <div
                        key={index}
                        style={{
                            padding: '10px',
                            borderBottom: '1px solid #e0e0e0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            ...(fileWithStatus.status === 'error' ? errorStyle : {}),
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <InsertDriveFileIcon style={{ marginRight: '15px', color: fileWithStatus.status === 'error' ? '#ff1744' : '#1c49d4' }} />
                            <div>
                                <p style={{ margin: 0, fontWeight: 'bold', fontSize: '0.85rem', color: fileWithStatus.status === 'error' ? '#ff1744' : 'inherit' }}>
                                    {fileWithStatus.file.name}
                                </p>
                                <p style={{ margin: '4px 0 0 0', color: fileWithStatus.status === 'error' ? '#ff1744' : 'rgba(0, 0, 0, 0.54)', fontSize: '0.7rem' }}>
                                    {fileWithStatus.file.size / 1024 / 1024 < 1
                                        ? `${(fileWithStatus.file.size / 1024).toFixed(1)} KB`
                                        : `${(fileWithStatus.file.size / 1024 / 1024).toFixed(1)} MB`}
                                    {fileWithStatus.status === 'complete' && ' - Success'}
                                    {fileWithStatus.status === 'error' && ' - Error'}
                                </p>
                            </div>
                        </div>
                        {fileWithStatus.status === 'complete' ? (
                            <CheckCircleIcon style={{ color: 'green' }} />
                        ) : fileWithStatus.status === 'error' ? (
                            <Tooltip title={fileWithStatus.errors.join(', ')}>
                                <CancelIcon style={{ color: '#ff1744' }} />
                            </Tooltip>
                        ) : (
                            <DeleteIcon style={{ color: '#757575', cursor: 'pointer' }} onClick={() => handleRemoveFile(index)} />
                        )}
                    </div>
                ))}
            </DialogContent>
            <DialogActions style={{ paddingLeft: '36px', paddingRight: '36px', paddingBottom: '28px', display: 'flex', justifyContent: 'space-between' }}>
                <Button sx={{ textTransform: 'none' }} variant="text" onClick={downloadTemplate}>
                    Download geochemical data template
                </Button>
                <div>
                    <Button onClick={handleClose} sx={{ textTransform: 'none', marginRight: '10px' }}>
                        Cancel
                    </Button>
                    {!uploadAttempted ? (
                        <Button sx={{ textTransform: 'none' }} variant="contained" onClick={() => uploadFiles(files)}>
                            Upload
                        </Button>
                    ) : (
                        <Button sx={{ textTransform: 'none' }} variant="contained" onClick={resetDialog}>
                            Reset
                        </Button>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    )
}
