interface PublicSamplePropertyItem {
    id: string
    name: string
}

interface PublicSamplePropertyGroup {
    name: string
    properties: PublicSamplePropertyItem[]
}

export const PUBLIC_SAMPLE_PROPERTIES: PublicSamplePropertyGroup[] = [
    {
        name: 'Basic Information',
        properties: [
            { id: 'projectDescription', name: 'Project Description' },
            { id: 'company', name: 'Company' },
            { id: 'status', name: 'Status' },
            { id: 'createdByEmail', name: 'Created By' },
            { id: 'collectionDate', name: 'Collection date' },
            { id: 'site', name: 'Site Id/Name' },
            { id: 'source', name: 'Source' },
        ],
    },
    {
        name: 'Location Information',
        properties: [
            { id: 'location', name: 'Latitude and longitude' },
            { id: 'sampleLocationId', name: 'Sample Location ID' },
            { id: 'elevation', name: 'Elevation' },
            { id: 'country', name: 'Country' },
        ],
    },
    {
        name: 'Measurements',
        properties: [
            { id: 'weather', name: 'Weather' },
            { id: 'ph', name: 'pH' },
            { id: 'conductivity', name: 'Conductivity' },
            { id: 'sampleType', name: 'Sample type' },
            { id: 'depth', name: 'Depth' },
            { id: 'do', name: 'DO' },
            { id: 'waterPreservation', name: 'Water Preservation' },
            { id: 'samplingMethod', name: 'Sampling method' },
            { id: 'temperature', name: 'Temperature' },
            { id: 'orp', name: 'ORP' },
            { id: 'geochemicalData', name: 'Geochecmical data' },
            { id: 'waterQualitySampleId', name: 'Water Quality Sample ID' },
            { id: 'samplingNotes', name: 'Sampling Notes' },
            { id: 'shippingCondition', name: 'Shipping Condition' },
        ],
    },
    {
        name: 'Photos',
        properties: [{ id: 'photos', name: 'Site Photos' }],
    },
]
