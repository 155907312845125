import { AccessTime, Inventory, People, Person, Public, StarOutline } from '@mui/icons-material'

export enum GridProjectOptions {
    recents,
    myprojects,
    shared,
    public,
    favorite,
    archived,
}

export enum ViewMode {
    grid,
    new,
    update,
}

export enum UserRole {
    owner,
    admin,
    user,
}

export enum ProjectStatus {
    open = 'open',
    close = 'closed',
    archive = 'archived',
}

export enum ProjectStatusHelperMessage {
    open = 'Project is accepting new samples or still sampling in the field',
    close = 'Project is not accepting new samples at the moment',
    archive = 'Project is achived',
}

export type Contributor = {
    id: string
    name: string
    email: string
    organizationName?: string
    role: UserRole
}

export type Project = {
    id: string
    name: string
    organizationId?: string
    organizationName?: string
    description: string
    contributors: Contributor[]
    status: ProjectStatus
    isPublic: boolean
    isUserMember?: boolean
    allowProjectUpdate?: boolean
    publicSampleProps?: string[]
}

export const projectNavItems = [
    {
        gridOption: GridProjectOptions.recents,
        label: 'Recents',
        icon: <AccessTime />,
        enabled: false,
    },
    {
        gridOption: GridProjectOptions.myprojects,
        label: 'My projects',
        icon: <Person />,
        enabled: true,
    },
    {
        gridOption: GridProjectOptions.shared,
        label: 'Shared with me',
        icon: <People />,
        enabled: true,
    },
    {
        gridOption: GridProjectOptions.public,
        label: 'Public projects',
        icon: <Public />,
        enabled: true,
    },
    {
        gridOption: GridProjectOptions.favorite,
        label: 'Favorite',
        icon: <StarOutline />,
        enabled: false,
    },
    {
        gridOption: GridProjectOptions.archived,
        label: 'Archived',
        icon: <Inventory />,
        enabled: false,
    },
]
