import { useState, useEffect, useContext } from 'react'
import { Avatar, Container, Grid, List, Typography } from '@mui/material'
import DataManagerGrid from './DataManagerGrid'
import { GridFileTypeOption, fileTypeNavItems, SolidGeochemicalFile } from './ManageData.model'
import FileTypeNavItem from './FileTypeNavItem'
import { UserProfileContext } from '../../context/CustomContext'
import { getSolidGeochemicalFilesForUser } from '../../libs/api/geochemical-files/GeochemicalFilesAPI'
import { useNotificationContext } from '../../context/NotificationContext'
import { deleteGeochemicalDataAndFile } from '../../libs/api/geochemical-files/GeochemicalFilesAPI'

/**
 * The Projects Management component
 */
export default function DataManagerPage() {
    const [selectedFileTypeOption, setSelectedFileTypeOption] = useState<GridFileTypeOption>(GridFileTypeOption.solidGeochemicalData)
    const [fileList, setFileList] = useState<SolidGeochemicalFile[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const userProfile = useContext(UserProfileContext)
    const openNotification = useNotificationContext().openNotification

    const refreshFiles = async () => {
        setLoading(true)
        const { succeeded, data, errors } = await getSolidGeochemicalFilesForUser()
        if (succeeded) {
            setFileList(data)
        } else {
            openNotification(errors.message, errors.severity)
        }
        setLoading(false)
    }

    const handleGridProjectSelection = (selectedGridProject: GridFileTypeOption) => {
        setSelectedFileTypeOption(selectedGridProject)
    }

    const handleDeleteFile = async (fileId) => {
        setLoading(true)
        const response = await deleteGeochemicalDataAndFile(fileId)
        if (response.succeeded) {
            openNotification('File deleted successfully', 'success')
            const updatedFiles = fileList.filter((file) => file.id !== fileId)
            setFileList(updatedFiles)
        } else {
            openNotification(response.errors.message, response.errors.severity)
        }
        setLoading(false)
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const { succeeded, data, errors } = await getSolidGeochemicalFilesForUser()

            if (succeeded) {
                setFileList(data)
            } else {
                openNotification(errors.message, errors.severity)
            }

            setLoading(false)
        }

        fetchData()
    }, [])

    return (
        <Container maxWidth={false}>
            <Grid container spacing={2} wrap="nowrap">
                <Grid
                    item
                    xs={2}
                    sx={{
                        minWidth: '280px',
                        paddingRight: '20px',
                        borderRight: '1px solid rgba(19, 19, 32, .12)',
                    }}
                >
                    <Grid
                        sx={{
                            p: '20px 0',
                            borderBottom: '1px solid rgba(19, 19, 32, .12)',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <Avatar alt="Anonimous Avatar" src="/images/avatar-anonimous.svg" />
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    marginLeft: '10px',
                                    overflow: 'hidden',
                                }}
                            >
                                <Grid sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{userProfile.email}</Grid>
                                {userProfile.organizationName && <small>{userProfile.organizationName}</small>}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ paddingTop: '20px' }}>
                        <List>
                            {fileTypeNavItems.map((item) => (
                                <FileTypeNavItem key={item.gridOption} item={item} isSelected={item.gridOption === selectedFileTypeOption} onSelect={handleGridProjectSelection} />
                            ))}
                        </List>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <DataManagerGrid
                        gridTitle={fileTypeNavItems.find((x) => x.gridOption === selectedFileTypeOption).label}
                        files={fileList}
                        loading={loading}
                        onDeleteFile={handleDeleteFile}
                        onRefreshFiles={refreshFiles}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
