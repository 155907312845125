import { CardContent, Grid, Typography } from '@mui/material'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import { useContext, useEffect, useState } from 'react'
import { SampleDataContext } from '../../../../context/CustomContext'
import { getKrona } from '../../../../libs/api/krona/kronaAPI'
import { useNotificationContext } from '../../../../context/NotificationContext'

export function Krona() {
    const { selectedSsuRunId, kronas, setKronas } = useContext(SampleDataContext)
    const openNotification = useNotificationContext().openNotification

    const [selectedKrona, setSelectedKrona] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const loadKrona = async () => {
            setLoading(true)
            const currentKronas = kronas || []
            const { succeeded, data, errors } = await getKrona(selectedSsuRunId)
            if (succeeded) {
                setKronas([...currentKronas, { ssuRunId: selectedSsuRunId, url: data }])
            } else {
                setKronas([...currentKronas, { ssuRunId: selectedSsuRunId, url: '' }])
                openNotification(errors.message, errors.severity)
            }
            setLoading(false)
        }

        const currSelectedKrona = kronas && kronas.find((x) => x.ssuRunId === selectedSsuRunId)
        if (currSelectedKrona) {
            setSelectedKrona(currSelectedKrona.url)
        } else if (selectedSsuRunId) {
            loadKrona()
        }
    }, [selectedSsuRunId, kronas])

    return selectedKrona && selectedKrona.length > 0 ? (
        <CardContent sx={{ padding: 0 }}>
            <iframe title="Krona" srcDoc={selectedKrona} width="800" height="800" style={{ border: '0' }}></iframe>
        </CardContent>
    ) : (
        <Grid
            item
            xs={12}
            sx={{
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {selectedKrona === '' ? (
                <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20px' }}>
                    <Typography variant="h5" sx={{ marginBottom: '30px' }}>
                        Krona plot not available
                    </Typography>
                </Grid>
            ) : null}
            <ProgressIndicator open={loading}></ProgressIndicator>
        </Grid>
    )
}
