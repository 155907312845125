import { ProjectInvitedUserDTO, UserDTO, UserRequestDTO } from './UserDTO'
import { ProjectInvitedUser, User, UserRequest } from './UserModel'

export const mapUserDtoToUser = (userDTO: UserDTO): User => {
    return userDTO as User
}

export const mapProjectInvitesDtoToInvitedUsers = (projectInvitesDTO: ProjectInvitedUserDTO[]): ProjectInvitedUser[] => {
    const invitedUsersModel: ProjectInvitedUser[] = projectInvitesDTO.map((invitedUser) => {
        return {
            email: invitedUser.id,
            isAdmin: invitedUser.admin,
        }
    })

    return invitedUsersModel
}

export const mapUserRequestsDtoToUserRequests = (userRequestsDTO: UserRequestDTO[]): UserRequest[] => {
    const model: UserRequest[] = userRequestsDTO.map((userRequest) => {
        return {
            id: userRequest.id,
            name: `${userRequest.firstName}${userRequest.lastName ? ' ' + userRequest.lastName : ''}`,
            email: userRequest.email,
            createdDate: userRequest.createdDate,
            enteredOrganization: userRequest.enteredCompany,
        } as UserRequest
    })

    return model
}
