import { Grid } from '@mui/material'
import { DataGridPremium, GridColDef, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium'
import { useEffect, useState } from 'react'
import { StaticReportRow } from '../../../../libs/api/static-reports/StaticReportModel'
import ReportActions from './ReportActions'

export interface ReportsGridProps {
    reportList: StaticReportRow[]
    loading: boolean
    reportDeleted: () => void
}

/**
 * The Reports Management Grid component
 */
export default function ReportsGrid(props: ReportsGridProps) {
    const { loading, reportList } = props

    const apiRef = useGridApiRef();

    const builtColumns = buildColumns(props.reportDeleted)

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {

            columns: {
                columnVisibilityModel: {
                    // Hide the column used for leaves
                    name: false,
                },
            },
            sorting: {
                sortModel: [
                    {
                        field: "createdDate",
                        sort: 'desc'
                    }
                ]
            },
            rowGrouping: {
                model: ['groupName'],
            }
        }
    })

    return (
        <Grid>
            <DataGridPremium
                getRowId={(r) => `${r.id}_${r.reportType}`}
                autoHeight
                groupingColDef={{
                    headerName: 'Name',
                    flex: 3,
                    leafField: 'name'
                }}
                columns={builtColumns}
                rows={reportList}
                loading={loading}
                initialState={initialState}
                disableColumnMenu
                apiRef={apiRef}
                slots={{
                    noRowsOverlay: () => <NoReportsOverlay />,
                }}
                sx={{
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '.MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    '& .project-name': {
                        fontWeight: 'bold',
                    },
                    borderTop: 'none',
                    borderRight: 'none',
                    borderLeft: 'none',
                }}
            />
        </Grid>
    )
}

/**
 * Component to render as fallback when no data is available
 */
const NoReportsOverlay = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 300)
        return () => clearTimeout(timeout)
    }, [])

    if (!show) return null
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <p>No reports found</p>
        </div>
    )
}

/**
 * The datagrid columns setup
 */
const buildColumns = (reportDeleted: () => void): GridColDef[] => {
    return [
        {
            field: 'groupName',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 3,
        },
        {
            field: 'sampleCount',
            headerName: 'Samples',
            flex: 3,
        },
        {
            field: 'reportType',
            headerName: 'Report Type',
            flex: 3,
        },
        {
            field: 'createdDate',
            headerName: 'Created On',
            flex: 3,
            type: 'date'
        },
        {
            field: 'actions',
            headerName: '',
            flex: 3,
            sortable: false,
            renderCell: (params) => <ReportActions
                reportDeleted={reportDeleted}
                row={params.row}
                firstChild={params.rowNode.type == "group" && params.rowNode.children[0].toString()} />
        },
    ]
}

