import BaseAPI, { ApiResponse } from '../BaseAPI'

import { SolidGeochemicalFile } from '../../../pages/manage-data/ManageData.model'
import { GeochemicalFileDTO } from './GeochemicalFilesDTO'
import { mapGeochemicalFileDtoToSolidGeochemicalFile } from './GeochemicalFilesMapper'
import { GeochemicalUploadResponse } from './GeochemicalFilesModel'

export async function uploadGeochemicalDataFile(formData: FormData): Promise<ApiResponse<GeochemicalUploadResponse>> {
    const response = await BaseAPI.postForm<GeochemicalUploadResponse>(`/geochemicalFiles`, formData)

    if (response.succeeded) {
        return { ...response }
    } else {
        return { ...response, data: null }
    }
}

export async function getSolidGeochemicalFilesForUser(): Promise<ApiResponse<SolidGeochemicalFile[]>> {
    const response = await BaseAPI.getMany<GeochemicalFileDTO>(`/geochemicalFiles`)

    if (response.succeeded) {
        const geochemFileMetadata = response.data?.map((fileObj) => mapGeochemicalFileDtoToSolidGeochemicalFile(fileObj))

        return {
            ...response,
            data: geochemFileMetadata ?? [],
        }
    } else {
        return {
            ...response,
            data: null,
        }
    }
}

export async function downloadOriginalGeochemicalDataFile(fileId: string): Promise<ApiResponse<Blob | MediaSource>> {
    const config = { responseType: 'blob' }
    const response = await BaseAPI.getBlob(`/geochemicalFile`, fileId, config)
    if (response.succeeded && response.data instanceof Blob) {
        return {
            ...response,
        }
    } else {
        return {
            ...response,
            data: null,
        }
    }
}

export async function deleteGeochemicalDataAndFile(fileId: string) {
    const response = await BaseAPI.delete(`/geochemicalFile/${fileId}`)
    if (response.succeeded) {
        return {
            ...response,
        }
    } else {
        return {
            ...response,
            data: null,
        }
    }
}
