import { AxiosResponse } from 'axios'
import { ApiResponse } from './BaseAPI'

/**
 * Represent the lack of network connection
 */
export const NETWORK_ERROR: ApiResponse<any> = {
    succeeded: false,
    errors: {
        code: 'NETWORK_FAILURE',
        message: 'Network connection failure. Please verify your internet connection and try again.',
        severity: 'error',
    },
}

/**
 * Represent any server failures (5XX) and details shouldn't be surfaced to user
 */
export const SERVER_ERROR: ApiResponse<any> = {
    succeeded: false,
    errors: {
        code: 'SERVER_ERROR',
        message: 'There was a problem processing your request',
        severity: 'error',
    },
}

export const getRequestError = (response: AxiosResponse): ApiResponse<any> => {
    const errorMessage = response?.data?.detail
    return {
        succeeded: false,
        errors: {
            code: 'REQUEST_ERROR',
            message: errorMessage || 'There was a problem processing your request',
            severity: 'error',
        },
    }
}
