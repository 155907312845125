import { useContext, useEffect, useState } from 'react'
import { Button, Grid, Tooltip, Typography } from '@mui/material'
import ContributorsForm from './projects-form/ContributorsForm'
import DetailsForm from './projects-form/DetailsForm'
import { Project } from '../Projects.model'
import { useNotificationContext } from '../../../context/NotificationContext'
import ProgressIndicator from '../../../components/ProgressIndicator'
import { getNewProjectData, mapProjectUpdatePayload, updateUsersAccess } from './Project.service'
import { createProject, deleteProject, updateProject } from '../../../libs/api/projects/ProjectAPI'
import { UserProfileContext } from '../../../context/CustomContext'
import { ContributorGridItem } from './projects-form/ContributorsGrid'

interface ProjectEditionProps {
    closeForm: () => void
    refreshLocalStorage: () => void
    onProjectCreated: () => void
    onProjectDeleted: () => void
    project?: Project
}

/**
 * The Project management form component for new project creation, update and deletion
 */
export default function ProjectForm(props: ProjectEditionProps) {
    const { closeForm, refreshLocalStorage, onProjectCreated, onProjectDeleted, project } = props
    const userProfile = useContext(UserProfileContext)
    const [isDetailsFormModified, setIsDetailsFormModified] = useState<boolean>(false)
    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [modifiedContributors, setModifiedContributors] = useState<ContributorGridItem[]>([])
    const [shouldRefreshUsers, setShouldRefreshUsers] = useState<boolean>(false)

    const openNotification = useNotificationContext().openNotification

    const handleProjectFormSubmit = (project: Project) => {
        if (project.id) {
            handleUpdateProject(project)
        } else {
            handleCreateNewProject(project)
        }
    }

    const handleCreateNewProject = async (project: Project) => {
        setLoading(true)
        const projectUpdatePayload = mapProjectUpdatePayload(project)
        const { succeeded, errors } = await createProject(projectUpdatePayload)
        if (succeeded) {
            openNotification('Project successfully created', 'success')
            onProjectCreated()
        } else {
            openNotification(errors.message, errors.severity)
        }
        setLoading(false)
    }

    const handleProjectDeleted = async (projectId: string) => {
        setLoading(true)
        const { succeeded, errors } = await deleteProject(projectId)
        if (succeeded) {
            openNotification('Project successfully deleted', 'success')
            onProjectDeleted()
        } else {
            openNotification(errors.message, errors.severity)
        }
        setLoading(false)
    }

    const handleUpdateProject = async (project: Project) => {
        setLoading(true)
        if (isDetailsFormModified) {
            const projectUpdatePayload = mapProjectUpdatePayload(project)
            const { succeeded, errors } = await updateProject(projectUpdatePayload)
            if (!succeeded) {
                openNotification(errors.message, errors.severity)
                setLoading(false)
                return
            }
        }

        if (modifiedContributors.length > 0) {
            const responseMessage = await updateUsersAccess(project.id, modifiedContributors)
            setShouldRefreshUsers(true)
            openNotification(responseMessage)
        } else {
            openNotification('Project details successfully updated', 'success')
        }
        refreshLocalStorage()
        setIsDetailsFormModified(false)
        setModifiedContributors([])
        setLoading(false)
    }

    useEffect(() => {
        setShouldRefreshUsers(false)
    }, [shouldRefreshUsers])

    useEffect(() => {
        setSubmitEnabled(isDetailsFormModified || modifiedContributors.length > 0)
    }, [isDetailsFormModified, modifiedContributors])

    return (
        <Grid>
            <ProjectFormHeader
                headerLabel={project === undefined ? 'Create New Project' : project.name}
                submitButtonLabel={project === undefined ? 'Create Project' : 'Update Project'}
                submitButtonEnabled={submitEnabled}
                closeForm={closeForm}
            />
            <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                <Grid item xs={6} sx={{ padding: '0 20px 0 0' }}>
                    <DetailsForm
                        project={project || getNewProjectData(userProfile)}
                        enableFormUpdate={(project && project.allowProjectUpdate) || project === undefined}
                        onFormModified={setIsDetailsFormModified}
                        onFormSubmit={handleProjectFormSubmit}
                        onProjectDeleted={handleProjectDeleted}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ContributorsForm
                        projectId={project && project.id}
                        currentUserId={userProfile.id}
                        allowProjectUpdate={project && project.allowProjectUpdate}
                        refresh={shouldRefreshUsers}
                        onModifiedContributors={setModifiedContributors}
                    />
                </Grid>
            </Grid>
            <ProgressIndicator open={loading}></ProgressIndicator>
        </Grid>
    )
}

interface ProjectFormHeaderProps {
    headerLabel: string
    submitButtonLabel: string
    submitButtonEnabled: boolean
    closeForm: () => void
}

/**
 * The Header component for the Project Form
 */
const ProjectFormHeader = (props: ProjectFormHeaderProps) => {
    const { headerLabel, submitButtonLabel, submitButtonEnabled, closeForm } = props

    // This handler is used to trigger form submission, but actual submission
    // logic is placed in the handleSubmit method in the form component
    const handleProjectSubmission = () => {}

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h4" style={{ padding: '20px 0' }}>
                {headerLabel}
            </Typography>
            <Button
                onClick={() => closeForm()}
                sx={{
                    padding: '10px 30px',
                    borderRadius: '10px',
                    margin: '19px 0 19px auto',
                }}
            >
                <Typography textTransform="capitalize">{submitButtonEnabled ? 'Cancel' : 'Close'}</Typography>
            </Button>
            <Tooltip title={submitButtonEnabled ? '' : "There aren't changes in current form"}>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        form="project-form"
                        type="submit"
                        disabled={!submitButtonEnabled}
                        onClick={() => handleProjectSubmission()}
                        sx={{
                            padding: '10px 30px',
                            borderRadius: '10px',
                            margin: '19px 0 19px 5px',
                        }}
                    >
                        <Typography textTransform="capitalize">{submitButtonLabel}</Typography>
                    </Button>
                </div>
            </Tooltip>
        </Grid>
    )
}
