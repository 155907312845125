import { FunctionalInferenceDTO } from './FunctionalInferenceDTO'
import { FunctionalInference } from './FunctionalInferenceModel'

export const mapFunctionInferenceDtoToFunctionalInference = (functionalInferenceDTO: FunctionalInferenceDTO): FunctionalInference => {
    const functionalInferenceModel: FunctionalInference = {
        id: functionalInferenceDTO.id,
        asvUsedInferencePerc: functionalInferenceDTO.asvUsedInferencePerc,
        communityUsedInferencePerc: functionalInferenceDTO.communityUsedInferencePerc,
        nstiSampleWeightedAvg: functionalInferenceDTO.nstiSampleWeightedAvg,
        pathwayCount: functionalInferenceDTO.pathwayCount,
        filteredAsvLowAbundanceCount: functionalInferenceDTO.filteredAsvLowAbundanceCount,
        filteredAsvLargeNstiCount: functionalInferenceDTO.filteredAsvLargeNstiCount,
        picrustVersion: functionalInferenceDTO.picrustVersion,
        executionId: functionalInferenceDTO.executionId,
        pathways: functionalInferenceDTO.pathways,
    }

    return functionalInferenceModel
}
