import { AlertColor } from '@mui/material'
import { createContext, useContext, useState } from 'react'

type NotifycationType = {
    message: string
    severity: AlertColor // success | info (default) | warning | error
    openNotification: (message: string, severity?: string) => void
}

const NotificationContext = createContext<NotifycationType | null>(null)

export function NotificationProvider({ children }: any) {
    const [message, setMessage] = useState<string>('')
    const [severity, setSeverity] = useState<AlertColor>('info')

    const handleOpenNotification = (message: string, severity?: AlertColor) => {
        setMessage(message)
        if (severity) {
            setSeverity(severity)
        }
    }

    return (
        <NotificationContext.Provider
            value={{
                message: message,
                severity: severity,
                openNotification: handleOpenNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    )
}

export function useNotificationContext() {
    return useContext(NotificationContext)
}
