import { Alert, Snackbar } from '@mui/material'
import { useEffect } from 'react'
import { useNotificationContext } from '../context/NotificationContext'
import { DoneOutline } from '@mui/icons-material'

// Timeout for the notification bar to be automatically dismissed
const NOTIFICATION_DISPLAY_TIME = 6000

/**
 * Notification Bar is a reusable component used to visually comunicate the user when some action is completed.
 * It can be used for Success, Warnings or Error notifications
 */
export default function NotificationBar() {
    const { message, severity, openNotification } = useNotificationContext()

    useEffect(() => {
        if (message && message.length > 0) {
            const timer = setTimeout(() => {
                openNotification('')
            }, NOTIFICATION_DISPLAY_TIME)

            return () => clearTimeout(timer)
        }
    }, [message, openNotification])

    const handleNotificationBarClose = () => {
        openNotification('')
    }

    return (
        <Snackbar open={message.length > 0} onClose={handleNotificationBarClose}>
            <Alert
                onClose={handleNotificationBarClose}
                severity={severity}
                sx={{
                    width: '100%',
                    background: 'black',
                    color: 'white',
                    borderRadius: '12px',
                    whiteSpace: 'pre-line',
                }}
                iconMapping={{
                    success: <DoneOutline fontSize="inherit" />,
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}
