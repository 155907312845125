import { SidebarDrawer } from '../../../../components/SidebarDrawer'
import { SampleDetailsSidebarHeader } from '../../SidebarHeader'
import { SidebarSearchFilters } from '../../SidebarSearchFilters'
import { SIDEBAR_DRAWER_BACKGROUND_COLOR } from '../../SampleDetails.constants'

export interface GeochemicalDataSidebarProps {
    open: boolean
    onClose: () => void
}

export function GeochemicalDataSidebar(props: GeochemicalDataSidebarProps) {
    const { open, onClose } = props
    return (
        <SidebarDrawer open={open} customHeader={SampleDetailsSidebarHeader({ onClose })} onClose={onClose} paperSx={{ backgroundColor: SIDEBAR_DRAWER_BACKGROUND_COLOR }}>
            <SidebarSearchFilters />
        </SidebarDrawer>
    )
}
