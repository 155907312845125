import { Link } from 'react-router-dom'
import { GridActionsCellItem, GridToolbar } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { RemoveCircleOutline } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { DataGridPremium, GridInitialState, useGridApiRef } from '@mui/x-data-grid-premium'
import LockIcon from '@mui/icons-material/Lock'
import { useCallback, useLayoutEffect, useState } from 'react'

export interface GridSamplesProps {
    samples: any[]
    loading: boolean
    enableCheckbox: boolean
    deleteRow?: (id: string) => void
    getSelectedRows?: (ids: string[]) => void
}

/**
 * Reusable Grid component for samples
 */
export default function GridSamples(props: GridSamplesProps) {
    const { samples, loading, enableCheckbox, deleteRow, getSelectedRows } = props
    const apiRef = useGridApiRef()

    const getActions = getActionsArray(deleteRow)
    const builtColumns = buildColumns(getActions)

    const [initialState, setInitialState] = useState<GridInitialState>()

    const saveSnapshot = useCallback(() => {
        // Save grid state to session storage
        if (apiRef?.current?.exportState && sessionStorage) {
            const currentState = apiRef.current.exportState()
            sessionStorage.setItem('samples-grid-state', JSON.stringify(currentState))
        }
    }, [apiRef])

    useLayoutEffect(() => {
        // Restore grid state from session storage
        // Note that this does will not restore column order - see https://github.com/mui/mui-x/issues/7607
        const stateFromSessionStorage = sessionStorage?.getItem('samples-grid-state')
        setInitialState(stateFromSessionStorage ? JSON.parse(stateFromSessionStorage) : defaultInitialState(deleteRow !== undefined))

        // Handle refresh and navigating away/refreshing
        window.addEventListener('beforeunload', saveSnapshot)

        return () => {
            // in case of an SPA remove the event-listener
            window.removeEventListener('beforeunload', saveSnapshot)
            saveSnapshot()
        }
    }, [saveSnapshot])

    return initialState && (
        <DataGridPremium
            getRowId={(r) => r.id}
            pagination
            autoHeight
            pageSizeOptions={[25, 50, 100, 250]}
            sortingOrder={['asc', 'desc']}
            rows={samples || []}
            columns={builtColumns}
            checkboxSelection={enableCheckbox}
            slots={{ toolbar: GridToolbar }}
            loading={loading}
            initialState={initialState}
            onRowSelectionModelChange={(selectedRows) => getSelectedRows && getSelectedRows(selectedRows as string[])}
            disableRowSelectionOnClick
            slotProps={{ toolbar: { csvOptions: { allColumns: true }, excelOptions: { allColumns: true } } }}
            sx={{
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                    outline: 'none',
                },
                '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                },
                '& .project-name': {
                    fontWeight: 'bold',
                },
                borderTop: 'none',
                borderRight: 'none',
                borderLeft: 'none',
            }}
            apiRef={apiRef}
        />
    )
}

/**
 * Grid columns setup
 * @param getActionsArray Callback to obtain the array of actions for a samples elements
 * @returns The rendering component array for the Grid
 */
const buildColumns = (getActionsArray: (params: any) => React.JSX.Element[]) => {
    return [
        { field: 'projectName', headerName: 'Project', flex: 2 },
        { field: 'id', headerName: 'Sample ID', flex: 2, renderCell: (params) => renderSampleId(params.row.id, params.row.projectId) },
        { field: 'userDefinedSampleId', headerName: 'User Defined ID', flex: 2 },
        { field: 'sampleType', headerName: 'Sample Type', flex: 2, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'country', headerName: 'Country', flex: 3, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'site', headerName: 'Sample Site', flex: 3, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'source', headerName: 'Source', flex: 3, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'status', headerName: 'Status', flex: 2, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        {
            field: 'collectionDate',
            headerName: 'Collection Date',
            flex: 3,
            valueFormatter: (params: any) => dayjs(params.value).format(),
            renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps),
        },
        { field: 'createdByEmail', headerName: 'Created By', flex: 2, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'actions', type: 'actions', flex: 1, getActions: getActionsArray },
        { field: 'weather', headerName: 'Weather', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        {
            field: 'shippingCondition',
            headerName: 'Shipping Condition',
            flex: 1,
            renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps),
        },
        { field: 'samplingMethod', headerName: 'Sampling Method', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'depth', headerName: 'Depth', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'temperature', headerName: 'Temperature', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'elevation', headerName: 'Elevation', flex: 1 },
        { field: 'ph', headerName: 'pH', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'do', headerName: 'DO', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'orp', headerName: 'ORP', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        { field: 'conductivity', headerName: 'Conductivity', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
        {
            field: 'waterPreservation',
            headerName: 'Water Preservation Method',
            flex: 1,
            renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps),
        },
        {
            field: 'waterQualitySampleId',
            headerName: 'Water Quality Sample ID',
            flex: 1,
            renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps),
        },
        {
            field: 'sampleLocationId',
            headerName: 'Sample Location ID',
            flex: 1,
            renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps),
        },
        { field: 'samplingNotes', headerName: 'Notes', flex: 1, renderCell: (params) => renderHTML(params.field, params.formattedValue, params.row.publicSampleProps) },
    ]
}

const renderSampleId = (id: string, projectId: string) => {
    return <Link to={`/sample/${projectId}/${id}`}>{id}</Link>
}

const renderHTML = (field: string, value: string, publicSampleProps: string[]) => {
    const isAnonymized = publicSampleProps && publicSampleProps.findIndex((x) => x === field) < 0
    return isAnonymized ? (
        <Tooltip title="Anonymized">
            <LockIcon sx={{ color: 'rgba(19, 19, 32, 0.38)', transform: 'scale(0.8)', marginBottom: '4px' }} />
        </Tooltip>
    ) : (
        <div>{value}</div>
    )
}

/**
 * Builds an array of action components for a samples grid item
 * @param navigate Callback to navigate to a samples details page
 * @param onDelete Callback to delete a samples
 * @returns The array of action components for a sample item
 */
function getActionsArray(onDelete?: (id: string) => void) {
    const getActionsArray = (params: any) => {
        if (typeof onDelete === 'function') {
            return [
                <GridActionsCellItem
                    key="remove"
                    icon={
                        <Tooltip title="Remove sample from view">
                            <RemoveCircleOutline />
                        </Tooltip>
                    }
                    onClick={() => {
                        onDelete(params.id)
                    }}
                    label="Remove"
                />,
            ]
        } else {
            return []
        }
    }

    return getActionsArray
}

/**
 * Samples grid initial state
 * @param showActions Flag to indicate if actions column should be displayed
 */
const defaultInitialState = (showActions: boolean) => {
    return {
        columns: {
            columnVisibilityModel: {
                actions: showActions,
                country: false,
                site: false,
                source: false,
                weather: false,
                shippingCondition: false,
                samplingMethod: false,
                depth: false,
                temperature: false,
                elevation: false,
                ph: false,
                do: false,
                orp: false,
                conductivity: false,
                waterPreservation: false,
                waterQualitySampleId: false,
                sampleLocationId: false,
                samplingNotes: false,
            },
        },
        pagination: {
            paginationModel: { pageSize: 50, page: 0 },
        },
    }
}
