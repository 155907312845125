import { UploadFile } from '@mui/icons-material'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { UserRole } from '../../Projects.model'
import EmailInviteButton from './EmailInviteButton'
import { useNotificationContext } from '../../../../context/NotificationContext'
import { getUserRoleInProject, mapInvitedUsersToContributors, mapUsersToContributors } from './ContributorsForm.service'
import { getProjectUsers } from '../../../../libs/api/projects/ProjectAPI'
import { getInvitedUsers } from '../../../../libs/api/users/UserAPI'
import { ContributorGridItem, ContributorsGrid } from './ContributorsGrid'

export interface ContributorsFormProps {
    projectId: string
    currentUserId: string
    allowProjectUpdate: boolean
    refresh: boolean
    onModifiedContributors: (modifiedContributors: ContributorGridItem[]) => void
}

/**
 * The Form component for project members management, including access level permission
 * management and invitation system
 */
export default function ContributorsForm(props: ContributorsFormProps) {
    const { projectId, currentUserId, allowProjectUpdate, refresh, onModifiedContributors } = props
    const [contributors, setContributors] = useState<ContributorGridItem[]>([])
    const [invitedUser, setInvitedUser] = useState<ContributorGridItem[]>([])
    const [userRoleInProject, setUserRoleInProject] = useState<UserRole>(UserRole.user)
    const [loadingContributors, setloadingContributors] = useState<boolean>(false)
    const [loadingInvited, setloadingInvited] = useState<boolean>(false)
    const [inviteButtonEnabled, setInviteButtonEnabled] = useState<boolean>(false)

    const openNotification = useNotificationContext().openNotification

    const loadContributors = useCallback(async () => {
        if (projectId) {
            setloadingContributors(true)
            const { succeeded, data, errors } = await getProjectUsers(projectId)
            if (succeeded) {
                const contributors = mapUsersToContributors(data)
                setContributors(contributors)

                const userRoleInProject = getUserRoleInProject(currentUserId, contributors)
                setUserRoleInProject(userRoleInProject)
            } else {
                openNotification(errors.message, errors.severity)
            }
            setloadingContributors(false)
        }
    }, [projectId, currentUserId])

    const loadInvitedUsers = useCallback(async () => {
        setloadingInvited(true)
        const { succeeded, data, errors } = await getInvitedUsers(projectId)
        if (succeeded) {
            const users = mapInvitedUsersToContributors(data)
            setInvitedUser(users)
        } else {
            openNotification(errors.message, errors.severity)
        }
        setloadingInvited(false)
    }, [projectId])

    useEffect(() => {
        if (projectId) {
            loadInvitedUsers()
            loadContributors()
        }
    }, [projectId, currentUserId])

    useEffect(() => {
        if (refresh && projectId) {
            loadInvitedUsers()
            loadContributors()
        }
    }, [refresh])

    useEffect(() => {
        setInviteButtonEnabled(allowProjectUpdate && !(loadingContributors || loadingInvited))
    }, [allowProjectUpdate, loadingContributors, loadingInvited])

    return (
        <>
            <Typography>Project Contributors</Typography>
            <Grid sx={{ display: 'flex', flexDirection: 'row', paddingTop: '0' }}>
                <EmailInviteButton projectId={projectId} disabled={!inviteButtonEnabled} onLoadInvitedUsers={loadInvitedUsers} />
                <Button
                    disabled
                    sx={{
                        padding: '10px 10px',
                        borderRadius: '10px',
                        margin: '19px 0 19px 5px',
                    }}
                >
                    <UploadFile sx={{ padding: 'auto', marginRight: '10px' }} />
                    <Typography textTransform="capitalize">Import CSV</Typography>
                </Button>
            </Grid>
            <Divider />
            <ContributorsGrid
                projectId={projectId}
                contributors={contributors}
                invitedUsers={invitedUser}
                loading={loadingContributors || loadingInvited}
                userRole={userRoleInProject}
                onLoadContributors={loadContributors}
                onModifiedContributors={onModifiedContributors}
            />
        </>
    )
}
