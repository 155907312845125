import { NavLink, Outlet } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'
import { AppBar, Box, Button, CssBaseline, Toolbar, Typography } from '@mui/material'
import { useStorageContext } from './context/Storage'
import { useContext, useEffect, useState } from 'react'
import { UserProfileContext } from './context/CustomContext'

interface NAV_ITEM {
    id: string
    name: string
    link: string
}

const NAVIGATION_ITEMS: NAV_ITEM[] = [
    { id: 'search', name: 'Search Samples', link: '/search' },
    { id: 'projects', name: 'Manage Projects', link: '/projects' },
    { id: 'status', name: 'Status Update', link: '/status' },
    { id: 'management', name: 'Manage Users', link: '/management' },
    { id: 'upload', name: 'Manage Data', link: '/upload' },
    { id: 'reports', name: 'View Reports', link: '/reports' },
    { id: 'generateBarcodes', name: 'Generate Barcodes', link: '/barcodes/generate' },
    { id: 'searchBarcodes', name: 'Search Barcodes', link: '/barcodes/search' },
]

export default function Layout() {
    const clearStorage = useStorageContext().clearLocalStorage
    const userProfile = useContext(UserProfileContext)
    const [navItems, setNavItems] = useState<NAV_ITEM[]>([])
    const { instance } = useMsal()

    useEffect(() => {
        let navItems = NAVIGATION_ITEMS
        if (!userProfile?.isApproved) {
            setNavItems([])
            return;
        }
        if (!userProfile?.statusUpdatePermission) {
            navItems = navItems.filter((item) => item.id !== 'status')
        }
        if (!userProfile?.globalAdminPermission) {
            navItems = navItems.filter((item) => item.id !== 'management' && !item.id.endsWith('Barcodes'))
        }
        setNavItems(navItems)
    }, [userProfile])

    function signOutClickHandler(instance: IPublicClientApplication) {
        clearStorage()
        instance.logoutRedirect()
    }

    const navigationItems = navItems.map((item) => (
        <Button
            key={item.id}
            color="inherit"
            component={NavLink}
            to={item.link}
            sx={{
                padding: '8px',
                borderRadius: '20px',
                marginLeft: '20px',
                '&.active': {
                    background: '#002FC7',
                },
            }}
        >
            <Typography fontWeight="bold" textTransform="capitalize" letterSpacing={1.2}>
                {item.name}
            </Typography>
        </Button>
    ))

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar>
                    <Typography
                        variant="appbar"
                        component="div"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            margin: '0 20px',
                            textWrap: 'nowrap',
                        }}
                    >
                        M-MAP
                    </Typography>
                    {navigationItems}
                    <Button sx={{ marginLeft: 'auto' }} color="inherit" onClick={() => signOutClickHandler(instance)}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ padding: '0', width: '100%' }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    )
}
