import Box from '@mui/system/Box'
import { PeriodicTableElement } from './PeriodicTableElement'
import { ALL_ELEMENTS } from './PeriodicTable.constants'
import { useEffect, useState } from 'react'

const SQUARE_SIZE = 70

export interface ElementData {
    name: string
    valueWithUnit?: string
    method?: string
}

export interface PeriodicTableProps {
    elementData: ElementData[] // This prop is an array of elements with additional data
}

export default function PeriodicTable(props: PeriodicTableProps) {
    const { elementData } = props

    // Change the state to store mapped elements
    const [mappedElements, setMappedElements] = useState([])

    useEffect(() => {
        const elementsWithData = ALL_ELEMENTS.map((element, index) => {
            if (element === null) {
                // Return a placeholder for null elements
                return <div key={index} style={{ width: `${SQUARE_SIZE}px`, height: `${SQUARE_SIZE}px` }} />
            }

            const elementInfo = elementData.find((e) => e.name === element.name) || null
            return <PeriodicTableElement key={index} elementName={element.name} valueWithUnit={elementInfo?.valueWithUnit} method={elementInfo?.method} />
        })

        setMappedElements(elementsWithData)
    }, [elementData])

    return (
        <Box
            sx={{
                display: 'grid',
                //gridTemplateColumns: 'repeat(18, 1fr)',
                gridTemplateColumns: `repeat(18, ${SQUARE_SIZE}px)`, // Set a fixed width for each column
                gridGap: '5px',
                marginLeft: 3,
                marginRight: 10,
                marginTop: 5,
                '&::after': {
                    content: '""',
                    height: '10px', // Height of the additional gap
                    gridColumn: '1 / -1', // Span all columns
                    gridRow: '8', // Position after the 6th row
                },
            }}
        >
            {mappedElements}
        </Box>
    )
}
