import { StaticReport, StaticReportRow } from "./StaticReportModel"

export const mapStaticReportToStaticReportRow = (report: StaticReport): StaticReportRow[] => {
    let rows: StaticReportRow[] = []
    report.files.forEach(file => {
        rows.push({
            id: report.id,
            groupName: report.name,
            name: report.name,
            reportType: file.reportType,
            sampleCount: report.sampleIds.length,
            sequencingType: report.sequencingType,
            status: report.status,
            createdDate: new Date(report.createdDate)
        })
    });

    if(report.files.length === 0){
        rows = [
            {
                id: report.id,
                groupName: null,
                name: report.name,
                reportType: report.sequencingType,
                sampleCount: report.sampleIds.length,
                sequencingType: report.sequencingType,
                status: report.status,
                createdDate: new Date(report.createdDate)
            }
        ]
    }

    return rows
}