import { useState } from 'react'
import { Grid, Paper, Typography, TextField, Button, Container, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { useNotificationContext } from '../../context/NotificationContext'
import { generateBarcodes } from '../../libs/api/barcodes/BarcodesAPI'

const defaultValues = {
    filename: 'barcodes',
    customer: '',
    num_codes: 1,
    output_type: 'pdf',
    code_type: 'new',
}

function GenerateBarcodesForm() {
    const [formValues, setFormValues] = useState(defaultValues)
    const openNotification = useNotificationContext().openNotification

    const handleSubmit = async (event) => {
        event.preventDefault()
        
        const response = await generateBarcodes(formValues.num_codes, formValues.customer, formValues.output_type, formValues.code_type)
        if (response.succeeded) {        
            const url = URL.createObjectURL(response.data)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${formValues.filename}.${formValues.output_type}`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } else {            
            openNotification(response.errors.message, response.errors.severity)
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Paper
                    elevation={3}
                    style={{
                        padding: 20,
                    }}
                >
                    <Typography variant="h4" component="h4">
                        Generate Barcodes
                    </Typography>
                    <Grid container alignItems="center" direction="column" spacing={3}>
                        <Grid item>
                            <TextField id="filename-input" name="filename" label="Filename" type="text" value={formValues.filename} onChange={handleInputChange} />
                        </Grid>
                        <Grid item>
                            <TextField id="customer-input" name="customer" label="Customer" type="text" value={formValues.customer} onChange={handleInputChange} />
                        </Grid>
                        <Grid item>
                            <TextField id="num_codes" name="num_codes" label="Count" type="number" value={formValues.num_codes} onChange={handleInputChange} />
                        </Grid>

                        <Grid item>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Output type</FormLabel>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="output_type" defaultValue="pdf" onChange={handleInputChange}>
                                    <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
                                    <FormControlLabel value="xlsx" control={<Radio />} label="Excel" />
                                    <FormControlLabel value="json" control={<Radio />} label="JSON" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label2">Code type</FormLabel>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label2" name="code_type" defaultValue="new" onChange={handleInputChange}>
                                    <FormControlLabel value="old" control={<Radio />} label="Old" />
                                    <FormControlLabel value="new" control={<Radio />} label="New" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>  
                    </Grid>   
                    </Grid>
                </Paper>
            </form>
        </Container>
    )
}

export default GenerateBarcodesForm
