import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'

export interface ConfirmationDialogProps {
    openDialog: boolean
    title: string
    description: string
    confirmText: string
    cancelText: string
    onConfirm: () => void
    onCancel: () => void
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
    const { openDialog, title, description, confirmText, cancelText, onConfirm, onCancel } = props

    return (
        <React.Fragment>
            <Dialog
                open={openDialog}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ style: { borderRadius: 12, padding: 15 } }}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            height: '36px',
                            padding: '9px 16px',
                            marginLeft: 'auto',
                            borderRadius: '12px',
                            alignItems: 'flex-start',
                        }}
                        onClick={onCancel}
                    >
                        <Typography variant="body2" fontWeight="700" textTransform="capitalize">
                            {cancelText}
                        </Typography>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            height: '36px',
                            padding: '9px 16px',
                            marginLeft: '15px',
                            borderRadius: '12px',
                            alignItems: 'flex-start',
                        }}
                        onClick={onConfirm}
                        autoFocus
                    >
                        <Typography variant="body2" textTransform="capitalize">
                            {confirmText}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
