import { SidebarDrawer } from '../../../../components/SidebarDrawer'
import { SampleDetailsSidebarHeader } from '../../SidebarHeader'
import { SidebarSearchFilters } from '../../SidebarSearchFilters'
import { SidebarAccessions } from '../../SidebarAccessions'
import { SIDEBAR_DRAWER_BACKGROUND_COLOR } from '../../SampleDetails.constants'

export interface TaxonomySidebarProps {
    open: boolean
    onClose: () => void
}

export function TaxonomySidebar(props: TaxonomySidebarProps) {
    const { open, onClose } = props

    return (
        <SidebarDrawer open={open} customHeader={SampleDetailsSidebarHeader({ onClose })} onClose={onClose} paperSx={{ backgroundColor: SIDEBAR_DRAWER_BACKGROUND_COLOR }}>
            <SidebarAccessions />
            <SidebarSearchFilters />
        </SidebarDrawer>
    )
}
