import { useContext, useRef, useEffect } from 'react'
import Map from '@arcgis/core/Map.js'
import MapView from '@arcgis/core/views/MapView.js'
import esriConfig from '@arcgis/core/config'
import ScaleBar from '@arcgis/core/widgets/ScaleBar.js'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer.js'
import Graphic from '@arcgis/core/Graphic.js'
import Point from '@arcgis/core/geometry/Point'
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle.js'
import { SampleDataContext } from '../context/CustomContext'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'

const LOCATION_PROPERTY_FIELD = 'location'
const LOCATION_ANONYMIZED_MESSAGE = 'Location is Anonymized'
const LOCATION_NOT_PROVIDED_MESSAGE = 'Location is not provided'

function initializeMap(ref, location) {
    esriConfig.apiKey = 'AAPKa623de3f4906450fa89bdf509ba9917ae8uFAUrLEQ3IGWO1iZ5DrVQe6Y06RvYazM6FFtjuT8zhMtdBG3229dJT86hhTvFv'

    const map = new Map({
        basemap: 'arcgis-topographic', // Basemap layer service
    })

    const view = new MapView({
        container: ref.current,
        map: map,
        center: location.coordinates,
        zoom: 3,
    })

    // Add a scalebar
    const scalebar = new ScaleBar({
        view: view,
    })

    view.ui.add(scalebar, 'bottom-left')

    // Add ability to toggle between contour and satellite view
    const basemapToggle = new BasemapToggle({
        view: view, // The view that provides access to the map's "streets-vector" basemap
        nextBasemap: 'hybrid', // Allows for toggling to the "hybrid" basemap
    })

    view.ui.add(basemapToggle, 'bottom-right')

    // Add location to the map
    const graphicsLayer = new GraphicsLayer()
    map.add(graphicsLayer)

    const point = {
        type: 'point',
        longitude: location.coordinates[0],
        latitude: location.coordinates[1],
    }

    const simpleMarkerSymbol = {
        type: 'simple-marker',
        color: 'orange',
        size: 8,
        outline: {
            color: 'white',
            width: 1,
        },
    }

    const pointGraphic = new Graphic({
        geometry: point as Point,
        symbol: simpleMarkerSymbol,
    })

    graphicsLayer.add(pointGraphic)

    return view
}

export default function SampleMap() {
    const mapRef = useRef()
    const {
        sampleData: { location, publicSampleProps },
    } = useContext(SampleDataContext)

    const isAnonymized = publicSampleProps && publicSampleProps.findIndex((x) => x === LOCATION_PROPERTY_FIELD) < 0

    useEffect(() => {
        if (location) {
            initializeMap(mapRef, location)
        }
    }, [location])

    const renderLocation = location ? <div style={{ height: '600px' }} className="webmap" ref={mapRef}></div> : <div>{LOCATION_NOT_PROVIDED_MESSAGE}</div>

    return (
        <Box>
            {isAnonymized ? (
                <Grid sx={{ alignTtems: 'center', display: 'flex', flexDirection: 'column', height: '200px', justifyContent: 'center', alignItems: 'center' }}>
                    <Tooltip title="Anonymized">
                        <LockIcon sx={{ color: 'rgba(19, 19, 32, 0.38)' }} />
                    </Tooltip>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {LOCATION_ANONYMIZED_MESSAGE}
                    </Typography>
                </Grid>
            ) : (
                renderLocation
            )}
        </Box>
    )
}
