import { Divider, Grid, IconButton, Typography } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { SIDEBAR_FONT_COLOR } from './SampleDetails.constants'

export interface SampleDetailsSidebarHeaderProps {
    onClose: () => void
}
export function SampleDetailsSidebarHeader(props: SampleDetailsSidebarHeaderProps) {
    const { onClose } = props
    return (
        <>
            <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '5px 0 7px' }}>
                <img alt="Search Summary Icon" src="/images/search-summary-icon.svg" />
                <Typography variant="h6" fontWeight={'bold'} color={SIDEBAR_FONT_COLOR} paddingTop="3px">
                    Search Summary
                </Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Grid>
            <Divider sx={{ borderColor: 'white' }} />
        </>
    )
}
