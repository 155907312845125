import { Sample } from '../../libs/api/samples/SampleModel'
import { SearchFilters } from '../sample-details/SidebarSearchFilters'
import { GeochemicalItemRange } from './GeochemicalFilter'
import { RANGE_PROPERTIES, RangeValues, SELECTION_FILTERS, SampleSearchFilter } from './SampleSearch.constants'
import { SearchSampleFilters } from './SampleSearchSidebar'

/**
 * Maps filters and sample data to SearchFilters strings structure for the front end display (ActiveFilters and MatchingCriteria)
 * @param filters the SearchSampleFilters model
 * @param sample The sample data model
 * @returns The string arrays structure for the display
 */
export function mapSearchFilters(filters: SearchSampleFilters, sample: Sample): SearchFilters {
    const activeFilters: string[] = []
    const matchingCriteria: string[] = []

    SELECTION_FILTERS.map((selectionFilter) => {
        if (filters[selectionFilter.name]) {
            activeFilters.push(...filters[selectionFilter.name])
            if (selectionFilter.name !== SampleSearchFilter.SequencingType) {
                matchingCriteria.push(`${selectionFilter.title}: ${sample[selectionFilter.name]}`)
            }
        }
    })

    RANGE_PROPERTIES.map((rangeFilter) => {
        if (filters[rangeFilter.name]) {
            const { minValue, maxValue } = filters[rangeFilter.name]
            if (minValue && maxValue) {
                activeFilters.push(`${rangeFilter.shortTitle}: ${minValue}-${maxValue} ${rangeFilter.unitSymbol}`)
            } else {
                const comparatorSymbol = minValue ? '>=' : '<='
                activeFilters.push(`${rangeFilter.shortTitle}: ${comparatorSymbol} ${minValue || maxValue} ${rangeFilter.unitSymbol}`)
            }
            matchingCriteria.push(`${rangeFilter.shortTitle}: ${sample[rangeFilter.sampleAttr]} ${rangeFilter.unitSymbol}`)
        }
    })

    if (filters.geochemElements) {
        const geochemElements = mapGeochemFilters(filters.geochemElements)
        activeFilters.push(...geochemElements)
    }

    if (filters.geochemCompounds) {
        const geochemCompounds = mapGeochemFilters(filters.geochemCompounds)
        activeFilters.push(...geochemCompounds)
    }

    return {
        isActiveFilterExpanded: activeFilters.length > 0,
        isMatchingCriteriaExpanded: matchingCriteria.length > 0,
        activeFilters,
        matchingCriteria,
    }
}

/**
 * Maps Geochemical filters model to string array for UX display
 * @param filters The filters data model
 * @returns A string array with Geochemical selections
 */
const mapGeochemFilters = (filters: GeochemicalItemRange[]): string[] => {
    const printedFilters: string[] = []

    filters.map((elem) => {
        if (elem.range) {
            const { minValue, maxValue } = elem.range
            if (minValue && maxValue) {
                printedFilters.push(`${elem.id}: ${minValue}-${maxValue} ppm`)
            } else {
                const comparatorSymbol = minValue ? '>=' : '<='
                printedFilters.push(`${elem.id}: ${comparatorSymbol} ${minValue || maxValue} ppm`)
            }
        } else {
            printedFilters.push(`${elem.id}`)
        }
    })

    return printedFilters
}

/**
 * Filters samples using the front end filtering from the overall filters object
 * @param samples The samples array to be filtered
 * @param filters The overal filter obejct
 * @returns The filtered samples array
 */
export function filterSamples(samples: Sample[], filters: SearchSampleFilters): Sample[] {
    if (!samples) {
        return []
    }

    return samples.filter(
        (sample) =>
            (!filters.sampleType || filters.sampleType.includes(sample.sampleType)) &&
            (!filters.samplingMethod || filters.samplingMethod.includes(sample.samplingMethod)) &&
            (!filters.shippingCondition || filters.shippingCondition.includes(sample.shippingCondition)) &&
            (!filters.weather || filters.weather.includes(sample.weather)) &&
            (!filters.phRange ||
                (sample.ph !== null &&
                    (!filters.phRange.minValue || filters.phRange.minValue <= sample.ph) &&
                    (!filters.phRange.maxValue || filters.phRange.maxValue >= sample.ph))) &&
            (!filters.temperatureRange ||
                (sample.temperature !== null &&
                    (!filters.temperatureRange.minValue || filters.temperatureRange.minValue <= sample.temperature) &&
                    (!filters.temperatureRange.maxValue || filters.temperatureRange.maxValue >= sample.temperature))) &&
            (!filters.depthRange ||
                (sample.depth !== null &&
                    (!filters.depthRange.minValue || filters.depthRange.minValue <= sample.depth) &&
                    (!filters.depthRange.maxValue || filters.depthRange.maxValue >= sample.depth))) &&
            (!filters.doRange ||
                (sample.do !== null &&
                    (!filters.doRange.minValue || filters.doRange.minValue <= sample.do) &&
                    (!filters.doRange.maxValue || filters.doRange.maxValue >= sample.do))) &&
            (!filters.conductivityRange ||
                (sample.conductivity !== null &&
                    (!filters.conductivityRange.minValue || filters.conductivityRange.minValue <= sample.conductivity) &&
                    (!filters.conductivityRange.maxValue || filters.conductivityRange.maxValue >= sample.conductivity)))
    )
}

/**
 * Filters a samples array where keyword matches (with include) project name or sample Id
 * @param filteredSamples Samples array to be filtered
 * @param searchTerm The keyword to be matched
 * @returns The samples array that contains the keyword substring in either project name or sample id
 */
export function searchSamples(filteredSamples: Sample[], searchTerm: string): Sample[] {
    if (searchTerm) {
        return filteredSamples.filter(
            (sample) =>
                sample.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                sample.userDefinedSampleId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                sample.projectName.toLowerCase().includes(searchTerm.toLowerCase())
        )
    } else {
        return filteredSamples
    }
}

/**
 * Obtains the complete updated range model for a new updated range value
 * @param range The current range model to be updated
 * @param key The key value being modified
 * @param value The new value for the key to be modified
 * @returns The updated range model. Can be undefined if no value is set.
 */
export const getUpdatedRangeValue = (range: RangeValues, key: string, value: string): RangeValues => {
    if (range) {
        range = {
            ...range,
            [key]: value.length > 0 ? value : undefined,
        }
    } else if (value.length > 0) {
        range = {
            [key]: value,
        }
    }

    if (range && range.minValue === undefined && range.maxValue === undefined) {
        range = undefined
    }

    return range
}

/**
 * Validates a range value model according to property rules, returning a string message error.
 * @param range The range value model to be validated
 * @param minAllowed The minimum (inclusive) value allowed for the range
 * @param maxAllowed The maximum (inclusive) value allowed for the range
 * @returns A string containing the error message or empty string if validation succeeds.
 */
export const validateRangeValue = (range: RangeValues, minAllowed: number, maxAllowed: number): string => {
    if (!range) {
        return ''
    }

    const { minValue, maxValue } = range
    const minValueNumber = Number(minValue)
    const maxValueNumber = Number(maxValue)

    if ((minValue !== undefined && isNaN(minValue)) || (maxValue !== undefined && isNaN(maxValue))) {
        return `Please provide a number within the range: ${minAllowed} to ${maxAllowed}`
    }

    const validateNumber = (value: number) => {
        return value >= minAllowed && value <= maxAllowed
    }

    if ((minValue !== undefined && !validateNumber(minValueNumber)) || (maxValue !== undefined && !validateNumber(maxValueNumber))) {
        return `Please provide a number within the range: ${minAllowed} to ${maxAllowed}`
    }

    if (minValue !== undefined && maxValue !== undefined && minValueNumber > maxValueNumber) {
        return 'Max value must be equal or higher than min value.'
    }

    return ''
}
