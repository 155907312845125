import { TabList } from '@mui/lab'
import { Button, Grid, Tab, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import { useNavigate } from 'react-router-dom'
import { TabItemValues, TabItems } from '../SampleDetails.model'
import { SaveAlt } from '@mui/icons-material'
import { useContext, useEffect, useState } from 'react'
import { SampleDataContext } from '../../../context/CustomContext'

const activeTabStyle = {
    padding: '10px 25px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    letterSpacing: 1.5,
    backgroundColor: 'white',
    color: '#1B49D4',
    borderRadius: '8px 8px 0 0',
    minHeight: '48px',
}
const inactiveTabStyle = {
    padding: '10px 25px',
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    letterSpacing: 1.5,
    minHeight: '48px',
}

export interface TopBarProps {
    sampleId: string
    currentTab: TabItemValues
    publicSampleProps?: string[]
    onEditData: (isEdit: boolean) => void
    updateCurrentTab: (newTab: TabItemValues) => void
}

/**
 * Top bar navigation component for the samples details page
 */
export function TopBar(props: TopBarProps) {
    const { sampleId, currentTab, publicSampleProps, onEditData, updateCurrentTab } = props
    const { exportButtonEnabled, setTriggerExport, isUserMemberOfProject } = useContext(SampleDataContext)
    const [publicProjectVisibilityKeys, setPublicProjectVisibilityKeys] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        const publicProjectKeys = validTabVisibilityKeys(publicSampleProps)
        setPublicProjectVisibilityKeys(publicProjectKeys)
    }, [publicSampleProps])

    return (
        <Grid
            container
            sx={{
                backgroundColor: '#1B49D4',
                color: 'white',
                padding: '10px 0 0',
            }}
        >
            <Grid
                sx={{
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Grid>
                    <Grid item xs={12} sx={{ padding: '5px 44px' }}>
                        <Button size="small" onClick={() => navigate(-1)}>
                            <Typography variant="body1" color="white" textTransform="capitalize">
                                &lt; Result List
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        sx={{
                            padding: '5px 44px 20px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography variant="h6" fontWeight="bold" textTransform="capitalize" sx={{ textWrap: 'nowrap' }}>
                            Sample ID | {sampleId}
                        </Typography>
                        {isUserMemberOfProject ? (
                            <Grid item xs={3} sx={currentTab !== TabItemValues.Summary ? { display: 'none' } : {}}>
                                <Button
                                    size="small"
                                    sx={{ color: 'white', marginLeft: '100px' }}
                                    onClick={() => {
                                        onEditData(true)
                                    }}
                                >
                                    Edit
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
                <Grid sx={{ padding: '20px 44px' }}>
                    <Button
                        id="export-button"
                        variant="contained"
                        disabled={!exportButtonEnabled}
                        sx={{
                            display: `${
                                currentTab === TabItemValues.MetabolicPathways || currentTab === TabItemValues.Taxonomy || currentTab === TabItemValues.GeochemicalData
                                    ? ''
                                    : 'none'
                            }`,
                            padding: '12px 24px',
                            backgroundColor: 'rgba(34, 34, 58, 1)',
                            borderRadius: '12px',
                        }}
                        onClick={() => {
                            setTriggerExport(true)
                        }}
                    >
                        <SaveAlt />
                        <Typography textTransform="capitalize" sx={{ paddingLeft: '6px' }}>
                            Export
                        </Typography>
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TabList
                    aria-label="lab API tabs example"
                    onChange={(event, newValue) => updateCurrentTab(newValue)}
                    sx={{ backgroundColor: '#002FC7' }}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    {TabItems.map((item) => {
                        const tabStyle = currentTab === item.value ? activeTabStyle : inactiveTabStyle

                        const isAnonymized = isTabAnonymized(item.publicProjectVisibilityKey, publicProjectVisibilityKeys)
                        const tabIcon = isAnonymized ? <LockIcon sx={{ color: 'rgba(19, 19, 32, 0.38)', transform: 'scale(0.8)', marginBottom: '4px' }} /> : null

                        return <Tab key={item.value} label={item.name} value={item.value} sx={tabStyle} disabled={isAnonymized} icon={tabIcon} iconPosition="end" />
                    })}
                </TabList>
            </Grid>
        </Grid>
    )
}

/**
 * Verify if tab item should be anonymized based on the tab anonymized property and project public settings
 * @param tabItemVisibilityKey Current item visibility key. Only set for anonymizable tabs
 * @param publicProjectVisibilityKeys Array of anonymized items that should be displayed from project settings
 * @returns True if tab item is anonymized
 */
const isTabAnonymized = (tabItemVisibilityKey: string, publicProjectVisibilityKeys: string[]): boolean => {
    return tabItemVisibilityKey && publicProjectVisibilityKeys && publicProjectVisibilityKeys.findIndex((key) => key === tabItemVisibilityKey) < 0
}

/**
 * Returns all the anonymizable tab (Ones that contains a defined publicProjectVisibilityKey) items that should be displayed based on the publicSampleProps attribute array
 * @param publicSampleProps The list of anonymized items that can be shown for public projects
 * @returns An array of the anonymizable tabs that should be shown.
 */
const validTabVisibilityKeys = (publicSampleProps: string[]): string[] => {
    const anonymizableTabItemKeys = TabItems.filter((x) => x.publicProjectVisibilityKey !== null).map((x) => x.publicProjectVisibilityKey)
    return publicSampleProps ? publicSampleProps.filter((x) => anonymizableTabItemKeys.includes(x)) : null
}
