import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'

export interface StatusMenuProps {
    sampleId: string
    loading: boolean
    loadSample: () => Promise<void>
    updateSampleId: (id: any) => void
    updateSamplesContext: (samples: any) => void
    updateStatus: () => Promise<void>
}

export function StatusMenu(props: StatusMenuProps) {
    const { sampleId, loading, loadSample, updateSampleId, updateSamplesContext, updateStatus } = props

    return (
        <Stack spacing={2}>
            <Typography variant="h6" component="h5">
                Sample
            </Typography>
            <TextField
                id="outlined-basic"
                label="Sample ID"
                variant="outlined"
                value={sampleId}
                onChange={(event) => updateSampleId(event.target.value)}
                onKeyDown={(event) => event.key === 'Enter' && loadSample()}
            />
            <LoadingButton variant="contained" color="primary" loading={loading} onClick={loadSample}>
                Add
            </LoadingButton>
            <LoadingButton variant="contained" color="primary" loading={loading} onClick={() => updateSamplesContext({})}>
                Clear
            </LoadingButton>
            <LoadingButton variant="contained" color="primary" loading={loading} onClick={updateStatus}>
                Receive
            </LoadingButton>
        </Stack>
    )
}
