import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { IExceptionTelemetry } from '@microsoft/applicationinsights-web'

interface AppError {
    [name: string]: IExceptionTelemetry
}

const StorageError: AppError = {
    FailToWrite: {
        id: 'StorageError.FailToWrite',
        exception: new Error('Exception when writing in local storage'),
        severityLevel: SeverityLevel.Error,
    },
    FailToRead: {
        id: 'StorageError.FailToRead',
        exception: new Error('Exception when reading from local storage'),
        severityLevel: SeverityLevel.Error,
    },
    FailToClear: {
        id: 'StorageError.FailToClear',
        exception: new Error('Exception when clearing local storage'),
        severityLevel: SeverityLevel.Error,
    },
}

const ImageApiError: AppError = {
    FailToFetchImage: {
        id: 'ImageApiError.FailToFetchImage',
        exception: new Error('Failed to fetch image from blob storage'),
        severityLevel: SeverityLevel.Error,
    },
    FailToFetchImages: {
        id: 'ImageApiError.FailToFetchImages',
        exception: new Error('Failed to fetch images from blob storage'),
        severityLevel: SeverityLevel.Error,
    },
}

export { StorageError, ImageApiError }
