import { Check } from '@mui/icons-material'
import { SxProps, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material'
import { useState } from 'react'

export const enum GeochemicalTabState {
    periodicTable = 'periodicTable',
    elementTable = 'elementTable',
    compoundTable = 'compoundTable',
}

export interface GeochemicalTabProps {
    tabState: GeochemicalTabState
    sx?: SxProps
    onTabStateChange: (newState: GeochemicalTabState) => void
}

export function GeochemicalTab(props: GeochemicalTabProps) {
    const { tabState, sx, onTabStateChange } = props
    const [currTabState, setCurrTabState] = useState<GeochemicalTabState>(tabState)

    const CustomToggle = styled(ToggleButton)({
        width: '180px',
        borderRadius: '12px',
        textTransform: 'inherit',
        '& .icon': {
            width: '24px', // Fixed width for the icon
            height: '24px', // Fixed height for the icon
        },
        '&.Mui-selected': {
            backgroundColor: '#EDF1FC',
            '&:hover': {
                backgroundColor: '#EDF1FC',
                cursor: 'unset',
            },
        },
    })

    const handleTabStateChange = (newValue: GeochemicalTabState) => {
        if (newValue && newValue !== currTabState) {
            setCurrTabState(newValue)
            onTabStateChange(newValue)
        }
    }

    return (
        <ToggleButtonGroup value={tabState} exclusive onChange={(e, value) => handleTabStateChange(value)} sx={sx}>
            <CustomToggle value={GeochemicalTabState.periodicTable}>
                {tabState === GeochemicalTabState.periodicTable ? (
                    <Check className="icon" sx={{ color: '#1B49D4' }} />
                ) : (
                    <img alt="Krona Icon" className="icon" src="/images/periodicTable.svg" />
                )}
                <Typography variant="body2" fontWeight="Bold" marginLeft="5px">
                    Periodic table
                </Typography>
            </CustomToggle>
            <CustomToggle value={GeochemicalTabState.elementTable}>
                {tabState === GeochemicalTabState.elementTable ? (
                    <Check className="icon" sx={{ color: '#1B49D4' }} />
                ) : (
                    <img alt="Element Icon" className="icon" src="/images/element.svg" />
                )}
                <Typography variant="body2" fontWeight="Bold" marginLeft="5px">
                    Element Table
                </Typography>
            </CustomToggle>
            <CustomToggle value={GeochemicalTabState.compoundTable}>
                {tabState === GeochemicalTabState.compoundTable ? (
                    <Check className="icon" sx={{ color: '#1B49D4' }} />
                ) : (
                    <img alt="Compound Icon" className="icon" src="/images/compounds.svg" />
                )}
                <Typography variant="body2" fontWeight="Bold" marginLeft="5px">
                    Compound Table
                </Typography>
            </CustomToggle>
        </ToggleButtonGroup>
    )
}
