import { Box, Checkbox, FormControl, FormControlLabel, Grid, Typography } from '@mui/material'
import { useCallback } from 'react'
import { PUBLIC_SAMPLE_PROPERTIES } from './PublicSamplePropsForm.model'

export interface PublicSamplePropertiesFormProps {
    publicSampleProps: string[]
    onPublicSamplePropsChange: (newAnonProps: string[]) => void
}

export function PublicSamplePropertiesForm(props: PublicSamplePropertiesFormProps) {
    const { publicSampleProps, onPublicSamplePropsChange } = props

    const checkedItem = useCallback(
        (property: string): boolean => {
            if (!publicSampleProps) {
                return false
            }
            return publicSampleProps.includes(property)
        },
        [publicSampleProps]
    )

    const checkedGroupCount = useCallback(
        (properties: string[]): number => {
            if (!publicSampleProps) {
                return 0
            }
            const count = publicSampleProps.filter((x) => properties.includes(x))?.length || 0
            return count
        },
        [publicSampleProps]
    )

    const handleItemChange = useCallback(
        (property: string) => {
            if (!publicSampleProps) {
                onPublicSamplePropsChange([property])
            } else if (publicSampleProps.findIndex((x) => x === property) >= 0) {
                onPublicSamplePropsChange(publicSampleProps.filter((x) => x !== property))
            } else {
                onPublicSamplePropsChange(publicSampleProps.concat(property))
            }
        },
        [publicSampleProps, onPublicSamplePropsChange]
    )

    const handleGroupChange = useCallback(
        (properties: string[]) => {
            if (!publicSampleProps) {
                return onPublicSamplePropsChange(properties)
            }

            const checkedCount = checkedGroupCount(properties)
            if (checkedCount < properties.length) {
                onPublicSamplePropsChange([...new Set([...publicSampleProps, ...properties])])
            } else {
                onPublicSamplePropsChange(publicSampleProps.filter((x) => !properties.includes(x)))
            }
        },
        [publicSampleProps, onPublicSamplePropsChange]
    )

    return (
        <Grid>
            {PUBLIC_SAMPLE_PROPERTIES.map((group) => {
                const { name, properties } = group
                const propertyIds = properties.map((item) => item.id)
                const propertiesCount = properties.length
                const selectedPropertiesCount = checkedGroupCount(propertyIds)
                return (
                    <FormControl key={group.name}>
                        <FormControlLabel
                            key={name}
                            label={<Typography variant="body2">{name}</Typography>}
                            control={
                                <Checkbox
                                    checked={selectedPropertiesCount === propertiesCount}
                                    indeterminate={selectedPropertiesCount > 0 && selectedPropertiesCount < propertiesCount}
                                    onChange={() => handleGroupChange(propertyIds)}
                                />
                            }
                        />
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1.5fr 1.3fr 1fr 1.4fr', marginLeft: 3 }}>
                            {group.properties.map((property) => {
                                const { id, name } = property
                                return (
                                    <FormControlLabel
                                        key={id}
                                        id={id}
                                        label={<Typography variant="body2">{name}</Typography>}
                                        control={<Checkbox checked={checkedItem(id)} onChange={() => handleItemChange(id)} />}
                                    />
                                )
                            })}
                        </Box>
                    </FormControl>
                )
            })}
        </Grid>
    )
}
